import { LaborRateTypes } from "../types/labor"


export interface AddLaborBreakdownInitialValues {
  // needed by API
  date: Date,
  hours: number,
  quantity: string,
  rate_type: LaborRateTypes | "",
  type_id: string,
  id?: string,
  // needed to display row data
  rate: string,
  type_name: string,
  cost_code: string,
  rate_type_name: string,
  total_hours: string | number,
  total_cost: string | number,
}

export function defaultAddLaborBreakdownInitialValues(date_start?: Date): AddLaborBreakdownInitialValues {
  return {
    // needed by API
    date: date_start ?? new Date(),
    hours: 8,
    quantity: "",
    rate_type: "",
    type_id: "",
    // needed to display row data
    rate: "",
    type_name: "",
    cost_code: "",
    rate_type_name: "",
    total_hours: "",
    total_cost: "",
  }
}
