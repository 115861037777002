import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import {useSnackbar} from "notistack"
import {useDispatch} from 'react-redux'

// @ts-ignore
import FormBreakdowns from "./FormBreakdowns"
// @ts-ignore
import FormMarkup from "./FormMarkup"
// @ts-ignore
import Container from "../components/Container"
import FormWideButtons from "../components/FormWideButtons"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import Switch from "../components/Fields/Switch"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import {getNextTicketNumber} from "../api/ticket"
import {
  CreateTicketInitialValues,
  defaultCreateTicketInitialValues
} from "./CreateTicket.types"
import FilesRow from "../components/FilesRow"
import {calculateTotalFileSizeInBytes} from "../types/file"
import {calculateTotalWithMarkupsInForm} from "../util/markup"
import {formatMoney} from "../libs/format"
import {createNewEquipmentTypesFromBreakdowns, createNewMaterialTypesFromBreakdowns} from "./CreateTicket.submit"
import { goToTicketsList } from "../util/routes"
import {addMultipleEquipmentTypes} from '../store/features/equipmentSlice'
import {addMultipleMaterials} from '../store/features/materialSlice'
import {DateField} from './components/DateField'


const useStyles = makeStyles<Theme>((theme) => ({
  total: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 20,
    paddingTop: 10
  }
}))

const fileUploadLimitInMB = 50

interface CreateTicketProps {
  initialValues?: CreateTicketInitialValues,
  submitValues: (output: CreateTicketInitialValues, nextTicketNumber: string|null) => Promise<void>,
  existingFiles?: any[],
  submitButtonText?: string,
  project: any
}

export default function CreateTicket(props: CreateTicketProps) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const project = props.project
  const [nextTicketNumber, setNextTicketNumber] = React.useState<string|null>(null)
  const [submitted, setSubmitted] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const allowedToAddCosts = project?.project_user_role && project.project_user_role !== 'crew'
  const projectLicenseIsActive = project.project_license_is_active

  React.useEffect(() => {
    if (!!project && projectLicenseIsActive) {
      if (project.id) {
        getNextTicketNumber(project.id)
          .then((res) => {
            if(res.status === 200 && !!res.data.next_number){
              setNextTicketNumber(res.data.next_number)
            }
          })
      }
    } else {
        history.push(goToTicketsList(project.id))
        enqueueSnackbar(t("view.Tickets.cannotCreateTicketInactiveLicense"), {
          variant: "info",
      })
    }
  }, [project, projectLicenseIsActive, history, t, enqueueSnackbar])

  React.useEffect(() => {
    window.onbeforeunload = function (_) {
      if (history.location.pathname.includes("tickets/add")) {
        return ""
      } else {
        return null
      }
    }
  }, [history.location.pathname])


  return (
    <Formik
      validateOnChange={false}
      initialValues={props.initialValues ?? defaultCreateTicketInitialValues()}
      validationSchema={Yup.object().shape({
        description: Yup.string().required(t("form.message.descriptionRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitted(true)
        // need to create any new material and equipment types before we can submit
        const newMaterialTypes = await createNewMaterialTypesFromBreakdowns(values.materialBreakdown)
        const newEquipmentTypes = await createNewEquipmentTypesFromBreakdowns(values.equipmentBreakdown)
        await props.submitValues(values, nextTicketNumber)
        resetForm()
        setSubmitting(false)
        // dispatch new types
        dispatch(addMultipleEquipmentTypes(newEquipmentTypes))
        dispatch(addMultipleMaterials(newMaterialTypes))
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
        }) => {
        return (
          <Form>
            <Container removeTop>
              <FormWideContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={nextTicketNumber ?? ""}
                      value={values.number}
                      label={t("form.label.ticketNumber")}
                      name="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.pco_number && errors.pco_number)}
                      helperText={touched.pco_number && errors.pco_number}
                      label={t("form.label.pco")}
                      name="pco_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pco_number}
                    />
                  </Grid>

                  <DateField
                    width={'half'}
                    label={t("form.label.startDate")}
                    value={values.date_start}
                    onChange={(dateStr) => setFieldValue('date_start', dateStr)}
                    maxDate={values.date_end ? new Date(values.date_end) : undefined}
                  />

                  <DateField
                    width={'half'}
                    label={t("form.label.endDate")}
                    value={values.date_end}
                    onChange={(dateStr) => setFieldValue('date_end', dateStr)}
                    minDate={values.date_start ? new Date(values.date_start) : undefined}
                  />

                  <Grid item xs={12}>
                    <TextAreaField
                      autoFocus
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label={t("form.label.workDescription")}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextAreaField
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      label={t("form.label.notes")}
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.subject && errors.subject)}
                      helperText={touched.subject && errors.subject}
                      label={t("form.label.subject")}
                      name="subject"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.invoice_number && errors.invoice_number)}
                      helperText={touched.invoice_number && errors.invoice_number}
                      label={t("form.label.invoiceNumber")}
                      name="invoice_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.invoice_number}
                    />
                  </Grid>

                  <DateField
                    width={'half'}
                    label={t("form.label.dateInvoice")}
                    value={values.date_invoice}
                    onChange={(dateStr) => setFieldValue('date_invoice', dateStr)}
                  />

                  <Grid item xs={12}>
                    {/* For editing, we show existing files here */
                      props.existingFiles && props.existingFiles.length > 0
                        ? <Grid item xs={12} sx={{paddingBottom: 4}}>
                          <Typography color="primary">Current Files:</Typography>
                          <FilesRow files={props.existingFiles}/>
                        </Grid>
                        : <></>
                    }

                    <UploadMultipleField
                      errors={errors}
                      label={t("form.label.uploadAttachments")}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      fileLimit={fileUploadLimitInMB}
                      existingFilesSizeInBytes={
                        props.existingFiles != null
                        ? calculateTotalFileSizeInBytes(props.existingFiles)
                        : null
                      }
                    />
                  </Grid>

                  {allowedToAddCosts ?
                    <Grid item xs={12} md={3}>
                      <InputLabel>{t("form.label.addCosts")}</InputLabel>
                      <Switch
                        checked={values.isAddCosts}
                        name="isAddCosts"
                        onBlur={handleBlur}
                        onChange={() => {
                          const newValue = !values.isAddCosts
                          const newType =
                            newValue && values.isLumpSum ? "sum_total" : newValue ? "sum_rates" : "tm"
                          setFieldValue("isAddCosts", newValue)
                          setFieldValue("type", newType)
                        }}
                        leftText={t("form.label.yes")}
                        rightText={t("form.label.no")}
                      />
                    </Grid>
                    : <></>
                  }

                  {values.isAddCosts ? (
                    <Grid item xs={12} md={3}>
                      <InputLabel>{t("form.label.switchLumpSum")}</InputLabel>
                      <Switch
                        checked={values.isLumpSum}
                        name="isLumpSum"
                        onBlur={handleBlur}
                        onChange={() => {
                          const newValue = !values.isLumpSum
                          const newType = newValue
                            ? "sum_total"
                            : values.isAddCosts
                              ? "sum_rates"
                              : "tm"
                          setFieldValue("isLumpSum", newValue)
                          setFieldValue("type", newType)
                        }}
                        leftText={t("form.label.yes")}
                        rightText={t("form.label.no")}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {values.isAddCosts && values.isLumpSum ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.manual_total && errors.manual_total)}
                        helperText={touched.manual_total && errors.manual_total}
                        label={t("form.label.total")}
                        name="manual_total"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.manual_total}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </FormWideContainer>
            </Container>

            <FormBreakdowns values={values} setFieldValue={setFieldValue} />

            {values.type !== "tm" ? (
              <FormMarkup values={values} setFieldValue={setFieldValue} total={total} setTotal={setTotal} />
            ) : (
              <></>
            )}
            <Prompt
              when={!submitted && projectLicenseIsActive}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            { values.isAddCosts ?
              <Container removeTop>
                <FormWideContainer>
                  <Grid item xs={12} justifyContent="flex-end" style={{display: 'flex'}}>
                    <Typography variant="body2" className={classes.total}>
                      Total: {formatMoney(calculateTotalWithMarkupsInForm(total, values.formMarkup))}
                    </Typography>
                  </Grid>
                </FormWideContainer>
              </Container>
              : <></>
            }
            <FormWideButtons
              cancel={{
                action: () => {
                  history.push(goToTicketsList(project.id))
                },
                text: t("view.ChangeOrder.cancel"),
              }}
              submit={{
                disabled: !isValid,
                isSubmitting: isSubmitting,
                text: props.submitButtonText ?? t("view.Tickets.createTicket"),
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
