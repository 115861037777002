import React from "react"
import { Route, Redirect } from "react-router-dom"

import { useTracFloSelector } from "../../../store/useTracFloSelector"
import { goHome } from "../../../util/routes"

interface SubRouteGuardProps {
  Component: any,
  redirectLink?: string,
  path: string,
  RouteGuard?: any,
}

//Checks if user is a Sub to render the corresponding component otherwise redirect them.
export default function SubRouteGuard(props: SubRouteGuardProps) {
  const {Component, redirectLink=goHome, path, RouteGuard=Route, ...rest} = props
  const company = useTracFloSelector((state) => state.company)
  const authorizedToViewComponent = company?.company_type === "trade"

  return (
    <RouteGuard
      exact
      path={path}
      {...rest}
    >
      {authorizedToViewComponent ? <Component {...props} /> : <Redirect to={redirectLink} />}
    </RouteGuard>
  )
}
