import React from "react"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"
import { Menu, MenuItem } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// @ts-ignore
import Button from "../../../../../../../components/Button"
import { CompanyType } from "../../../../../../../types/company"
import { ProjectUserRoleCode } from "../../../../../../../types/userRoles"
import { ActionCode } from "../../../../../../../types/action"
import {Ticket} from '../../../../../../../types/ticket'


export type UpdatesFormName = 'trade_manual_gc_cost_approve' | 'update_invoice_info' | 'gc_manual_cost_approve' | null

function showTradeManualGcCostApprove(
  ticketIncludesCosts: boolean,
  ticketActionCode: ActionCode,
  isLicenseOwner: boolean
): boolean {
  return ticketIncludesCosts
    && ticketActionCode !== 'cost_approve'
    && isLicenseOwner
}

// Should be identical to api :: src/controllers/req-data-validation/ticketReviewProcessValidation.ts
const gcCannotManuallyCostApproveTicketActionCodes: ActionCode[] = [
  'draft',
  // if rejected, cannot be owner approved
  'tm_reject',
  'cost_reject',
  // cannot approve it again
  'cost_approve',
]

function showGcManualCostApprove(
  ticketIncludesCosts: boolean,
  ticketActionCode: ActionCode,
  isLicenseOwner: boolean
): boolean {
  return ticketIncludesCosts
    && !gcCannotManuallyCostApproveTicketActionCodes.includes(ticketActionCode)
    && isLicenseOwner
}

function showUpdateInvoiceInfo(
  ticketActionCode: ActionCode,
): boolean {
  const showUpdateInvoiceInfoTicketActions: ActionCode[] = [
    'tm_submitted', 'cost_submitted', 'cost_reviewed', 'cost_owner', 'cost_approve'
  ]
  return  showUpdateInvoiceInfoTicketActions.includes(ticketActionCode)
}

function getTicketUpdateMenuItems(
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  ticketIncludesCosts: boolean,
  isLicenseOwner: boolean,
  t: (s: string) => string,
  toggleFormHandleClose: (updatesFormName: UpdatesFormName) => void,
  ticketActionCode: ActionCode,
): JSX.Element[] {
  const ticketUpdateMenuItems: JSX.Element[] = []
  const updateAllowedUserRoles: ProjectUserRoleCode[] = ["pm","super"]
  if (companyType === "trade" && updateAllowedUserRoles.includes(projectUserRole)) {
    if(showTradeManualGcCostApprove(ticketIncludesCosts, ticketActionCode, isLicenseOwner)){
      ticketUpdateMenuItems.push(
        <MenuItem onClick={() => toggleFormHandleClose("trade_manual_gc_cost_approve")}>
          {t("view.Tickets.UpdatesDropdown.markApprovedLabel")}
        </MenuItem>
      )
    }
    if(showUpdateInvoiceInfo(ticketActionCode)){
      ticketUpdateMenuItems.push(
        <MenuItem onClick={() => toggleFormHandleClose("update_invoice_info")}>
          {t("view.Tickets.UpdatesDropdown.updateInvoiceNumberDate")}
        </MenuItem>
      )
    }
  }
  if (companyType === 'cm' && updateAllowedUserRoles.includes(projectUserRole)) {
    if (showGcManualCostApprove(ticketIncludesCosts, ticketActionCode, isLicenseOwner)) {
      ticketUpdateMenuItems.push(
        <MenuItem onClick={() => toggleFormHandleClose("gc_manual_cost_approve")}>
          {t("view.Tickets.UpdatesDropdown.markFinalApprovalLabel")}
        </MenuItem>
      )
    }
  }
  return ticketUpdateMenuItems
}

interface UpdatesDropdownProps {
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  ticket: Ticket,
  isLicenseOwner: boolean,
  toggleForm: (updatesFormName: UpdatesFormName) => void,
  ticketActionCode: ActionCode,
}

export function UpdatesDropdown(
  {companyType, projectUserRole, ticket, isLicenseOwner, toggleForm, ticketActionCode}: UpdatesDropdownProps
):JSX.Element {
  const { t } = useTranslation("private")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleFormHandleClose = (updatesFormName: UpdatesFormName) => {
    toggleForm(updatesFormName)
    handleClose()
  }

  const ticketUpdateMenuItems: JSX.Element[] = getTicketUpdateMenuItems(
    companyType,
    projectUserRole,
    ticket.includes_costs,
    isLicenseOwner,
    t,
    toggleFormHandleClose,
    ticketActionCode,
  )

  return ticketUpdateMenuItems.length > 0 ? (
    <Grid item>
      <Button
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        style={{ minWidth: 125, backgroundColor: "white", color: "#143366" }}
      >
        {t("view.Tickets.updates")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        {ticketUpdateMenuItems}
      </Menu>
    </Grid>
  ) : (
    <></>
  )
}
