import React from "react"
import { useTranslation } from "react-i18next"
import { GridColDef } from "@mui/x-data-grid"
import { IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'

//@ts-ignore
import DtRowTitle from "../../components/DtRowTitle"

export default function SettingsUsers(
  canRemoveUserFromCompany: boolean,
  openConfirmCancelRemoveUserDialog: (user_id: string) => void,
  user_id: string | null,
): GridColDef[] {
  const { t } = useTranslation("private")

  const columns: GridColDef[] = [
    {
      align: "left",
      headerAlign: "left",
      field: "name", // variable id
      headerName: t("view.Settings.name"), // name that will appear on colummn
      minWidth: 180, //manual
      sortable: false,
      renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "email",
      headerName: t("view.Settings.email"),
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "phone",
      headerName: t("view.Settings.phone"),
      width: 130,
      editable: false,
      sortable: false,
    },
  ]

  if(canRemoveUserFromCompany){
    columns.push({
      align: "center",
      headerAlign: "center",
      field: "",
      headerName: t("view.Settings.remove_user"),
      width: 120,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return params.id !== user_id 
          ? <IconButton 
            color="secondary"
            onClick={() => {openConfirmCancelRemoveUserDialog(params.id)}}
          >
            <DeleteIcon />
          </IconButton>
          : <></>
      }
    })
  }

  return columns
}
