import React from "react"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import { GridColDef } from "@mui/x-data-grid"

//@ts-ignore
import DtRowTitle from "../../components/DtRowTitle"
import { formatDate, formatMoney } from "../format"
import { convertToNumber } from "../../util/number"

interface SettingsMaterialsEquipmentProps {
  handleEdit: (index: number) => void,
  handleSelection: (value: any) => void ,
}

export default function SettingsMaterialsEquipment(props: SettingsMaterialsEquipmentProps): GridColDef[] {
  const { handleEdit, handleSelection } = props

  return [
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: "Description",
      minWidth: 180,
      sortable: false,
      renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "cost_code",
      headerName: "Cost Code",
      flex: 1,
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate",
      renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
      headerName: "Rate",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "unit",
      headerName: "Unit",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_created",
      renderCell: (params: any) => formatDate(params.value),
      headerName: "Added",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_modified",
      renderCell: (params: any) => formatDate(params.value),
      headerName: "Modified",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "center",
      headerAlign: "right",
      field: "",
      headerName: " ",
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        
        return params?.row?.editable ? (
          <IconButton
            color="secondary"
            onClick={() => {
              if (typeof handleEdit === "function") {
                handleEdit(params.row.index)
              }
              if (typeof handleSelection === "function") {
                handleSelection(params.row)
              }
            }}
          >
            <EditIcon />
          </IconButton>
        ) : <></>
      }
    },
  ]
}
