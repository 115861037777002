import { editProfile } from "../api/profile"
import { EditProfileFormFields } from "./EditProfile"

export async function editUserProfile(
  snackbarMessage: {successMessage: string, errorMessage: string},
  enqueueSnackbar: (message: string, options: any) => any,
  user: EditProfileFormFields
) : Promise<{token: string} | null> {
  try {
    const {data: editedProfile} = await editProfile(user)
    if (!!editedProfile.token) {
      enqueueSnackbar(snackbarMessage.successMessage, {variant: "success",})
      return editedProfile
    } else {
      enqueueSnackbar(snackbarMessage.errorMessage, {variant: "error",})
      return null
    }
  } catch (e) {
    enqueueSnackbar(snackbarMessage.errorMessage, {variant: "error",})
    return null
  }
}