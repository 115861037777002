import React from "react"
import {GridColDef} from "@mui/x-data-grid"

import {CompanyType} from "../../../../../types/company"
import {ActionInfo, BulletinActionCode,} from "../../../../../types/action"
import { goToSingleBulletin } from "../../../../../util/routes"
import {formatMoney, formatDate, formatPercent} from '../../../../../libs/format'
import BulletinAction from "../../../../../components/BulletinAction"
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'


export const BulletinListColumns = (
  projectId: string,
  companyType: CompanyType,
  actionIdToInfo: {[key: number]: ActionInfo<BulletinActionCode>},
  t: any,
): GridColDef[] => {

  return [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "pco_number",
      headerName: "#",
      type: 'string',
      align: "left",
      headerAlign: "left",
      width: 170,
      editable: false,
    },
    {
      align: "left",
      field: "title",
      headerAlign: "left",
      headerName: t("view.Bulletins.BulletinList.titleColumnHeader"),
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <FormatTableTitleLink
            url={goToSingleBulletin(projectId, params.row.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      align: "left",
      headerAlign: "center",
      field: "bulletin_action_id",
      headerName: t("view.Bulletins.BulletinList.actionColumnHeader"),
      width: 180,
      editable: false,
      renderCell: (params: any) => {
        return <BulletinAction
          code={actionIdToInfo[params.row.bulletin_action_id].code}
        />
      },
      valueGetter: (params: any) => actionIdToInfo[params.value].name,
      type: 'string'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount_approved",
      headerName: t("view.Bulletins.BulletinList.totalApprovedColumnHeader"),
      width: 150,
      editable: false,
      type: 'number',
      renderCell: (params: any) => {
        return formatMoney(params.value ?? 0)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_actual_amount",
      headerName: t("view.Bulletins.BulletinList.totalActualColumnHeader"),
      width: 150,
      editable: false,
      type: 'number',
      renderCell: (params: any) => {
        return formatMoney(params.value ?? 0)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "actual_perc_approved",
      headerName: t("view.Bulletins.BulletinList.actualPercColumnHeader"),
      width: 120,
      editable: false,
      type: 'number',
      renderCell: (params: any) => {
        return params.value == null
          ? '-'
          : formatPercent(params.value)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_modified",
      headerName: t("view.Tickets.modified"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
    {
      field: "author",
      headerName: t("view.Tickets.inbox"),
      type: "string",
      renderCell: (params: any) => params.value,
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 122,
    },
  ]
}
