import { useState } from "react"
import Grid from "@mui/material/Grid"

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../../../components/Pocket"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import { getCompany } from "../../../../../../store/features/companySlice"
import { SendRfpToSubs } from "./SendRfpToSubs"
import { ActionInfo, BulletinActionCode } from "../../../../../../types/action"
import { ShareBulletinButton } from "./ShareBulletinButton"
import ShareBulletinForm from "./ShareBulletinForm"
import {NoLongerNeeded} from './NoLongerNeeded'
import {NotifyToProceed} from './NotifyToProceed'

type FormName = "share" | null

interface BulletinReviewButtonsProps {
  bulletinId: string,
  projectId: string,
  bulletinActionInfo: ActionInfo<BulletinActionCode>,
  bulletinActionChange: (new_action_id: number) => void,
}

export default function BulletinReviewButtons(
  {bulletinId, projectId, bulletinActionInfo, bulletinActionChange}: BulletinReviewButtonsProps
): JSX.Element {
  const company = useTracFloSelector(getCompany)
  const project = useTracFloSelector((state) => state.project)
  const [showThisForm, setShowThisForm] = useState<FormName>(null)

  const toggleBulletinReviewForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  return (
    <>
      <Container removeTop>
        <Grid container spacing={2}>
          <SendRfpToSubs
            companyType={company.company_type}
            projectUserRole={project.project_user_role}
            bulletinActionCode={bulletinActionInfo.code}
            bulletinId={bulletinId}
            projectId={projectId}
            bulletinActionChange={bulletinActionChange}
            togglePocketForm={() => toggleBulletinReviewForm(null)}
          />

          <NotifyToProceed
            companyType={company.company_type}
            projectUserRole={project.project_user_role}
            bulletinActionCode={bulletinActionInfo.code}
            bulletinId={bulletinId}
            projectId={projectId}
            bulletinActionChange={bulletinActionChange}
            togglePocketForm={() => toggleBulletinReviewForm(null)}
          />

          <NoLongerNeeded
            companyType={company.company_type}
            projectUserRole={project.project_user_role}
            bulletinActionCode={bulletinActionInfo.code}
            bulletinId={bulletinId}
            projectId={projectId}
            bulletinActionChange={bulletinActionChange}
            togglePocketForm={() => toggleBulletinReviewForm(null)}
          />

          <ShareBulletinButton togglePocketForm={() => toggleBulletinReviewForm('share')}/>

        </Grid>
      </Container>

      <Pocket show={showThisForm === 'share'}>
        <ShareBulletinForm
          projectId={projectId}
          bulletinId={bulletinId}
          toggleFormOpen={() => toggleBulletinReviewForm("share")}
        />
      </Pocket>
    </>
  )
}

