import React, {useMemo} from "react"
import {TFunction, useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {
  ActionInfo,
  ChangeOrderActionCode, getChangeOrderActionLabel,
} from '../../../../../types/action'
import {CompanyType} from '../../../../../types/company'
import {convertToNumber} from '../../../../../util/number'
import {getChangeOrderActionFill} from '../../../../../style/action'
import LogStatusChart from '../../../../../components/LogStatusChart'
import {StatusChartData} from '../../../../../components/LogStatusChart/type'
import {ChangeOrder} from '../../../../../types/changeOrder'


function createCoStatusChartMapping(
  t: TFunction<'private'>,
  actionMap: {[p: number | string]: ActionInfo<ChangeOrderActionCode>},
  companyType: CompanyType
): {[status: string]: StatusChartData} {
  // for each action, prep a mapping that helps us create chart data quickly
  return Object.keys(actionMap).reduce<{[status: string]: StatusChartData}>(
    // NOTE: actionId is a number
    (statusMap, action_id) => {
      const statusLabel = getChangeOrderActionLabel(t, actionMap[action_id].code, companyType)
      const statusFill = getChangeOrderActionFill(actionMap[action_id].code)
      statusMap[statusLabel] = {
        status: statusLabel,
        count: 0,
        cost: 0,
        label: statusLabel,
        fill: statusFill,
        id: action_id
      }
      return statusMap
    },
    {}
  )
}

function addCoToStatusChartMapping(
  // updated in place
  statusMap: {[status: string]: StatusChartData},
  changeOrder: ChangeOrder,
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<ChangeOrderActionCode>},
  companyType: CompanyType
): void {
  const coStatusLabel = getChangeOrderActionLabel(
    t,
    actionMap[changeOrder.change_order_action_id].code,
    companyType,
  )
  statusMap[coStatusLabel].count += 1
  statusMap[coStatusLabel].cost += convertToNumber(changeOrder.total_amount) ?? 0
}

function convertCosToCoStatusChart(
  changeOrders: ChangeOrder[],
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<ChangeOrderActionCode>},
  companyType: CompanyType
): StatusChartData[] {
  const statusChartMap = createCoStatusChartMapping(t, actionMap, companyType)
  for (let changeOrder of changeOrders) {
    addCoToStatusChartMapping(statusChartMap, changeOrder, t, actionMap, companyType)
  }
  // just trying to get similar statuses near one another in the chart
  return Object.values(statusChartMap).sort((cd1, cd2) => cd1.id > cd2.id ? 1 : 0)
}

export default function ChangeOrderListStatusChart(): JSX.Element {
  const company = useTracFloSelector((state) => state.company)
  const changeOrders = useTracFloSelector((state) => state.changeOrders.items)
  const actionMap = useTracFloSelector((state) => state.changeOrderAction.idObjectMapping)
  const { t } = useTranslation("private")

  const statusData: StatusChartData[] = useMemo(() => {
    return convertCosToCoStatusChart(changeOrders, t, actionMap, company.company_type)
  }, [changeOrders, t, actionMap, company.company_type])

  return (
    <LogStatusChart statusData={statusData}/>
  )
}
