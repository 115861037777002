import React from "react"
import { Switch } from "react-router-dom"

import AdminRouteGuard from "./Admin.RouteGuard"
import AdminLayout from "./Admin.layout"
import AdminCompanies from "./Companies"
import AdminUsers from "./Users"
import AdminSingleCompanyView from "./SingleCompany"
import AdminSingleProjectInCompanyView from "./SingleProjectInCompany"
import AdminDashboardView from "./AdminDashboard"

export default function AdminRouter() {
  return (
    <AdminLayout>
      <Switch>
        <AdminRouteGuard
          path="/0/admin/users"
          Component={AdminUsers}
        />
        <AdminRouteGuard
          path="/0/admin/companies/:company_id/project/:project_id"
          Component={AdminSingleProjectInCompanyView}
        />
        <AdminRouteGuard
          path="/0/admin/companies/:company_id"
          Component={AdminSingleCompanyView}
        />
        <AdminRouteGuard
          path="/0/admin/companies"
          Component={AdminCompanies}
        />
        <AdminRouteGuard
          path="/0/admin/dashboard"
          Component={AdminDashboardView}
        />
      </Switch>
    </AdminLayout>
  )
}