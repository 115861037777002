import React, {useState, useEffect} from "react"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"

//@ts-ignore
import Container from "../components/Container"
//@ts-ignore
import FormWideContainer from "../components/FormWideContainer"
import BreakdownTable from "../components/BreakdownTable"
//@ts-ignore
import MarkupColumns from "../libs/tableColumns/markup"
import AddChangeOrderMarkup from "./AddChangeOrderMarkup"
import {handleFieldChange} from '../util/form'

interface ChangeOrderMarkupProps {
  setFieldValue: (field: string, value: any,) => void,
  markup: any
  subtotal: number
}

function handleDeleteRow(setData: (field: string, value: any,) => void, value: any) {
  return (rowId: any) => {
    setData(`markup`, value.filter((row: any, index: any) => index !== rowId - 1))
  }
}

export default function ChangeOrderMarkup(props: ChangeOrderMarkupProps) {
  const { t } = useTranslation("private")
  const [markups, setMarkups] = useState(props.markup)
  const [totals, setTotals] = useState({})
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns(
      MarkupColumns({
        handleDeleteRow: handleDeleteRow(props.setFieldValue, props.markup),
        editable: true,
        handleFieldChanges: handleFieldChange(props.setFieldValue, props.markup, `markup`),
        t
      })
    )
    let markupSubtotal = 0
    setMarkups(
      props.markup.map((row: any) => {
        const rowTotal = (row.amount / 100) * props.subtotal
          markupSubtotal += rowTotal
          return {
            ...row,
            total: rowTotal,
          }
      })
    )
    setTotals({ total: markupSubtotal })
  }, [props.subtotal, props.markup, props.setFieldValue, t])

  return (
    <Container removeTop>
      <FormWideContainer>
        <Typography variant="h2">{t("view.ChangeOrder.markupTitle")}</Typography>
          <Grid item xs={12} container justifyContent="flex-end">
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                  <BreakdownTable columns={columns} editable rows={markups} totals={totals} />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <Typography variant="h4" style={{ margin: "20px 0 10px" }}>
                  {t("view.ChangeOrder.markup")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AddChangeOrderMarkup setData={props.setFieldValue} markup={props.markup}/>
              </Grid>
            </Grid>
          </Grid>
      </FormWideContainer>
    </Container>
  )
}
