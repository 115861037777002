import { StoreBase } from "./storeBase"
import {LoadingStatus} from "./status"
import { ExpirationField } from "./expiration"


export interface Bulletin {
  id: string,
  pco_number?: string,
  description: string,
  title: string,
  total_amount_approved?: string | number,
  date_created: string,
  date_modified: string,
  notes?: string,
  bulletin_action_id: number,
  inbox_first_name: string,
  inbox_last_name: string,
  author: string,
  date_estimates_due?: string,
  is_closed: boolean,
  total_actual_amount?: string | number 
}

export type BulletinsStore = {items: Bulletin[], projectId: string | null} & StoreBase<LoadingStatus, ExpirationField>

export const bulletinActionIdEstimatesFinalized = 3
export const bulletinActionIdTmAuthorized = 4
export const bulletinActionIdForNotNeeded = 5

export type BulletinReviewProcessType = "share" | "send_rfps" | "no_longer_needed" | "notify_to_proceed"

export interface BulletinReviewProcess {
  id: string,
  bulletin_id: string,
  old_bulletin_action_id: number,
  new_bulletin_action_id: number,
  message: string | null,
  bulletin_review_process_type: BulletinReviewProcessType,
  created_by: string,
  date_created: string,
}
