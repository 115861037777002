import React from "react"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"

// @ts-ignore
import Button from "./Button"
// @ts-ignore
import Container from "./Container"
// @ts-ignore
import FormWideContainer from "./FormWideContainer"

interface FormWideButtonsCancel {
  text: string,
  action: (e: any) => void,
}

interface FormWideButtonsSubmit {
  text: string,
  icon?: any,
  disabled: boolean,
  isSubmitting: boolean,
  onClick?: () => void,
}

interface FormWideButtonsProps {
  cancel?: FormWideButtonsCancel,
  submit?: FormWideButtonsSubmit,
}

export default function FormWideButtons(props: FormWideButtonsProps) {
  const { cancel, submit} = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Container removeTop>
      <FormWideContainer>
        <Grid container spacing={3}>
          {cancel ? (
            <Grid item md={6} container justifyContent="flex-start">
              <Button onClick={cancel.action} color="secondary" variant="text">
                {cancel.text}
              </Button>
            </Grid>
          ) : (
            <></>
          )}

          {submit ? (
            <Grid item md={6} container justifyContent={isMobile ? "flex-start" : "flex-end"}>
              <Button
                disabled={submit.disabled}
                isLoading={submit.isSubmitting}
                size="large"
                type="submit"
                endIcon={submit.icon || <AddIcon />}
                onClick={submit.onClick}
              >
                {submit.text}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </FormWideContainer>
    </Container>
  )
}
