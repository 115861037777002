import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {
  notifyForCostRevise,
  submitCostRevise,
  uploadTicketReviewProcessFiles,
} from "../../../../../../../api/ticketReviewProcess"
import OneTextFieldAndUploadForm from '../../../../../../../forms/OneTextFieldAndUploadForm'
import {hasFilesForUpload} from '../../../../../../../util/file'



interface CostReviseFormProps {
  toggleFormOpen: () => void,
  ticketId: string,
  projectId: string,
  ticketActionChange: (new_action_id: number) => void
}

export default function CostReviseForm(props: CostReviseFormProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitForm = async (values: any): Promise<void> => {
    try {
      const {data: trp} = await submitCostRevise(props.ticketId, props.projectId, values)
      // upload files
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
        const fileUploadMessage = await uploadTicketReviewProcessFiles(values.files, props.projectId, trp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      // notify of the approval and create history object
      await notifyForCostRevise(trp.id, props.projectId)
      if (trp?.new_action_id) {
        props.ticketActionChange(trp.new_action_id)
      }
      props.toggleFormOpen()
      enqueueSnackbar(t('view.Tickets.cost_revise_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.cost_revise_fail'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <OneTextFieldAndUploadForm
        onCancel={props.toggleFormOpen}
        submitForm={submitForm}
        titleText={t('view.Tickets.cost_revise_title')}
        textFieldRequired={true}
      />
    </FormSmallContainer>
  )
}
