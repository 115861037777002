import React from "react"
import makeStyles from "@mui/styles/makeStyles"

import SiteHeader from "./TopNav"
// @ts-ignore
import SiteHeaderSpacer from "../../components/Layout/SiteHeaderSpacer"

const useStyles = makeStyles(() => {
  return {
    root: {
      display: "flex",
    },
    contentWrap: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      overflow: "hidden",
    },
  }
})

interface AccountsLayoutProps {
  topTitle?: string,
  bottomTitle?: string,
  children: any,
}

export default function Layout(props: AccountsLayoutProps) {
  const { children, topTitle, bottomTitle } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SiteHeader simple topTitle={topTitle ?? ''} bottomTitle={bottomTitle ?? ''} />
      <div className={classes.contentWrap}>
        <SiteHeaderSpacer />
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  )
}
