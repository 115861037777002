import React from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// @ts-ignore
import Container from "../../../../components/Container"
import CompaniesCreatedInLastSixtyDaysView from "./CompaniesCreatedInLastSixtyDays"
import MonthlyTicketsReportView from "./MonthlyTicketsReport"
import ProjectsCreatedInLastSixtyDaysView from "./ProjectsCreatedInLastSixtyDays"
import MonthlyCOsReportView from "./MonthlyCOsReport"


export default function AdminDashboardView() {
  const { t } = useTranslation("private")

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">
              {t("view.Admin.adminDashboardTitle")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container removeTop>
        <ProjectsCreatedInLastSixtyDaysView/>
      </Container>
      <Container removeTop>
        <CompaniesCreatedInLastSixtyDaysView/>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">
              {t("view.Admin.adminMetricsTitle")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container removeTop>
        <MonthlyTicketsReportView/>
      </Container>
      <Container removeTop>
        <MonthlyCOsReportView/>
      </Container>
    </>
  )
}