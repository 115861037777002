import { convertToNumber } from "./number"
import {TicketMarkup} from "../types/ticket"

export function calculateTotalWithMarkups(breakdownTypeTotal: number, markup: TicketMarkup[]): number {
  return markup.reduce(
    (total: number, m) => {
      return total + breakdownTypeTotal * (convertToNumber(m.percent) ?? 0) / 100
    },
    breakdownTypeTotal
  )
}

export function calculateTotalWithMarkupsInForm(subtotal: number, markup: {amount: number | string}[]): number {
  return markup.reduce(
    (total: number, m) => {
      return total + subtotal * (convertToNumber(m.amount) ?? 0) / 100
    },
    subtotal
  )
}
