import {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'

import { 
  EstimateMarkup as EstimateMarkupType, 
  getEstimateMarkup 
} from '../../../../../../api/estimate'
import EstimateMarkupTable from '../../../../../../components/EstimateMarkupTable'


interface EstimateMarkupProps {
  projectId: string,
  estimateId: string,
  subtotal: number,
}

export default function EstimateMarkup(
  {projectId, estimateId, subtotal}: EstimateMarkupProps
): JSX.Element {
  const [markup, setMarkup] = useState<EstimateMarkupType[]>([])

  useEffect(() => {
    getEstimateMarkup(projectId, estimateId).then((res) => {
      if (res.status === 200 && Array.isArray(res.data)) {
        setMarkup(res.data)
      }
    })
  }, [projectId, estimateId])

  return markup.length 
    ? <Grid container justifyContent="flex-end">
      <Grid item xs={6}>
        <EstimateMarkupTable
          editable={false}
          markup={markup}
          subtotal={subtotal}
        />
      </Grid>
    </Grid>
    : <></>
}
