// @ts-ignore
import api from "../libs/api"
import { uploadFiles, uploadSignature } from "./file"

export function submitChangeOrderForReview(projectId: string, changeOrderId: string, message: string): Promise<any> {
  return api.post(`/project/${projectId}/change_order/${changeOrderId}/submit_for_review`, {message})
}

export async function submitChangeOrderBackToDraft(projectId: string, changeOrderId: string): Promise<any> {
  return api.post(`/project/${projectId}/change_order/${changeOrderId}/back_to_draft`)
}

export async function submitChangeOrderForApprove(
  changeOrderId: string,
  projectId: string,
  message: string | null
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/change_order/${changeOrderId}/submit_for_approve`,
    data: message === null ? {} : {message}
  })
}

export async function submitChangeOrderForRevise(projectId: string, changeOrderId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/change_order/${changeOrderId}/submit_for_revise`,
    data
  })
}

export function submitChangeOrderForShare(projectId: string, changeOrderId: string, message: string): Promise<any> {
  return api.post(`/project/${projectId}/change_order/${changeOrderId}/submit_for_share`, {message})
}

export async function uploadChangeOrderReviewProcessFiles(
  files: any[],
  project_id: string,
  corp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, corp_id, 'change_order_review_process')
}

export function notifyForCoReview(project_id: string, corp_id: string, idsToEmail: string[]): Promise<any> {
  return api.post(`/project/${project_id}/change_order_review_process/${corp_id}/notify_for_review`, {idsToEmail})
}

export async function notifyForCoApprove(corpId: string, projectId: string): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/change_order_review_process/${corpId}/notify_for_approve`,
  })
}

export async function notifyForCoRevise(corpId: string, projectId: string): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/change_order_review_process/${corpId}/notify_for_revise`,
  })
}

export function notifyForCoShare(corpId: string, projectId: string, idsToEmail: string[]): Promise<any> {
  return api.post(`/project/${projectId}/change_order_review_process/${corpId}/share`, {idsToEmail})
}

export async function uploadChangeOrderReviewProcessSignature(
  signature: Blob,
  project_id: string,
  corp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadSignature(signature, project_id, corp_id, 'change_order_review_process')
}