import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"
import { Estimate, EstimatesStore } from "../../types/estimate"
import {apiHeaders} from '../../api/util'

const initialState: EstimatesStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadEstimates = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadEstimates",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/estimate`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const estimatesSlice = createSlice({
  name: "estimates",
  initialState,
  reducers: {
    addEstimate: (state, action: {payload: Estimate}) => {
      state.items.unshift(action.payload)
    },
    resetEstimates: () => initialState,
    updateEstimate: (state, action: {payload: Estimate}) => {
      if (action.payload) {
        const updatedEstimate = action.payload
        updatedEstimate.date_modified = (new Date()).toISOString()
        state.items = [updatedEstimate, ...state.items.filter((b) => b.id !== updatedEstimate.id)]
      }
    },
    updateEstimateAction: (state, action: {payload: {new_action_id: number, estimate_id: string}}) => {
      const updatedEstimate = state.items.find((co) => co.id === action.payload.estimate_id)
      if (updatedEstimate != null) {
        updatedEstimate.estimate_action_id = action.payload.new_action_id
        updatedEstimate.date_modified = (new Date()).toISOString()
        // remove the estimate
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.estimate_id),
          1
        )
        // add it back in at the front
        state.items = [updatedEstimate, ...state.items]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEstimates.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadEstimates.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadEstimates.fulfilled, (state, action: {payload: {data: Estimate[], projectId: string}}) => {
        if (action.payload && action.payload.data) {
          state.items =  action.payload.data.filter((row) => row.id).map((e) => {
            return {
              ...e,
              // need to create author from data sent
              author: `${e.inbox_first_name} ${e.inbox_last_name}`
            }
          })
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
  },
})

export const { updateEstimate, resetEstimates, updateEstimateAction, addEstimate } = estimatesSlice.actions

export const listEstimates = (state: TracFloState) => state.estimates.items

export const reloadEstimatesIfInvalid = (
  projectId: string,
  estimatesProjectId: string | null,
  estimatesExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== estimatesProjectId || reduxStateExpired(estimatesExpiry)) {
    dispatch(loadEstimates())
  }
}

export default estimatesSlice.reducer
