import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Typography, Menu, MenuItem} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import theme from '../theme'
import {useTracFloSelector} from '../store/useTracFloSelector'
import {getCustomCodes, getProjectCustomCodes} from '../store/features/customCodeSlice'
import {projectNotInitialized} from '../store/features/projectSlice'
import {CustomCode} from '../types/customCode'


type AttachableCode = {
  code: string,
  description: string,
  id: string,
}

type AttachedCodeTagProps = {
  code: AttachableCode,
  removeCode: () => void,
}

function AttachedCodeTag(
  {
    code,
    removeCode
  }: AttachedCodeTagProps
): JSX.Element {
  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.dark,
        gridTemplateColumns: 'max-content 22px',
        display: 'grid',
        width: 'max-content',
        height: '20px',
        borderRadius: '6px',
        marginRight: '5px'
      }}
    >
      <div style={{gridColumn: '1', width: 'fit-content'}}>
        <Typography
          variant={'h5'}
          style={{
            color: '#FFFFFF',
            marginTop: '2px',
            marginBottom: '0',
            marginLeft: '4px',
            fontWeight: '800',
            fontSize: '12px'
          }}
        >
          {code.code}
        </Typography>
      </div>
      <div style={{gridColumn: '2', height: '14px'}}>
        <CloseIcon
          sx={{color: '#FFFFFF', marginLeft: '5px', cursor: 'pointer', fontSize: 12, marginTop: '0', marginRight: '2px'}}
          onClick={removeCode}
        />
      </div>
    </div>
  )
}

type AttachCodeProps = {
  ccIds: string[],
  attachNewCode: (ccId: string) => void,
  removeCode: (ccId: string) => void,
}

export function AttachCode(
  {ccIds, attachNewCode, removeCode}: AttachCodeProps
): JSX.Element {
  const project = useTracFloSelector((state) => state.project)
  const allCodes = useTracFloSelector(getCustomCodes)
  const { t } = useTranslation("private")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const projectCodes = useMemo<CustomCode[]>(() => {
    if (projectNotInitialized(project)) return []
    return getProjectCustomCodes(allCodes, project.id)
  }, [allCodes, project])

  const attachableCodes = useMemo<AttachableCode[]>(() => {
    return projectCodes.filter((cc) => !ccIds.includes(cc.id))
  }, [projectCodes, ccIds])

  const attachedCodes = useMemo<AttachableCode[]>(() => {
    return projectCodes.filter((cc) => ccIds.includes(cc.id))
  }, [projectCodes, ccIds])

  const handleOpen = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const selectNewCode = (event: any, code: AttachableCode) => {
    handleClose(event)
    attachNewCode(code.id)
  }

  const allowedToEditAttachableCodes = ['pm', 'super'].includes(project.project_user_role)

  const pickNewCode = allowedToEditAttachableCodes && attachableCodes.length > 0
    ? ccIds.length === 0
      // show text instructions when no current codes
      ? <Typography
        color="secondary"
        display="inline"
        style={{
          textDecorationColor: "#40C4FF",
          textDecoration: 'underline',
          cursor: 'pointer',
          fontWeight: 700
        }}
        onClick={handleOpen}
      >
        {t('component.AttachCode.addCode')}
      </Typography>
      // small edit icon if we have at least one code
      : <AddIcon
        sx={{color: theme.palette.secondary.main, marginLeft: '5px', cursor: 'pointer', fontSize: 24}}
        onClick={handleOpen}
      />
    // if not allowed to add or nothing to add, don't display
    : <></>

  const allowedToViewAttachableCodes = ['pm', 'super'].includes(project.project_user_role)

  // if no codes in project, don't show this section
  return allowedToViewAttachableCodes && projectCodes.length > 0
    ? <div style={{display: 'flex'}}>
      <Typography style={{marginRight: '5px'}}>
        <strong>{t("component.AttachCode.rowTitle")}</strong>
      </Typography>
      {
        attachedCodes.map((ac) =>
          <AttachedCodeTag code={ac} removeCode={() => {removeCode(ac.id)}} key={ac.id} />
        )
      }

      {pickNewCode}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {attachableCodes.map((e) => (
          <MenuItem onClick={(event) => selectNewCode(event, e)}>
            {`${e.code}: ${e.description}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
    : <></>
}
