import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Theme } from "@mui/material"

import {EstimateActionCode, getEstimateActionLabel} from "../types/action"
import {getEstimateActionFill} from '../style/action'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  pending: {
    background: getEstimateActionFill('pending'),
  },
  draft: {
    background: getEstimateActionFill('draft'),
  },
  submitted: {
    background: getEstimateActionFill('submitted'),
  },
  revise: {
    background: getEstimateActionFill('revise'),
  },
  gc_approved: {
    background: getEstimateActionFill('gc_approved'),
  },
  tm_authorized: {
    background: getEstimateActionFill('tm_authorized'),
  },
  not_needed: {
    background: getEstimateActionFill('not_needed'),
    border: "solid 2px",
    borderColor: "#516487",
    color: "#687997",
  },
}))

interface EstimateActionProps {
  code: EstimateActionCode
}

export default function EstimateAction({code}: EstimateActionProps) {
  const { t } = useTranslation("private")
  const classes = useStyles()
  const className = code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{getEstimateActionLabel(t, code)}</Typography>
    </Box>
  )
}
