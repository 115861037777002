import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// @ts-ignore
import Button from "../../../../components/Button"
import PasswordField, {passwordValidation} from "../../../../components/Fields/Password"
import {UserForAdmin} from '../../../../api/admin'
import {makeUserName} from '../../../../util/user'


export type ResetUserPasswordFormProps = {
  user: UserForAdmin | null,
  submit: (newPassword: string) => Promise<void>,
  closeForm: () => void,
}

export default function ResetUserPasswordForm({submit, user, closeForm}: ResetUserPasswordFormProps): JSX.Element {
  const { t: tPrivate } = useTranslation("private")
  const { t } = useTranslation("public")
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return user == null
    ? <></>
    : (
      <Formik
        initialValues={{password: "",}}
        validationSchema={Yup.object().shape({password: passwordValidation(t),})}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          await submit(values.password)
          setSubmitting(false)
          closeForm()
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            isValid,
            touched,
            values
        }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography sx={{textAlign: "center",}}>
                    {tPrivate('view.Admin.resetUserPasswordFormInstructions', {userName: makeUserName(user)})}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PasswordField
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    label={t("form.label.password")}
                    new={false}
                  />
                </Grid>
                <Grid container justifyContent={isMobile ? "center" : "flex-end"} item xs={12}>
                  <Button disabled={!isValid} isLoading={isSubmitting} type="submit">
                    {t("view.newPassword.resetPassword")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
}
