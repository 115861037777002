import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"
import {uploadFiles, uploadSignature} from './file'


interface ReviewProcessSubmit {
  id: string,
  new_estimate_action_id: number,
}

interface ReviewProcessApprove extends ReviewProcessSubmit {
  bulletin_is_estimate_approved: boolean
}

export async function uploadEstimateReviewProcessFiles(
  files: any[],
  project_id: string,
  erp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, erp_id, 'estimate_review_process')
}

export async function submitEstimateForRevise(
  projectId: string,
  estimateId: string,
  data: any
): Promise<AxiosResponse<ReviewProcessSubmit>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate/${estimateId}/submit_for_revise`,
    data
  })
}

export async function notifyForEstimateRevise(projectId: string, erpId: string): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate_review_process/${erpId}/notify_for_revise`,
  })
}

export async function submitEstimateForReview(
  projectId: string,
  estimateId: string,
  data: {message: string}
): Promise<AxiosResponse<ReviewProcessSubmit>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate/${estimateId}/submit_for_review`,
    data
  })
}

export async function notifyForEstimateReview(
  projectId: string,
  erpId: string,
  idsToEmail: string[]
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate_review_process/${erpId}/notify_for_review`,
    data: {idsToEmail}
  })
}

export async function submitEstimateForApprove(
  projectId: string,
  estimateId: string,
  data: {message?: string}
): Promise<AxiosResponse<ReviewProcessApprove>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate/${estimateId}/submit_for_approve`,
    data
  })
}

export async function notifyForEstimateApprove(
  projectId: string,
  erpId: string,
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/estimate_review_process/${erpId}/notify_for_approve`,
  })
}

export async function uploadEstimateReviewProcessSignature(
  signature: Blob,
  project_id: string,
  erp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadSignature(signature, project_id, erp_id, 'estimate_review_process')
}
