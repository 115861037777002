export interface CreateChangeOrderInitialValues {
  date_end: string | null,
  date_start: string,
  exclusion: string,
  files: any[],
  invoice_number: string,
  is_lump_sum: boolean,
  lump_sum_total: number|string,
  markup: any[],
  number: string,
  notes: string,
  pco_number: string,
  scope: string,
  subject: string,
  ticketIds: any[],
}

export function defaultCreateChangeOrderInitialValues(): CreateChangeOrderInitialValues {
  return {
    date_end: null,
    date_start: (new Date()).toDateString(),
    exclusion: "",
    files: [{name: "",},],
    invoice_number: "",
    is_lump_sum: false,
    lump_sum_total: "",
    markup: [],
    number: "",
    notes: "",
    pco_number: "",
    scope: "",
    subject: "",
    ticketIds: [],
  }
}

export interface CreateChangeOrderOutputForApi {
  date_end: string | null,
  date_start: string,
  exclusion: string,
  invoice_number: string,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  markup: any[],
  number: string|null,
  notes: string,
  pco_number: string,
  scope: string,
  subject: string,
}