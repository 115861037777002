import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'
import {GridColDef} from '@mui/x-data-grid'

// @ts-ignore
import DtRowTitle from "../../../../../components/DtRowTitle"
import theme from '../../../../../theme'
import templateUrls from '../../../../../util/csv/templateUrls'
import {convertCsvStringToMaterialArray} from './ImportMaterial.data'
import {createMultipleMaterialTypes} from '../../../../../api/material'
import {addMultipleMaterials} from '../../../../../store/features/materialSlice'
import CsvImport, {CsvImportUtils, ImportInstructionsUtils} from '../../../../../components/CsvImport'
import {DisplayImportedObjectsUtils} from '../../../../../components/CsvImportDisplayObjects'
import {convertToNumber} from '../../../../../util/number'
import {formatMoney} from '../../../../../libs/format'


interface MaterialForUpload {
  name: string,
  unit: string,
  rate: string,
  cost_code: string,
}

interface ImportMaterialProps {
  onClose: () => void
}

const displayColumns: GridColDef[] = [
  {
    align: "left",
    headerAlign: "left",
    field: "name",
    headerName: "Description",
    flex: 1,
    minWidth: 180,
    renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "cost_code",
    headerName: "Cost Code",
    width: 100,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "Rate",
    width: 100,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "unit",
    headerName: "Unit",
    width: 100,
    editable: false,
  },
]

const translateComponents: JSX.Element[] = [
  <a
    style={{
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      textDecoration: "underline",
      // @ts-ignore
      "&:hover": {
        cursor: "pointer",
      },
    }}
    href={templateUrls.materialTypes}
    download={true}
  />
]

const importUtils: CsvImportUtils<MaterialForUpload> = {
  convertCsvStringToObjectArray: convertCsvStringToMaterialArray
}

const displayUtils: DisplayImportedObjectsUtils<MaterialForUpload> = {
  columns: displayColumns,
  addIdToObject: (m, i) => ({...m, id: `${m.name}||${m.rate}||${i}`}),
  titleTranslateKey: 'view.Settings.ImportMaterialDialog.uploadedMaterialsTitle',
}

const instructionUtils: ImportInstructionsUtils = {
  translateKey: "view.Settings.ImportMaterialDialog.instructions",
  translateComponents,
}

export default function ImportMaterial({onClose}: ImportMaterialProps): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const submitImportedMaterials = async (materialsForUpload: MaterialForUpload[]) => {
    if (materialsForUpload.length > 0) {
      try {
        const res = await createMultipleMaterialTypes(materialsForUpload)
        if (res.status === 200 && Array.isArray(res.data)) {
          dispatch(addMultipleMaterials(res.data))
          enqueueSnackbar(t('view.Settings.ImportMaterialDialog.submitSuccess'), {variant: 'success'})
        } else {
          enqueueSnackbar(t('view.Settings.ImportMaterialDialog.submitFailed'), {variant: 'error'})
        }
      } catch (e: any) {
        enqueueSnackbar(t('view.Settings.ImportMaterialDialog.submitFailed'), {variant: 'error'})
      }
      onClose()
    }
  }

  return (
    <CsvImport<MaterialForUpload>
      onClose={onClose}
      importUtils={importUtils}
      displayUtils={displayUtils}
      instructionsUtils={instructionUtils}
      submit={submitImportedMaterials}
      submitButtonTextTranslateKey={"view.Settings.ImportMaterialDialog.submitButton"}
    />
  )
}
