import React from "react"
import { Switch } from "react-router-dom"

import ChangeOrdersListView from "../ChangeOrders/ChangeOrderList/ChangeOrdersList"
import CreateChangeOrderView from "../ChangeOrders/CreateChangeOrder"
import EditChangeOrderView from "./EditChangeOrder"
import ChangeOrderView from "../ChangeOrders/SingleChangeOrder"
import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import SubRouteGuard from "../Sub.RouteGuard"
import {goToChangeOrdersList, goToSingleChangeOrder} from "../../../../util/routes"

export default function ChangeOrdersRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToChangeOrdersList(":projectId")}>
        <ChangeOrdersListView />
      </ProjectSelectedRouteGuard>
      <SubRouteGuard
        path={"/0/project/:projectId/changeorders/add"}
        Component={CreateChangeOrderView}
        RouteGuard={ProjectSelectedRouteGuard}
      />
      <ProjectSelectedRouteGuard exact path={goToSingleChangeOrder(':projectId', ':changeOrderId')}>
        <ChangeOrderView />
      </ProjectSelectedRouteGuard>
      <SubRouteGuard
        path={"/0/project/:projectId/changeorders/:changeOrderId/edit"}
        Component={EditChangeOrderView}
        RouteGuard={ProjectSelectedRouteGuard}
      />
    </Switch>
  )
}
