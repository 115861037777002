import {
  BulletinSubcontractorForSubmit,
  CreateBulletinInitialValues,
  CreateBulletinOutputForApi,
} from "./CreateBulletin.types"
import {BulletinSubcontractorForTable} from '../components/BulletinSubsTable'


function convertAttachedSubsToApiData(sub: BulletinSubcontractorForTable): BulletinSubcontractorForSubmit {
  return {subcontractor_id: sub.subcontractor_id, sub_user_id: sub.subcontractor_user_id}
}

export function convertFormValuesToApiData(values: CreateBulletinInitialValues, nextBulletinPcoNumber: string|null): CreateBulletinOutputForApi {
  return {
    title: values.title,
    description: values.description,
    notes: values.notes.length > 0 ? values.notes : undefined,
    pco_number: values.pco_number.length > 0 ? values.pco_number : nextBulletinPcoNumber,
    date_estimates_due: (new Date(values.date_estimates_due)).toISOString(),
    attached_subs: values.attached_subs.map(convertAttachedSubsToApiData)
  }
}
