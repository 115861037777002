import {createSlice} from "@reduxjs/toolkit"
import {GridColumnVisibilityModel} from "@mui/x-data-grid"

import {TracFloState} from "../../types/state"
import {TableColumnsStore} from '../../types/tableColumns'


const initialState: TableColumnsStore = {
  tableColumns: {}
}

export const tableColumnsSlice = createSlice({
  name: "tableColumns",
  initialState,
  reducers: {
    reset: () => initialState,
    setTableColumns: (
      state: TableColumnsStore,
      action: {payload: {tableName: string, columnVisModel: GridColumnVisibilityModel}}
    ) => {
      const {tableName, columnVisModel} = action.payload
      state.tableColumns[tableName] = {
        tableName,
        columnVisModel: columnVisModel,
      }
    },
  },
})

export const {
  reset,
  setTableColumns,
} = tableColumnsSlice.actions

export default tableColumnsSlice.reducer

export const getTableColumns = (tableName: string) => {
  return (state: TracFloState) => {
    return state.tableColumns.tableColumns[tableName]
  }
}
