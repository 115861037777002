import parse from "html-react-parser"
import { decode } from "html-entities"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import SettingsIcon from "@mui/icons-material/Settings"
import CableIcon from "@mui/icons-material/Cable"
import {GridColDef} from "@mui/x-data-grid"
import {Tooltip} from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ArchiveIcon from '@mui/icons-material/Archive'

//@ts-ignore
import DtRowTitle from "../../../../components/DtRowTitle"
import { formatDate } from "../../../../libs/format"
import {
  goToBulletinList,
  goToChangeOrdersList,
  goToDailyList,
  goToEstimateList,
  goToProjectOverview,
  goToTicketsList
} from "../../../../util/routes"
import {useTracFloSelector} from "../../../../store/useTracFloSelector"
import { Company } from "../../../../types/company"
import FormatTableTitleLink from "../../../../components/FormatTableTitleLink"
import {UserIntegration} from '../../../../types/user'


type AllColumns = {
  name: GridColDef,
  number: GridColDef,
  tix: GridColDef,
  date_start: GridColDef,
  address: GridColDef,
  co: GridColDef,
  connect: GridColDef,
  settings: GridColDef,
  bulletin: GridColDef,
  estimate: GridColDef,
  daily: GridColDef,
  archive: GridColDef,
}

// Create all columns at once so that they can be injected as needed
function createAllHomeColumns(
  t: (s: string) => string,
  activeIntegration: UserIntegration | undefined,
  history: {push: (s: string) => void}, // couldn't find a good type for this
  userIsCrewOnAllProjects: boolean,
  connectToIntegration: (id: string) => void,
  company: Company,
  openConfirmCancelArchiveProjectDialog: (project_id: string) => void,
): AllColumns {
  return {
    number: {
      field: "number",
      headerName: "#", //TIX
      headerAlign: "left",
      align: "left",
      width: 80,
      editable: false,
    },
    name: {
      field: "name",
      flex: 1,
      headerName: t("view.Projects.name").toUpperCase(), //Name and Client
      minWidth: 250,
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              {params.row.project_user_role === "crew"
                ? <DtRowTitle>{decode(params.value)}</DtRowTitle>
                : <FormatTableTitleLink
                  url={goToProjectOverview(params.row.id)}
                  text={decode(params.value)}
                  openNewWindow={false}
                />
              }
            </Grid>
          </Grid>
        )
      },
    },
    tix: {
      field: "tix",
      headerName: t("view.Projects.tix").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 90,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) => (
        <FormatTableTitleLink
          url={goToTicketsList(params.row.id)}
          text={t("view.Projects.view")}
          openNewWindow={false}
        />
      ),
    },
    date_start: {
      field: "date_start",
      width: 120,
      headerName: t("view.Projects.start").toUpperCase(), //Start
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params: any) => formatDate(params.value),
    },
    address: {
      field: "address",
      flex: 1,
      headerName: t("view.Projects.address").toUpperCase(),
      minWidth: 180,
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {parse(decode(params.value))}
          </Box>
        )
      },
    },
    co: {
      field: "co",
      headerName: t("view.Projects.cos").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 90,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) =>
        params.row.project_user_role !== "crew" ? (
          <FormatTableTitleLink
            url={goToChangeOrdersList(params.row.id)}
            text={t("view.Projects.view")}
            openNewWindow={false}
          />
        ) : (
          <></>
        ),
    },
    connect: {
      field: "connect",
      headerName: '',
      headerAlign: "center",
      align: "center",
      editable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        // are they allowed to see the button at all
        params.row.project_user_role === "pm" && activeIntegration != null
          // if the project is not connected to integration
          ? activeIntegration.projects == null || activeIntegration.projects.indexOf(params.row.id) === -1
            ? (
              <Tooltip title={t("view.Projects.HomePageList.connectToIntegrationTooltip")} placement={'top'}>
                <IconButton
                  color="secondary"
                  onClick={() => {connectToIntegration(params.row)}}
                >
                  <CableIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("view.Projects.HomePageList.connectedToIntegrationTooltip")} placement={'top'}>
                {/*for some reason, tooltip doesn't show up without div here*/}
                <div>
                  <IconButton disabled={true}>
                    <CheckCircleIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )
          : <></>
      ),
    },
    settings: {
      field: "settings",
      headerName: '',
      headerAlign: "center",
      align: "center",
      width: 20,
      editable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        params.row.project_user_role !== "crew" ? (
          <Tooltip title={t("view.Projects.settings")} placement={'top'}>
            <IconButton
              color="secondary"
              onClick={() => {
                history.push(`/0/project/${params.row.id}/settings`)
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        ) : <></>
      ),
    },
    bulletin: {
      field: "bulletin",
      headerName: t("view.Projects.bulletin").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 100,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) =>
        params.row.project_user_role !== "crew" ? (
          <FormatTableTitleLink
            url={goToBulletinList(params.row.id)}
            text={t("view.Projects.view")}
            openNewWindow={false}
          />
        ) : (
          <></>
        ),
    },
    estimate: {
      field: "estimate",
      headerName: t("view.Projects.estimate").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 100,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) =>
        params.row.project_user_role !== "crew" ? (
          <FormatTableTitleLink
            url={goToEstimateList(params.row.id)}
            text={t("view.Projects.view")}
            openNewWindow={false}
          />
        ) : (
          <></>
        ),
    },
    daily: {
      field: "daily",
      headerName: t("view.Projects.daily").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 150,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) => (
        <FormatTableTitleLink
          url={goToDailyList(params.row.id)}
          text={t("view.Projects.view")}
          openNewWindow={false}
        />
      ),
    },
    archive: {
      field: "archive",
      headerName: t("view.Projects.archive"),
      editable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      renderCell: (params: any) => {
        return (
          <IconButton
            color="secondary"
            onClick={() => {
              openConfirmCancelArchiveProjectDialog(params.row.id)
            }}
          >
            <ArchiveIcon />
          </IconButton>
        )
      }
    },
  }
}

// Returning full columns here to ensure explicit clarity on order
function createTradeColumns(
  allColumns: AllColumns,
  activeIntegration: UserIntegration | undefined,
  userIsCrewOnAllProjects: boolean,
  company: Company,
  userIsPmOnAnyProject: boolean,
): GridColDef[] {
  if (userIsCrewOnAllProjects) {
    return [
      allColumns.number,
      allColumns.name,
      allColumns.tix,
      allColumns.daily,
      allColumns.date_start,
      allColumns.address,
    ]
  }
  if (userIsPmOnAnyProject && activeIntegration != null) {
    if (company.company_user_role === 'admin') {
      return [
        allColumns.number,
        allColumns.name,
        allColumns.estimate,
        allColumns.co,
        allColumns.tix,
        allColumns.daily,
        allColumns.date_start,
        allColumns.address,
        allColumns.connect,
        allColumns.settings,
        allColumns.archive,
      ]
    } else {
      return [
        allColumns.number,
        allColumns.name,
        allColumns.estimate,
        allColumns.co,
        allColumns.tix,
        allColumns.daily,
        allColumns.date_start,
        allColumns.address,
        allColumns.connect,
        allColumns.settings,
      ]
    }
  }
  if (company.company_user_role === 'admin') {
    return [
      allColumns.number,
      allColumns.name,
      allColumns.estimate,
      allColumns.co,
      allColumns.tix,
      allColumns.daily,
      allColumns.date_start,
      allColumns.address,
      allColumns.settings,
      allColumns.archive,
    ]
  }
  return [
    allColumns.number,
    allColumns.name,
    allColumns.estimate,
    allColumns.co,
    allColumns.tix,
    allColumns.daily,
    allColumns.date_start,
    allColumns.address,
    allColumns.settings,
  ]
}

function createGcColumns(
  allColumns: AllColumns,
  activeIntegration: UserIntegration | undefined,
  company: Company,
  userIsPmOnAnyProject: boolean,
): GridColDef[] {
  if (userIsPmOnAnyProject && activeIntegration != null) {
    if (company.company_user_role === 'admin') {
      return [
        allColumns.number,
        allColumns.name,
        allColumns.bulletin,
        allColumns.estimate,
        allColumns.co,
        allColumns.tix,
        allColumns.date_start,
        allColumns.address,
        allColumns.connect,
        allColumns.settings,
        allColumns.archive,
      ]
    } else {
      return [
        allColumns.number,
        allColumns.name,
        allColumns.bulletin,
        allColumns.estimate,
        allColumns.co,
        allColumns.tix,
        allColumns.date_start,
        allColumns.address,
        allColumns.connect,
        allColumns.settings,
      ]
    }
  }
  if (company.company_user_role === 'admin') {
    return [
      allColumns.number,
      allColumns.name,
      allColumns.bulletin,
      allColumns.estimate,
      allColumns.co,
      allColumns.tix,
      allColumns.date_start,
      allColumns.address,
      allColumns.settings,
      allColumns.archive,
    ]
  }
  return [
    allColumns.number,
    allColumns.name,
    allColumns.bulletin,
    allColumns.estimate,
    allColumns.co,
    allColumns.tix,
    allColumns.date_start,
    allColumns.address,
    allColumns.settings,
  ]
}

export const HomeColumns = (
  userIsCrewOnAllProjects: boolean,
  connectToIntegration: (id: string) => void,
  company: Company,
  openConfirmCancelArchiveProjectDialog: (project_id: string) => void,
  userIsPmOnAnyProject: boolean
): GridColDef[] => {
  const { t } = useTranslation("private")
  const history = useHistory()
  const activeIntegration = useTracFloSelector((s) => s.user.activeIntegration)

  const allColumns = createAllHomeColumns(
    t,
    activeIntegration,
    history,
    userIsCrewOnAllProjects,
    connectToIntegration,
    company,
    openConfirmCancelArchiveProjectDialog
  )

  // NOTE: we have had many bugs related to home page column order due to the complexity of the logic.
  // To address this, we have broken out the column order explicitly for each individual case -- this adds more
  // work to maintain, but makes the complexity clearer and prevents reordering columns accidentally
  if (company.company_type === 'trade') {
    return createTradeColumns(allColumns, activeIntegration, userIsCrewOnAllProjects, company, userIsPmOnAnyProject)
  } else {
    return createGcColumns(allColumns, activeIntegration, company, userIsPmOnAnyProject)
  }
}
