import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"


export async function createCompany(company: any): Promise<any> {
  return api({
    method: "post",
    url: `/company`,
    data: company,
  })
}

export async function getGcsOnProject(projectId: string): Promise<any> {
  return api({method: 'get', url: `/project/${projectId}/gc`})
}

export async function getTracFloGcs(project_id: any): Promise<any> {
  return api({method: 'get', url: `/project/${project_id}/tracflo_gc`})
}

export interface SubOnProject {
  id: string,
  name: string,
  date_added: string,
  count_of_tickets: number,
}

export async function getSubsOnProject(projectId: string): Promise<AxiosResponse<SubOnProject[]>> {
  return api({method: 'get', url: `/project/${projectId}/subcontractor`})
}
