import React from "react"
import { Formik, Form } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Prompt } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
//@ts-ignore
import TextField from "../components/Fields/Text"
//@ts-ignore
import TextAreaField from "../components/Fields/TextArea"
//@ts-ignore
import api from "../libs/api"
import { addProject } from "../store/features/userSlice"
import {useTracFloSelector} from '../store/useTracFloSelector'
import {DateField} from './components/DateField'


type CreateProjectProps = {
  onCancel: () => void,
}

export default function CreateProject({ onCancel }: CreateProjectProps) {
  const dispatch = useDispatch()
  const user = useTracFloSelector((state) => state.user)
  const company = useTracFloSelector((state) => state.company)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        address: "",
        date_start: (new Date()).toDateString(),
        name: "",
        number: "",
        created_by: ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t("form.message.nameRequired")),
        address: Yup.string().required(t("form.message.addressRequired")),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        // Set up the values for submission
        const submitValues = values

        // Format dates
        const startDate = new Date(values.date_start)
        submitValues.date_start = startDate.toISOString()

        // Add author
        submitValues.created_by = user.id as string

        api({
          method: "post",
          url: `/project`,
          headers: { company_id: company.id },
          data: submitValues,
        })
          .then(async (response: any) => {
            if (response.data.id) {
              dispatch(addProject(response.data))
              resetForm()
              enqueueSnackbar("Project Created", {
                variant: "success",
              })
              onCancel()
            } else {
              enqueueSnackbar("There was a problem creating the project", {
                variant: "error",
              })
            }
          })
          .catch((error: any) => {
            console.log("error", error)
            enqueueSnackbar("There was a problem creating the project", {
              variant: "error",
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        isValid,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Prompt
              when={Object.keys(touched).length > 0}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <FormSmallContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={t("form.label.projectName")}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                    label={t("form.label.projectNumber")}
                    name="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.number}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextAreaField
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    label={t("form.label.projectAddress")}
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                  />
                </Grid>

                <DateField
                  width={'full'}
                  label={t("form.label.startDate")}
                  value={values.date_start}
                  onChange={(date) => {
                    setFieldValue("date_start", date)
                  }}
                />

                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button onClick={onCancel} color="secondary" variant="text">
                    {t("form.label.cancel")}
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    disabled={!Object.keys(touched).length || !isValid}
                    isLoading={isSubmitting}
                    type="submit"
                    endIcon={<AddIcon />}
                  >
                    {t("view.Projects.createProject")}
                  </Button>
                </Grid>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
