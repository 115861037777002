import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import TextField from "../components/Fields/Text"
import {ProductionMetric} from '../types/productionMetric'


export interface CreateEditProductionMetricValues {
  description: string,
  total_amount: string | number,
  unit: string,
}

function defaultInitialValues(): CreateEditProductionMetricValues {
  return {
    description: "",
    total_amount: "",
    unit: "",
  }
}

export function createInitialValues(metric: ProductionMetric | null): CreateEditProductionMetricValues {
  if (metric == null) return defaultInitialValues()
  return {
    description: metric.description,
    total_amount: metric.total_amount,
    unit: metric.unit,
  }
}

interface CreateEditProductionMetricProps {
  onCancel: () => void,
  initialValues?: CreateEditProductionMetricValues,
  submit: (values: CreateEditProductionMetricValues) => Promise<void>,
}

export default function CreateEditProductionMetric(props: CreateEditProductionMetricProps) {
  const { t } = useTranslation("private")

  return (
    <Formik
      validateOnChange={false}
      initialValues={props.initialValues ?? defaultInitialValues()}
      validationSchema={Yup.object().shape({
        description: Yup.string().min(2).required(t("form.message.descriptionRequired")),
        unit: Yup.string().min(2).required(t("form.message.unitRequired")),
        total_amount: Yup.number().required(t("form.message.amountRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        if(values.total_amount === "") values.total_amount = 0
        setSubmitting(true)
        await props.submit(values)
        setSubmitting(false)
        resetForm()
        props.onCancel()
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => {
        return (
          <Form>
            <Prompt
              when={Object.keys(touched).length > 0}
              message={t("form.closePrompt")}
            />
            <FormSmallContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    id="description"
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label={t("form.label.description")}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="unit"
                    label={t("form.label.unit")}
                    name="unit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unit}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="total_amount"
                    type="number"
                    label={t("form.label.amount")}
                    name="total_amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.total_amount}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{values.unit}</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} paddingTop={3}>
                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button
                    onClick={() => {props.onCancel()}}
                    color="secondary"
                    variant="text"
                  >
                    {t("form.label.cancel")}
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {t("form.label.submit")}
                  </Button>
                </Grid>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
