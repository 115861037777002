import React from 'react'
import {useTranslation} from 'react-i18next'

import BreakdownTable from './BreakdownTable'
import BulletinSubsTableColumn from './BulletinSubsTable.columns'
import {BulletinSubcontractor} from '../api/bulletin'
import { useTracFloSelector } from '../store/useTracFloSelector'


export type BulletinSubcontractorForTable = BulletinSubcontractor

interface BulletinSubsTableProps {
  editable: boolean,
  selectedSubs: BulletinSubcontractorForTable[],
  handleDeleteRow?: (i: number) => void,
  projectId: string,
  showEstimateActualCosts: boolean,
}

export default function BulletinSubsTable(
  {editable, selectedSubs, handleDeleteRow, projectId, showEstimateActualCosts}: BulletinSubsTableProps
): JSX.Element {
  const {t} = useTranslation('private')
  const estimateActionIdObjMapping = useTracFloSelector((state) => state?.estimateAction?.idObjectMapping)
  const columns = BulletinSubsTableColumn({
    editable,
    handleDeleteRow,
    t,
    projectId,
    estimateActionIdToInfo: estimateActionIdObjMapping,
    showEstimateActualCosts
  })

  return (
    <BreakdownTable columns={columns} rows={selectedSubs} totals={null}/>
  )
}
