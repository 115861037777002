import React, {useState} from 'react'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import Grid from '@mui/material/Grid'

import {useLanguageLocale} from '../../hook/languageLocale'
// @ts-ignore
import TextField from "../../components/Fields/Text"
import {convertStrToDate} from '../../util/time'


export type DateFieldProps = {
  width: 'full' | 'half',
  label: string,
  value: string | null,
  onChange: (dateStr: string) => void,
  maxDate?: Date,
  minDate?: Date,
  disabled?: boolean,
}

const InvalidDateString = ''
const MinimumValidDate = new Date('2010-01-01')
const MaximumValidDate = new Date('2100-01-01')
const DefaultFieldWidth = 12 // out of 12

export function DateField(
  {
    label,
    maxDate,
    minDate,
    width,
    value,
    onChange,
    disabled,
  }: DateFieldProps
) {
  const languageLocale = useLanguageLocale()
  const [localValue, setLocalValue] = useState<Date | null>(value == null ? null : convertStrToDate(value))

  const widthValue = width === 'full'
    ? 12
    : width === 'half'
    ? 6
    : DefaultFieldWidth

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={languageLocale}>
      <Grid item xs={12} md={widthValue}>
        <DatePicker
          label={label}
          mask="__/__/____"
          maxDate={maxDate ?? MaximumValidDate}
          minDate={minDate ?? MinimumValidDate}
          onChange={(date) => {
            setLocalValue(date)
            // ensure date is valid
            if (date == null) {
              onChange(InvalidDateString)
              return
            }
            const valueOfDate = date.valueOf()
            if (Number.isNaN(valueOfDate)) {
              onChange(InvalidDateString)
              return
            }
            onChange(date.toISOString())
          }}
          // need to explicitly set bg color to white, otherwise it's transparent
          renderInput={(props) => <TextField {...props} sx={{bgcolor: 'white'}} />}
          value={localValue}
          disabled={disabled}
        />
      </Grid>
    </LocalizationProvider>
  )
}
