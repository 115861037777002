import { unstable_createMuiStrictModeTheme as createTheme } from "@mui/material"

const primaryColor = {
  dark: "#0e2347",
  light: "#435b84",
  main: "#143366",
}
const secondaryColor = {
  dark: "#006695", //"#2c89b2",
  light: "#33a7dd", //"#66cfff",
  main: "#0192D5", //"#40c4ff",
}

export const ticketRowAttachedToCo: string = "#E7EBF0"

export const lineGraphColor1: string = "#A6CEE3"
export const lineGraphColor2: string = "#1F78B4"

export const drawerWidth: number = 240

export const integrationOrange: string = "#ed8456"

export const completedGreen: string = "#45bf55"

const theme = createTheme({
  palette: {
    primary: {
      ...primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      ...secondaryColor,
      contrastText: "#fff",
    },
    info: {
      contrastText: "#fff",
      main: "#0093D6",
    },
  },
  typography: {
    h1: {
      color: primaryColor.main,
      fontSize: "1.75rem",
      fontWeight: 500,
      margin: "16px 0px",
    },
    h2: {
      color: primaryColor.main,
      fontSize: "1.125rem",
      fontWeight: 700,
      margin: "16px 0px",
      textTransform: "uppercase",
    },
    h3: {
      color: primaryColor.main,
      fontSize: "1.125rem",
      fontWeight: 700,
      margin: "16px 0px",
    },
    h4: {
      color: primaryColor.main,
      fontSize: "0.875rem",
      textTransform: "uppercase",
      margin: "16px 0px",
    },
    h5: {
      color: primaryColor.main,
      fontSize: "0.875rem",
      margin: "8px 0px",
    },
    h6: {
      color: primaryColor.main,
      fontSize: "0.75.rem",
      fontWeight: 700,
      margin: "8px 0px",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "currentColor",
        },
      },
    },
  },
})

export default theme
