import React, {useState, useEffect} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import SelectField from "../components/Fields/Select"
import {ListOption} from '../types/form'
import {getSubUsers} from '../api/users'

export interface BulletinSubcontractorForForm {
  subcontractor_id: string,
  subcontractor_name: string,
  subcontractor_user_id: string,
  subcontractor_user_name: string,
}

function defaultCreateBulletinSubsFormValues(): BulletinSubcontractorForForm {
  return {
    subcontractor_id: '',
    subcontractor_name: '',
    subcontractor_user_id: '',
    subcontractor_user_name: '',
  }
}

interface CreateBulletinSubsFormProps {
  subcontractorOptions: ListOption<string>[],
  addNewSubcontractor: (s: BulletinSubcontractorForForm) => void,
  projectId: string,
  closeForm: () => void
}

export default function CreateBulletinSubsForm(
  {subcontractorOptions, addNewSubcontractor, projectId, closeForm}: CreateBulletinSubsFormProps
): JSX.Element {
  const { t } = useTranslation("private")
  const [currentSubId, setCurrentSubId] = useState<string | null>(null)
  const [subUsers, setSubUsers] = useState<ListOption<string>[]>([])

  useEffect(() => {
    if (currentSubId != null && currentSubId.length > 0 && subUsers.length === 0) {
      getSubUsers(projectId, currentSubId).then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          setSubUsers(
            res.data.filter((su) => ['pm', 'super'].includes(su.project_user_role_code))
              .map((su) => ({value: su.id, label: `${su.first_name} ${su.last_name}`}))
          )
        }
      })
    }
  }, [currentSubId, subUsers, projectId])

  useEffect(() => {
    // reset when sub options change
    setCurrentSubId(null)
    setSubUsers([])
  }, [subcontractorOptions])

  return (
    <Formik
      initialValues={defaultCreateBulletinSubsFormValues()}
      validationSchema={
        Yup.object().shape({
          subcontractor_id: Yup.string().required(t("form.CreateBulletinSubsForm.subRequired")),
          subcontractor_user_id: Yup.string().required(t("form.CreateBulletinSubsForm.subUserRequired")),
        })
      }
      onSubmit={(values, { resetForm }) => {
        addNewSubcontractor(values)
        resetForm()
      }}
    >
      {({
          errors,
          handleBlur,
          touched,
          values,
          setFieldValue,
          resetForm,
          submitForm,
          handleChange,
          isValid
        }) => {
        return (
          <Form>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <SelectField
                  error={Boolean(touched.subcontractor_id && errors.subcontractor_id)}
                  helperText={touched.subcontractor_id && errors.subcontractor_id}
                  label={t("form.CreateBulletinSubsForm.subLabel")}
                  name="subcontractor_id"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    if (e.target.value !== values.subcontractor_id) {
                      setSubUsers([])
                      setCurrentSubId(e.target.value)
                      setFieldValue('subcontractor_user_id', '')
                      setFieldValue('subcontractor_user_name', '')
                      // get name of sub and set
                      const subOption = subcontractorOptions.find((so) => so.value === e.target.value)
                      if (subOption != null) {
                        setFieldValue('subcontractor_name', subOption.label)
                      } else {
                        setFieldValue('subcontractor_name', '')
                      }
                    }
                    handleChange(e)
                  }}
                  options={subcontractorOptions}
                  renderInput={(props: any) => <TextField {...props} />}
                  value={values.subcontractor_id}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  error={Boolean(touched.subcontractor_user_id && errors.subcontractor_user_id)}
                  helperText={touched.subcontractor_user_id && errors.subcontractor_user_id}
                  disabled={subUsers.length === 0}
                  label={t("form.CreateBulletinSubsForm.subUserLabel")}
                  name="subcontractor_user_id"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    if (e.target.value !== values.subcontractor_user_id) {
                      // get name of user and set
                      const subUserOption = subUsers.find((so) => so.value === e.target.value)
                      if (subUserOption != null) {
                        setFieldValue('subcontractor_user_name', subUserOption.label)
                      } else {
                        setFieldValue('subcontractor_user_name', '')
                      }
                    }
                    handleChange(e)
                  }}
                  options={subUsers}
                  renderInput={(props: any) => <TextField {...props} />}
                  value={values.subcontractor_user_id}
                />
              </Grid>

              <Grid container item xs={6} alignContent="center" justifyContent="flex-start">
                <Button
                  color="secondary"
                  onClick={() => {
                    resetForm()
                    closeForm()
                  }}
                  size="small"
                  variant="text"
                >
                  {t("view.ChangeOrder.cancel")}
                </Button>
              </Grid>

              <Grid container item xs={6} alignContent="center" justifyContent="flex-end">
                <Button
                  disabled={!isValid}
                  startIcon={<AddIcon />}
                  onClick={() => {submitForm()}}
                >
                  {t("form.CreateBulletinSubsForm.submitButton")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
