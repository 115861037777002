import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import React from "react"

import {BreakdownTableColumn, getColumnStyles} from "./BreakdownTable.columns"

function createParamsForRenderCell(row: any, rows: any[], value: any): any {
  return {
    ...row,
    value,
    getValue: (id: any, key: any) => {
      const valueRow = rows.find((findRow) => findRow.id === id)
      return valueRow && valueRow[key] ? valueRow[key] : null
    },
  }
}

interface BreakdownTableCellProps {
  rows: any[],
  row: any,
  column: BreakdownTableColumn,
  index: number,
}

function BreakdownTableCell(props: BreakdownTableCellProps) {
  const value: any = props.column.field && (props.row[props.column.field] ?? null)
  return (
    <TableCell
      align={props.column.align}
      key={props.index}
      style={getColumnStyles(props.column)}
    >
      {props.column.renderCell
        ? props.column.renderCell(createParamsForRenderCell(props.row, props.rows, value))
        : value
      }
    </TableCell>
  )
}

interface BreakdownTableRowsProps {
  rows: any[],
  columns: BreakdownTableColumn[],
}

export function BreakdownTableRows(props: BreakdownTableRowsProps) {
  return (
    <>
      {
        props.rows.map((row) => (
          <TableRow key={row.index}>
            {props.columns
              .filter((column) => !column.hide)
              .map((column, index) => (
                <BreakdownTableCell rows={props.rows} row={row} column={column} index={index} key={index}/>
              ))
            }
          </TableRow>
        ))
      }
    </>
  )
}
