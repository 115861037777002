import React from "react"

// @ts-ignore
import ButtonDelete from "./ButtonDelete"
// @ts-ignore
import FormatRowId from "./FormatRowId"
import {BreakdownTableColumn} from './BreakdownTable.columns'
import {formatMoney} from '../libs/format'
import {convertToNumber} from '../util/number'


interface EstimateLineItemsTableColumnInputs {
  editable: boolean,
  handleDeleteRow?: (i: number) => void,
  t: (s: string) => string,
  showCosts: boolean,
}

export default function EstimateLineItemsTableColumn(
  {editable, handleDeleteRow, t, showCosts}: EstimateLineItemsTableColumnInputs
): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      style: { paddingLeft: 0 },
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t("component.EstimateLineItemsTable.descriptionColLabel"),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "cost",
      headerName: t("component.EstimateLineItemsTable.costColLabel"),
      maxWidth: 30,
      hide: !showCosts,
      editable: false,
      sortable: false,
      renderCell: (params: any) => params.value == null
        ? ''
        : formatMoney(convertToNumber(params.value) ?? 0, false),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      headerName: "",
      hide: !editable,
      width: 50,
      editable: false,
      sortable: false,
      style: { paddingRight: 0 },
      renderCell: (params: any) => (
        <ButtonDelete
          onClick={() => {
            if (handleDeleteRow) {
              handleDeleteRow(params.index)
            }
          }}
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        />
      ),
    },
  ]
}
