import React from "react"
import MuiFab, {FabProps} from "@mui/material/Fab"

type Props = FabProps & { children: React.ReactNode, component?: any, to?: string, edge?: string }

export default function Fab({ children, color, ...props }: Props) {
  // @ts-ignore
  const isTransparent = color === "transparent"

  return (
    <MuiFab
      // @ts-ignore
      color={color || "secondary"}
      sx={{
        background: isTransparent ? "transparent" : null,
        boxShadow: isTransparent ? "none" : null,
        m: 1,
        "&.MuiFab-extended .MuiSvgIcon-root": {
          marginRight: 1,
        },
        "&:hover": isTransparent ? {
          color: "secondary.contrastText",
        } : null,
      }}
      {...props}
    >
      {children}
    </MuiFab>
  )
}
