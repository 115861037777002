import {
  determineTicketType,
  EquipmentBreakdown,
  LaborBreakdown,
  MaterialBreakdown,
  TicketMarkup
} from "../../../../../types/ticket"
import { Material } from "../../../../../types/material"
import { Equipment } from "../../../../../types/equipment"
import {CreateDailyInitialValues} from '../../../../../forms/CreateDaily.types'
import {Daily} from '../../../../../types/daily'


function initializeMaterialBreakdown(material: MaterialBreakdown, materialTypes: Material[]): any {
  return {
    id: material.id,
    unit: material.unit,
    quantity: material.quantity,
    type_id: material.material_type_id,
    rate: material.rate ?? materialTypes.find((m) => m.id === material.material_type_id)?.rate
  }
}

function initializeEquipmentBreakdown(equipment: EquipmentBreakdown, equipmentTypes: Equipment[]): any {
  return {
    id: equipment.id,
    unit: equipment.unit,
    quantity: equipment.quantity,
    type_id: equipment.equipment_type_id,
    rate: equipment.rate ?? equipmentTypes.find((e) => e.id === equipment.equipment_type_id)?.rate
  }
}

function initializeLaborBreakdown(laborBreakdown: LaborBreakdown): any {
  return {
    id: laborBreakdown.id,
    hours: laborBreakdown.hours_per_person,
    quantity: laborBreakdown.crew_size,
    rate_type: laborBreakdown.rate_type,
    type_id: laborBreakdown.labor_type_id,
    date: laborBreakdown.date,
  }
}

function initializeMarkup(markup: TicketMarkup): any {
  return {
    id: markup.id,
    amount: markup.percent,
    title: markup.description,
    markup_type: markup.markup_type,
  }
}

export function initializeFormWithDailyData(
  daily: Daily,
  markup: TicketMarkup[],
  labor: LaborBreakdown[],
  material: MaterialBreakdown[],
  equipment: EquipmentBreakdown[],
  materialTypes: Material[],
  equipmentTypes: Equipment[],
): CreateDailyInitialValues {
  return {
    date_start: daily.date_start,
    description: daily.description,
    files: [{name: ""}],
    formMarkup: markup.filter((m) => m.markup_type === 'ticket').map(initializeMarkup),
    isAddCosts: daily.includes_costs,
    isLumpSum: daily.is_lump_sum,
    laborBreakdown: labor.map((l) => initializeLaborBreakdown(l)),
    laborMarkup: markup.filter((m) => m.markup_type === 'labor').map(initializeMarkup),
    materialBreakdown: material.map((m) => initializeMaterialBreakdown(m, materialTypes)),
    materialMarkup: markup.filter((m) => m.markup_type === 'material').map(initializeMarkup),
    equipmentBreakdown: equipment.map((e) => initializeEquipmentBreakdown(e, equipmentTypes)),
    equipmentMarkup: markup.filter((m) => m.markup_type === 'equipment').map(initializeMarkup),
    lumpSumTotal: daily.lump_sum_total ?? '',
    manual_total: daily.lump_sum_total ?? '',
    notes: daily.notes ?? '',
    number: daily.number,
    subject: daily.subject,
    type: determineTicketType(daily.includes_costs, daily.is_lump_sum),
    // create new objects to prevent overwriting existing data
    production: daily.production == null ? [] : daily.production.map((dpm) => ({...dpm})),
  }
}
