import { AxiosResponse } from "axios"

// @ts-ignore
import api from "../libs/api"
import {uploadFiles} from "./file"
import { HistoryItem } from "../types/history"
import { CompanySenderReceiver } from "../types/company"
import { InvoiceSignature } from "../types/signature"


export async function getNextChangeOrderNumber(project_id: string): Promise<AxiosResponse<{next_number: string}>> {
  return api({
    method: "get",
    url: `/project/${project_id}/next-change-order-number`,
  })
}

export async function createChangeOrder(project_id: string, changeOrderData: any): Promise<any> {
  return api({
    method: "post",
    url: `/project/${project_id}/change_order`,
    data: changeOrderData,
  })
}

export async function getChangeOrderById(project_id: string, co_id: string): Promise<any> {
  const {data: changeOrderData}  = await api.get(`/project/${project_id}/change_order/${co_id}`)
  return changeOrderData[0]
}

export async function getChangeOrderSenderReceiver(
  project_id: string,
  co_id: string
): Promise<AxiosResponse<CompanySenderReceiver>> {
  return api.get(`/project/${project_id}/change_order/${co_id}/sender_receiver_company_info`)
}

export async function getChangeOrderTickets(project_id: string, co_id: string): Promise<any> {
  return api.get(`/project/${project_id}/change_order/${co_id}/ticket`)
}

export async function getChangeOrderMarkup(project_id: string, co_id: string): Promise<any> {
  return api.get(`/project/${project_id}/change_order/${co_id}/markup`)
}

export async function uploadChangeOrderFiles(
  files: any[],
  project_id: string,
  co_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, co_id, 'change_order')
}

export async function getChangeOrderFiles(project_id: string, co_id: string): Promise<any> {
  return api.get(`/project/${project_id}/change_order/${co_id}/file_presigned`)
}

export async function getChangeOrderHistory(project_id: string, co_id: string): Promise<AxiosResponse<HistoryItem[]>> {
  return api.get(`/project/${project_id}/change_order/${co_id}/history`)
}

export async function getChangeOrderSignature(project_id: string, co_id: string): Promise<AxiosResponse<InvoiceSignature|null>> {
  return api.get(`/project/${project_id}/change_order/${co_id}/signature`)
}

export async function editChangeOrder(changeOrder: any, co_id: string, project_id: string): Promise<any> {
  return api({
    method: "patch",
    url: `/project/${project_id}/change_order/${co_id}`,
    data: changeOrder,
  })
}

export async function getOpenItemsChangeOrders(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/open_items_change_orders`)
}

export async function getChangeOrdersWaitingForReview(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/cos_waiting_for_review`)
}

export async function getChangeOrdersApprovedByUser(projectId: string): Promise<AxiosResponse<string[]>> {
  return api.get(`/project/${projectId}/change_orders_approved_by_user`)
}

export async function getCoCostReviewAvgResponseTime(projectId: string): Promise<AxiosResponse<any>> {
  return api.get(`/project/${projectId}/change_order_review_response_times`)
}

export interface ChangeOrderShape {
  id: string,
  co_lump_sum: boolean,
  co_markup: boolean,
  ticket_lump_sum: boolean | null,
  ticket_markup: boolean | null,
  labor_bd: boolean | null,
  labor_markup: boolean | null,
  material_bd: boolean | null,
  material_markup: boolean | null,
  equipment_bd: boolean | null,
  equipment_markup: boolean | null,
}

export async function getChangeOrderShape(projectId: string, coId: string): Promise<AxiosResponse<ChangeOrderShape>> {
  return api.get(`/project/${projectId}/change_order/${coId}/shape`)
}

export async function changeCoIsActiveStatus(
  is_active_status: boolean,
  projectId: string,
  coId: string,
): Promise<AxiosResponse<{id: string}>> {
  return api({
    method: "patch",
    url: `/project/${projectId}/change_order/${coId}/is_active_status`,
    data: {is_active: is_active_status},
  })
}

export type AttachEstimateToChangeOrderOutput = {
  pco_number: string,
  estimate_id: string
}

export async function attachEstimateToChangeOrder(
  projectId: string,
  coId: string,
  estimate_id: string,
): Promise<AxiosResponse<AttachEstimateToChangeOrderOutput>> {
  return api({
    method: "post",
    url: `/project/${projectId}/change_order/${coId}/attach_estimate`,
    data: {estimate_id},
  })
}

export async function removeEstimateFromChangeOrder(
  projectId: string,
  coId: string,
): Promise<AxiosResponse<string>> {
  return api({
    method: "post",
    url: `/project/${projectId}/change_order/${coId}/remove_estimate`,
  })
}

export async function getChangeOrderInvoice(projectId: string, coId: string): Promise<AxiosResponse<{success: boolean}>> {
  return api.get(`/project/${projectId}/change_order/${coId}/invoice`)
}
