import React from "react"
import Typography from "@mui/material/Typography"
import {Link} from "react-router-dom"

import {goToSingleChangeOrder} from "../../../../../../util/routes"


interface AttachedToCoTextProps {
  attachedCo: any,
  projectId: string,
}

export default function AttachedToCoText(props: AttachedToCoTextProps): any {
  return (
    <>
      {"Attached to (CO # "}
        <Link
          to={goToSingleChangeOrder(props.projectId, props.attachedCo.id)}
          style={{ textDecorationColor: "#40C4FF" }}
          onClick={() => {window.scrollTo(0, 0)}}
          target={"_blank"}
          >
          <Typography color="secondary" display="inline">
            {`${props.attachedCo.number}`}
          </Typography>
        </Link>
      {")"}
    </>
  )
}
