import React, {useEffect, useState} from "react"
import { Formik, Form } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import NextIcon from "@mui/icons-material/ArrowForwardIos"

// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import SelectField from "../components/Fields/Select"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
import { getUsersInProject } from "../api/users"
import { makeUserName } from "../util/user"
import { ListOption, ProjectShareFormSubmitValues } from "../types/form"

interface shareFormProps {
  projectId: string
  toggleFormOpen: () => void
  submitValues: (values: ProjectShareFormSubmitValues) => Promise<void>,
}

export default function ShareForm(props: shareFormProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [internalUsers, setInternalUsers] = useState<ListOption<string>[]>([])

  useEffect(() => {
    if (!!props.projectId) {
      getUsersInProject(props.projectId)
        .then((response) => {
          if (Array.isArray(response.data)) {
            setInternalUsers(
              response.data
                .filter((item) => item.project_user_role_code !== 'crew')
                .map((user) => ({
                  label: makeUserName(user),
                  value: user.id,
                }))
            )
          }
        })
        .catch(() => {
          enqueueSnackbar("There was a problem getting the users associated with this project.", {
            variant: "error",
          })
        })
    }
  }, [props.projectId, enqueueSnackbar, t])

  return (
    <FormSmallContainer>
      <Formik
      initialValues={{
        shareWith: [],
        notes: "",
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        // Send emails
        await props.submitValues(values)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          handleBlur,
          handleChange,
          values,
          isSubmitting,
        }) => {
        return internalUsers.length > 0 ? (
          <Form>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" align={isMobile ? "center" : "left"}>
                  {t("view.ChangeOrder.Send.recipients")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  label={t("view.Settings.internal")}
                  multiple={true}
                  name={'shareWith'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={internalUsers}
                  value={values.shareWith}
                />
              </Grid>

              <Grid item xs={12}>
                <TextAreaField
                  label={t("form.label.notes")}
                  value={values.notes}
                  name="notes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container item xs={6} alignItems="center">
                <Button color="secondary" variant="text" onClick={props.toggleFormOpen}>
                  {t("view.ChangeOrder.cancel")}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
              >
                <Button
                  type="submit"
                  endIcon={<NextIcon />}
                  disabled={(values.shareWith.length) === 0 || isSubmitting}
                >
                  {t("view.ChangeOrder.Summary.send")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        ) : <></>
      }}
    </Formik>
    </FormSmallContainer>
  )
}
