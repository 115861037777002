import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

import {BulletinSubcontractor, getBulletinSubcontractors} from '../../../../../../api/bulletin'
import BulletinSubsTable from '../../../../../../components/BulletinSubsTable'
import {bulletinActionIdTmAuthorized} from '../../../../../../types/bulletin'


interface BulletinSubcontractorsTableProps {
  bulletinId: string,
  bulletinActionId: number,
  projectId: string,
}

export default function BulletinSubcontractorsTable(
  {bulletinId, projectId, bulletinActionId}: BulletinSubcontractorsTableProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [bulletinSubs, setBulletinSubs] = useState<BulletinSubcontractor[]>([])

  useEffect(() => {
    getBulletinSubcontractors(projectId, bulletinId).then((res) => {
      if (res.status === 200 && Array.isArray(res.data)) {
        setBulletinSubs(res.data)
      }
    })
  }, [bulletinId, projectId])

  return (
    <Grid container style={{marginTop: 30}}>
      <Grid item xs={12}>
        <Typography variant="h2">{t('view.Bulletins.SingleBulletin.Subcontractors.title')}</Typography>
      </Grid>

      <Grid item xs={12}>
        {
          bulletinSubs.length > 0
            ? <BulletinSubsTable
              editable={false}
              selectedSubs={bulletinSubs}
              projectId={projectId}
              showEstimateActualCosts={bulletinActionId === bulletinActionIdTmAuthorized}
            />
            : <Typography variant="body1">{t('view.Bulletins.SingleBulletin.Subcontractors.noSubs')}</Typography>
        }
      </Grid>
    </Grid>
  )
}
