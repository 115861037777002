import {HandleFieldChangeObject} from '../types/form'


export function handleFieldChange(
  setData: (field: string, value: any,) => void,
  value: any,
  valuePropName: string
): (i: number, fieldNameAndValue: HandleFieldChangeObject[]) => void {
  return (index: any, propNamesAndValues: any) => {
    for (const {fieldPropName, newValue} of propNamesAndValues) {
      value[index][fieldPropName] = newValue
    }
    setData(valuePropName, [...value])
  }
}
