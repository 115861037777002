import React, { useEffect, useMemo, useState } from "react"
import { Grid, Typography } from "@mui/material"

import DailyBreakdowns from "../DailyBreakdowns"
import { getDailyMarkup } from "../../../../../../api/daily"
import { TicketMarkup, TicketType } from "../../../../../../types/ticket"
import { convertToNumber } from "../../../../../../util/number"
import TicketMarkupSubtotals from "../../../Tickets/SingleTicket/Markup"


interface DailyBreakdownMarkupCostDetailProps {
  dailyId: string,
  projectId: string,
  dailyTicketType: TicketType,
  dailyTotal: string,
  lumpSumTotal?: string | number
}

export default function DailyBreakdownMarkupCostDetail(
  {dailyId, projectId, dailyTicketType, dailyTotal, lumpSumTotal}: DailyBreakdownMarkupCostDetailProps
) {
  const [dailyMarkup, setDailyMarkup] = useState<TicketMarkup[]>([])

  const addRates = dailyTicketType === 'sum_rates'
  const showCosts = dailyTicketType !== 'tm'

  // Tracking subtotals
  const [overallSubtotal, setOverallSubtotal] = useState(
    dailyTicketType === "sum_total" 
    ? convertToNumber(lumpSumTotal) ?? 0  
    : 0
  )
  
  const subtotals = useMemo(
    () => ({labor: 0, material: 0, equipment: 0}),
    // eslint-disable-next-line
    [dailyId, projectId]
  )

  function updateSubtotal(type: 'labor' | 'material' | 'equipment', value: number): void {
    subtotals[type] = value
    setOverallSubtotal(subtotals.labor + subtotals.material + subtotals.equipment)
  }

  useEffect(() => {
    // get markup for this ticket
    getDailyMarkup(projectId, dailyId).then(
      (res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setDailyMarkup(res.data)
        }
      }, // TODO BETTER ERROR HANDLING
    )
  }, [projectId, dailyId])

  return (
    <>
      <DailyBreakdowns 
        dailyId={dailyId} 
        addRates={addRates} 
        projectId={projectId}
        markup={dailyMarkup}
        updateSubtotal={updateSubtotal}
      />

      { showCosts 
        ? <>
          <TicketMarkupSubtotals
            subtotal={overallSubtotal}
            markup={dailyMarkup.filter((d) => d.markup_type === 'daily')}
            ticketType={dailyTicketType}
          />

          <Grid item container xs={12} justifyContent="flex-end">
            <Typography variant="body2" style={{ fontWeight: 800, fontSize: 20, paddingTop: 10 }}>
              Total: {dailyTotal}
            </Typography>
          </Grid>
        </>
        : <></>
      }
    </>
  )
}
