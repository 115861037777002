import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import BreakdownTable from './BreakdownTable'
import {EstimateMarkup} from '../api/estimate'
import EstimateMarkupTableColumns from './EstimateMarkupTable.columns'
import {HandleFieldChangeObject} from '../types/form'
import { calculateEstimateMarkupTotalPercent } from '../util/estimate'


export type EstimateMarkupForTable = EstimateMarkup

interface EstimateMarkupTableProps {
  editable: boolean,
  markup: EstimateMarkupForTable[],
  subtotal: number,
  handleDeleteRow?: (i: number) => void,
  handleFieldChange?: (i: number, fc: HandleFieldChangeObject[]) => void,
}

export default function EstimateMarkupTable(
  {editable, markup, handleDeleteRow, subtotal, handleFieldChange}: EstimateMarkupTableProps
): JSX.Element {
  const {t} = useTranslation('private')

  const columns = EstimateMarkupTableColumns({
    editable,
    handleFieldChange,
    handleDeleteRow,
    t,
    subtotal
  })

  const totals = useMemo(() => {
    if (markup.length > 0) {
      return {total: calculateEstimateMarkupTotalPercent(markup) / 100 * subtotal}
    }
    return null
  }, [markup, subtotal])

  return (
    <BreakdownTable
      columns={columns}
      rows={markup}
      totals={totals}
      editable={editable}
      subtotalText={t('component.EstimateMarkupTable.subtotalText')}
    />
  )
}
