import React from "react"
import { Route, useHistory } from "react-router-dom"

import { useQuery } from "../../util/url"
import { useTracFloSelector } from "../../store/useTracFloSelector"
import { goToAccounts } from "../../util/routes"

function isTryingToResetPassword(path: string | null): boolean {
  return path != null && path.includes('/reset-password/')
}

export default function UnauthenticatedRouteGuard({ children, ...rest }: any) {
  const user = useTracFloSelector((state) => state.user)
  const history = useHistory()
  const redirect = useQuery().get("redirect")

  // redirect unless they are trying to reset password
  if (user.isLoggedIn && !isTryingToResetPassword(history?.location?.pathname)) {
    const nextPage = redirect && redirect !== goToAccounts ? `${goToAccounts}?redirect=${redirect}` : goToAccounts
    history.push(nextPage)
  }

  return <Route {...rest}>{children}</Route>
}
