import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import {
  Project,
  ProjectStore,
} from "../../types/project"
import { TracFloState } from "../../types/state"

const initialState: ProjectStore = {
  address: "",
  date_start: "",
  disclaimer: "",
  date_disclaimer_modified: null,
  id: "",
  name: "",
  number: "",
  project_user_role: "pm",
  //License fields
  change_order_is_active: false,
  gc_can_add_subs: false,
  project_license_is_active: false,
  license_owner: "",
  exp: "",
  status: "idle",
}

export const loadProject = createAsyncThunk<Project, void, {state: TracFloState}>(
  "project/loadProject",
  async (id, thunkAPI) => {
    //{ companyId, projectId, token },
    const { company, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/${company.id}/project/${id}`,
    })
    const { data } = response
    if (response.status === 200) {
      return data
    } else {
      return thunkAPI.rejectWithValue(data)
    }
  }
)

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state: ProjectStore, action: {payload: Project }) => {
      const project = action.payload
      state.address = project.address
      state.date_start = project.date_start
      state.disclaimer = project.disclaimer
      state.date_disclaimer_modified = project.date_disclaimer_modified
      state.id = project.id
      state.name = project.name
      state.number = project.number
      state.project_user_role = project.project_user_role
      //License fields
      state.change_order_is_active = project.change_order_is_active
      state.gc_can_add_subs = project.gc_can_add_subs
      state.project_license_is_active = project.project_license_is_active
      state.license_owner = project.license_owner
      state.status = "set"
    },
    resetProject: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProject.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadProject.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadProject.fulfilled, (state: any, action: any) => {
        console.log(action)
        state = action.payload
        state.status = "fulfilled"
      })
  },
})

export const { setProject, resetProject } = projectSlice.actions

export default projectSlice.reducer

export const getActiveProject = (s: TracFloState) => s.project

export const projectNotInitialized = (p: ProjectStore) => p.id.length === 0
