
export type FinancingApr2024 = {
  interested_in_financing: boolean,
  // date we should ask them again
  datetime_try_again: Date,
}

export type UserTestingStore = {
  financingApr2024?: {[key: string]: FinancingApr2024}
}

export function initializeUserTestingStore(): UserTestingStore {
  return {}
}
