import {useEffect, useState} from "react"

import HistoryList from "../../../../../../components/History"
import { HistoryItem } from "../../../../../../types/history"
import { getEstimateHistory } from "../../../../../../api/estimate"

interface EstimateHistoryProps {
  estimate_id: string,
  project_id: string,
}

export default function EstimateHistory({estimate_id, project_id}: EstimateHistoryProps) {
  const [estimateHistory, setEstimateHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    if (!!estimate_id && !!project_id) {
      getEstimateHistory(project_id, estimate_id).then(
        (res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setEstimateHistory(res.data)
          }
        }
      )
    }
  }, [estimate_id, project_id])

  return estimateHistory.length > 0 ? (
    <HistoryList historyItems={estimateHistory} />
  ) : (<></>)
}
