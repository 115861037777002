import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

// @ts-ignore
import { convertToNumber } from "../../../../../../util/number"
import materialColumns from "./MaterialBreakdown.columns"
// @ts-ignore
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import {TicketMarkup} from "../../../../../../types/ticket"
import MaterialBreakdownTable from "./BreakdownTables/MaterialBreakdownTable"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"
import {getTicketMaterialBreakdown} from "../../../../../../api/ticket"

export interface MaterialTotals {
  quantity: number, total?: number
}
export function calculateMaterialTotals(
  rows: any[], addRates: boolean
): MaterialTotals {
  const materialTotals: MaterialTotals = {
    quantity: rows.reduce(
      (total, { quantity: current }) => total + parseFloat(current),
      0
    ),
  }
  if (addRates) {
    materialTotals.total = rows.reduce(
      (total, { rate, quantity }) => {
        return total + (
          (convertToNumber(rate) ?? 0)
          * (convertToNumber(quantity) ?? 0)
        )
      },
      0
    )
  }
  return materialTotals
}

interface MaterialBreakdownProps {
  ticket_id: string,
  project_id: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateMaterialSubtotal: (ls: number) => void
}

export default function MaterialBreakdown(props: MaterialBreakdownProps) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<MaterialTotals>({quantity: 0})
  const [rows, setRows] = useState([])

  // Get material data
  useEffect(() => {
    if (!!props.ticket_id && !!props.project_id) {
      // get sender and receiver for this ticket
      getTicketMaterialBreakdown(props.project_id, props.ticket_id).then(
        (res: any) => {
          setRows(res.data)
        } // TODO BETTER ERROR HANDLING
      )
    }
  }, [props.ticket_id, props.project_id])

  // Calculate table totals
  useEffect(() => {
    const materialTotals = calculateMaterialTotals(rows, props.addRates)
    if (props.addRates && materialTotals.total != null) {
      props.updateMaterialSubtotal(calculateTotalWithMarkups(materialTotals.total, props.markupRows))
    }
    setTotals(materialTotals)
  }, [props.addRates, rows, props.markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.ChangeOrder.Material.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialBreakdownTable
          addRates={props.addRates}
          columns={materialColumns({addRates: props.addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.TicketBreakdowns.material_subtotal")}
        />
      </Grid>
      {props.addRates
        ? <InvoiceMarkup
          rows={props.markupRows}
          total={totals.total ?? 0}
          subTotalText={t("view.TicketBreakdowns.material_markup")}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
