import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"
import {Dialog, useTheme} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"

import {ticketColumns} from "./TicketsList.columns"
//@ts-ignore
import Container from "../../../../../components/Container"
//@ts-ignore
import DataTable from "../../../../../components/DataTable"
import FeatureAccessButton from "../../../../../components/FeatureAccessButton"
import {
  deleteTicket as deleteTicketGlobally,
  loadTickets,
  reloadTicketsIfInvalid
} from "../../../../../store/features/ticketsSlice"
import {ticketRowAttachedToCo} from "../../../../../theme"
import {useTracFloSelector} from "../../../../../store/useTracFloSelector"
import RefreshButton from "../../../../../components/refreshButton"
import { useFilter } from "../../../../../hook/tableFilter"
import DownloadInvoicesButton from "../../../../../components/DownloadInvoicesButton"
import { useSnackbar } from "notistack"
import {changeTicketIsActiveStatus, getBatchTicketInvoiceUrl, getTicketLaborTimesheetReport} from "../../../../../api/ticket"
import { splitArrayIntoGroups } from "../../../../../util/array"
import ConfirmOrCancelPrompt from "../../../../../components/ConfirmOrCancelPrompt"
import {useTableColumns} from '../../../../../hook/tableColumns'
import {GridColumnVisibilityModel} from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'
import Fab from '../../../../../components/Fab'
import {
  convertObjectListToCsvAndDownload,
} from '../../../../../util/csv/createAndDownloadCsv'
import {TicketLaborTimesheetReportCsvHeaders} from '../../../../../types/ticket'
import TicketListStatusChart from './TicketListStatusChart'


const tableName = 'TicketsList'

export default function TicketsList() {
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const isSuperadmin = useTracFloSelector((state) => state.user?.is_admin)
  const loadingStatus = useTracFloSelector((state) => state.tickets.status.toString())
  const tickets = useTracFloSelector((state) => state.tickets.items)
  const ticketsProjectId = useTracFloSelector((state) => state.tickets.projectId)
  const ticketExpiry = useTracFloSelector((state) => state.tickets.exp)
  const actionIdToInfo = useTracFloSelector((state) => state.action?.idObjectMapping)
  const [selectedTickets, setSelectedTickets] = useState<any[]>([])
  const [deleteTicketId, setDeleteTicketId] = useState<string | null>(null)
  const [openDeleteTicketDialog, setOpenDeleteTicketDialog] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const [filter, setFilter] = useFilter(tableName)

  const openConfirmCancelDeleteTicketDialog = async (ticketId: string): Promise<void> => {
    setDeleteTicketId(ticketId)
    setOpenDeleteTicketDialog(true)
  }

  const columns = ticketColumns(
    company.company_type,
    project?.project_user_role,
    projectId,
    actionIdToInfo,
    t,
    openConfirmCancelDeleteTicketDialog
  )

  const [globalTableColumns, setGlobalTableColumns] = useTableColumns(tableName, columns)

  const closeConfirmCancelDeleteTicketDialog = () => {
    setOpenDeleteTicketDialog(false)
  }

  // set up styling for special case
  const attachedStyling: any = {}
  const attachedToChangeOrder = "attachedToChangeOrder"
  attachedStyling[`.${attachedToChangeOrder}`] = {backgroundColor: ticketRowAttachedToCo}

  const readyToShowTickets = tickets.length && project.id === projectId

  // Only trades. superadmins not allowed to add
  const showAddTicketButton = company.company_type === "trade" && !isSuperadmin

  // only PMs at trades (superadmins allowed)
  const showDownloadLaborTimesheetButton = company.company_type === "trade" && project.project_user_role === 'pm'

  const canAddTickets = project && project.project_license_is_active

  const deleteTicket = async () => {
    closeConfirmCancelDeleteTicketDialog()
    if(deleteTicketId != null){
      try {
        const { data } = await changeTicketIsActiveStatus(
          false,
          deleteTicketId,
          projectId,
        )
        if (!!data.id) {
          dispatch(deleteTicketGlobally(data.id)) // Remove the ticket once we have a successful call.
          enqueueSnackbar(t("view.Tickets.delete_success"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.Tickets.delete_fail"), {
            variant: "error",
          })
        }
      } catch(_) {
        enqueueSnackbar(t("view.Tickets.delete_fail"), {
          variant: "error",
        })
      }
    }
  }

  useEffect(() => {
    if (project.id === projectId) {
      reloadTicketsIfInvalid(projectId, ticketsProjectId, ticketExpiry, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id])

  // Note: We are batching tickets in intervals of 15
  const downloadTicketInvoices = async () => {
    const maxTicketsPerPdf = 15
    const ticketIdsSplitIntoGroups = splitArrayIntoGroups<string>(selectedTickets.map((ticket) => ticket.id), maxTicketsPerPdf)
    let ticketCounter = 1

    try {
      for(let i=0; i < ticketIdsSplitIntoGroups.length; i++){
        const snackbarInfoMessage = ticketIdsSplitIntoGroups[i].length > 1
          ? `${t('view.Tickets.downloading_tickets')} ${ticketCounter}-${ticketCounter + ticketIdsSplitIntoGroups[i].length - 1}`
          : `${t('view.Tickets.downloading_ticket')} ${ticketCounter}`

        const snackbarKey = enqueueSnackbar(snackbarInfoMessage, {variant: 'info', persist: true})

        const { data: pdfRes } = await getBatchTicketInvoiceUrl(
          projectId,
          ticketIdsSplitIntoGroups[i]
        )
        closeSnackbar(snackbarKey)
        ticketCounter += maxTicketsPerPdf
        if (!!pdfRes.url) {
          window.open(pdfRes.url, '_blank')
        } else {
          enqueueSnackbar(
            t('view.Tickets.download_list_fail'),
            { variant: "error", }
          )
        }
      }
      enqueueSnackbar(t('view.Tickets.download_list_success'), {variant: "success"})
    } catch(_) {
      enqueueSnackbar(
        t('view.Tickets.download_list_fail'),
        { variant: "error",}
      )
    }
    setSelectedTickets([])
  }

  const downloadLaborTimesheet = async (): Promise<void> => {
    const inProgressSbKey = enqueueSnackbar(
      t('view.Tickets.downloadLaborTimesheetReportInProgress'),
      { variant: "info", }
    )
    const res = await getTicketLaborTimesheetReport(project.id)
    if (res.status !== 200) {
      closeSnackbar(inProgressSbKey)
      enqueueSnackbar(t('view.Tickets.downloadLaborTimesheetReportFail'), { variant: "error", })
      return
    }
    if (res.data.length === 0) {
      closeSnackbar(inProgressSbKey)
      enqueueSnackbar(t('view.Tickets.downloadLaborTimesheetReportNoData'), { variant: "info", })
      return
    }
    const result = convertObjectListToCsvAndDownload(
      res.data,
      TicketLaborTimesheetReportCsvHeaders,
      'labor_data.csv'
    )
    closeSnackbar(inProgressSbKey)
    if (result.success) {
      enqueueSnackbar(t('view.Tickets.downloadLaborTimesheetReportSuccess'), { variant: "success", })
    } else {
      enqueueSnackbar(
        t('view.Tickets.downloadLaborTimesheetReportCsvFileIssue', {issue: result.error}),
        { variant: "info", autoHideDuration: 10000}
      )
    }
  }

  const refreshTicketList = () => {
    return <RefreshButton reload={loadTickets} />
  }

  const downloadTicketListInvoices = () => {
    return <DownloadInvoicesButton
      idArray={selectedTickets}
      title={t('view.Tickets.download_list_tooltip_title')}
      tooltipInfo={t("view.Tickets.download_list_tooltip_info")}
      submitValues={downloadTicketInvoices}
    />
  }

  const TicketStatusChart = project.project_user_role === 'pm' || project.project_user_role === 'super'
    ? <TicketListStatusChart/>
    : <></>

  return (
    <>
      <Container fullWidth removeBottom>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Tickets.tickets")}</Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            md={6}
          >
            {
              showDownloadLaborTimesheetButton
                ? (
                  <Fab variant="extended" onClick={downloadLaborTimesheet}>
                    {t("view.Tickets.downloadLaborTimesheetButton")}
                  </Fab>
                )
                : <></>
            }
            {showAddTicketButton ? (
              <FeatureAccessButton
                canAccessFeature={canAddTickets}
                supportDialogFeatureDesc={t("view.Tickets.supportDialogFeatureTicketDesc")}
                supportDialogTitle={t("view.Tickets.add")}
                linkProps={{
                  url: `/0/project/${projectId}/tickets/add`,
                  buttonText: t("view.Tickets.button"),
                  LeadingIconComponent: <AddIcon/>
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>

      {TicketStatusChart}

      <Container fullWidth removeTop>
        <DataTable
          // NOTE: for row conditional styling/coloring see:
          // https://stackoverflow.com/questions/73113770/mui-v4-how-to-use-conditional-row-coloring
          getRowClassName={(params: any) => {
            return params.row?.change_order_number
              ? attachedToChangeOrder
              :  ""
          }}
          columns={columns}
          filterModel={filter}
          onFilterModelChange={setFilter}
          rows={readyToShowTickets ? tickets.filter((item: any) => item && item.id) : []}
          loading={loadingStatus === "loading"}
          sx={attachedStyling}
          additionalToolbars={
            [downloadTicketListInvoices, refreshTicketList]
          }
          showExportToolbar={true}
          checkboxSelectionVisibleOnly
          rowsSelected={selectedTickets}
          setRowsSelected={(checkedTickets: any[]) => {
            setSelectedTickets(checkedTickets)
          }}
          columnVisibilityModel={globalTableColumns}
          onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
            setGlobalTableColumns(newModel)
          }}
        />
      </Container>

      {/* Confirm Cancel Delete Ticket Dialog */}
      <Dialog
        open={openDeleteTicketDialog}
        onClose={closeConfirmCancelDeleteTicketDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt
          submitForm={deleteTicket}
          closeDialog={closeConfirmCancelDeleteTicketDialog}
          dialogPrompt={t("view.Tickets.deleteTicketPrompt")}
        />
      </Dialog>
    </>
  )
}
