import React from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

// @ts-ignore
import Container from "../../../../components/Container"
import CreateTicketForm from "../../../../forms/CreateTicket"
import {createTicket, uploadTicketFiles} from "../../../../api/ticket"
// @ts-ignore
import { addTicket } from "../../../../store/features/ticketsSlice"
import {convertFormValuesToApiData} from "../../../../forms/CreateTicket.submit"
import {CreateTicketInitialValues} from "../../../../forms/CreateTicket.types"
import {useSnackbar} from "notistack"
import { goToSingleTicket } from "../../../../util/routes"

export default function CreateTicket() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const history = useHistory()
  const { projectId } = useParams<{projectId: string}>()
  const project = useSelector((state: any) => state.project)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitTicket = async (values: CreateTicketInitialValues, nextTicketNumber: string|null) => {
    try {
      const { data } = await createTicket(
        convertFormValuesToApiData(values, nextTicketNumber),
        projectId
      )
      if (data.id) {
        const newTicket = data
        enqueueSnackbar("Ticket Created", {
          variant: "success",
        })
        dispatch(addTicket(newTicket))
        // need to check that files actually exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
          const fileUploadMessage = await uploadTicketFiles(values.files, project.id, newTicket.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleTicket(project.id, newTicket.id))
      } else {
        enqueueSnackbar("There was a problem creating the ticket, code: 104", {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar("There was a problem creating the ticket, code: 102", {
        variant: "error",
      })
    }
  }

  return project && project.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.Tickets.add")}</Typography>
      </Container>

      <CreateTicketForm submitValues={submitTicket} project={project} />
    </>
  ) : (
    <></>
  )
}
