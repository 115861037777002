
export const goHome: string = '/0/home'

export const goToAccounts: string = '/0/accounts'

export const goToIntegrations: string = '/0/integrations'

export const goToCompanySettings: string = '/0/settings'

export function goToProjectOverview(projectId: string): string {
  return `/0/project/${projectId}/overview`
}

export function goToSingleTicket(projectId: string, ticketId: string): string {
  return `/0/project/${projectId}/ticket/${ticketId}`
}

export function goToTicketsList(projectId: string): string {
  return `/0/project/${projectId}/tickets`
}

export function goToSingleChangeOrder(projectId: string, coId: string): string {
  return `/0/project/${projectId}/changeorders/${coId}`
}

export function goToChangeOrdersList(projectId: string): string {
  return `/0/project/${projectId}/changeorders`
}

export function goToBulletinList(projectId: string): string {
  return `/0/project/${projectId}/bulletin`
}

export function goToCreateBulletin(projectId: string): string {
  return `/0/project/${projectId}/bulletin/add`
}

export function goToSingleBulletin(projectId: string, bulletinId: string): string {
  return `/0/project/${projectId}/bulletin/${bulletinId}`
}

export function goToEditBulletin(projectId: string, bulletinId: string): string {
  return `/0/project/${projectId}/bulletin/${bulletinId}/edit`
}

export function goToEstimateList(projectId: string): string {
  return `/0/project/${projectId}/estimate`
}

export function goToCreateEstimate(projectId: string): string {
  return `/0/project/${projectId}/estimate/add`
}

export function goToSingleEstimate(projectId: string, estimateId: string): string {
  return `/0/project/${projectId}/estimate/${estimateId}`
}

export function goToEditEstimate(projectId: string, estimateId: string): string {
  return `/0/project/${projectId}/estimate/${estimateId}/edit`
}

export function goToDailyList(projectId: string): string {
  return `/0/project/${projectId}/daily`
}

export function goToCreateDaily(projectId: string): string {
  return `/0/project/${projectId}/daily/add`
}

export function goToSingleDaily(projectId: string, dailyId: string): string {
  return `/0/project/${projectId}/daily/${dailyId}`
}

export function goToEditDaily(projectId: string, dailyId: string): string {
  return `/0/project/${projectId}/daily/${dailyId}/edit`
}

export const pathNeedsActiveCompany = (pathname: string) => {
  const routesWithoutActiveCompany = [
    goToAccounts,
    "/0/admin",
    goToIntegrations,
  ]
  return !routesWithoutActiveCompany.some(route => pathname.startsWith(route))
}
