import React from "react"

import PushToProcoreChangeEventForm from './ChangeEvent'


interface PushToProcoreContainerProps {
  projectId: string,
  changeOrder: any,
  closeDialog: () => void
}

export default function PushToProcoreContainer({projectId, changeOrder, closeDialog}: PushToProcoreContainerProps) {
  return (
    <div style={{height: '70vh'}}>
      <PushToProcoreChangeEventForm projectId={projectId} changeOrder={changeOrder} closeDialog={closeDialog}/>
    </div>
  )
}
