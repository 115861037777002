import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {CustomCode} from '../types/customCode'


export type CreateCustomCode = {
  description: string,
  code: string,
}
export async function createCompanyCode(
  data: CreateCustomCode,
): Promise<AxiosResponse<CustomCode>> {
  return api({
    method: "post",
    url: `/code`,
    data,
  })
}

export async function editCompanyCode(
  cc_id: string,
  data: CreateCustomCode,
): Promise<AxiosResponse<CustomCode>> {
  return api({
    method: "patch",
    url: `/code/${cc_id}`,
    data,
  })
}

export async function createProjectCode(
  project_id: string,
  data: CreateCustomCode,
): Promise<AxiosResponse<CustomCode>> {
  return api({
    method: "post",
    url: `/project/${project_id}/code`,
    data,
  })
}

export async function editProjectCode(
  project_id: string,
  cc_id: string,
  data: CreateCustomCode,
): Promise<AxiosResponse<CustomCode>> {
  return api({
    method: "patch",
    url: `/project/${project_id}/code/${cc_id}`,
    data,
  })
}
