import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"

// @ts-ignore
import { formatMoney } from "../../../../../../libs/format"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import {getChangeOrderMarkup} from "../../../../../../api/changeOrder"


interface ChangeOrderMarkupProps {
  projectId: string,
  changeOrderId: string,
  subtotal: number
}

export default function ChangeOrderMarkup(props: ChangeOrderMarkupProps) {
  const {projectId, changeOrderId, subtotal} = props
  const [markup, setMarkup] = useState<any[]>([])
  const { t } = useTranslation("private")

  useEffect(() => {
    if (changeOrderId) {
      getChangeOrderMarkup(projectId, changeOrderId).then(async (res) => {
        if (res.data?.length > 0) {
          setMarkup(res.data)
        }
      })
    }
  }, [changeOrderId])

  return markup.length > 0
    ? (
      <InvoiceMarkup
        rows={markup}
        total={subtotal}
        subTotalText={t('view.ChangeOrder.markupSubtotal')}
      />
    ) : <></>
}
