import React from "react"

//@ts-ignore
import Switch from "../../../../components/Fields/Switch"
//@ts-ignore
import Link from "../../../../components/Link"
import { formatDate } from "../../../../libs/format"
import { Company } from "../../../../types/company"
import { ChangeableLicenseField } from "../../../../types/project"

export const AdminProjectLicensesColumns = (
  t: (s: string) => string,
  company_id: string,
  currentCompany: Company,
  toggleActiveStatus: (projectLicenseId: number, field: ChangeableLicenseField, status?: boolean) => void
) => {
  return [
    {
      field: "project_name",
      headerName: t("view.Admin.projectName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 195,
      renderCell: (params: any) => (
        <Link to=
          {{
            pathname: `/0/admin/companies/${company_id}/project/${params.id}`,
            state: {
              company: currentCompany,
              project: params.row
            }
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "is_active",
      headerName: t("view.Admin.projectLicenseActive"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 145,
      renderCell: (params: any) => {
        return(
          <Switch
            name="is_active"
            checked={params.row.is_active}
            onChange={() => {
              toggleActiveStatus(params.row.project_license_id, "projectLicenseActiveStatus")
            }}
            leftText={t("form.label.yes")}
            rightText={t("form.label.no")}
          />
        )
      }
    },
    {
      field: "can_add_subs",
      headerName: t("view.Admin.projectLicenseCanAddSubs"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 125,
      hide: currentCompany.company_type === "trade",
      renderCell: (params: any) => {
        return(
          <Switch
            name="can_add_subs"
            checked={params.row.can_add_subs}
            onChange={() => {
              toggleActiveStatus(params.row.project_license_id, "canAddSubs", !params.row.can_add_subs)
            }}
            leftText={t("form.label.yes")}
            rightText={t("form.label.no")}
          />
        )
      }
    },
    {
      field: "change_order_is_active",
      headerName: t("view.Admin.projecLicenseChangeOrderActive"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 175,
      renderCell: (params: any) => {
        return(
          <Switch
            name="change_order_is_active"
            checked={params.row.change_order_is_active}
            onChange={() => {
              toggleActiveStatus(params.row.project_license_id, "changeOrderActiveStatus", !params.row.change_order_is_active)
            }}
            leftText={t("form.label.yes")}
            rightText={t("form.label.no")}
          />
        )
      }
    },
    {
      field: "date_started",
      headerName: t("view.Admin.projectLicenseDateStarted"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 150,
      renderCell: (params: any) => formatDate(params.value)
    },
    {
      field: "license_owner_name",
      headerName: t("view.Admin.projectLicenseOwnerName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 215,
      renderCell: (params: any) => params.row.license_owner_id === company_id ? t("view.Admin.projectLicenseSelfTitle") : params.value
    },
    {
      align: "left",
      headerAlign: "left",
      field: "all_gcs_on_project",
      headerName: t("view.Admin.gcCompaniesOnProjectTitle"),
      minWidth: 300,
      sortable: false,
      renderCell: (params: any) =>
        <div
          style = {{
            height: "150px",
            overflowX: "hidden",
          }}
        >
          <ul
            style = {{
              listStyle: "none",
              padding: "0px"
            }}
          >
            {
              params.row.gcCompaniesOnProject.map((cm: any) =>
                <li
                  style ={{padding: "5px"}}
                >
                  <Link to={`/0/admin/companies/${cm.id}`}>
                    {`${cm.name} (Seats: ${cm.active_users})`}
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
    },
    {
      align: "left",
      headerAlign: "left",
      field: "all_subs_on_project",
      headerName: t("view.Admin.subCompaniesOnProjectTitle"),
      minWidth: 250,
      sortable: false,
      renderCell: (params: any) =>
        <div
          style = {{
            height: "150px",
            overflowX: "hidden",
          }}
        >
          <ul
            style = {{
              listStyle: "none",
              padding: "0px"
            }}
          >
            {
              params.row.subCompaniesOnProject.map((trade: any) =>
                <li
                  style ={{padding: "5px"}}
                >
                  <Link to={`/0/admin/companies/${trade.id}`}>
                    {`${trade.name} (Seats: ${trade.active_users})`}
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
    },
  ]
}