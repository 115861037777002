import {Grid} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"

import {InvoiceSignature} from "../../../../../../components/Invoice/Signature"
import {getChangeOrderSignature} from "../../../../../../api/changeOrder"
import { InvoiceSignature as InvoiceSignatureType} from "../../../../../../types/signature"


interface ChangeOrderInvoiceSignatureProps {
  coId: string,
  projectId: string,
}

export function ChangeOrderInvoiceSignature(props: ChangeOrderInvoiceSignatureProps) {
  const {coId, projectId} = props
  const [signature, setSignature] = useState<InvoiceSignatureType | null>(null)
  const { t } = useTranslation("private")

  useEffect(() => {
    if (coId) {
      getChangeOrderSignature(projectId, coId)
        .then((res) => {
          if (res.status === 200 && !!res.data) {
            setSignature(res.data)
          }
        })
    }
  }, [coId, projectId])

  return signature != null ? (
    <Grid container spacing={2} sx={{ justifyContent: "flex-end", mt: 3 }}>
      <InvoiceSignature
        url={signature.url}
        signed_by={signature.signed_by}
        date_signed={signature.date_signed}
        approved_text={t('view.ChangeOrder.signatureApproved')}
      />
    </Grid>
  ) : (<></>)
}
