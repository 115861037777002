import React, {useMemo, useState} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import { MenuItem } from "@mui/material"
import Typography from "@mui/material/Typography"
import NextIcon from "@mui/icons-material/ArrowForwardIos"

// @ts-ignore
import Button from "../../../../../../../components/Button"
// @ts-ignore
import SelectField from "../../../../../../../components/Fields/Select"
// @ts-ignore
import TextAreaField from "../../../../../../../components/Fields/TextArea"
// @ts-ignore
import UploadMultipleField from "../../../../../../../components/Fields/UploadMultiple"
// @ts-ignore
import TextField from "../../../../../../../components/Fields/Text"
import {ProjectClient} from "../../../../../../../api/ticketReviewProcess"
import {ActionCode} from "../../../../../../../types/action"
import { makeUserName } from "../../../../../../../util/user"
// @ts-ignore
import Dialog from '../../../../../../../components/Dialog'
import ConfirmOrCancelPrompt from '../../../../../../../components/ConfirmOrCancelPrompt'

export type ReviewType = 'tm' | 'cost'

export interface TicketSubmitFormValues {
  pmClients: string[],
  superClients: string[],
  sendMeCopy: boolean,
  files: any[],
  notes: string,
  review_type: ReviewType,
}

interface ReviewTypeOption {
  label: string,
  value: ReviewType
}

const canSubmitForTmReview: ActionCode[] = ['draft', 'tm_revise', 'tm_submitted']

const canSubmitForCostReview: ActionCode[] = ['draft', 'tm_revise', 'tm_submitted', 'tm_approve', 'cost_submitted', 'cost_revise']

function createReviewTypeOptions(includesCosts: boolean, isCrew: boolean, actionCode: ActionCode): ReviewTypeOption[] {
  // crew or doesnt have costs included, then can only choose TM
  if (isCrew || !includesCosts) {
    return [{ label: "Time and Materials", value: "tm" }]
  }
  // if the only option is cost review, return cost only
  if (canSubmitForCostReview.includes(actionCode) && ! canSubmitForTmReview.includes(actionCode)) {
    return [{ label: "Cost", value: "cost" }]
  }
  // otherwise return both
  return [
    { label: "Time and Materials", value: "tm" },
    { label: "Cost", value: "cost" },
  ]
}

function initialReviewType(actionCode: ActionCode): ReviewType {
  // if it can be submitted for T&M, then pick as default
  return canSubmitForTmReview.includes(actionCode)
    ? 'tm'
    : 'cost'
}

const fileUploadLimitInMB: number = 10

const TicketSubmitForm = (props: {
  ticketIncludesCosts: boolean,
  ticketActionCode: ActionCode,
  toggle: () => void,
  submitValues: (values: any) => Promise<void>,
  userIsCrew: boolean,
  clients: ProjectClient[],
}): any => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const [showNoEmailsWarning, setShowNoEmailsWarning] = useState<boolean>(false)

  const closeNoEmailsWarningDialog = () => setShowNoEmailsWarning(false)

  const reviewTypeOptions = createReviewTypeOptions(
    props.ticketIncludesCosts,
    props.userIsCrew,
    props.ticketActionCode
  )
  const initReviewType = initialReviewType(props.ticketActionCode)

  const {pmClientUsers, superClientUsers} = useMemo(() => {
    return {
      pmClientUsers: props.clients.filter((c) => c.project_user_role_code === 'pm'),
      superClientUsers: props.clients.filter((c) => c.project_user_role_code === 'super'),
    }
  }, [props.clients])

  return (
    <Formik
      initialValues={{
        pmClients: [],
        superClients: [],
        sendMeCopy: false,
        files: [{name: ""}], // needs to be initialized like this
        notes: "",
        review_type: initReviewType,
      }}
      onSubmit={async () => {
        // NOTE: DO NOT USE THIS FUNCTION -- WE HAVE DEFINED IT INSIDE THE CALLBACK
        return
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
          isSubmitting,
          setSubmitting,
          resetForm,
        }) => {

        // we define submit fn inside so that we can use it after the dialog warning is shown
        const onSubmit = async (): Promise<void> => {
          setSubmitting(true)
          await props.submitValues(values)
          resetForm()
          setSubmitting(false)
        }

        const noEmailSelected = (values.superClients.length + values.pmClients.length) === 0

        const onSubmitButtonPressed = async () => {
          if (noEmailSelected) {
            setShowNoEmailsWarning(true)
          } else {
            await onSubmit()
          }
        }

        return (
          <>
            <Form>
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h2" align={isMobile ? "center" : "left"}>
                    {t("view.ChangeOrder.Send.recipients")}
                  </Typography>
                </Grid>

                {pmClientUsers.length > 0
                  ? <Grid item xs={12}>
                    <SelectField
                      label={t("view.Settings.manager")}
                      multiple={true}
                      name={'pmClients'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={pmClientUsers.map((user) => ({
                        label: makeUserName(user),
                        value: user.id,
                      }))}
                      value={values.pmClients || []}
                    />
                  </Grid>
                  : <></>
                }

                {superClientUsers.length > 0
                  ? <Grid item xs={12}>
                    <SelectField
                      label={t("view.Settings.super_intendent")}
                      multiple={true}
                      name={'superClients'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={superClientUsers.map((user) => ({
                        label: makeUserName(user),
                        value: user.id,
                      }))}
                      value={values.superClients || []}
                    />
                  </Grid>
                  : <></>
                }

                <Grid item xs={12} md={7}>
                  <TextField
                    label={t("view.Tickets.review_type")}
                    name="review_type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    multiline
                    minRows={1}
                    defaultValue={initReviewType}
                    disabled={reviewTypeOptions.length < 2}
                  >
                    {reviewTypeOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.sendMeCopy}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="sendMeCopy"
                      />
                    }
                    label={t("view.ChangeOrder.Send.send_copy")}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {errors.sendMeCopy && touched.sendMeCopy ? errors.sendMeCopy : ""}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12}>
                  <TextAreaField
                    label={t("form.label.notes")}
                    value={values.notes}
                    name="notes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadMultipleField
                    errors={errors}
                    label={t("form.label.uploadAttachments")}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    fileLimit={fileUploadLimitInMB}
                  />
                </Grid>
                <Grid container item xs={6} alignItems="center">
                  <Button color="secondary" variant="text" onClick={props.toggle}>
                    {t("view.ChangeOrder.cancel")}
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent={isMobile ? "flex-start" : "flex-end"}
                >
                  <Button
                    type="submit"
                    endIcon={<NextIcon />}
                    disabled={isSubmitting}
                    onClick={onSubmitButtonPressed}
                  >
                    {t("view.ChangeOrder.Summary.send")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
            <Dialog
              hideactions="true"
              open={showNoEmailsWarning}
              onClose={closeNoEmailsWarningDialog}
              title={t('view.Tickets.SingleTicket.TicketReview.ReviewForms.TicketSubmitForm.dialogWarningTitle')}
            >
              <ConfirmOrCancelPrompt
                submitForm={async () => {
                  closeNoEmailsWarningDialog()
                  await onSubmit()
                }}
                closeDialog={closeNoEmailsWarningDialog}
                dialogPrompt={t('view.Tickets.SingleTicket.TicketReview.ReviewForms.TicketSubmitForm.dialogWarningPrompt')}
              />
            </Dialog>
          </>

        )
      }}
    </Formik>
  )
}

export default TicketSubmitForm
