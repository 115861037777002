import {useTranslation} from 'react-i18next'
import React, {useMemo} from 'react'
import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import {DataGrid, GridColDef} from '@mui/x-data-grid'


export interface DisplayImportedObjectsUtils<O> {
  columns: GridColDef[],
  addIdToObject: (o: O, i: number) => O & {id: string},
  titleTranslateKey: string,
}

interface DisplayImportedObjectsProps<O> {
  objects: O[],
  displayUtils: DisplayImportedObjectsUtils<O>
}

export default function DisplayImportedObjects<O>(
  {objects, displayUtils}: DisplayImportedObjectsProps<O>
): JSX.Element {
  const {t} = useTranslation('private')
  const objectsForDisplay = useMemo(() => {
    return objects.map(displayUtils.addIdToObject)
  }, [objects, displayUtils.addIdToObject])

  return objects.length > 0
    ? (
      <Grid container item xs={12} rowSpacing={2}>
        <Grid container justifyContent="flex-start" item>
          <Typography variant="h3">{t(displayUtils.titleTranslateKey)}</Typography>
        </Grid>
        <Grid container justifyContent="flex-start" item style={{height: 500}}>
          <DataGrid columns={displayUtils.columns} rows={objectsForDisplay} />
        </Grid>
      </Grid>
    )
    : <></>
}

