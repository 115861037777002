import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import {apiHeaders} from "../../api/util"
import { TracFloState } from "../../types/state"
import {Integration, IntegrationStore} from "../../types/integration"

const initialState: IntegrationStore = {
  items: [],
  idObjectMapping: {},
  exp: "",
  status: "idle",
}

export const loadIntegrations = createAsyncThunk<Integration[], void, {state: TracFloState}>(
  "integration/loadIntegrations",
  async (args, thunkAPI
  ) => {
    const { user } = await thunkAPI.getState()
    const response = await axios({
      headers: apiHeaders(undefined, user.token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/integration`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  })

export const integrationSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadIntegrations.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadIntegrations.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadIntegrations.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          const integrations = action.payload
          state.items = integrations
          // Create id to code mapping
          state.idObjectMapping = integrations.reduce(
            (idObj: {[key: number]: Integration}, i) => {
              idObj[i.id] = i
              return idObj
            }, {}
          )
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 60 * 24 // one day
        }
      })
  },
})

export const {
  reset,
} = integrationSlice.actions

export default integrationSlice.reducer
