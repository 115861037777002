import React, {useMemo, useState} from "react"
import { useTranslation } from "react-i18next"
import {useDispatch} from 'react-redux'
import {useSnackbar} from 'notistack'

//@ts-ignore
import Dialog from "../../../../../components/Dialog"
import { getCompany } from "../../../../../store/features/companySlice"
import { CompanyUserRoleCode } from "../../../../../types/userRoles"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import DataGridAccordion from "../../../../../components/DataGridAccordion"
import {
  addCustomCode,
  getCustomCodes,
  getProjectCustomCodes, updateCustomCode,
} from '../../../../../store/features/customCodeSlice'
import CodesListColumns from './Codes.columns'
import {CustomCode} from '../../../../../types/customCode'
import {getActiveProject, projectNotInitialized} from '../../../../../store/features/projectSlice'
import CreateEditCode, {CreateEditCodeValues, createInitialValues} from '../../../../../forms/CreateEditCode'
import {createProjectCode, editProjectCode} from '../../../../../api/customCode'


function userCanEditRow(companyUserRole: CompanyUserRoleCode): boolean {
  return companyUserRole !== "crew"
}

type CodesListDialog = 'edit' | null

export default function CodesList() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const allCodes = useTracFloSelector(getCustomCodes)
  const company = useTracFloSelector(getCompany)
  const project = useTracFloSelector(getActiveProject)
  const [dialog, setDialog] = useState<CodesListDialog>(null)
  const [editCc, setEditCc] = useState<CustomCode | null>(null)

  const closeDialog = () => {
    setDialog(null)
    setEditCc(null)
  }

  const handleEdit = useMemo(() => {
    return async (cc: CustomCode): Promise<void> => {
      setEditCc(cc)
      setDialog('edit')
    }
  }, [setEditCc, setDialog])

  const rows = useMemo(() => {
    if (projectNotInitialized(project)) {
      return []
    }
    return getProjectCustomCodes(allCodes, project.id)
  }, [allCodes, project])

  const columns = useMemo(() => {
    return CodesListColumns({
      editable: userCanEditRow(company.company_user_role),
      handleEdit,
      t,
      projectId: project.id
    })
  }, [handleEdit, company.company_user_role, t, project.id])

  const submitCreate = useMemo(() => {
    return async (values: CreateEditCodeValues) => {
      // early return if project not ready
      if (projectNotInitialized(project)) return
      // before submitting, make sure that this code is not currently being used
      const findCode = allCodes.find((cc) => cc.code === values.code)
      if (findCode != null) {
        enqueueSnackbar(t("view.ProjectSettings.Codes.createDuplicateCode"), {
          variant: "error",
        })
        return
      }
      try {
        const {data} = await createProjectCode(project.id, values)
        if (!!data?.id) {
          dispatch(addCustomCode(data))
          enqueueSnackbar(t("view.ProjectSettings.Codes.createSuccess"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.ProjectSettings.Codes.createFail"), {
            variant: "error",
          })
        }
      } catch (e) {
        enqueueSnackbar(t("view.ProjectSettings.Codes.createFail"), {
          variant: "error",
        })
      }
    }
  }, [dispatch, enqueueSnackbar, t, allCodes, project])

  const submitEdit = useMemo(() => {
    return async (values: CreateEditCodeValues) => {
      // early return if we dont have an object set or project not ready
      if (editCc == null || projectNotInitialized(project)) return
      // before submitting, make sure that this code is not currently being used (by non-edited)
      const findCode = allCodes.find((cc) => cc.code === values.code && cc.id !== editCc.id)
      if (findCode != null) {
        enqueueSnackbar(t("view.ProjectSettings.Codes.editDuplicateCode"), {
          variant: "error",
        })
        return
      }
      try {
        const {data} = await editProjectCode(project.id, editCc.id, values)
        if (!!data?.id) {
          dispatch(updateCustomCode(data))
          enqueueSnackbar(t("view.ProjectSettings.Codes.editSuccess"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.ProjectSettings.Codes.editFail"), {
            variant: "error",
          })
        }
      } catch (e) {
        enqueueSnackbar(t("view.ProjectSettings.Codes.editFail"), {
          variant: "error",
        })
      }
    }
  }, [dispatch, enqueueSnackbar, t, allCodes, editCc, project])

  return (
    <>
      <DataGridAccordion
        items={[
          {
            title: t("view.Settings.CodesList.title"),
            columns,
            rows,
            addForm: {
              submitText: '',
              openButtonText: t("view.Settings.CodesList.openAddFormButtonText"),
              title: t("view.Settings.CodesList.addFormTitle"),
              form: CreateEditCode,
              props: {
                submit: submitCreate
              }
            },
          },
        ]}
      />

      <Dialog
        open={dialog === "edit"}
        onClose={closeDialog}
        title={t("view.ProjectSettings.Codes.editDialogTitle")}
        maxWidth={"md"}
      >
        <CreateEditCode
          initialValues={createInitialValues(editCc)}
          submit={submitEdit}
          onCancel={closeDialog}
        />
      </Dialog>
    </>
  )
}
