import React from "react"
import AddIcon from "@mui/icons-material/Add"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"

import Fab from "../../../../components/Fab"
import {CompanyUserRoleCode} from "../../../../types/userRoles"


export const UsersTopButtons = (
  setDialog: any,
  companyUserRole: CompanyUserRoleCode,
  theme: Theme,
  t: any,
): any[] => {
  return companyUserRole === 'admin' ? [
    (
      <Grid item>
        <Fab
          // @ts-ignore
          onClick={() => setDialog("inviteUserForm")}
          size="medium"
          variant="extended"
          style={{ backgroundColor: "white", color: theme.palette.secondary.main }}
        >
          <AddIcon />
          {t("view.Settings.invite")}
        </Fab>
      </Grid>
    ),
  ] : []
}
