import React, {useEffect, useMemo} from "react"
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {useDispatch} from 'react-redux'

// @ts-ignore
import Container from "../components/Container"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
import {ProductionMetric} from '../types/productionMetric'
import {CreateEditDailyProductionMetric} from './CreateDaily.types'
import {BreakdownTableColumn} from '../components/BreakdownTable.columns'
import CreateDailyProductionMetricsColumns from './CreateDailyProductionMetrics.columns'
import {useTranslation} from 'react-i18next'
import BreakdownTable from '../components/BreakdownTable'
import {useTracFloSelector} from '../store/useTracFloSelector'
import {reloadProductionMetricIfInvalid} from '../store/features/productionMetricSlice'


type DisplayDailyProductionMetrics = {
  amount: number | string,
  production_metric_id: string,
  description: string,
  unit: string,
}

function createDisplayDpmsFromPmAndDpms(
  pm: ProductionMetric,
  dpms: CreateEditDailyProductionMetric[]
): DisplayDailyProductionMetrics {
  const dpm = dpms.find((dpm) => dpm.production_metric_id === pm.id)
  return {
    amount: dpm == null ? 0 : dpm.amount,
    production_metric_id: pm.id,
    description: pm.description,
    unit: pm.unit,
  }
}

type CreateDailyProductionMetricsFormProps = {
  dailyPms: CreateEditDailyProductionMetric[],
  updateDpm: (amount: number | string, pmId: string, ) => void,
  projectId: string | null,
  editable: boolean,
}

export default function CreateDailyProductionMetricsForm(
  {
    dailyPms,
    updateDpm,
    projectId,
    editable,
  }: CreateDailyProductionMetricsFormProps
): JSX.Element {
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const pmStore = useTracFloSelector((s) => s.productionMetric)

  useEffect(() => {
    // make sure we have production metrics loaded
    if (!!projectId) {
      reloadProductionMetricIfInvalid(projectId, pmStore.projectId, pmStore.exp, dispatch)
    }
  }, [projectId, pmStore, dispatch])

  const rows: DisplayDailyProductionMetrics[] = useMemo(() => {
    // every PM should have a display row
    return pmStore.items.map((pm) => createDisplayDpmsFromPmAndDpms(pm, dailyPms))
  }, [pmStore.items, dailyPms])

  const columns: BreakdownTableColumn[] = useMemo(() => {
    return CreateDailyProductionMetricsColumns({
      editable: editable,
      handleAmountChange: updateDpm,
      t,
    })
  }, [updateDpm, t, editable])

  return pmStore.items.length === 0
    ? (<></>)
    : (
      <Container removeTop>
        <FormWideContainer>
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12}>
              <Typography variant="h2">
                {t('form.CreateDailyProductionMetrics.title')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} lg={9}>
              <BreakdownTable columns={columns} totals={null} rows={rows} editable={editable}/>
            </Grid>
          </Grid>
        </FormWideContainer>
      </Container>
    )
}
