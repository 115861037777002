import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import {ProjectsCreatedInLastSixtyDaysColumns} from "./ProjectsCreatedInLastSixtyDays.columns"
import {getProjectsCreatedInLastSixtyDays} from "../../../../../api/admin"
//@ts-ignore
import FormWideContainer from "../../../../../components/FormWideContainer"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import { ProjectCreatedInLastSixtyDays } from "../../../../../types/project"


export default function ProjectsCreatedInLastSixtyDaysView() {
  const [ projectsCreatedInLastSixtyDays,
          setProjectsCreatedInLastSixtyDays
  ] = useState<ProjectCreatedInLastSixtyDays[]>([])
  const { t } = useTranslation("private")

  useEffect(() => { 
    getProjectsCreatedInLastSixtyDays().then(
      (res) => {
        if (res?.data?.length > 0) {
          setProjectsCreatedInLastSixtyDays(res.data)
        }
      }
    )
  }, [])

  const columns = ProjectsCreatedInLastSixtyDaysColumns(t)

  return (
    <>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.Admin.projectsCreatedLastSixtyDaysTitle")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            rows={projectsCreatedInLastSixtyDays}
            getRowId={(row: any) => row.id}
            showCheckboxes={false}
            getRowHeight={() => 'auto'}
          />
        </Grid>
      </FormWideContainer>
    </>
  )
}