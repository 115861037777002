import React from "react"
import { Grid } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { useTranslation } from "react-i18next"

// @ts-ignore
import Fab from "../../../../../components/Fab"
// @ts-ignore
import Dialog from "../../../../../components/Dialog"
import EditCompanyLogo from "../../../../../forms/EditCompanyLogo"

export default function EditCompanyLogoButton(props: {company: any}) {
  const [dialog, setDialog] = React.useState(false)
  const { t } = useTranslation("private")

  const handleClose = () => {
    setDialog(false)
  }

  return (
    <>      
      <Grid item>
        <Fab 
        // @ts-ignore
        onClick={() => setDialog(true)} size="medium">
          <EditIcon />
        </Fab>
      </Grid>
      <Dialog open={dialog} onClose={() => setDialog(false)} title={t("form.label.edit_logo")}>
        <EditCompanyLogo onClose={handleClose} company={props.company}/>
      </Dialog>
    </>
  )
}
