import React from "react"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import * as Yup from "yup"

// @ts-ignore
import TextField from "./Text"


export const passwordValidation = (t: (s: string) => string): any => {
  return Yup.string()
    .required(t("form.message.passRequired"))
    .matches(
      /^.{10,}$/,
      t("form.message.passFormula")
    )
}

export default function Password(props: any) {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClick = () => setShowPassword(!showPassword)
  const handleMouseDown = (event: any) => event.preventDefault()

  return (
    <TextField
      label="Password"
      name="password"
      id="password"
      autocomplete={props.new ? 'new-password' : 'current-password'}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClick}
              onMouseDown={handleMouseDown}
              edge="end"
              size="large"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
