import React from "react"

// @ts-ignore
import Fab from "../../../../components/Fab"

export const AdminUsersColumns = (
  t: (s: string) => string,
  impersonate: (id: string) => void,
  resetPassword: (user: any) => void,
) => {
  return [
    {
      field: "email",
      headerName: t("view.Admin.usersEmail"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 300,
    },
    {
      field: "first_name",
      headerName: t("view.Admin.usersFirstName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 150,
    },
    {
      field: "last_name",
      headerName: t("view.Admin.usersLastName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "impersonate",
      headerName: t("view.Admin.usersImpersonateButton"),
      editable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Fab
            // @ts-ignore
            onClick={() => impersonate(params?.row?.id)}
            // @ts-ignore
            variant={'extended'}
            size={'small'}
          >
            {t("view.Admin.usersImpersonateButton")}
          </Fab>
        )
      },
    },
    {
      field: "resetPassword",
      headerName: t("view.Admin.resetUserPasswordColLabel"),
      editable: false,
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Fab
            // @ts-ignore
            onClick={() => resetPassword(params.row)}
            // @ts-ignore
            variant={'extended'}
            size={'small'}
          >
            {t("view.Admin.resetUserPasswordButton")}
          </Fab>
        )
      },
    },
  ]
}
