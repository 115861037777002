import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"
import {useParams} from "react-router-dom"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useDispatch } from "react-redux"
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material'
import {GridColumnVisibilityModel} from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'

//@ts-ignore
import Container from "../../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import RefreshButton from "../../../../../components/refreshButton"
import { useFilter } from "../../../../../hook/tableFilter"
import {BulletinListColumns} from './BulletinList.columns'
import {listBulletins, loadBulletins, reloadBulletinsIfInvalid} from '../../../../../store/features/bulletinSlice'
import FeatureAccessButton from '../../../../../components/FeatureAccessButton'
import {useTableColumns} from '../../../../../hook/tableColumns'
import BulletinListStatusChart from './BulletinListStatusChart'


const tableName = 'BulletinsList'

export default function BulletinList() {
  const dispatch = useDispatch()
  const { projectId } = useParams<{projectId: string}>()
  const isSuperadmin = useTracFloSelector((state) => state.user.is_admin)
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const bulletins = useTracFloSelector(listBulletins)
  const bulletinsProjectId = useTracFloSelector((state) => state.bulletins.projectId)
  const bulletinsExpiry = useTracFloSelector((state) => state.bulletins.exp)
  const actionIdObjMapping = useTracFloSelector((state) => state?.bulletinAction?.idObjectMapping)
  const { t } = useTranslation("private")
  const [filter, setFilter] = useFilter(tableName)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const columns = BulletinListColumns(
    projectId,
    company.company_type,
    actionIdObjMapping,
    t,
  )

  const [globalTableColumns, setGlobalTableColumns] = useTableColumns(tableName, columns)

  const showAddBulletinButton = !isSuperadmin

  // TODO DEV-1745 add check for bulletins feature is active
  const canAddBulletins = project
    && project.project_license_is_active

  useEffect(() => {
    if (projectId === project.id) {
      reloadBulletinsIfInvalid(projectId, bulletinsProjectId, bulletinsExpiry, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project.id])

  const refreshBulletinList = () => {
    return <RefreshButton reload={loadBulletins} />
  }

  const BulletinStatusChart = project.project_user_role === 'pm' || project.project_user_role === 'super'
    ? <BulletinListStatusChart/>
    : <></>

  return (
    <>
      <Container fullWidth removeBottom>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Bulletins.BulletinList.header")}</Typography>
          </Grid>
          {
            showAddBulletinButton
              ? <Grid
                container
                item
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
                md={6}
              >
                <FeatureAccessButton
                  canAccessFeature={canAddBulletins}
                  supportDialogFeatureDesc={t("view.Bulletins.BulletinList.supportDialogFeatureDesc")}
                  supportDialogTitle={t("view.Bulletins.BulletinList.add")}
                  linkProps={{
                    url: `/0/project/${projectId}/bulletin/add`,
                    buttonText: t("view.Bulletins.BulletinList.button"),
                    LeadingIconComponent: <AddIcon/>
                  }}
                />
              </Grid>
              : <></>
          }
        </Grid>
      </Container>

      {BulletinStatusChart}

      <Container fullWidth removeTop>
        <DataTable
          checkboxSelection={false}
          columns={columns}
          rows={bulletins}
          filterModel={filter}
          onFilterModelChange={setFilter}
          additionalToolbars={
            [refreshBulletinList]
          }
          columnVisibilityModel={globalTableColumns}
          onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
            setGlobalTableColumns(newModel)
          }}
        />
      </Container>
    </>
  )
}
