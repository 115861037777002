import React from "react"

import AddSubButton from './AddSub.button'
//@ts-ignore
import api from "../../../../../libs/api"
// @ts-ignore
import { subcontractorColumns } from "../../../../../libs/tableColumns/SubcontractorColumns"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import DataGridAccordion from '../../../../../components/DataGridAccordion'
import {getSubsOnProject} from '../../../../../api/company'

export default function Subcontractors() {
  const [subcontractors, setSubcontractors] = React.useState([])
  const project = useTracFloSelector((state) => state.project)

  React.useEffect(() => {
    getSubsOnProject(project.id).then(
      (res: any) => {
        setSubcontractors(res.data)
      } // TODO BETTER ERROR HANDLING
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id])

  const subcontractorAccordion = [
    {
      title: "Active Subcontractors",
      columns: subcontractorColumns,
      rows: subcontractors,
    },
  ]

  return (
    <>
      <AddSubButton/>
      <DataGridAccordion items={subcontractorAccordion}></DataGridAccordion>
    </>
  )
}
