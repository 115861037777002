import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"
import {apiHeaders} from '../../api/util'
import {ProductionMetric, ProductionMetricStore} from '../../types/productionMetric'
import {compareAlphabetically} from '../../util/string'

const initialState: ProductionMetricStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadProductionMetric = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadProductionMetric",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/production_metric`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const productionMetricSlice = createSlice({
  name: "productionMetric",
  initialState,
  reducers: {
    addProductionMetric: (state, action: {payload: ProductionMetric}) => {
      state.items = [action.payload, ...state.items]
        .sort((m1, m2) =>
          compareAlphabetically(m1.description, m2.description)
        )
    },
    resetProductionMetric: () => initialState,
    updateProductionMetric: (state, action: {payload: ProductionMetric}) => {
      if (action.payload) {
        const updatedMetric = action.payload
        state.items = [updatedMetric, ...state.items.filter((t) => t.id !== updatedMetric.id)]
          .sort((m1, m2) =>
            compareAlphabetically(m1.description, m2.description)
          )
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProductionMetric.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadProductionMetric.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadProductionMetric.fulfilled, (
        state,
        action: {payload: {data: ProductionMetric[], projectId: string}}
      ) => {
        if (action.payload && action.payload.data) {
          state.items =  action.payload.data.map((m) => {return m})
            .sort((m1, m2) =>
              compareAlphabetically(m1.description, m2.description)
            )
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
  },
})

export const { addProductionMetric, updateProductionMetric, resetProductionMetric } = productionMetricSlice.actions

export const listProductionMetric = (state: TracFloState) => state.productionMetric.items

export const reloadProductionMetricIfInvalid = (
  projectId: string,
  pmProjectId: string | null,
  pmExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== pmProjectId || reduxStateExpired(pmExpiry)) {
    dispatch(loadProductionMetric())
  }
}

export default productionMetricSlice.reducer
