import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import BreakdownTable from './BreakdownTable'
import {EstimateLineItem} from '../api/estimate'
import EstimateLineItemsTableColumn from './EstimateLineItemsTable.columns'
import { calculateEstimateLineItemSubtotal } from '../util/estimate'


export type EstimateLineItemForTable = EstimateLineItem

interface EstimateLineItemsTableProps {
  editable: boolean,
  showCosts: boolean,
  lineItems: EstimateLineItemForTable[],
  handleDeleteRow?: (i: number) => void,
}

export default function EstimateLineItemsTable(
  {editable, lineItems, handleDeleteRow, showCosts}: EstimateLineItemsTableProps
): JSX.Element {
  const {t} = useTranslation('private')

  const columns = EstimateLineItemsTableColumn({
    editable,
    t,
    showCosts,
    handleDeleteRow
  })

  const totals = useMemo(() => {
    if (lineItems.length > 0 && showCosts) {
      return {total: calculateEstimateLineItemSubtotal(lineItems)}
    }
    return null
  }, [lineItems, showCosts])

  return (
    <BreakdownTable
      columns={columns}
      rows={lineItems}
      totals={totals}
      editable={editable}
      subtotalText={t('component.EstimateLineItemsTable.subtotalText')}
    />
  )
}




