import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid} from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import {CompanyType} from '../../../../../../../types/company'
import {EstimateActionCode} from '../../../../../../../types/action'
//@ts-ignore
import Button from "../../../../../../../components/Button"


function showTheseButtons(companyType: CompanyType, actionCode: EstimateActionCode): boolean {
  const showIfActionCode: EstimateActionCode[] = ['submitted']
  return companyType === 'cm' && showIfActionCode.includes(actionCode)
}

interface ApproveReviseButtonsProps {
  toggleReviseForm: () => void,
  toggleApproveForm: () => void,
  companyType: CompanyType,
  estimateActionCode: EstimateActionCode,
}

export default function ApproveReviseButtons(
  {companyType, toggleReviseForm, toggleApproveForm, estimateActionCode}: ApproveReviseButtonsProps
): JSX.Element {
  const { t } = useTranslation("private")
  return showTheseButtons(companyType, estimateActionCode) ? (
    <>
      <Grid item>
        <Button
          color="success"
          endIcon={<CheckBoxIcon />}
          style={{ minWidth: 125 }}
          onClick={toggleApproveForm}
        >
          {t("view.Estimates.SingleEstimate.Review.Approve.button")}
        </Button>
      </Grid>

      <Grid item>
        <Button
          color="warning"
          onClick={toggleReviseForm}
        >
          {t("view.Estimates.SingleEstimate.Review.Revise.button")}
        </Button>
      </Grid>
    </>
  ) : (<></>)
}
