import React from "react"
import {Route, Switch} from "react-router-dom"

import SettingsView from "./Settings"
import { goToCompanySettings } from "../../../../util/routes"

export default function CompanySettingsRouter() {
    return(
      <Switch>
        <Route path={goToCompanySettings}>
          <SettingsView />
        </Route>
      </Switch>
    )
  }