import React from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import {useHistory, useParams} from "react-router-dom"
import {useSnackbar} from "notistack"
import {useDispatch} from 'react-redux'

// @ts-ignore
import Container from "../../../../../components/Container"
import { CreateBulletinInitialValues } from "../../../../../forms/CreateBulletin.types"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import CreateBulletin from '../../../../../forms/CreateBulletin'
import {convertFormValuesToApiData} from '../../../../../forms/CreateBulletin.submit'
import {goToSingleBulletin, } from '../../../../../util/routes'
import {createBulletin, uploadBulletinFiles} from '../../../../../api/bulletin'
import {addBulletin} from '../../../../../store/features/bulletinSlice'


export default function CreateBulletinView() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const { projectId } = useParams<{projectId: string}>()
  const project = useTracFloSelector((state) => state.project)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()

  const submitBulletin = async (
    values: CreateBulletinInitialValues,
    nextBulletinPcoNumber: string | null,
  ) => {
    try {
      const res = await createBulletin(projectId, convertFormValuesToApiData(values, nextBulletinPcoNumber))
      if (res.status === 200 && res.data.id) {
        const bulletin = res.data
        enqueueSnackbar(t("view.Bulletins.CreateBulletin.createdSuccess"), {
          variant: "success",
        })
        dispatch(addBulletin(bulletin))
        // need to check that files actually exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar(
            t("view.Bulletins.CreateBulletin.uploadingFiles"),
            {variant: 'info'}
          )
          const fileUploadMessage = await uploadBulletinFiles(values.files, project.id, bulletin.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleBulletin(project.id, bulletin.id))
      } else {
        enqueueSnackbar(t("view.Bulletins.CreateBulletin.createdFail"), {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar(t("view.Bulletins.CreateBulletin.createdFail"), {
        variant: "error",
      })
    }
  }

  return project && project.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.Bulletins.CreateBulletin.header")}</Typography>
      </Container>

      <CreateBulletin
        submitValues={submitBulletin}
        projectId={project.id}
      />
    </>
  ) : (
    <></>
  )
}
