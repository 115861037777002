import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {useSnackbar} from 'notistack'

// @ts-ignore
import Container from "../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../components/DataTable"
// @ts-ignore
import Dialog from "../../../../components/Dialog"
import { resetToImpersonateUser } from "../../../../store/resetStoreService"
import {AdminUsersColumns} from "./AdminUsers.columns"
import {getUsersForAdmin, impersonateUser, resetUserPassword, UserForAdmin} from "../../../../api/admin"
import ResetUserPasswordForm from './ResetUserPassword.Form'


export default function AdminUsers() {
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const [adminUsers, setAdminUsers] = useState<UserForAdmin[]>([])
  const [showResetPwForm, setShowResetPwForm] = useState<boolean>(false)
  const [resetPwUser, setResetPwUser] = useState<UserForAdmin | null>(null)
  const { t } = useTranslation("private")

  const impersonate = async (id: string) => {
    const res = await impersonateUser(id)
    if (res.data.success) {
      await resetToImpersonateUser(dispatch, res.data.data.jwt)
    }
  }

  const closeResetPwForm = () => {
    setResetPwUser(null)
    setShowResetPwForm(false)
  }

  const openResetPwForm = (user: UserForAdmin) => {
    setResetPwUser(user)
    setShowResetPwForm(true)
  }

  const submitResetPassword = async (newPassword: string) => {
    if (resetPwUser == null) return
    try {
      const res = await resetUserPassword(resetPwUser.id, newPassword)
      if (res.status === 200) {
        enqueueSnackbar(t('view.Admin.resetUserPasswordSuccess'), {variant: 'success'})
      } else {
        enqueueSnackbar(t('view.Admin.resetUserPasswordFailure'), {variant: 'error'})
      }
    } catch (e: any) {
      enqueueSnackbar(t('view.Admin.resetUserPasswordFailure'), {variant: 'error'})
    }
  }

  useEffect(() => {
    getUsersForAdmin().then(
      (res) => {
        if (res?.data?.length) {
          setAdminUsers(res.data)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = AdminUsersColumns(t, impersonate, openResetPwForm)

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Admin.usersTitle")}</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container fullWidth removeTop>
        <DataTable
          columns={columns}
          rows={adminUsers}
          showCheckboxes={false}
        />
      </Container>

      <Dialog
        open={showResetPwForm}
        onClose={closeResetPwForm}
        maxWidth={'md'}
        title={t('view.Admin.resetUserPasswordTitle')}
      >
        <ResetUserPasswordForm user={resetPwUser} submit={submitResetPassword} closeForm={closeResetPwForm}/>
      </Dialog>
    </>
  )
}
