import React, {useMemo} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import EditIcon from "@mui/icons-material/Edit"
import Grid from "@mui/material/Grid"
import * as Yup from "yup"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import Select from "../components/Fields/Select"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
import { useTracFloSelector } from "../store/useTracFloSelector"
import {SupportedLanguage, SupportedLanguages} from "../types/language"


export interface EditProfileFormFields {
  first_name?: string,
  last_name?: string,
  phone?: string,
  language: SupportedLanguage
}

interface EditProfileProps {
  onCancel: () => void,
  submit: (user: EditProfileFormFields) => Promise<void>
}

export default function EditProfile(props: EditProfileProps) {
  const {onCancel, submit} = props
  const { t } = useTranslation("private")
  const user = useTracFloSelector((state) => state.user)
  const languageOptions = useMemo(() => {
    return Object.values(SupportedLanguages).map((sl) => ({value: sl.value, label: t(sl.display)}))
  }, [t])

  return (
    <Formik<EditProfileFormFields>
      validateOnChange={false}
      initialValues={{
        first_name: user.firstName ?? "",
        last_name: user.lastName ?? "",
        phone: user.phone ?? "",
        language: user.language
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required(t("form.EditUserProfile.firstNameRequired")),
        last_name: Yup.string().required(t("form.EditUserProfile.lastNameRequired"))
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await submit(values)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
        }) => {
        return (
          <Form>
            <FormSmallContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label={t("form.EditUserProfile.editProfileFirstName")}
                    name="first_name"
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("form.EditUserProfile.editProfileLastName")}
                    name="last_name"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("form.EditUserProfile.editProfilePhone")}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    autoFocus
                    label={t("form.EditUserProfile.languageLabel")}
                    name="language"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={languageOptions}
                    renderInput={(props: any) => <TextField {...props} />}
                    value={values.language}
                  />
                </Grid>
                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button onClick={onCancel} color="secondary" variant="text">
                    {t("form.EditUserProfile.editProfileCancelTitle")}
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    disabled={!Object.keys(touched).length || !isValid}
                    isLoading={isSubmitting}
                    type="submit"
                    endIcon={<EditIcon />}
                  >
                    {t("form.EditUserProfile.editProfile")}
                  </Button>
                </Grid>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
