import React from "react"
import Grid from "@mui/material/Grid"
import ShareIcon from '@mui/icons-material/Share'
import {useTranslation} from "react-i18next"

// @ts-ignore
import Button from "../../../../../../../components/Button"

interface ShareChangeOrderButtonProps {
  togglePocketForm: () => void,
}

export function ShareChangeOrderButton(props: ShareChangeOrderButtonProps) {
  const { t } = useTranslation("private")

  return (
    <Grid item>
      <Button
        endIcon={<ShareIcon />}
        style={{ minWidth: 125, backgroundColor: "white", color: "#143366" }}
        onClick={props.togglePocketForm}
      >
        {t("view.ChangeOrder.share")}
      </Button>
    </Grid>
  )
}
