import React from "react"

// @ts-ignore
import ButtonDelete from "../components/ButtonDelete"
// @ts-ignore
import FormatRowId from "../components/FormatRowId"
import FormatTableTitle from "../components/FormatTableTitle"
import { formatMoney } from "../libs/format"
import TinyTextField from "./TinyTextField"
import {convertToNumber} from "../util/number"
import {TFunction} from 'react-i18next'
import {BreakdownTableColumn} from '../components/BreakdownTable.columns'


type CreateDailyLaborBreakdownTableColumnsProps = {
  addRates: boolean,
  editable: boolean,
  handleDeleteRow: (i: number) => void,
  handleFieldChanges: (i: number, propNamesAndValues: any[]) => void,
  forCostEdit: boolean,
  t: TFunction,
}

export default function CreateDailyLaborBreakdownTableColumns(
  {
    addRates,
    editable,
    handleDeleteRow,
    handleFieldChanges,
    forCostEdit,
    t
  }: CreateDailyLaborBreakdownTableColumnsProps
): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      style: { paddingLeft: 0 },
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "type_name",
      headerName: t("view.Labor.labor_type"),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <FormatTableTitle>{params.value}</FormatTableTitle>
        )
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "cost_code",
      headerName: t("view.ChangeOrder.Labor.cost_code"),
      hide: !addRates,
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "rate_type_name",
      headerName: t("view.ChangeOrder.Labor.rate"),
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "quantity",
      headerName: t("view.ChangeOrder.Labor.crew_size"),
      width: 70,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return editable && !forCostEdit
          ? (<TinyTextField
            value={params.value}
            handleChange={
              (newValue) => handleFieldChanges(
                // params.index is "1 indexed", so we need to subtract 1
                params.index - 1,
                [
                  { // update the rate itself
                    fieldPropName: 'quantity',
                    newValue
                  },
                  { // and total changes too
                    fieldPropName: 'total_hours',
                    newValue: (convertToNumber(newValue) ?? 0) * (convertToNumber(params.hours) ?? 0)
                  },
                  {
                    fieldPropName: 'total_cost',
                    newValue: (convertToNumber(newValue) ?? 0)
                      * (convertToNumber(params.hours) ?? 0)
                      * (convertToNumber(params.rate) ?? 0)
                  }
                ]
              )
            }
            isNumber={true}
          />)
          : params.value
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "hours",
      headerName: t("view.ChangeOrder.Labor.hour_person"),
      width: 80,
      sortable: false,
      renderCell: (params: any) => {
        return editable && !forCostEdit
          ? (<TinyTextField
            value={params.value}
            handleChange={
              (newValue) => handleFieldChanges(
                // params.index is "1 indexed", so we need to subtract 1
                params.index - 1,
                [
                  { // update the rate itself
                    fieldPropName: 'hours',
                    newValue
                  },
                  { // and total changes too
                    fieldPropName: 'total_hours',
                    newValue: (convertToNumber(newValue) ?? 0) * (convertToNumber(params.quantity) ?? 0)
                  },
                  {
                    fieldPropName: 'total_cost',
                    newValue: (convertToNumber(newValue) ?? 0)
                      * (convertToNumber(params.quantity) ?? 0)
                      * (convertToNumber(params.rate) ?? 0)
                  }
                ]
              )
            }
            isNumber={true}
          />)
          : params.value
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_hours",
      headerName: t("view.ChangeOrder.Labor.total_hrs"),
      width: 70,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate",
      headerName: t("view.ChangeOrder.Labor.hour_rate"),
      hide: !addRates,
      flex: 1,
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_cost",
      headerName: t("view.ChangeOrder.Labor.total_cost"),
      hide: !addRates,
      flex: 1,
      minWidth: 90,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      headerName: "",
      hide: !editable || forCostEdit,
      width: 50,
      editable: false,
      sortable: false,
      style: { paddingRight: 0 },
      renderCell: (params: any) => (
        <ButtonDelete
          onClick={() => {
            if (handleDeleteRow) {
              handleDeleteRow(params.index)
            }
          }}
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        />
      ),
    },
  ]
}
