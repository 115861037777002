import React, {useEffect, useState} from "react"
import {Grid} from "@mui/material"
import {useTranslation} from "react-i18next"

import {InvoiceSignature} from "../../../../../../components/Invoice/Signature"
import { getEstimateSignature } from "../../../../../../api/estimate"
import { InvoiceSignature as InvoiceSignatureType } from "../../../../../../types/signature"


interface EstimateInvoiceSignatureProps {
  estimateId: string,
  projectId: string,
}

export function EstimateInvoiceSignature(
  {estimateId, projectId}: EstimateInvoiceSignatureProps
): JSX.Element {
  const [signature, setSignature] = useState<InvoiceSignatureType | null>(null)
  const { t } = useTranslation("private")

  useEffect(() => {
    if (estimateId) {
      getEstimateSignature(projectId, estimateId)
        .then((res) => {
          if (res.status === 200 && !!res.data) {
            setSignature(res.data)
          }
        })
    }
  }, [estimateId, projectId])

  return signature != null ? (
    <Grid container spacing={2} sx={{ justifyContent: "flex-end", mt: 3 }}>
      <InvoiceSignature
        url={signature.url}
        signed_by={signature.signed_by}
        date_signed={signature.date_signed}
        approved_text={t('view.Estimates.SingleEstimate.signatureApproved')}
      />
    </Grid>
  ) : (<></>)
}
