import React from "react"

import {DailyProductionMetric} from '../types/daily'
import CreateDailyProductionMetricsForm from '../forms/CreateDailyProductionMetrics'


type DailyProductionMetricsTableProps = {
  dailyPms: DailyProductionMetric[],
  projectId: string | null,
}

export default function DailyProductionMetricsTable(
  {
    dailyPms,
    projectId,
  }: DailyProductionMetricsTableProps
): JSX.Element {

  return (
    <CreateDailyProductionMetricsForm
      dailyPms={dailyPms}
      updateDpm={() => {/*intentionally blank, cannot be updated*/}}
      projectId={projectId}
      editable={false}
    />
  )
}
