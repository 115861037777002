import React from "react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from "react-redux"
import {GridColumnVisibilityModel} from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'
import {useSnackbar} from 'notistack'

//@ts-ignore
import Container from "../../../../../components/Container"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import FabLink from '../../../../../components/FabLink'
import {goToCreateDaily} from '../../../../../util/routes'
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import { useFilter } from "../../../../../hook/tableFilter"
import RefreshButton from "../../../../../components/refreshButton"
import { listDaily, loadDaily, reloadDailyIfInvalid } from "../../../../../store/features/dailySlice"
import { DailyListColumns } from "./DailyList.columns"
import {useTableColumns} from '../../../../../hook/tableColumns'
import Fab from '../../../../../components/Fab'
import {getDailyLaborTimesheetReport} from '../../../../../api/daily'
import {convertObjectListToCsvAndDownload} from '../../../../../util/csv/createAndDownloadCsv'
import {DailyLaborTimesheetReportCsvHeaders} from '../../../../../types/daily'
import ProductionTrackingSummary from '../../../../../components/ProductionTrackingSummary'
import {listProductionMetric} from '../../../../../store/features/productionMetricSlice'


const tableName = 'DailyList'

export default function DailyList() {
  const dispatch = useDispatch()
  const {t} = useTranslation('private')
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const { projectId } = useParams<{projectId: string}>()
  const company = useTracFloSelector((state) => state.company)
  const isSuperadmin = useTracFloSelector((state) => state.user?.is_admin)
  const project = useTracFloSelector((state) => state.project)
  const daily = useTracFloSelector(listDaily)
  const dailyProjectId = useTracFloSelector((state) => state.daily.projectId)
  const dailyExpiry = useTracFloSelector((state) => state.daily.exp)
  const productionMetrics = useTracFloSelector(listProductionMetric)
  const [filter, setFilter] = useFilter(tableName)

  const columns = DailyListColumns(project.project_user_role, t, projectId, productionMetrics)

  const [globalTableColumns, setGlobalTableColumns] = useTableColumns(tableName, columns)

  const showAddDailyButton = company.company_type === 'trade' && !isSuperadmin

  // only PMs at trades (superadmins allowed)
  const showDownloadLaborTimesheetButton = company.company_type === "trade" && project.project_user_role === 'pm'

  React.useEffect(() => {
    if (projectId === project.id) {
      reloadDailyIfInvalid(projectId, dailyProjectId, dailyExpiry, dispatch)
    }
  }, [projectId, project.id, dailyProjectId, dailyExpiry, dispatch])

  const refreshDailyList = () => {
    return <RefreshButton reload={loadDaily} />
  }

  const downloadLaborTimesheet = async (): Promise<void> => {
    const inProgressSbKey = enqueueSnackbar(
      t('view.Daily.DailyList.downloadLaborTimesheetReportInProgress'),
      { variant: "info", }
    )
    const res = await getDailyLaborTimesheetReport(project.id)
    if (res.status !== 200) {
      closeSnackbar(inProgressSbKey)
      enqueueSnackbar(t('view.Daily.DailyList.downloadLaborTimesheetReportFail'), { variant: "error", })
      return
    }
    if (res.data.length === 0) {
      closeSnackbar(inProgressSbKey)
      enqueueSnackbar(t('view.Daily.DailyList.downloadLaborTimesheetReportNoData'), { variant: "info", })
      return
    }
    const result = convertObjectListToCsvAndDownload(
      res.data,
      DailyLaborTimesheetReportCsvHeaders,
      'labor_data.csv'
    )
    closeSnackbar(inProgressSbKey)
    if (result.success) {
      enqueueSnackbar(t('view.Daily.DailyList.downloadLaborTimesheetReportSuccess'), { variant: "success", })
    } else {
      enqueueSnackbar(
        t('view.Daily.DailyList.downloadLaborTimesheetReportCsvFileIssue', {issue: result.error}),
        { variant: "info", autoHideDuration: 10000}
      )
    }
  }

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Daily.DailyList.header")}</Typography>
          </Grid>
          <Grid container item alignItems="center" justifyContent={"flex-end"} md={6}>
            {
              showDownloadLaborTimesheetButton
                ? (
                  <Fab variant="extended" onClick={downloadLaborTimesheet}>
                    {t("view.Daily.DailyList.downloadLaborTimesheetButton")}
                  </Fab>
                )
                : <></>
            }
            {showAddDailyButton ? (
              <FabLink
                url={goToCreateDaily(projectId)}
                buttonText={t("view.Daily.DailyList.addButton")}
                LeadingIconComponent={<AddIcon/>}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>

      <ProductionTrackingSummary/>

      <Container fullWidth removeTop>
        <DataTable
          checkboxSelection={false}
          columns={columns}
          rows={daily}
          filterModel={filter}
          onFilterModelChange={setFilter}
          additionalToolbars={
            [refreshDailyList]
          }
          columnVisibilityModel={globalTableColumns}
          onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
            setGlobalTableColumns(newModel)
          }}
          showExportToolbar={true}
        />
      </Container>
    </>
  )
}
