import React from "react"
import Grid from "@mui/material/Grid"
import {Form, Formik} from "formik"
import Typography from "@mui/material/Typography"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import TextField from "../components/Fields/Text"
import {UpdateInvoiceInformationFormFields} from './UpdateInvoiceInformationForm.submit'
import {DateField} from './components/DateField'


interface UpdateInvoiceInformationFormProps {
  onCancel: () => void,
  submitForm: (values: UpdateInvoiceInformationFormFields) => Promise<void>,
  invoiceInfo: UpdateInvoiceInformationFormFields,
}

export default function UpdateInvoiceInformationForm(
  {onCancel, submitForm, invoiceInfo}: UpdateInvoiceInformationFormProps
) {
  const { t } = useTranslation("private")

  return (
    <Grid container style={{ paddingTop: 30, paddingBottom: 30 }} justifyContent={"flex-start"}>
      <Formik<UpdateInvoiceInformationFormFields>
        initialValues={invoiceInfo}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true)
          await submitForm(values)
          resetForm()
          setSubmitting(false)
        }}
      >
        {({
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            isSubmitting
          }) => {
          return (
            <Form>
              <Grid item container xs={12}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Typography variant="h6" align={"left"} style={{ fontSize: 20 }}>
                    {t("view.Tickets.UpdatesDropdown.updateInvoiceNumberDateTitle")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DateField
                    width={'full'}
                    label={t("form.label.dateInvoice")}
                    value={values.date_invoice}
                    onChange={(date) => {
                      setFieldValue("date_invoice", date)
                    }}
                  />
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 20 }}>
                  <TextField
                    label={t("form.label.invoiceNumber")}
                    value={values.invoice_number}
                    name="invoice_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item>
                    <Button
                      type="reset"
                      style={{
                        color: "#0092D6",
                        backgroundColor: "transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        cursor: "pointer",
                        overFlow: "hidden",
                        outline: "0",
                        height: 40,
                      }}
                      color="secondary"
                      onClick={onCancel}
                      variant={null}
                    >
                      {t("form.label.cancel")}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      endIcon={<ArrowForwardIosIcon style={{ color: "white" }} />}
                      style={{ height: 40, borderRadius: "5" }}
                    >
                      {t("form.label.update")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
