import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"

import { notifyForCoShare, submitChangeOrderForShare } from "../../../../../../../api/changeOrderReviewProcess"
import ShareForm from "../../../../../../../forms/ProjectShareForm"
import { ProjectShareFormSubmitValues } from "../../../../../../../types/form"


interface shareChangeOrderFormProps {
  projectId: string
  toggleFormOpen: () => void
  changeOrder: any,
}

export default function ShareChangeOrderForm(props: shareChangeOrderFormProps) {  
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  async function shareChangeOrder(values: ProjectShareFormSubmitValues){
    const res = await submitChangeOrderForShare(
      props.projectId,
      props.changeOrder.id,
      values.notes
    )

    if (res == null || !res?.data?.id) {
      enqueueSnackbar(
        `${t("view.ChangeOrder.share_fail")}`,
        {variant: 'error'}
      )
      return
    }

    // Then send emails
    notifyForCoShare(res.data.id, props.projectId, values.shareWith)
    .then((response: any) => {
      if (response.status === 200) {
          enqueueSnackbar(
            `${t("view.ChangeOrder.share_success")}`,
            {variant: 'success'}
          )
          props.toggleFormOpen()
      } else {
          enqueueSnackbar(
            `${t("view.ChangeOrder.share_email_fail")}`,
            {variant: 'error'}
          )
      }
    })
    props.toggleFormOpen()
  }

  return (
    <ShareForm
      projectId={props.projectId}
      toggleFormOpen={() => props.toggleFormOpen()}
      submitValues={shareChangeOrder}
    />
  )
}
