import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'

import Fab from '../../../../../../components/Fab'
import {goToEditDaily} from '../../../../../../util/routes'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {getUser} from '../../../../../../store/features/userSlice'
import {getCompany} from '../../../../../../store/features/companySlice'


interface DailyEditButtonProps {
  projectId?: string,
  dailyId?: string,
  dailyIsActive: boolean,
}

export default function DailyEditButton(
  {projectId, dailyId, dailyIsActive}: DailyEditButtonProps
): JSX.Element {
  const user = useTracFloSelector(getUser)
  const company = useTracFloSelector(getCompany)
  // if you can see this, you can edit it, unless you're a superadmin
  const showEditButton = projectId && dailyId
    && company.company_type === 'trade'
    && !user.is_admin
    && dailyIsActive
  const { t } = useTranslation("private")

  return showEditButton
    ? (
      <Fab
        color="secondary"
        component={Link}
        edge="end"
        size="medium"
        to={goToEditDaily(projectId, dailyId)}
        variant="extended"
      >
        <EditIcon />
        {t("view.Daily.SingleDaily.editButton")}
      </Fab>
    ) : <></>
}
