import { 
  notifyForShareBulletin, 
  shareBulletin, 
  uploadBulletinReviewProcessFiles 
} from "../../../../../../api/bulletinReviewProcess"
import { ProjectShareAndUploadFormSubmitValues } from "../../../../../../types/form"

export interface SubmitForReviewProps {
  values: ProjectShareAndUploadFormSubmitValues,
  bulletinId: string,
  projectId: string,
  t: (s: string) => string,
  enqueueSnackbar: (message: string, options: any) => any,
  closeSnackbar: (snackbarKey:  string | number) => void
}

export async function submitShareBulletin(
  {bulletinId, projectId, values, t, enqueueSnackbar, closeSnackbar}: SubmitForReviewProps
): Promise<void> {

  // Share the bulletin
  const res = await shareBulletin(
    projectId,
    bulletinId,
    values.notes,
  )
  if (!(res.status === 200 && res.data.id)) {
    enqueueSnackbar(t('view.Bulletins.SingleBulletin.ShareBulletin.fail'), {variant: 'error'})
    return
  }

  // Upload any attached files
  const filesForUpload = values.files
  if (filesForUpload.length > 0 && !!filesForUpload[0].name) {
    const uploadingKey = enqueueSnackbar(
      t('view.Bulletins.SingleBulletin.ShareBulletin.uploadingFiles'),
      {variant: 'info', style: {whiteSpace: 'pre-wrap'}}
    )
    const fileUploadMessage = await uploadBulletinReviewProcessFiles(
      filesForUpload,
      projectId,
      res.data.id
    )
    closeSnackbar(uploadingKey)
    if (fileUploadMessage.error) {
      enqueueSnackbar(fileUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      return
    }
  }

  // Then send emails
  const emailsRes = await notifyForShareBulletin(
    projectId,
    res.data.id,
    values.shareWith
  )
  if (emailsRes.status === 200 && emailsRes.data.success) {
    // Clean up
    enqueueSnackbar(
      t('view.Bulletins.SingleBulletin.ShareBulletin.success'),
      {variant: 'success'}
    )
  } else {
    enqueueSnackbar(
      t('view.Bulletins.SingleBulletin.ShareBulletin.emailFail'),
      {variant: 'error'}
    )
  }

}