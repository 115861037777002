import React from "react"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Theme } from "@mui/material"
import {ChangeOrderActionCode, getChangeOrderActionLabel} from "../types/action"
import {getChangeOrderActionFill} from '../style/action'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  draft: {
    background: getChangeOrderActionFill('draft'),
  },
  submitted: {
    background: getChangeOrderActionFill('submitted'),
  },
  approve: {
    background: getChangeOrderActionFill('approve'),
  },
  revise: {
    background: getChangeOrderActionFill('revise'),
  },
}))

export default function ChangeOrderAction(props: { code: ChangeOrderActionCode }) {
  const { t } = useTranslation("private")
  const company = useSelector((state: any) => state.company)

  const classes = useStyles()
  const className = props.code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{getChangeOrderActionLabel(t, props.code, company.company_type)}</Typography>
    </Box>
  )
}
