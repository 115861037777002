import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import {useHistory, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useSnackbar} from "notistack"

// @ts-ignore
import Container from "../../../../../components/Container"
import {Ticket} from "../../../../../types/ticket"
import {ChangeOrder, ChangeOrderMarkup} from "../../../../../types/changeOrder"
import {
  editChangeOrder,
  getChangeOrderById,
  getChangeOrderFiles,
  getChangeOrderMarkup,
  getChangeOrderTickets, uploadChangeOrderFiles
} from "../../../../../api/changeOrder"
import CreateChangeOrderForm from "../../../../../forms/CreateChangeOrder"
import {initializeFormWithChangeOrderData} from "./EditChangeOrder.data"
import {convertFormValuesToApiData} from "../../../../../forms/CreateChangeOrder.submit"
import {CreateChangeOrderInitialValues} from "../../../../../forms/CreateChangeOrder.types"
import {goToSingleChangeOrder} from "../../../../../util/routes"
import {File} from '../../../../../types/file'
import { updateChangeOrder } from "../../../../../store/features/changeOrdersSlice"


export default function EditChangeOrder() {
  const { t } = useTranslation("private")
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { changeOrderId, projectId } = useParams<{changeOrderId: string, projectId: string}>()
  const project = useSelector((state: any) => state.project)
  const [changeOrder, setChangeOrder] = useState<ChangeOrder | null>(null)
  const [tickets, setTickets] = useState<Ticket[] | null>(null)
  const [markup, setMarkup] = useState<ChangeOrderMarkup[] | null>(null)
  const [files, setFiles] = useState<File[] | null>(null)
  // NOTE: this needs to be updated if more CO-related states are added
  const readyToInitialize: boolean = changeOrder != null && tickets != null && markup != null && files != null
    && project != null

  const submitEditedCo = async (
    values: CreateChangeOrderInitialValues,
    nextChangeOrderNumber: string|null,
    addedTickets: Ticket[]
  ) => {
    try {
      const { data } = await editChangeOrder(
        convertFormValuesToApiData(values, nextChangeOrderNumber, addedTickets),
        changeOrderId,
        projectId
      )
      if (data.id) {
        const updatedChangeOrder = data
        enqueueSnackbar(t("view.ChangeOrder.Edit.apiRequestSucceed"), {variant: "success",})
        dispatch(updateChangeOrder(updatedChangeOrder))
        // need to check that new files exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar(t("view.Files.uploading"), {variant: 'info'})
          const fileUploadMessage = await uploadChangeOrderFiles(values.files, project.id, updatedChangeOrder.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleChangeOrder(project.id, updatedChangeOrder.id))
      } else {
        enqueueSnackbar(t("view.ChangeOrder.Edit.apiRequestFailed"), {variant: "error",})
      }
    } catch(_) {
      enqueueSnackbar(t("view.ChangeOrder.Edit.apiRequestFailed"), {variant: "error",})
    }
  }

  // Get the CO info we need to populate the form
  useEffect(() => {
    getChangeOrderById(projectId, changeOrderId).then(
      (data: any) => {
        if (data?.id) {
          setChangeOrder(data)
        }
      }
    )

    getChangeOrderTickets(projectId, changeOrderId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setTickets(res.data)
        }
      }
    )

    getChangeOrderMarkup(projectId, changeOrderId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setMarkup(res.data)
        }
      }
    )

    getChangeOrderFiles(projectId, changeOrderId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setFiles(res.data)
        }
      }
    )
  }, [projectId, changeOrderId])

  return project?.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.ChangeOrder.Edit.title")}</Typography>
      </Container>

      { readyToInitialize
        ? <CreateChangeOrderForm
          submitValues={submitEditedCo}
          projectId={projectId}
          initialTickets={tickets as Ticket[]}
          initialValues={initializeFormWithChangeOrderData(changeOrder as ChangeOrder, markup as ChangeOrderMarkup[])}
          existingFiles={files as any[]}
          submitButtonText={t("view.ChangeOrder.Edit.submitButton")}
        /> : <></>
      }
    </>
  ) : <></>
}
