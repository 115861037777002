import React from "react"
import { makeStyles } from "@mui/styles"
import { Theme, Typography } from "@mui/material"

//@ts-ignore
import logo from "../assets/tracflo_horizontal_light.svg"

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      height: "auto",
      maxHeight: theme.mixins.toolbar.minHeight,
      maxWidth: "100%",
      objectFit: "contain",
    },
    name: {
      maxHeight: theme.mixins.toolbar.minHeight,
    },
  }
})

interface LogoProps {
  imgUrl?: string
  companyName: string
  tracFloLogo?: boolean
}

export default function Logo(props: LogoProps) {
  const classes = useStyles()
  return props.tracFloLogo ? (
    <img alt={"TracFlo Logo"} className={classes.root} src={logo}/>
  ) : props.imgUrl && props.imgUrl !== "null" ? (
    <img alt={props.companyName} className={classes.root} src={props.imgUrl} />
  ) : (
    <Typography className={classes.root} color="primary" variant="h2">
      {props.companyName}
    </Typography>
  )
}
