import React, {useState} from "react"
import {useTranslation} from "react-i18next"

import Fab from "../../../../../../components/Fab"
import {integrationOrange} from "../../../../../../theme"
import {useTracFloSelector} from "../../../../../../store/useTracFloSelector"
import {getIntegrationObjForActiveIntegration} from "../../../../../../store/features/userSlice"
// @ts-ignore
import Dialog from "../../../../../../components/Dialog"
import PushToIntegrationForm from "./index"


interface PushToIntegrationButtonProps {
  allowedToPush: boolean, // change order has status that allows pushing
  projectId: string,
  changeOrder: any,
}

export default function PushToIntegrationButton(
  {allowedToPush, projectId, changeOrder}: PushToIntegrationButtonProps
) {
  const {t} = useTranslation('private')
  const activeIntegration = useTracFloSelector((s) => s.user.activeIntegration)
  const integrationObj = useTracFloSelector(getIntegrationObjForActiveIntegration)
  const [showPushToForm, setShowPushToForm] = useState<boolean>(false)
  // CO needs to be in a status that can be pushed
  const showButton = allowedToPush
    // and the current project must be integrated
    && activeIntegration != null
    && activeIntegration.projects != null
    && activeIntegration.projects.indexOf(projectId) > -1
    && integrationObj != null

  const closePushToFormDialog = () => {
    setShowPushToForm(false)
  }

  return showButton
    ? (
      <>
        <Fab
          style={{ backgroundColor: integrationOrange }}
          edge="end"
          size="medium"
          variant="extended"
          onClick={() => setShowPushToForm(true)}
        >
          {t('view.ChangeOrder.Integration.pushToButtonText') + integrationObj?.name}
        </Fab>
        <Dialog
          open={showPushToForm}
          onClose={closePushToFormDialog}
          maxWidth={'lg'}
          title={t('view.ChangeOrder.Integration.pushToButtonText') + integrationObj?.name}
        >
          <PushToIntegrationForm
            projectId={projectId}
            changeOrder={changeOrder}
            closeDialog={closePushToFormDialog}
          />
        </Dialog>
      </>
    ) : <></>
}
