import React, {useState, useEffect} from "react"
import { Theme } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"

import {BreakdownTableHeader} from "../../../../../../components/BreakdownTable.header"
import {BreakdownTableRows} from "../../../../../../components/BreakdownTable.rows"
import ChangeOrderTicketListColumns from "./ChangeOrderTicketList.columns"
import {BreakdownTableStyles} from "../../../../../../components/Invoice/BreakdownTableStyles"
import {ChangeOrderTicketListTotals} from "./ChangeOrderTicketList.totals"
import {getChangeOrderTickets} from "../../../../../../api/changeOrder"
import {convertToNumber} from "../../../../../../util/number"

const useStyles = makeStyles<Theme>((theme) => ({
  subject: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    overflow: "hidden",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: 330,
  }
}))

function calculateTicketTotals(tickets: {total_amount: number}[]): number {
  return tickets.reduce(
    (total: number, t) => {
      return total + t.total_amount
    }, 0
  )
}

interface ChangeOrderTicketListProps {
  projectId: string,
  changeOrderId: string,
  setTicketTotal: (total: number) => void,
  isLumpSum: boolean,
  total: number,
}

export default function ChangeOrderTicketList(props: ChangeOrderTicketListProps) {
  const {projectId, changeOrderId, setTicketTotal, isLumpSum, total} = props
  const [tickets, setTickets] = useState<any[]>([])
  const { t } = useTranslation("private")
  const classes = BreakdownTableStyles()
  const columnClasses = useStyles()
  const columns = ChangeOrderTicketListColumns({projectId, subjectFormatting: columnClasses.subject, isLumpSum})

  // get tickets
  useEffect(() => {
    if (!!changeOrderId) {
      getChangeOrderTickets(projectId, changeOrderId).then(async (res) => {
        if (res.data?.length > 0) {
          setTickets(
            // need to add index to numbers, and fix total amount type
            res.data.map((t: any, i: number) => {
              t.index = i+1
              t.total_amount = convertToNumber(t.total_amount) ?? 0
              return t
            })
          )
          setTicketTotal(calculateTicketTotals(res.data))
        }
      })
    }
  }, [changeOrderId])

  return tickets.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t('view.ChangeOrder.tickets')}</Typography>
      </Grid>
      <TableContainer className={classes.container}>
        <Table size="small" className={classes.table}>

          <BreakdownTableHeader columns={columns}/>

          <TableBody>
            <BreakdownTableRows rows={tickets} columns={columns}/>
            <ChangeOrderTicketListTotals
              total={total}
              subtotalText={t('view.ChangeOrder.ticketSubtotal')}
              numberOfColumns={columns.length}
              isLumpSum={isLumpSum}
            />
          </TableBody>

        </Table>
      </TableContainer>
    </>
  ) : (
    <></>
  )
}
