import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// @ts-ignore
import Container from "../components/Container"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import FormSmallContainer from '../components/FormSmallContainer'
import Fab from '../components/Fab'
import CreateBulletinSubsForm, {BulletinSubcontractorForForm} from './CreateBulletinSubs.form'
import {ListOption} from '../types/form'
import {getSubsOnProject, SubOnProject} from '../api/company'
import BulletinSubsTable, {BulletinSubcontractorForTable} from '../components/BulletinSubsTable'
import {deleteAtIndex} from '../util/array'


function convertSubToListOption(sub: SubOnProject): ListOption<string> {
  return {value: sub.id, label: sub.name}
}

interface CreateBulletinSubcontractorsProps {
  projectId: string,
  selectedSubs: BulletinSubcontractorForTable[],
  setSelectedSubs: (s: BulletinSubcontractorForTable[]) => void,
}

export default function CreateBulletinSubcontractors(
  {projectId, selectedSubs, setSelectedSubs}: CreateBulletinSubcontractorsProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [formActive, setFormActive] = useState<boolean>(false)
  const [allSubcontractorOptions, setAllSubcontractorOptions] = useState<ListOption<string>[]>([])
  const [subcontractorOptions, setSubcontractorOptions] = useState<ListOption<string>[]>([])

  const addNewSubContractor = (newSub: BulletinSubcontractorForForm) => {
    const newSubCorrectDataType: BulletinSubcontractorForTable = {
      ...newSub,
      id: null,
      estimate_id: null,
      estimate_action_id: null,
      estimate_total_amount: null,
    }
    setSelectedSubs([...selectedSubs, newSubCorrectDataType])
    setFormActive(false)
  }

  const deleteSubcontractor = (atIndex: number) => {
    const atZeroIndex = atIndex - 1
    if (selectedSubs.length > atZeroIndex && atZeroIndex >= 0) {
      setSelectedSubs(deleteAtIndex(selectedSubs, atZeroIndex))
    }
  }

  useEffect(() => {
    getSubsOnProject(projectId).then((res) => {
      if (res.status === 200 && Array.isArray(res.data)) {
        setAllSubcontractorOptions(
          res.data.map(convertSubToListOption)
            .sort((a, b) => a.label.localeCompare(b.label))
        )
      }
    })
  }, [projectId])

  useEffect(() => {
    if (selectedSubs.length === 0) {
      setSubcontractorOptions(allSubcontractorOptions)
    } else {
      // remove the selected subs from all sub options
      const selectedSubIds = selectedSubs.map((ss) => ss.subcontractor_id)
      setSubcontractorOptions(
        allSubcontractorOptions.filter((so) => !selectedSubIds.includes(so.value))
      )
    }
  }, [selectedSubs, allSubcontractorOptions])

  return (
    <Container removeTop>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t('form.CreateBulletinSubs.title')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <BulletinSubsTable
            editable={true}
            selectedSubs={selectedSubs}
            handleDeleteRow={deleteSubcontractor}
            projectId={projectId}
            showEstimateActualCosts={false}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          {!formActive ? (
            <Fab
              variant="extended"
              disabled={subcontractorOptions.length === 0}
              onClick={() => setFormActive(!formActive)}
            >
              <AddIcon />
              {t('form.CreateBulletinSubs.addSubButton')}
            </Fab>
          ) : (
            <FormSmallContainer>
              <CreateBulletinSubsForm
                subcontractorOptions={subcontractorOptions}
                addNewSubcontractor={addNewSubContractor}
                projectId={projectId}
                closeForm={() => {setFormActive(false)}}
              />
            </FormSmallContainer>
          )}
        </Grid>
      </FormWideContainer>
    </Container>
  )
}
