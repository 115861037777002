import React from "react"

import ButtonDelete from "../../components/ButtonDelete"
import FormatRowId from "../../components/FormatRowId"
import FormatTableTitle from "../../components/FormatTableTitle"
import { formatMoney } from "../format"
import TinyTextField from "../../forms/TinyTextField"
import {convertToNumber} from "../../util/number"

export default function Material(props = {}) {
  const { addRates, editable, handleDeleteRow, handleFieldChanges, forCostEdit, type, t } = props
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      maxWidth: 50,
      sortable: false,
      renderCell: (params) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "type_name",
      headerName: t(`view.TicketBreakdowns.type`),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => <FormatTableTitle>{params.value}</FormatTableTitle>,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "cost_code",
      headerName: t("view.TicketBreakdowns.cost_code"),
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "unit",
      headerName: t("view.TicketBreakdowns.unit"),
      flex: 1,
      minWidth: 50,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "quantity",
      headerName: t("view.TicketBreakdowns.quantity"),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return editable && !forCostEdit
          ? (<TinyTextField
            value={params.value}
            handleChange={
              (newValue) => handleFieldChanges(
                // params.index is "1 indexed", so we need to subtract 1
                params.index - 1,
                [
                  { // update the rate itself
                    fieldPropName: 'quantity',
                    newValue
                  },
                  { // and total changes too
                    fieldPropName: 'total_cost',
                    newValue: convertToNumber(newValue) * convertToNumber(params.rate)
                  }
                ]
              )
            }
            isNumber={true}
          />)
          : params.value
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate",
      headerName: t("view.TicketBreakdowns.rate_unit"),
      hide: !addRates,
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        return editable
          ? (<TinyTextField
              fieldWidth={'100px'}
              value={params.value}
              handleChange={
                (newValue) => handleFieldChanges(
                  // params.index is "1 indexed", so we need to subtract 1
                  params.index - 1,
                  [
                    { // update the rate itself
                      fieldPropName: 'rate',
                      newValue
                    },
                    { // and total changes too
                      fieldPropName: 'total_cost',
                      newValue: convertToNumber(newValue) * convertToNumber(params.quantity)
                    }
                  ]
                )
              }
              isMoney={true}
              isNumber={true}
          />)
          : formatMoney(params.value, true)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_cost",
      headerName: t("view.TicketBreakdowns.total_cost"),
      hide: !addRates,
      flex: 1,
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      headerName: "",
      hide: !editable || forCostEdit,
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ButtonDelete
            onClick={() => {
              if (handleDeleteRow) {
                handleDeleteRow(params.index)
              }
            }}
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          />
        )
      },
    },
  ]
}
