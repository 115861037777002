import React from "react"
import InputAdornment from "@mui/material/InputAdornment"

// @ts-ignore
import TextField from "../components/Fields/Text"


interface TinyTextFieldProps {
  value: number | string,
  handleChange: (newVal: number | string) => void,
  isNumber: boolean,
  isMoney?: boolean,
  isPercent?: boolean,
  fieldWidth?: string,
  endAdornment?: JSX.Element,
}

export default function TinyTextField(props: TinyTextFieldProps) {
  return (
    <TextField
      style={{width: props.fieldWidth ?? '85px'}}
      size={'small'}
      fullWidth={false}
      InputProps={
        props.endAdornment
          ? {endAdornment: props.endAdornment}
          : props.isMoney
          ? {startAdornment: <InputAdornment position="start">$</InputAdornment>}
          : props.isPercent
          ? {endAdornment: <InputAdornment position="start">%</InputAdornment>}
          : {}
      }
      onChange={(e: any) => {props.handleChange(e.target.value)}}
      value={props.value}
      type={props.isNumber ? 'number' : undefined}
    />
  )
}
