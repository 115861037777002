import {Trans, useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'
import React, {ChangeEvent, useState} from 'react'
import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'

import {readCsvFileToString} from '../util/csv/read'
// @ts-ignore
import Container from './Container'
// @ts-ignore
import Button from './Button'
import DisplayImportedObjects, {DisplayImportedObjectsUtils} from './CsvImportDisplayObjects'


export interface CsvImportUtils<O> {
  convertCsvStringToObjectArray: (s: string) => O[]
}

export interface ImportInstructionsUtils {
  translateKey: string,
  translateComponents: JSX.Element[],
}

interface CsvImportProps<O> {
  onClose: () => void,
  importUtils: CsvImportUtils<O>,
  displayUtils: DisplayImportedObjectsUtils<O>,
  instructionsUtils: ImportInstructionsUtils,
  submit: (o: O[]) => Promise<void>,
  submitButtonTextTranslateKey: string,
}

export default function CsvImport<O>(
  {onClose, importUtils, submit, displayUtils, instructionsUtils, submitButtonTextTranslateKey}: CsvImportProps<O>
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const fileInputRef = React.createRef<any>()
  const [objectsForUpload, setObjectsForUpload] = useState<O[]>([])

  const processCsvFile = (e: ChangeEvent<HTMLInputElement>) => {
    readCsvFileToString(
      e,
      (err: string) => {enqueueSnackbar(t(err), {variant: 'error'})},
      (csvStr) => {
        try {
          const objects = importUtils.convertCsvStringToObjectArray(csvStr)
          setObjectsForUpload(objects)
        } catch (e: any) {
          enqueueSnackbar(t(e.message), {variant: 'error'})
        }
      }
    )
  }

  const submitImportedObjects = async () => {
    if (objectsForUpload.length > 0) {
      await submit(objectsForUpload)
      onClose()
    }
  }

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid container item xs={12} rowSpacing={2}>
          <Typography variant="h3">
            <Trans
              i18nKey={instructionsUtils.translateKey}
              t={t}
              components={instructionsUtils.translateComponents}
            />
          </Typography>

          <Grid container justifyContent="flex-start" item>
            <input
              type={'file'}
              accept={'.csv'}
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={processCsvFile}
            />
            <Button onClick={() => fileInputRef.current.click()}>
              {t("component.CsvImport.uploadButton")}
            </Button>
          </Grid>

          <DisplayImportedObjects objects={objectsForUpload} displayUtils={displayUtils}/>

          <Grid container justifyContent="flex-end" item>
            <Button
              disabled={objectsForUpload.length === 0}
              onClick={submitImportedObjects}
            >
              {t(submitButtonTextTranslateKey)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
