import React from "react"
import {GridColDef} from "@mui/x-data-grid"
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Tooltip } from "@mui/material"

import {CompanyType} from "../../../../../types/company"
import {ActionInfo, ChangeOrderActionCode} from "../../../../../types/action"
import ChangeOrderAction from "../../../../../components/ChangeOrderActions"
import {goToSingleChangeOrder} from "../../../../../util/routes"
import {formatMoney, formatDate} from '../../../../../libs/format'
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'

export const ChangeOrdersListColumns = (
  projectId: string,
  companyType: CompanyType,
  actionIdToInfo: {[key: number]: ActionInfo<ChangeOrderActionCode>},
  t: any,
  canDeleteChangeOrder: boolean,
  openConfirmCancelDeleteChangeOrderDialog: (co_id: string) => void
): any[] => {
  // GCs see the name of the creating company
  const coColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 110,
    },
    {
      align: "left",
      field: "pco_number",
      headerAlign: "left",
      headerName: t("view.Tickets.pco"),
      width: 170,
      editable: false,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Tickets.subject"),
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <FormatTableTitleLink
            url={goToSingleChangeOrder(projectId, params.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      align: "left",
      headerAlign: "center",
      field: "change_order_action_id",
      headerName: t("view.Tickets.status"),
      width: 180,
      editable: false,
      renderCell: (params: any) => {
        return <ChangeOrderAction
          code={actionIdToInfo[params.row.change_order_action_id].code as ChangeOrderActionCode}
        />
      },
      valueGetter: (params: any) => actionIdToInfo[params.value].name,
      type: 'string'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t("view.Tickets.total"),
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return formatMoney(params.value)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_modified",
      headerName: t("view.Tickets.modified"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
    {
      field: "author",
      headerName: t("view.Tickets.inbox"),
      type: "string",
      renderCell: (params: any) => params.value,
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 122,
    },
    {
      field: "date_created",
      headerName: t("view.Tickets.created"),
      renderCell: (params: any) => formatDate(params.value),
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 170,
      type: 'dateTime'
    },
    {
      field: "date_closed",
      headerName: t("view.ChangeOrder.CoListColumns.closedDate"),
      renderCell: (params: any) => params.value ? formatDate(params.value) : '',
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 120,
      type: 'dateTime'
    },
    {
      field: "first_submit_date",
      headerName: t("view.ChangeOrder.CoListColumns.firstSubmitDate"),
      renderCell: (params: any) => params.value ? formatDate(params.value) : '',
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 120,
      type: 'dateTime'
    },
    {
      field: "reason_co_closed",
      headerName: t("view.ChangeOrder.CoListColumns.reasonClosed"),
      renderCell: (params: any) => params.value ? params.value : '',
      editable: false,
      hide: true,
      align: "right",
      headerAlign: "right",
      width: 120,
      type: 'string'
    },
  ]

  if(companyType === 'cm'){
    coColumns.splice(0,0, {
      field: "created_by_company_name",
      headerName: t("view.Tickets.company"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 175,
    },)
  }

  if(canDeleteChangeOrder){
    coColumns.push({
      field: "delete",
      headerName: t("view.Tickets.TicketListColumns.delete"),
      editable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      renderCell: (params: any) => {
        return params.row.estimate_id != null ? (
          <Tooltip placement={'top'} title={t("view.ChangeOrder.CoListColumns.deleteTooltip")}>
            <div>
              <IconButton disabled color="secondary">
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <IconButton
            color="secondary"
            onClick={() => openConfirmCancelDeleteChangeOrderDialog(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        )
      }
    })
  }

  return coColumns
}
