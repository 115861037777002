import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiHeaders } from "../../api/util"
import axios from "axios"
import config from "../../libs/config"
import { TracFloState } from "../../types/state"
import { Equipment, EquipmentStore } from "../../types/equipment"

const initialState: EquipmentStore = {
  items: [],
  exp: "",
  status: "idle",
}

export const loadEquipment = createAsyncThunk<Equipment[], void, {state: TracFloState}>("user/loadEquipment", async (args, thunkAPI) => {
  const { company, user } = await thunkAPI.getState()
  const response = await axios({
    headers: apiHeaders(company.id, user.token),
    method: "get",
    timeout: 20000,
    url: `${config.api.url}/equipment`,
  })
  if (response.status === 200 && response.data && Array.isArray(response.data)) {
    const { data } = response
    return data
  } else {
    return thunkAPI.rejectWithValue(response)
  }
})

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    addEquipment: (state, action) => {
      state.items.unshift(action.payload)
    },
    addMultipleEquipmentTypes: (state, action: {payload: Equipment[]}) => {
      const newEquips = [...state.items, ...action.payload]
      newEquips.sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1)
      state.items = newEquips
    },
    updateEquipment: (state , action: {payload: Equipment}) => {
      const newEquipment = action.payload
      if (newEquipment && newEquipment.id) {
        state.items = state.items.map((item) => (item.id === newEquipment.id ? newEquipment : item))
      }
    },
    resetEquipment: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEquipment.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadEquipment.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadEquipment.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          state.items = action.payload
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 60 // one hour
        }
      })
  },
})

export const { addEquipment, updateEquipment, resetEquipment, addMultipleEquipmentTypes } =
  equipmentSlice.actions

export const getEquipmentTypes = (state: TracFloState) => state.equipment.items

export default equipmentSlice.reducer
