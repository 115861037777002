export interface AddMaterialEquipmentBreakdownInitialValues {
  // Needed by API
  type_id: string | null,
  quantity: string | number,
  rate: string | number,
  unit: string,
  id?: string,
  // For display
  cost_code: string,
  total_cost: string | number,
  type_name: string,
}

export function defaultAddMaterialEquipmentBreakdownInitialValues(): AddMaterialEquipmentBreakdownInitialValues {
  return {
    // Needed by API
    type_id: "",
    quantity: "",
    rate: "0",
    unit: "",
    // For display
    cost_code: "",
    total_cost: "",
    type_name: "",
  }
}
