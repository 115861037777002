import React from "react"
import Grid from "@mui/material/Grid"

import LaborBreakdown from "./LaborBreakdown"
import MaterialBreakdown from "./MaterialBreakdown"
import {TicketMarkup} from "../../../../../../types/ticket"
import EquipmentBreakdown from "./EquipmentBreakdown"

interface BreakdownsProps {
  ticket_id: string,
  addRates: boolean,
  project_id: string,
  updateSubtotal: (type: 'labor' | 'material' | 'equipment', value: number) => void,
  markup: TicketMarkup[],
}

export default function Breakdowns(props: BreakdownsProps) {
  return (
    <Grid container style={{ marginTop: 50 }}>
      <Grid item container xs={12} justifyContent="flex-start">
        <LaborBreakdown
          addRates={props.addRates}
          markupRows={props.markup.filter((m) => m.markup_type === 'labor')}
          ticket_id={props.ticket_id}
          project_id={props.project_id}
          updateLaborSubtotal={(val) => props.updateSubtotal('labor', val)}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start">
        <MaterialBreakdown
          addRates={props.addRates}
          markupRows={props.markup.filter((m) => m.markup_type === 'material')}
          ticket_id={props.ticket_id}
          project_id={props.project_id}
          updateMaterialSubtotal={(val) => props.updateSubtotal('material', val)}
        />
      </Grid>

      <Grid item container xs={12} justifyContent="flex-start">
        <EquipmentBreakdown
          addRates={props.addRates}
          markupRows={props.markup.filter((m) => m.markup_type === 'equipment')}
          ticket_id={props.ticket_id}
          project_id={props.project_id}
          updateEquipmentSubtotal={(val) => props.updateSubtotal('equipment', val)}
        />
      </Grid>
    </Grid>
  )
}
