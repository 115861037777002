// 60 seconds * 60 minutes * 24 hours
export const secToDays: number = 60 * 60 * 24

export function howManyMonthsBetweenDates(d1: Date, d2: Date): number {
  const d1Month = d1.getMonth() + 1
  const d1Year = d1.getFullYear()
  const d2Month = d2.getMonth() + 1
  const d2Year = d2.getFullYear()
  let monthDiffFromYears = 12 * (d2Year - d1Year)

  return d2Month - d1Month + monthDiffFromYears - 1 // subtract the extra end month
}

export function sortObjsContainingDateField(a: {date: Date}, b: {date: Date}): number {
  return a.date.getTime() - b.date.getTime()
}

export function convertStrToDate(str: string): Date | null {
  const dateFromStr = new Date(str)
  return Number.isNaN(dateFromStr.valueOf())
    ? null
    : dateFromStr
}
