import React from "react"
import { Tooltip } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download'

//@ts-ignore
import Button from "./Button"


interface DownloadInvoicesButtonProps {
  idArray: string[],
  title: string,
  tooltipInfo: string,
  submitValues: () => Promise<void>,
}

export default function DownloadInvoicesButton(props: DownloadInvoicesButtonProps) {
  const { idArray, title, tooltipInfo, submitValues } = props

  return (
    <Tooltip placement='top' title={tooltipInfo}>
      <div>
        <Button 
          disabled={idArray.length === 0}
          onClick={submitValues} 
          startIcon={<DownloadIcon/>} 
          variant="text" 
          sx={{fontSize: 13}}
        >
          {title}
        </Button>
      </div>
    </Tooltip>
  )
}

