import {ListOption} from "../../../../../../../../types/form"
import {ChangeEventLineItemLevel} from '../../../../../../../../api/changeOrderIntegration'

export type PushToChangeEventType = 'new' | 'existing'
export const pushToChangeEventTypes = ['new', 'existing']

export const changeEventLineItemLevel = ['oneLineItem', 'allDetail']

export interface ChangeEventDetailsFormOutput {
  existingChangeEvent: ListOption<string> | null, // null ID means they are creating a new change event
  ceLineItemLevel: ChangeEventLineItemLevel,
}

export function formIsValid(
  pushToCeType: PushToChangeEventType,
  selectedCe: ListOption<string> | null,
  ceLineItemLevel: ChangeEventLineItemLevel,
): boolean {
  return (pushToCeType === 'new' || (pushToCeType === 'existing' && selectedCe != null))
    && changeEventLineItemLevel.includes(ceLineItemLevel)
}
