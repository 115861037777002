import { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"

import InvoiceTopAttachedFiles from "./InvoiceTopAttachedFiles"
import { File } from "../../types/file"
import { Estimate } from "../../types/estimate"
import { formatDate } from "../../libs/format"
import DetailsTable from "../DetailsTable"
import DetailRow from "../DetailRow"
import InvoiceTopNumber from "./InvoiceTopNumber"
import InvoiceTopLogo from "./InvoiceTopLogo"
import { useTracFloSelector } from "../../store/useTracFloSelector"
import InvoiceTopCompanyInfo from "./InvoiceTopCompanyInfo"
import { getEstimateSenderReceiver } from "../../api/estimate"
import { CompanySenderReceiverInfo } from "../../types/company"


interface EstimateDetailsTopSectionProps {
  files: File[] | null,
  estimate: Estimate,
}

export default function EstimateDetailsTopSection(
  {files, estimate}: EstimateDetailsTopSectionProps
): JSX.Element {
  const { t } = useTranslation("private")
  const project = useTracFloSelector((state) => state.project)
  const [sender, setSender] = useState<CompanySenderReceiverInfo | null>(null)
  const [receiver, setReceiver] = useState<CompanySenderReceiverInfo | null>(null)

  const estimateProjectDetails = [
    {
      title: t('component.EstimateTopSectionDetails.pcoNumber'),
      content: estimate.pco_number
    },
    {
      title: t('component.EstimateTopSectionDetails.subject'),
      content: estimate.subject
    },
    {
      title: t('component.EstimateTopSectionDetails.projectNumber'),
      content: project.number
    },
    {
      title: t('component.EstimateTopSectionDetails.projectName'),
      content: project.name
    },
    {
      title: t('component.EstimateTopSectionDetails.workDate'),
      content: estimate.date_start ? formatDate(estimate.date_start, estimate.date_end) : null
    },
    {
      title: t('component.EstimateTopSectionDetails.dateCreated'),
      content: estimate.date_created ? formatDate(estimate.date_created) : null
    },
    {
      title: t('component.EstimateTopSectionDetails.submittedBy'),
      content: `${estimate.inbox_first_name} ${estimate.inbox_last_name}`
    }
  ]

  useEffect(() => {
    // get sender and receiver for this estimate
    getEstimateSenderReceiver(project.id, estimate.id).then(
      (res) => {
        if(res.status === 200){
          const {sender, receiver} = res.data
          setSender(sender)
          setReceiver(receiver)
        }
      },
    )
  }, [estimate.id, project.id])

  return (
    <Grid container spacing={3}>

      <InvoiceTopNumber number={estimate.number}/>

      <InvoiceTopLogo senderLogoUrl={sender?.logo_url} senderName={sender?.name}/>

      {/* Estimate and Project Details */}
      <Grid container item xs={8}>
        <DetailsTable details={estimateProjectDetails} />
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow
            title={t('component.EstimateTopSectionDetails.description')}
            content={estimate.description}
          />
        </Grid>
      </Grid>

      <Grid container item xs={4}>
        <InvoiceTopCompanyInfo
          sender={sender}
          receiver={receiver}
        />

        <DetailRow
          title={t('component.EstimateTopSectionDetails.notes')}
          content={estimate.notes}
        />
      </Grid>

      { files != null
        ? <InvoiceTopAttachedFiles files={files}/>
        : <></>
      }
    </Grid>
  )
}
