import { convertToNumber } from "../util/number"

export interface CreateEditLaborInitialValues {
  name: string,
  cost_code: string,
  date_start: string,
  date_end: string | null,
  notes: string,
  rate_rt: string,
  rate_ot: string,
  rate_dt: string,
  rate_pot: string,
  rate_pdt: string,
  subcontractor_id: string,
}

export function defaultCreateLaborInitialValues(company: any): CreateEditLaborInitialValues {
  return {
    name: "",
    cost_code: "",
    date_start: (new Date()).toDateString(),
    date_end: null,
    notes: "",
    rate_rt: "",
    rate_ot: "",
    rate_dt: "",
    rate_pot: "",
    rate_pdt: "",
    subcontractor_id: company.company_type === 'trade' ? company.id : "",
  }
}

export function defaultEditLaborInitialValues(selectedLaborType: CreateEditLaborInitialValues): CreateEditLaborInitialValues {
  return {
    name: selectedLaborType.name ?? '',
    cost_code: selectedLaborType.cost_code ?? '',
    date_start: selectedLaborType.date_start ?? '',
    date_end: selectedLaborType.date_end ?? null,
    notes: selectedLaborType.notes ?? '',
    rate_rt: convertToNumber(selectedLaborType.rate_rt) !== 0 ? selectedLaborType.rate_rt  : '',
    rate_ot: convertToNumber(selectedLaborType.rate_ot) !== 0 ? selectedLaborType.rate_ot : '',
    rate_dt: convertToNumber(selectedLaborType.rate_dt) !== 0 ? selectedLaborType.rate_dt : '',
    rate_pot: convertToNumber(selectedLaborType.rate_pot) !== 0 ? selectedLaborType.rate_pot : '',
    rate_pdt: convertToNumber(selectedLaborType.rate_pdt) !== 0 ? selectedLaborType.rate_pdt : '',
    subcontractor_id: selectedLaborType.subcontractor_id ?? '',
  }
}