import React from "react"
import {GridColDef} from "@mui/x-data-grid"

import {formatMoney, formatDate} from '../../../../../libs/format'
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'
import { goToSingleEstimate } from "../../../../../util/routes"
import EstimateAction from "../../../../../components/EstimateAction"
import { ActionInfo, EstimateActionCode } from "../../../../../types/action"
import {CompanyType} from '../../../../../types/company'

export const EstimateListColumns = (
  projectId: string,
  t: any,
  actionIdToInfo: {[key: number]: ActionInfo<EstimateActionCode>},
  companyType: CompanyType
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 150,
    },
    {
      field: "number",
      headerName: "#",
      type: 'string',
      align: "left",
      headerAlign: "left",
      width: 150,
      editable: false,
    },
    {
      field: "pco_number",
      headerName: t("view.Estimates.EstimateList.pcoNumberColumnHeader"),
      type: 'string',
      align: "left",
      headerAlign: "left",
      width: 150,
      editable: false,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Estimates.EstimateList.subjectColumnHeader"),
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <FormatTableTitleLink
            url={goToSingleEstimate(projectId, params.row.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      align: "left",
      headerAlign: "center",
      field: "estimate_action_id",
      headerName: t("view.Estimates.EstimateList.actionColumnHeader"),
      width: 180,
      editable: false,
      renderCell: (params: any) => {
        return <EstimateAction
          code={actionIdToInfo[params.row.estimate_action_id].code}
        />
      },
      valueGetter: (params: any) => actionIdToInfo[params.value].name,
      type: 'string'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t("view.Estimates.EstimateList.totalColumnHeader"),
      width: 140,
      editable: false,
      renderCell: (params: any) => {
        return formatMoney(params.value ?? 0)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_actual_amount",
      // trades call this field something different
      headerName: companyType === 'trade'
        ? t("view.Estimates.EstimateList.totalSubmittedColHeader")
        : t("view.Estimates.EstimateList.totalActualColumnHeader")
      ,
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return formatMoney(params.value ?? 0)
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_modified",
      headerName: t("view.Estimates.EstimateList.dateModifiedColumnHeader"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
    {
      field: "author",
      headerName: t("view.Estimates.EstimateList.inboxColumnHeader"),
      type: "string",
      renderCell: (params: any) => params.value,
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 130,
    },
  ]
  if(companyType === 'cm') {
    columns.splice(0,0, {
      field: "created_by_company_name",
      headerName: t("view.Tickets.company"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 175,
    },)
  }
  if (companyType === 'trade') {
    columns.splice(6, 0, {
      align: "right",
      headerAlign: "right",
      field: "total_sub_actual_amount",
      headerName: t("view.Estimates.EstimateList.totalActualColumnHeader"),
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return formatMoney(params.value ?? 0)
      },
    })
  }
  return columns
}
