import {UseAgaveLinkProps} from "@agave-api/react-agave-link/src/types"

import {SupportedIntegration} from "../types/integration"
import {PRODUCTION_ENV} from "./featureFlags"


function createAgaveLinkConfig(
  linkToken: string,
  sourceSystem: SupportedIntegration,
  onSuccess: (token: string) => void
): UseAgaveLinkProps {
  if (PRODUCTION_ENV) {
    return {onSuccess, linkToken, sourceSystem,}
  } else {
    // Need to use sandbox system outside of production
    return {
      onSuccess,
      linkToken,
      sourceSystem,
      sourceSystemEnvironment: 'sandbox',
      showSandboxSourceSystems: true,
      showProductionSourceSystems: false,
    }
  }
}

export async function getPublicTokenFromAgaveLink(
  linkToken: string,
  sourceSystem: SupportedIntegration,
  onSuccess: (token: string) => void
): Promise<void> {
  if (window.AgaveLink) {
    window.AgaveLink.openLink(createAgaveLinkConfig(linkToken, sourceSystem, onSuccess))
  }
}
