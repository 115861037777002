import {Estimate} from '../../../../../types/estimate'
import {
  CreateEstimateInitialValues,
  CreateEstimateLineItem,
  CreateEstimateMarkup,
} from '../../../../../forms/CreateEstimate.types'
import {convertToNumber} from '../../../../../util/number'


export function createInitialFormValues(
  estimate: Estimate,
  lineItems: CreateEstimateLineItem[],
  markup: CreateEstimateMarkup[],
): CreateEstimateInitialValues {
  return {
    number: estimate.number ?? '',
    subject: estimate.subject,
    description: estimate.description,
    notes: estimate.notes ?? '',
    date_start: estimate.date_start ?? null,
    date_end: estimate.date_end ?? null,
    is_lump_sum: estimate.is_lump_sum,
    lump_sum_total: convertToNumber(estimate.lump_sum_total) ?? undefined,
    files: [{name: '', url: '', file_size_bytes: 0},],
    line_items: lineItems,
    markup,
  }
}
