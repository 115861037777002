import React, {useEffect} from "react"
import { Route, Redirect, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import {useTranslation} from "react-i18next"

import {setFsUserInfo} from "../../libs/fullstory"
import { loadIntegrations } from "../../store/features/integrationSlice"
import { loadActions } from "../../store/features/actionSlice"
import { loadActions as loadCoActions } from "../../store/features/changeOrderActionSlice"
import { loadActions as loadBulletinActions } from "../../store/features/bulletinActionSlice"
import { loadActions as loadEstimateActions } from "../../store/features/estimateActionSlice"
import { getCompany, setCompany } from "../../store/features/companySlice"
//@ts-ignore
import {resetStore} from "../../store/resetStoreService"
import {getUser, loadActiveIntegrations, setActiveIntegration} from "../../store/features/userSlice"
import { useTracFloSelector } from "../../store/useTracFloSelector"
import { CompanyStore } from "../../types/company"
import { Expiration, ExpirationField } from "../../types/expiration"
import { UserStore } from "../../types/user"
import { goToAccounts, pathNeedsActiveCompany } from "../../util/routes"


export default function AuthenticatedRouteGuard({ children, ...rest }: any) {
  const dispatch = useDispatch()
  const company: CompanyStore = useTracFloSelector(getCompany)
  const user: UserStore = useTracFloSelector(getUser)
  const action = useTracFloSelector((state) => state.action)
  const changeOrderAction = useTracFloSelector((state) => state.changeOrderAction)
  const bulletinAction = useTracFloSelector((state) => state.bulletinAction)
  const estimateAction = useTracFloSelector((state) => state.estimateAction)
  const integration = useTracFloSelector((state) => state.integration)
  const history = useHistory()
  const { pathname, search } = useLocation()
  const {i18n} = useTranslation()

  useEffect(() => {
    const expiration = (user.exp as Expiration).user as ExpirationField
    const now = Math.floor(Date.now() / 1000)
    if (expiration < now) {
      resetStore(dispatch)
      return history.push(`/login?redirect=${pathname}${search}`)
    }
    // get current company if we dont already have it
    if (!company.id) {
      if (user && user.companies && user.companies.length === 1) {
        dispatch(setCompany(user.companies[0]))
      } else if (pathNeedsActiveCompany(pathname)) {
        return history.push(`${goToAccounts}?redirect=${pathname}${search}`)
      }
    }
    // Check if we need to grab action items
    if (!action?.items?.length || action?.items?.length === 0) {
      dispatch(loadActions())
    }
    // Check if we need to grab change order action items
    if (!changeOrderAction?.items?.length || changeOrderAction?.items?.length === 0) {
      dispatch(loadCoActions())
    }
    // Check if we need to grab bulletin action items
    if (!bulletinAction?.items?.length || bulletinAction?.items?.length === 0) {
      dispatch(loadBulletinActions())
    }
    // Check if we need to grab estimate action items
    if (!estimateAction?.items?.length || estimateAction?.items?.length === 0) {
      dispatch(loadEstimateActions())
    }
    // Check if we need to grab integrations
    if (!integration?.items?.length || integration?.items?.length === 0) {
      dispatch(loadIntegrations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, user])

  useEffect(() => {
    // set up fullstory with user info
    if (user.id) {
      setFsUserInfo(user.id, {displayName: user.name, email: user.email})
    }
  }, [user.id, user.email, user.name])

  useEffect(() => {
    // load active user integrations on login
    if (user.id) {
      dispatch(loadActiveIntegrations())
    }
  }, [user.id, dispatch])

  useEffect(() => {
    // switch to users preferred language
    if (user.id) {
      if (i18n.language !== user.language) {
        i18n.changeLanguage(user.language).then(/*intentionally blank*/)
      }
    }
  }, [user.id, i18n, user.language])

  useEffect(() => {
    // set "active" integration for global use
    if (!!user.id && user.integrations.length > 0 && user.activeIntegration == null) {
      dispatch(setActiveIntegration(user.integrations[0]))
    }
  }, [user.id, user.integrations, user.activeIntegration, dispatch])

  return (
    <Route {...rest}>
      {user.isLoggedIn ? children : <Redirect to={`/login?redirect=${pathname}${search}`} />}
    </Route>
  )
}
