import { Theme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

export const BreakdownTableStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "block",
    marginBottom: theme.spacing(1),
    maxWidth: "100%",
    overflowX: "auto",
    width: "100%",
  },
  table: {
    border: "none",
    "& .MuiTableCell-head": {
      color: theme.palette.grey[600],
      fontSize: 12,
      paddingLeft: 5,
      paddingRight: 5,
      "&:first-child": {
        lineHeight: 1,
        paddingBottom: 0,
        paddingTop: 0,
      },
      "&:last-child": {
        lineHeight: 1,
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    "& .MuiTableCell-body": {
      paddingLeft: 5,
      paddingRight: 5,
      "&:first-child": {},
      "&:last-child": {},
    },
  },
}))
