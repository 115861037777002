import {CreateEstimateInitialValues, CreateEstimateOutputForApi} from './CreateEstimate.types'


export function convertFormValuesToApiData(values: CreateEstimateInitialValues, nextEstimateNumber: string|null): CreateEstimateOutputForApi {
  return {
    subject: values.subject,
    description: values.description,
    notes: values.notes.length > 0 ? values.notes : undefined,
    number: values.number.length > 0 ? values.number : nextEstimateNumber,
    date_start: values.date_start && values.date_start.length > 0 ? values.date_start : undefined,
    date_end: values.date_end && values.date_end.length > 0 ? values.date_end : undefined,
    is_lump_sum: values.is_lump_sum,
    lump_sum_total: values.lump_sum_total,
    line_items: values.line_items,
    markup: values.markup,
  }
}
