import React from "react"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import { GridColDef } from "@mui/x-data-grid"

//@ts-ignore
import DtRowTitle from "../../../../../components/DtRowTitle"
import { CompanyType } from "../../../../../types/company"
import { convertToNumber } from "../../../../../util/number"
import { formatDate, formatMoney } from "../../../../../libs/format"
import { ProjectUserRoleCode } from "../../../../../types/userRoles"


interface ProjectLaborProps {
  companyType: CompanyType,
  companyId: string,
  projectUserRole: ProjectUserRoleCode,
  handleEdit: (labor: any) => void,
  t: (s: string) => string
}

export default function ProjectLabor(props: ProjectLaborProps ): GridColDef[] {
  const {companyType, companyId, projectUserRole, handleEdit, t} = props

  const laborColumns: GridColDef[] = [
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: 'Description',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => (
        <DtRowTitle>{params.value}</DtRowTitle>
      ),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "cost_code",
      headerName: 'Cost Code',
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate_rt",
      headerName: "RT",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate_ot",
      headerName: "OT",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate_dt",
      headerName: "DT",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate_pot",
      headerName: "POT",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate_pdt",
      headerName: "PDT",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_start",
      headerName: t("view.ProjectSettings.LaborTypes.start"),
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (params.value ? formatDate(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_end",
      headerName: t("view.ProjectSettings.LaborTypes.end"),
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (params.value ? formatDate(params.value) : "—"),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "created_by_company_id",
      headerName: t("view.ProjectSettings.LaborTypes.created_by"),
      width: 80,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (
        params.value === companyId
          ? t("view.ProjectSettings.LaborTypes.you")
          : t("view.ProjectSettings.LaborTypes.them")
      ), // Translate
    },
  ]

  if (companyType === "cm") {
    // GC's need to see sub name
    laborColumns.splice(0,0,{
      align: "left",
      headerAlign: "left",
      field: "subcontractor_name",
      headerName: t("view.ProjectSettings.LaborTypes.company"),
      width: 100,
      editable: false,
      sortable: false,
    })
  }
  else if(companyType === "trade"){
    if (projectUserRole === "pm"){
      laborColumns.push({
        align: "center",
        headerAlign: "right",
        field: "",
        headerName: " ",
        width: 50,
        editable: false,
        hideable: false,
        sortable: false,
        renderCell: (params: any) => (
          params.row.created_by_company_id === companyId ?
            <IconButton
              color="secondary"
              onClick={() => {
                handleEdit(params.row)
              }}
            >
              <EditIcon />
            </IconButton>
          : <></>
        ),
      })
    }
  }

  return laborColumns
}
