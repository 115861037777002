import React from "react"
import { Link } from "react-router-dom"

interface TableColumnLinkProps {
  text: string,
  url: string,
  openNewWindow?: boolean,
  className: any,
}

export default function TableColumnLink(props: TableColumnLinkProps) {
  const {text, url, openNewWindow, className} = props

  return (
    <Link
      className={className}
      to={url}
      target={openNewWindow ? '_blank' : '_self'}
      style={{ textDecoration: "none", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}
    >
      {text}
    </Link>
  )
}
