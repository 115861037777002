import React from "react"
import * as Yup from "yup"
import {Form, Formik} from "formik"
import Grid from "@mui/material/Grid"
import {useSnackbar} from "notistack"
import {useTranslation} from "react-i18next"

import {addGcToSubProjectByEmail} from "../../../../../api/invite"
// @ts-ignore
import Button from "../../../../../components/Button"
// @ts-ignore
import TextField from "../../../../../components/Fields/Text"
// @ts-ignore
import FormSmallContainer from "../../../../../components/FormSmallContainer"


interface AddGcToSubProjectByEmailForm {
  email: string,
}

interface AddGcToSubProjectByEmailProps {
  projectId: string,
  gcAddedToProject: () => void
}

export function AddGcToSubProjectByEmail(props: AddGcToSubProjectByEmailProps) {
  const {projectId, gcAddedToProject} = props
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation('private')

  const addGcToProjectByEmail = async (formValues: AddGcToSubProjectByEmailForm): Promise<void> => {
    const res = await addGcToSubProjectByEmail(projectId, formValues)
    if (res?.data?.success) {
      enqueueSnackbar(res.data.message, {variant: 'success'})
      gcAddedToProject()
    } else {
      enqueueSnackbar(t('form.AddGcToProject.issueAddingGc'), {variant: 'error'})
    }
  }

  return (
    <Formik<AddGcToSubProjectByEmailForm>
      validateOnChange={false}
      initialValues={{email: '',}}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(t("form.message.emailRequired")),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        await addGcToProjectByEmail(values)
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
        }) => {
        return (
          <Form>
            <FormSmallContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    label={t("form.label.emailRequired")}
                    name="email"
                    type="email"
                    id="email"
                    autocomplete="email"
                    />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" item xs={6} style={{paddingTop: '15px'}}>
                <Button
                  disabled={!Object.keys(touched).length || !isValid}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('form.AddGcToProject.submit')}
                </Button>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
