import React, {useEffect, useRef, useState} from "react"
import { useParams } from "react-router-dom"
import { useTheme } from "@mui/material"
import {useSnackbar} from "notistack"
import { useTranslation } from "react-i18next"

import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { getDailyById } from "../../../../../api/daily"
import { Daily } from "../../../../../types/daily"
//@ts-ignore
import Container from "../../../../../components/Container"
import DailyReviewSection from "./Review"
import DailyInvoice from "./Invoice"
import DailyHistory from "./History"


export default function SingleDaily(): JSX.Element {
  const { dailyId, projectId } = useParams<{dailyId: string, projectId: string}>()
  const theme = useTheme()
  const project = useTracFloSelector((state) => state.project)
  const [daily, setDaily] = useState<Daily | null>(null)
  const {enqueueSnackbar} = useSnackbar()
  const { t } = useTranslation("private")
  const historyRef = useRef<any>()

  useEffect(() => {
    if (projectId === project.id && dailyId) {
      getDailyById(projectId, dailyId)
        .then((res) => {
          if (res.status === 200) {
            setDaily(res.data[0])
          }
        })
        .catch(() => {
          enqueueSnackbar(t("view.Daily.SingleDaily.getDailyByIdFail"), {
            variant: "error",
          })
        })
    }
  }, [projectId, dailyId, project.id, enqueueSnackbar, t])

  return project.id === projectId && !!daily ? (
    <>
      <DailyReviewSection 
        historyRef={historyRef} 
        daily={daily} 
        projectId={projectId}
        setDaily={setDaily}
      />

      <Container fullWidth style={{ background: theme.palette.grey[200] }}>
        <DailyInvoice daily={daily} project={project}/>

      </Container>

      <div ref={historyRef}>
        <Container>
          <DailyHistory daily_id={daily.id} project_id={project.id} />
        </Container>
      </div>
    </>
  ) : (<></>)
}
