import React from "react"
import { Grid } from "@mui/material"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useDispatch } from "react-redux"

// @ts-ignore
import Button from "../components/Button"
import { uploadCompanyLogoFile } from "../api/file"
// @ts-ignore
import { updateCompany } from "../store/features/userSlice"
// @ts-ignore
import { updateCompanyInfo } from "../store/features/companySlice"
import UploadImage from "../components/Fields/UploadImage"

interface EditCompanyLogoProps {
  onClose: () => void, 
  company: any
}

export default function EditCompanyLogo(props: EditCompanyLogoProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const fileUploadLimitInMB = 3

  const submitForm = async (values: any): Promise<void> => {
    // need to check that files actually exist
    if (!!values.file.name) {
      const uploadingKey = enqueueSnackbar('Uploading file...', {variant: 'info'})
      try { 
        const updateCompanyLogo = await uploadCompanyLogoFile(values.file, props.company.id)
        if(updateCompanyLogo.data.id){
          // update user state version of this company 
          dispatch(updateCompany(updateCompanyLogo.data))
          // update active company state
          dispatch(updateCompanyInfo(updateCompanyLogo.data))
          closeSnackbar(uploadingKey)
          enqueueSnackbar("Logo successfully updated.", {
              variant: "success"
          })
        }
        else {
          closeSnackbar(uploadingKey)
          enqueueSnackbar(`There was a problem uploading the new logo file.`, {
            variant: "error",
          })
        }
      } catch(_) {
        closeSnackbar(uploadingKey)
        enqueueSnackbar(`There was a problem uploading the new logo file.`, {
          variant: "error",
        })
      }
    }
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{file: {name: "",}}}
      onSubmit={async (values, { resetForm }) => {
        await submitForm(values)
        resetForm()
        props.onClose()
      }}
    >
      {({
        values,
        setFieldValue
      }) => {
        const setImageFile = (file: any) => {
          setFieldValue("file", file)
        }
        
        return (
          <Form>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <UploadImage 
                  file={values.file}
                  setImageFile={setImageFile}
                  fileSizeLimitInMBs={fileUploadLimitInMB}
                />
              </Grid>
              <Grid container justifyContent="flex-start" item xs={6}>
                <Button onClick={() => props.onClose()} color="secondary" variant="text">
                  {t("form.label.cancel")}
                </Button>
              </Grid>
              <Grid container justifyContent="flex-end" item xs={6}>
                <Button disabled={values.file.name === ""} type="submit">
                  {t("form.label.edit_logo")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
