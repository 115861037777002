import React from "react"
import {Redirect, Route, Switch} from "react-router-dom"

import ChangeOrdersRouter from "./ChangeOrders/ChangeOrder.Router"
import Home from "./Projects/Home"
import ProjectSettingsRouter from "./Projects/ProjectSettings.Router"
import CompanySettingsRouter from "./Settings/CompanySettings.Router"
import SubInvite from "./SubInvite"
import TicketRouter from "./Tickets/Ticket.Router"
//@ts-ignore
import Layout from "../Layout/LayoutAuthenticated"
import { useTracFloSelector } from "../../../store/useTracFloSelector"
import DashboardRouter from "./Dashboard/Dashboard.Router"
import { goHome } from "../../../util/routes"
import BulletinsRouter from './Bulletins/Bulletin.Router'
import EstimateRouter from './Estimates/Estimate.Router'
import DailyRouter from './Daily/Daily.Router'
import {useFinancingApr2024} from '../../../hook/userTesting/financingApr2024'


export default function AccountSelectedRouter() {
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  useFinancingApr2024()

  return (
    <Layout>
      <Switch>
        <Route exact path="/0">
          <Home />
        </Route>
        <Route exact path="/0/sub_invite/:subInviteId">
          <SubInvite />
        </Route>
        <Route exact path={goHome}>
          <Home />
        </Route>
        {/* // this common parent container may look a little weird but it is required for rendering */}
        <>
        {company.company_user_role !== "crew" ? (
          <>
            <CompanySettingsRouter/>
          </>
        ) : (
          <></>
        )}
        <Route path="/0/project">
          <>
            <TicketRouter/>
            <DailyRouter/>
            {project.project_user_role !== "crew" ? (
              <>
                <ChangeOrdersRouter/>
                <ProjectSettingsRouter/>
                <DashboardRouter/>
                <BulletinsRouter/>
                <EstimateRouter/>
              </>
            ) : (
              <></>
            )}
          </>
        </Route>
        </>
        <Route path={'*'}>
          <Redirect to={'/0'}/>
        </Route>
      </Switch>
    </Layout>
  )
}
