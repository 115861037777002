import React from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"

import ShareForm from "../../../../../../../forms/ProjectShareForm"
import { ProjectShareFormSubmitValues } from "../../../../../../../types/form"
import { shareDaily as shareDailyApi } from "../../../../../../../api/daily"


interface ShareDailyFormProps {
  projectId: string
  toggleFormOpen: () => void
  dailyId: string,
}

export default function ShareDailyForm(
  {projectId, toggleFormOpen, dailyId}: ShareDailyFormProps
): JSX.Element {  
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  async function shareDaily(values: ProjectShareFormSubmitValues){
    const res = await shareDailyApi(
      projectId,
      dailyId,
      values.notes,
      values.shareWith
    )
    if (res.status === 200 && res.data?.success) {
      enqueueSnackbar(`${t("view.Daily.SingleDaily.shareSuccess")}`, {variant: 'success'})
    } else {
      enqueueSnackbar(`${t("view.Daily.SingleDaily.shareFail")}`, {variant: 'error'})
    }
    toggleFormOpen()
  }

  return (
    <ShareForm
      projectId={projectId}
      toggleFormOpen={() => toggleFormOpen()}
      submitValues={shareDaily}
    />
  )
}
