import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import {Theme, Typography} from "@mui/material"
import {useAgaveLink} from "@agave-api/react-agave-link"
import {UseAgaveLinkResponse} from "@agave-api/react-agave-link/src/types"
import {useSnackbar} from "notistack"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"

import IntegrationsLayout from "./Integrations.layout"
import {useTracFloSelector} from "../../../store/useTracFloSelector"
import IntegrationCard from "./IntegrationCard"
import {createUserIntegration, getAgaveLinkToken} from "../../../api/integration"
import {getPublicTokenFromAgaveLink} from "../../../libs/agave"
import {addIntegration} from "../../../store/features/userSlice"
import { Link } from "react-router-dom"
import { goHome, goToAccounts } from "../../../util/routes"
import { isAccountSelected } from "../../../store/features/companySlice"


const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(18),
    paddingRight: theme.spacing(18),
  },
}))

export default function Integrations() {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()
  const { t } = useTranslation("private")
  const user = useTracFloSelector((s) => s.user)
  const integrations = useTracFloSelector((s) => s.integration.items)
  const userIntegrations = useTracFloSelector((s) => s.user.integrations)
  const accountSelected: boolean = useTracFloSelector(isAccountSelected)
  const agaveLink: UseAgaveLinkResponse = useAgaveLink({/* Placeholder, we don't access link thru this */})
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const classes: any = useStyles()

  // if account selected, go home, otherwise need to choose account
  const linkRedirect = accountSelected ? {
    url: goHome,
    text: t("view.Integrations.backToHomePage"),
  } : {
    url: goToAccounts,
    text: t("view.Integrations.backToAccountsPage"),
  }

  useEffect(() => {
    if (user.id) {
      getAgaveLinkToken().then(
        (res) => {
          if (res.data?.link_token) {
            setLinkToken(res.data.link_token)
          }
        }
      )
    }
  }, [user.id])

  const onPublicTokenSuccess = (integrationId: number) => {
    return async (public_token: string) => {
      try {
        const res = await createUserIntegration(integrationId, public_token)
        if (res.data?.integration?.id) {
          dispatch(addIntegration(res.data.integration))
          enqueueSnackbar(t('view.Integrations.setupIntegrationSuccess'), {variant: 'success'})
        } else {
          enqueueSnackbar(t('view.Integrations.setupIntegrationError'), {variant: 'error'})
        }
      } catch (_) {
        enqueueSnackbar(t('view.Integrations.setupIntegrationError'), {variant: 'error'})
      }
    }
  }

  const contents = integrations?.length > 0 && agaveLink.isReady && linkToken != null
    ? integrations.map((i) =>
      <IntegrationCard
        integration={i}
        isActive={userIntegrations.some((ai) => ai.integration_id === i.id)}
        onClick={() => {
          getPublicTokenFromAgaveLink(linkToken, i.code, onPublicTokenSuccess(i.id)).then(/*blank*/)
        }}
      />
    ) : <></>

  return (
    <IntegrationsLayout>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {t("view.Integrations.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to={linkRedirect.url}>
            {linkRedirect.text}
          </Link>
        </Grid>
        {contents}
      </Grid>
    </IntegrationsLayout>
  )
}
