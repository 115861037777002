import React, {useState, useEffect} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// @ts-ignore
import { convertToNumber } from "../../../../../../util/number"
import equipmentColumns from "./EquipmentBreakdown.columns"
// @ts-ignore
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import {TicketMarkup} from "../../../../../../types/ticket"
import EquipmentBreakdownTable from "./BreakdownTables/EquipmentBreakdownTable"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"
import {getTicketEquipmentBreakdown} from "../../../../../../api/ticket"

export interface EquipmentTotals {
  quantity: number, total?: number
}
export function calculateEquipmentTotals(
  rows: any[], addRates: boolean
): EquipmentTotals {
  const equipmentTotals: EquipmentTotals = {
    quantity: rows.reduce(
      (total, { quantity: current }) => total + parseFloat(current),
      0
    ),
  }
  if (addRates) {
    equipmentTotals.total = rows.reduce(
      (total, { rate, quantity }) => {
        return total + (
          (convertToNumber(rate) ?? 0)
          * (convertToNumber(quantity) ?? 0)
        )
      },
      0
    )
  }
  return equipmentTotals
}

interface EquipmentBreakdownProps {
  ticket_id: string,
  project_id: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateEquipmentSubtotal: (ls: number) => void
}

export default function EquipmentBreakdown(props: EquipmentBreakdownProps) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<EquipmentTotals>({quantity: 0})
  const [rows, setRows] = useState([])

  // Get equipment data
  useEffect(() => {
    if (!!props.ticket_id && !!props.project_id) {
      // get sender and receiver for this ticket
      getTicketEquipmentBreakdown(props.project_id, props.ticket_id).then(
        (res: any) => {
          setRows(res.data)
        } // TODO BETTER ERROR HANDLING
      )
    }
  }, [props.ticket_id, props.project_id])

  // Calculate table totals
  useEffect(() => {
    const equipmentTotals = calculateEquipmentTotals(rows, props.addRates)
    if (props.addRates && equipmentTotals.total != null) {
      props.updateEquipmentSubtotal(calculateTotalWithMarkups(equipmentTotals.total, props.markupRows))
    }
    setTotals(equipmentTotals)
  }, [props.addRates, rows, props.markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.ChangeOrder.Equipment.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <EquipmentBreakdownTable
          addRates={props.addRates}
          columns={equipmentColumns({addRates: props.addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.TicketBreakdowns.equipment_subtotal")}
        />
      </Grid>
      {props.addRates
        ? <InvoiceMarkup
          rows={props.markupRows}
          total={totals.total ?? 0}
          subTotalText={t('view.TicketBreakdowns.equipment_markup')}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
