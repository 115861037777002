import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import { notifyForCoRevise, submitChangeOrderForRevise, uploadChangeOrderReviewProcessFiles } from "../../../../../../../api/changeOrderReviewProcess"
import OneTextFieldAndUploadForm, {
  OneTextFieldAndUploadFormFields,
} from "../../../../../../../forms/OneTextFieldAndUploadForm"
import { hasFilesForUpload } from "../../../../../../../util/file"

interface ReviseFormProps {
  toggleFormOpen: () => void,
  changeOrderId: string,
  projectId: string,
  changeOrderActionChange: (new_action_id: number) => void
}

export default function ReviseForm(props: ReviseFormProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitForm = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    try {
      const {data: corp} = await submitChangeOrderForRevise(props.projectId, props.changeOrderId, values)

      // upload files
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar(t('form.label.uploadingFiles'), {variant: 'info'})
        const fileUploadMessage = await uploadChangeOrderReviewProcessFiles(values.files, props.projectId, corp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      // notify revise and create history object
      await notifyForCoRevise(corp.id, props.projectId)
      props.toggleFormOpen()
      if (corp?.new_change_order_action_id) {
        props.changeOrderActionChange(corp.new_change_order_action_id)
      }
      enqueueSnackbar(t('view.ChangeOrder.revise_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.ChangeOrder.revise_fail'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <OneTextFieldAndUploadForm
        onCancel={props.toggleFormOpen}
        submitForm={submitForm}
        titleText={t('view.ChangeOrder.revise_title')}
        textFieldRequired={true}
      />
    </FormSmallContainer>
  )
}
