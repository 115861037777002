import { ExpirationField } from "../types/expiration"

export function reduxStateExpired(expiry: ExpirationField): boolean {
  if (expiry === "") return true
  return Date.now() / 1000 > expiry
}

export function getExpiry(minutes: number): number {
  return Date.now() / 1000 + minutes * 60 // adding seconds
}
