import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import React from "react"

// @ts-ignore
import { formatMoney } from "../../../../../../../libs/format"
import { BreakdownTableColumn } from "../../../../../../../components/BreakdownTable.columns"

interface LaborBreakdownTableTotalsProps {
  totals: any,
  columns: BreakdownTableColumn[],
  editable?: boolean,
  subtotalText?: string,
}

//Computes how many columns the Subtotal text cell must take up from right to left
function columnsSubtotalCellSpans(props: any){
  //Total number of columns in labor breakdown table
  const allColumns = props.columns.filter((column: any) => !column.hide).length

  //Number of columns that will be shown on subtotal table
  const totalColumns = Object.keys(props.totals).length

  // If the ticket is "sum total or no cost" and doesnt have an addable total, add an extra column that the subtotal column has to span to.
  // 1 represents the the number of columns between crew_size and total hrs that the subtotal label has to be shifted by.
  // 2 represents the extra column that will be needed between total hrs and total cost if there is cost associated that the subtotal label has to be shifted by.
  const sum_columns = props.totals.total == null ? 1 : 2
  return (allColumns - totalColumns - sum_columns)
}

export function LaborBreakdownTableTotals(props: LaborBreakdownTableTotalsProps) {
  return props.totals && Object.keys(props.totals).length ? (
    <TableRow key="totalRow">
      <TableCell
        align="right"
        colSpan={columnsSubtotalCellSpans(props)}
        key="subtotal"
        style={{ border: "none", fontWeight: 700 }}
      >
        {props.subtotalText}
      </TableCell>
      {Object.keys(props.totals).map((key, index) => (
        <TableCell
          align="right"
          // If the selected column is hours or total give it a column span of 2 to align it's column with the parent breakdown table.
          // Column hours and total are two columns away from each other.
          // Therefore if they both exist, they have to align with the parent columns.
          // 1 represents the the number of columns crew_size and total hrs that the certain column must take up.
          // 2 represents the extra column that will be needed between total hrs and total cost if there is cost associated that the certain column must take up.
          colSpan={["hours", "total"].includes(key) ? 2 : 1}
          key={index}
          style={{ background: "#f1f1f1", border: "none", fontWeight: 700 }}
        >
          {key === "total" ? formatMoney(props.totals[key]) : props.totals[key]}
        </TableCell>
      ))}
    </TableRow>
  ) : (
    <></>
  )
}
