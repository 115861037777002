import React from "react"
import { Switch } from "react-router-dom"

//@ts-ignore
import ProjectSettingsView from "./Settings"
import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"

export default function ProjectSettingsRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path="/0/project/:projectId/settings">
        <ProjectSettingsView />
      </ProjectSelectedRouteGuard>
    </Switch>
  )
}