import React, {useMemo} from "react"
import {TFunction, useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {Ticket} from '../../../../../types/ticket'
import {ActionCode, ActionInfo, getTicketActionLabel, ticketActionIdCoAttached} from '../../../../../types/action'
import {CompanyType} from '../../../../../types/company'
import {convertToNumber} from '../../../../../util/number'
import {getTicketStatusSummaryChartFill} from '../../../../../style/action'
import LogStatusChart from '../../../../../components/LogStatusChart'
import {StatusChartData} from '../../../../../components/LogStatusChart/type'


function createTicketStatusChartMapping(
  t: TFunction<'private'>,
  actionMap: {[p: number | string]: ActionInfo<ActionCode>},
  companyType: CompanyType
): {[status: string]: StatusChartData} {
  // need to prep the return object with attached to CO, since that's not in the action list
  const coAttachedStatusLabel = getTicketActionLabel(t, actionMap[1].code, companyType, true)
  const coAttachedStatusFill = getTicketStatusSummaryChartFill('draft', true)
  const retObj: {[status: string]: StatusChartData} = {}
  retObj[coAttachedStatusLabel] = {
    status: coAttachedStatusLabel,
    count: 0,
    cost: 0,
    label: coAttachedStatusLabel,
    fill: coAttachedStatusFill,
    id: ticketActionIdCoAttached,
  }
  // for each action, prep a mapping that helps us create chart data quickly
  return Object.keys(actionMap).reduce<{[status: string]: StatusChartData}>(
    // NOTE: actionId is a number
    (statusMap, action_id) => {
      const statusLabel = getTicketActionLabel(t, actionMap[action_id].code, companyType, false)
      const statusFill = getTicketStatusSummaryChartFill(actionMap[action_id].code, false)
      statusMap[statusLabel] = {
        status: statusLabel,
        count: 0,
        cost: 0,
        label: statusLabel,
        fill: statusFill,
        id: action_id
      }
      return statusMap
    },
    retObj
  )
}

function addTicketToStatusChartMapping(
  // updated in place
  statusMap: {[status: string]: StatusChartData},
  ticket: Ticket,
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<ActionCode>},
  companyType: CompanyType
): void {
  const ticketStatusLabel = getTicketActionLabel(
    t,
    actionMap[ticket.action_id].code,
    companyType,
    !!ticket.change_order_number
  )
  statusMap[ticketStatusLabel].count += 1
  statusMap[ticketStatusLabel].cost += convertToNumber(ticket.total_amount) ?? 0
}

function convertTicketsToTicketStatusChart(
  tickets: Ticket[],
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<ActionCode>},
  companyType: CompanyType
): StatusChartData[] {
  const statusChartMap = createTicketStatusChartMapping(t, actionMap, companyType)
  for (let ticket of tickets) {
    addTicketToStatusChartMapping(statusChartMap, ticket, t, actionMap, companyType)
  }
  // just trying to get similar statuses near one another in the chart
  return Object.values(statusChartMap).sort((cd1, cd2) => cd1.id > cd2.id ? 1 : 0)
}

export default function TicketListStatusChart(): JSX.Element {
  const company = useTracFloSelector((state) => state.company)
  const tickets = useTracFloSelector((state) => state.tickets.items)
  const actionMap = useTracFloSelector((state) => state.action.idObjectMapping)
  const { t } = useTranslation("private")

  const statusData: StatusChartData[] = useMemo(() => {
    return convertTicketsToTicketStatusChart(tickets, t, actionMap, company.company_type)
  }, [tickets, t, actionMap, company.company_type])

  return (
    <LogStatusChart statusData={statusData}/>
  )
}
