import React from "react"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import {useTranslation} from "react-i18next"
import {Tooltip} from "@mui/material"

import {CompanyType} from "../../../../../../../types/company"
// @ts-ignore
import Button from "../../../../../../../components/Button"
import {EstimateActionCode} from "../../../../../../../types/action"


function showThisButton(companyType: CompanyType, actionCode: EstimateActionCode): boolean {
  if (companyType !== 'trade') return false
  const validStatuses: EstimateActionCode[] = ['draft', 'revise', 'submitted']
  return validStatuses.includes(actionCode)
}

interface SubmitButtonProps {
  companyType: CompanyType,
  estimateActionCode: EstimateActionCode,
  togglePocketForm: () => void,
  disabled: boolean,
  disabledMessage: string,
}

export function SubmitButton(
  {companyType, togglePocketForm, disabled, disabledMessage, estimateActionCode}: SubmitButtonProps
) {
  const { t } = useTranslation("private")

  return showThisButton(companyType, estimateActionCode) ? (
    <Grid item>
      <Tooltip title={disabled? disabledMessage : null} placement={'top'}>
        <div>
          <Button
            endIcon={<SendIcon />}
            style={{ minWidth: 125 }}
            onClick={togglePocketForm}
            disabled={disabled}
          >
            {t("view.Estimates.SingleEstimate.Review.Submit.button")}
          </Button>
        </div>
      </Tooltip>
    </Grid>
  ) : (
    <></>
  )
}
