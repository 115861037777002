import React from "react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

// @ts-ignore
import Button from "./Button"


interface ConfirmOrCancelPromptProps {
  submitForm: () => Promise<void>,
  closeDialog: () => void,
  dialogPrompt: string,
}

export default function ConfirmOrCancelPrompt({submitForm, closeDialog, dialogPrompt}: ConfirmOrCancelPromptProps) {
  const { t } = useTranslation("private")

  return (
    <>
      <Grid container padding={4}>
        <Grid item xs={12}>
          <Typography variant="h3">
            {dialogPrompt}
          </Typography>
        </Grid>

        <Grid item container xs={12} justifyContent="space-between" paddingTop={3}>
          <Button onClick={closeDialog} type="submit" color="secondary" variant="text">
            {t('form.label.cancel')}
          </Button>
          <Button onClick={submitForm} type="submit">
            {t('form.label.confirm')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
