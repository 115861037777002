import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import {useTranslation} from 'react-i18next'

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../../../components/Pocket"
// @ts-ignore
import { getCompany } from "../../../../../../store/features/companySlice"
import {ActionInfo, EstimateActionCode} from "../../../../../../types/action"
import ApproveReviseButtons from "./ReviewButtons/ApproveReviseButtons"
import ReviseForm from "./ReviewForms/EstimateReviseForm"
import {getProjectClients, ProjectClient} from "../../../../../../api/ticketReviewProcess"
import {Estimate} from '../../../../../../types/estimate'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {SubmitButton} from './ReviewButtons/SubmitButton'
import SubmitContainer from './ReviewForms/SubmitContainer'
import ApproveForm from './ReviewForms/ApproveForm'


type FormName = "share" | "submit" | "approve" | "revise" | null

interface EstimateReviewButtonsProps {
  estimate: Estimate,
  estimateActionInfo: ActionInfo<EstimateActionCode>,
  estimateActionChange: (new_action_id: number) => void,
  projectId: string,
}

export default function EstimateReviewButtons(
  {estimate, projectId, estimateActionInfo, estimateActionChange}: EstimateReviewButtonsProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [showThisForm, setShowThisForm] = useState<FormName>(null)
  const company: any = useTracFloSelector(getCompany)
  const [clients, setClients] = useState<ProjectClient[]>([])

  const toggleEstimateReviewForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  useEffect(() => {
    if (!!projectId && !!estimate.id) {
      getProjectClients(projectId).then(
        (clients) => {
          if (!!clients) {
            setClients(clients)
          }
        }
      )
    }
  }, [projectId, estimate.id])

  return (
    <>
      {/* Review Process Buttons */}
      <Container removeTop>
        <Grid container xs={12} spacing={2}>
          <SubmitButton
            companyType={company.company_type}
            estimateActionCode={estimateActionInfo.code}
            togglePocketForm={() => toggleEstimateReviewForm('submit')}
            disabled={clients.length === 0}
            // intentionally using the same translate code
            disabledMessage={t('view.ChangeOrder.noClientsTooltip')}
          />

          <ApproveReviseButtons
            companyType={company.company_type}
            estimateActionCode={estimateActionInfo.code}
            toggleReviseForm={() => toggleEstimateReviewForm('revise')}
            toggleApproveForm={() => toggleEstimateReviewForm('approve')}
          />
        </Grid>
      </Container>

      {/* Review Process Forms */}
      <Pocket show={showThisForm === 'submit'}>
        <SubmitContainer
          toggleFormOpen={() => toggleEstimateReviewForm("submit")}
          estimateId={estimate.id}
          estimateActionChange={estimateActionChange}
          projectId={projectId}
          clients={clients}
        />
      </Pocket>

      <Pocket show={showThisForm === 'revise'}>
        <ReviseForm
          toggleFormOpen={() => toggleEstimateReviewForm("revise")}
          estimateId={estimate.id}
          projectId={projectId}
          estimateActionChange={estimateActionChange}
        />
      </Pocket>

      <Pocket show={showThisForm === 'approve'}>
        <ApproveForm
          toggleFormOpen={() => toggleEstimateReviewForm("approve")}
          estimateId={estimate.id}
          projectId={projectId}
          estimateActionChange={estimateActionChange}
          bulletinId={estimate.bulletin_id}
        />
      </Pocket>
    </>
  )
}

