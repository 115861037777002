import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { AxiosResponse } from "axios/index"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {useHistory} from "react-router-dom"
import {useSnackbar} from "notistack"

import {AdminProjectLicensesColumns} from "./AdminProjectLicenses.columns"
import {getProjectLicensesForAdmin,
        updateProjectActiveStatus,
        updateChangeOrderActiveStatus,
        updateCanAddSubsStatus} from "../../../../api/admin"
// @ts-ignore
import Container from "../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../components/DataTable"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import { Company } from "../../../../types/company"
import { ChangeableLicenseField, ProjectWithLicense } from "../../../../types/project"


async function updateToggleStatus (
  projectLicenseId: number,
  field: ChangeableLicenseField,
  status?: boolean
): Promise<AxiosResponse<ProjectWithLicense> | null> {
    if (field === 'projectLicenseActiveStatus') return updateProjectActiveStatus(projectLicenseId)
    if (field === 'changeOrderActiveStatus') return updateChangeOrderActiveStatus(projectLicenseId, status as boolean)
    if (field === 'canAddSubs') return updateCanAddSubsStatus(projectLicenseId, status as boolean)
    return null
}

export default function AdminSingleCompanyView() {
  const [adminProjectLicenses, setAdminProjectLicenses] = useState<ProjectWithLicense[]>([])
  const { t } = useTranslation("private")
  const { company_id } = useParams<{company_id: string}>()
  const currentCompany = useTracFloSelector((state) => state.user.companies.find((singleCompany) => singleCompany.id === company_id))
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()


  const toggleActiveStatus = async (projectLicenseId: number, field: ChangeableLicenseField, status?: boolean): Promise<void> => {
    if (!!projectLicenseId) {
      const res = await updateToggleStatus(
        projectLicenseId,
        field,
        status
      )
      if (res?.data?.project_license_id) {
        const index = adminProjectLicenses.map(project => project.project_license_id).indexOf(projectLicenseId)
        Object.assign(adminProjectLicenses[index], res?.data)
        setAdminProjectLicenses([...adminProjectLicenses])
      } else {
          enqueueSnackbar(t("view.Admin.issueTogglingActiveStatus"), {
            variant: "error",
        })
      }
    } else {
        enqueueSnackbar(t("view.Admin.issueTogglingActiveStatus"), {
          variant: "error",
      })
    }
  }

  useEffect(() => {
    if(!!company_id) {
      if(!!currentCompany) {
        getProjectLicensesForAdmin(company_id).then(
          (res) => {
            if (res?.data?.length > 0) {
              const adminProjectLicensesWithGcAndSubCompanies = res.data.map((project) =>
              ({
                ...project,
                gcCompaniesOnProject: project.all_companies_on_project
                  .filter((companyOnProject) => companyOnProject.role === "cm"),
                subCompaniesOnProject: project.all_companies_on_project
                  .filter((companyOnProject) => companyOnProject.role === "trade")
                  .sort((c1, c2) => c1.name > c2.name ? 1 : -1),
              }
              ))
              setAdminProjectLicenses(adminProjectLicensesWithGcAndSubCompanies)
            }
          }
        )
      } else {
        enqueueSnackbar(t("view.Admin.issueGettingCompany"), {
          variant: "error",
        })
        history.push(`/0/admin/companies`)
      }
    }
  }, [company_id, currentCompany])

  const columns = AdminProjectLicensesColumns(t, company_id, currentCompany as Company, toggleActiveStatus)

  return !!currentCompany ? (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">
              {currentCompany.name} Info
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Link to={`/0/admin/companies`}>
                {t("view.Admin.backToTracFloCompaniesPageTitle")}
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container fullWidth removeTop>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.Admin.projectLicensesTitle")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            rows={adminProjectLicenses}
            getRowId={(row: any) => row.project_id}
            showCheckboxes={false}
            getRowHeight={() => 'auto'}
          />
        </Grid>
      </Container>
    </>
  )
  : (<></>)
}