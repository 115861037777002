import React from "react"
import { useDispatch } from "react-redux"
import RefreshIcon from '@mui/icons-material/Refresh'
import { Tooltip } from "@mui/material"

//@ts-ignore
import Button from "./Button"


interface RefreshButtonProps {
  reload: () => void,
}

export default function RefreshButton(props: RefreshButtonProps) {
  const { reload } = props
  const dispatch = useDispatch()

  return (
    <Tooltip placement='top' title={'Refresh the list'}>
      <div>
        <Button onClick={() => {dispatch(reload())}} startIcon={<RefreshIcon/>} variant="text" sx={{fontSize: 13}}>
            Refresh
        </Button>
      </div>
    </Tooltip>
  )
}

