import React from "react"
import DownloadIcon from '@mui/icons-material/Download'
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

import Fab from "../../../../../../components/Fab"
import {getChangeOrderInvoice} from '../../../../../../api/changeOrder'


interface CoDownloadButtonProps {
  projectId: string,
  coId: string,
}

export default function CoDownloadButton({projectId, coId}: CoDownloadButtonProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const getInvoice = async () => {
    const snackbarKey = enqueueSnackbar(
      t('view.ChangeOrder.CoDownloadButton.downloading'),
      {variant: 'info', persist: true,}
    )
    try {
      const pdfRes = await getChangeOrderInvoice(projectId, coId)
      closeSnackbar(snackbarKey)
      if (pdfRes.status === 200 && pdfRes.data.success) {
        enqueueSnackbar(t('view.ChangeOrder.CoDownloadButton.success'), {variant: 'success'})
        return
      }
      enqueueSnackbar(
        t('view.ChangeOrder.CoDownloadButton.fail'),
        {variant: 'error', style: {whiteSpace: 'pre-wrap'}}
      )
    } catch (_) {
      closeSnackbar(snackbarKey)
      enqueueSnackbar(
        t('view.ChangeOrder.CoDownloadButton.fail'),
        {variant: 'error', style: {whiteSpace: 'pre-wrap'}}
      )
    }
  }

  return (
    <Fab
      // @ts-ignore
      edge="end"
      size="medium"
      style={{color: "white", backgroundColor: "#143366"}}
      variant="extended"
      onClick={getInvoice}
    >
      <DownloadIcon />
      {t("view.Tickets.download")}
    </Fab>
  )
}
