import React from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"

import InvoiceDate from "./InvoiceDate"
import { Ticket } from "../../types/ticket"


interface InvoiceNumberDateProps {
  ticket: Ticket
}

const numberStyle = {fontFamily: "'Share Tech Mono', monospace", fontWeight: 900, width: "100%"}

export default function InvoiceNumberDate({ticket}: InvoiceNumberDateProps): JSX.Element {
  const { t } = useTranslation("private")
  const { invoice_number, date_invoice, number } = ticket

  const displayInvoiceNumber = invoice_number != null && invoice_number.length > 0
    ? t("view.Tickets.Summary.invoiceNumber", {invoice_number})
    : null

  const displayTicketNumber = invoice_number != null && invoice_number.length > 0
    ? t("view.Tickets.Summary.ticketNumber", {number}) 
    : `#${number}`

  return (
    <Grid container item xs={8}>
      <Typography variant="h1" style={{ ...numberStyle, marginBottom: 5}}>
        {displayInvoiceNumber ?? displayTicketNumber}
      </Typography>
      { date_invoice != null ? <InvoiceDate date_invoice={date_invoice} /> : <></> }
      { displayInvoiceNumber != null
        ? <Typography variant="h1" style={{...numberStyle, fontSize: 20, marginBottom: 0}}>
          {displayTicketNumber}
        </Typography>
        : <></>
      }
    </Grid>
  )
}
