import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"

// @ts-ignore
import Form from "../../../forms/Signin"
import {useQuery} from "../../../util/url"

const useStyles = makeStyles<Theme>((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  root: {
    margin: "auto",
    maxWidth: 500,
  },
}))

export default function SignInFormWrapper() {
  const { t } = useTranslation("public")
  const classes = useStyles()
  // passing forward redirect
  const redirect = useQuery().get("redirect")
  const signUpUrl = `/sign-up${redirect ? `?redirect=${redirect}` : ''}`

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>

        <Typography
          style={{
            color: "#757575",
            textAlign: "center",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Trans
            i18nKey="view.signin.switch"
            t={t}
            components={[<Link className={classes.link} to={signUpUrl} />]}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Form />
      </Grid>
    </Grid>
  )
}
