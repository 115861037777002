import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import React from "react"

// @ts-ignore
import { formatMoney } from "../libs/format"
import {BreakdownTableColumn} from "./BreakdownTable.columns"


interface BreakdownTableTotalsProps {
  totals: any,
  columns: BreakdownTableColumn[],
  editable?: boolean,
  subtotalText?: string,
}

export function BreakdownTableTotals(props: BreakdownTableTotalsProps) {
  const subtotalTextDefault: string = 'Subtotal:'
  return props.totals && Object.keys(props.totals).length ? (
    <TableRow key="totalRow">
      <TableCell
        align="right"
        colSpan={
          props.columns.filter((column) => !column.hide).length
          - Object.keys(props.totals).length
          - (props.totals.hours && props.totals.total != null ? 2 : 0)
          - (props.totals.hours && props.totals.total == null ? 1 : 0)
          - (props.editable ? 1 : 0)
        }
        key="subtotal"
        style={{ border: "none", fontWeight: 700 }}
      >
        {props.subtotalText ?? subtotalTextDefault}
      </TableCell>
      {Object.keys(props.totals).map((key, index) => (
        <TableCell
          align="right"
          colSpan={props.totals.hours && ["hours", "total"].includes(key) ? 2 : 1}
          key={index}
          style={{ background: "#f1f1f1", border: "none", fontWeight: 700 }}
        >
          {key === "total" ? formatMoney(props.totals[key]) : props.totals[key]}
        </TableCell>
      ))}
    </TableRow>
  ) : (
    <></>
  )
}
