import React from "react"
import { Switch } from "react-router-dom"

import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import {goToEstimateList, goToSingleEstimate, goToEditEstimate, goToCreateEstimate} from "../../../../util/routes"
import EstimateList from './EstimateList'
import SingleEstimateView from "./SingleEstimate"
import EditEstimate from './EditEstimate'
import CreateEstimate from './CreateEstimate'


export default function EstimatesRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToEstimateList(":projectId")}>
        <EstimateList />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToCreateEstimate(":projectId")}>
        <CreateEstimate />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToSingleEstimate(":projectId", ":estimateId")}>
        <SingleEstimateView />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToEditEstimate(":projectId", ":estimateId")}>
        <EditEstimate />
      </ProjectSelectedRouteGuard>
    </Switch>
  )
}
