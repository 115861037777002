import { useEffect, useRef, useState } from 'react'
import {useTheme} from "@mui/material"
import {useParams} from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

//@ts-ignore
import Container from "../../../../../components/Container"
import BulletinInvoice from './Invoice'
import BulletinHistory from './History'
import BulletinReviewSection from './Review'
import { getBulletinById } from '../../../../../api/bulletin'
import { Bulletin } from '../../../../../types/bulletin'
import { useTracFloSelector } from '../../../../../store/useTracFloSelector'


export default function SingleBulletinView(): JSX.Element {
  const { bulletinId, projectId } = useParams<{bulletinId: string, projectId: string}>()
  const [bulletin, setBulletin] = useState<Bulletin | null>(null)
  const project = useTracFloSelector((state) => state.project)
  const {t} = useTranslation("private")
  const {enqueueSnackbar} = useSnackbar()
  const theme = useTheme()
  const historyRef = useRef<any>()

  useEffect(() => {
    if (projectId && bulletinId) {
      getBulletinById(projectId, bulletinId)
        .then((res) => {
          if(!!res.data){
            setBulletin(res.data)
          }
        })
        .catch(() => {
          enqueueSnackbar(t("view.Bulletins.SingleBulletin.loadBulletinFail"), {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, bulletinId])

  return project.id === projectId && !!bulletin
    ? (
      <>
        <BulletinReviewSection bulletin={bulletin} projectId={project.id} historyRef={historyRef}/>

        <Container fullWidth style={{ background: theme.palette.grey[200] }}>
          <BulletinInvoice bulletin={bulletin} project={project}/>
        </Container>

        <div ref={historyRef}>
          <Container>
            <BulletinHistory bulletin_id={bulletin.id} project_id={project.id} />
          </Container>
        </div>
      </>
    )
    : <></>
}
