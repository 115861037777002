import React from "react"

// @ts-ignore
import ButtonDelete from "../../components/ButtonDelete"
// @ts-ignore
import { formatMoney } from "../../libs/format"
import {BreakdownTableColumn} from "../BreakdownTable.columns";

interface MarkupColumnProps {
  editable: boolean,
  handleDeleteRow: ((index: number) => void) | null,
  t: (s: string) => string,
}

export default function Markup({editable, handleDeleteRow, t}: MarkupColumnProps): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t("view.Tickets.Markup.description"),
      flex: 2,
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "percent",
      flex: 1,
      headerName: t("view.Tickets.Markup.amount"),
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => `${params.value}%`,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total",
      flex: 1,
      headerName: t("view.Tickets.Markup.total"),
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      flex: 1,
      headerName: "",
      hide: !editable,
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <ButtonDelete
            onClick={() => {
              if (handleDeleteRow != null) {
                handleDeleteRow(params.index)
              }
            }}
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          />
        )
      },
    },
  ]
}
