import React from "react"

import ButtonDelete from "../../components/ButtonDelete"
import { formatMoney } from "../format"
import TinyTextField from "../../forms/TinyTextField"

export default function Markup(props = {}) {
  const { editable, handleDeleteRow, handleFieldChanges, t } = props

  return [
    {
      align: "left",
      headerAlign: "left",
      field: "title",
      headerName: t("view.ChangeOrder.description"),
      flex: 2,
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "amount",
      flex: 1,
      headerName: t("view.ChangeOrder.amount"),
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return editable
          ? (<TinyTextField
            fieldWidth={'100px'}
            value={params.value}
            handleChange={
              (newValue) => handleFieldChanges(
                // params.index is "1 indexed", so we need to subtract 1
                params.index - 1,
                [{fieldPropName: 'amount', newValue},]
              )
            }
            isPercent={true}
            isNumber={true}
          />)
          : `${params.value}%`
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total",
      flex: 1,
      headerName: "Total",
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params) => formatMoney(params.value),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      flex: 1,
      headerName: "",
      hide: !editable,
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ButtonDelete
            onClick={() => {
              if (handleDeleteRow) {
                handleDeleteRow(params.index)
              }
            }}
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          />
        )
      },
    },
  ]
}
