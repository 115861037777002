import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import MonthlyTicketsReportTable from "./MonthlyTickets.table"
import { getMonthlyTicketsReportForAdmin } from "../../../../../api/admin"
//@ts-ignore
import FormWideContainer from "../../../../../components/FormWideContainer"


export default function MonthlyTicketsReportView() {
  const [ monthlyTicketsReport,
          setMonthlyTicketsReport] = useState<{month: string, tickets_created: string, tickets_created_amount: string}[]>([])
  const { t } = useTranslation("private")

  useEffect(() => {
    getMonthlyTicketsReportForAdmin().then(
      (res) => {
        if (res?.data?.length > 0) {
          const monthlyTicketsReportData = res.data.slice(0, 6).reverse()
          setMonthlyTicketsReport(monthlyTicketsReportData)
        }
      }
    )
  }, [])

  return (
    <>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.Admin.monthlyTicketsReportTitle")}</Typography>
        </Grid>
        <MonthlyTicketsReportTable monthlyTicketsReportData={monthlyTicketsReport}/>
      </FormWideContainer>
    </>
  )
}