import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import AddIcon from "@mui/icons-material/Add"

import Fab from "../components/Fab"
//@ts-ignore
import TextField from "../components/Fields/Text"

interface CreateEstimateMarkupFormProps {
  submitValues: (v: {description: string, percent: string}) => void
}

export default function CreateEstimateMarkupForm({submitValues}: CreateEstimateMarkupFormProps) {
  const { t } = useTranslation("private")
  return (
    <div>
      <Formik
        initialValues={{
          description: "",
          percent: "",
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().min(2).required(t("form.CreateEstimateMarkupForm.descriptionRequired")),
          percent: Yup.number().required(t("form.CreateEstimateMarkupForm.percentRequired")),
        })}
        onSubmit={(values, { resetForm }) => {
          submitValues(values)
          resetForm()
        }}
      >
        {({
            errors,
            handleBlur,
            touched,
            values,
            submitForm,
            handleChange,
          }) => {
          return (
            <Form>
              <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label={t("form.CreateEstimateMarkupForm.descriptionLabel")}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    error={Boolean(touched.percent && errors.percent)}
                    helperText={touched.percent && errors.percent}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: { min: 1, step: 1 },
                    }}
                    label={t("form.CreateEstimateMarkupForm.percentLabel")}
                    name="percent"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.percent}
                  />
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                  <Fab disabled={!values.description || !values.percent} onClick={submitForm}>
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
