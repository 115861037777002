import React from "react"

// @ts-ignore
import {CompanyType} from "../../../../../../types/company"
import {ActionCode} from "../../../../../../types/action"
import { ticketHasBeenTmApproved } from "../../../../../../api/ticketReviewProcess";
import BackToDraftButton from "./ReviewButtons/BackToDraft";
import BackToCostEditButton from "./ReviewButtons/BackToCostEdit";

interface CostApproveRejectReviseButtonsProps {
  companyType: CompanyType,
  projectId: string,
  ticket: any,
  ticketActionCode: ActionCode,
  ticketActionChange: (new_action_id: number) => void,
  hasAttachedCo: boolean,
}

export default function BackToDraftOrCostEdit(props: CostApproveRejectReviseButtonsProps) {
  const [hasBeenTmApproved, setHasBeenTmApproved] = React.useState(false)

  React.useEffect(() => {
    if (props.projectId && props.ticket.id) {
      if(props.companyType === 'trade' && props.ticketActionCode === 'cost_submitted'){
        ticketHasBeenTmApproved(props.ticket.id, props.projectId).then(
          (data: boolean) => {
            setHasBeenTmApproved(data)
          }
        )
      }
      else {
        setHasBeenTmApproved(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId, props.ticket, props.ticketActionCode])


  return hasBeenTmApproved ? (
    <BackToCostEditButton
      companyType={props.companyType}
      projectId={props.projectId}
      ticketId={props.ticket.id}
      ticketActionCode={props.ticketActionCode}
      ticketActionChange={props.ticketActionChange}
      hasAttachedCo={props.hasAttachedCo}
  />
  ) : (
    <BackToDraftButton
      companyType={props.companyType}
      projectId={props.projectId}
      ticketId={props.ticket.id}
      ticketActionCode={props.ticketActionCode}
      ticketActionChange={props.ticketActionChange}
      hasAttachedCo={props.hasAttachedCo}
    />
  )
}
