import Grid from "@mui/material/Grid"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import React from "react"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Button from "./Button"

interface ApproveRejectReviseButtonsProps {
  toggleApproveForm: () => void,
  toggleRejectForm: () => void,
  toggleReviseForm: () => void,
}

export default function ApproveRejectReviseButtons(props: ApproveRejectReviseButtonsProps) {
  const { t } = useTranslation("private")

  return (
    <>
      <Grid item>
        <Button
          color="success"
          endIcon={<CheckBoxIcon />}
          style={{ minWidth: 125 }}
          onClick={props.toggleApproveForm}
        >
          {t("view.ChangeOrder.Summary.approve")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="error"
          endIcon={<ThumbDownIcon />}
          style={{ minWidth: 125 }}
          onClick={props.toggleRejectForm}
        >
          {t("view.ChangeOrder.Summary.reject")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="warning"
          onClick={props.toggleReviseForm}
        >
          {t("view.ChangeOrder.Summary.revise")}
        </Button>
      </Grid>
    </>
  )
}
