import { SupportedDateLanguages } from "../types/language"
import {convertToNumber, roundToDecimal} from '../util/number'

export const formatDate = (startDate: string, endDate?: string | null): string => {
  const sDate = new Date(startDate)
  const dateFormat: any = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }
  const shortFormat: any = {
    month: "2-digit",
    day: "2-digit",
  }
  if (endDate) {
    const eDate = new Date(endDate)
    const sFormatted = sDate.toLocaleDateString(
      "en-US",
      sDate.getFullYear() === eDate.getFullYear() ? shortFormat : dateFormat
    )
    const eFormatted = eDate.toLocaleDateString("en-US", dateFormat)
    return `${sFormatted}-${eFormatted}`
  }

  return sDate.toLocaleDateString("en-US", dateFormat)
}

export const formatDateToMonthYear = (startDate: string): string => {
  const sDate = new Date(startDate)
  const dateFormat: any = {
    month: "2-digit",
    year: "numeric",
  }

  return sDate.toLocaleDateString("en-US", dateFormat)
}

// NOTE: amount MUST BE A NUMBER -- strings may not be formatted correctly
export const formatMoney = (amount: number, notRounded?: boolean): string => {
  let options: any = {
    style: "currency",
    currency: "USD",
  }
  if (notRounded) {
    options.maximumSignificantDigits = 5
  }
  const formatter = new Intl.NumberFormat("en-US", options)

  return formatter.format(amount)
}

export function formatDateTimeLongMonth(
  dateString: string,
  dateLanguage: SupportedDateLanguages,
): string {
  const date = new Date(dateString)
  const dateLongOptions: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }
  // NOTE: for changing language of date object see:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  return date.toLocaleDateString(dateLanguage, dateLongOptions)
}

export function formatDateTimeShort(
  dateString: Date,
): string {
  const date = new Date(dateString)
  const dateShortOptions: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }
  return date.toLocaleDateString("en-US", dateShortOptions)
}

// Formats numbers in the typical percent style (usually between 0 -> 1, where 0.1 = 10%)
export function formatPercent(perc: string | number): string {
  return `${roundToDecimal((convertToNumber(perc) ?? 0) * 100, 0)}%`
}
