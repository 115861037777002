import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import OneTextFieldAndUploadForm, {OneTextFieldAndUploadFormFields} from '../../../../../../../forms/OneTextFieldAndUploadForm'
import {hasFilesForUpload} from '../../../../../../../util/file'
import {
  notifyForEstimateRevise,
  submitEstimateForRevise,
  uploadEstimateReviewProcessFiles,
} from '../../../../../../../api/estimateReviewProcess'


interface ReviseFormProps {
  toggleFormOpen: () => void,
  estimateId: string,
  projectId: string,
  estimateActionChange: (new_action_id: number) => void
}

export default function EstimateReviseForm(
  {toggleFormOpen, estimateId, projectId, estimateActionChange}: ReviseFormProps
): JSX.Element {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitForm = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    try {
      const res = await submitEstimateForRevise(projectId, estimateId, values)
      if (res.status === 200 && res.data?.id != null) {
        const erp = res.data
        // upload files
        if (hasFilesForUpload(values.files)) {
          const uploadingKey = enqueueSnackbar(t('form.label.uploadingFiles'), {variant: 'info'})
          const fileUploadMessage = await uploadEstimateReviewProcessFiles(values.files, projectId, erp.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        // notify revise and create history object
        await notifyForEstimateRevise(projectId, erp.id)
        toggleFormOpen()
        estimateActionChange(erp.new_estimate_action_id)
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Revise.apiSuccess'), {variant: "success"})
      } else {
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Revise.apiFail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Revise.apiFail'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <OneTextFieldAndUploadForm
        onCancel={toggleFormOpen}
        submitForm={submitForm}
        titleText={t('view.Estimates.SingleEstimate.Review.Revise.formTitle')}
        textFieldRequired={true}
      />
    </FormSmallContainer>
  )
}
