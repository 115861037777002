import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Theme } from "@mui/material"

import {BulletinActionCode, getBulletinActionLabel} from "../types/action"
import {getBulletinActionFill} from '../style/action'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.grey[900],
    border: "none",
    borderRadius: 5,
    color: "white",
    fontWeight: 900,
    fontSize: 13,
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  draft: {
    background: getBulletinActionFill('draft'),
  },
  review: {
    background: getBulletinActionFill('review'),
  },
  estimates_approved: {
    background: getBulletinActionFill('estimates_approved'),
  },
  owner_approved: {
    background: getBulletinActionFill('owner_approved'),
  },
  not_needed: {
    background: getBulletinActionFill('not_needed'),
    border: "solid 2px",
    borderColor: "#516487",
    color: "#687997",
  },
}))

interface BulletinActionProps {
  code: BulletinActionCode
}

export default function BulletinAction({code}: BulletinActionProps) {
  const { t } = useTranslation("private")
  const classes = useStyles()
  const className = code

  return (
    <Box className={`${classes.root} ${classes[className]}`}>
      <Typography>{getBulletinActionLabel(t, code)}</Typography>
    </Box>
  )
}
