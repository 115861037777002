import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import React from "react"

interface InvoiceTopNumberProps {
  number?: string,
}

export default function InvoiceTopNumber({number}: InvoiceTopNumberProps): any {
  return (
    <Grid container item xs={8} justifyContent={"flex-start"}>
      { number 
        ? <Typography
          variant="h1"
          style={{
            fontFamily: "'Share Tech Mono', monospace",
            fontWeight: 900,
          }}
        >
          {`#${number}`}
        </Typography>
        : <></>
      }
    </Grid>
  )
}
