import React from 'react'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {AttachEstimate} from '../../../../../../components/AttachEstimate'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import { 
  attachEstimateToTicket, 
  AttachEstimateToTicketOutput, 
  removeEstimateFromTicket 
} from '../../../../../../api/ticket'


export type TicketAttachedEstimateProps = {
  ticketId: string,
  estimate_id: string | null,
  attachEstimate: (output: AttachEstimateToTicketOutput) => void,
  removeEstimate: () => void,
}

export function TicketAttachEstimate(
  {estimate_id, ticketId, attachEstimate, removeEstimate}: TicketAttachedEstimateProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const project = useTracFloSelector((state) => state.project)

  const attachEstimateFromComponent = async (estimateId: string) => {
    try {
      const res = await attachEstimateToTicket(project.id, ticketId, estimateId)
      if (res.status !== 200 || !res.data.estimate_id) {
        enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.attachFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.attachSuccess'), {variant: 'success'})
      attachEstimate(res.data)
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.attachFail'), {variant: 'error'})
    }
  }

  const removeEstimateFromComponent = async () => {
    try {
      const res = await removeEstimateFromTicket(project.id, ticketId)
      if (res.status !== 200) {
        enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.removeFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.removeSuccess'), {variant: 'success'})
      removeEstimate()
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.TicketAttachEstimate.removeFail'), {variant: 'error'})
    }
  }

  return (
    <AttachEstimate
      estimate_id={estimate_id}
      attachNewEstimate={attachEstimateFromComponent}
      removeEstimate={removeEstimateFromComponent}
    />
  )
}

