import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {apiHeaders} from "../../api/util"
import config from "../../libs/config"
import {
  CompanyStore,
  Company,
  CompanyWithoutUserPermissions,
} from "../../types/company"
import { TracFloState } from "../../types/state"

const companyApi = `${config.api.url}/company`
const initialState: CompanyStore = {
  id: "",
  logo_url: "",
  name: "",
  company_user_role: "super",
  company_type: "trade",
  phone: "",
  address: "",
  exp: "",
  status: "idle",
}

export const loadCompany = createAsyncThunk<Company, void, {state: TracFloState}>(
  "company/loadCompany",
  async (id, thunkAPI
) => {
  const { user } = await thunkAPI.getState()
  const response = await axios({
    headers: apiHeaders(undefined, user.token),
    method: "get",
    timeout: 20000,
    url: `${companyApi}/${id}`,
  })
  const { data } = response
  if (response.status === 200) {
    return data
  } else {
    return thunkAPI.rejectWithValue(data)
  }
})

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state: CompanyStore, action: {payload: Company }) => {
      const company = action.payload
      state.id = company.id
      state.logo_url = company.logo_url
      state.name = company.name
      state.company_type = company.company_type
      state.phone = company.phone
      state.address = company.address
      state.company_user_role = company.company_user_role
      state.status = "set"
    },
    updateCompanyInfo: (state: CompanyStore, action: {payload: CompanyWithoutUserPermissions}) => {
      const companyInfo = action.payload
      state.logo_url = companyInfo.logo_url
      state.name = companyInfo.name
      state.company_type = companyInfo.company_type
      state.phone = companyInfo.phone
      state.address = companyInfo.address
    },
    resetCompany: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCompany.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadCompany.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadCompany.fulfilled, (state: any, action: any) => {
        state = action.payload
        state.status = "fulfilled"
      })
  },
})

export const { setCompany, updateCompanyInfo, resetCompany } = companySlice.actions

export const getCompany = (state: TracFloState) => state.company

export const getCompanyType = (state: TracFloState) => state.company.company_type

export const isAccountSelected = (state: TracFloState) => !!state.company.id

export default companySlice.reducer
