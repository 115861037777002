import * as React from "react"
import AppBar from "@mui/material/AppBar"
import MuiTabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"

// @ts-ignore
import Container from "../../../../components/Container"

interface TabContentProps {
  children: any, 
}

function TabContent({children} : TabContentProps) 
{
  return (
    <div role="tabpanel">
      <Box>{children}</Box>
    </div>
  )
}

export default function OverviewTabs(props: { tabs: any[] }) {
  const { tabs } = props
  const [tabIndex, setTabIndex] = React.useState(0)

  const selectedTab = tabs[tabIndex]

  const switchTabs = (_: any, newValue: any) => {
    setTabIndex(newValue)
  }

  return tabs.length ? (
    <Box>
      <Container fullWidth removeTop removeBottom>
        <AppBar position="static"
          color="transparent"
          sx={{
            color: "#00000099", 
          }}
          elevation={0}
        >
          <MuiTabs
            value={tabIndex}
            onChange={switchTabs}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, index) => (
              <Tab 
                icon={
                  <img 
                    alt="" 
                    role="presentation" 
                    src={tab.icon} 
                  />} 
                iconPosition={"start"} 
                key={index} 
                label={tab.navigationText}
              />
            ))}
          </MuiTabs>
        </AppBar>
      </Container>
      <Box sx={{ width: "100%" }}>
        { selectedTab !== null ?
          <TabContent>
            {selectedTab.content}
          </TabContent>
          : <></>
        }
      </Box>
    </Box>
  ) : (
    <></>
  )
}
