import React from 'react'
import {useTranslation} from 'react-i18next'
import {GridColDef} from '@mui/x-data-grid'
import {useDispatch} from 'react-redux'
import {useSnackbar} from 'notistack'

// @ts-ignore
import DtRowTitle from "../../../../../components/DtRowTitle"
import theme from '../../../../../theme'
import templateUrls from '../../../../../util/csv/templateUrls'
import {convertCsvStringToEquipArray} from './ImportEquipment.data'
import CsvImport, {CsvImportUtils, ImportInstructionsUtils} from '../../../../../components/CsvImport'
import {DisplayImportedObjectsUtils} from '../../../../../components/CsvImportDisplayObjects'
import {convertToNumber} from '../../../../../util/number'
import {formatMoney} from '../../../../../libs/format'
import {createMultipleEquipmentTypes} from '../../../../../api/equipment'
import {addMultipleEquipmentTypes} from '../../../../../store/features/equipmentSlice'


interface EquipmentForUpload {
  name: string,
  unit: string,
  rate: string,
  cost_code: string,
}

interface ImportEquipmentProps {
  onClose: () => void
}

const displayColumns: GridColDef[] = [
  {
    align: "left",
    headerAlign: "left",
    field: "name",
    headerName: "Description",
    flex: 1,
    minWidth: 180,
    renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "cost_code",
    headerName: "Cost Code",
    width: 100,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "Rate",
    width: 100,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "unit",
    headerName: "Unit",
    width: 100,
    editable: false,
  },
]

const translateComponents: JSX.Element[] = [
  <a
    style={{
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      textDecoration: "underline",
      // @ts-ignore
      "&:hover": {
        cursor: "pointer",
      },
    }}
    href={templateUrls.equipmentTypes}
    download={true}
  />
]

const importUtils: CsvImportUtils<EquipmentForUpload> = {
  convertCsvStringToObjectArray: convertCsvStringToEquipArray
}

const displayUtils: DisplayImportedObjectsUtils<EquipmentForUpload> = {
  columns: displayColumns,
  addIdToObject: (m, i) => ({...m, id: `${m.name}||${m.rate}||${i}`}),
  titleTranslateKey: 'view.Settings.ImportEquipDialog.uploadedEquipmentTitle',
}

const instructionUtils: ImportInstructionsUtils = {
  translateKey: "view.Settings.ImportEquipDialog.instructions",
  translateComponents,
}

export default function ImportEquipment({onClose}: ImportEquipmentProps): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const submitImportedEquipment = async (equipmentForUpload: EquipmentForUpload[]) => {
    if (equipmentForUpload.length > 0) {
      try {
        const res = await createMultipleEquipmentTypes(equipmentForUpload)
        if (res.status === 200 && Array.isArray(res.data)) {
          dispatch(addMultipleEquipmentTypes(res.data))
          enqueueSnackbar(t('view.Settings.ImportEquipDialog.submitSuccess'), {variant: 'success'})
        } else {
          enqueueSnackbar(t('view.Settings.ImportEquipDialog.submitFailed'), {variant: 'error'})
        }
      } catch (e: any) {
        enqueueSnackbar(t('view.Settings.ImportEquipDialog.submitFailed'), {variant: 'error'})
      }
      onClose()
    }
  }

  return (
    <CsvImport<EquipmentForUpload>
      onClose={onClose}
      importUtils={importUtils}
      displayUtils={displayUtils}
      instructionsUtils={instructionUtils}
      submit={submitImportedEquipment}
      submitButtonTextTranslateKey={"view.Settings.ImportEquipDialog.submitButton"}
    />
  )
}
