import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useTranslation } from "react-i18next"

import SiteHeader from "../TopNav"
// @ts-ignore
import SiteHeaderSpacer from "../../../components/Layout/SiteHeaderSpacer"

const useStyles = makeStyles(() => {
  return {
    root: {
      display: "flex",
    },
    contentWrap: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      overflow: "hidden",
    },
  }
})

interface IntegrationsLayoutProps {
  children: any
}

export default function IntegrationsLayout(props: IntegrationsLayoutProps) {
  const { children } = props
  const classes = useStyles()
  const { t } = useTranslation("private")

  return (
    <div className={classes.root}>
      <SiteHeader simple bottomTitle={t("view.Integrations.topNavTitle")} />
      <div className={classes.contentWrap}>
        <SiteHeaderSpacer />
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  )
}
