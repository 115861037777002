import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { convertToNumber } from "../../../../../../util/number"
import laborColumns from "./LaborBreakdown.columns"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import {TicketMarkup} from "../../../../../../types/ticket"
import LaborBreakdownTable from "./BreakdownTables/LaborBreakdownTable"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"
import { getTicketLaborBreakdown } from "../../../../../../api/ticket"

export interface LaborTotals {
  crew_size: number, hours: number, total?: number
}

export function calculateLaborTotals(
  rows: any[], addRates: boolean
): LaborTotals {
  const laborTotals: LaborTotals = {
    crew_size: rows.reduce(
      (total, { crew_size: current }) => total + parseFloat(current),
      0
    ),
    hours: rows.reduce(
      (total, { hours_per_person, crew_size }) =>
        total + (convertToNumber(hours_per_person) ?? 0) * (convertToNumber(crew_size) ?? 0),
      0
    ),
  }
  if (addRates) {
    laborTotals.total = rows.reduce(
      (total, { rate, hours_per_person, crew_size }) => {
        return total + (
          (convertToNumber(rate) ?? 0)
          * (convertToNumber(hours_per_person) ?? 0)
          * (convertToNumber(crew_size) ?? 0)
        )
      },
      0
    )
  }
  return laborTotals
}

interface LaborBreakdownProps {
  ticket_id: string,
  project_id: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateLaborSubtotal: (ls: number) => void
}

export default function LaborBreakdown(props: LaborBreakdownProps) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<LaborTotals>({crew_size: 0, hours: 0})
  const [rows, setRows] = useState([])

  // Get labor data
  useEffect(() => {
    if (!!props.ticket_id && !!props.project_id) {
      // get sender and receiver for this ticket
      getTicketLaborBreakdown(props.project_id, props.ticket_id).then(
        (res: any) => {
          if (res.data.length > 0) {
            setRows(res.data)
          }
        } // TODO BETTER ERROR HANDLING
      )
    }
  }, [props.ticket_id, props.project_id])

  // Calculate table totals
  useEffect(() => {
    const laborTotals = calculateLaborTotals(rows, props.addRates)
    if (props.addRates && laborTotals.total != null) {
      props.updateLaborSubtotal(calculateTotalWithMarkups(laborTotals.total, props.markupRows))
    }
    setTotals(laborTotals)
  }, [props.addRates, rows, props.markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.ChangeOrder.Labor.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <LaborBreakdownTable
          columns={laborColumns({addRates: props.addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.TicketBreakdowns.labor_subtotal")}
        />
      </Grid>
      {props.addRates
        ? <InvoiceMarkup
          rows={props.markupRows}
          total={totals.total ?? 0}
          subTotalText={t("view.TicketBreakdowns.labor_markup")}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
