import React from "react"
import {Link} from "react-router-dom"

import Fab from "./Fab"


export interface FabLinkProps {
  url: string,
  buttonText: string,
  LeadingIconComponent?: any
}

export default function FabLink(props: FabLinkProps) {
  const {url, buttonText, LeadingIconComponent} = props

  return (
    <Fab component={Link} to={url} variant="extended">
      {LeadingIconComponent ?? null}
      {buttonText}
    </Fab>
  )
}
