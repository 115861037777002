import React from "react"
import { Formik, Form } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Prompt } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment" // possibe cause of cancel issue

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import api from "../libs/api"
// @ts-ignore
import { addEquipment, updateEquipment } from "../store/features/equipmentSlice"
import {createEquipmentType} from "../api/equipment"

interface InitialValues {
  cost_code: string,
  name: string,
  rate: string | number,
  unit: string,
}

const defaultInitialValues = (): InitialValues => {
  return {
    cost_code: "",
    name: "",
    rate: "",
    unit: "",
  }
}

const updateOnSubmit = (values: any, id: string): Promise<any> => {
  return api({
    method: "patch",
    url: `/equipment/${id}`,
    data: values,
  })
}

interface CreateEditEquipmentProps {
  onCancel: any,
  initialValues?: InitialValues,
  updateObjectId?: string,
  update?: boolean,
  submitButtonText?: string,
}

export default function CreateEditEquipment(props: CreateEditEquipmentProps) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  // ensure that update data is OK
  if (
    // want to update but there's not enough information
    (props.update && (props.updateObjectId == null || props.initialValues == null))
    // or they don't want to update and we are passing in weird stuff
    || (!props.update && (props.updateObjectId != null || props.initialValues != null))
  ) {
    enqueueSnackbar('There was a problem attempting to create or edit the material.')
    props.onCancel(false)
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={props.initialValues ?? defaultInitialValues()}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t("form.message.nameRequired")),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        if(values.rate === "") values.rate = "0"
        if (props.update && props.updateObjectId != null) {
          updateOnSubmit(values, props.updateObjectId).then(async (response) => {
            if (response.data.id) {
              dispatch(updateEquipment(response.data))
              resetForm()
              enqueueSnackbar("Equipment Updated", {
                variant: "success",
              })
              props.onCancel(false)
            } else {
              enqueueSnackbar("There was a problem updating the equipment", {
                variant: "error",
              })
            }
          })
            .catch((error) => {
              console.log("error", error)
              enqueueSnackbar("There was a problem updating the equipment", {
                variant: "error",
              })
            })
            .finally(() => {
              setSubmitting(false)
            })
        } else {
          createEquipmentType(values).then(async (response) => {
            if (response.data.id) {
              dispatch(addEquipment(response.data))
              resetForm()
              enqueueSnackbar("Equipment Created", {
                variant: "success",
              })
              props.onCancel(false)
            } else {
              enqueueSnackbar("There was a problem creating the equipment", {
                variant: "error",
              })
            }
          })
            .catch((error) => {
              console.log("error", error)
              enqueueSnackbar("There was a problem creating the equipment", {
                variant: "error",
              })
            })
            .finally(() => {
              setSubmitting(false)
            })
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => {
        return (
          <Form>
            <Prompt
              when={Object.keys(touched).length > 0}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <FormSmallContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    id="name"
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={t("form.label.description")}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="cost_code"
                    error={Boolean(touched.cost_code && errors.cost_code)}
                    helperText={touched.cost_code && errors.cost_code}
                    label={t("form.label.cost_code")}
                    name="cost_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cost_code}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="rate"
                    type="number"
                    label={t("form.label.rate")}
                    name="rate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.rate}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="unit"
                    label={t("form.label.unit")}
                    name="unit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unit}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} paddingTop={3}>
                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button
                    onClick={() => {
                      props.onCancel(false)
                    }}
                    color="secondary"
                    variant="text"
                  >
                    {t("form.label.cancel")}
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {props.submitButtonText ?? t("form.label.add")}
                  </Button>
                </Grid>
              </Grid>
             
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
