import React from "react"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import { GridColDef } from "@mui/x-data-grid"
import {TFunction} from 'react-i18next'

//@ts-ignore
import DtRowTitle from "../../../../components/DtRowTitle"
import { formatDate, } from "../../../../libs/format"
import {CustomCode} from '../../../../types/customCode'


interface CodesListColumnsProps {
  editable: boolean,
  handleEdit: (cc: CustomCode) => void,
  t: TFunction
}

export default function CodesListColumns(
  {
    editable,
    handleEdit,
    t,
  }: CodesListColumnsProps
): GridColDef[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "code",
      headerName: t("view.Settings.CodesList.codeColumnHeader"),
      minWidth: 100,
      editable: false,
      renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t("view.Settings.CodesList.descriptionColumnHeader"),
      minWidth: 180,
      editable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_created",
      renderCell: (params: any) => formatDate(params.value),
      headerName: t("view.Settings.CodesList.addedColumnHeader"),
      width: 100,
      editable: false,
    },
    {
      align: "center",
      headerAlign: "right",
      field: "",
      headerName: " ",
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return editable ? (
          <IconButton
            color="secondary"
            onClick={() => {
              if (handleEdit != null) {
                handleEdit(params.row)
              }
            }}
          >
            <EditIcon />
          </IconButton>
        ) : <></>
      }
    },
  ]
}
