import {createSlice} from "@reduxjs/toolkit"

import {FinancingApr2024, initializeUserTestingStore, UserTestingStore} from '../../types/userTesting'
import {TracFloState} from '../../types/state'


const initialState = initializeUserTestingStore()

export const userTestingSlice = createSlice({
  name: "userTesting",
  initialState,
  reducers: {
    reset: () => initialState,
    setFinancingApr2024: (
      state: UserTestingStore,
      action: {payload: {interested_in_financing: boolean, datetime_try_again: Date, project_id: string}}
    ) => {
      const newFinancingApr2024Obj: FinancingApr2024 = {
        interested_in_financing: action.payload.interested_in_financing,
        datetime_try_again: action.payload.datetime_try_again,
      }
      if (state.financingApr2024 == null) {
        state.financingApr2024 = {}
      }
      state.financingApr2024[action.payload.project_id] = newFinancingApr2024Obj
    },
  },
})

export const {
  reset,
  setFinancingApr2024,
} = userTestingSlice.actions

export default userTestingSlice.reducer

export function getFinancingApr2024(state: TracFloState): {[key: string]: FinancingApr2024} | undefined {
  return state.userTesting.financingApr2024
}
