import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'

//@ts-ignore
import Container from "../../../../../../components/Container"
import { Estimate } from '../../../../../../types/estimate'
import { convertToNumber } from '../../../../../../util/number'
import EstimateTotalActualCostCards from './TotalActualCards'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {getCompanyType} from '../../../../../../store/features/companySlice'
import EstimateTotalSubmittedCostCards from './TotalSubmittedCards'


interface EstimateCostOverviewProps {
  estimate: Estimate,
}

export default function EstimateCostOverview(
  {estimate}: EstimateCostOverviewProps
): JSX.Element {
  const { t } = useTranslation("private")
  const companyType = useTracFloSelector(getCompanyType)
  const estimateTotalAmount = convertToNumber(estimate.total_amount) ?? 0
  const estimateTotalActualAmount = convertToNumber(
    companyType === 'trade'
      ? estimate.total_sub_actual_amount
      : estimate.total_actual_amount
  ) ?? 0
  const estimateTotalSubmittedAmount = convertToNumber(estimate.total_actual_amount) ?? 0

  return (
  <>
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h2'>
            {t("view.Estimates.SingleEstimate.Overview.title")}
          </Typography>
        </Grid>
      </Grid>

      <EstimateTotalActualCostCards
        estimateTotalAmount={estimateTotalAmount}
        estimateTotalActualAmount={estimateTotalActualAmount}
      />

      {
        companyType === 'trade'
          ? <EstimateTotalSubmittedCostCards
            estimateTotalAmount={estimateTotalAmount}
            estimateTotalSubmittedAmount={estimateTotalSubmittedAmount}
          />
          : <></>
      }
    </Container>
  </>
  )
}
