import {
  determineTicketType,
  EquipmentBreakdown,
  LaborBreakdown,
  MaterialBreakdown,
  Ticket,
  TicketMarkup
} from "../../../../../types/ticket"
import {CreateTicketInitialValues} from "../../../../../forms/CreateTicket.types"
import { Material } from "../../../../../types/material"
import { Equipment } from "../../../../../types/equipment"

function initializeMaterialBreakdown(material: MaterialBreakdown, materialTypes: Material[]): any {
  return {
    id: material.id,
    unit: material.unit,
    quantity: material.quantity,
    type_id: material.material_type_id,
    rate: material.rate ?? materialTypes.find((m) => m.id === material.material_type_id)?.rate
  }
}

function initializeEquipmentBreakdown(equipment: EquipmentBreakdown, equipmentTypes: Equipment[]): any {
  return {
    id: equipment.id,
    unit: equipment.unit,
    quantity: equipment.quantity,
    type_id: equipment.equipment_type_id,
    rate: equipment.rate ?? equipmentTypes.find((e) => e.id === equipment.equipment_type_id)?.rate
  }
}

function initializeLaborBreakdown(laborBreakdown: LaborBreakdown): any {
  return {
    id: laborBreakdown.id,
    hours: laborBreakdown.hours_per_person,
    quantity: laborBreakdown.crew_size,
    rate_type: laborBreakdown.rate_type,
    type_id: laborBreakdown.labor_type_id,
    date: laborBreakdown.date,
  }
}

function initializeMarkup(markup: TicketMarkup): any {
  return {
    id: markup.id,
    amount: markup.percent,
    title: markup.description,
    markup_type: markup.markup_type,
  }
}

export function initializeFormWithTicketData(
  ticket: Ticket,
  markup: TicketMarkup[],
  labor: LaborBreakdown[],
  material: MaterialBreakdown[],
  equipment: EquipmentBreakdown[],
  materialTypes: Material[],
  equipmentTypes: Equipment[],
): CreateTicketInitialValues {
  return {
    date_end: ticket.date_end,
    date_start: ticket.date_start,
    description: ticket.description,
    files: [{name: ""}],
    formMarkup: markup.filter((m) => m.markup_type === 'ticket').map(initializeMarkup),
    invoice_number: ticket.invoice_number ?? '',
    isAddCosts: ticket.includes_costs,
    isLumpSum: ticket.is_lump_sum,
    is_signed: '',
    laborBreakdown: labor.map((l) => initializeLaborBreakdown(l)),
    laborMarkup: markup.filter((m) => m.markup_type === 'labor').map(initializeMarkup),
    materialBreakdown: material.map((m) => initializeMaterialBreakdown(m, materialTypes)),
    materialMarkup: markup.filter((m) => m.markup_type === 'material').map(initializeMarkup),
    equipmentBreakdown: equipment.map((e) => initializeEquipmentBreakdown(e, equipmentTypes)),
    equipmentMarkup: markup.filter((m) => m.markup_type === 'equipment').map(initializeMarkup),
    lumpSumTotal: ticket.lump_sum_total ?? '',
    manual_total: ticket.lump_sum_total ?? '',
    notes: ticket.notes,
    number: ticket.number,
    pco_number: ticket.pco_number,
    subject: ticket.subject,
    type: determineTicketType(ticket.includes_costs, ticket.is_lump_sum),
    date_invoice: ticket.date_invoice
  }
}
