import React from "react"
import {Link} from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import {useTranslation} from "react-i18next"

import Fab from "../../../../../../components/Fab"
import {CompanyType} from "../../../../../../types/company"
import {ChangeOrderActionCode} from "../../../../../../types/action"

interface ChangeOrderEditButtonProps {
  projectId?: string,
  coId?: string,
  companyType?: CompanyType,
  changeOrderActionCode?: ChangeOrderActionCode,
  changeOrderLicenseIsActive: boolean,
}

export default function ChangeOrderEditButton(props: ChangeOrderEditButtonProps) {
  const showEditButtonForActionCodes: ChangeOrderActionCode[] = ['draft', 'revise']
  const showEditButton = props.projectId && props.coId
    && props.companyType === 'trade'
    && props.changeOrderActionCode
    && showEditButtonForActionCodes.includes(props.changeOrderActionCode)
    && props.changeOrderLicenseIsActive
  const { t } = useTranslation("private")

  return showEditButton
    ? (
      <Fab
        color="secondary"
        // @ts-ignore
        component={Link}
        edge="end"
        size="medium"
        to={`/0/project/${props.projectId}/changeorders/${props.coId}/edit`}
        variant="extended"
      >
        <EditIcon />
        {t("view.ChangeOrder.edit")}
      </Fab>
    ) : <></>
}
