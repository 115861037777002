import {
  attachTicketInvoice,
  notifyForCostReview,
  notifyForTmReview,
  submitTicketForCostReview,
  submitTicketForTmReview,
  uploadTicketReviewProcessFiles
} from "../../../../../../../api/ticketReviewProcess"
import {ReviewType, TicketSubmitFormValues} from "./TicketSubmitForm"

export function submitTicketForReview(
  projectId: string,
  ticketId: string,
  message: string,
  reviewType: ReviewType
): Promise<any> | null {
  try {
    if (reviewType === 'tm') return submitTicketForTmReview(projectId, ticketId, message)
    if (reviewType === 'cost') return submitTicketForCostReview(projectId, ticketId, message)
    return null
  } catch (_) {
    // TODO need better error handling
    return null
  }
}

export async function sendEmailNotification(
  trp_id: string,
  project_id: string,
  pmIds: string[],
  superIds: string[],
  userId: string | null,
  reviewType: ReviewType
): Promise<any | null> {
  const ids = (Array.isArray(pmIds) ? pmIds : []).concat(
    Array.isArray(superIds) ? superIds : []
  ).concat(
    userId !== null ? [userId] : []
  )
  if (reviewType === 'tm') return notifyForTmReview(project_id, trp_id, ids)
  if (reviewType === 'cost') return notifyForCostReview(project_id, trp_id, ids)
  return null
}

export interface SubmitForReviewProps {
  values: TicketSubmitFormValues,
  userId: string,
  projectId: string,
  ticketId: string,
  enqSnackbar: any,
  closeSnackbar: any,
  ticketActionChange: (actionId: number) => void
}

export async function attachTicketInvoiceForSubmit(
  trpId: string,
  projectId: string,
  enqSnackbar: any,
  closeSnackbar: any,
): Promise<void> {
  const uploadingKey = enqSnackbar('Uploading invoice...', {variant: 'info'})
  try {
    await attachTicketInvoice(trpId, projectId)
    closeSnackbar(uploadingKey)
  } catch (_) {
    closeSnackbar(uploadingKey)
    enqSnackbar('There was an issue uploading the ticket invoice', {variant: 'error'})
  }
}

export async function submitForReview(props: SubmitForReviewProps): Promise<void> {
  const {
    values,
    userId,
    projectId,
    ticketId,
    enqSnackbar,
    closeSnackbar,
    ticketActionChange
  } = props
  // Submit the ticket for review NOTE: THIS SPLITS BY TM VS COST
  const res = await submitTicketForReview(
    projectId,
    ticketId,
    values.notes,
    values.review_type
  )
  if (res == null || !res?.data?.id) {
    enqSnackbar(
      'Sorry, there was an issue submitting your request. Please try again later.',
      {variant: 'error'}
    )
    return
  }
  // Upload any attached files
  const filesForUpload = values.files
  if (filesForUpload.length > 0 && !!filesForUpload[0].name) {
    const uploadingKey = enqSnackbar(
      'Uploading files...',
      {variant: 'info', style: {whiteSpace: 'pre-wrap'}}
    )
    const fileUploadMessage = await uploadTicketReviewProcessFiles(
      filesForUpload,
      projectId,
      res.data.id
    )
    closeSnackbar(uploadingKey)
    if (fileUploadMessage.error) {
      enqSnackbar(fileUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      return
    }
  }
  // then attach ticket invoice
  await attachTicketInvoiceForSubmit(res.data.id, projectId, enqSnackbar, closeSnackbar)
  // Then send emails NOTE: THIS SPLITS BY TM VS COST
  const emailsRes = await sendEmailNotification(
    res.data.id,
    projectId,
    values.pmClients,
    values.superClients,
    values.sendMeCopy ? userId : null,
    values.review_type
  )
  if (emailsRes.status === 200) {
    // Clean up
    // Set the state of Ticket Action to New Action
    if (res?.data?.new_action_id) {
      ticketActionChange(res.data.new_action_id)
    }
    enqSnackbar(
      'Review request was submitted successfully',
      {variant: 'success'}
    )
  } else {
    enqSnackbar(
      'There was an issue sending the email notification. Please try again later.',
      {variant: 'error'}
    )
  }
}
