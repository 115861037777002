import React from "react"
import { Formik, Form } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Prompt } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import {AxiosResponse} from 'axios'

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import api from "../libs/api"
import { updateProject } from "../store/features/userSlice"
import { setProject } from "../store/features/projectSlice"
import { useTracFloSelector } from "../store/useTracFloSelector"
import {formatDate} from '../libs/format'
import {DateField} from './components/DateField'


interface UpdateProjectFormValues {
  address: string,
  name: string,
  number: string,
  disclaimer: string,
  date_start: string
}

interface UpdateProjectProps {
  onCancel: () => void,
}

export default function UpdateProject({ onCancel }: UpdateProjectProps) {
  const dispatch = useDispatch()
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  const formValuesSameAsOriginal = (values: UpdateProjectFormValues) => {
    const formFields = ['address', 'name', 'number', 'disclaimer', 'date_start']
    for (let formField of formFields) {
      // @ts-ignore
      if (values[formField] !== project[formField]) {
        return false
      }
    }
    return true
  }

  return (
    <Formik<UpdateProjectFormValues>
      validateOnChange={false}
      initialValues={{
        address: project.address ? project.address : "N/A",
        date_start: project.date_start,
        name: project.name ? project.name : "N/A",
        number: project.number ? project.number : "N/A",
        disclaimer: project.disclaimer ? project.disclaimer : ''
      }}
      validationSchema={Yup.object().shape({
        date_start: Yup.string().required(t("form.message.dateStartRequired")),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        // Set up the values for submission
        const submitValues = values

        // Format dates
        const startDate = new Date(values.date_start)
        submitValues.date_start = startDate.toISOString()

        api({
          method: "patch",
          url: `/project/${project.id}`,
          headers: { company_id: company.id },
          data: submitValues,
        })
          .then(async (response: AxiosResponse) => {
            if (response.data.id) {
              dispatch(updateProject(response.data))
              resetForm()
              enqueueSnackbar("Project Updated", {
                variant: "success",
              })
              dispatch(setProject(response.data))
              onCancel()
            } else {
              enqueueSnackbar("There was a problem updating the project, code: 104", {
                variant: "error",
              })
            }
          })
          .catch(() => {
            enqueueSnackbar("There was a problem updating the project, code: 102", {
              variant: "error",
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        isValid,
        touched,
        values,
        setFieldValue
      }) => {
        return (
          <Form>
            <Prompt
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <FormSmallContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus name="name"
                    label="Project Name"
                    defaultValue={project.name ? project.name : "N/A"}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus name="number"
                    label="Project Number"
                    defaultValue={project.number ? project.number : "N/A"}
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.number}
                  />
                </Grid>
                <DateField
                  width={'full'}
                  label={'Start Date'}
                  value={values.date_start}
                  onChange={(date) => {
                    setFieldValue("date_start", date)
                  }}
                />
                <Grid item xs={12}>
                  <TextAreaField
                    name="address"
                    label="Project Address"
                    defaultValue={project.address  ? project.address : "N/A"}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextAreaField
                    name="disclaimer"
                    label="Time & Materials Disclaimer"
                    defaultValue={project.disclaimer ? project.disclaimer : ""}
                    error={Boolean(touched.disclaimer && errors.disclaimer)}
                    helperText={
                      project.date_disclaimer_modified == null
                        ? 'Default disclaimer'
                        : `Last updated: ${formatDate(project.date_disclaimer_modified)}`
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.disclaimer}
                  />
                </Grid>
                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button onClick={onCancel} color="secondary" variant="text">
                      Cancel
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    disabled={! isValid || formValuesSameAsOriginal(values)}
                    isLoading={isSubmitting}
                    type="submit"
                    endIcon={<AddIcon />}
                    >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </FormSmallContainer>
          </Form>
          )
        }}
      </Formik>
  )
}
