import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
// @ts-ignore
import Container from "../../../../../../../components/Container"
import {
  notifyForCostApprove,
  submitCostApprove,
  uploadTicketReviewProcessFiles,
  uploadTicketReviewProcessSignature
} from "../../../../../../../api/ticketReviewProcess"
import SignatureAndTextFieldForm, {
  SignatureAndTextFieldFormValues
} from "../../../../../../../forms/SignatureAndTextFieldForm"
import {hasFilesForUpload} from "../../../../../../../util/file"


interface CostApproveFormProps {
  toggleFormOpen: () => void,
  ticketId: string,
  projectId: string,
  ticketActionChange: (new_action_id: number) => void
}

export default function CostApproveForm(props: CostApproveFormProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitForm = async (values: SignatureAndTextFieldFormValues): Promise<void> => {
    try {
      // update the ticket status
      const {data: trp} = await submitCostApprove(
        props.ticketId,
        props.projectId,
        values.message.length === 0 ? null : values.message
      )
      // upload files
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
        const fileUploadMessage = await uploadTicketReviewProcessFiles(values.files, props.projectId, trp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      // upload signature
      const signatureUploadMessage = await uploadTicketReviewProcessSignature(values.signature, props.projectId, trp.id)
      if (signatureUploadMessage.error) {
        enqueueSnackbar(signatureUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      }
      // notify of the approval and create history object
      await notifyForCostApprove(trp.id, props.projectId)
      props.toggleFormOpen()
      if (trp?.new_action_id) {
        props.ticketActionChange(trp.new_action_id)
      }
      enqueueSnackbar(t('view.Tickets.cost_approve_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.cost_approve_fail'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <SignatureAndTextFieldForm
        submitValues={submitForm}
        formCancel={props.toggleFormOpen}
        titleText={t('view.Tickets.cost_approve_title')}
      />
    </FormSmallContainer>
  )
}
