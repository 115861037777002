import React, {useEffect, useMemo} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

// @ts-ignore
import MarkupField from "../components/Fields/Markup"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
import BreakdownTable from "../components/BreakdownTable"
import Fab from "../components/Fab"
import AddLaborForm from "./AddLaborToBreakdown"
import {
  calculateLaborTotals,
  updateLaborBreakdownWithLaborType,
} from "../components/Fields/Breakdown.processData"
import {useTracFloSelector} from '../store/useTracFloSelector'
import {TicketType} from '../types/ticket'
import {AddDailyLaborBreakdownInitialValues} from './CreateDailyBreakdown.type'
import {getLaborTypesForCreateTicketForm} from '../api/labor'
import {Labor} from '../types/labor'
import CreateDailyLaborBreakdownTableColumns from './CreateDailyLaborBreakdownField.columns'


const laborBreakdownFieldName = 'laborBreakdown'

function handleDeleteRow(
  setFieldValue: (f: string, value: any) => void,
  value: AddDailyLaborBreakdownInitialValues[]
): (index: number) => void {
  return (rowIndex: number) => {
    setFieldValue(
      laborBreakdownFieldName,
      value.filter((row, index) => index !== (rowIndex - 1))
    )
  }
}

function handleFieldChanges(
  setFieldValue: (f: string, value: any) => void,
  value: AddDailyLaborBreakdownInitialValues[],
): (index: number, propNamesAndValues: any[]) => void {
  return (index, propNamesAndValues) => {
    for (const {fieldPropName, newValue} of propNamesAndValues) {
      // @ts-ignore
      value[index][fieldPropName] = newValue
    }
    setFieldValue(
      laborBreakdownFieldName,
      [...value]
    )
  }
}

type CreateDailyLaborBreakdownFieldProps = {
  setFieldValue: (f: string, value: any) => void,
  formType: TicketType,
  value: AddDailyLaborBreakdownInitialValues[],
  forCostEdit: boolean,
  noTypesToAddMessage: string,
  markupValue: any[],
  date: string,
}

export default function CreateDailyLaborBreakdownField(
  {
    formType,
    markupValue,
    setFieldValue,
    value,
    noTypesToAddMessage, /* if this is omitted, user is allowed to add breakdown even if no types exist*/
    forCostEdit,
    date,
  }: CreateDailyLaborBreakdownFieldProps
) {
  const { t } = useTranslation("private")
  const project = useTracFloSelector((state) => state.project)
  const [columns, setColumns] = React.useState<any[]>([])
  const [dropdownItems, setDropdownItems] = React.useState<Labor[]>([])
  const [formActive, setFormActive] = React.useState<boolean>(false)
  const [totals, setTotals] = React.useState<any>({})
  const addRates = formType === "sum_rates"
  const canOnlyAddWithExistingTypes = !!noTypesToAddMessage


  useEffect(() => {
    getLaborTypesForCreateTicketForm(project.id)
      .then(({data}) => {
        setDropdownItems(data)
      })
  }, [project.id])

  useEffect(() => {
    if (dropdownItems.length > 0) {
      // Need to update breakdowns with type information when they come in
      updateLaborBreakdownWithLaborType(value, dropdownItems, addRates)
      setTotals(calculateLaborTotals(value, addRates))
      setFieldValue(laborBreakdownFieldName, [...value])
    }
  }, [dropdownItems, addRates, setFieldValue])

  useEffect(() => {
    const valueArray = Object.values(value)
    setColumns(CreateDailyLaborBreakdownTableColumns({
      addRates,
      handleDeleteRow: handleDeleteRow(setFieldValue, value),
      editable: true,
      handleFieldChanges: handleFieldChanges(setFieldValue, value),
      forCostEdit,
      t
    }))
    setTotals(calculateLaborTotals(valueArray, addRates))
  }, [addRates, setFieldValue, value, forCostEdit, t])

  const dailyDates = useMemo(() => {
    return {
      date_start: date,
      date_end: null
    }
  }, [date])


  return value.length > 0 || !forCostEdit ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.Daily.SingleDaily.Breakdowns.Labor.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        {dropdownItems.length === 0 && canOnlyAddWithExistingTypes
          ? <Typography variant="body1">{noTypesToAddMessage}</Typography>
          // editable adjusts last column for delete icon, we don't show this icon if cost edit
          : <BreakdownTable columns={columns} editable={!forCostEdit} rows={value} totals={totals} />
        }
      </Grid>

      {/* Cannot add if cost edit */}
      {!forCostEdit
        ? (
          <Grid item xs={12} container justifyContent="center">
            {!formActive ? (
              <Fab
                variant="extended"
                disabled={dropdownItems.length === 0 && canOnlyAddWithExistingTypes}
                onClick={() => setFormActive(!formActive)}
              >
                <AddIcon />
                {t("form.label.add_new")}
              </Fab>
            ) : (
              <FormSmallContainer>
                <AddLaborForm
                  addRates={addRates}
                  data={value}
                  dropdownItems={dropdownItems}
                  setData={setFieldValue}
                  setFormActive={setFormActive}
                  ticketDates={dailyDates}
                  hideDateField={true}
                />
              </FormSmallContainer>
            )}
          </Grid>
        ) : (<></>)
      }

      {value.length && addRates ? (
        <MarkupField
          name={'labor'}
          setData={setFieldValue}
          total={totals.total || 0}
          value={markupValue}
        />
      ) : (
        <></>
      )}
    </Grid>
  ) : (<></>)
}
