import React from 'react'
import {useTranslation} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// @ts-ignore
import Container from "../components/Container"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
import {deleteAtIndex} from '../util/array'
import EstimateMarkupTable, {EstimateMarkupForTable} from '../components/EstimateMarkupTable'
import {handleFieldChange} from '../util/form'
import CreateEstimateMarkupForm from './CreateEstimateMarkup.form'


interface CreateEstimateMarkupProps {
  subtotal: number,
  setFieldValues: (field: string, value: any,) => void,
  markup: EstimateMarkupForTable[],
  setMarkup: (s: EstimateMarkupForTable[]) => void,
}

export default function CreateEstimateMarkup(
  {markup, setMarkup, subtotal, setFieldValues}: CreateEstimateMarkupProps
): JSX.Element {
  const {t} = useTranslation('private')

  const deleteMarkup = (atIndex: number) => {
    const atZeroIndex = atIndex - 1
    if (markup.length > atZeroIndex && atZeroIndex >= 0) {
      setMarkup(deleteAtIndex(markup, atZeroIndex))
    }
  }

  const addMarkup = (newMarkup: {description: string, percent: string}) => {
    // @ts-ignore
    setMarkup([...markup, newMarkup])
  }

  return (
    <Container removeTop>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t('form.CreateEstimateMarkup.title')}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <EstimateMarkupTable
              editable={true}
              markup={markup}
              subtotal={subtotal}
              handleDeleteRow={deleteMarkup}
              handleFieldChange={handleFieldChange(setFieldValues, markup, 'markup')}
            />
            <Grid item xs={12} container justifyContent="flex-end">
              <Typography variant="h4" style={{ margin: "20px 0 10px" }}>
                {t("form.CreateEstimateMarkup.addMarkup")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CreateEstimateMarkupForm submitValues={addMarkup}/>
            </Grid>
          </Grid>
        </Grid>
      </FormWideContainer>
    </Container>
  )
}
