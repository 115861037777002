import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { ChangeOrder, ChangeOrdersStore } from "../../types/changeOrder"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"

const initialState: ChangeOrdersStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadChangeOrders = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadChangeOrders",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        company_id: company.id,
      },
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/change_order`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const refreshChangeOrder = createAsyncThunk<any, {changeOrderId: string}, {state: TracFloState}>(
  "user/refreshChangeOrder",
  async ({ changeOrderId }, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "get",
      timeout: 20000,
      // url: `${company.url}${config.api.path}/project/${project.id}/changeorder/${changeOrderId}`,
      url: `${config.api.url}/${company.id}/project/${project.id}/changeorder/${changeOrderId}`,
    })
    if (response.status === 200 && response.data) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const changeOrdersSlice = createSlice({
  name: "changeOrders",
  initialState,
  reducers: {
    addChangeOrder: (state, action) => {
      state.items.unshift(action.payload)
    },
    deleteChangeOrder: (state, action) => {
      const indexOfDeletedChangeOrder = state.items.findIndex((item) => item.id === action.payload)
      if(indexOfDeletedChangeOrder > -1) {
        state.items.splice(indexOfDeletedChangeOrder, 1)
      }
    },
    resetChangeOrders: () => initialState,
    updateChangeOrder: (state, action) => {
      if (action.payload) {
        const updatedChangeOrder = action.payload
        updatedChangeOrder.date_modified = (new Date()).toISOString()
        state.items = [updatedChangeOrder, ...state.items.filter((co) => co.id !== updatedChangeOrder.id)]
      }
    },
    updateChangeOrderAction: (state, action: {payload: {new_action_id: number, co_id: string}}) => {
      const updatedChangeOrder = state.items.find((co) => co.id === action.payload.co_id)
      if (updatedChangeOrder != null) {
        updatedChangeOrder.change_order_action_id = action.payload.new_action_id
        updatedChangeOrder.date_modified = (new Date()).toISOString()
        // remove the change order
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.co_id),
          1
        )
        // add it back in at the front
        state.items = [updatedChangeOrder, ...state.items]
      }
    },
    updateChangeOrderEstimate: (
      state,
      action: {payload: {new_estimate_id: string | null, new_pco_number: string, co_id: string}}
    ) => {
      const updatedChangeOrder = state.items.find((co) => co.id === action.payload.co_id)
      if (updatedChangeOrder != null) {
        updatedChangeOrder.estimate_id = action.payload.new_estimate_id
        updatedChangeOrder.pco_number = action.payload.new_pco_number
        updatedChangeOrder.date_modified = (new Date()).toISOString()
        // remove the change order
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.co_id),
          1
        )
        // add it back in at the front
        state.items = [updatedChangeOrder, ...state.items]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadChangeOrders.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadChangeOrders.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadChangeOrders.fulfilled, (state, action: {payload: {data: ChangeOrder[], projectId: string}}) => {
        if (action.payload && action.payload.data) {
          const changeorders = action.payload.data.filter((row) => row.id)
          state.items = changeorders
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
      .addCase(refreshChangeOrder.fulfilled, (state, action) => {
        if (action.payload && action.payload.id) {
          const newChangeOrder = action.payload
          let updated = false
          if (state.items.length) {
            state.items = state.items.map((item) => {
              if (item && item.id === newChangeOrder.id) {
                updated = true
                return newChangeOrder
              } else {
                return item
              }
              // return item.id === newChangeOrder.id ? newChangeOrder : item
            })
          }
          if (!updated) {
            state.items.unshift(newChangeOrder)
          }
        }
      })
  },
})

export const {
  addChangeOrder,
  deleteChangeOrder,
  resetChangeOrders,
  updateChangeOrder,
  updateChangeOrderAction,
  updateChangeOrderEstimate,
} = changeOrdersSlice.actions

export const listChangeOrders = (state: TracFloState) => state.changeOrders.items

export const getChangeOrder = (changeOrderId: string) => (state: TracFloState) =>
  state.changeOrders.items.find((item) => item && item.id === changeOrderId)

export const reloadChangeOrdersIfInvalid = (
  projectId: string,
  changeOrdersProjectId: string | null,
  changeOrderExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== changeOrdersProjectId || reduxStateExpired(changeOrderExpiry)) {
    dispatch(loadChangeOrders())
  }
}

export default changeOrdersSlice.reducer
