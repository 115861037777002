import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"

export async function createEquipmentType(equipmentType: any): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: "/equipment",
    data: equipmentType,
  })
}

export async function createMultipleEquipmentTypes(equipmentTypes: any[]): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: "/equipments",
    data: equipmentTypes,
  })
}
