import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"
import {apiHeaders} from '../../api/util'
import {compareAlphabetically} from '../../util/string'
import {CustomCode, CustomCodeStore} from '../../types/customCode'

const initialState: CustomCodeStore = {
  items: [],
  companyId: null,
  exp: "",
  status: "idle",
}

export const loadCustomCode = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadCustomCode",
  async (_, thunkAPI) => {
    const { company, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/code`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, companyId: company.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const customCodeSlice = createSlice({
  name: "customCode",
  initialState,
  reducers: {
    addCustomCode: (state, action: {payload: CustomCode}) => {
      state.items = [action.payload, ...state.items]
        .sort((m1, m2) =>
          compareAlphabetically(m1.code, m2.code)
        )
    },
    resetCustomCode: () => initialState,
    updateCustomCode: (state, action: {payload: CustomCode}) => {
      if (action.payload) {
        const updatedMetric = action.payload
        state.items = [updatedMetric, ...state.items.filter((t) => t.id !== updatedMetric.id)]
          .sort((m1, m2) =>
            compareAlphabetically(m1.code, m2.code)
          )
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomCode.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadCustomCode.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadCustomCode.fulfilled, (
        state,
        action: {payload: {data: CustomCode[], companyId: string}}
      ) => {
        if (action.payload && action.payload.data) {
          state.items =  action.payload.data.map((m) => {return m})
            .sort((m1, m2) =>
              compareAlphabetically(m1.code, m2.code)
            )
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.companyId = action.payload.companyId
        }
        state.status = "fulfilled"
      })
  },
})

export const { addCustomCode, updateCustomCode, resetCustomCode } = customCodeSlice.actions

export const getCustomCodes = (state: TracFloState) => state.customCode.items

export function getCompanyCustomCodes(ccs: CustomCode[]): CustomCode[] {
  return ccs.filter((cc) => cc.project_id == null)
}

export function getProjectCustomCodes(ccs: CustomCode[], projectId: string): CustomCode[] {
  return ccs.filter((cc) => cc.project_id === projectId || cc.project_id == null)
}

export const reloadCustomCodeIfInvalid = (
  companyId: string,
  ccCompanyId: string | null,
  ccExpiry: ExpirationField,
  dispatch: any,
) => {
  if (companyId !== ccCompanyId || reduxStateExpired(ccExpiry)) {
    dispatch(loadCustomCode())
  }
}

export default customCodeSlice.reducer
