import React from 'react'
import {Menu, MenuItem} from '@mui/material'
import {useTranslation} from 'react-i18next'

// @ts-ignore
import Button from '../../../../../../components/Button'


export interface ImportLaborButtonProps {
  showImportLaborFromProject: () => void,
  showImportLaborFromCsv: () => void,
}

export default function ImportLaborButton(
  {showImportLaborFromProject, showImportLaborFromCsv}: ImportLaborButtonProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // need to stop the accordion from closing
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleFromProjectClick = (event: any) => {
    handleClose(event)
    showImportLaborFromProject()
  }

  const handleFromCsvClick = (event: any) => {
    handleClose(event)
    showImportLaborFromCsv()
  }

  return (
    <>
      <Button
        color="secondary"
        variant="text"
        onClick={handleButtonClick}
        key={'importLaborButton'}
      >
        {t('view.ProjectSettings.LaborTypes.ImportLaborButton.text')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleFromProjectClick}>
          {t('view.ProjectSettings.LaborTypes.ImportLaborButton.fromProjectText')}
        </MenuItem>
        <MenuItem onClick={handleFromCsvClick}>
          {t('view.ProjectSettings.LaborTypes.ImportLaborButton.fromCsvText')}
        </MenuItem>
      </Menu>
    </>
  )
}
