import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"

export async function createMaterialType(materialType: any): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: "/material",
    data: materialType,
  })
}

export async function createMultipleMaterialTypes(materialTypes: any[]): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: "/materials",
    data: materialTypes,
  })
}
