import React from "react"
import { useTranslation } from "react-i18next"

//@ts-ignore
import Dialog from "../../../../components/Dialog"
import MaterialsEquipmentColumns from "../../../../libs/tableColumns/SettingsMaterialsEquipment"
import { getEquipmentTypes } from "../../../../store/features/equipmentSlice"
import { getMaterialTypes } from "../../../../store/features/materialSlice"
import { getCompany } from "../../../../store/features/companySlice"
import CreateEditEquipment from "../../../../forms/CreateEditEquipment"
import CreateEditMaterial from "../../../../forms/CreateEditMaterial"
import { CompanyUserRoleCode } from "../../../../types/userRoles"
import { convertToNumber } from "../../../../util/number"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import ImportMaterial from './ImportMaterial'
import DataGridAccordion from "../../../../components/DataGridAccordion"
import ImportEquipment from './ImportEquipment'


type MaterialsEquipmentDialogStates = 'updateMaterialForm' | "updateEquipmentForm" | "importMaterial" | "importEquip"

export default function MaterialsEquipment() {
  const { t } = useTranslation("private")
  const equipment = useTracFloSelector(getEquipmentTypes)
  const material = useTracFloSelector(getMaterialTypes)
  const company = useTracFloSelector(getCompany)
  const [dialog, setDialog] = React.useState<MaterialsEquipmentDialogStates | null>(null)
  const [selectedItem, setSelectedItem] = React.useState<any>({})

  const userCanEditRow = (companyUserRole: CompanyUserRoleCode) => {
    return companyUserRole !== "crew"
  }

  const equipmentRows = equipment.map((row: any) => ({
    ...row,
    editable: userCanEditRow(company.company_user_role),
  }))

  const materialsRows = material.map((row: any) => ({
    ...row,
    editable: userCanEditRow(company.company_user_role),
  }))

  return (
    <>
      <DataGridAccordion
        items={[
          {
            title: t("view.Settings.material_types"),
            columns: MaterialsEquipmentColumns({
              handleEdit: () => setDialog("updateMaterialForm"),
              handleSelection: setSelectedItem,
            }),
            rows: materialsRows,
            addForm: {
              submitText: "Add Material Type",
              openButtonText: "Material Type",
              title: "Add Material Type",
              form: CreateEditMaterial,
            },
            extraButtons: [
              {
                text: t("view.Settings.importMaterialButton"),
                onClick: (event: any) => {
                  // need to stop the accordion from closing
                  event.stopPropagation()
                  setDialog('importMaterial')
                },
              }
            ]
          },
          {
            title: t("view.Settings.equipment_types"),
            columns: MaterialsEquipmentColumns({
              handleEdit: () => setDialog("updateEquipmentForm"),
              handleSelection: setSelectedItem,
            }),
            rows: equipmentRows,
            addForm: {
              submitText: "Add Equipment Type",
              openButtonText: "Equipment Type",
              title: "Add Equipment Type",
              form: CreateEditEquipment,
            },
            extraButtons: [
              {
                text: t("view.Settings.importEquipButton"),
                onClick: (event: any) => {
                  // need to stop the accordion from closing
                  event.stopPropagation()
                  setDialog('importEquip')
                },
              }
            ]
          },
        ]}
      />
      <Dialog
        open={dialog === "updateMaterialForm"}
        onClose={() => setDialog(null)}
        title="Edit Material Type"
      >
        <CreateEditMaterial
          initialValues={{
            cost_code: selectedItem.cost_code ?? '',
            name: selectedItem.name ?? '',
            rate: convertToNumber(selectedItem.rate) !== 0 ? selectedItem.rate : '',
            unit: selectedItem.unit ?? '',
          }}
          update={true}
          updateObjectId={selectedItem.id}
          onCancel={() => setDialog(null)}
          submitButtonText={t("form.label.update")}
        />
      </Dialog>

      <Dialog
        open={dialog === "updateEquipmentForm"}
        onClose={() => setDialog(null)}
        title="Edit Equipment Type"
      >
        <CreateEditEquipment
          initialValues={{
            cost_code: selectedItem.cost_code ?? '',
            name: selectedItem.name ?? '',
            rate: convertToNumber(selectedItem.rate) !== 0 ? selectedItem.rate : '',
            unit: selectedItem.unit ?? '',
          }}
          update={true}
          updateObjectId={selectedItem.id}
          onCancel={() => setDialog(null)}
          submitButtonText={t("form.label.update")}
        />
      </Dialog>

      <Dialog
        open={dialog === "importMaterial"}
        onClose={() => setDialog(null)}
        title={t('view.Settings.importMaterialDialogTitle')}
        maxWidth={'lg'}
      >
        <ImportMaterial onClose={() => setDialog(null)}/>
      </Dialog>

      <Dialog
        open={dialog === "importEquip"}
        onClose={() => setDialog(null)}
        title={t('view.Settings.importEquipDialogTitle')}
        maxWidth={'lg'}
      >
        <ImportEquipment onClose={() => setDialog(null)}/>
      </Dialog>
    </>
  )
}
