import React, {useState} from "react"
import Grid from "@mui/material/Grid"

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../../../components/Pocket"
import { ShareDailyButton } from "./ReviewButtons/ShareButton"
import ShareDailyForm from "./ReviewForm/ShareDailyForm"


type FormName = "share" | null

interface DailyReviewButtonsProps {
  projectId: string,
  dailyId: string,
}

export default function DailyReviewButtons(
  {dailyId, projectId}: DailyReviewButtonsProps
): JSX.Element {
  const [showThisForm, setShowThisForm] = useState<FormName>(null)

  const toggleDailyReviewForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  return (
    <>
      {/* Review Process Buttons */}
      <Container removeTop>
        <Grid container xs={12} spacing={2}>
          <ShareDailyButton
            togglePocketForm={() => toggleDailyReviewForm('share')}
          />
        </Grid>
      </Container>

      {/* Review Process Forms */}
      <Pocket show={showThisForm === 'share'}>
        <ShareDailyForm
          projectId={projectId}
          toggleFormOpen={() => toggleDailyReviewForm("share")}
          dailyId={dailyId}
        />
      </Pocket>
    </>
  )
}

