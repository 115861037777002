import React, {useEffect, useState} from "react"
import {useSnackbar} from "notistack"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"

import {useTracFloSelector} from "../../../../../store/useTracFloSelector"
import {getIntegrationProjects, IntegrationProjectsForDisplay} from "../../../../../api/integration"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import {IntegrationProjectColumns} from "./ConnectToIntegration.columns"
import {addProjectIntegration} from "../../../../../api/project"
import {addProjectToIntegration} from "../../../../../store/features/userSlice"


interface ConnectToIntegrationProps {
  project: {id: string, name: string} | null,
  closeDialog: () => void,
}

export default function ConnectToIntegration({ project, closeDialog }: ConnectToIntegrationProps) {
  const activeIntegration = useTracFloSelector((s) => s.user.activeIntegration)
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const [integrationProjects, setIntegrationProjects] = useState<IntegrationProjectsForDisplay[]>([])

  useEffect(() => {
    if (project != null && activeIntegration != null) {
      getIntegrationProjects(activeIntegration.id).then((r) => {
        if (r.data?.projects?.length > 0) {
          // data table needs an id field
          const projects = r.data.projects.map((p) => ({...p, id: p.agave_id}))
          projects.sort(
            (a, b) => (new Date(a.start_date)).valueOf() <= (new Date(b.start_date)).valueOf() ? 1 : -1
          )
          setIntegrationProjects(projects)
        }
      })
    }
  }, [project, activeIntegration])

  const connectProject = async (integrationProjectId: string): Promise<void> => {
    if (project != null && activeIntegration != null) {
      try {
        const res = await addProjectIntegration(project.id, activeIntegration.id, integrationProjectId)
        if (res.data?.id) {
          enqueueSnackbar(t('view.Projects.ConnectToProjectDialog.connectSuccess'), {variant: 'success'})
          // add to global integration state
          dispatch(addProjectToIntegration({projectId: project.id, userIntegrationId: activeIntegration.id}))
          closeDialog()
        } else {
          enqueueSnackbar(t('view.Projects.ConnectToProjectDialog.connectError'), {variant: 'error'})
        }
      } catch(_) {
        enqueueSnackbar(t('view.Projects.ConnectToProjectDialog.connectError'), {variant: 'error'})
      }
    }
  }

  return (
    <DataTable
      checkboxSelection={false}
      columns={IntegrationProjectColumns(connectProject)}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      rows={integrationProjects}
    />
  )
}
