import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"
import { Estimate } from '../types/estimate'
import { HistoryItem } from '../types/history'
import { File } from '../types/file'
import {uploadFiles} from './file'
import { CompanySenderReceiver } from '../types/company'
import { InvoiceSignature } from '../types/signature'

export async function getEstimateById(project_id: string, estimate_id: string): Promise<AxiosResponse<Estimate>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}`})
}

export async function getEstimateHistory(project_id: string, estimate_id: string): Promise<AxiosResponse<HistoryItem[]>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}/history`})
}

export async function getEstimateFiles(project_id: string, estimate_id: string): Promise<AxiosResponse<File[]>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}/file_presigned`,})
}

export async function editEstimate(project_id: string, estimate_id: string, estimateData: any): Promise<AxiosResponse<Estimate>> {
  return api({
    method: "patch",
    url: `/project/${project_id}/estimate/${estimate_id}`,
    data: estimateData,
  })
}

export async function createEstimate(project_id: string, estimateData: any): Promise<AxiosResponse<Estimate>> {
  return api({
    method: "post",
    url: `/project/${project_id}/estimate`,
    data: estimateData,
  })
}

export async function uploadEstimateFiles(
  files: any[],
  project_id: string,
  estimate_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, estimate_id, 'estimate')
}

export interface EstimateLineItem {
  id: string,
  estimate_id: string,
  description: string,
  cost: string | number,
}

export async function getEstimateLineItems(
  project_id: string,
  estimate_id: string
): Promise<AxiosResponse<EstimateLineItem[]>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}/line_item`,})
}

export interface EstimateMarkup {
  id: string,
  estimate_id: string,
  description: string,
  percent: string | number,
}

export async function getEstimateMarkup(
  project_id: string,
  estimate_id: string
): Promise<AxiosResponse<EstimateMarkup[]>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}/markup`,})
}

export async function getEstimateSenderReceiver(
  project_id: string,
  estimate_id: string
): Promise<AxiosResponse<CompanySenderReceiver>> {
  return api({method: "get", url: `/project/${project_id}/estimate/${estimate_id}/sender_receiver_company_info`})
}

export async function getEstimateSignature(project_id: string, estimate_id: string): Promise<AxiosResponse<InvoiceSignature|null>> {
  return api.get(`/project/${project_id}/estimate/${estimate_id}/signature`)
}

export async function getNextEstimateNumber(project_id: string): Promise<AxiosResponse<{next_number: string}>> {
  return api({
    method: "get",
    url: `/project/${project_id}/next_estimate_number`,
  })
}

export type AttachableEstimate = {
  id: string,
  subject: string,
  description: string,
  number: string,
  pco_number: string | null,
}

export async function getAttachableEstimates(project_id: string): Promise<AxiosResponse<AttachableEstimate[]>> {
  return api({
    method: "get",
    url: `/project/${project_id}/attachable_estimates`,
  })
}

export async function downloadEstimateBackups(
  project_id: string,
  estimate_id: string
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/estimate/${estimate_id}/zip_backups`,
  })
}
