import React from "react"
import { Grid } from "@mui/material"

import DetailsTable from "../../../../../../components/DetailsTable"
import DetailRow from "../../../../../../components/DetailRow"


interface ChangeOrderInvoiceTopDetailsProps {
  summary: any,
  scope: string,
  exclusion: string | undefined,
}

export default function ChangeOrderInvoiceTopDetails(props: ChangeOrderInvoiceTopDetailsProps): any {
  return (
    <>
      <DetailsTable details={props.summary} />
      <Grid container style={{ marginBottom: 16 }}>
        <DetailRow title={"Scope of Work"} content={props.scope}/>
      </Grid>
      <Grid container style={{ marginBottom: 16 }}>
        <DetailRow title={"Exclusions"} content={props.exclusion}/>
      </Grid>
    </>
  )
}
