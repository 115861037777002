import {convertCsvStrToJsArray} from '../../../../../util/csv/read'
import {processRateFieldForImport} from '../../../../../util/csv/util'

export type ImportMaterialProperties = 'name' | 'rate' | 'unit' | 'cost_code'

export type ImportMaterialFields = 'Description' | 'Rate' | 'Unit' | 'Cost Code'
export const importMaterialFields: ImportMaterialFields[] = ['Description', 'Rate', 'Unit', 'Cost Code']

export const importMaterialFieldToPropertyMapping: {[key in ImportMaterialFields]: ImportMaterialProperties} = {
  'Description': 'name',
  'Rate': 'rate',
  'Unit': 'unit',
  'Cost Code': 'cost_code',
}

export const ImportMaterialFieldValidation: {[key in ImportMaterialFields]: (s: string) => string} = {
  'Description': (s) => {
    // name is required
    if (s.length === 0) throw new Error('view.Settings.ImportMaterialDialog.errorNameValidation')
    return s
  },
  'Rate': (s) => {
    return processRateFieldForImport(s, 'view.Settings.ImportMaterialDialog.errorRateValidation')
  },
  'Unit': (s) => {return s}, // no validation necessary
  'Cost Code': (s) => {return s}, // no validation necessary
}

export function convertCsvStringToMaterialArray(csvStr: string): {[key in ImportMaterialProperties]: string}[] {
  return convertCsvStrToJsArray<ImportMaterialFields, ImportMaterialProperties>(
    csvStr,
    importMaterialFields,
    ImportMaterialFieldValidation,
    importMaterialFieldToPropertyMapping,
  )
}
