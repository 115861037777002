import { AxiosResponse } from "axios"
// @ts-ignore
import api from "../libs/api"
import { ProjectCreatedInLastSixtyDays, ProjectWithLicense } from "../types/project"
import { ProjectUserRoleCode } from "../types/userRoles"

export type UserForAdmin = {
  id: string,
  first_name: string,
  last_name: string,
  email: string,
}

export async function getUsersForAdmin(): Promise<AxiosResponse<UserForAdmin[]>> {
  return api({method: 'get', url: `/user`})
}

export async function getCompaniesForAdmin(): Promise<any> {
  return api({method: 'get', url: `/admin/companies`})
}

export async function getProjectsCreatedInLastSixtyDays(): Promise<AxiosResponse<ProjectCreatedInLastSixtyDays[]>> {
  return api({method: 'get', url: `/admin/last_60_days_projects`})
}

export async function getSingleProjectInCompanyUsersForAdmin(company_id: string, project_id: string): Promise<AxiosResponse<any[]>> {
  return api({method: 'get', url: `/admin/company/${company_id}/project/${project_id}/user`})
}

export async function getProjectLicensesForAdmin(company_id: string): Promise<AxiosResponse<ProjectWithLicense[]>> {
  return api({method: 'get', url: `/admin/company/${company_id}/project_license`})
}

export async function getMonthlyCOsReportForAdmin()
: Promise<AxiosResponse<{month: string, change_orders_created: string, change_orders_created_amount: string}[]>> {
  return api({method: 'get', url: `/admin/monthly_co_report`})
}

export async function getMonthlyTicketsReportForAdmin()
: Promise<AxiosResponse<{month: string, tickets_created: string, tickets_created_amount: string}[]>> {
  return api({method: 'get', url: `/admin/monthly_ticket_report`})
}

export async function updateUserRole(
  company_id: string,
  project_id: string,
  user_id: string,
  project_user_role_code: ProjectUserRoleCode
): Promise<AxiosResponse<{
  id: string,
  first_name: string,
  last_name: string,
  project_user_role_code: ProjectUserRoleCode}>>
{
  return api({method: 'patch', url: `/admin/company/${company_id}/project/${project_id}/user/${user_id}`, data: {project_user_role_code}})
}

export async function updateProjectActiveStatus(project_license_id: number): Promise<AxiosResponse<ProjectWithLicense>> {
  return api({method: 'patch', url: `/admin/project_license/${project_license_id}/change_active_status`})
}

export async function updateChangeOrderActiveStatus(project_license_id: number, change_order_is_active: boolean): Promise<AxiosResponse<ProjectWithLicense>> {
  return api({method: 'patch', url: `/admin/project_license/${project_license_id}/feature_access`, data: {change_order_is_active}})
}

export async function updateCanAddSubsStatus(project_license_id: number, can_add_subs: boolean): Promise<AxiosResponse<ProjectWithLicense>> {
  return api({method: 'patch', url: `/admin/project_license/${project_license_id}/feature_access`, data: {can_add_subs}})
}

export async function impersonateUser(id: string): Promise<any> {
  return api({method: 'post', url: `/user/${id}/impersonate`})
}

export async function resetUserPassword(userId: string, password: string): Promise<AxiosResponse<any>> {
  return api({method: 'patch', url: `/user/${userId}/password`, data: {password}})
}

export async function changeCompanyDemoStatus(companyId: string, for_demos: boolean): Promise<any> {
  return api({method: 'patch', url: `/company/${companyId}/for_demo_status`, data: {for_demos}})
}
