import React from "react"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import {useTranslation} from "react-i18next"
import {Tooltip} from "@mui/material"

import {CompanyType} from "../../../../../../../types/company"
// @ts-ignore
import Button from "../../../../../../../components/Button"
import { ChangeOrderActionCode } from "../../../../../../../types/action"


function showThisButton(companyType: CompanyType, changeOrderActionCode: ChangeOrderActionCode): boolean {
  // must be trade
  if (companyType !== 'trade') return false
  // these statuses can always submit
  return ["draft", "revise", "submitted"].includes(changeOrderActionCode)
}

interface SubmitReviewButtonProps {
  companyType: CompanyType,
  changeOrderActionCode: ChangeOrderActionCode,
  togglePocketForm: () => void,
  disabled: boolean,
  disabledMessage: string,
}

export function SubmitReviewButton(props: SubmitReviewButtonProps) {
  const { t } = useTranslation("private")

  return showThisButton(props.companyType, props.changeOrderActionCode) ? (
    <Grid item>
      <Tooltip title={props.disabled? props.disabledMessage : null} placement={'top'}>
        <div>
          <Button
            endIcon={<SendIcon />}
            style={{ minWidth: 125 }}
            onClick={props.togglePocketForm}
            disabled={props.disabled}
          >
            {t("view.ChangeOrder.Summary.submit")}
          </Button>
        </div>
      </Tooltip>
    </Grid>
  ) : (
    <></>
  )
}
