import React from "react"
import { useTranslation } from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

// @ts-ignore
import Card from "../../components/Card"

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
  },
  logo: {
    margin: "0 auto",
    maxHeight: 100,
    maxWidth: 100,
    width: "90%",
  },
}))

interface AccountsCompanyCardProps {
  company: any,
  index: number,
  onClickCompany: (company: any) => void
}

export default function AccountsCompanyCard(props: AccountsCompanyCardProps) {
  const {company, index, onClickCompany} = props
  const classes: any = useStyles()
  const { t } = useTranslation("common")

  const visitButton = (
    <Button
      className={classes.button}
      size="small"
      onClick={() => {onClickCompany(company)}}
    >
      {t("Visit")}
    </Button>
  )

  const companyLogo = company.logo_url ? (
    <img alt={t("Logo")} className={classes.logo} src={`${company.logo_url}`} />
  ) : (
    <></>
  )

  return (
    <Grid
      key={`${company.name} ${index}`}
      item
      container
      alignContent="stretch"
      alignItems="stretch"
      xs={12}
      sm={6}
      md={3}
    >
      <Card
        button={visitButton}
        action={() => {onClickCompany(company)}}
        title={company.name}
      >
        {companyLogo}
      </Card>
    </Grid>
  )
}
