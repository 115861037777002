import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {UserIntegration} from "../types/user"

export async function getAgaveLinkToken(): Promise<AxiosResponse<{link_token: string}>> {
  return api.post('/agave_link_token')
}

export async function createUserIntegration(
  integrationId: number,
  public_token: string
): Promise<AxiosResponse<{integration: UserIntegration}>> {
  return api.post(`/active-integration/${integrationId}`, {public_token})
}

export interface IntegrationProjectsForDisplay {
  short_address: string,
  description: string,
  name: string,
  number: string,
  start_date: string,
  agave_id: string,
}

export async function getIntegrationProjects(
  userIntegrationId: string
): Promise<AxiosResponse<{projects: IntegrationProjectsForDisplay[] }>> {
  return api.get(`/active-integration/${userIntegrationId}/project`)
}

export interface IntegrationPrimeContractsForDisplay {
  agave_id: string,
  description: string,
  name: string,
  number: string,
  start_date: string,
}

export async function getProjectIntegrationPrimeContracts(
  userIntegrationId: string,
  projectId: string,
): Promise<AxiosResponse<{prime_contracts: IntegrationPrimeContractsForDisplay[] }>> {
  return api.get(`/active-integration/${userIntegrationId}/project/${projectId}/prime-contract`)
}

export interface IntegrationPcoForDisplay {
  agave_id: string,
  description: string,
  name: string,
  number: string,
  status: string,
  type: string,
}

export async function getProjectIntegrationPcos(
  userIntegrationId: string,
  projectId: string,
): Promise<AxiosResponse<{change_orders: IntegrationPcoForDisplay[] }>> {
  return api.get(`/active-integration/${userIntegrationId}/project/${projectId}/pco`)
}

export interface IntegrationChangeEventForDisplay {
  agave_id: string,
  description: string,
  name: string,
  number: string,
  status: string,
  type: string,
}

export async function getProjectIntegrationChangeEvents(
  userIntegrationId: string,
  projectId: string,
): Promise<AxiosResponse<{change_events: IntegrationChangeEventForDisplay[]}>> {
  return api.get(`/active-integration/${userIntegrationId}/project/${projectId}/change_event`)
}

export interface IntegrationWbsForDisplay {
  agave_id: string,
  code: string,
  name: string,
}

export async function getProjectIntegrationWbs(
  userIntegrationId: string,
  projectId: string,
): Promise<AxiosResponse<{wbs: IntegrationWbsForDisplay[] }>> {
  return api.get(`/active-integration/${userIntegrationId}/project/${projectId}/wbs`)
}
