import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import {useDispatch} from 'react-redux'

//@ts-ignore
import Container from "../../../../../../components/Container"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import { ActionInfo, EstimateActionCode } from "../../../../../../types/action"
// @ts-ignore
import ScrollToLink from "../../../../../../components/ScrollToLink"
import { Estimate } from "../../../../../../types/estimate"
import EstimateEditButton from './EstimateEditButton'
import EstimateReviewButtons from './EstimateReviewButtons'
import {updateEstimateAction} from '../../../../../../store/features/estimateSlice'
import EstimateBackupsButton from './EstimateBackupsButton'


interface EstimateReviewSectionProps {
  estimate: Estimate
  projectId: string,
  historyRef: any
}

export default function EstimateReviewSection(
  {estimate, projectId, historyRef}: EstimateReviewSectionProps
): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation("private")
  const estimateActionIdObjMapping = useTracFloSelector((state) => state?.estimateAction?.idObjectMapping)
  const [estimateActionInfo, setEstimateActionInfo] = useState<ActionInfo<EstimateActionCode> | null>(
    !!estimate && !!estimateActionIdObjMapping
      ? estimateActionIdObjMapping[estimate.estimate_action_id]
      : null
  )

  const estimateActionChange = (new_action_id: number): void => {
    if (estimateActionIdObjMapping[new_action_id]) {
      setEstimateActionInfo(estimateActionIdObjMapping[new_action_id])
      dispatch(updateEstimateAction({new_action_id, estimate_id: estimate.id}))
    }
  }

  useEffect(() => {
    setEstimateActionInfo(
      !!estimate && !!estimateActionIdObjMapping
        ? estimateActionIdObjMapping[estimate.estimate_action_id]
        : null
    )
  }, [estimate, estimateActionIdObjMapping])


  return estimateActionInfo != null ? (
    <>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6} md={7}>
            <Typography variant="h1">{t("view.Estimates.SingleEstimate.title")}</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginRight: -12, marginTop: -12, textAlign: "right" }}
          >
            <EstimateBackupsButton projectId={projectId} estimateId={estimate.id}/>
            <EstimateEditButton
              projectId={projectId}
              estimateId={estimate.id}
              estimateActionCode={estimateActionInfo.code}
              estimateIsClosed={estimate.is_closed}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong>{t("view.Estimates.SingleEstimate.status")}</strong>
              {t(`view.Estimates.SingleEstimate.IsClosed.${estimate.is_closed.toString()}`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong>{t("view.Estimates.SingleEstimate.action")}</strong>
              {t(`component.EstimateAction.${estimateActionInfo.code}`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ScrollToLink targetRef={historyRef}>
              {t("view.Estimates.SingleEstimate.history")}
            </ScrollToLink>
          </Grid>
        </Grid>
      </Container>

      <EstimateReviewButtons
        estimate={estimate}
        estimateActionInfo={estimateActionInfo}
        estimateActionChange={estimateActionChange}
        projectId={projectId}
      />
    </>
  ) : <></>
}
