import React from "react"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { ActionCode } from "../../../../../../types/action"
import { CompanyType } from "../../../../../../types/company"
import AttachedToCoText from "./AttachedToCoText"


interface TicketActionTextProps {
  attachedCo: any,
  projectId: string,
  ticketActionInfoCode: ActionCode,
  companyType: CompanyType,
}

export default function TicketActionText(props: TicketActionTextProps): any {
  const { attachedCo, projectId, ticketActionInfoCode, companyType } = props
  const { t } = useTranslation("private")

  return (
    <Typography display="inline">
      <strong>{t("view.ChangeOrder.Summary.action")}</strong>:{" "}
      { attachedCo ?
        <AttachedToCoText attachedCo={attachedCo} projectId={projectId} /> : 
        t(`view.TicketAction.${ticketActionInfoCode}.${companyType}`)
      }
    </Typography>
  )
}
