import React, {useMemo} from 'react'
import {Form, FormikProps} from 'formik'
import Grid from '@mui/material/Grid'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {Autocomplete} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import AddIcon from '@mui/icons-material/Add'

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import SelectField from "../components/Fields/Select"
//@ts-ignore
import TextField from "../components/Fields/Text"
import {Labor, LaborRateTypes} from '../types/labor'
import {useLanguageLocale} from '../hook/languageLocale'
import {formatDate} from '../libs/format'
import {ListOption} from '../types/form'
import {AddLaborBreakdownInitialValues} from './AddLaborToBreakdown.types'


export function laborTypeIsValidForBreakdownWorkDate(
  workDate: Date | null,
  laborStartDate: string,
  laborEndDate: string | null
): boolean {
  // if there is no work date, or we don't have a labor end date, then it can be used
  if(workDate == null || laborEndDate == null) return true
  // we have work date and both labor rate effective dates, we only allow if work date is between
  return ((workDate.valueOf() >= (new Date(laborStartDate)).valueOf())
    && (workDate.valueOf() <= (new Date(laborEndDate)).valueOf()))
}

function createLaborTypeLabel(
  name: string,
  startDate: string,
  t: (s: string, v: any) => string,
  endDate?: string,
): string {
  return t("form.label.laborTypeDropdown", { name, date: formatDate(startDate, endDate)})
}

export type AddLaborToBreakdownFormProps = FormikProps<AddLaborBreakdownInitialValues> & {
  t: (s: string) => string,
  selectedType: Labor | null,
  setSelectedType: (l: Labor | null) => void,
  date_start: Date | undefined,
  date_end: Date | undefined,
  setFormActive: (formActive: boolean) => void,
  addRates: boolean,
  laborTypes: Labor[],
  rateTypeOptions: ListOption<LaborRateTypes>[],
  hideDateField: boolean,
}

export default function AddLaborToBreakdownForm(
  {
    errors,
    handleBlur,
    touched,
    values,
    setFieldValue,
    resetForm,
    submitForm,
    handleChange,
    t,
    selectedType,
    setSelectedType,
    date_end,
    date_start,
    setFormActive,
    addRates,
    laborTypes,
    rateTypeOptions,
    hideDateField,
  }: AddLaborToBreakdownFormProps
): JSX.Element {
  const languageLocale = useLanguageLocale()
  const laborTypeOptions = useMemo<ListOption<string>[]>(() => {
    return laborTypes.filter((l) => (
      laborTypeIsValidForBreakdownWorkDate(new Date(values.date), l.date_start, l.date_end)
    )).map((l) => ({
      label: createLaborTypeLabel(l.name, l.date_start, t, l.date_end ?? undefined),
      value: l.id,
    }))
  }, [laborTypes, values.date, t])

  return (
    <Form>
      <Grid container spacing={3} justifyContent="center">
        {
          hideDateField
            ? <></>
            : <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={languageLocale}>
              <Grid item xs={12}>
                <DatePicker
                  //@ts-ignore
                  autoOk={true}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  label={t("form.label.date")}
                  mask="__/__/____"
                  name="date"
                  onChange={(date) => {
                    setFieldValue("date", date ? date.toString() : "")
                    if(values.type_id != null){
                      const selectedLaborType = laborTypes.find(({ id }) => id === values.type_id)
                      if(selectedLaborType != null) {
                        const isCurrentLaborTypeValid = laborTypeIsValidForBreakdownWorkDate(
                          date,
                          selectedLaborType.date_start,
                          selectedLaborType.date_end
                        )
                        if(!isCurrentLaborTypeValid){
                          setFieldValue("type_name", "")
                          setFieldValue("type_id", "")
                          setSelectedType(null)
                          setFieldValue("rate", "")
                        }
                      }
                    }
                  }}
                  renderInput={(props) => <TextField {...props} />}
                  showTodayButton={true}
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={values.date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={date_end}
                  minDate={date_start}
                />
              </Grid>
            </LocalizationProvider>
        }
        <Grid item xs={12}>
          <Autocomplete
            onChange={(_, newValue: any) => {
              // Will only execute when user chooses an existing type
              if(newValue && newValue.value) {
                const selectedLaborType = laborTypes.find(({ id }) => id === newValue.value)
                if(selectedLaborType != null){
                  setFieldValue("type_id", newValue.value)
                  setFieldValue("type_name", selectedLaborType.name)
                  setSelectedType(selectedLaborType)
                  if(values.rate_type !== "") {
                    setFieldValue('rate', selectedLaborType[values.rate_type as LaborRateTypes])
                  }
                }
              }
            }}
            disableClearable
            freeSolo
            options={laborTypeOptions}
            renderInput={(params) => {
              return <TextField {...params}
                autoFocus
                onChange={(e: any) => {
                  if(e.target.value.length > 0){
                    // Update all fields if there is an exact match
                    const selectedLaborType = laborTypes.find((l) =>
                      createLaborTypeLabel(l.name, l.date_start, t, l.date_end ?? undefined) === e.target.value
                    )
                    if(selectedLaborType != null){
                      setFieldValue("type_name", selectedLaborType.name)
                      setFieldValue("type_id", selectedLaborType.id)
                      setSelectedType(selectedLaborType)
                    } else {
                      setFieldValue("type_name", "")
                      setFieldValue("type_id", "")
                      setSelectedType(null)
                    }
                  }
                  // Reset everything in case string is blank
                  else {
                    setFieldValue("type_name", "")
                    setFieldValue("type_id", "")
                    setSelectedType(null)
                  }
                }}
                label={t("view.ChangeOrder.Labor.type")}
                error={Boolean(touched.type_id && errors.type_id)}
                helperText={touched.type_id && errors.type_id}
              />
            }}
            value={values.type_name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectField
            error={Boolean(touched.rate_type && errors.rate_type)}
            helperText={touched.rate_type && errors.rate_type}
            label={t("view.ChangeOrder.Labor.rate")}
            name="rate_type"
            onBlur={handleBlur}
            onChange={(e: {target: {value: LaborRateTypes}}) => {
              handleChange(e)
              // rate type determines the exact rate, so we need to get it off of the object
              if(selectedType != null){
                setFieldValue('rate', selectedType[e.target.value] ?? 0)
              }
            }}
            options={rateTypeOptions}
            renderInput={(props: any) => <TextField {...props} />}
            value={values.rate_type}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={Boolean(touched.quantity && errors.quantity)}
            helperText={touched.quantity && errors.quantity}
            InputProps={{ inputProps: { min: 1, step: 1 } }}
            label={t("view.ChangeOrder.Labor.crew_size")}
            name="quantity"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            value={values.quantity}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={Boolean(touched.hours && errors.hours)}
            helperText={touched.hours && errors.hours}
            InputProps={{ inputProps: { min: 0, step: 0.25 } }}
            label={t("view.ChangeOrder.Labor.hours_person")}
            name="hours"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            value={values.hours}
          />
        </Grid>

        {addRates ? (
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
                style: {
                  border: "none",
                  outline: "none",
                },
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              error={Boolean(touched.rate && errors.rate)}
              disabled
              helperText={touched.rate && errors.rate}
              label={t("view.ChangeOrder.Labor.hours_rate")}
              name="rate"
              onBlur={handleBlur}
              onChange={handleChange}
              style={{
                border: "none",
                outline: "none",
              }}
              value={values.rate}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid container item xs={6} alignContent="center" justifyContent="flex-start">
          <Button
            color="secondary"
            onClick={() => {
              resetForm()
              setFormActive(false)
            }}
            size="small"
            variant="text"
          >
            {t("view.ChangeOrder.cancel")}
          </Button>
        </Grid>

        <Grid container item xs={6} alignContent="center" justifyContent="flex-end">
          <Button startIcon={<AddIcon />} onClick={submitForm}>
            {t("view.ChangeOrder.Labor.add")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}
