import Grid from "@mui/material/Grid"

import DetailRow, { DetailRowInfo } from "./DetailRow"


interface DetailsTableProps {
  details: DetailRowInfo[],
  titleContainerWidth?: number
}

export default function DetailsTable(
  {details, titleContainerWidth}: DetailsTableProps
): JSX.Element {
  return (
    <Grid container item xs={12} direction="column" style={{ marginBottom: 16, alignItems: "flex-start" }}>
      {details.map((detail) => 
        <DetailRow 
          key={detail.title} 
          title={detail.title}
          content={detail.content}
          titleStyle={detail.titleStyle}
          titleContainerWidth={titleContainerWidth}
          contentFormatter={detail.contentFormatter}
        />
      )}
    </Grid>
  )
}
