import {createSlice} from "@reduxjs/toolkit"
import {GridFilterItem} from "@mui/x-data-grid"

import {TableFiltersStore} from "../../types/tableFilter"
import {getExpiry} from "../../util/expiry"
import {TracFloState} from "../../types/state"


const initialState: TableFiltersStore = {
  tableFilters: {}
}

export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    reset: () => initialState,
    setTableFilter: (
      state: TableFiltersStore,
      action: {payload: {tableName: string, filterItems: GridFilterItem[]}}
    ) => {
      const {tableName, filterItems} = action.payload
      state.tableFilters[tableName] = {
        tableName,
        filterItems,
        exp: getExpiry(60 * 24) // good for one day
      }
    },
  },
})

export const {
  reset,
  setTableFilter,
} = tableFiltersSlice.actions

export default tableFiltersSlice.reducer

export const getTableFilter = (tableName: string) => {
  return (state: TracFloState) => {
    return state.tableFilters.tableFilters[tableName]
  }
}
