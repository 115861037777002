import Typography from "@mui/material/Typography"
import parse from "html-react-parser"
import React from "react"
import {useTranslation} from "react-i18next"
import { Grid } from "@mui/material"

import DetailRow from "../DetailRow"
import { CompanySenderReceiverInfo } from "../../types/company"


interface InvoiceTopCompanyInfoProps {
  sender: CompanySenderReceiverInfo | null,
  receiver: CompanySenderReceiverInfo | null,
}

export default function InvoiceTopCompanyInfo(props: InvoiceTopCompanyInfoProps): any {
  const { t } = useTranslation("private")

  return (
    <>
      {props.sender ?
        <Grid container style={{ marginBottom: 16 }}>
          <DetailRow
            title={t("view.ChangeOrder.Summary.from")}
            content={{
              address: props.sender.address,
              name: props.sender.name,
              phone: props.sender.phone,
            }}
            contentFormatter={(content: any) => (
              <>
                <Typography style={{fontSize: 12}}>{content.name}</Typography>
                {content.address ? <Typography style={{fontSize: 12}}>{parse(`${content.address}`)}</Typography> : <></>}
                {content.phone ? <Typography style={{fontSize: 12}}>{content.phone}</Typography> : <></>}
              </>
            )}
          />
        </Grid>
        : <></>
      }

      {props.receiver ?
        <Grid container style={{ marginBottom: 16 }}>
          <DetailRow
            title={t("view.ChangeOrder.Summary.to")}
            content={{
              address: props.receiver.address,
              name: props.receiver.name,
            }}
            contentFormatter={(content: any) => (
              <>
                <Typography style={{fontSize: 12}}>{content.name}</Typography>
                {content.address ? <Typography style={{fontSize: 12}}>{parse(content.address)}</Typography> : <></>}
              </>
            )}
          />
        </Grid>
        : <></>
      }
    </>
  )
}
