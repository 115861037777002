import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'
import {GridColDef} from '@mui/x-data-grid'

// @ts-ignore
import DtRowTitle from "../../../../../../../components/DtRowTitle"
import theme from '../../../../../../../theme'
import templateUrls from '../../../../../../../util/csv/templateUrls'
import CsvImport, {CsvImportUtils, ImportInstructionsUtils} from '../../../../../../../components/CsvImport'
import {DisplayImportedObjectsUtils} from '../../../../../../../components/CsvImportDisplayObjects'
import {convertToNumber} from '../../../../../../../util/number'
import {formatMoney} from '../../../../../../../libs/format'
import {convertCsvStringToLaborArray} from './ImportLaborFromCsv.data'
import {createMultipleLaborTypes} from '../../../../../../../api/labor'


interface LaborForUpload {
  name: string,
  cost_code: string,
  rate_rt: string,
  rate_ot: string,
  rate_dt: string,
  rate_pot: string,
  rate_pdt: string,
}

interface ImportLaborFromCsvProps {
  onClose: () => void,
  importLaborTypesSuccessful: () => void,
  projectId: string,
}

const displayColumns: GridColDef[] = [
  {
    align: "left",
    headerAlign: "left",
    field: "name",
    headerName: "Description",
    flex: 1,
    minWidth: 180,
    renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "cost_code",
    headerName: "Cost Code",
    width: 100,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate_rt",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "RT",
    width: 80,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate_ot",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "OT",
    width: 80,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate_dt",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "DT",
    width: 80,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate_pot",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "POT",
    width: 80,
    editable: false,
  },
  {
    align: "right",
    headerAlign: "right",
    field: "rate_pdt",
    renderCell: (params: any) => convertToNumber(params.value) !== 0 ? formatMoney(params.value) : "—",
    headerName: "PDT",
    width: 80,
    editable: false,
  },
]

const translateComponents: JSX.Element[] = [
  <a
    style={{
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      textDecoration: "underline",
      // @ts-ignore
      "&:hover": {
        cursor: "pointer",
      },
    }}
    href={templateUrls.laborTypes}
    download={true}
  />
]

type LaborWithDateForUpload = LaborForUpload & {date_start: string}

function addDateStartToUploadedLabor(labor: LaborForUpload): LaborWithDateForUpload {
  return {
    ...labor,
    date_start: (new Date()).toISOString()
  }
}

const importUtils: CsvImportUtils<LaborForUpload> = {
  convertCsvStringToObjectArray: convertCsvStringToLaborArray
}

const displayUtils: DisplayImportedObjectsUtils<LaborForUpload> = {
  columns: displayColumns,
  addIdToObject: (m, i) => ({...m, id: `${m.name}||${m.rate_rt}||${m.rate_dt}||${m.rate_ot}||${i}`}),
  titleTranslateKey: 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.uploadedLaborTitle',
}

const instructionUtils: ImportInstructionsUtils = {
  translateKey: "view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.instructions",
  translateComponents,
}

export default function ImportLaborFromCsv(
  {onClose, importLaborTypesSuccessful, projectId}: ImportLaborFromCsvProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const submitImportedEquipment = async (laborForUpload: LaborForUpload[]) => {
    if (laborForUpload.length > 0) {
      try {
        // Need to add required fields before uploading
        const res = await createMultipleLaborTypes(projectId, laborForUpload.map(addDateStartToUploadedLabor))
        if (res.status === 200 && Array.isArray(res.data)) {
          importLaborTypesSuccessful()
          enqueueSnackbar(t('view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.submitSuccess'), {variant: 'success'})
        } else {
          enqueueSnackbar(t('view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.submitFailed'), {variant: 'error'})
        }
      } catch(e: any) {
        enqueueSnackbar(t('view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.submitFailed'), {variant: 'error'})
      }
      onClose()
    }
  }

  return (
    <CsvImport<LaborForUpload>
      onClose={onClose}
      importUtils={importUtils}
      displayUtils={displayUtils}
      instructionsUtils={instructionUtils}
      submit={submitImportedEquipment}
      submitButtonTextTranslateKey={"view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.submitButton"}
    />
  )
}
