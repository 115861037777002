import {Container} from "@mui/material"
import {useDispatch} from "react-redux"
import {useSnackbar} from "notistack"

import CreateCompany from "../../forms/CreateCompany"
// @ts-ignore
import { addCompany } from "../../store/features/userSlice"
import { createNewCompanyWithLogo } from "../../util/company"
// @ts-ignore

export default function AccountsCreateCompany() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const createCompanyForNewUser = async (company: any): Promise<void> => {
    const newCompany = await createNewCompanyWithLogo(
      enqueueSnackbar,
      company,
    )
    if(newCompany){
      dispatch(addCompany(newCompany))
    }
  }

  return (
    <Container maxWidth={'md'} style={{marginTop: '48px'}}>
      <CreateCompany
        onCancel={() => {/*intentionally blank*/}}
        submit={createCompanyForNewUser}
        useBigSubmitButton={true}
      />
    </Container>
  )
}
