import { notifyForCoReview, submitChangeOrderForReview } from "../../../../../../../api/changeOrderReviewProcess"
import { uploadChangeOrderReviewProcessFiles } from "../../../../../../../api/changeOrderReviewProcess"
import { ChangeOrderSubmitFormValues } from "./ChangeOrderSubmitForm"


async function submitCoForReview(
  projectId: string,
  changeOrderId: string,
  message: string,
): Promise<any | null> {
  try {
    return submitChangeOrderForReview(projectId, changeOrderId, message)
  } catch (_) {
    // TODO need better error handling
    return null
  }
}

export interface SubmitForReviewProps {
  values: ChangeOrderSubmitFormValues,
  userId: string | null,
  projectId: string,
  changeOrderId: string,
  enqSnackbar: any,
  closeSnackbar: any,
  changeOrderActionChange: (actionId: number) => void
}

async function sendCoEmailNotification(
  trp_id: string,
  project_id: string,
  pmIds: string[],
  superIds: string[],
  userId: string | null,
): Promise<any | null> {
  const ids = (Array.isArray(pmIds) ? pmIds : []).concat(
    Array.isArray(superIds) ? superIds : []
  ).concat(
    userId !== null ? [userId] : []
  )
  // only email if we have stuff
  if (ids.length === 0) return

  return notifyForCoReview(project_id, trp_id, ids)
}

export async function submitForReview(props: SubmitForReviewProps): Promise<void> {
  const {
    values,
    userId,
    projectId,
    changeOrderId,
    enqSnackbar,
    closeSnackbar,
    changeOrderActionChange
  } = props

  // Submit the change order for review
  const res = await submitCoForReview(
    projectId,
    changeOrderId,
    values.notes,
  )
  if (res == null || !res?.data?.id) {
    enqSnackbar(
      'Sorry, there was an issue submitting your request. Please try again later.',
      {variant: 'error'}
    )
    return
  }

  // Upload any attached files
  const filesForUpload = values.files
  if (filesForUpload.length > 0 && !!filesForUpload[0].name) {
    const uploadingKey = enqSnackbar(
      'Uploading files...',
      {variant: 'info', style: {whiteSpace: 'pre-wrap'}}
    )
    const fileUploadMessage = await uploadChangeOrderReviewProcessFiles(
      filesForUpload,
      projectId,
      res.data.id
    )
    closeSnackbar(uploadingKey)
    if (fileUploadMessage.error) {
      enqSnackbar(fileUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      return
    }
  }

  // Then send emails
  const emailsRes = await sendCoEmailNotification(
    res.data.id,
    projectId,
    values.pmClients,
    values.superClients,
    values.sendMeCopy ? userId : null,
  )
  if (emailsRes.status === 200) {
    // Clean up
    // Set the state of Change Order Action to New Action
    if (res?.data?.new_change_order_action_id) {
      changeOrderActionChange(res.data.new_change_order_action_id)
    }
    enqSnackbar(
      'Review request was submitted successfully',
      {variant: 'success'}
    )
  } else {
    enqSnackbar(
      'There was an issue sending the email notification. Please try again later.',
      {variant: 'error'}
    )
  }

}
