import React from "react"
import { useDispatch } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {useSnackbar} from "notistack"

// @ts-ignore
import Button from "../../../components/Button"
// @ts-ignore
import Link from "../../../components/Link"
// @ts-ignore
import TextField from "../../../components/Fields/Text"
// @ts-ignore
import { setUserFromToken } from "../../../store/features/userSlice"
import {signUp} from "../../../api/users"
import {useQuery} from "../../../util/url";

interface SignUpFormFields {
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
}

function processSignUpForm(form: SignUpFormFields): any {
  return {
    ...form,
    avatar_url: '', // we need this to process users correctly
  }
}

export default function SignUpForm() {
  const { t } = useTranslation("public")
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()

  // passing forward redirect
  const redirect = useQuery().get("redirect")
  const loginUrl = `/login${redirect ? `?redirect=${redirect}` : ''}`

  return (
    <Formik<SignUpFormFields>
      initialValues={{
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(t("form.message.emailRequired")),
        first_name: Yup.string().required(t("form.message.firstRequired")).min(1),
        last_name: Yup.string().required(t("form.message.lastRequired")).min(1),
      })}
      onSubmit={async (values) => {
        try {
          const {data} = await signUp(processSignUpForm(values))
          const token = data?.data?.jwt
          if (token) {
            dispatch(setUserFromToken({token}))
          } else {
            enqueueSnackbar(t('form.message.signupGenericFailed'), {variant: 'error'})
          }
        } catch(e: any) {
          if (e?.response?.status) {
            if (e.response.status === 400) {
              if (e.response.data.includes('user already has an account')) {
                enqueueSnackbar(t('form.message.signupUserAlreadyExists'), {variant: 'info'})
                return
              }
            }
          }
          enqueueSnackbar(t('form.message.signupGenericFailed'), {variant: 'error'})
        }
      }}
    >
      {({
        errors,
        handleBlur,
        isSubmitting,
        isValid,
        touched,
        values,
        handleChange,
      }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{textAlign: "center",}}>
                  <Trans
                    i18nKey="view.signup.switch"
                    t={t}
                    components={[<Link to={loginUrl} style={{ fontWeight: 700 }} />]}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  label={t("form.label.firstName")}
                  name="first_name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name}
                  label={t("form.label.lastName")}
                  name="last_name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  label={t("form.label.email")}
                  name="email"
                  type="email"
                  id="email"
                  autocomplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  label={t("form.label.phone")}
                  name="phone"
                />
              </Grid>
              <Grid container justifyContent={"center"} item xs={12}>
                <Typography align={"center"} sx={{ mb: 1, width: "100%" }}>
                  {t("form.emailWillRequired")}
                </Typography>
                <Button
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  type="submit"
                  size="large"
                  endIcon={<ArrowForwardIosIcon/>}
                  style={{marginTop: '20px', marginBottom: '5px'}}
                >
                  {t("form.label.signup")}
                </Button>
                <Typography align={"center"} sx={{ mb: 1, width: "100%" }} variant={'h6'}>
                  {t("form.userAgreement")}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
