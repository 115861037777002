import { useState } from "react"
import Grid from "@mui/material/Grid"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import {useTranslation} from "react-i18next"
import { useSnackbar } from "notistack"

import {CompanyType} from "../../../../../../types/company"
// @ts-ignore
import Button from "../../../../../../components/Button"
// @ts-ignore
import Dialog from "../../../../../../components/Dialog"
import {BulletinActionCode} from "../../../../../../types/action"
import ConfirmOrCancelPrompt from "../../../../../../components/ConfirmOrCancelPrompt"
import {notifyToProceed} from "../../../../../../api/bulletin"
import { ProjectUserRoleCode } from "../../../../../../types/userRoles"
import {bulletinActionIdTmAuthorized} from '../../../../../../types/bulletin'


function showThisButton(
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  bulletinActionCode: BulletinActionCode
): boolean {
  // must be gc
  if (companyType !== 'cm') return false
  // must be a pm or super
  if (!["pm", "super"].includes(projectUserRole)) return false
  const validStatuses: BulletinActionCode[] = ['estimates_approved']
  return validStatuses.includes(bulletinActionCode)
}

interface NotifyToProceedProps {
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  bulletinActionCode: BulletinActionCode,
  bulletinId: string,
  projectId: string,
  bulletinActionChange: (new_action_id: number) => void,
  togglePocketForm: () => void
}

export function NotifyToProceed(
  {
    companyType,
    projectUserRole,
    bulletinActionCode,
    bulletinId,
    projectId,
    bulletinActionChange,
    togglePocketForm
  }: NotifyToProceedProps
): JSX.Element {
  const { t } = useTranslation("private")
  const [confirmOrCancelPromptOpen, setConfirmOrCancelPromptOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()


  const openConfirmCancelDialog = async (): Promise<void> => {
    togglePocketForm()
    setConfirmOrCancelPromptOpen(true)
  }

  const closeConfirmCancelDialog = () => {
    setConfirmOrCancelPromptOpen(false)
  }

  const submitForm = async (): Promise<void> => {
    closeConfirmCancelDialog()
    try {
      // Sends an email to all sub contacts
      // Creates an estimate
      const {data} = await notifyToProceed(projectId, bulletinId)
      if(data.success){
        // Changes bulletin status
        bulletinActionChange(bulletinActionIdTmAuthorized)
        enqueueSnackbar(t('view.Bulletins.SingleBulletin.NotifyToProceed.apiSuccess'), {variant: "success"})
      } else {
        enqueueSnackbar(t('view.Bulletins.SingleBulletin.NotifyToProceed.apiFail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Bulletins.SingleBulletin.NotifyToProceed.apiFail'), {variant: "error"})
    }
  }

  return showThisButton(companyType, projectUserRole, bulletinActionCode) ? (
    <>
      <Grid item>
        <Button
          color="success"
          endIcon={<ThumbUpIcon />}
          style={{ minWidth: 125 }}
          onClick={openConfirmCancelDialog}
        >
          {t("view.Bulletins.SingleBulletin.NotifyToProceed.button")}
        </Button>
      </Grid>

      <Dialog
        open={confirmOrCancelPromptOpen}
        onClose={closeConfirmCancelDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt
          submitForm={submitForm}
          closeDialog={closeConfirmCancelDialog}
          dialogPrompt={t("view.Bulletins.SingleBulletin.NotifyToProceed.dialogPrompt")}
        />
      </Dialog>
    </>
  ) : (
    <></>
  )
}
