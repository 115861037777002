import { GridColDef } from "@mui/x-data-grid"

//@ts-ignore
import DtRowTitle from "../../../../components/DtRowTitle"
//@ts-ignore
import SelectField from "../../../../components/Fields/Select"
import { CompanyType } from "../../../../types/company"
import { ProjectUserRoleCode, ProjectUserRoleOptionsForGc, ProjectUserRoleOptionsForSub } from "../../../../types/userRoles"

export default function SingleProjectInCompanyUsers(
  t: (s: string) => string,
  company_type: CompanyType,
  adminUpdateUserRoleCode: (user_id: string, user: ProjectUserRoleCode) => void
): GridColDef[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: t("view.Admin.projectUserName"),
      minWidth: 250,
      sortable: false,
      renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "email",
      headerName: t("view.Admin.projectUserEmail"),
      minWidth: 300,
      sortable: false,
      renderCell: (params: any) => <DtRowTitle>{params.value}</DtRowTitle>,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "project_user_role_code",
      headerName: t("view.Admin.projectUserRole"),
      minWidth: 300,
      sortable: false,
      renderCell: (params: any) => (
        <SelectField
          name="project_user_role_code"
          label="Role"
          options={company_type === "cm" ? ProjectUserRoleOptionsForGc : ProjectUserRoleOptionsForSub}
          onChange={(e: any) => (
            adminUpdateUserRoleCode(params.id, e.target.value)
          )}
          value={params.value}
        />
      )
    }
  ]
}
