import { AxiosResponse } from "axios"

//@ts-ignore
import api from "../libs/api"
import {ProjectUser, SubInvitee} from "../types/user"
import {ProjectUserRoleCode} from '../types/userRoles'

export async function getUsersInCompany(companyId: string): Promise<any> {
  return api({
    method: 'get',
    url: `/companies/${companyId}/users`,
  })
}

export async function getUsersInProject(projectId: string): Promise<AxiosResponse<ProjectUser>> {
  return api({
    method: 'get',
    url: `/project/${projectId}/users`,
  })
}

export async function isExistingUser(email: string, projectId: string): Promise<any> {
  return api.get(`/project/${projectId}/existing_user_for_sub_invite?email=${email}`)
}

// FROM API: src/services/inviteService.ts
export interface InviteSubResponse {
  userFound?: boolean,
  userAdded?: boolean,
  userAlreadyInProject?: boolean,
  companyAlreadyInProject?: boolean,
  userCantBeInvited?: boolean,
  inviteSent?: boolean,
}

export async function inviteSub(invitee: SubInvitee, projectId: string): Promise<{data: InviteSubResponse}> {
  return api({
    method: "post",
    url: `/project/${projectId}/invite_sub`,
    data: invitee,
  })
}

export async function signUp(userInfo: any): Promise<any> {
  return api({
    method: 'post',
    url: `/sign-up`,
    data: userInfo,
  })
}

export async function getGcUsers(project_id: any, gc_id: any): Promise<any> {
  return api({
    method: 'get',
    url: `/project/${project_id}/gc/${gc_id}/user`
  })
}

interface SubUser {
  first_name: string,
  last_name: string,
  id: string,
  project_user_role_code: ProjectUserRoleCode
}

export async function getSubUsers(project_id: any, sub_id: any): Promise<AxiosResponse<SubUser[]>> {
  return api({
    method: 'get',
    url: `/project/${project_id}/subcontractor/${sub_id}/user`
  })
}

export async function removeProjectUser(
  project_id: string,
  user_id: string
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'delete',
    url: `/project/${project_id}/users`,
    data: {id: user_id}
  })
}

export async function removeUserFromCompany(
  company_id: string,
  remove_user_id: string
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'delete',
    url: `/companies/${company_id}/user/${remove_user_id}`,
  })
}