import React, {useEffect, useState} from "react"

import HistoryList from "../../../../../../components/History"
import { getBulletinHistory } from "../../../../../../api/bulletin"
import { HistoryItem } from "../../../../../../types/history"

interface BulletinHistoryProps {
  bulletin_id: string,
  project_id: string,
}

export default function BulletinHistory({bulletin_id, project_id}: BulletinHistoryProps) {
  const [bulletinHistory, setBulletinHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    if (!!bulletin_id && !!project_id) {
      getBulletinHistory(project_id, bulletin_id).then(
        (res) => {
          if (res.data.length > 0) {
            setBulletinHistory(res.data)
          }
        }
      )
    }
  }, [bulletin_id, project_id])

  return bulletinHistory.length > 0 ? (
    <HistoryList historyItems={bulletinHistory} />
  ) : (<></>)
}
