import React from "react"
import EditIcon from "@mui/icons-material/Edit"
import {useTranslation} from "react-i18next"

import {CompanyType} from "../../../../../../types/company"
import {ActionCode} from "../../../../../../types/action"
import FeatureAccessButton from "../../../../../../components/FeatureAccessButton"

interface TicketEditButtonProps {
  projectId?: string,
  ticketId?: string,
  companyType?: CompanyType,
  ticketActionCode?: ActionCode,
  hasAttachedCo: boolean,
  projectLicenseIsActive: boolean,
}

export default function TicketEditButton(props: TicketEditButtonProps) {
  const showEditButtonForActionCodes: ActionCode[] = ['draft', 'tm_revise']
  const showEditButton = !!props.projectId && !!props.ticketId
    && props.companyType === 'trade'
    && !!props.ticketActionCode
    && showEditButtonForActionCodes.includes(props.ticketActionCode)
    && !props.hasAttachedCo
  const allowedToEdit = props.projectLicenseIsActive
  const { t } = useTranslation("private")

  return showEditButton
    ? (
      <FeatureAccessButton
        canAccessFeature={allowedToEdit}
        supportDialogFeatureDesc={t("view.Tickets.SingleTicket.editSupportDialogDesc")}
        supportDialogTitle={t("view.Tickets.SingleTicket.editSupportDialogTitle")}
        linkProps={{
          url: `/0/project/${props.projectId}/ticket/${props.ticketId}/edit`,
          buttonText: t("view.Tickets.edit"),
          LeadingIconComponent: <EditIcon />
        }}
      />
    ) : <></>
}
