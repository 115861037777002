import {useEffect, useState} from "react"

import HistoryList from "../../../../../../components/History"
import { HistoryItem } from "../../../../../../types/history"
import { getDailyHistory } from "../../../../../../api/daily"

interface DailyHistoryProps {
  daily_id: string,
  project_id: string,
}

export default function DailyHistory({daily_id, project_id}: DailyHistoryProps) {
  const [dailyHistory, setDailyHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    if (!!daily_id && !!project_id) {
      getDailyHistory(project_id, daily_id).then(
        (res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setDailyHistory(res.data)
          }
        }
      )
    }
  }, [daily_id, project_id])

  return dailyHistory.length > 0 ? (
    <HistoryList historyItems={dailyHistory} />
  ) : (<></>)
}
