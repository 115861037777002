import { createCompany } from "../api/company"
import { uploadCompanyLogoFile } from "../api/file"

export async function createNewCompanyWithLogo(
    enqueueSnackbar: (message: string, options: any) => any,
    company: any,
) : Promise<any | null> {
  try {
    const {data: createdCompany} = await createCompany(company)
    if (createdCompany.id) {
      if (!!company.file.name) {
        try { 
          const {data: updateCompanyLogo} = await uploadCompanyLogoFile(company.file, createdCompany.id)
          if(updateCompanyLogo.id){
            createdCompany.logo_url = updateCompanyLogo.logo_url
          }
          else {
            enqueueSnackbar(`Company successfully created, but unable to upload logo.`, {
              variant: "warning",
            })
          }
        } catch(_) {
          enqueueSnackbar(`Company successfully created, but unable to upload logo`, {
            variant: "warning",
          })
        }
      }
      enqueueSnackbar("Company Created!", {variant: "success",})
      return createdCompany
    } else {
      enqueueSnackbar("There was a problem with creating a company.", {variant: "error",})
    }
  } catch (e) {
    enqueueSnackbar("There was a problem with creating a company.", {variant: "error",})
  } 

}