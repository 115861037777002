import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import React from "react"

// @ts-ignore
import { formatMoney } from "../../../../../../libs/format"


interface ChangeOrderTicketListTotalsProps {
  total: number
  subtotalText: string,
  numberOfColumns: number,
  isLumpSum: boolean,
}

export function ChangeOrderTicketListTotals(props: ChangeOrderTicketListTotalsProps) {
  const {total, subtotalText, numberOfColumns, isLumpSum} = props

  // Don't show if change order is lump sum
  return isLumpSum
    ? (
    <></>
  ) : (
    <TableRow key="totalRow">
      <TableCell
        align="right"
        colSpan={numberOfColumns - 1}
        key={'subtotalText'}
        style={{ border: "none", fontWeight: 700 }}
      >
        {subtotalText}
      </TableCell>
      <TableCell
        align="right"
        colSpan={1}
        key={'subtotalValue'}
        style={{ background: "#f1f1f1", border: "none", fontWeight: 700 }}
      >
        {formatMoney(total)}
      </TableCell>
    </TableRow>
  )
}
