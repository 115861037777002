import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useLocation, Redirect } from "react-router-dom"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useSnackbar } from "notistack"

import { getSingleProjectInCompanyUsersForAdmin, updateUserRole } from "../../../../api/admin"
// @ts-ignore
import Container from "../../../../components/Container"
//@ts-ignore
import FormWideContainer from "../../../../components/FormWideContainer"
import SingleProjectInCompanyUsers from "./SingleProjectInCompanyUsers.columns"
import { Company } from "../../../../types/company"
import { ProjectWithLicense, SingleProjectInCompanyUserData } from "../../../../types/project"
import { ProjectUserRoleCode } from "../../../../types/userRoles"
import DataGridAccordion from '../../../../components/DataGridAccordion'

function singleProjectInCompanyUserDataMapping (singleProjectInCompanyUser: any): SingleProjectInCompanyUserData
{
  const projectUserData = {
    id: singleProjectInCompanyUser.id,
    name: `${singleProjectInCompanyUser.first_name} ${singleProjectInCompanyUser.last_name}`,
    email: singleProjectInCompanyUser.email,
    project_user_role_code: singleProjectInCompanyUser.project_user_role_code
  }
  return projectUserData
}

export default function AdminSingleProjectInCompanyView() {
  const { t } = useTranslation("private")
  const location = useLocation<{company: Company, project: ProjectWithLicense}>()
  const { company, project } = location.state || {}
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [singleProjectInCompanyUsers, setSingleProjectInCompanyUsers] = React.useState<SingleProjectInCompanyUserData[]>([])


  async function adminUpdateUserRoleCode (user_id: string, userRole: ProjectUserRoleCode): Promise<void> {
    try {
      const res = await updateUserRole(company.id, project.project_id, user_id, userRole)
      if(res?.data?.id) {
        const index = singleProjectInCompanyUsers.map(user => user.id).indexOf(user_id)
        Object.assign(singleProjectInCompanyUsers[index], singleProjectInCompanyUserDataMapping(res.data))
        setSingleProjectInCompanyUsers([...singleProjectInCompanyUsers])
      } else {
        enqueueSnackbar(t("view.Admin.issueUpdatingUserRoleCode"), {
          variant: "error",
        })
      }
    } catch {
      enqueueSnackbar(t("view.Admin.issueUpdatingUserRoleCode"), {
        variant: "error",
      })
    }
  }

  async function getSingleProjectInCompanyUsers (company_id: string, project_id: string): Promise<void> {
    try {
      const res = await getSingleProjectInCompanyUsersForAdmin(company_id, project_id)
      if(res?.data?.length >= 0) {
        const singleProjectInCompanyUsersData = res.data.map(singleProjectInCompanyUserDataMapping)
        setSingleProjectInCompanyUsers(singleProjectInCompanyUsersData)
      } else {
        enqueueSnackbar(t("view.Admin.issueGettingProjectUsers"), {
          variant: "error",
        })
        history.push(`/0/admin/companies/`)
      }
    } catch (error) {
      enqueueSnackbar(t("view.Admin.issueGettingProjectUsers"), {
        variant: "error",
      })
      history.push(`/0/admin/companies/`)
    }
  }

  React.useEffect(() => {
    if (!!company?.id && !!project?.project_id) {
      getSingleProjectInCompanyUsers(company.id, project.project_id)
    } else {
      history.push(`/0/admin/companies/`)
    }
  }, [company, project])

  const userItems = {
    title: (t("view.Admin.singleProjectInCompanyUsersTitle")),
    columns: SingleProjectInCompanyUsers(t, company.company_type, adminUpdateUserRoleCode),
    rows: singleProjectInCompanyUsers,
  }

  return !!company && !!project ?
   (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">
              {company.name} - {project.project_name} Project Info
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Link to={`/0/admin/companies/${company.id}`}>
              {t("view.Admin.backToTracFloCompanyProjectsPageTitle")}
            </Link>
          </Grid>
        </Grid>
      </Container>
      <FormWideContainer>
        <DataGridAccordion items={[userItems]}></DataGridAccordion>
      </FormWideContainer>
    </>
  )
  : <Redirect to={`/0/admin/companies`} />
}
