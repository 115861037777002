import { StoreBase } from "./storeBase"
import {LoadingStatus} from "./status"
import { ExpirationField } from "./expiration"
import {CsvHeader} from '../util/csv/createAndDownloadCsv'

export type TicketType = 'tm' | 'sum_rates' | 'sum_total'

export type MarkupType = 'ticket' | 'labor' | 'material' | 'equipment'

export type DailyMarkupType = 'daily' | 'labor' | 'material' | 'equipment'

export interface TicketMarkup {
  id: number,
  description: string,
  percent: number | string,
  markup_type: MarkupType | DailyMarkupType // this applies until we split daily markup from ticket markup
}

export type ReasonTicketClosed = 'added_to_change_order' | 'cost_reject' | 'tm_reject'

export interface Ticket {
  id: string,
  action_id: number,
  total_amount: number | null,
  date_approved: string | null,
  date_cm_cost_approved: string | null,
  date_cm_tm_approved: string | null,
  date_end: string | null,
  date_start: string,
  description: string,
  invoice_number: string | null,
  is_signed: string,
  lumpSumTotal: string | number,
  manual_total: string | number,
  notes: string,
  number: string,
  pco_number: string,
  subject: string,
  includes_costs: boolean,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  ticket_type: TicketType,
  first_submit_date: string | null,
  has_been_approved: boolean,
  date_closed: string | null,
  reason_ticket_closed: ReasonTicketClosed | null,
  change_order_id?: string,
  change_order_number?: string,
  date_modified: string,
  estimate_id: string | null,
  date_invoice: string | null,
  custom_code_ids: string[] | null,
  codes: string[] | null, // added after load, is the code property of custom codes in custom_code_ids
}

export interface LaborBreakdown {
  id: string,
  hours_per_person: number,
  crew_size: number,
  rate_type: string,
  labor_type_id: string,
  date: string,
}

export interface EquipmentBreakdown {
  id: string,
  equipment_type_id: string,
  unit: string,
  rate: number,
  quantity: number,
}

export interface MaterialBreakdown {
  id: string,
  material_type_id: string,
  unit: string,
  rate: number,
  quantity: number,
}

export type TicketDates = {
  date_end: string | null,
  date_start: string | null,
}

export function determineTicketType(includesCosts: boolean, isLumpSum?: boolean): TicketType {
  return includesCosts
    ? isLumpSum
      ? 'sum_total'
      : 'sum_rates'
    : 'tm'
}

export type TicketsStore = {items: Ticket[], projectId: string | null} & StoreBase<LoadingStatus, ExpirationField>

export type LaborTimesheetReportRateType = 'Regular' | 'Double' | 'Overtime' | 'Premium Overtime' | 'Premium Double'

export type TicketLaborTimesheetReport = {
  project_number?: string,
  ticket_number: string,
  labor_type: string,
  labor_date: string,
  hours_per_person: number,
  crew_size: number,
  rate_type: LaborTimesheetReportRateType,
  rate: number,
}

export const TicketLaborTimesheetReportCsvHeaders: CsvHeader[] = [
  {
    value: 'project_number',
    type: 'string'
  },
  {
    value: 'ticket_number',
    type: 'string'
  },
  {
    value: 'labor_type',
    type: 'string'
  },
  {
    value: 'labor_date',
    type: 'date'
  },
  {
    value: 'hours_per_person',
    type: 'number'
  },
  {
    value: 'crew_size',
    type: 'number'
  },
  {
    value: 'rate_type',
    type: 'string'
  },
  {
    value: 'rate',
    type: 'number'
  },
]
