import React from "react"
import {useSnackbar} from "notistack"
import {useSelector} from "react-redux"

import TicketSubmitForm from "./TicketSubmitForm"
// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {ActionCode, ActionInfo} from "../../../../../../../types/action"
import {submitForReview} from "./SubmitTmOrCostForm.submit"
import {ProjectClient} from "../../../../../../../api/ticketReviewProcess"


interface SubmitTmOrCostFormProps {
  toggleFormOpen: () => void,
  ticket: any,
  ticketActionInfo: ActionInfo
  ticketActionChange: (new_action_id: number) => void,
  clients: ProjectClient[],
}

export default function SubmitTmOrCostForm(props: SubmitTmOrCostFormProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const project = useSelector((state: any) => state.project)
  const user = useSelector((state: any) => state.user)

  const submitValuesForReview = async (values: any): Promise<void> => {
    return submitForReview({
      values,
      userId: user.id,
      projectId: project.id,
      ticketId: props.ticket.id,
      enqSnackbar: enqueueSnackbar,
      closeSnackbar,
      ticketActionChange: props.ticketActionChange,
    }).then(() => {
      props.toggleFormOpen()
    })
  }

  return (
    <FormSmallContainer>
      <TicketSubmitForm
        ticketIncludesCosts={props.ticket?.includes_costs}
        ticketActionCode={props.ticketActionInfo.code as ActionCode}
        toggle={props.toggleFormOpen}
        submitValues={submitValuesForReview}
        userIsCrew={project.project_user_role === 'crew'}
        clients={props.clients}
      />
    </FormSmallContainer>
  )
}
