import React, {useEffect, useState} from "react"

import History from "../../../../../../components/History"
import {getTicketHistory} from "../../../../../../api/ticket"
import { HistoryItem } from "../../../../../../types/history"

interface TicketHistoryProps {
  ticket_id: string,
  project_id: string,
}

export default function TicketHistory(props: TicketHistoryProps) {
  const {project_id, ticket_id} = props
  const [ticketHistory, setTicketHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    if (!!ticket_id && !!project_id) {
      getTicketHistory(project_id, ticket_id).then(
        (res) => {
          if (res.data.length > 0) {
            setTicketHistory(res.data)
          }
        } // TODO BETTER ERROR HANDLING
      )
    }
  }, [ticket_id, project_id])

  return ticketHistory.length > 0 ? (
    <History historyItems={ticketHistory} />
  ) : (<></>)
}
