import React, {useMemo} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import NextIcon from "@mui/icons-material/ArrowForwardIos"

// @ts-ignore
import Button from "../../../../../../../components/Button"
// @ts-ignore
import SelectField from "../../../../../../../components/Fields/Select"
// @ts-ignore
import TextAreaField from "../../../../../../../components/Fields/TextArea"
// @ts-ignore
import UploadMultipleField from "../../../../../../../components/Fields/UploadMultiple"
import {ProjectClient} from "../../../../../../../api/ticketReviewProcess"
import {ChangeOrderActionCode} from "../../../../../../../types/action"
import { makeUserName } from "../../../../../../../util/user"

export interface ChangeOrderSubmitFormValues {
  pmClients: string[],
  superClients: string[],
  sendMeCopy: boolean,
  files: any[], // needs to be initialized like this
  notes: string,
}

const fileUploadLimitInMB: number = 10

const ChangeOrderSubmitForm = (props: {
  changeOrderActionCode: ChangeOrderActionCode,
  toggle: () => void,
  submitValues: (values: any) => Promise<void>,
  clients: ProjectClient[],
}): any => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")

  const {pmClientUsers, superClientUsers} = useMemo(() => {
    return {
      pmClientUsers: props.clients.filter((c) => c.project_user_role_code === 'pm'),
      superClientUsers: props.clients.filter((c) => c.project_user_role_code === 'super'),
    }
  }, [props.clients])

  return (
    <Formik
      initialValues={{
        pmClients: [],
        superClients: [],
        sendMeCopy: false,
        files: [{name: ""}], // needs to be initialized like this
        notes: "",
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await props.submitValues(values)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
          isSubmitting,
        }) => {
        return (
          <Form>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" align={isMobile ? "center" : "left"}>
                  {t("view.ChangeOrder.Send.recipients")}
                </Typography>
              </Grid>

              {pmClientUsers.length > 0
                ? <Grid item xs={12}>
                  <SelectField
                    label={t("view.Settings.manager")}
                    multiple={true}
                    name={'pmClients'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={pmClientUsers.map((user) => ({
                      label: makeUserName(user),
                      value: user.id,
                    }))}
                    value={values.pmClients || []}
                  />
                </Grid>
                : <></>
              }

              {superClientUsers.length > 0
                ? <Grid item xs={12}>
                  <SelectField
                    label={t("view.Settings.super_intendent")}
                    multiple={true}
                    name={'superClients'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    options={superClientUsers.map((user) => ({
                      label: makeUserName(user),
                      value: user.id,
                    }))}
                    value={values.superClients || []}
                  />
                </Grid>
                : <></>
              }

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sendMeCopy}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="sendMeCopy"
                    />
                  }
                  label={t("view.ChangeOrder.Send.send_copy")}
                />
                <FormHelperText style={{ color: "red" }}>
                  {errors.sendMeCopy && touched.sendMeCopy ? errors.sendMeCopy : ""}
                </FormHelperText>
              </Grid>

              <Grid item xs={12}>
                <TextAreaField
                  label={t("form.label.notes")}
                  value={values.notes}
                  name="notes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadMultipleField
                  errors={errors}
                  label={t("form.label.uploadAttachments")}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  fileLimit={fileUploadLimitInMB}
                />
              </Grid>
              <Grid container item xs={6} alignItems="center">
                <Button color="secondary" variant="text" onClick={props.toggle}>
                  {t("view.ChangeOrder.cancel")}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
              >
                <Button
                  type="submit"
                  endIcon={<NextIcon />}
                  disabled={(values.pmClients.length + values.superClients.length) === 0 || isSubmitting}
                >
                  {t("view.ChangeOrder.Summary.send")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ChangeOrderSubmitForm
