import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import Grid from '@mui/material/Grid'

// @ts-ignore
import Container from "./Container"
// @ts-ignore
import FormWideContainer from "./FormWideContainer"
import {useTracFloSelector} from '../store/useTracFloSelector'
import {reloadProductionMetricIfInvalid} from '../store/features/productionMetricSlice'
import {reloadDailyIfInvalid} from '../store/features/dailySlice'
import {ProductionMetric} from '../types/productionMetric'
import {Daily} from '../types/daily'
import {convertToNumber} from '../util/number'
import {compareAlphabetically} from '../util/string'
import {Typography} from '@mui/material'
import {ProductionTrackingSummaryMetrics, ProductionTrackingSummaryRow} from './ProductionTrackingSummary.row'


function initializeProductionTrackingSummaryMetrics(
  pms: ProductionMetric[],
): {[key: string]: ProductionTrackingSummaryMetrics} {
  return pms.reduce<{[key: string]: ProductionTrackingSummaryMetrics}>(
    (agg, next) => {
      agg[next.id] = {
        current_amount: 0,
        total_amount: next.total_amount,
        unit: next.unit,
        description: next.description,
        production_metric_id: next.id,
      }
      return agg
    },
    {}
  )
}

function createProductionTrackingSummaryMetrics(
  pms: ProductionMetric[],
  dailies: Daily[]
): ProductionTrackingSummaryMetrics[] {
  const summaryMetricsMapping = initializeProductionTrackingSummaryMetrics(pms)
  // go through all daily production metrics and capture current progress
  for (let daily of dailies) {
    if (daily.production != null) {
      for (let dpm of daily.production) {
        if (summaryMetricsMapping[dpm.production_metric_id] != null) {
          summaryMetricsMapping[dpm.production_metric_id].current_amount += convertToNumber(dpm.amount) ?? 0
        }
      }
    }
  }
  return Object.values(summaryMetricsMapping).sort((a, b) => compareAlphabetically(a.description, b.description))
}

export default function ProductionTrackingSummary(): JSX.Element {
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const project = useTracFloSelector((s) => s.project)
  const pmStore = useTracFloSelector((s) => s.productionMetric)
  const dailyStore = useTracFloSelector((s) => s.daily)

  useEffect(() => {
    // make sure we have production metrics loaded
    if (!!project.id) {
      reloadProductionMetricIfInvalid(project.id, pmStore.projectId, pmStore.exp, dispatch)
    }
  }, [project.id, pmStore, dispatch])

  useEffect(() => {
    // make sure we have dailies loaded
    if (!!project.id && pmStore.projectId != null) {
      reloadDailyIfInvalid(project.id, dailyStore.projectId, dailyStore.exp, dispatch)
    }
  }, [project.id, dailyStore, dispatch, pmStore.projectId])

  const rows = useMemo(() => {
    return createProductionTrackingSummaryMetrics(pmStore.items, dailyStore.items)
  }, [pmStore.items, dailyStore.items])

  return pmStore.items.length === 0
    ? (<></>)
    : (
      <Container removeTop>
        <FormWideContainer>
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid
              item
              xs={12}
              md={10}
              lg={9}
              style={{border: '2px solid #DCDBDC', borderRadius: '2px', paddingTop: '8px'}}
            >
              <Typography variant="h3" style={{marginTop: '0', marginBottom: '20px'}}>
                {t('component.ProductionTrackingSummary.title')}
              </Typography>
              {
                rows.map((row) =>
                  <ProductionTrackingSummaryRow rowMetrics={row} key={row.production_metric_id}/>
                )
              }
            </Grid>
          </Grid>
        </FormWideContainer>
      </Container>
    )
}
