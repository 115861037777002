import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"

//@ts-ignore
import Container from "../components/Container"
//@ts-ignore
import DataTable from "../components/DataTable"
//@ts-ignore
import { formatMoney } from "../libs/format"
import { SelectTicketsColumns } from "./SelectTickets.columns"
import { getEligibleTicketsForChangeOrderForms } from "../api/ticket"

interface SelectTicketsProps {
  addedTickets: any[]
  setAddedTickets: (tickets: any[]) => void,
  projectId: string,
}

const useStyles = makeStyles<Theme>((theme) => ({
  subject: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    overflow: "hidden",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: 330,
  }
}))

export default function SelectTickets(props: SelectTicketsProps) {
  const {addedTickets, setAddedTickets, projectId} = props
  const classes = useStyles()
  const { t } = useTranslation("private")
  const loadingStatus = useSelector((state: any) => state.user.status.tickets)
  const actionIdToInfo = useSelector((state: any) => state.action?.idObjectMapping)
  const [eligibleTickets, setEligibleTickets] = useState<any[]>([])

  useEffect(() => {
    getEligibleTicketsForChangeOrderForms(projectId).then(async (res) => {
      if (res.data) {
        setEligibleTickets(res.data)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  const addTicket = (addTicket: any) => {
    setAddedTickets([addTicket].concat(addedTickets))
    setEligibleTickets(eligibleTickets.filter((t) => t.id !== addTicket.id))
  }

  const removeTicket = (removeTicket: any, addedTickets: any[]) => {
    setEligibleTickets([removeTicket].concat(eligibleTickets))
    setAddedTickets(addedTickets)
  }

  return (
    <Container fullWidth removeTop removeBottom>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.ChangeOrder.selectTickets")}</Typography>
          <Typography variant="h5">{t("view.ChangeOrder.eligibleTicketsNote")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            columns={SelectTicketsColumns(projectId, actionIdToInfo, t, formatMoney, classes)}
            loading={loadingStatus === "loading"}
            rowsSelected
            setRowsSelected={(addedTicket: any[]) => {
              if (addedTicket.length > 0) {
                // only ever one new selected ticket
                addTicket(addedTicket[0])
              }
            }}
            pageSize={8}
            rows={eligibleTickets}
            isRowSelectable={(params: any) => params.row.includes_costs}
          />
        </Grid>
      </Grid>
      {
        addedTickets.length > 0 ?
        <>
          <Grid item xs={12} sx={{paddingTop: 5}}>
            <Typography variant="h2">Added Tickets</Typography>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              hideFooter={addedTickets.length <= 8}
              showCheckboxes={true}
              columns={SelectTicketsColumns(props.projectId, actionIdToInfo, t, formatMoney, classes)}
              loading={loadingStatus === "loading"}
              pageSize={8}
              rows={addedTickets}
              selectionModel={addedTickets.map((ticket) => ticket.id)}
              rowsSelected
              setRowsSelected={(addedTicketsAfterChange: any)=> {
                const addedTicketAfterChangeIds = addedTicketsAfterChange.map((ns: any) => ns.id)
                const removedTicket = addedTickets.find((at) => ! addedTicketAfterChangeIds.includes(at.id))
                if (!!removedTicket) {
                  removeTicket(removedTicket, addedTicketsAfterChange)
                }
              }}
            />
          </Grid>
        </>
        : <></>
      }
    </Container>
  )
}
