import { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper"
import makeStyles from '@mui/styles/makeStyles'
import {Grid, Theme, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'

import {Project} from '../../../../../../types/project'
import { File } from '../../../../../../types/file'
import { Estimate } from '../../../../../../types/estimate'
import EstimateDetailsTopSection from '../../../../../../components/Invoice/EstimateDetailsTopSection'
import { EstimateLineItem, getEstimateFiles, getEstimateLineItems } from '../../../../../../api/estimate'
import EstimateLineItems from './EstimateLineItems'
import EstimateMarkup from './EstimateMarkup'
import { calculateEstimateSubtotal } from '../../../../../../util/estimate'
import { formatMoney } from '../../../../../../libs/format'
import { convertToNumber } from '../../../../../../util/number'
import { EstimateInvoiceSignature } from '../Signature'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 1080,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    "@media print": {
      maxWidth: 9999,
      padding: 0,
    },
    totals: {
      paddingTop: 20,
    },
  },
}))

interface EstimateInvoiceProps {
  estimate: Estimate,
  project: Project,
}

export default function EstimateInvoice(
  {estimate, project}: EstimateInvoiceProps
): JSX.Element {
  const classes = useStyles()
  const [estimateFiles, setEstimateFiles] = useState<File[] | null>(null)
  const [lineItems, setLineItems] = useState<EstimateLineItem[] | null>(null)
  const { t } = useTranslation("private")

  useEffect(() => {
    if (!!estimate.id) {
      // get files for this estimate
      getEstimateFiles(project.id, estimate.id).then(
        (res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setEstimateFiles(res.data)
          }
        }
      )

      getEstimateLineItems(project.id, estimate.id).then(
        (res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setLineItems(res.data)
          }
        }
      )
    }
  }, [estimate.id, project.id])


  return estimate.id && project.id
    ? (
      <Paper className={classes.root} elevation={2}>
        <EstimateDetailsTopSection files={estimateFiles} estimate={estimate} />

        { lineItems != null 
          ? <>
            <EstimateLineItems isLumpSum={estimate.is_lump_sum} lineItems={lineItems}/> 

            {/* Lump Sum Total */}
            { estimate.is_lump_sum && estimate.lump_sum_total != null
              ? <Grid container justifyContent={"flex-end"} style={{marginTop: 20, marginBottom: 30}}>
                <Grid item>
                  <Typography style={{ fontWeight: 800, fontSize: 15 }}>
                    {
                      `${t("view.Estimates.SingleEstimate.lumpSumTotal")}
                      ${formatMoney(convertToNumber(estimate.lump_sum_total) ?? 0)}`
                    }
                  </Typography>
                </Grid>
              </Grid>
              : <></>
            }

            <EstimateMarkup
              projectId={project.id} 
              estimateId={estimate.id} 
              subtotal={calculateEstimateSubtotal(
                estimate.is_lump_sum, 
                estimate.lump_sum_total ?? 0,
                lineItems
              )}
            />

            {/*  Estimate Total */}
            <Grid container justifyContent={"flex-end"} style={{marginTop: 10}}>
              <Grid item>
                <Typography style={{ fontWeight: 800, fontSize: 20}}>
                  {
                    `${t("view.Estimates.SingleEstimate.total")}
                    ${formatMoney(convertToNumber(estimate.total_amount) ?? 0)}`
                  }
                </Typography>
              </Grid>
            </Grid>
          </>
          : <></>
        } 
        <EstimateInvoiceSignature estimateId={estimate.id} projectId={project.id}/>
      </Paper>
    )
    : <></>
}
