import {Grid} from "@mui/material"
import React, {useEffect, useState} from "react"
import {getTicketSignatures} from "../../../../../../api/ticket"
import {useTranslation} from "react-i18next"

import {InvoiceSignature} from "../../../../../../components/Invoice/Signature"
import { InvoiceSignature as InvoiceSignatureType } from "../../../../../../types/signature"
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'


interface TicketInvoiceSignaturesProps {
  ticketId: string,
  projectId: string,
}

export function TicketInvoiceSignatures(props: TicketInvoiceSignaturesProps) {
  const project = useTracFloSelector((s) => s.project)
  const [tmSignature, setTmSignature] = useState<InvoiceSignatureType | null>(null)
  const [costSignature, setCostSignature] = useState<InvoiceSignatureType | null>(null)
  const { t } = useTranslation("private")

  useEffect(() => {
    if (props.ticketId) {
      getTicketSignatures(props.projectId, props.ticketId).then((res) => {
        if(res.status === 200){
          if (res?.data?.tm) {
            setTmSignature(res.data.tm)
          }
          if (res?.data?.cost) {
            setCostSignature(res.data.cost)
          }
        }
      })
    }
  }, [props.ticketId, props.projectId])

  return tmSignature != null || costSignature != null ? (
    <Grid container spacing={2} sx={{ justifyContent: "flex-end", mt: 3 }}>
      {tmSignature != null
        ? <InvoiceSignature
          url={tmSignature.url}
          signed_by={tmSignature.signed_by}
          date_signed={tmSignature.date_signed}
          approved_text={t('view.Tickets.tmSignatureApproved')}
          disclaimerText={project.disclaimer}
        /> : <></>
      }
      {costSignature != null
        ? <InvoiceSignature
          url={costSignature.url}
          signed_by={costSignature.signed_by}
          date_signed={costSignature.date_signed}
          approved_text={t('view.Tickets.costSignatureApproved')}
        /> : <></>
      }
    </Grid>
  ) : (<></>)
}
