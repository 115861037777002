import React, { useMemo } from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import AddIcon from "@mui/icons-material/Add"
import Autocomplete from "@mui/material/Autocomplete"

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import TextField from "../components/Fields/Text"
import { convertToNumber } from "../util/number"
import { defaultAddMaterialEquipmentBreakdownInitialValues } from "./AddMaterialEquipmentToBreakdown.types"
import { formatMoney } from "../libs/format"

interface AddMaterialEquipmentToBreakdownProps {
  addRates: boolean,
  data: any,
  dropdownItems: any[],
  setData: (field: string, value: any) => void,
  setFormActive: (formActive: boolean) => void,
  type: string,
}

function dropdownLabel(name: string, unit: string, rate: string, t: (s: string, v: any) => string): string {
  const rateToNumber = convertToNumber(rate)
  const hasUnit = unit.length > 0
  const hasRate = rateToNumber !== 0 && rateToNumber !== null

  // If unit and cost exist
  if(hasUnit && hasRate) 
    return `${name} ${t("form.label.meBreakdownDropdownUnitCostLabel", {unit, cost: formatMoney(rateToNumber)})}`

  // If only cost exists 
  if(hasRate) 
    return `${name} ${t("form.label.meBreakdownDropdownCostLabel", {cost: formatMoney(rateToNumber)})}`

  // If only unit exits
  if(hasUnit) 
    return `${name} ${t("form.label.meBreakdownDropdownUnitLabel", {unit})}`

  // If neither unit nor cost exists
  return name
}

export default function AddMaterialEquipmentToBreakdown(props: AddMaterialEquipmentToBreakdownProps) {
  const { addRates, data, dropdownItems, setData, setFormActive, type } = props
  const { t } = useTranslation("private")
  const typeOptions = useMemo(() => {
    return dropdownItems.map((item: any) => ({
      label: dropdownLabel(item.name, item.unit, item.rate, t),
      value: item.id,
    }))
  }, [dropdownItems, t])

  return (
    <div>
      <Formik
        initialValues={defaultAddMaterialEquipmentBreakdownInitialValues()}
        validationSchema={
          addRates
            ? Yup.object().shape({
                type_name: Yup.string().required(t("form.message.typeNameRequired")),
                quantity: Yup.number()
                  .required(t("form.message.quantityRequired"))
                  .moreThan(0, t("form.message.greaterThanZero")),
                unit: Yup.string().required(t("form.message.unitRequired")),
                rate: Yup.string().required(t("form.message.rateRequired")),
              })
            : Yup.object().shape({
                type_name: Yup.string().required(t("form.message.typeNameRequired")),
                quantity: Yup.number()
                  .required(t("form.message.quantityRequired"))
                  .moreThan(0, t("form.message.greaterThanZero")),
                unit: Yup.string().required(t("form.message.unitRequired")),
              })
        }
        onSubmit={(values, { resetForm }) => {
          // Determine display values
          values.total_cost = (convertToNumber(values.quantity) as number) * (convertToNumber(values.rate) as number)
          // Pass up to parent
          setData(`${type}Breakdown`, [...data, { ...values }])
          resetForm()
          setFormActive(false)
        }}
      >
        {({
          errors,
          handleBlur,
          touched,
          values,
          setFieldValue,
          resetForm,
          submitForm,
          handleChange,
        }) => {
          return (
            <Form>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(_, newValue: any) => {
                      // Will only execute when user chooses an existing type
                      if(newValue && newValue.value) {
                        setFieldValue("type_id", newValue.value)
                        const selectedItem = dropdownItems.find(({ id }) => id === newValue.value)
                        setFieldValue("type_name", selectedItem.name)
                        setFieldValue("cost_code", selectedItem.cost_code)
                        setFieldValue("unit", selectedItem.unit)
                        setFieldValue("rate", selectedItem.rate)
                      }
                    }}
                    disableClearable
                    freeSolo
                    options={typeOptions}
                    renderInput={(params) => {
                      return <TextField {...params}
                        onChange={(e: any) => {
                          if(e.target.value.length > 0){
                            // Update all fields if there is an exact match
                            const selectedItem = dropdownItems.find((item) => 
                              dropdownLabel(item.name, item.unit, item.rate, t) === e.target.value
                                ? item
                                : null
                            )                            
                            if(selectedItem != null){
                              setFieldValue("type_name", selectedItem.name)
                              setFieldValue("type_id", selectedItem.id)
                              setFieldValue("cost_code", selectedItem.cost_code)
                              setFieldValue("unit", selectedItem.unit)
                              setFieldValue("rate", selectedItem.rate)
                            }
                            else{
                              setFieldValue("type_name", e.target.value)
                              setFieldValue("cost_code", "")
                              setFieldValue("type_id", "")
                            }
                          }
                          // Reset everything in case string is blank
                          else {
                            setFieldValue("type_name", e.target.value)
                            setFieldValue("type_id", "")
                            setFieldValue("cost_code", "")
                            setFieldValue("unit", "")
                            setFieldValue("quantity", "")
                            setFieldValue("rate", "0")
                          }
                        }}
                        label={t(`view.TicketBreakdowns.type`)}
                        error={Boolean(touched.type_name && errors.type_name)}
                        helperText={touched.type_name && errors.type_name}
                      />
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                    InputProps={{ inputProps: { min: 0 } }}
                    label={t("view.ChangeOrder.Material.quantity")}
                    name="quantity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.quantity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                    label={t("view.ChangeOrder.Material.unit")}
                    name="unit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unit}
                  />
                </Grid>
                {addRates ? (
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.rate && errors.rate)}
                      helperText={touched.rate && errors.rate}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label={t("view.ChangeOrder.Material.rate")}
                      name="rate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.rate}
                    />
                  </Grid>
                ) : (
                  ""
                )}

                <Grid container item xs={6} alignContent="center" justifyContent="flex-start">
                  <Button
                    color="secondary"
                    onClick={() => {
                      resetForm()
                      setFormActive(false)
                    }}
                    size="small"
                    variant="text"
                  >
                    {t("view.ChangeOrder.cancel")}
                  </Button>
                </Grid>

                <Grid container item xs={6} alignContent="center" justifyContent="flex-end">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      submitForm()
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
