import React, {useState} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {useSnackbar} from 'notistack'

//@ts-ignore
import Dialog from "../../../../../components/Dialog"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import DataGridAccordion from '../../../../../components/DataGridAccordion'
import {DataGridAccordionRowItem} from '../../../../../components/DataGridAccordionRow'
import {
  addProductionMetric,
  listProductionMetric,
  loadProductionMetric, updateProductionMetric,
} from '../../../../../store/features/productionMetricSlice'
import ProductionMetricColumns from './Production.columns'
import CreateEditProductionMetric, {
  CreateEditProductionMetricValues,
  createInitialValues,
} from '../../../../../forms/CreateEditProductionMetric'
import {createProductionMetric, editProductionMetric} from '../../../../../api/productionMetrics'
import {ProductionMetric} from '../../../../../types/productionMetric'


type DialogType = 'edit'

export default function Production() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const productionMetrics = useTracFloSelector(listProductionMetric)
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const [dialog, setDialog] = useState<DialogType | null>(null)
  const [metricToEdit, setMetricToEdit] = useState<ProductionMetric | null>(null)

  React.useEffect(() => {
    dispatch(loadProductionMetric())
  }, [company.id, project.id, dispatch])

  const closeDialog = () => {
    setDialog(null)
    setMetricToEdit(null)
  }

  const createMetric = async (values: CreateEditProductionMetricValues) => {
    try {
      const { data } = await createProductionMetric(project.id, values)
      if (!!data.id) {
        dispatch(addProductionMetric(data))
        enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.createSuccess"), {
          variant: "success",
        })
      } else {
        enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.createFail"), {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.createFail"), {
        variant: "error",
      })
    }
  }

  const editMetric = async (values: CreateEditProductionMetricValues) => {
    if (metricToEdit == null) {
      setDialog(null)
      return
    }
    try {
      const { data } = await editProductionMetric(project.id, metricToEdit.id, values)
      if (!!data.id) {
        dispatch(updateProductionMetric(data))
        enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.editSuccess"), {
          variant: "success",
        })
      } else {
        enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.editFail"), {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar(t("view.Settings.ProjectSettings.ProductionTab.editFail"), {
        variant: "error",
      })
    }
  }

  const setMetricToEditAndOpenDialog = (metric: ProductionMetric) => {
    setMetricToEdit(metric)
    setDialog('edit')
  }

  const accordion: DataGridAccordionRowItem = {
    title: t("view.Settings.ProjectSettings.ProductionTab.accordionTitle"),
    columns: ProductionMetricColumns({t, setMetricToEdit: setMetricToEditAndOpenDialog}),
    rows: productionMetrics,
  }

  if (
    (project.project_user_role === 'pm' || project.project_user_role === 'super')
    && (project.project_license_is_active)
  ) {
    accordion.addForm = {
      submitText: '', // not used
      openButtonText: t("view.Settings.ProjectSettings.ProductionTab.openCreateFormButtonText"),
      title: t("view.Settings.ProjectSettings.ProductionTab.createTitle"),
      props: {submit: createMetric},
      form: CreateEditProductionMetric,
    }
  }

  return (
    <>
      <DataGridAccordion items={[accordion]}/>

      <Dialog
        open={dialog === "edit"}
        onClose={closeDialog}
        title={t("view.Settings.ProjectSettings.ProductionTab.editDialogTitle")}
        maxWidth={"md"}
      >
        <CreateEditProductionMetric
          initialValues={createInitialValues(metricToEdit)}
          submit={editMetric}
          onCancel={closeDialog}
        />
      </Dialog>
    </>
  )
}
