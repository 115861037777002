import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import React from "react"

// @ts-ignore
import { formatMoney } from "../../../../../../../libs/format"
import { BreakdownTableColumn } from "../../../../../../../components/BreakdownTable.columns"

interface MaterialBreakdownTableTotalsProps {
  totals: any,
  columns: BreakdownTableColumn[],
  editable?: boolean,
  subtotalText?: string,
}

//Computes how many columns the Subtotal text cell must take up from left to right
function columnsSubtotalCellSpans(props: any){
  //Total number of columns in material breakdown table
  const allColumns = props.columns.filter((column: any) => !column.hide).length

  return (allColumns - 1)
}

export function MaterialBreakdownTableTotals(props: MaterialBreakdownTableTotalsProps) {
  return props.totals && Object.keys(props.totals).length ? (
    <TableRow key="totalRow">
      <TableCell
        align="right"
        colSpan={columnsSubtotalCellSpans(props)}
        key="subtotal"
        style={{ border: "none", fontWeight: 700 }}
      >
        {props.subtotalText}
      </TableCell>
      <TableCell
        align="right"
        colSpan={1}
        key="total"
        style={{ background: "#f1f1f1", border: "none", fontWeight: 700 }}
      >
        {formatMoney(props.totals.total)}
      </TableCell>

    </TableRow>
  ) : (
    <></>
  )
}
