import React from "react"

import {CompanyType} from "../../../../../../../types/company"
import {ActionCode} from "../../../../../../../types/action"
import ApproveRejectReviseButtons from "../../../../../../../components/ApproveRejectReviseButtons"

function showTheseButtons(companyType: CompanyType, ticketActionCode: ActionCode, hasAttachedCo: boolean): boolean {
  const showIfActionCode: ActionCode[] = ['cost_submitted']
  // Returns true if no attachedCo
  return companyType === 'cm' && showIfActionCode.includes(ticketActionCode) && !hasAttachedCo
}

interface CostApproveRejectReviseButtonsProps {
  toggleApproveForm: () => void,
  toggleRejectForm: () => void,
  toggleReviseForm: () => void,
  companyType: CompanyType,
  ticketActionCode: ActionCode,
  hasAttachedCo: boolean,
}

export default function CostApproveRejectReviseButtons(props: CostApproveRejectReviseButtonsProps) {
  return showTheseButtons(props.companyType, props.ticketActionCode, props.hasAttachedCo) ? (
    <ApproveRejectReviseButtons
      toggleApproveForm={props.toggleApproveForm}
      toggleRejectForm={props.toggleRejectForm}
      toggleReviseForm={props.toggleReviseForm}
    />
  ) : (<></>)
}
