import * as FS from "@fullstory/browser"
import config from "./config"
import {UserVars} from "@fullstory/browser"

export function startFullstory(): void {
  // NOTE this will not be initialized on local env unless the environment variable is added
  if (config.fullstory.orgId) {
    FS.init({orgId: config.fullstory.orgId})
  }
}

export function setFsUserInfo(userId: string, userVars: UserVars): void {
  if (FS.isInitialized()) {
    userVars.environment = config.env
    FS.identify(userId, userVars)
  }
}
