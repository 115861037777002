import React, {useMemo} from 'react'
import {Grid, LinearProgress, linearProgressClasses, Typography} from '@mui/material'

import {roundToDecimal} from '../util/number'
import {completedGreen} from '../theme'


type ProductionTrackingSummaryRowProgressBarProps = {
  current: number,
  total: number,
}

const unfilledProgressBarBackgroundColor = '#f5f5f5'
const filledProgressBarBackgroundColor = '#40C4FF'
const completedProgressBarBackgroundColor = completedGreen

function ProductionTrackingSummaryRowProgressBar(
  {
    current,
    total,
  }: ProductionTrackingSummaryRowProgressBarProps
): JSX.Element {
  // whole number representing %
  const progress = useMemo(() => {
    return current <= total
      ? roundToDecimal(100 * current / total, 0)
      : 100
  }, [current, total])

  const progressColor = useMemo(() => {
    return progress >= 100 ? completedProgressBarBackgroundColor : filledProgressBarBackgroundColor
  }, [progress])

  return (
    <Grid container spacing={1} alignContent={'center'} style={{height: '25px', marginTop: '25px'}}>
      <Grid item xs={11} style={{lineHeight: '0', height: '25px', paddingTop: '3px'}}>
        <LinearProgress
          variant={'determinate'}
          value={progress}
          sx={{
            height: 15,
            borderRadius: '4px',
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: unfilledProgressBarBackgroundColor
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: progressColor
            }
          }}
        />
      </Grid>
      <Grid item xs={1} style={{paddingTop: '0', lineHeight: '0', height: '25px'}}>
        <Typography variant={'h3'} style={{fontWeight: '800', margin: '0'}}>
          {`${progress.toString()}%`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export type ProductionTrackingSummaryMetrics = {
  current_amount: number,
  total_amount: number,
  unit: string,
  description: string,
  production_metric_id: string,
}

export type ProductionTrackingSummaryRowProps = {
  rowMetrics: ProductionTrackingSummaryMetrics
}

export function ProductionTrackingSummaryRow(
  {
    rowMetrics,
  }: ProductionTrackingSummaryRowProps
): JSX.Element {
  return (
    <>
      <ProductionTrackingSummaryRowProgressBar current={rowMetrics.current_amount} total={rowMetrics.total_amount}/>
      <Typography variant="h5" style={{marginTop: '0'}}>
        {`${rowMetrics.description}: ${rowMetrics.current_amount} / ${rowMetrics.total_amount} ${rowMetrics.unit}`}
      </Typography>
    </>
  )
}
