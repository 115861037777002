import React from "react"
import { Grid } from "@mui/material"
import UndoIcon from '@mui/icons-material/Undo';
import {useSnackbar} from "notistack"

// @ts-ignore
import Button from "../../../../../../../components/Button"
import {CompanyType} from "../../../../../../../types/company"
import {ActionCode} from "../../../../../../../types/action"
import { useTranslation } from "react-i18next"
import { submitBackToDraft} from "../../../../../../../api/ticketReviewProcess";


function showThisButton(companyType: CompanyType, ticketActionCode: ActionCode, hasAttachedCo: boolean): boolean {
  return companyType === 'trade' 
    && (ticketActionCode === 'tm_submitted' || ticketActionCode === 'cost_submitted')
    && !hasAttachedCo
}

interface BackToDraftProps {
  companyType: CompanyType,
  projectId: string,
  ticketId: string,
  ticketActionCode: ActionCode,
  ticketActionChange: (new_action_id: number) => void,
  hasAttachedCo: boolean,
}

export default function BackToDraftButton(props: BackToDraftProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [isDisabled, setIsDisabled] = React.useState(false)

  const toggleBackToDraft = async (): Promise<void> => {
    setIsDisabled(true)
    try {
      const {data: data } = await submitBackToDraft(props.ticketId, props.projectId)
      if (data?.new_action_id) {
        props.ticketActionChange(data.new_action_id)
        enqueueSnackbar(t('view.Tickets.back_to_draft_success'), {variant: "success"})
      }
      else {
        enqueueSnackbar(t('view.Tickets.back_to_draft_fail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.back_to_draft_fail'), {variant: "error"})
    }
    setIsDisabled(false)
  }

  return showThisButton(props.companyType, props.ticketActionCode, props.hasAttachedCo) ? (
    <>
      <Grid item>
        <Button
          disabled={isDisabled}
          endIcon={<UndoIcon/>}
          style={{ minWidth: 125, backgroundColor: "grey" }}
          onClick={() => {toggleBackToDraft()}}
        >
          {t("view.Tickets.back_to_draft")}
        </Button>
      </Grid>
    </>
  ) : (<></>)
}
