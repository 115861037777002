import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import {useHistory, useParams} from "react-router-dom"
import { useDispatch } from "react-redux"
import {useSnackbar} from "notistack"

// @ts-ignore
import Container from "../../../../../components/Container"
import {LaborBreakdown, TicketMarkup, EquipmentBreakdown, MaterialBreakdown} from "../../../../../types/ticket"
import {convertFormValuesToApiData} from "../../../../../forms/CreateDaily.submit"
import {goToSingleDaily,} from "../../../../../util/routes"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { getEquipmentTypes } from "../../../../../store/features/equipmentSlice"
import { getMaterialTypes } from "../../../../../store/features/materialSlice"
import CreateDaily from '../../../../../forms/CreateDaily'
import {initializeFormWithDailyData} from './EditDaily.data'
import {CreateDailyInitialValues} from '../../../../../forms/CreateDaily.types'
import {
  editDaily,
  getDailyById,
  getDailyEquipmentBreakdown,
  getDailyFiles,
  getDailyLaborBreakdown,
  getDailyMarkup,
  getDailyMaterialBreakdown,
  uploadDailyFiles,
} from '../../../../../api/daily'
import {Daily} from '../../../../../types/daily'
import {updateDaily} from '../../../../../store/features/dailySlice'
import {listProductionMetric} from '../../../../../store/features/productionMetricSlice'
import {getCustomCodes} from '../../../../../store/features/customCodeSlice'


const tName = 'view.Daily.EditDaily'

export default function EditDaily() {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const history = useHistory()
  const { dailyId, projectId } = useParams<{dailyId: string, projectId: string}>()
  const project = useTracFloSelector((state) => state.project)
  const materialTypes = useTracFloSelector(getMaterialTypes)
  const equipmentTypes = useTracFloSelector(getEquipmentTypes)
  const productionMetrics = useTracFloSelector(listProductionMetric)
  const customCodes = useTracFloSelector(getCustomCodes)
  const [daily, setDaily] = useState<Daily | null>(null)
  const [labor, setLabor] = useState<LaborBreakdown[] | null>(null)
  const [equipment, setEquipment] = useState<EquipmentBreakdown[] | null>(null)
  const [material, setMaterial] = useState<MaterialBreakdown[] | null>(null)
  const [markup, setMarkup] = useState<TicketMarkup[] | null>(null)
  const [files, setFiles] = useState<any[] | null>(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // NOTE: this needs to be updated if more ticket-related states are added
  const readyToInitialize: boolean = daily != null && labor != null && markup != null && files != null
    && project != null && equipment != null && material != null

  const submitDaily = async (values: CreateDailyInitialValues, nextDailyNumber: string | null) => {
    try {
      const { data, status } = await editDaily(
        convertFormValuesToApiData(values, nextDailyNumber),
        dailyId,
        projectId
      )
      if (status >= 400 || data?.id == null) {
        enqueueSnackbar(t(`${tName}.requestFailed`), {variant: "error",})
        return
      }
      const updatedDaily = data
      enqueueSnackbar(t(`${tName}.requestSucceeded`), {variant: "success",})
      dispatch(updateDaily({daily: updatedDaily, pms: productionMetrics, ccs: customCodes}))
      // need to check that files actually exist
      if (values.files.length > 0 && !!values.files[0].name) {
        const uploadingKey = enqueueSnackbar(t('Frequent.uploadingFiles'), {variant: 'info'})
        const fileUploadMessage = await uploadDailyFiles(values.files, project.id, updatedDaily.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      history.push(goToSingleDaily(project.id, updatedDaily.id))
    } catch(_) {
      enqueueSnackbar(t(`${tName}.requestFailed`), {variant: "error",})
    }
  }

  // Get the ticket info we need to populate the form
  useEffect(() => {
    getDailyById(projectId, dailyId).then(
      (res) => {
        if (res?.data?.length > 0) {
          setDaily(res.data[0])
        }
      }
    )

    getDailyMarkup(projectId, dailyId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setMarkup(res.data)
        }
      }
    )

    getDailyLaborBreakdown(projectId, dailyId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setLabor(res.data)
        }
      }
    )

    getDailyEquipmentBreakdown(projectId, dailyId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setEquipment(res.data)
        }
      }
    )

    getDailyMaterialBreakdown(projectId, dailyId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setMaterial(res.data)
        }
      }
    )

    getDailyFiles(projectId, dailyId).then(
      (res: any) => {
        if (Array.isArray(res?.data)) {
          setFiles(res.data)
        }
      }
    )
  }, [projectId, dailyId])

  return project?.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t(`${tName}.header`)}</Typography>
      </Container>

      { readyToInitialize
        ? <CreateDaily
          initialValues={
            initializeFormWithDailyData(
              // these are all guaranteed by readyToInitialize
              daily as Daily,
              markup as TicketMarkup[],
              labor as LaborBreakdown[],
              material as MaterialBreakdown[],
              equipment as EquipmentBreakdown[],
              materialTypes,
              equipmentTypes,
            )
          }
          submitValues={submitDaily}
          existingFiles={files as any[]}
          submitButtonText={t(`${tName}.submitButton`)}
          project = {project}
        /> : <></>
      }
    </>
  ) : <></>
}
