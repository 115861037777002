import React from "react"
import {useSnackbar} from "notistack"
import {useTranslation} from 'react-i18next'

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {ProjectClient} from "../../../../../../../api/ticketReviewProcess"
import {useTracFloSelector} from '../../../../../../../store/useTracFloSelector'
import EstimateSubmitForm, {EstimateSubmitFormValues} from './SubmitForm'
import {
  notifyForEstimateReview,
  submitEstimateForReview,
  uploadEstimateReviewProcessFiles,
} from '../../../../../../../api/estimateReviewProcess'


function createIdsToEmail(pmIds: string[], superIds: string[], userId: string | null, sendMeFlag: boolean): string[] {
  return (Array.isArray(pmIds) ? pmIds : []).concat(
    Array.isArray(superIds) ? superIds : []
  ).concat(
    sendMeFlag && userId != null ? [userId] : []
  )
}

interface SubmitContainerProps {
  toggleFormOpen: () => void,
  estimateId: string,
  estimateActionChange: (new_action_id: number) => void,
  projectId: string,
  clients: ProjectClient[],
}

export default function SubmitContainer(
  {estimateId, projectId, clients, toggleFormOpen, estimateActionChange}: SubmitContainerProps
): JSX.Element {
  const {t} = useTranslation('private')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const user = useTracFloSelector((state) => state.user)

  const submitValuesForReview = async (values: EstimateSubmitFormValues): Promise<void> => {
    try {
      const erpRes = await submitEstimateForReview(projectId, estimateId, {message: values.notes})
      if (erpRes.status === 200 && erpRes.data?.id != null) {
        const erp = erpRes.data
        // files
        const filesForUpload = values.files
        if (filesForUpload.length > 0 && !!filesForUpload[0].name) {
          const uploadingKey = enqueueSnackbar(
            t('form.label.uploadingFiles'),
            {variant: 'info', style: {whiteSpace: 'pre-wrap'}}
          )
          const fileUploadMessage = await uploadEstimateReviewProcessFiles(
            filesForUpload,
            projectId,
            erp.id
          )
          closeSnackbar(uploadingKey)
          if (fileUploadMessage.error) {
            enqueueSnackbar(fileUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
            return
          }
        }
        // notify
        const idsToEmail = createIdsToEmail(values.pmClients, values.superClients, user.id, values.sendMeCopy)
        await notifyForEstimateReview(projectId, erp.id, idsToEmail)
        estimateActionChange(erp.new_estimate_action_id)
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Submit.apiSuccess'), {variant: 'success'})
        toggleFormOpen()
      } else {
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Submit.apiFail'), {variant: 'error'})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Submit.apiFail'), {variant: 'error'})
    }
  }

  return (
    <FormSmallContainer>
      <EstimateSubmitForm
        toggle={toggleFormOpen}
        submitValues={submitValuesForReview}
        clients={clients}
      />
    </FormSmallContainer>
  )
}
