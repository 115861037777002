import React, { useEffect, useState} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt, useHistory } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {InputAdornment, InputLabel, Switch, Theme} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

// @ts-ignore
import Container from "../components/Container"
import FormWideButtons from "../components/FormWideButtons"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import FilesRow from "../components/FilesRow"
import {calculateTotalFileSizeInBytes, File} from "../types/file"
import {goToEstimateList} from '../util/routes'
import {CreateEstimateInitialValues, defaultCreateEstimateInitialValues} from './CreateEstimate.types'
import CreateEstimateLineItems from './CreateEstimateLineItems'
import CreateEstimateMarkup from './CreateEstimateMarkup'
import {formatMoney} from '../libs/format'
import { calculateEstimateSubtotal, calculateEstimateTotal } from "../util/estimate"
import { getNextEstimateNumber } from "../api/estimate"
import {DateField} from './components/DateField'


const fileUploadLimitInMB = 50


const useStyles = makeStyles<Theme>((theme) => ({
  total: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 20,
    paddingTop: 10
  }
}))

interface CreateEstimateProps {
  initialValues?: CreateEstimateInitialValues | null,
  projectId: string,
  submitValues: (
    output: CreateEstimateInitialValues,
    nextEstimateNumber: string|null
  ) => Promise<void>,
  existingFiles?: File[] | null,
  submitButtonText?: string,
}

export default function CreateEstimate(props: CreateEstimateProps) {
  const {initialValues, submitValues, existingFiles, submitButtonText, projectId,} = props
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation("private")
  const [nextEstimateNumber, setNextEstimateNumber] = useState<string|null>(null)

  useEffect(() => {
    if (projectId) {
      getNextEstimateNumber(projectId)
        .then((res) => {
          if(res.status === 200 && !!res.data.next_number){
            setNextEstimateNumber(res.data.next_number)
          }
        })
    }
  }, [projectId])

  useEffect(() => {
    window.onbeforeunload = function (_) {
      if (history.location.pathname.includes("estimate/add")) {
        return ""
      } else {
        return null
      }
    }
  }, [history.location.pathname])

  return (
    <Formik<CreateEstimateInitialValues>
      initialValues={initialValues ?? defaultCreateEstimateInitialValues()}
      validationSchema={Yup.object().shape({
        subject: Yup.string().min(2).required(t("view.Estimates.CreateEstimate.subjectRequired")),
        description: Yup.string().min(2).required(t("view.Estimates.CreateEstimate.descriptionRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await submitValues(values, nextEstimateNumber)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
        }) => {
        return (
          <Form>
            <Container removeTop>
              <FormWideContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t("view.Estimates.CreateEstimate.numberLabel")}
                      name="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.number}
                      placeholder={nextEstimateNumber ?? ""}
                    />
                  </Grid>
                  {/* intentionally blank grid for spacing */}
                  <Grid item xs={12} md={6}/>

                  <DateField
                    width={'half'}
                    label={t("view.Estimates.CreateEstimate.dateStartLabel")}
                    value={values.date_start}
                    maxDate={values.date_end ? new Date(values.date_end) : undefined}
                    onChange={(date) => {
                      setFieldValue("date_start", date)
                    }}
                  />

                  <DateField
                    width={'half'}
                    label={t("view.Estimates.CreateEstimate.dateEndLabel")}
                    value={values.date_end}
                    minDate={values.date_start ? new Date(values.date_start) : undefined}
                    onChange={(date) => {
                      setFieldValue("date_end", date)
                    }}
                  />

                  <Grid item xs={12} md={12}>
                    <TextField
                      error={touched.subject && errors.subject}
                      helperText={touched.subject && errors.subject}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                      label={t("view.Estimates.CreateEstimate.subjectLabel")}
                      name="subject"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaField
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                      label={t("view.Estimates.CreateEstimate.descriptionLabel")}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.notes")}
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {/* For editing, we show existing files here */
                      existingFiles && existingFiles.length > 0
                        ? <Grid item xs={12} sx={{paddingBottom: 4}}>
                          <Typography color="primary">Current Files:</Typography>
                          <FilesRow files={existingFiles}/>
                        </Grid>
                        : <></>
                    }

                    <UploadMultipleField
                      errors={errors}
                      label={t("form.label.uploadAttachments")}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      fileLimit={fileUploadLimitInMB}
                      existingFilesSizeInBytes={
                        existingFiles != null
                          ? calculateTotalFileSizeInBytes(existingFiles)
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputLabel>{t("form.label.addLumpSumTotal")}</InputLabel>
                    <Switch
                      name="is_lump_sum"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.is_lump_sum}
                    />
                  </Grid>

                  {values.is_lump_sum ? (
                    <Grid item xs={12} md={9}>
                      <TextField
                        label={t("form.label.total")}
                        name="lump_sum_total"
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          setFieldValue("lump_sum_total", event.target.value)
                        }}
                        type="number"
                        value={values.lump_sum_total}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                </Grid>
              </FormWideContainer>
            </Container>

            <CreateEstimateLineItems
              lineItems={values.line_items}
              setLineItems={(s) => setFieldValue('line_items', s)}
              showCosts={!values.is_lump_sum}
            />

            <CreateEstimateMarkup
              subtotal={calculateEstimateSubtotal(
                values.is_lump_sum,
                values.lump_sum_total ?? 0,
                values.line_items
              )}
              setFieldValues={setFieldValue}
              markup={values.markup}
              setMarkup={(m) => setFieldValue('markup', m)}
            />

            <Container>
              <FormWideContainer>
                <Grid item xs={12} justifyContent="flex-end" style={{display: 'flex'}}>
                  <Typography variant="body2" className={classes.total}>
                    {t('view.Estimates.CreateEstimate.totalLabel')}
                    {formatMoney(calculateEstimateTotal(
                      values.is_lump_sum,
                      values.lump_sum_total ?? 0,
                      values.line_items,
                      values.markup,
                    ))}
                  </Typography>
                </Grid>
              </FormWideContainer>
            </Container>

            <Prompt
              when={!isSubmitting}
              message={t('form.message.leavingPage')}
            />

            <FormWideButtons
              cancel={{
                action: () => {
                  history.push(goToEstimateList(projectId))
                },
                text: t("view.Estimates.CreateEstimate.cancelButton"),
              }}
              submit={{
                disabled: !isValid,
                isSubmitting: isSubmitting,
                text: submitButtonText ?? t("view.Estimates.CreateEstimate.addButton"),
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
