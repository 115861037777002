import React from "react"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

import { UsersTopButtons } from "./Users.TopButtons"
import { getUsersInCompany, removeUserFromCompany } from "../../../../api/users"
//@ts-ignore
import Dialog from "../../../../components/Dialog"
//@ts-ignore
import CreateInviteNewUser from "../../../../forms/CreateInviteNewUser"
//@ts-ignore
import UsersColumns from "../../../../libs/tableColumns/SettingsUsers"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import { CompanyUserRoleCode } from "../../../../types/userRoles"
import DataGridAccordion from '../../../../components/DataGridAccordion'
import ConfirmOrCancelPrompt from "../../../../components/ConfirmOrCancelPrompt"


type UsersDialogPrompts = "confirmCancelRemoveUserDialog" | "inviteUserForm" | null

export default function Users() {
  const theme = useTheme()
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [dialog, setDialog] = React.useState<UsersDialogPrompts>(null)
  const [companyUsers, setCompanyUsers] = React.useState<any[]>([])
  const company = useTracFloSelector((state) => state.company)
  const user = useTracFloSelector((state) => state.user)
  const [removeUserId, setRemoveUserId] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (!!company.id) {
      getUsersInCompany(company.id)
        .then((response: any) => {
          setCompanyUsers(response.data)
        })
        .catch((error: any) => {
          enqueueSnackbar("There was a problem getting the users associated with this company.", {
            variant: "error",
          })
        })
    }
  }, [company.id, enqueueSnackbar])

  const updateCompanyUsers = (newCompanyUser: any) => {
    setCompanyUsers([newCompanyUser].concat(companyUsers))
  }

  const userCanEditRow = (companyUserRole: CompanyUserRoleCode, userId: string, rowUserId: string) => {
    // admins can edit any row
    if (company.company_user_role === "admin") return true
    // users can edit their own row
    if (userId === rowUserId) return true
    return false
  }

  const userRow = (code: CompanyUserRoleCode) => {
    return companyUsers
      .filter((item) => item.company_user_role_code === code)
      .map((rowUser) => {
        return {
          id: rowUser.id,
          name: `${rowUser.first_name} ${rowUser.last_name}`,
          email: rowUser.email,
          phone: rowUser.phone,
          editable: userCanEditRow(company.company_user_role, user.id as string, rowUser.id),
        }
      })
  }

  const userTopButtons = UsersTopButtons(setDialog, company.company_user_role, theme, t)
  const canRemoveUserFromCompany = company.company_user_role === 'admin'

  const openConfirmCancelRemoveUserDialog = async (user_id: string): Promise<void> => {
    setRemoveUserId(user_id)
    setDialog("confirmCancelRemoveUserDialog")
  }
  
  const closeConfirmCancelRemoveUserDialog = () => {
    setDialog(null)
  }

  const removeUser = async () => {
    closeConfirmCancelRemoveUserDialog()
    if(removeUserId != null){
      try {
        const { data } = await removeUserFromCompany(company.id, removeUserId)
        if (data.success) {
          setCompanyUsers(companyUsers.filter((item) => item.id !== removeUserId)) // Remove the user once we have a successful call.
          enqueueSnackbar(t("view.Settings.removeCompanyUserSuccess"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.Settings.removeCompanyUserFail"), {
            variant: "error",
          })
        }
      } catch(_) {
        enqueueSnackbar(t("view.Settings.removeCompanyUserFail"), {
          variant: "error",
        })
      }
    }
  }


  const userItems = [
    {
      title: t("view.Settings.administrator"),
      columns: UsersColumns(canRemoveUserFromCompany, openConfirmCancelRemoveUserDialog, user.id),
      rows: userRow("admin"),
    },
    {
      title: t("view.Settings.super_intendent"),
      columns: UsersColumns(canRemoveUserFromCompany, openConfirmCancelRemoveUserDialog, user.id),
      rows: userRow("super"),
    },
  ]

  if (company.company_type === "trade") {
    userItems.push({
      title: t("view.Settings.Crew"),
      columns: UsersColumns(canRemoveUserFromCompany, openConfirmCancelRemoveUserDialog, user.id),
      rows: userRow("crew"),
    })
  }

  return (
    <>
      {company.company_user_role !== "crew" ? (
        <Box sx={{ mb: 3 }}>
          <Grid container alignItems="center" justifyContent="flex-end">
            {userTopButtons}
          </Grid>
        </Box>
      ) : (
        <></>
      )}
      <DataGridAccordion items={userItems}></DataGridAccordion>

      <Dialog
        open={dialog === "inviteUserForm"}
        onClose={() => setDialog(null)}
        title="Invite New User"
      >
        <CreateInviteNewUser
          onClose={() => setDialog(null)}
          updateCompanyUsers={updateCompanyUsers}
        />
      </Dialog>

      <Dialog
        open={dialog === "confirmCancelRemoveUserDialog"}
        onClose={closeConfirmCancelRemoveUserDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt 
          submitForm={removeUser} 
          closeDialog={closeConfirmCancelRemoveUserDialog} 
          dialogPrompt={t("view.Settings.removeCompanyUserPrompt")}
        />
      </Dialog>
    </>
  )
}
