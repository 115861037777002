import {roundToDecimal} from "../../util/number"
import {bytesInMB} from "../../util/file"

interface FileUpload {
  name: string,
  size?: number,
}

function currentFileSizeInBytes(currentFiles: FileUpload[], startAtBytes: number): number {
  return currentFiles.reduce(
    (total: number, file) => {
      if (!!file.name && !!file.size) {
        return total + file.size
      }
      return total
    }, startAtBytes
  )
}

export function overFileSizeLimit(
  fileSizeLimitInMB: number,
  newFile: FileUpload,
  currentFiles: FileUpload[],
  startAtBytes: number
): boolean {
  // Invalid new file
  if (!newFile.name || !newFile.size) return true
  const currentFileSize: number = currentFileSizeInBytes(currentFiles, startAtBytes)
  return (currentFileSize + newFile.size) > fileSizeLimitInMB * bytesInMB
}

export function fileLimitTrackingText(
  fileSizeLimitInMB: number | undefined,
  currentFiles: FileUpload[],
  startAtBytes: number,
  t: any
): string {
  if (fileSizeLimitInMB == null) return ""
  const currentFileSize: number = currentFileSizeInBytes(currentFiles, startAtBytes)
  return currentFileSize > 0 ? 
    t("form.label.current_file_size", {currentFileSize: roundToDecimal(currentFileSize / bytesInMB, 1), fileSizeLimitInMB})
    : t("form.label.file_limit", {fileSizeLimitInMB})
}
