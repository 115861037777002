import React from "react"
import {useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import
  UpdateInvoiceInformationForm
from "../../../../../../../forms/UpdateInvoiceInformationForm"
import { Ticket } from "../../../../../../../types/ticket"
import {
  convertTicketInvoiceInformationFieldsForApi,
  UpdateInvoiceInformationFormFields,
} from '../../../../../../../forms/UpdateInvoiceInformationForm.submit'
import {updateTicketInvoiceInformation} from '../../../../../../../api/ticket'


interface UpdateInvoiceInfoFormProps {
  toggleFormOpen: () => void,
  ticket: Ticket,
  projectId: string,
  ticketInvoiceChange: (date_invoice: string | null, invoice_number: string | null) => void,
}

export default function UpdateInvoiceInfoForm(
  {toggleFormOpen, ticket, projectId, ticketInvoiceChange}: UpdateInvoiceInfoFormProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()

  const submitForm = async (values: UpdateInvoiceInformationFormFields): Promise<void> => {
    try {
      const res = await updateTicketInvoiceInformation(
        projectId,
        ticket.id,
        convertTicketInvoiceInformationFieldsForApi(values)
      )
      if (res.status >= 400) {
        enqueueSnackbar(t('view.Tickets.UpdateInvoiceInfoForm.requestFailure'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Tickets.UpdateInvoiceInfoForm.requestSuccess'), {variant: 'success'})
      ticketInvoiceChange(res.data.date_invoice, res.data.invoice_number)
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.UpdateInvoiceInfoForm.requestFailure'), {variant: 'error'})
    }
    toggleFormOpen()
  }

  return (
    <FormSmallContainer>
      <UpdateInvoiceInformationForm
        onCancel={toggleFormOpen}
        submitForm={submitForm}
        invoiceInfo={{
          date_invoice: ticket.date_invoice,
          invoice_number: ticket.invoice_number
        }}
      />
    </FormSmallContainer>
  )
}
