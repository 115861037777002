import {convertToNumber} from '../number'


export function processRateFieldForImport(rateStr: string, errTranslateKey: string): string {
  // blank means we return 0
  if (rateStr.length === 0) return '0'
  const useRateStr = rateStr.replace('$', '')
  // if not blank, need to ensure it is number-like
  if (convertToNumber(useRateStr) == null) throw new Error(errTranslateKey)
  return useRateStr
}
