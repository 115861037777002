import React from "react"
import { useTranslation } from "react-i18next"
import {GridColDef} from "@mui/x-data-grid"

import { formatDate } from "../../../../../libs/format"
import Fab from "../../../../../components/Fab"


export const IntegrationProjectColumns = (
  connectProject: (integrationProjectId: string) => void,
): GridColDef[] => {
  const { t } = useTranslation("private")

  return [
    {
      field: "number",
      headerName: "#",
      headerAlign: "left",
      align: "left",
      width: 80,
      editable: false,
    },
    {
      field: "name",
      flex: 1,
      headerName: t("view.Projects.ConnectToProjectDialog.nameCol").toUpperCase(),
      minWidth: 90,
      headerAlign: "left",
      align: "left",
      editable: false,
    },
    {
      field: "description",
      headerName: t("view.Projects.ConnectToProjectDialog.descCol").toUpperCase(),
      headerAlign: "center",
      align: "center",
      width: 90,
      editable: false,
      filterable: false,
      hideable: false,
      sortable: false,
    },
    {
      field: "start_date",
      width: 100,
      headerName: t("view.Projects.ConnectToProjectDialog.startDateCol").toUpperCase(), //Start
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params: any) => formatDate(params.value),
    },
    {
      field: "short_address",
      flex: 1,
      headerName: t("view.Projects.ConnectToProjectDialog.addressCol").toUpperCase(),
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      editable: false,
    },
    {
      field: "connect",
      flex: 1,
      headerName: '',
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (params: any) => (
        <Fab
          // @ts-ignore
          onClick={() => connectProject(params.row.agave_id)}
          // @ts-ignore
          variant={'extended'}
          size={'medium'}
        >
          {t("view.Projects.ConnectToProjectDialog.connectButton")}
        </Fab>
      ),
    },
  ]
}
