import React from "react"

import FabLink, {FabLinkProps} from "./FabLink"
import FabDialog, {FabDialogProps} from "./FabDialog"
import ContactCustomerSupportDialog from "./ContactCustomerSupportDialog"


export interface FeatureAccessButtonProps {
  canAccessFeature: boolean,
  supportDialogFeatureDesc: string,
  supportDialogTitle: string
  linkProps?: FabLinkProps
  dialogProps?: FabDialogProps
}

// If the user does not have access to this feature, a dialog showing a message about contacting customer
//  support is shown. If they do have access:
//  - If the Fab is just a normal link, fill in the linkProps
//  - If the Fab is triggers a dialog component, fill in the dialogProps
//  - If neither is filled in, nothing is displayed
export default function FeatureAccessButton(props: FeatureAccessButtonProps) {
  const {
    canAccessFeature,
    supportDialogFeatureDesc,
    supportDialogTitle,
    linkProps,
    dialogProps
  } = props

  const canBeDisplayed = !!linkProps || !!dialogProps
  const buttonText: string = linkProps?.buttonText ?? dialogProps?.buttonText ?? ''
  const LeadingIconComponent: any = linkProps?.LeadingIconComponent ?? dialogProps?.LeadingIconComponent ?? null

  const DisplayFab = canBeDisplayed
    ? !!dialogProps
      ? <FabDialog {...dialogProps}/>
      : <FabLink {...(linkProps as FabLinkProps)}/>
    : <></>

  return canAccessFeature
    ? DisplayFab
    : (
      <ContactCustomerSupportDialog
        buttonText={buttonText}
        titleText={supportDialogTitle}
        featureDescription={supportDialogFeatureDesc}
        LeadingIconComponent={LeadingIconComponent}
      />
    )
}
