import React from "react"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {ActionInfo, ChangeOrderActionCode} from "../../../../../../../types/action"
import { submitForReview } from "./SubmitReviewForm.submit"
import ChangeOrderSubmitForm from "./ChangeOrderSubmitForm"
import {ProjectClient} from "../../../../../../../api/ticketReviewProcess"
import {useTracFloSelector} from '../../../../../../../store/useTracFloSelector'


interface SubmitReviewFormProps {
  toggleFormOpen: () => void,
  changeOrder: any,
  changeOrderActionInfo: ActionInfo
  changeOrderActionChange: (new_action_id: number) => void,
  projectId: string,
  clients: ProjectClient[],
}

export default function SubmitReviewForm(props: SubmitReviewFormProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const user = useTracFloSelector((state) => state.user)

  const submitValuesForReview = async (values: any): Promise<void> => {
    return submitForReview({
      values,
      userId: user.id,
      projectId: props.projectId,
      changeOrderId: props.changeOrder.id,
      enqSnackbar: enqueueSnackbar,
      closeSnackbar,
      changeOrderActionChange: props.changeOrderActionChange,
    }).then(() => {
      props.toggleFormOpen()
    })
  }

  return (
    <FormSmallContainer>
      <ChangeOrderSubmitForm
        changeOrderActionCode={props.changeOrderActionInfo.code as ChangeOrderActionCode}
        toggle={props.toggleFormOpen}
        submitValues={submitValuesForReview}
        clients={props.clients}
      />
    </FormSmallContainer>
  )
}
