import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper"
import makeStyles from '@mui/styles/makeStyles'
import {Grid, Theme, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'

import {Project} from '../../../../../../types/project'
import BulletinSubcontractorsTable from './BulletinSubcontractorsTable'
import { File } from '../../../../../../types/file'
import { getBulletinFiles } from '../../../../../../api/bulletin'
import BulletinDetailsTopSection from '../../../../../../components/Invoice/BulletinDetailsTopSection'
import { 
  Bulletin, 
  bulletinActionIdEstimatesFinalized, 
  bulletinActionIdTmAuthorized 
} from '../../../../../../types/bulletin'
import { formatMoney } from '../../../../../../libs/format'
import { convertToNumber } from '../../../../../../util/number'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 1080,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    "@media print": {
      maxWidth: 9999,
      padding: 0,
    },
    totals: {
      paddingTop: 20,
    },
  },
}))

interface BulletinInvoiceProps {
  bulletin: Bulletin,
  project: Project,
}

export default function BulletinInvoice(
  {bulletin, project}: BulletinInvoiceProps
): JSX.Element {
  const classes = useStyles()
  const [bulletinFiles, setBulletinFiles] = useState<File[]>([])
  const { t } = useTranslation("private")
  const actualCostShownStatuses = [bulletinActionIdEstimatesFinalized, bulletinActionIdTmAuthorized]
  const actualCost = formatMoney(convertToNumber(bulletin.total_actual_amount ?? 0) ?? 0)
  const totalApprovedCostImpact = formatMoney(convertToNumber(bulletin.total_amount_approved ?? 0) ?? 0)

  useEffect(() => {
    // get files for this bulletin
    getBulletinFiles(project.id, bulletin.id).then(
      (res) => {
        if (res?.data?.length > 0) {
          setBulletinFiles(res.data)
        }
      }
    )
  }, [bulletin.id, project.id])


  return bulletin.id && project.id
    ? (
      <Paper className={classes.root} elevation={2}>
        <BulletinDetailsTopSection files={bulletinFiles} bulletin={bulletin}/>

        <BulletinSubcontractorsTable
          bulletinId={bulletin.id}
          projectId={project.id}
          bulletinActionId={bulletin.bulletin_action_id}
        />

        {/*  Total Approved Cost Impact */}
        <Grid container justifyContent={"flex-end"} style={{marginTop: 10}}>
          <Grid item>
            <Typography style={{ fontWeight: 800, fontSize: 20}}>
              {t("view.Bulletins.SingleBulletin.totalApprovedCostImpact", {totalApprovedCostImpact})}
            </Typography>
          </Grid>
        </Grid>

        {/* Actual Cost */}
        {/* Should only be shown if estimates approved or owner approved */}
        { actualCostShownStatuses.includes(bulletin.bulletin_action_id)
          ? <Grid container justifyContent={"flex-end"} style={{marginTop: 10}}>
            <Grid item>
              <Typography style={{ fontWeight: 800, fontSize: 20}}>
                {t("view.Bulletins.SingleBulletin.actualCost", {actualCost})}
              </Typography>
            </Grid>
          </Grid>
          : <></>
        }
      </Paper>
    )
    : <></>
}
