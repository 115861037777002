import {Ticket} from "../../../../../types/ticket"
import {convertToNumber} from "../../../../../util/number"
import {ActionInfo} from "../../../../../types/action"
import { sortObjsContainingDateField } from "../../../../../util/time"
import { addCurrentMonthIfMissing, fillInMissingMonths } from "../chartDataUtils"
import { SupportedDateLanguages } from "../../../../../types/language"


interface TicketMetricsCards {
  tmReviewCount: number,
  costReviewCount: number,
  open: {
    count: number,
    cost: number,
  },
  submitted: {
    count: number,
    cost: number,
  },
  gcCostApproved: {
    count: number,
    cost: number,
  },
  costApproved: {
    count: number,
    cost: number,
  },
}

interface MonthlyChartData {
  date: Date
  xAxisLabel: string,
}

export interface MonthlyTicketChartData extends MonthlyChartData {
  tixSubmittedCount: number,
  tixSubmittedCost: number,
  tixApprovedCount: number,
  tixApprovedCost: number,
}

interface TicketMetricsReducer extends TicketMetricsCards {
  monthlyTixSubmittedApproved: {[key: string]: MonthlyTicketChartData},
}

export interface TicketMetrics extends TicketMetricsCards {
  monthlyTixSubmittedApproved: MonthlyTicketChartData[],
}

function initializeTicketMetrics(): TicketMetricsReducer {
  return {
    tmReviewCount: 0,
    costReviewCount: 0,
    open: {
      count: 0,
      cost: 0,
    },
    submitted: {
      count: 0,
      cost: 0
    },
    gcCostApproved: {
      count: 0,
      cost: 0,
    },
    costApproved: {
      count: 0,
      cost: 0,
    },
    monthlyTixSubmittedApproved: {},
  }
}

const createMonthlyTicketChartData = (
  currentDate: Date,
  costData: {submitCost?: number, approveCost?: number} = {},
  languageDate: SupportedDateLanguages
): MonthlyTicketChartData => {
  return {
    date: new Date(currentDate.getFullYear(), currentDate.getMonth()),
    xAxisLabel: new Date(currentDate.getFullYear(), currentDate.getMonth())
      .toLocaleString(languageDate, { month: 'short', year: "numeric"}),
    tixSubmittedCount: costData.submitCost ? 1 : 0,
    tixSubmittedCost: costData.submitCost ?? 0,
    tixApprovedCount: costData.approveCost ? 1 : 0,
    tixApprovedCost: costData.approveCost ?? 0,
  }
}

export function calculateTicketMetrics(
  tickets: Ticket[],
  actionMapper: {[key: number]: ActionInfo},
  languageDate: SupportedDateLanguages
): TicketMetrics {
  const ticketMetrics = tickets.reduce<TicketMetricsReducer>(
    (metrics, t) => {
      const ticketCost = convertToNumber(t.total_amount ?? 0) ?? 0
      if (t.has_been_approved) {
        metrics.costApproved.count += 1
        metrics.costApproved.cost += ticketCost
      }
      if (t.date_cm_cost_approved != null) {
        // card
        metrics.gcCostApproved.count += 1
        metrics.gcCostApproved.cost += ticketCost
        // graphs
        const dateApproved = new Date(t.date_cm_cost_approved)
        const monthApproved = `${dateApproved.getFullYear()}-${dateApproved.getMonth() + 1}`
        // if we have it, then we increment metrics
        if (!!metrics.monthlyTixSubmittedApproved[monthApproved]) {
          metrics.monthlyTixSubmittedApproved[monthApproved].tixApprovedCount += 1
          metrics.monthlyTixSubmittedApproved[monthApproved].tixApprovedCost += ticketCost ?? 0
        } else {
          // initialize if we dont have it already
          metrics.monthlyTixSubmittedApproved[monthApproved] = createMonthlyTicketChartData(
            dateApproved, {approveCost: ticketCost}, languageDate
          )
        }
      }
      if (!!t.first_submit_date) {
        metrics.submitted.count += 1
        metrics.submitted.cost += ticketCost

        const dateSubmitted = new Date(t.first_submit_date)
        const monthSubmitted = `${dateSubmitted.getFullYear()}-${dateSubmitted.getMonth() + 1}`

        // if we have it, then we increment metrics
        if (!!metrics.monthlyTixSubmittedApproved[monthSubmitted]) {
          metrics.monthlyTixSubmittedApproved[monthSubmitted].tixSubmittedCount += 1
          metrics.monthlyTixSubmittedApproved[monthSubmitted].tixSubmittedCost += ticketCost
        } else {
          // initialize if we dont have it already
          metrics.monthlyTixSubmittedApproved[monthSubmitted] = createMonthlyTicketChartData(
            dateSubmitted, {submitCost: ticketCost}, languageDate
          )
        }
      }
      if (!t.date_closed) {
        metrics.open.count += 1
        metrics.open.cost += ticketCost
      }
      if (actionMapper[t.action_id].code === 'tm_submitted' && !t.date_closed) {
        metrics.tmReviewCount += 1
      }
      if (actionMapper[t.action_id].code === 'cost_submitted' && !t.date_closed) {
        metrics.costReviewCount += 1
      }
      return metrics
    }, initializeTicketMetrics()
  )

  // Sort monthly metrics by date ascending
  const monthlyTixSubmittedApproved = Object.values(ticketMetrics.monthlyTixSubmittedApproved)
  monthlyTixSubmittedApproved.sort(sortObjsContainingDateField)
  // Check if current month is represented at the end of the list, if not, we should add it.
  addCurrentMonthIfMissing<MonthlyTicketChartData>(monthlyTixSubmittedApproved, createMonthlyTicketChartData, languageDate)

  return {
    tmReviewCount: ticketMetrics.tmReviewCount,
    costReviewCount: ticketMetrics.costReviewCount,
    open: ticketMetrics.open,
    submitted: ticketMetrics.submitted,
    gcCostApproved: ticketMetrics.gcCostApproved,
    costApproved: ticketMetrics.costApproved,
    monthlyTixSubmittedApproved: fillInMissingMonths<MonthlyTicketChartData>(
      monthlyTixSubmittedApproved, createMonthlyTicketChartData, languageDate
    ),
  }
}

