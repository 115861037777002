import React, {useEffect} from "react"
import { useDispatch } from "react-redux"
import {useTranslation} from "react-i18next"
import { useHistory } from "react-router-dom"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import {useSnackbar} from "notistack"

import AccountsLayout from "./Accounts.layout"
import { resetChangeOrders } from "../../store/features/changeOrdersSlice"
import { resetCompany, setCompany } from "../../store/features/companySlice"
import { loadEquipment, resetEquipment } from "../../store/features/equipmentSlice"
import { resetLabor } from "../../store/features/laborSlice"
import { loadMaterial, resetMaterial } from "../../store/features/materialSlice"
import { resetProject } from "../../store/features/projectSlice"
import { resetTickets } from "../../store/features/ticketsSlice"
import {
  listUserCompanies,
  loadUserCompanies,
  loadUserProjects,
  resetUserProjects,
} from "../../store/features/userSlice"
import { useQuery } from "../../util/url"
import AccountsCompanyCard from "./AccountsCompanyCard"
import AccountsCreateCompany from "./AccountsCreateCompany"
import { goHome } from "../../util/routes"
import {useTracFloSelector} from "../../store/useTracFloSelector"
import { Company } from "../../types/company"
import {loadCustomCode, resetCustomCode} from '../../store/features/customCodeSlice'


const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    marginLeft: "auto",
  },
  container: {
    padding: theme.spacing(3),
  },
  logo: {
    margin: "0 auto",
    maxHeight: 100,
    maxWidth: 100,
    width: "90%",
  },
  title: {
    fontSize: 14,
    textAlign: "center",
    paddingTop: 10,
  },
}))

export default function Authenticated() {
  const dispatch = useDispatch<(a: any) => Promise<void>>()
  const history = useHistory()
  const classes: any = useStyles()
  const [
    projectsLoadedAfterSelectCompany,
    setProjectsLoadedAfterSelectCompany
  ] = React.useState(false)
  const companies = useTracFloSelector(listUserCompanies)
  const redirect = useQuery().get("redirect")
  const { t } = useTranslation("private")
  const {enqueueSnackbar} = useSnackbar()

  const setCurrentCompany = (newCompany: Company) => {
    dispatch(setCompany(newCompany))
    dispatch(loadUserProjects()).then(() => {
      setProjectsLoadedAfterSelectCompany(true)
    })
    dispatch(loadCustomCode())
    if(newCompany.company_type === "trade"){
      dispatch(loadEquipment())
      dispatch(loadMaterial())
    }
  }

  useEffect(() => {
    // let user know that they need to pick a company to be redirected correctly
    if (!!redirect && companies.length > 1) {
      enqueueSnackbar(t('view.Accounts.needToSelect'), {variant: 'info'})
    }
  }, [redirect, companies, t, enqueueSnackbar])

  useEffect(() => {
    dispatch(loadUserCompanies())
    dispatch(resetEquipment())
    dispatch(resetLabor())
    dispatch(resetMaterial())
    dispatch(resetTickets())
    dispatch(resetChangeOrders())
    dispatch(resetProject())
    dispatch(resetUserProjects())
    dispatch(resetCompany())
    dispatch(resetCustomCode())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companies && companies.length === 1) {
      setCurrentCompany(companies[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  useEffect(() => {
    if (projectsLoadedAfterSelectCompany) {
      const nextPage = redirect ?? goHome
      return history.push(nextPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsLoadedAfterSelectCompany])

  const showCreateCompany = companies && companies.length === 0

  const accountContents = companies
    // if we have companies, display them
    ? companies.length > 0
      ? companies.map((company, index) => (
        <AccountsCompanyCard key={company.id} company={company} index={index} onClickCompany={setCurrentCompany}/>
      ))
      // if user has zero companies, allowed to create one
      : <AccountsCreateCompany/>
    // waiting for companies to load
    : <></>

  return (
    <AccountsLayout
      topTitle={showCreateCompany ? t('view.Accounts.createCompanyTopTitle') : ''}
      bottomTitle={showCreateCompany ? t('view.Accounts.createCompanyBottomTitle') : ''}
    >
      <Grid className={classes.container} container spacing={3}>
        {accountContents}
      </Grid>
    </AccountsLayout>
  )
}
