import {convertStrToDate} from '../time'
import {formatDate} from '../../libs/format'

type GenericObject<T = any> = {[key: string]: T}

export type CsvHeader = {
  type: 'string' | 'number' | 'date' | 'timestamp'
  value: string,
}

function createCsvCellFromObject(obj: GenericObject, csvHeader: CsvHeader): string | number {
  const objValue = obj[csvHeader.value]
  if (objValue == null) {
    return ''
  }
  if (csvHeader.type === 'number') {
    if (typeof objValue === 'number' || typeof objValue === 'string') return objValue
    // if its not a number or string, then default to blank
    return ''
  }
  if (csvHeader.type === 'string') {
    if (typeof objValue === 'string') return `"${objValue}"`
    if (typeof objValue === 'number') return `"${objValue.toString()}"`
    if (objValue instanceof Date) return objValue.toISOString()
    // anything else defaults to blank
    return ''
  }
  if (csvHeader.type === 'date') {
    if (typeof objValue === 'string') {
      // attempt to convert to date
      if (convertStrToDate(objValue) == null) {
        return `"${objValue}"`
      }
      // format what we know is a valid date
      return formatDate(objValue)
    }
    if (objValue instanceof Date) return objValue.toDateString()
    return ''
  }
  if (csvHeader.type === 'timestamp') {
    if (typeof objValue === 'string') return `"${objValue}"`
    if (objValue instanceof Date) return objValue.toISOString()
    return ''
  }
  return ''
}

function convertObjectToString(obj: GenericObject, csvHeaders: CsvHeader[]): string {
  return csvHeaders.map((csvHeader) => createCsvCellFromObject(obj, csvHeader)).join(',')
}

function convertObjectListToCsv(objs: GenericObject[], csvHeaders: CsvHeader[]): string {
  // stringify headers
  const csvHeadersStr = csvHeaders.map((s) => `"${s.value}"`).join(',')
  // stringify rows, then join with new line
  return [csvHeadersStr].concat(
    objs.map((obj) => convertObjectToString(obj, csvHeaders))
  ).join(`\n`)
}

function downloadStringAsCsvFile(csvStr: string, filename: string): void {
  const blob = new Blob([csvStr], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

export function convertObjectListToCsvAndDownload(
  objs: GenericObject[],
  csvHeaders: CsvHeader[],
  filename: string,
): {success: boolean, error: string | null} {
  if (objs.length === 0) return {success: false, error: 'There is no data to convert to CSV.'}
  try {
    const csvString = convertObjectListToCsv(objs, csvHeaders)
    downloadStringAsCsvFile(csvString, filename)
    return {success: true, error: null}
  } catch {
    return {success: false, error: 'We were unable to convert the data to CSV.'}
  }
}
