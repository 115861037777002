import React from 'react'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {
  attachCodeToTicket,
  removeCodeFromTicket,
} from '../../../../../../api/ticket'
import {AttachCode} from '../../../../../../components/AttachCustomCode'


export type TicketAttachedCodeProps = {
  ticketId: string,
  ccIds: string[],
  attachCode: (ccId: string) => void,
  removeCode: (ccId: string) => void,
}

export function TicketAttachCode(
  {ccIds, ticketId, attachCode, removeCode}: TicketAttachedCodeProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const project = useTracFloSelector((state) => state.project)

  const attachCodeFromComponent = async (ccId: string) => {
    try {
      const res = await attachCodeToTicket(project.id, ticketId, ccId)
      if (res.status !== 200 || !res.data.custom_code_id) {
        enqueueSnackbar(t('view.Tickets.TicketAttachCode.attachFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Tickets.TicketAttachCode.attachSuccess'), {variant: 'success'})
      attachCode(res.data.custom_code_id)
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.TicketAttachCode.attachFail'), {variant: 'error'})
    }
  }

  const removeCodeFromComponent = async (ccId: string) => {
    try {
      const res = await removeCodeFromTicket(project.id, ticketId, ccId)
      if (res.status !== 200) {
        enqueueSnackbar(t('view.Tickets.TicketAttachCode.removeFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Tickets.TicketAttachCode.removeSuccess'), {variant: 'success'})
      removeCode(res.data.custom_code_id)
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.TicketAttachCode.removeFail'), {variant: 'error'})
    }
  }

  return (
    <AttachCode
      ccIds={ccIds}
      attachNewCode={attachCodeFromComponent}
      removeCode={removeCodeFromComponent}
    />
  )
}
