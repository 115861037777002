import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

//@ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import ScrollToLink from "../../../../../../components/ScrollToLink"
import {Daily} from '../../../../../../types/daily'
import DailyEditButton from './DailyEditButton'
import {updateDailyCode, updateDailyEstimate} from "../../../../../../store/features/dailySlice"
import { DailyAttachEstimate } from "./DailyAttachEstimate"
import DailyReviewButtons from "./DailyReviewButtons"
import DailyDownloadButton from './DailyDownloadButton'
import {DailyAttachCode} from './DailyAttachCode'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {getCustomCodes} from '../../../../../../store/features/customCodeSlice'


interface DailyReviewSectionProps {
  historyRef: any,
  projectId: string,
  daily: Daily,
  setDaily: (daily: Daily) => void,
}

export default function DailyReviewSection(
  {historyRef, projectId, daily, setDaily}: DailyReviewSectionProps
): JSX.Element {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const codes = useTracFloSelector(getCustomCodes)

  const attachEstimate = (estimate_id: string) => {
    setDaily({...daily, estimate_id})
    dispatch(updateDailyEstimate(
      {new_estimate_id: estimate_id, daily_id: daily.id}
    ))
  }

  const removeEstimate = () => {
    setDaily({...daily, estimate_id: null})
    dispatch(updateDailyEstimate(
      {new_estimate_id: null, daily_id: daily.id}
    ))
  }

  const attachCode = (ccId: string) => {
    const custom_code_ids = daily.custom_code_ids == null
      ? [ccId]
      : [...daily.custom_code_ids, ccId]
    setDaily({...daily, custom_code_ids})
    dispatch(updateDailyCode(
      {cc_ids: custom_code_ids, daily_id: daily.id, ccs: codes}
    ))
  }

  const removeCode = (ccId: string) => {
    const custom_code_ids = daily.custom_code_ids == null
      ? []
      : [...daily.custom_code_ids.filter((cc) => cc !== ccId)]
    setDaily({...daily, custom_code_ids})
    dispatch(updateDailyCode(
      {cc_ids: custom_code_ids, daily_id: daily.id, ccs: codes}
    ))
  }

  return (
    <>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6} md={7}>
            <Typography variant="h1">{t("view.Daily.SingleDaily.title")}</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginRight: -12, marginTop: -12, textAlign: "right" }}
          >
            <DailyDownloadButton projectId={projectId} dailyId={daily.id}/>

            <DailyEditButton projectId={projectId} dailyId={daily.id} dailyIsActive={daily.is_active}/>
          </Grid>

          <Grid item xs={12}>
            <DailyAttachEstimate
              estimate_id={daily.estimate_id}
              dailyId={daily.id}
              attachEstimate={attachEstimate}
              removeEstimate={removeEstimate}
            />
          </Grid>

          <Grid item xs={12}>
            <DailyAttachCode
              dailyId={daily.id}
              ccIds={daily.custom_code_ids ?? []}
              attachCode={attachCode}
              removeCode={removeCode}
            />
          </Grid>

          <Grid item xs={12}>
            <ScrollToLink targetRef={historyRef}>
              {t("view.Daily.SingleDaily.history")}
            </ScrollToLink>
          </Grid>
        </Grid>
      </Container>
      <DailyReviewButtons projectId={projectId} dailyId={daily.id}/>
    </>
  )
}
