export function splitArrayIntoGroups<T>(array: T[], batchAmount: number): T[][] {
  const returnArr: T[][] = []
  for (let i = 0; i < array.length; i += batchAmount) {
    returnArr.push(array.slice(i, i + batchAmount))
  }

  return returnArr
}

export function deleteAtIndex<T>(arr: T[], index: number): T[] {
  return arr.filter((_, i) => i !== index)
}
