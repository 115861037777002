import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { MaterialTotals, calculateMaterialTotals } from "../../../Tickets/SingleTicket/Breakdowns/MaterialBreakdown"
import { getDailyMaterialBreakdown } from "../../../../../../api/daily"
import MaterialBreakdownTable from "../../../Tickets/SingleTicket/Breakdowns/BreakdownTables/MaterialBreakdownTable"
import MaterialBreakdownColumns from "../../../Tickets/SingleTicket/Breakdowns/MaterialBreakdown.columns"
import { MaterialBreakdown, TicketMarkup } from "../../../../../../types/ticket"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"


interface DailyMaterialBreakdownProps {
  dailyId: string,
  projectId: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateMaterialSubtotal: (ls: number) => void,
}

export default function DailyMaterialBreakdown(
  {dailyId, projectId, addRates, markupRows, updateMaterialSubtotal}: DailyMaterialBreakdownProps
) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<MaterialTotals>({quantity: 0})
  const [rows, setRows] = useState<MaterialBreakdown[]>([])

  // Get material data
  useEffect(() => {
    getDailyMaterialBreakdown(projectId, dailyId).then(
      (res) => {
        setRows(res.data)
      } // TODO BETTER ERROR HANDLING
    )
  }, [dailyId, projectId])

  // Calculate table totals
  useEffect(() => {
    const materialTotals = calculateMaterialTotals(rows, addRates)
    if (addRates && materialTotals.total != null) {
      updateMaterialSubtotal(calculateTotalWithMarkups(materialTotals.total, markupRows))
    }
    setTotals(materialTotals)
  }, [addRates, rows, markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.Daily.SingleDaily.Breakdowns.Material.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialBreakdownTable
          addRates={addRates}
          columns={MaterialBreakdownColumns({addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.Daily.SingleDaily.Breakdowns.Material.materialSubtotal")}
        />
      </Grid>
      {addRates
        ? <InvoiceMarkup
          rows={markupRows}
          total={totals.total ?? 0}
          subTotalText={t("view.Daily.SingleDaily.Breakdowns.Material.markup")}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
