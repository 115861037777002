import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"

import { ProjectShareAndUploadFormSubmitValues } from "../../../../../../types/form"
import ShareAndUploadForm from "../../../../../../forms/ProjectShareAndUploadForm"
import { submitShareBulletin } from "./ShareBulletinForm.submit"


interface ShareBulletinFormProps {
  projectId: string
  bulletinId: string,
  toggleFormOpen: () => void,
}

export default function ShareBulletinForm(
  {projectId, bulletinId, toggleFormOpen}: ShareBulletinFormProps
): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation("private")

  const submitForm = async (values: ProjectShareAndUploadFormSubmitValues): Promise<void> => {
    await submitShareBulletin({
      values,
      bulletinId,
      projectId,
      t,
      enqueueSnackbar,
      closeSnackbar,
    })   
    toggleFormOpen()
  }

  return (
    <ShareAndUploadForm
      projectId={projectId}
      toggleFormOpen={() => toggleFormOpen()}
      submitValues={submitForm}
    />
  )
}
