import { ExpirationField } from "./expiration"
import { LoadingStatus } from "./status"
import { StoreBase } from "./storeBase"


export interface Estimate {
  id: string,
  bulletin_id: string,
  estimate_action_id: number,
  number: string,
  notes: string | null,
  description: string,
  subject: string,
  date_created: string,
  date_modified: string,
  date_start: string | null,
  date_end: string | null,
  total_amount: string | null,
  inbox_first_name: string,
  inbox_last_name: string,
  pco_number: string | null,
  author: string,
  is_closed: boolean,
  is_lump_sum: boolean,
  lump_sum_total: string | number | null,
  total_actual_amount: string | null,
  total_sub_actual_amount: string | null,
}

export const estimateDraftActionStatusId: number = 2

export type EstimatesStore = {items: Estimate[], projectId: string | null} & StoreBase<LoadingStatus, ExpirationField>
