import {TFunction} from 'react-i18next'

import { StoreBase } from "./storeBase"
import {LoadingStatus} from "./status"
import {CompanyType} from './company'

export type ActionCode = 'draft'
  | 'tm_submitted' | 'tm_approve' | 'tm_reject' | 'tm_revise'
  | 'cost_submitted' | 'cost_reject' | 'cost_revise'
  | 'cost_reviewed' | 'cost_owner' | 'cost_approve'

export const ticketActionIdCostApproveStatus = 9
// not actually in database, used for sorting statuses
export const ticketActionIdCoAttached = 999

export type ChangeOrderActionCode = 'draft' | 'approve' | 'submitted' | 'revise'

export const changeOrderActionIdRejectStatus = 5

export type BulletinActionCode = 'draft' | 'review' | 'estimates_approved' | 'owner_approved' | 'not_needed'

export type EstimateActionCode = 'pending' | 'draft' | 'submitted' | 'revise' | 'gc_approved' | 'tm_authorized' | 'not_needed'

export interface ActionInfo<T = ActionCode | ChangeOrderActionCode | BulletinActionCode | EstimateActionCode> {
  code: T,
  id: number,
  name: string,
}

export type ActionsStore<T> = {
  items: ActionInfo<T>[],
  idObjectMapping: {[key: number]: ActionInfo<T>}
} & StoreBase<LoadingStatus>

export function getTicketActionLabel(
  t: TFunction<'private'>,
  code: ActionCode,
  company_type: CompanyType,
  coAttached?: boolean
): string {
  return coAttached
    ? t(`view.TicketAction.attached_to_co`)
    : t(`view.TicketAction.${code}.${company_type}`)
}

export function getChangeOrderActionLabel(
  t: TFunction<'private'>,
  code: ChangeOrderActionCode,
  company_type: CompanyType,
): string {
  return t(`view.ChangeOrderAction.${code}.${company_type}`)
}

export function getEstimateActionLabel(
  t: TFunction<'private'>,
  code: EstimateActionCode,
): string {
  return t(`component.EstimateAction.${code}`)
}

export function getBulletinActionLabel(
  t: TFunction<'private'>,
  code: BulletinActionCode,
): string {
  return t(`component.BulletinAction.${code}`)
}
