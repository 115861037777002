import React from "react"
import i18next from "i18next"
import { SnackbarProvider } from "notistack"
import ReactDOM from "react-dom"
import { I18nextProvider } from "react-i18next"
import { Provider as ReduxProvider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider, StyledEngineProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license-pro"

import App from "./App"
import store from "./store"
import theme from "./theme"
import SnackbarCloseButton from "./components/SnackbarCloseButton"
import {initializeI18next} from "./translations"


// MUI Pro license NOTE: INTENDED TO BE PUBLIC
LicenseInfo.setLicenseKey('79e37ab774d9f9dee6e0392b1ddcf6f4Tz04NDk3NSxFPTE3NDAzMzY0MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

let persistor = persistStore(store)

initializeI18next()

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <I18nextProvider i18n={i18next}>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Router>
                  <App />
                </Router>
              </PersistGate>
            </ReduxProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
