import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

//@ts-ignore
import AddUserForm from "../../../../../forms/AddUserForm"
//@ts-ignore
import api from "../../../../../libs/api"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { removeProjectUser } from "../../../../../api/users"
import { ProjectUsersColumns } from "../../../../../libs/tableColumns/ProjectUsers"
import DataGridAccordion from "../../../../../components/DataGridAccordion"


export default function Users() {
  const { t } = useTranslation("private")
  const [projectUsers, setProjectUsers] = React.useState<any[]>([])
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const { enqueueSnackbar } = useSnackbar()

  const {pms, supers, crew} = useMemo(() => {
    return {
      pms: projectUsers.filter((item) => item.project_user_role_code === "pm"),
      supers: projectUsers.filter((item) => item.project_user_role_code === "super"),
      crew: projectUsers.filter((item) => item.project_user_role_code === "crew")
    }
  }, [projectUsers])

  React.useEffect(() => {
    if (!!project.id) {
      const projectUsersURL = `/project/${project.id}/users`
      api({
        method: "get",
        url: projectUsersURL,
      })
        .then((response: any) => {
          if (Array.isArray(response.data)) {
            setProjectUsers(response.data)
          }
        })
        .catch(() => {
          enqueueSnackbar("There was a problem getting the users associated with this project.", {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id])

  const updateProjectUsers = (newProjectUser: any) => {
    setProjectUsers([newProjectUser].concat(projectUsers))
  }

  const removeUser = async (user_id: string) => {
    try {
      const { data } = await removeProjectUser(project.id, user_id)

      if (data.success) {
        setProjectUsers(projectUsers.filter((item) => item.id !== user_id)) // Remove the user once we have a successful call.
        enqueueSnackbar(t("view.Settings.remove_user_success"), {
          variant: "success",
        })
      } else {
        enqueueSnackbar(t("view.Settings.remove_user_fail"), {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar(t("view.Settings.remove_user_fail"), {
        variant: "error",
      })
    }
  }

  const canRemoveUsers = project.project_user_role !== "crew" && project.project_license_is_active
  const columns = ProjectUsersColumns(canRemoveUsers, removeUser)

  const userItems = [
    {
      title: t("view.Settings.active_pm"),
      columns,
      rows: pms,
      addForm:
        (
          (project.project_user_role === "pm" || project.project_user_role === "super")
          && project.project_license_is_active
        )
          ? {
            submitText: "Add Project Manager",
            openButtonText: "Project Manager",
            title: "Add Project Manager",
            form: AddUserForm,
            props: { project_user_role_code: "pm", updateProjectUsers },
          } : undefined,
    },

    {
      title: t("view.Settings.super_intendent"),
      columns,
      rows: supers,
      addForm:
        (
          (project.project_user_role === "pm" || project.project_user_role === "super")
          && project.project_license_is_active
        )
          ? {
            submitText: "Add Superintendent",
            openButtonText: "Superintendent",
            title: "Add Superintendent",
            form: AddUserForm,
            props: { project_user_role_code: "super", updateProjectUsers },
          } : undefined,
    },
  ]

  if (company.company_type === "trade") {
    userItems.push({
      title: t("view.Settings.active_crew"),
      columns,
      rows: crew,
      addForm: project.project_license_is_active
        ? {
          submitText: "Add Crew",
          openButtonText: "Crew",
          title: "Add Crew",
          form: AddUserForm,
          props: { project_user_role_code: "crew", updateProjectUsers },
        } : undefined,
    })
  }

  return (
    <DataGridAccordion items={userItems}/>
  )
}
