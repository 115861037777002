import React from "react"
import { Switch } from "react-router-dom"

import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import {goToBulletinList, goToCreateBulletin, goToEditBulletin, goToSingleBulletin} from "../../../../util/routes"
import BulletinList from './BulletinList'
import CreateBulletin from './CreateBulletin'
import SingleBulletinView from './SingleBulletin'
import EditBulletin from './EditBulletin'


export default function BulletinsRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToBulletinList(":projectId")}>
        <BulletinList />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToCreateBulletin(":projectId")}>
        <CreateBulletin />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToSingleBulletin(":projectId", ":bulletinId")}>
        <SingleBulletinView />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToEditBulletin(":projectId", ":bulletinId")}>
        <EditBulletin />
      </ProjectSelectedRouteGuard>
    </Switch>
  )
}
