import React from "react"
import {GridColDef} from "@mui/x-data-grid"

import {ActionInfo, ChangeOrderActionCode} from "../../../../../../types/action"
import ChangeOrderAction from "../../../../../../components/ChangeOrderActions"
import {goToSingleChangeOrder} from "../../../../../../util/routes"
import {formatMoney, formatDate} from '../../../../../../libs/format'
import FormatTableTitleLink from '../../../../../../components/FormatTableTitleLink'
import {AttachedCoAddsToTotalActionCode} from '../../../../../../types/estimateAttachedObjects'
import {ChangeOrder} from '../../../../../../types/changeOrder'

export default function AttachedCosListColumns (
  projectId: string,
  actionIdToInfo: {[key: number]: ActionInfo<ChangeOrderActionCode>},
  t: any,
): GridColDef<ChangeOrder>[] {
  return [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 110,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoSubjectColHeader"),
      width: 250,
      editable: false,
      renderCell: (params) => {
        return (
          <FormatTableTitleLink
            url={goToSingleChangeOrder(projectId, params.row.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      field: "first_submit_date",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoSubmittedColHeader"),
      renderCell: (params) => params.value ? formatDate(params.value) : '',
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 120,
      type: 'dateTime'
    },
    {
      align: "left",
      headerAlign: "center",
      field: "change_order_action_id",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoActionColHeader"),
      width: 180,
      editable: false,
      renderCell: (params) => {
        return <ChangeOrderAction
          code={actionIdToInfo[params.row.change_order_action_id].code}
        />
      },
      valueGetter: (params) => actionIdToInfo[params.value].name,
      type: 'string'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoTotalColHeader"),
      width: 150,
      editable: false,
      renderCell: (params) => {
        const countsTowardsActualTotal = AttachedCoAddsToTotalActionCode.includes(
          actionIdToInfo[params.row.change_order_action_id].code
        )
        return countsTowardsActualTotal
          ? formatMoney(params.value)
          : t('view.Estimates.SingleEstimate.AttachedObjects.attachedCoDoesNotCountTowardsTotal')
      },
    },
  ]
}
