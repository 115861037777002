import { useTracFloSelector } from "../store/useTracFloSelector"
import { SupportedLanguages } from "../types/language"
import { currentLanguageLocale } from "../util/language"

export function useLanguageLocale () {
  const userLanguage = useTracFloSelector((state) => state.user.language)

  return currentLanguageLocale(userLanguage)
}

export function useLanguageDate () {
  const userLanguage = useTracFloSelector((state) => state.user.language)

  return Object.values(SupportedLanguages).find((sl) => sl.value === userLanguage)?.date ?? "en-US"
}