import React, {useState, useEffect} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { EquipmentBreakdown, TicketMarkup } from "../../../../../../types/ticket"
import EquipmentBreakdownTable from "../../../Tickets/SingleTicket/Breakdowns/BreakdownTables/EquipmentBreakdownTable"
import EquipmentBreakdownColumns from "../../../Tickets/SingleTicket/Breakdowns/EquipmentBreakdown.columns"
import { getDailyEquipmentBreakdown } from "../../../../../../api/daily"
import { EquipmentTotals, calculateEquipmentTotals } from "../../../Tickets/SingleTicket/Breakdowns/EquipmentBreakdown"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"


interface DailyEquipmentBreakdownProps {
  dailyId: string,
  projectId: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateEquipmentSubtotal: (ls: number) => void,
}

export default function DailyEquipmentBreakdown(
  {dailyId, projectId, addRates, markupRows, updateEquipmentSubtotal}: DailyEquipmentBreakdownProps
) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<EquipmentTotals>({quantity: 0})
  const [rows, setRows] = useState<EquipmentBreakdown[]>([])

  // Get equipment data
  useEffect(() => {
    getDailyEquipmentBreakdown(projectId, dailyId).then(
      (res) => {
        setRows(res.data)
      } // TODO BETTER ERROR HANDLING
    )
  }, [dailyId, projectId])

  // Calculate table totals
  useEffect(() => {
    const equipmentTotals = calculateEquipmentTotals(rows, addRates)
    if (addRates && equipmentTotals.total != null) {
      updateEquipmentSubtotal(calculateTotalWithMarkups(equipmentTotals.total, markupRows))
    }
    setTotals(equipmentTotals)
  }, [addRates, rows, markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.Daily.SingleDaily.Breakdowns.Equipment.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <EquipmentBreakdownTable
          addRates={addRates}
          columns={EquipmentBreakdownColumns({addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.Daily.SingleDaily.Breakdowns.Equipment.equipmentSubtotal")}
        />
      </Grid>
      {addRates
        ? <InvoiceMarkup
          rows={markupRows}
          total={totals.total ?? 0}
          subTotalText={t("view.Daily.SingleDaily.Breakdowns.Equipment.markup")}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
