import React from "react"
import { Route, Redirect } from "react-router-dom"

import { useTracFloSelector } from "../../../store/useTracFloSelector"
import { goHome } from "../../../util/routes"

interface AdminGuardedRouteProps {
  Component: any,
  redirectLink?: string,
  path: string,
}

// Checks if user is a TracFlo admin
export default function AdminRouteGuard(props: AdminGuardedRouteProps) {
  const {Component, redirectLink=goHome, path} = props
  const user = useTracFloSelector((state) => state.user)
  const authorizedToViewComponent = user.is_admin

  return (
    <Route
      exact
      path={path}
      render={(renderProps) =>
        authorizedToViewComponent ? <Component {...renderProps} /> : <Redirect to={redirectLink} />
      }
    />
  )
}
