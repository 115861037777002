import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {
  BreakdownLinesApiInput
} from "../views/Authenticated/AccountSelected/ChangeOrders/SingleChangeOrder/PushToIntegration/PushToProcore/Pco/SelectWbsForm.data"

export interface ChangeOrderIntegration {
  id: string,
  user_project_integration_id: string,
  change_order_id: string,
  change_order_id_linked: string,
  change_order_id_source: string,
  date_created: string,
}

export async function createAgavePco(
  user_integration_id: string,
  project_id: string,
  co_id: string,
  contract_id: string,
): Promise<AxiosResponse<ChangeOrderIntegration>> {
  return api({
    method: "post",
    url: `/active-integration/${user_integration_id}/project/${project_id}/change_order/${co_id}/pco`,
    data: {contract_id},
  })
}

export async function createAgaveChangeEvent(
  user_integration_id: string,
  project_id: string,
  co_id: string,
  levelOfDetail: ChangeEventLineItemLevel
): Promise<AxiosResponse<ChangeOrderIntegration>> {
  return api({
    method: "post",
    url: `/active-integration/${user_integration_id}/project/${project_id}/change_order/${co_id}/change_event`,
    data: {levelOfDetail},
  })
}

export async function connectAgavePco(
  user_integration_id: string,
  project_id: string,
  change_order_id: string,
  change_order_id_linked: string,
): Promise<AxiosResponse<ChangeOrderIntegration>> {
  return api({
    method: "post",
    url: `/active-integration/${user_integration_id}/project/${project_id}/change_order/${change_order_id}/connect_change_order`,
    data: {change_order_id_linked},
  })
}

export async function connectChangeEvent(
  user_integration_id: string,
  project_id: string,
  co_id: string,
  change_order_id_linked: string,
): Promise<AxiosResponse<ChangeOrderIntegration>> {
  return api({
    method: "post",
    url: `/active-integration/${user_integration_id}/project/${project_id}/change_order/${co_id}/connect_change_event`,
    data: {change_order_id_linked},
  })
}

export async function addTfLineItemsToChangeOrder(
  coIntegrationId: string,
  oneLineItemWbs: string | null,
  wbs: BreakdownLinesApiInput | null
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'put',
    url: `/active-change-order-integration/${coIntegrationId}/line_items`,
    data: {oneLineItemWbs, wbs},
  })
}


// FROM API src/services/changeOrderIntegrationService.ts
export type ChangeEventLineItemLevel = 'oneLineItem' | 'allDetail'
export async function addTfLineItemsToChangeEvent(
  coIntegrationId: string,
  levelOfDetail: ChangeEventLineItemLevel
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'put',
    url: `/active-change-event-integration/${coIntegrationId}/line_items`,
    data: {levelOfDetail},
  })
}
