import React from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"

// @ts-ignore
import BreakdownTable from "../BreakdownTable"
// @ts-ignore
import MarkupColumns from "./Markup.columns"
import {TicketMarkup} from "../../types/ticket"
import {convertToNumber} from "../../util/number"

type TicketMarkupRow = TicketMarkup & {total: number}

function createMarkupRow(markups: TicketMarkup[], total: number): TicketMarkupRow[] {
  return markups.map((markup) => {
    return {
      ...markup,
      total: (convertToNumber(markup.percent) ?? 0) / 100 * total,
    }
  })
}

function calculateMarkupTotal(markupRows: TicketMarkupRow[]): number {
  return markupRows.reduce(
    (sum: number, markup) => {
      return sum + markup.total
    },
    0
  )
}

interface MarkupProps {
  rows: TicketMarkup[],
  total: number,
  subTotalText: string,
}

export default function Markup(props: MarkupProps) {
  const { t } = useTranslation("private")
  const columns = MarkupColumns({
    editable: false,
    handleDeleteRow: null,
    t
  })
  const rows = createMarkupRow(props.rows, props.total)

  return (
    <Grid item xs={12} container justifyContent="flex-end">
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6} container justifyContent="flex-end">
        <Grid item xs={12}>
          <BreakdownTable
            columns={columns}
            rows={rows}
            totals={{total: calculateMarkupTotal(rows)}}
            subtotalText={props.subTotalText}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
