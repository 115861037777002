import React from "react"
import { Switch } from "react-router-dom"

import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import {goToDailyList, goToCreateDaily, goToSingleDaily, goToEditDaily} from "../../../../util/routes"
import DailyList from './DailyList'
import CreateDaily from './CreateDaily'
import SingleDaily from './SingleDaily'
import EditDaily from './EditDaily'


export default function DailyRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToDailyList(":projectId")}>
        <DailyList />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToCreateDaily(":projectId")}>
        <CreateDaily />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToSingleDaily(":projectId", ":dailyId")}>
        <SingleDaily />
      </ProjectSelectedRouteGuard>
      <ProjectSelectedRouteGuard exact path={goToEditDaily(":projectId", ":dailyId")}>
        <EditDaily />
      </ProjectSelectedRouteGuard>
    </Switch>
  )
}
