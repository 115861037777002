import React from "react"
import {TFunction} from 'react-i18next'

import FormatTableTitle from "../components/FormatTableTitle"
import TinyTextField from "./TinyTextField"
import {BreakdownTableColumn} from '../components/BreakdownTable.columns'
import InputAdornment from '@mui/material/InputAdornment'



type CreateDailyProductionMetricsColumnsProps = {
  editable: boolean,
  handleAmountChange: (newAmount: number | string, pmId: string) => void,
  t: TFunction
}

export default function CreateDailyProductionMetricsColumns(
  {
    editable,
    handleAmountChange,
    t,
  }: CreateDailyProductionMetricsColumnsProps
): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t(`form.CreateDailyProductionMetrics.Columns.descriptionHeader`),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => <FormatTableTitle>{params.value}</FormatTableTitle>,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "amount",
      headerName: t("form.CreateDailyProductionMetrics.Columns.amountHeader"),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params: any) => {
        return editable
          ? (<TinyTextField
            fieldWidth={'150px'}
            value={params.value}
            handleChange={
              (newValue) => {
                handleAmountChange(newValue, params.production_metric_id)
              }
            }
            endAdornment={<InputAdornment position="start">{params.unit}</InputAdornment>}
            isNumber={true}
          />) : `${params.value} ${params.unit}`
      },
    },
  ]
}
