import React from "react"
import { Switch } from "react-router-dom"

import DashboardView from "./index"
import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import { goToProjectOverview } from "../../../../util/routes"


export default function DashboardRouter() {
  return(
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToProjectOverview(":projectId")}>
        <DashboardView />
      </ProjectSelectedRouteGuard>
    </Switch>
  )
}
