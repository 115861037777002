import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material"
import { useDispatch } from "react-redux"

// @ts-ignore
import Container from "../../../../../../components/Container"
import { getCompany } from "../../../../../../store/features/companySlice"
// @ts-ignore
import ScrollToLink from "../../../../../../components/ScrollToLink"
import ChangeOrderReviewButtons from "./ChangeOrderReviewButtons"
import ChangeOrderEditButton from "./ChangeOrderEditButton"
import {ActionInfo, ChangeOrderActionCode} from "../../../../../../types/action"
import BackToDraft from "./ChangeOrderBackToDraft"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import PushToIntegrationButton from "../PushToIntegration/button"
import {updateChangeOrderAction, updateChangeOrderEstimate} from "../../../../../../store/features/changeOrdersSlice"
import {ChangeOrderAttachEstimate} from './ChangeOrderAttachEstimate'
import {AttachEstimateToChangeOrderOutput} from '../../../../../../api/changeOrder'
import StatusField from '../../../../../../components/Invoice/StatusField'
import CoDownloadButton from './CoDownloadButton'


function changeOrderHasCorrectStatusToPushToIntegration(): boolean {
  // we allow users to push any CO
  return true
}

interface ChangeOrderReviewSectionProps {
  changeOrder: any,
  historyRef: any,
  projectId: string,
  setChangeOrder: (co: any) => void,
}

export default function ChangeOrderReviewSection(props: ChangeOrderReviewSectionProps): any {
  const {changeOrder, historyRef, projectId, setChangeOrder} = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const company = useTracFloSelector(getCompany)
  const project = useTracFloSelector((state) => state.project)
  const user = useTracFloSelector((state) => state.user)
  const { t } = useTranslation("private")
  const coActionIdObjMapping = useTracFloSelector((state) => state?.changeOrderAction?.idObjectMapping)
  const [changeOrderActionInfo, setChangeOrderActionInfo] = useState<ActionInfo<ChangeOrderActionCode> | null>(
    !!changeOrder && !!coActionIdObjMapping
      ? coActionIdObjMapping[changeOrder.change_order_action_id]
      : null
  )
  useEffect(() => {
    setChangeOrderActionInfo(
      !!changeOrder && !!coActionIdObjMapping
        ? coActionIdObjMapping[changeOrder.change_order_action_id]
        : null
    )
  }, [changeOrder, coActionIdObjMapping])

  function changeOrderActionChange(new_action_id: number) {
    if(coActionIdObjMapping[new_action_id]){
      setChangeOrderActionInfo(coActionIdObjMapping[new_action_id])
      dispatch(updateChangeOrderAction({new_action_id, co_id: props.changeOrder.id}))
    }
  }

  const attachEstimate = (output: AttachEstimateToChangeOrderOutput) => {
    setChangeOrder({...changeOrder, ...output})
    dispatch(updateChangeOrderEstimate(
      {new_estimate_id: output.estimate_id, new_pco_number: output.pco_number, co_id: props.changeOrder.id}
    ))
  }

  // Note: PCO number does not change when removing estimate
  const removeEstimate = () => {
    setChangeOrder({...changeOrder, estimate_id: null})
    dispatch(updateChangeOrderEstimate(
      {new_estimate_id: null, new_pco_number: changeOrder.pco_number, co_id: changeOrder.id}
    ))
  }

  return changeOrderActionInfo != null ? (
    <>
      <Container>
        <Grid container spacing={2} justifyContent={isMobile ? "center" : "space-between"}>
          <Grid item xs={6} md={7}>
            <Typography variant="h1">{t("view.ChangeOrder.changeOrder")}</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginRight: -12, marginTop: -12, textAlign: "right" }}
          >
            <CoDownloadButton projectId={projectId} coId={changeOrder.id}/>

            <ChangeOrderEditButton
              projectId={projectId}
              coId={changeOrder.id}
              companyType={company.company_type}
              changeOrderActionCode={changeOrderActionInfo.code}
              changeOrderLicenseIsActive={
                project?.project_license_is_active
                && project?.change_order_is_active
                // superadmins not allowed
                && !user.is_admin
              }
            />
            <BackToDraft
              companyType={company.company_type}
              projectId={props.projectId}
              changeOrderId={props.changeOrder.id}
              changeOrderActionCode={changeOrderActionInfo.code}
              changeOrderActionChange={changeOrderActionChange}
            />
            <PushToIntegrationButton
              allowedToPush={changeOrderHasCorrectStatusToPushToIntegration()}
              projectId={props.projectId}
              changeOrder={props.changeOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <StatusField isClosed={!!changeOrder && !!changeOrder.date_closed}/>

            <Typography>
              <strong>{t("view.ChangeOrder.Summary.action")}</strong>:{" "}
              {t(`view.ChangeOrderAction.${changeOrderActionInfo.code}.${company.company_type}`)}
            </Typography>

            <ChangeOrderAttachEstimate
              estimate_id={changeOrder.estimate_id}
              coId={changeOrder.id}
              attachEstimate={attachEstimate}
              removeEstimate={removeEstimate}
            />
          </Grid>
          <Grid item xs={12}>
            <ScrollToLink targetRef={historyRef}>
              {t("view.ChangeOrder.Summary.history")}
            </ScrollToLink>
          </Grid>
        </Grid>
      </Container>

      <ChangeOrderReviewButtons
        changeOrder={props.changeOrder}
        changeOrderActionInfo={changeOrderActionInfo}
        changeOrderActionChange={changeOrderActionChange}
        projectId={project.id}
      />
    </>
  ) : <></>
}
