import React, {useEffect, useState} from 'react'
import {Autocomplete, FormControlLabel, Grid, Radio, RadioGroup, TextField} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

// @ts-ignore
import Container from "../../../../../../../components/Container"
// @ts-ignore
import Button from "../../../../../../../components/Button"
import {ListOption} from "../../../../../../../types/form"
import {getAllCompanyProjects} from '../../../../../../../api/project'
import {importLaborTypeFromProject} from '../../../../../../../api/labor'

type IncludeRates = 't' | 'f'

interface ImportLaborFromProjectProps {
  projectId: string,
  importLaborTypesSuccessful: () => void,
}

export default function ImportLaborFromProject(
  {importLaborTypesSuccessful, projectId}: ImportLaborFromProjectProps
): JSX.Element {
  const [projects, setProjects] = useState<ListOption<string>[] | null>(null)
  const [includeRates, setIncludeRates] = useState<IncludeRates>('f')
  const [selectedProject, setSelectedProject] = useState<ListOption<string> | null>(null)
  const {t} = useTranslation('private')

  useEffect(() => {
    getAllCompanyProjects().then((res) => {
      if (Array.isArray(res.data)) {
        setProjects(
          res.data.map((p) => ({label: p.name, value: p.id}))
        )
      }
    })
  }, [])

  const selectIncludeRates = (_: any, selectedIncludeRates: string) => {
    if (selectedIncludeRates === 'f' || selectedIncludeRates === 't') {
      setIncludeRates(selectedIncludeRates)
    }
  }

  const selectProject = (_: any, selectedProj: ListOption<string> | null): void => {
    setSelectedProject(selectedProj)
  }

  const formIsValid = (): boolean => {
    return selectedProject != null
  }

  const submit = async (): Promise<void> => {
    const res = await importLaborTypeFromProject(
      projectId,
      {from_project_id: selectedProject?.value ?? '', import_rates: includeRates === 't'}
    )
    if (res.data?.success) {
      importLaborTypesSuccessful()
    }
  }

  const loadingProjectsMessage = <Typography variant="body1">
    {t("view.ProjectSettings.LaborTypes.ImportLaborDialog.loadingProjects")}
  </Typography>
  const noProjectsMessage = <Typography variant="body1">
    {t("view.ProjectSettings.LaborTypes.ImportLaborDialog.noProjects")}
  </Typography>

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid container item xs={10} md={10} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">
              {t("view.ProjectSettings.LaborTypes.ImportLaborDialog.includeRatesTitle")}
            </Typography>
            <RadioGroup onChange={selectIncludeRates} value={includeRates}>
              <FormControlLabel
                control={<Radio/>}
                label={t("view.ProjectSettings.LaborTypes.ImportLaborDialog.notIncludeRatesButton")}
                value={'f'}
              />
              <FormControlLabel
                control={<Radio/>}
                label={t("view.ProjectSettings.LaborTypes.ImportLaborDialog.includeRatesButton")}
                value={'t'}
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            {projects == null
              ? loadingProjectsMessage
              : projects.length === 0
              ? noProjectsMessage
              : <Autocomplete<ListOption<string> | null>
                onChange={selectProject}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    label={t("view.ProjectSettings.LaborTypes.ImportLaborDialog.selectProject")}
                    variant={'standard'}
                    {...params}
                  />
                )}
                options={projects}
              />
            }
          </Grid>

          <Grid container justifyContent="flex-end" item>
            <Button
              disabled={!formIsValid()}
              onClick={submit}
            >
              {t("view.ProjectSettings.LaborTypes.ImportLaborDialog.submitButton")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
