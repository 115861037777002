import React from "react"
import { GridColDef } from "@mui/x-data-grid"
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

//@ts-ignore
import DtRowTitle from "../../../../../components/DtRowTitle"
import { convertToNumber } from "../../../../../util/number"
import {ProductionMetric} from '../../../../../types/productionMetric'


interface ProductionMetricProps {
  t: (s: string) => string,
  setMetricToEdit: (metric: ProductionMetric) => void,
}

export default function ProductionMetricColumns(props: ProductionMetricProps ): GridColDef[] {
  const {t, setMetricToEdit} = props

  return [
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t('view.Settings.ProjectSettings.ProductionTab.descriptionColHeader'),
      width: 500,
      renderCell: (params: any) => (
        <DtRowTitle>{params.value}</DtRowTitle>
      ),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "unit",
      headerName: t('view.Settings.ProjectSettings.ProductionTab.unitColHeader'),
      minWidth: 100,
      editable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t('view.Settings.ProjectSettings.ProductionTab.totalColHeader'),
      width: 100,
      editable: false,
      renderCell: (params: any) => convertToNumber(params.value),
    },
    {
      align: "center",
      headerAlign: "right",
      field: "",
      headerName: " ",
      width: 50,
      editable: false,
      hideable: false,
      sortable: false,
      renderCell: (params: any) => (
        <IconButton
          color="secondary"
          onClick={() => {setMetricToEdit(params.row)}}
        >
          <EditIcon />
        </IconButton>
      ),
    }
  ]
}
