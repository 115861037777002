import React from "react"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

import {CompanyType} from "../../../../../../../types/company"
import {ChangeOrderActionCode} from "../../../../../../../types/action"
//@ts-ignore
import Button from "../../../../../../../components/Button"

function showTheseButtons(companyType: CompanyType, changeOrderActionCode: ChangeOrderActionCode): boolean {
  const showIfActionCode: ChangeOrderActionCode[] = ['submitted']
  return companyType === 'cm' && showIfActionCode.includes(changeOrderActionCode)
}

interface ApproveReviseButtonsProps {
  toggleApproveForm: () => void,
  toggleReviseForm: () => void,
  companyType: CompanyType,
  changeOrderActionCode: ChangeOrderActionCode,
}

export default function ApproveReviseButtons(props: ApproveReviseButtonsProps) {
  const { t } = useTranslation("private")
  return showTheseButtons(props.companyType, props.changeOrderActionCode) ? (
    <>
      <Grid item>
        <Button
          color="success"
          endIcon={<CheckBoxIcon />}
          style={{ minWidth: 125 }}
          onClick={props.toggleApproveForm}
        >
          {t("view.ChangeOrder.Summary.approve")}
        </Button>
      </Grid>

      <Grid item>
        <Button
          color="warning"
          onClick={props.toggleReviseForm}
        >
          {t("view.ChangeOrder.Summary.revise")}
        </Button>
      </Grid>
    </>
  ) : (<></>)
}
