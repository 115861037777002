import React from "react"
import DownloadIcon from '@mui/icons-material/Download'
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

import Fab from "../../../../../../components/Fab"
import { openTicketPdfInNewWindow } from "../../../../../../util/file"

interface TicketDownloadButtonProps {
  projectId: string,
  ticketId: string,
}

export default function TicketDownloadButton({projectId, ticketId}: TicketDownloadButtonProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return (
      <Fab
        // @ts-ignore
        edge="end"
        size="medium"
        style={{color: "white", backgroundColor: "#143366"}}
        variant="extended"
        onClick={async ()=> {await openTicketPdfInNewWindow(projectId, ticketId, enqueueSnackbar, closeSnackbar, t)}}
      >
        <DownloadIcon />
        {t("view.Tickets.download")}
      </Fab>
  )
}
