import React from "react"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Delete from "@mui/icons-material/Delete"

//@ts-ignore
import TextField from "../../../../../components/Fields/Text"
import {SubInvitee} from "../../../../../types/user"

interface AddSubRowProps {
  invitee: SubInvitee,
  deleteRow: () => void
}

const AddSubRow = (props: AddSubRowProps) => {
  const {
    invitee,
    deleteRow,
  } = props
  const theme = useTheme()

  return (
    <Stack gap={1} justifyContent="space-between" flexDirection="row" alignItems="center" mb={1}>
      <TextField
        type="text"
        value={invitee.invite_email}
        disabled
      />
      <TextField
        type="text"
        value={invitee.invite_first_name}
        disabled
      />
      <TextField
        type="text"
        value={invitee.invite_last_name}
        disabled
      />
      <TextField
        type="text"
        value={invitee.invite_company_name}
        disabled
      />
      <Grid
        item
        sx={{ color: theme.palette.secondary.main, cursor: "pointer", minWidth: 40 }}
        onClick={deleteRow}
      >
        <Delete />
      </Grid>
    </Stack>
  )
}

export default AddSubRow
