import Grid from "@mui/material/Grid"
import React, {useEffect, useMemo} from "react"
import {useHistory, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"
import {GridFilterItem, GridFilterModel, GridLinkOperator} from "@mui/x-data-grid"

// @ts-ignore
import Container from "../../../../../components/Container"
//@ts-ignore
import {reloadTicketsIfInvalid} from "../../../../../store/features/ticketsSlice"
import {useTracFloSelector} from "../../../../../store/useTracFloSelector"
import {calculateTicketMetrics, TicketMetrics} from "./TicketOverview.data"
import MetricCard from "../../../../../components/MetricCard"
import {formatMoney} from "../../../../../libs/format"
import { getTmReviewAvgResponseTime } from "../../../../../api/ticket"
import { roundToDecimal } from "../../../../../util/number"
import { secToDays } from "../../../../../util/time"
import { goToTicketsList } from "../../../../../util/routes"
import MetricCardGraph from "../../../../../components/MetricCardGraph"
import { lineGraphColor1, lineGraphColor2 } from "../../../../../theme"
import { useLanguageDate } from "../../../../../hook/languageLocale"


const inTmReviewFilter: GridFilterItem[] = [
  {
    columnField: 'action_id',
    operatorValue: 'equals',
    value: 'T&M Review'
  },
  {
    columnField: 'change_order_number',
    operatorValue: 'isEmpty',
  }
]

const inCostReviewFilter: GridFilterItem[] = [
  {
    columnField: 'action_id',
    operatorValue: 'equals',
    value: 'Cost Review'
  },
  {
    columnField: 'change_order_number',
    operatorValue: 'isEmpty',
  }
]

const openFilter: GridFilterItem[] = [
  {
    columnField: 'date_closed',
    operatorValue: 'isEmpty',
  },
]

const submittedFilter: GridFilterItem[] = [
  {
    columnField: 'first_submit_date',
    operatorValue: 'isNotEmpty',
  },
]

const gcCostApprovedFilter: GridFilterItem[] = [
  {
    columnField: 'date_cm_cost_approved',
    operatorValue: 'isNotEmpty',
  },
]

const approvedFilter: GridFilterItem[] = [
  {
    columnField: 'has_been_approved',
    operatorValue: 'is',
    value: 'true'
  },
]

export default function TicketOverview() {
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const tickets = useTracFloSelector((state) => state.tickets.items)
  const ticketsProjectId = useTracFloSelector((state) => state.tickets.projectId)
  const ticketExpiry = useTracFloSelector((state) => state.tickets.exp)
  const ticketActionCodeMapper = useTracFloSelector((state) => state.action.idObjectMapping)
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const history = useHistory<{filter: GridFilterModel}>()
  const [tmReviewAverageResponseTime, setTmReviewAverageResponseTime] = React.useState<number | null>(null)
  const [costReviewAverageResponseTime, setCostReviewAverageResponseTime] = React.useState<number | null>(null)

  const languageDate = useLanguageDate()

  const ticketMetrics = useMemo<TicketMetrics | null>(() => {
    if (!!tickets && !!ticketActionCodeMapper) {
      return calculateTicketMetrics(tickets, ticketActionCodeMapper, languageDate)
    }
    return null
  }, [tickets, ticketActionCodeMapper, languageDate])

  useEffect(() => {
    if (projectId && project && project.id === projectId) {
      reloadTicketsIfInvalid(projectId, ticketsProjectId, ticketExpiry, dispatch)
      getTmReviewAvgResponseTime(projectId).then(async (res) => {
        if (!!res.data) {
          setTmReviewAverageResponseTime(res.data.tm_response_time_in_sec)
          setCostReviewAverageResponseTime(res.data.cost_response_time_in_sec)
        }
      })
    }
  }, [projectId, project, dispatch, ticketsProjectId])

  return ticketMetrics != null
    ? (
      <>
        <Container fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.tm_review")}
                large_number={ticketMetrics.tmReviewCount.toString()}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: inTmReviewFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.tm_review_avg")}
                large_number={
                  tmReviewAverageResponseTime != null ?
                    roundToDecimal(tmReviewAverageResponseTime/secToDays, 0).toString() : '-'
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.cost_review")}
                large_number={ticketMetrics.costReviewCount.toString()}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: inCostReviewFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.cost_review_avg")}
                large_number={
                  costReviewAverageResponseTime != null ?
                    roundToDecimal(costReviewAverageResponseTime/secToDays, 0).toString()
                    : '-'
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.tix_open")}
                large_number={ticketMetrics.open.count.toString()}
                small_number={formatMoney(ticketMetrics.open.cost)}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: openFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.total_submitted")}
                large_number={ticketMetrics.submitted.count.toString()}
                small_number={formatMoney(ticketMetrics.submitted.cost)}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: submittedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.total_cm_cost_approved")}
                large_number={ticketMetrics.gcCostApproved.count.toString()}
                small_number={formatMoney(ticketMetrics.gcCostApproved.cost)}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: gcCostApprovedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MetricCard
                title={t("view.Dashboard.TicketCardTitles.total_cost_approved")}
                large_number={ticketMetrics.costApproved.count.toString()}
                small_number={formatMoney(ticketMetrics.costApproved.cost)}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: approvedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6}>
              <MetricCardGraph
                data={ticketMetrics.monthlyTixSubmittedApproved}
                title={t("view.Dashboard.Graphs.monthlySubmitted")}
                lines={
                  [
                    {
                      name: t("view.Dashboard.Graphs.tixCountSubmitted"),
                      dataKey: "tixSubmittedCount",
                      color: lineGraphColor1,
                    },
                    {
                      name: t("view.Dashboard.Graphs.tixCountApproved"),
                      dataKey: "tixApprovedCount",
                      color: lineGraphColor2,
                    },
                  ]
                }
                yAxisType={"number"}
              />
            </Grid>
            <Grid item xs={6}>
              <MetricCardGraph
                data={ticketMetrics.monthlyTixSubmittedApproved}
                title={t("view.Dashboard.Graphs.monthlyCost")}
                lines={
                  [
                    {
                      name: t("view.Dashboard.Graphs.tixCostSubmitted"),
                      dataKey: "tixSubmittedCost",
                      color: lineGraphColor1,
                    },
                    {
                      name: t("view.Dashboard.Graphs.tixCostApproved"),
                      dataKey: "tixApprovedCost",
                      color: lineGraphColor2,
                    },
                  ]
                }
                yAxisType={"money"}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    ) : <></>
}

