import React, {useEffect} from "react"
import { Route, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

// @ts-ignore
import {setProject} from "../../../store/features/projectSlice"
// @ts-ignore
import {resetStoreForProject} from "../../../store/resetStoreService"
import { useTracFloSelector } from "../../../store/useTracFloSelector"
import { goHome } from "../../../util/routes"

export default function ProjectSelectedRouteGuard({ children, ...rest }: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeProject = useTracFloSelector((state) => state.project)
  const projects = useTracFloSelector((state) => state.user.projects)
  // We do it this way instead of using useParams() because it hasn't populated the projectId yet
  const projectId = rest?.computedMatch?.params?.projectId

  useEffect(() => {
    if(!!projectId && !!projects) {
      // if active project is not the project in the URL
      if (activeProject?.id !== projectId) {
        // check if we have url project
        const newActiveProject = projects.find((p: {id: string}) => p.id === projectId)
        if (!!newActiveProject) {
          resetStoreForProject(dispatch)
          dispatch(setProject(newActiveProject))
        } else {
          history.push(goHome)
        }
      }
    }
  }, [projectId, activeProject, projects, dispatch, history])

  return (
    <Route {...rest}>
      {children}
    </Route>
  )
}
