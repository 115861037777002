import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"


export async function completeSubInvite(subInviteId: string): Promise<any> {
  return api({
    method: 'post',
    url: `/sub_invite/${subInviteId}`
  })
}

export async function addGcToSubProject(projectId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/add_gc_to_project/${projectId}`,
    data
  })
}

export async function addGcToSubProjectByEmail(
  projectId: string,
  data: {email: string}
): Promise<AxiosResponse<{success: boolean, message: string}>> {
  return api({
    method: 'post',
    url: `/add_gc_to_project_by_email/${projectId}`,
    data
  })
}
