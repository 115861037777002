import React, {useEffect, useState} from "react"

import {getChangeOrderHistory} from "../../../../../../api/changeOrder"
import HistoryList from "../../../../../../components/History"
import { HistoryItem } from "../../../../../../types/history"

interface ChangeOrderHistoryProps {
  change_order_id: string,
  project_id: string,
}

export default function ChangeOrderHistory(props: ChangeOrderHistoryProps) {
  const {project_id, change_order_id} = props
  const [changeOrderHistory, setChangeOrderHistory] = useState<HistoryItem[]>([])

  useEffect(() => {
    if (!!change_order_id && !!project_id) {
      getChangeOrderHistory(project_id, change_order_id).then(
        (res) => {
          if (res.data.length > 0) {
            setChangeOrderHistory(res.data)
          }
        } // TODO BETTER ERROR HANDLING
      )
    }
  }, [change_order_id, project_id])

  return changeOrderHistory.length > 0 ? (
    <HistoryList historyItems={changeOrderHistory} />
  ) : (<></>)
}
