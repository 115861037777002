import React from "react"

import ShareForm from "../../../../../../../forms/ProjectShareForm"
import { notifyForShare, submitTicketForShare } from "../../../../../../../api/ticketReviewProcess"
import { useSnackbar } from "notistack"
import { ProjectShareFormSubmitValues } from "../../../../../../../types/form"
import {attachTicketInvoiceForSubmit} from "./SubmitTmOrCostForm.submit"

interface shareTicketFormProps {
  projectId: string
  toggleFormOpen: () => void
  ticket: any,
}

export default function ShareTicketForm(props: shareTicketFormProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  async function shareTicket(values: ProjectShareFormSubmitValues){
    const res = await submitTicketForShare(
      props.projectId,
      props.ticket.id,
      values.notes
    )

    if (res == null || !res?.data?.id) {
      enqueueSnackbar(
        'Sorry, there was an issue submitting your request. Please try again later.',
        {variant: 'error'}
      )
      return
    }
    // attach invoice
    await attachTicketInvoiceForSubmit(res.data.id, props.projectId, enqueueSnackbar, closeSnackbar)
    // Then send emails
    notifyForShare(props.projectId, res.data.id, values.shareWith)
      .then((response: any) => {
        if (response.status === 200) {
            enqueueSnackbar(
              'Ticket was shared successfully',
              {variant: 'success'}
            )
            props.toggleFormOpen()
        } else {
            enqueueSnackbar(
              'There was an issue sending the email notification. Please try again later.',
              {variant: 'error'}
            )
        }
      })
  }


  return (
    <ShareForm
      projectId={props.projectId}
      toggleFormOpen={() => props.toggleFormOpen()}
      submitValues={shareTicket}
    />
  )
}
