import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid"
import { Location } from "history"

import { reduxStateExpired } from "../util/expiry"
import { getTableFilter, setTableFilter } from "../store/features/tableFilterSlice"
import { useTracFloSelector } from "../store/useTracFloSelector"
import { TableFilter } from "../types/tableFilter"

export function initLocalFilter(location: Location<{ filter?: GridFilterModel | undefined }>, globalStateFilter: TableFilter) {
  // if the location has a filter, then use that
  return location?.state?.filter
    ?? {
    // if we have a saved filter, and it hasn't expired yet, then use the filter, otherwise no filter
      items: globalStateFilter
        ? reduxStateExpired(globalStateFilter.exp)
          ? []
          : globalStateFilter.filterItems
        : []
      ,
      linkOperator: GridLinkOperator.And
    }
}

export function useFilter(tableName: string) {
  const dispatch = useDispatch()
  const location = useLocation<{filter?: GridFilterModel}>()

  // table filter setup
  const globalStateFilter = useTracFloSelector(getTableFilter(tableName))
  const prevGlobalStateFilter = useRef(globalStateFilter)
  const [localFilter, setLocalFilter] = useState<GridFilterModel>(initLocalFilter(location, globalStateFilter))

  useEffect(() => {
    // NOTE: this first case is added because global filter is not reset by changing projects until after the
    //  first render
    // if our global filter was reset, and we haven't reset local filter yet
    if (prevGlobalStateFilter.current != null && globalStateFilter == null && localFilter.items.length > 0) {
      // then reset filter
      setLocalFilter({items: [], linkOperator: GridLinkOperator.And})
    } else if (globalStateFilter?.filterItems !== localFilter.items) {
      // otherwise, update global filter state if they aren't the same list
      dispatch(setTableFilter({tableName, filterItems: localFilter.items}))
    }
  }, [localFilter.items, globalStateFilter, dispatch, prevGlobalStateFilter])

  return [localFilter, setLocalFilter]
}