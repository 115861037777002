import React from 'react'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {AttachCode} from '../../../../../../components/AttachCustomCode'
import {attachCodeToDaily, removeCodeFromDaily} from '../../../../../../api/daily'


export type DailyAttachedCodeProps = {
  dailyId: string,
  ccIds: string[],
  attachCode: (ccId: string) => void,
  removeCode: (ccId: string) => void,
}

export function DailyAttachCode(
  {ccIds, dailyId, attachCode, removeCode}: DailyAttachedCodeProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const project = useTracFloSelector((state) => state.project)

  const attachCodeFromComponent = async (ccId: string) => {
    try {
      const res = await attachCodeToDaily(project.id, dailyId, ccId)
      if (res.status !== 200 || !res.data.custom_code_id) {
        enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.attachFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.attachSuccess'), {variant: 'success'})
      attachCode(res.data.custom_code_id)
    } catch (_) {
      enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.attachFail'), {variant: 'error'})
    }
  }

  const removeCodeFromComponent = async (ccId: string) => {
    try {
      const res = await removeCodeFromDaily(project.id, dailyId, ccId)
      if (res.status !== 200) {
        enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.removeFail'), {variant: 'error'})
        return
      }
      enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.removeSuccess'), {variant: 'success'})
      removeCode(res.data.custom_code_id)
    } catch (_) {
      enqueueSnackbar(t('view.Daily.SingleDaily.AttachCode.removeFail'), {variant: 'error'})
    }
  }

  return (
    <AttachCode
      ccIds={ccIds}
      attachNewCode={attachCodeFromComponent}
      removeCode={removeCodeFromComponent}
    />
  )
}
