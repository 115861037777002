import { SupportedDateLanguages } from "../../../../types/language"
import { howManyMonthsBetweenDates } from "../../../../util/time"
import { MonthlyChartData } from "./ChangeOrderOverview/ChangeOrderOverview.data"

export function addCurrentMonthIfMissing<T extends MonthlyChartData>(
  monthlyData: T[], 
  createMonthlyData: (d: Date, costDate: {}, languageDate: SupportedDateLanguages) => T,
  languageDate: SupportedDateLanguages
) : void {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const monthlyDataIsEmptyOrNoCurrentMonth = monthlyData.length === 0
    || (monthlyData[monthlyData.length - 1].date.getMonth() !== currentMonth
      || monthlyData[monthlyData.length - 1].date.getFullYear() !== currentYear)

  if(monthlyDataIsEmptyOrNoCurrentMonth){
    monthlyData.push(createMonthlyData(currentDate, {}, languageDate))
  }
}

// Note: Throughout the function, zero-indexed months are used.
export function createFillerMonths<T extends MonthlyChartData>(
  startMonth: number, 
  startYear: number, 
  numOfMonthsToAdd: number, 
  createMonthlyChartData: (d: Date, costDate: {}, languageDate: SupportedDateLanguages) => T,
  languageDate: SupportedDateLanguages
): T[] {
  let returnArr: T[] = []
  // month and year counters that will be added to the missing months while we loop through the numOfMonthsToAdd 
  let monthCounter = startMonth
  let yearCounter = startYear

  for(let i = 0; i < numOfMonthsToAdd; i++){
    // If we reach to index 11 (Dec) make month equal 0 (Jan) and add a year
    if(monthCounter > 11){
      yearCounter += 1
      monthCounter = 0
    }
    returnArr.push(createMonthlyChartData(new Date(yearCounter, monthCounter), {}, languageDate))
    monthCounter += 1
  }
  return returnArr
}

// NOTE: monthlyDataWithHoles MUST be sorted ascending prior to function call
export function fillInMissingMonths<T extends MonthlyChartData>(
  monthlyDataWithHoles: T[], 
  createMonthlyChartData: (d: Date, costDate: {}, languageDate: SupportedDateLanguages) => T,
  languageDate: SupportedDateLanguages
): T[] {
  let returnArr: T[] = []
  // loop through each monthly item
  for(let index = 0; index < monthlyDataWithHoles.length; index++){
    returnArr.push(monthlyDataWithHoles[index])
    if(monthlyDataWithHoles[index+1] != undefined ){
      // determine number of missing months between two dates in monthlyDataWithHoles
      const amountOfMissingMonthsBetweenDates = howManyMonthsBetweenDates(
        monthlyDataWithHoles[index].date, monthlyDataWithHoles[index + 1].date
      )
      if(amountOfMissingMonthsBetweenDates > 0){
        // push the amount of missing months in between the two dates into returnArr
        returnArr.push(...createFillerMonths<T>( 
          monthlyDataWithHoles[index].date.getMonth() + 1,
          monthlyDataWithHoles[index].date.getFullYear(),
          amountOfMissingMonthsBetweenDates,
          createMonthlyChartData,
          languageDate
        ))
      }
    }
  }

  return returnArr
}