import React, {useState, useEffect} from "react"
import * as Yup from "yup"
import {Form, Formik} from "formik"
import Grid from "@mui/material/Grid"
import {useSnackbar} from "notistack"
import {useTranslation} from "react-i18next"

import {addGcToSubProject} from "../../../../../api/invite"
import {getGcUsers} from "../../../../../api/users"
// @ts-ignore
import Button from "../../../../../components/Button"
// @ts-ignore
import SelectField from "../../../../../components/Fields/Select"
// @ts-ignore
import FormSmallContainer from "../../../../../components/FormSmallContainer"
import {CompanyUserRoleCode} from "../../../../../types/userRoles"
import { getTracFloGcs } from "../../../../../api/company"

interface AddGcToSubProjectForm {
  gc_company_id: string,
  gc_project_manager_id: string,
}

interface AddGcToSubProjectProps {
  projectId: string,
  gcAddedToProject: () => void
}

export function AddGcToSubProject(props: AddGcToSubProjectProps) {
  const {projectId, gcAddedToProject} = props
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation('private')
  const [selectedGcId, setSelectedGcId] = useState<string>('')
  const [gcPmOptions, setGcPmOptions] = useState<{label: string, value: string}[]>([])
  const [gcDropdownOptions, setGcDropdownOptions] = useState<{label: string, value: string}[]>([])

  const getGcPmOptions = async (gcId: string): Promise<void> => {
    const res = await getGcUsers(projectId, gcId)
    if (res.data) {
      // Get users that qualify to be added as PMs
      const gcPms = res.data.filter(
        (u: {company_user_role_code: CompanyUserRoleCode}) => ['admin', 'super'].includes(u.company_user_role_code)
      )
      if (gcPms.length > 0) {
        // Then create options for form select
        setGcPmOptions(gcPms.map(
          (u: {first_name: string, last_name: string, id: string}) => ({
            label: `${u.first_name} ${u.last_name}`,
            value: u.id
          })
        ))
      } else {
        enqueueSnackbar(t('form.AddGcToProject.noGcUsers'), {variant: 'error'})
      }
    } else {
      enqueueSnackbar(t('form.AddGcToProject.issueGettingGcUsers'), {variant: 'warning'})
    }
  }

  useEffect(() => {
    if (selectedGcId.length > 0) {
      getGcPmOptions(selectedGcId).then(/*intentionally blank*/)
    } else {
      // reset
      setGcPmOptions([])
    }
  }, [selectedGcId])

  useEffect(() => {
    getTracFloGcs(projectId).then((res) => {
      if (res?.data?.length > 0) {
        const listOfTracFloGcs =  res.data
          .map((company: any) => ({
            label: company.name,
            value: company.id
          })
        )
        setGcDropdownOptions(listOfTracFloGcs)
      }
      else {
        enqueueSnackbar(t('form.AddGcToProject.issueGettingGcUsers'), {variant: 'warning'})
      }
    })
  }, [])

  const addGcToProject = async (formValues: AddGcToSubProjectForm): Promise<void> => {
    const res = await addGcToSubProject(projectId, formValues)
    if (res?.data?.success) {
      enqueueSnackbar(t('form.AddGcToProject.gcAdded'), {variant: 'success'})
      gcAddedToProject()
    } else {
      enqueueSnackbar(t('form.AddGcToProject.issueAddingGc'), {variant: 'error'})
    }
  }

  return (
    <Formik<AddGcToSubProjectForm>
      validateOnChange={false}
      initialValues={{gc_company_id: '', gc_project_manager_id: '',}}
      validationSchema={Yup.object().shape({
        gc_company_id: Yup.string().min(1).required(t('form.AddGcToProject.gcRequired')),
        gc_project_manager_id: Yup.string().min(1).required(t('form.AddGcToProject.gcPmRequired')),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        await addGcToProject(values)
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
        }) => {
        return (
          <Form>
            <FormSmallContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectField
                    error={Boolean(touched.gc_company_id && errors.gc_company_id)}
                    helperText={touched.gc_company_id && errors.gc_company_id}
                    label={t('form.AddGcToProject.gcLabel')}
                    name="gc_company_id"
                    onBlur={handleBlur}
                    onChange={(e: any) => {
                      handleChange(e)
                      setFieldValue('gc_project_manager_id', '')
                      setSelectedGcId(e.target.value)
                    }}
                    options={gcDropdownOptions}
                    value={values.gc_company_id}
                  />
                </Grid>
                {gcPmOptions.length > 0
                  ? <Grid item xs={12}>
                    <SelectField
                      error={Boolean(touched.gc_project_manager_id && errors.gc_project_manager_id)}
                      helperText={touched.gc_project_manager_id && errors.gc_project_manager_id}
                      label={t('form.AddGcToProject.gcPmLabel')}
                      name="gc_project_manager_id"
                      onBlur={handleBlur}
                      onChange={(e: any) => {handleChange(e)}}
                      options={gcPmOptions}
                      value={values.gc_project_manager_id}
                    />
                  </Grid>
                  : <></>
                }
              </Grid>
              <Grid container justifyContent="flex-end" item xs={6} style={{paddingTop: '15px'}}>
                <Button
                  disabled={!Object.keys(touched).length || !isValid}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('form.AddGcToProject.submit')}
                </Button>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
