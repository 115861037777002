import React, {useEffect, useMemo} from "react"
import {useParams, useHistory} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"
import Grid from "@mui/material/Grid"
import {GridFilterItem, GridFilterModel, GridLinkOperator} from "@mui/x-data-grid"

import {useTracFloSelector} from "../../../../../store/useTracFloSelector"
import MetricCard from "../../../../../components/MetricCard"
import {formatMoney} from "../../../../../libs/format"
// @ts-ignore
import Container from "../../../../../components/Container"
//@ts-ignore
import {reloadChangeOrdersIfInvalid} from "../../../../../store/features/changeOrdersSlice"
//@ts-ignore
import {reloadTicketsIfInvalid} from "../../../../../store/features/ticketsSlice"
import {calculateCOTicketsAttachedMetric,
  calculateChangeOrderMetrics,
  ChangeOrderMetrics
} from "./ChangeOrderOverview.data"
import { goToChangeOrdersList, goToTicketsList } from "../../../../../util/routes"
import { getCoCostReviewAvgResponseTime } from "../../../../../api/changeOrder"
import { roundToDecimal } from "../../../../../util/number"
import { secToDays } from "../../../../../util/time"
import MetricCardGraph from "../../../../../components/MetricCardGraph"
import { lineGraphColor1, lineGraphColor2 } from "../../../../../theme"
import { useLanguageDate } from "../../../../../hook/languageLocale"


const changeOrderTicketsAttachedFilter: GridFilterItem[] = [
  {
    columnField: 'change_order_number',
    operatorValue: 'isNotEmpty',
  }
]

const inCostReviewFilter: GridFilterItem[] = [
  {
    columnField: 'change_order_action_id',
    operatorValue: 'equals',
    value: 'Review'
  },
]

const openFilter: GridFilterItem[] = [
  {
    columnField: 'date_closed',
    operatorValue: 'isEmpty',
  },
]

const submittedFilter: GridFilterItem[] = [
  {
    columnField: 'first_submit_date',
    operatorValue: 'isNotEmpty',
  },
]

const approvedFilter: GridFilterItem[] = [
  {
    columnField: 'reason_co_closed',
    operatorValue: 'equals',
    value: 'approved'
  },
]

export default function ChangeOrderOverview() {
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const tickets = useTracFloSelector((state) => state.tickets.items)
  const ticketsProjectId = useTracFloSelector((state) => state.tickets.projectId)
  const ticketExpiry = useTracFloSelector((state) => state.tickets.exp)
  const changeOrders = useTracFloSelector((state) => state.changeOrders.items)
  const coProjectId = useTracFloSelector((state) => state.changeOrders.projectId)
  const coExpiry = useTracFloSelector((state) => state.changeOrders.exp)
  const coActionCodeMapper = useTracFloSelector((state) => state.changeOrderAction.idObjectMapping)
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const history = useHistory<{filter: GridFilterModel}>()
  const [coCostReviewAverageResponseTime, setCoCostReviewAverageResponseTime] = React.useState<number | null>(null)

  const languageDate = useLanguageDate()

  const coTicketsAttachedMetric = useMemo<number>(() => {
    // ensure that tickets are for the current project
    if (!!tickets && project.id && projectId === project.id && projectId === ticketsProjectId) {
      return calculateCOTicketsAttachedMetric(tickets)
    }
    return 0
  }, [tickets, project.id, projectId, ticketsProjectId])

  const coMetrics = useMemo<ChangeOrderMetrics | null>(() => {
    if (!!changeOrders && !!coActionCodeMapper) {
      return calculateChangeOrderMetrics(changeOrders, coActionCodeMapper, languageDate)
    }
    return null
  }, [changeOrders, coActionCodeMapper, languageDate])

  useEffect(() => {
    if (projectId && project && project.id === projectId) {
      reloadTicketsIfInvalid(projectId, ticketsProjectId, ticketExpiry, dispatch)
      reloadChangeOrdersIfInvalid(projectId, coProjectId, coExpiry, dispatch)
      getCoCostReviewAvgResponseTime(projectId).then(async (res) => {
        if (!!res.data) {
          setCoCostReviewAverageResponseTime(res.data.cost_response_time_in_sec)
        }
      })
    }
  }, [projectId, project])

  return coMetrics != null
    ? (
      <>
        <Container fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MetricCard
                title={t("view.Dashboard.CoCardTitles.tickets_attached")}
                large_number={coTicketsAttachedMetric.toString()}
                onClick={() => {
                  history.push(
                    goToTicketsList(projectId),
                    {filter: {items: changeOrderTicketsAttachedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <MetricCard          
                title={t("view.Dashboard.CoCardTitles.cost_review")}
                large_number={coMetrics.costReviewCount.toString()}
                onClick={() => {
                  history.push(
                    goToChangeOrdersList(projectId),
                    {filter: {items: inCostReviewFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <MetricCard          
                title={t("view.Dashboard.CoCardTitles.avg_response")}
                large_number={
                  coCostReviewAverageResponseTime != null ? 
                    roundToDecimal(coCostReviewAverageResponseTime/secToDays, 0).toString() 
                    : '-'
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={4}>
              <MetricCard
                title={t("view.Dashboard.CoCardTitles.open")}
                large_number={coMetrics.open.count.toString()}
                small_number={formatMoney(coMetrics.open.cost)}
                onClick={() => {
                  history.push(
                    goToChangeOrdersList(projectId),
                    {filter: {items: openFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <MetricCard
                title={t("view.Dashboard.CoCardTitles.total_submitted")}
                large_number={coMetrics.submitted.count.toString()}
                small_number={formatMoney(coMetrics.submitted.cost)}
                onClick={() => {
                  history.push(
                    goToChangeOrdersList(projectId),
                    {filter: {items: submittedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <MetricCard
                title={t("view.Dashboard.CoCardTitles.total_cost_approved")}
                large_number={coMetrics.costApproved.count.toString()}
                small_number={formatMoney(coMetrics.costApproved.cost)}
                onClick={() => {
                  history.push(
                    goToChangeOrdersList(projectId),
                    {filter: {items: approvedFilter, linkOperator: GridLinkOperator.And}}
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6}>
              <MetricCardGraph
                data={coMetrics.monthlyCoSubmittedApproved} 
                title={t("view.Dashboard.Graphs.monthlySubmitted")}
                lines={
                  [
                    {
                      name: t("view.Dashboard.Graphs.coCountSubmitted"),
                      dataKey: "coSubmittedCount",
                      color: lineGraphColor1,
                    },
                    {
                      name: t("view.Dashboard.Graphs.coCountApproved"),
                      dataKey: "coApprovedCount",
                      color: lineGraphColor2,
                    },
                  ]
                }
                yAxisType={"number"}
              />
            </Grid>
            <Grid item xs={6}>
              <MetricCardGraph
                data={coMetrics.monthlyCoSubmittedApproved} 
                title={t("view.Dashboard.Graphs.monthlyCost")}
                lines={
                  [
                    {
                      name: t("view.Dashboard.Graphs.coCostSubmitted"),
                      dataKey: "coSubmittedCost",
                      color: lineGraphColor1,
                    },
                    {
                      name: t("view.Dashboard.Graphs.coCostApproved"),
                      dataKey: "coApprovedCost",
                      color: lineGraphColor2,
                    },
                  ]
                }
                yAxisType={"money"}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    ) : <></>
}

