import React, { useState } from "react"
import Divider from "@mui/material/Divider"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

import AddSubRow from "./AddSub.row"
import AddSubForm from "./AddSub.form"
import {SubInvitee} from "../../../../../types/user"
import FormWideButtons from "../../../../../components/FormWideButtons"
import {inviteSub} from "../../../../../api/users"

// null is a successful invite, string indicates that something went wrong
async function sendInvite(invitee: SubInvitee, projectId: string): Promise<string | null> {
  try {
    const {data} = await inviteSub(invitee, projectId)
    // successful cases
    if (data.inviteSent || (data.userFound && data.userAdded)) return null
    // issues
    const issueMessage = data.userCantBeInvited
      ? 'this user is on TracFlo but cannot be invited'
      : data.userAlreadyInProject
        ? 'this user is already on your project'
        : data.companyAlreadyInProject
          ? `this user's company is already on your project. Please ask this company to add the user to the project.`
          : 'contact support regarding this user'
    return `${invitee.invite_email}: ${issueMessage}`
  } catch (_) {
    return `${invitee.invite_email}: there was an issue with this invite`
  }
}

interface AddSubProps {
  onClose: () => void,
  projectId: string,
}

const AddSub = (props: AddSubProps) => {
  const {
    onClose,
    projectId
  } = props
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [invitees, setInvitees] = useState<SubInvitee[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const addInvitee = (newInvitee: SubInvitee) => {
    const inviteeNotInList = invitees.filter((i) => i.invite_email === newInvitee.invite_email).length === 0
    if (inviteeNotInList) {
      setInvitees([newInvitee].concat(invitees))
    } else {
      // let user know this invitee is already on the list
      enqueueSnackbar(t("form.message.emailExist"), { variant: 'error' })
    }
  }

  const deleteRow = (deleteIndex: number) => {
    setInvitees(invitees.filter((_, index) => index !== deleteIndex))
  }

  const cleanupAndClose = () => {
    setIsSubmitting(false)
    setInvitees([])
    onClose()
  }

  const sendInvites = async () => {
    if (invitees.length === 0) return
    setIsSubmitting(true)
    const inviteResponses: (string | null)[] = await Promise.all(
      invitees.map((i) => sendInvite(i, projectId))
    )
    // notify of sent invites
    const numOfSentInvites: number = inviteResponses.filter((t) => t === null).length
    if (numOfSentInvites > 0) enqueueSnackbar(
      `Sent ${numOfSentInvites} invites successfully!`,
      {variant: "success"}
    )
    // notify of invites with issues
    const notSentReasons: string[] = inviteResponses.filter((t) => t !== null) as string[]
    if (notSentReasons.length > 0) enqueueSnackbar(
      `There were issues with the following invites:\r\n${
        notSentReasons.map((t) => `- ${t}`).join(`\r\n`)
      }\r\n\r\nPlease contact support for additional help`,
      {variant: 'error', style: {whiteSpace: 'pre-wrap'}}
    )
    cleanupAndClose()
  }

  return (
    <>
      <AddSubForm addInvitee={addInvitee} projectId={projectId}/>

      <Divider light style={{ marginBottom: 24 }} />

      {invitees.map((i, idx) =>
        <AddSubRow key={i.invite_email}  invitee={i} deleteRow={() => deleteRow(idx)}/>
      )}

      <FormWideButtons
        cancel={{text: t("form.label.cancel"), action: onClose,}}
        submit={{
          text: t("form.label.submit"),
          disabled: invitees.length === 0,
          isSubmitting,
          onClick: sendInvites,
        }}
      />
    </>
  )
}

export default AddSub
