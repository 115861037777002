import {useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {useTracFloSelector} from '../../store/useTracFloSelector'
import {getFinancingApr2024, setFinancingApr2024} from '../../store/features/userTestingSlice'
import {ChangeOrder} from '../../types/changeOrder'
import {convertToNumber} from '../../util/number'
import {changeOrderActionIdRejectStatus} from '../../types/action'
// @ts-ignore
import Button from "../../components/Button"
import {financingApr2024Api} from '../../api/userTesting'
import {secToDays} from '../../util/time'
import {formatMoney} from '../../libs/format'


function sumCoTotalAmount(cos: ChangeOrder[]): number {
  return cos.reduce<number>(
    (cum, co) => {
      // we dont count the change order's total if it's been rejected
      if (co.change_order_action_id === changeOrderActionIdRejectStatus) {
        return cum
      }
      return cum + (convertToNumber(co.total_amount) ?? 0)
    }, 0
  )
}

const minimumCoTotalToAskQuestion = 100000

const buttonStyles = {backgroundColor: "white", color: "#143366", height: '25px', fontSize: '12px'}

const showAgainToUserAfterYesNoResponse = 1000 * secToDays * 30 // 30 days in milliseconds

const showAgainToUserAfterDismissResponse = 1000 * secToDays // 1 day in milliseconds

type IsOpenForProject = {
  snackbarId: string | number,
  projectId: string,
}

export function useFinancingApr2024(): void {
  const financingApr2024 = useTracFloSelector(getFinancingApr2024)
  const cos = useTracFloSelector((s) => s.changeOrders.items)
  const project = useTracFloSelector((s) => s.project)
  const company = useTracFloSelector((s) => s.company)
  const user = useTracFloSelector((s) => s.user)
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const [isOpenForProject, setIsOpenForProject] = useState<IsOpenForProject | null>(null)

  const updateFinancingApr2024ForProjectState = useCallback(
    (interested_in_financing: boolean, datetime_try_again: Date): void => {
      dispatch(setFinancingApr2024({interested_in_financing, datetime_try_again, project_id: project.id}))
    }, [project.id, dispatch]
  )

  const updateFinancingApr2024ForYesNo = useCallback(
    async (interested_in_financing: boolean, snackbarId: string): Promise<void> => {
      closeSnackbar(snackbarId)
      const res = await financingApr2024Api(project.id, interested_in_financing)
      // only let them know this went well if they are interested in financing, otherwise this should just disappear
      if (res.status === 200 && res.data.success && interested_in_financing) {
        enqueueSnackbar(t('hook.useFinancingApr2024.success'), {variant: 'success'})
      }
      const tryAgainOnDate = new Date((new Date()).valueOf() + showAgainToUserAfterYesNoResponse)
      updateFinancingApr2024ForProjectState(interested_in_financing, tryAgainOnDate)
    }, [enqueueSnackbar, updateFinancingApr2024ForProjectState, project.id, closeSnackbar, t]
  )

  const updateFinancingApr2024ForDismiss = useCallback(
    (snackbarId: string): void => {
      closeSnackbar(snackbarId)
      const tryAgainOnDate = new Date((new Date()).valueOf() + showAgainToUserAfterDismissResponse)
      updateFinancingApr2024ForProjectState(false, tryAgainOnDate)
    }, [updateFinancingApr2024ForProjectState, closeSnackbar]
  )

  const snackbarAction = useCallback(
    (snackbarId: string) => {
      return (
        <>
          <Button
            style={{...buttonStyles, marginRight: '15px',}}
            onClick={() => {
              updateFinancingApr2024ForYesNo(true, snackbarId).then(/*intentionally blank*/)
            }}
          >
            {t('hook.useFinancingApr2024.yes')}
          </Button>
          <Button
            style={{...buttonStyles, marginRight: '15px',}}
            onClick={() => {
              updateFinancingApr2024ForYesNo(false, snackbarId).then(/*intentionally blank*/)
            }}
          >
            {t('hook.useFinancingApr2024.no')}
          </Button>
          <Button
            style={buttonStyles}
            onClick={() => {updateFinancingApr2024ForDismiss(snackbarId)}}
          >
            {t('hook.useFinancingApr2024.dismiss')}
          </Button>
        </>
      )
    }, [t, updateFinancingApr2024ForDismiss, updateFinancingApr2024ForYesNo]
  )

  const showSnackbar = useCallback(
    (coTotalAmount: number): void => {
      const snackbarId = enqueueSnackbar(
        t('hook.useFinancingApr2024.message', {coTotal: formatMoney(coTotalAmount), projectName: project.name}),
        {
          variant: 'info',
          action: snackbarAction,
          persist: true,
        }
      )
      setIsOpenForProject({projectId: project.id, snackbarId})
    }, [t, enqueueSnackbar, snackbarAction, project.name, project.id]
  )

  useEffect(() => {
    if (isOpenForProject != null) {
      // dont show a snackbar multiple times for the same project
      if (isOpenForProject.projectId === project.id) {return}
      // we need to reset if the project has changed
      closeSnackbar(isOpenForProject.snackbarId)
      setIsOpenForProject(null)
    }
    // asking the question is dependent on change orders, and should only be shown to trade PMs (no superadmins)
    if (
      cos.length > 0
      && project.id != null
      && project.project_user_role === 'pm'
      && company.company_type === 'trade'
      && ! user.is_admin
    ) {
      const financingApr2024ForProject = financingApr2024?.[project.id]
      // if we've never asked this question OR it's been awhile since we asked it
      if (
        financingApr2024ForProject == null
        || financingApr2024ForProject.datetime_try_again.valueOf() < (new Date()).valueOf()
      ) {
        const coTotal = sumCoTotalAmount(cos)
        if (coTotal > minimumCoTotalToAskQuestion) {
          showSnackbar(coTotal)
        }
      }
    }
  }, [cos, project, financingApr2024, company, user, showSnackbar, isOpenForProject, setIsOpenForProject, closeSnackbar])

  // this use effect is to clean up the component when it unmounts
  // MM: I tried putting this into the useEffect above, but it wasn't executing on unmount, not sure why...
  useEffect(() => {
    return () => {
      if (isOpenForProject != null) {
        closeSnackbar(isOpenForProject.snackbarId)
      }
    }
  }, [closeSnackbar, isOpenForProject])
}
