import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { formatDateTimeLongMonth } from "../../libs/format"
import FilesRow from "../FilesRow"
import { useLanguageDate } from "../../hook/languageLocale"
import { HistoryItem } from "../../types/history"


interface HistoryListItemProps {
  item: HistoryItem
}

export default function HistoryListItem(props: HistoryListItemProps) {
  const {
    first_name,
    last_name,
    date_created,
    description,
    title,
    files,
  } = props.item
  const { t } = useTranslation("private")
  const signatureFile = files.find((file: any) => file.file_special_type === "signature")
  const filesWithoutSignature = files.filter((file: any) => file.file_special_type !== "signature")
  const languageDate = useLanguageDate()

  return (
    <Box sx={{ borderTop: 1, borderColor: "grey.400", p: 2 }}>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12} md={8}>
          <Typography
            style={{
              color: "#000000de",
              fontWeight: 700,
              paddingBottom: 5,
              whiteSpace: 'pre-wrap'
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container xs={12} md={5}>
          { description ?
            <Grid item xs={12}>
              <Typography sx={{whiteSpace: 'pre-wrap'}}>
                {description}
              </Typography>
            </Grid>
            : <></>
          }
          { !!signatureFile ?
            <Grid item xs={12}>
               <Box
                  component="img"
                  alt="signature"
                  paddingBottom={2}
                  src={signatureFile.url}
                  sx={{maxWidth: "150px"}}
                />
            </Grid>
            : <></> 
          }
          <Grid item xs={12} container alignItems="flex-end">
            <Typography sx={{ fontWeight: 400, color: "grey.600" }}>
              {t("view.History.author", {first_name, last_name})}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4}>
          {
            filesWithoutSignature.length > 0
              ? <FilesRow files={filesWithoutSignature}/>
              : <></>
          }
        </Grid> 
        <Grid item container xs={12} md={3} justifyContent="flex-end" alignItems="flex-end">
          <Typography sx={{ color: "grey.600", fontSize: 15 }}>
            {t("view.History.date", {date: formatDateTimeLongMonth(date_created, languageDate) })}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
