import {convertCsvStrToJsArray} from '../../../../../../../util/csv/read'
import {processRateFieldForImport} from '../../../../../../../util/csv/util'

export type ImportLaborProperties = 'name' | 'cost_code' | 'rate_rt' | 'rate_ot' | 'rate_dt' | 'rate_pot' | 'rate_pdt'

export type ImportLaborFields = 'Description' | 'Cost Code' | 'RT' | 'OT' | 'DT' | 'POT' | 'PDT'
export const importLaborFields: ImportLaborFields[] = ['Description', 'Cost Code', 'RT', 'OT', 'DT', 'POT', 'PDT']

export const importLaborFieldToPropertyMapping: {[key in ImportLaborFields]: ImportLaborProperties} = {
  'Description': 'name',
  'Cost Code': 'cost_code',
  'RT': 'rate_rt',
  'OT': 'rate_ot',
  'DT': 'rate_dt',
  'POT': 'rate_pot',
  'PDT': 'rate_pdt',
}

export const ImportLaborFieldValidation: {[key in ImportLaborFields]: (s: string) => string} = {
  'Description': (s) => {
    // name is required
    if (s.length === 0) throw new Error('view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorDescValidation')
    return s
  },
  'Cost Code': (s) => {return s}, // no validation necessary
  'RT': (s) => {
    return processRateFieldForImport(s, 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorRateValidation')
  },
  'OT': (s) => {
    return processRateFieldForImport(s, 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorRateValidation')
  },
  'DT': (s) => {
    return processRateFieldForImport(s, 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorRateValidation')
  },
  'POT': (s) => {
    return processRateFieldForImport(s, 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorRateValidation')
  },
  'PDT': (s) => {
    return processRateFieldForImport(s, 'view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.errorRateValidation')
  },
}

export function convertCsvStringToLaborArray(csvStr: string): {[key in ImportLaborProperties]: string}[] {
  return convertCsvStrToJsArray<ImportLaborFields, ImportLaborProperties>(
    csvStr,
    importLaborFields,
    ImportLaborFieldValidation,
    importLaborFieldToPropertyMapping,
  )
}
