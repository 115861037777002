import React from "react"
import { useTranslation } from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

// @ts-ignore
import Card from "../../../components/Card"
import {Integration} from "../../../types/integration"

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
  },
  logo: {
    margin: "0 auto",
    maxHeight: 100,
    maxWidth: 100,
    width: "90%",
  },
}))

interface IntegrationCardProps {
  integration: Integration,
  isActive: boolean,
  onClick: () => void
}

export default function IntegrationCard({integration, isActive, onClick}: IntegrationCardProps) {
  const classes: any = useStyles()
  const { t } = useTranslation("private")

  const button = isActive
    ? (
      <Button
        disabled={true}
        className={classes.button}
        size="small"
        color={"success"}
      >
        {t("view.Integrations.activeButton")}
      </Button>
    )
    : (
      <Button
        className={classes.button}
        size="small"
        onClick={onClick}
      >
        {t("view.Integrations.setupButton")}
      </Button>
    )

  const logo = integration.logo_url ? (
    <img alt={t("Logo")} className={classes.logo} src={`${integration.logo_url}`} />
  ) : (
    <></>
  )

  return (
    <Grid
      key={integration.id}
      item
      container
      alignContent="stretch"
      alignItems="stretch"
      xs={12}
      sm={6}
      md={3}
    >
      <Card
        button={button}
        action={isActive ? undefined : onClick}
        title={integration.name}
      >
        {logo}
      </Card>
    </Grid>
  )
}
