import React, {useState} from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Divider from "@mui/material/Divider"
import MuiAvatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import {useSnackbar} from "notistack"

// @ts-ignore
import Dialog from "../../../components/Dialog"
import CreateCompanyForm from "../../../forms/CreateCompany"
import EditProfileForm, { EditProfileFormFields } from "../../../forms/EditProfile"
import { editUserProfile } from "../../../forms/EditProfile.submit"
import { addCompany, setUserFromToken } from "../../../store/features/userSlice"
import { resetStore } from "../../../store/resetStoreService"
import { useTracFloSelector } from "../../../store/useTracFloSelector"
import { createNewCompanyWithLogo } from "../../../util/company"
import {goToAccounts, goToIntegrations} from "../../../util/routes"
import ContactSupportToAddFeature from '../../../components/ContactSupportToAddFeature'


const useStyles = makeStyles<Theme>((theme) => ({
  info: {
    background: theme.palette.text.disabled,
    color: theme.palette.background.paper,
    fontSize: "0.85rem",
    marginBottom: theme.spacing(1),
    marginTop: -theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}))

export default function Avatar() {
  const { t } = useTranslation(["common", "private"])
  const classes = useStyles()
  const history = useHistory()
  const [profileOpen, setProfileOpen] = React.useState(null)
  const [dialog, setDialog] = React.useState<string | null>(null)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const user = useTracFloSelector((state) => state.user)
  const [showIntegrationContactSupportDialog, setShowIntegrationContactSupportDialog] = useState<boolean>(false)

  const createCompanyForAdmin = async (company: any): Promise<void> => {
    const newCompany = await createNewCompanyWithLogo(
      enqueueSnackbar,
      company,
    )
    if(newCompany){
      dispatch(addCompany(newCompany))
      history.push(goToAccounts)
    }
  }

  const editProfileForUser = async (user: EditProfileFormFields): Promise<void> => {
    // We use {ns: 'private'} here to specify which file we are using for the useTranslation, it defaults to 'common' but we need 'private' here
    const snackbarMessage = {
      successMessage: t("form.EditUserProfile.successEditingUserProfileMessage", {ns: 'private'}),
      errorMessage: t("form.EditUserProfile.issueEditingUserProfile", {ns: 'private'})
    }
    const userToken = await editUserProfile(
      snackbarMessage,
      enqueueSnackbar,
      user
    )
    if(!!userToken){
      dispatch(setUserFromToken(userToken))
      setDialog(null)
    }
  }

  const handleLogout = () => {
    resetStore(dispatch)
  }

  const handleClick = (event: any) => {
    setProfileOpen(event.currentTarget)
  }

  const handleClose = () => {
    setProfileOpen(null)
  }

  const adminMenuItems = [
    <MenuItem
      onClick={() => {
        history.push("/0/admin/dashboard")
        handleClose()
      }}
      key={t("Admin Dashboard")}
    >
      {t("Admin Dashboard")}
    </MenuItem>,
    <MenuItem
      onClick={() => {
        history.push("/0/admin/users")
        handleClose()
      }}
      key={t("ViewAllUsers")}
    >
      {t("ViewAllUsers")}
    </MenuItem>,
    <MenuItem
      onClick={() => {
        history.push("/0/admin/companies")
        handleClose()
      }}
      key={t("ViewTracFloCompanies")}
    >
      {t("ViewTracFloCompanies")}
    </MenuItem>,
    <MenuItem
      onClick={() => {
        setDialog("newcustomer")
        handleClose()
      }}
      key={t("Create Company")}
    >
      {t("Create Company")}
    </MenuItem>,
    <Divider key="divider" />,
  ]

  const userMenuItems = [
    <MenuItem
      onClick={() => {
        history.push(goToIntegrations)
        handleClose()
      }}
      key={t("Integrations")}
    >
      {t("Integrations")}
    </MenuItem>,
    <MenuItem
      onClick={() => {
        setDialog("editprofile")
        handleClose()
      }}
      key={t("Edit Profile")}
    >
      {t("Edit Profile")}
    </MenuItem>,
    <Divider key="divider" />,
  ]

  return (
    <>
      {user.isLoggedIn ? (
        <div>
          <IconButton
            aria-label="open"
            aria-controls="simple-menu"
            aria-haspopup="true"
            edge="end"
            onClick={handleClick}
            size="large"
          >
            <MuiAvatar src="/broken-image.jpg" />
          </IconButton>
          <Menu
            anchorEl={profileOpen}
            keepMounted
            open={Boolean(profileOpen)}
            onClose={handleClose}
          >
            <Typography className={classes.info} component="li">
              <strong>{user.name}</strong>
              <br />
              <strong>{user.email}</strong>
            </Typography>
            {userMenuItems}
            {user.is_admin ? adminMenuItems : []}
            {user?.companies.length > 1 ? (
              <MenuItem
                onClick={() => {
                  history.push(goToAccounts)
                  handleClose()
                }}
                key={t("Change Account")}
              >
                {t("Change Account")}
              </MenuItem>
            ) : null}
            <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
          </Menu>
          <Dialog
            maxWidth={'md'}
            hideactions="true"
            open={dialog === "newcustomer"}
            onClose={() => {
              setDialog(null)
            }}
            title={"Add a Company"}
          >
            <CreateCompanyForm
              onCancel={() => {setDialog(null)}}
              submit={createCompanyForAdmin}
            />
          </Dialog>
          <Dialog
            maxWidth={'md'}
            hideactions="true"
            open={dialog === "editprofile"}
            onClose={() => {
              setDialog(null)
            }}
            title={t("Edit Profile")}
          >
            <EditProfileForm
              onCancel={() => {setDialog(null)}}
              submit={editProfileForUser}
            />
          </Dialog>
          <Dialog
            hideactions="true"
            open={showIntegrationContactSupportDialog}
            onClose={() => setShowIntegrationContactSupportDialog(false)}
            title={t('view.AvatarMenu.Integrations.supportDialogTitle', {ns: 'private'})}
          >
            <ContactSupportToAddFeature
              featureDescription={t('view.AvatarMenu.Integrations.supportDialogDesc', {ns: 'private'})}
            />
          </Dialog>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
