import {convertToNumber} from "../util/number"
import {CreateTicketInitialValues, CreateTicketOutputForApi} from "./CreateTicket.types"
import {AddMaterialEquipmentBreakdownInitialValues} from "./AddMaterialEquipmentToBreakdown.types"
import {createMaterialType} from "../api/material"
import {createEquipmentType} from "../api/equipment"
import {Material} from '../types/material'
import {Equipment} from '../types/equipment'
import {AddLaborBreakdownInitialValues} from './AddLaborToBreakdown.types'

export function generateMarkupData(markup: any[], markup_type: string): any[] {
  return markup.map((row: any) => {
    const rowValue: any = {
      percent: row.amount,
      description: row.title,
      markup_type,
    }
    if (row.id) {
      rowValue.id = row.id
    }
    return rowValue
  })
}

export function generateSubject(submitValues: {subject: string | null, description: string}): string {
  if (submitValues.subject == null || submitValues.subject.length === 0) {
    const descriptionArr: string[] = submitValues.description.split(' ')
    const maxWordsInSubject = 10
    // comes from description, but if description is too long, then truncate it
    return descriptionArr.length > maxWordsInSubject
      ? descriptionArr.slice(0, 10).join(' ') + '...'
      : submitValues.description
  } else {
    return submitValues.subject
  }
}

export function convertEquipmentBreakdown(row: AddMaterialEquipmentBreakdownInitialValues): any {
  const rowValue: any = {
    unit: row.unit,
    quantity: row.quantity,
    rate: row.rate,
    equipment_type_id: row.type_id,
  }
  if (row.id) {
    rowValue.id = row.id
  }
  return rowValue
}

export function convertMaterialBreakdown(row: AddMaterialEquipmentBreakdownInitialValues): any {
  const rowValue: any = {
    unit: row.unit,
    quantity: row.quantity,
    rate: row.rate,
    material_type_id: row.type_id,
  }
  if (row.id) {
    rowValue.id = row.id
  }
  return rowValue
}

export function convertLaborBreakdown(row: AddLaborBreakdownInitialValues): any {
  const rowValue: any = {
    hours_per_person: row.hours,
    crew_size: row.quantity,
    rate_type: row.rate_type,
    labor_type_id: row.type_id,
    date: row.date,
  }
  if (row.id) {
    rowValue.id = row.id
  }
  return rowValue
}

export function convertFormValuesToApiData(
  values: CreateTicketInitialValues,
  nextTicketNumber: string|null,
): CreateTicketOutputForApi {
  // Set up most of our values
  const submitValues: any = Object.keys(values)
    .filter(
      (key) =>
        !(
          key.includes("Breakdown") ||
          key.includes("Markup") ||
          key.includes("files") ||
          key === "isAddCosts" ||
          key === "isLumpSum"
          // @ts-ignore
        ) && values[key]
    )
    .reduce((current, key) => {
      // @ts-ignore
      return Object.assign(current, { [key]: values[key] })
    }, {})
  // auto-generate subject
  submitValues.subject = generateSubject(submitValues)
  // Next number
  submitValues.number = values.number ? values.number.toString() : nextTicketNumber
  // initialize markup
  submitValues.markup = []
  // Set up the cost related stuff
  submitValues.includes_costs = values.isAddCosts
  submitValues.is_lump_sum = values.isLumpSum
  if (submitValues.is_lump_sum) {
    const lumpSumTotal = convertToNumber(values.manual_total)
    submitValues.lump_sum_total = lumpSumTotal == null ? 0 : lumpSumTotal
  }

  // Start and end dates
  const startDate = new Date(values.date_start)
  submitValues.date_start = startDate.toISOString()
  if (values.date_end) {
    const endDate = new Date(values.date_end)
    submitValues.date_end = endDate.toISOString()
  } else {
    delete submitValues.date_end
  }

  // Equipment breakdown and markups
  if (values.equipmentBreakdown.length) {
    submitValues.equipment = values.equipmentBreakdown.map(convertEquipmentBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.equipmentMarkup, 'equipment')
      )
    }
  }
  // Labor breakdown and markups
  if (values.laborBreakdown.length) {
    submitValues.labor = values.laborBreakdown.map(convertLaborBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.laborMarkup, 'labor')
      )
    }
  }
  // Material breakdown and markups
  if (values.materialBreakdown.length) {
    submitValues.material = values.materialBreakdown.map(convertMaterialBreakdown)
    if (values.type === "sum_rates") {
      submitValues.markup = submitValues.markup.concat(
        generateMarkupData(values.materialMarkup, 'material')
      )
    }
  }
  // Ticket markups
  if (values.formMarkup.length) {
    submitValues.markup = submitValues.markup.concat(
      generateMarkupData(values.formMarkup, 'ticket')
    )
  }
  return submitValues
}

// NOTE: this updates the type id of any new materials in place
export async function createNewMaterialTypesFromBreakdowns(
  materialBds: AddMaterialEquipmentBreakdownInitialValues[]
): Promise<Material[]> {
  const newMaterials = materialBds.filter((mbd) => !mbd.type_id || mbd.type_id.length === 0)
  const newMaterialTypes: Material[] = []
  for (let newMaterial of newMaterials) {
    const res = await createMaterialType({
      rate: newMaterial.rate,
      unit: newMaterial.unit,
      name: newMaterial.type_name
    })
    if (res.data?.id) {
      newMaterial.type_id = res.data.id
      newMaterialTypes.push(res.data)
    }
  }
  return newMaterialTypes
}

// NOTE: this updates the type id of any new equipment in place
export async function createNewEquipmentTypesFromBreakdowns(
  equipmentBds: AddMaterialEquipmentBreakdownInitialValues[]
): Promise<Equipment[]> {
  const newEquips = equipmentBds.filter((ebd) => !ebd.type_id || ebd.type_id.length === 0)
  const newEquipmentTypes: Equipment[] = []
  for (let newEquip of newEquips) {
    const res = await createEquipmentType({
      rate: newEquip.rate,
      unit: newEquip.unit,
      name: newEquip.type_name
    })
    if (res.data?.id) {
      newEquip.type_id = res.data.id
      newEquipmentTypes.push(res.data)
    }
  }
  return newEquipmentTypes
}

