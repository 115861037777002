import React from "react"
import Checkbox from "@mui/material/Checkbox"

//@ts-ignore
import Link from "../../../../components/Link"


export const AdminCompaniesColumns = (
  t: (s: string) => string,
  toggleCompanyDemoStatus: (companyId: string, for_demos: boolean) => void
) => {
  return [
    {
      field: "name",
      headerName: t("view.Admin.companyList"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 650,
      renderCell: (params: any) => (
        <Link to={`/0/admin/companies/${params.id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "active_project_licenses",
      headerName: t("view.Admin.companyActiveProjectLicenses"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 350,
    },
    {
      field: "for_demos",
      headerName: t("view.Admin.companyForDemos"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 150,
      renderCell: (params: any) => (
        <Checkbox
          checked={params.value}
          onChange={() => {
            toggleCompanyDemoStatus(params.id, !params.value)
          }}
          name="for_demos"
        />
      ),
    },
  ]
}