

export function apiHeaders(companyId?: string, userToken?: string): any {
  const headers: any = {
    "Content-Type": "application/json",
  }
  if (userToken != null) {
    headers.Authorization = `Bearer ${userToken}`
  }
  if (companyId != null) {
    headers.company_id = companyId
  }
  return headers
}
