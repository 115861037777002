import React, { useEffect, useState} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt, useHistory } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// @ts-ignore
import Container from "../components/Container"
import FormWideButtons from "../components/FormWideButtons"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import FilesRow from "../components/FilesRow"
import {calculateTotalFileSizeInBytes, File} from "../types/file"
import {
  CreateBulletinInitialValues,
  defaultCreateBulletinInitialValues,
} from './CreateBulletin.types'
import {goToBulletinList} from '../util/routes'
import CreateBulletinSubcontractors from './CreateBulletinSubs'
import { getNextBulletinPcoNumber } from "../api/bulletin"
import {DateField} from './components/DateField'


const fileUploadLimitInMB = 50

interface CreateBulletinProps {
  initialValues?: CreateBulletinInitialValues | null,
  projectId: string,
  submitValues: (
    output: CreateBulletinInitialValues,
    nextBulletinPcoNumber: string | null,
  ) => Promise<void>,
  existingFiles?: File[] | null,
  submitButtonText?: string,
  editingBulletin?: boolean,
}

export default function CreateBulletin(props: CreateBulletinProps) {
  const {initialValues, submitValues, existingFiles, submitButtonText, projectId, editingBulletin} = props
  const history = useHistory()
  const { t } = useTranslation("private")
  const [nextBulletinPcoNumber, setNextBulletinPcoNumber] = useState<string | null>(null)

  useEffect(() => {
    window.onbeforeunload = function (_) {
      if (history.location.pathname.includes("bulletin/add")) {
        return ""
      } else {
        return null
      }
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (projectId) {
      getNextBulletinPcoNumber(projectId)
        .then((res) => {
          if(res.status === 200 && !!res.data.next_pco_number){
            setNextBulletinPcoNumber(res.data.next_pco_number)
          }
        })
    }
  }, [projectId])

  return (
    <Formik<CreateBulletinInitialValues>
      initialValues={initialValues ?? defaultCreateBulletinInitialValues()}
      validationSchema={Yup.object().shape({
        title: Yup.string().min(2).required(t("view.Bulletins.CreateBulletin.titleRequired")),
        description: Yup.string().min(2).required(t("view.Bulletins.CreateBulletin.descriptionRequired")),
        date_estimates_due: Yup.string().min(2).required(t("view.Bulletins.CreateBulletin.dateEstimatesDueRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await submitValues(values, nextBulletinPcoNumber)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
        }) => {
        return (
          <Form>
            <Container removeTop>
              <FormWideContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t("view.Bulletins.CreateBulletin.pcoNumberLabel")}
                      name="pco_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pco_number}
                      placeholder={nextBulletinPcoNumber ?? ""}
                    />
                  </Grid>

                  <DateField
                    width={'half'}
                    label={t("view.Bulletins.CreateBulletin.dateEstimateDueLabel")}
                    value={values.date_estimates_due}
                    // When creating, estimate due date must be in the future. When editing, this requirement is removed.
                    minDate={editingBulletin ? undefined : new Date()}
                    onChange={(date) => {
                      setFieldValue("date_estimates_due", date)
                    }}
                  />

                  <Grid item xs={12} md={12}>
                    <TextField
                      error={touched.title && errors.title}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      label={t("view.Bulletins.CreateBulletin.titleLabel")}
                      name="title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaField
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                      label={t("view.Bulletins.CreateBulletin.descriptionLabel")}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.notes")}
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {/* For editing, we show existing files here */
                      existingFiles && existingFiles.length > 0
                        ? <Grid item xs={12} sx={{paddingBottom: 4}}>
                          <Typography color="primary">Current Files:</Typography>
                          <FilesRow files={existingFiles}/>
                        </Grid>
                        : <></>
                    }

                    <UploadMultipleField
                      errors={errors}
                      label={t("form.label.uploadAttachments")}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      fileLimit={fileUploadLimitInMB}
                      existingFilesSizeInBytes={
                        existingFiles != null
                          ? calculateTotalFileSizeInBytes(existingFiles)
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </FormWideContainer>
            </Container>

            <CreateBulletinSubcontractors
              projectId={projectId}
              selectedSubs={values.attached_subs}
              setSelectedSubs={(s) => setFieldValue('attached_subs', s)}
            />

            <Prompt
              when={!isSubmitting}
              message={t('form.message.leavingPage')}
            />

            <FormWideButtons
              cancel={{
                action: () => {
                  history.push(goToBulletinList(projectId))
                },
                text: t("view.Bulletins.CreateBulletin.cancelButton"),
              }}
              submit={{
                disabled: !isValid,
                isSubmitting: isSubmitting,
                text: submitButtonText ?? t("view.Bulletins.CreateBulletin.addButton"),
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
