import React, {useEffect, useState} from "react"
import { Formik, Form } from "formik"
import { Prompt } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import {useTranslation} from "react-i18next"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
//@ts-ignore
import SelectField from "../components/Fields/Select"
//@ts-ignore
import TextField from "../components/Fields/Text"
//@ts-ignore
import TextAreaField from "../components/Fields/TextArea"
//@ts-ignore
import api from "../libs/api"
import { useTracFloSelector } from "../store/useTracFloSelector"
import { CreateEditLaborInitialValues, defaultCreateLaborInitialValues } from "./CreateLabor.types"
import { convertToNumber } from "../util/number"
import {DateField} from './components/DateField'


interface CreateEditLaborProps {
  submitValues: (values: any) => Promise<boolean>,
  onCancel: () => void,
  initialValues?: CreateEditLaborInitialValues,
  update?: boolean,
  submitButtonText?: string,
}

// Determining if rate field should be disabled depending on
// whether form is being used to update & if rate is already filled in with a value higher than 0
function rateFieldDisabled(update: any, initialValues: any, rate_type: string): boolean {
  return !!update && !!initialValues[rate_type] && (convertToNumber(initialValues[rate_type]) ?? 0) > 0
}

export default function CreateEditLaborForm(props: CreateEditLaborProps) {
  const { onCancel, initialValues, update, submitButtonText, submitValues } = props
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const user = useTracFloSelector((state) => state.user)
  const [subOptions, setSubOptions] = useState([])
  const { t } = useTranslation("private")

  // Superadmins and sub license owners can edit any labor rate
  const canEditLaborRates = user.is_admin || (company.id === project.license_owner && company.company_type === 'trade')

  useEffect(() => {
    if (company.company_type === 'cm') {
      api({
        method: "get",
        url: `/project/${project.id}/trade_company_list`,
      }).then(
        (res: any) => {
          if (Array.isArray(res.data)) {
            setSubOptions(res.data.map((sub: any) => ({
              label: sub.name,
              value: sub.id,
            })))
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id, project.id])

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues ?? defaultCreateLaborInitialValues(company)}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t("form.message.nameRequired")),
        subcontractor_id: Yup.string().required(t("form.message.subcontractorRequired")),
        date_start: Yup.string().required(t("form.message.dateStartRequired")),
      })}
      onSubmit={async(values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        // Returning a successful submission boolean due to this form being used in both a Dialog and Accordion
        const isSuccessfulSubmit = await submitValues(values)
        if(isSuccessfulSubmit){
          onCancel()
        }
        resetForm()
        setSubmitting(false)
      }}
    >
    {({
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
      isValid,
      touched,
      values,
      setFieldValue,
    }) => {
      return (
      <Form>
      <Prompt
          // The select company form is only for GCs. For subs using this form, they are the selected company.
          when={Object.keys(touched).length > 0}
          message="You have unsaved changes, are you sure you want to leave?"
      />
      <FormSmallContainer>
        <Grid container spacing={2}>
          {company.company_type === 'cm'
            ? <Grid item xs={12}>
              <SelectField
                autoFocus
                error={Boolean(touched.subcontractor_id && errors.subcontractor_id)}
                helperText={touched.subcontractor_id && errors.subcontractor_id}
                label="Subcontractor"
                name="subcontractor_id"
                options={subOptions}
                onChange={handleChange}
                renderInput={(props: any) => <TextField {...props} />}
                value={values.subcontractor_id}
              />
            </Grid>
            : <></>
          }
          <Grid item xs={12}>
            <TextField
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              autoFocus id="name"
              label= {t("form.label.name")}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={Boolean(touched.cost_code && errors.cost_code)}
              helperText={touched.cost_code && errors.cost_code}
              id="cost_code"
              label= {t("form.label.cost_code")}
              name="cost_code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cost_code}
            />
          </Grid>

          <DateField
            width={'half'}
            label={t("form.label.startDate")}
            value={values.date_start}
            maxDate={values.date_end ? new Date(values.date_end) : undefined}
            onChange={(date) => {
              setFieldValue("date_start", date)
            }}
          />

          <DateField
            width={'half'}
            label={t("form.label.endDate")}
            value={values.date_end}
            minDate={values.date_start ? new Date(values.date_start) : undefined}
            onChange={(date) => {
              setFieldValue("date_end", date)
            }}
          />

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={7} md>
              <TextField
                disabled={!canEditLaborRates && rateFieldDisabled(update, initialValues, "rate_rt")}
                error={Boolean(touched.rate_rt && errors.rate_rt)}
                helperText={touched.rate_rt && errors.rate_rt}
                id="rate_rt"
                label="Regular Time"
                type="number"
                name="rate_rt"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rate_rt}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={7} md>
              <TextField
                disabled={!canEditLaborRates && rateFieldDisabled(update, initialValues, "rate_ot")}
                error={Boolean(touched.rate_ot && errors.rate_ot)}
                helperText={touched.rate_ot && errors.rate_ot}
                id="rate_ot"
                label="Overtime"
                type="number"
                name="rate_ot"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rate_ot}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={7} md>
              <TextField
                disabled={!canEditLaborRates && rateFieldDisabled(update, initialValues, "rate_dt")}
                error={Boolean(touched.rate_dt && errors.rate_dt)}
                helperText={touched.rate_dt && errors.rate_dt}
                id="rate_dt"
                label="Double Time"
                type="number"
                name="rate_dt"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rate_dt}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={7} md>
              <TextField
                disabled={!canEditLaborRates && rateFieldDisabled(update, initialValues, "rate_pot")}
                error={Boolean(touched.rate_pot && errors.rate_pot)}
                helperText={touched.rate_pot && errors.rate_pot}
                id="rate_pot"
                label="Premium Time"
                type="number"
                name="rate_pot"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rate_pot}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={7} md>
              <TextField
                disabled={!canEditLaborRates && rateFieldDisabled(update, initialValues, "rate_pdt")}
                error={Boolean(touched.rate_pdt && errors.rate_pdt)}
                helperText={touched.rate_pdt && errors.rate_pdt}
                id="rate_pdt"
                label="Premium Double"
                type="number"
                name="rate_pdt"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rate_pdt}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              error={Boolean(touched.notes && errors.notes)}
              helperText={touched.notes && errors.notes}
              id="notes"
              label={t("form.label.notes")}
              name="notes"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.notes}
            />
          </Grid>
          { !!update ?
            <Grid item xs={12}>
              <Box sx={{backgroundColor: "#d32f2f"}} pt={5} pb={5} pl={3} pr={3}>
                <Typography textAlign={"center"} sx={{color: "white", fontWeight: 600}}>
                  {t("view.Labor.edit_labor_rate_warning")}
                </Typography>
              </Box>
            </Grid> : <></>
          }
          <Grid container justifyContent="flex-start" item xs={6}>
            <Button onClick={onCancel} color="secondary" variant="text">
              {t("form.label.cancel")}
            </Button>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6}>
            <Button
              disabled={!Object.keys(touched).length || !isValid }
              isLoading={isSubmitting}
              type="submit"
            >
              {submitButtonText ?? t("form.label.add")}
            </Button>
          </Grid>
        </Grid>
        </FormSmallContainer>
        </Form>
      )}
    }
    </Formik>
  )
}
