import React from "react"
import { decode } from "html-entities"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { makeStyles, useTheme } from "@mui/styles"
import { Theme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppBar from "@mui/material/AppBar"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import MenuIcon from "@mui/icons-material/Menu"
import { drawerWidth } from "../../../theme"

import Avatar from "../AvatarMenu"
import Logo from "../../../components/LogoPortable"
import { showDrawer } from "../../../store/features/userSlice"
import { useTracFloSelector } from "../../../store/useTracFloSelector"
import { useLocation } from "react-router-dom"
import { pathNeedsActiveCompany } from "../../../util/routes"
import IntegrationsDropdown from "./IntegrationsDropdown"

const padding = 24
const useStyles = makeStyles<Theme>((theme) => {
  return {
    currentProject: {
      color: "#999999",
      letterSpacing: "1.2px",
      textTransform: "uppercase",
      opacity: "1",
      fontSize: "12px",
    },
    drawerAligned: {
      [theme.breakpoints.up("md")]: {
        maxWidth: drawerWidth - padding,
        width: drawerWidth - padding,
      },
    },
    hide: {
      display: "none",
    },
    logoContainer: {
      zIndex: 0,
      [theme.breakpoints.up("md")]: {
        maxWidth: drawerWidth - padding - 47,
        width: drawerWidth - padding - 47,
      },
    },
    navContainer: {
      maxWidth: 47,
      width: 47,
      zIndex: 10,
    },
    projectName: {
      color: "#143366",
      fontSize: "15px",
      fontWeight: "700",
    },
    root: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    shift: {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      position: "relative",
    },
  }
})

interface SiteHeaderProps {
  simple?: boolean,
  topTitle?: string,
  bottomTitle?: string,
}

export default function SiteHeader(props: SiteHeaderProps) {
  const { simple, topTitle, bottomTitle } = props
  const { t } = useTranslation("private")
  const classes = useStyles()
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const dispatch = useDispatch()
  const { drawerOpen } = useTracFloSelector((state) => state.user)
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const { pathname } = useLocation()

  const handleDrawerOpen = () => {
    dispatch(showDrawer(!drawerOpen))
  }

  return (
    // @ts-ignore
    <AppBar
      background={theme.palette.background.paper}
      className={classes.root}
      elevation={5}
      position="fixed"
    >
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between">
          <Grid className={classes.drawerAligned} item xs container justifyContent="flex-start">
            {!simple ? (
              <Grid
                className={classes.navContainer}
                item
                xs
                container
                alignItems="center"
                justifyContent="flex-start"
                style={{ width: 47 }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  size="large"
                >
                  {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
              </Grid>
            ) : (
              <></>
            )}
            <Grid
              className={classes.logoContainer}
              item
              xs
              container
              alignItems="center"
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              <Logo companyName={company.name} imgUrl={company?.logo_url} tracFloLogo={!pathNeedsActiveCompany(pathname)}/>
            </Grid>
          </Grid>
          <Grid item xs container alignItems="center" justifyContent="space-between">
            {!isMobile ? (
              <Grid
                item
                xs={9}
                md={6}
                justifyContent="center"
                container
                direction="column"
                style={{ paddingLeft: isMobile ? 0 : 40 }}
              >
                <div>
                  <Typography className={classes.currentProject}>
                    {topTitle ?? (project.id ? t("component.siteheader") : '')}
                  </Typography>

                  <Typography className={classes.projectName}>
                    {bottomTitle ?? (project.id ? decode(project.name): '')}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IntegrationsDropdown />
              </Grid>
              <Grid item>
                <Avatar />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>

      <Divider />
    </AppBar>
  )
}
