import {convertToNumber} from "../../util/number"
// @ts-ignore
import {getRateTypeName} from "../../libs/rateTypes"

export function updateEquipmentBreakdownWithEquipmentType(
  equipmentBds: any[],
  equipmentTypes: any[],
  addRates: boolean
): void {
  for (let equipmentBd of equipmentBds) {
    if (addRates) {
      equipmentBd.total_cost = equipmentBd.total_cost
        ?? (convertToNumber(equipmentBd.rate) ?? 0) * (convertToNumber(equipmentBd.quantity) ?? 0)
    }
    if(equipmentBd.type_id) {
      const equipmentType = equipmentTypes.find((et) => et.id === equipmentBd.type_id)
      equipmentBd.type_name = equipmentBd.type_name ?? equipmentType.name
      equipmentBd.cost_code = equipmentBd.cost_code ?? equipmentType.cost_code
    }
  }
}

export function calculateEquipmentTotals(values: any[], addRates: boolean): any {
  return addRates
    ? {
      total: values.reduce(
        (total, { total_cost: current }) => total + (convertToNumber(current) ?? 0),
        0
      ),
    }
    : {}
}

export function updateMaterialBreakdownWithMaterialType(
  materialBds: any[],
  materialTypes: any[],
  addRates: boolean
): void {
  for (let materialBd of materialBds) {
    if (addRates) {
      materialBd.total_cost = materialBd.total_cost
        ?? (convertToNumber(materialBd.rate) ?? 0) * (convertToNumber(materialBd.quantity) ?? 0)
    }
    if(materialBd.type_id) {
      const materialType = materialTypes.find((mt) => mt.id === materialBd.type_id)
      materialBd.type_name = materialBd.type_name ?? materialType.name
      materialBd.cost_code = materialBd.cost_code ?? materialType.cost_code
    }
  }
}

export function calculateMaterialTotals(values: any[], addRates: boolean): any {
  return addRates
    ? {
      total: values.reduce(
        (total, { total_cost: current }) => total + (convertToNumber(current) ?? 0),
        0
      ),
    }
    : {}
}

export function updateLaborBreakdownWithLaborType(laborBds: any[], laborTypes: any[], addRates: boolean): void {
  for (let laborBd of laborBds) {
    // Regardless, we need to add this field if it doesnt exist
    laborBd.total_hours = laborBd.total_hours
      ?? (convertToNumber(laborBd.quantity) ?? 0) * (convertToNumber(laborBd.hours) ?? 0)
    // Find the associated labor type and add relevant fields
    if (laborBd.type_id) {
      const laborType = laborTypes.find((lt) => lt.id === laborBd.type_id)
      if (laborType) {
        laborBd.cost_code = laborBd.cost_code ?? laborType.cost_code
        laborBd.type_name = laborBd.type_name ?? laborType.name
        laborBd.rate_type_name = laborBd.rate_type_name ?? getRateTypeName(laborBd.rate_type)
        laborBd.rate = laborBd.rate ?? laborType[laborBd.rate_type]
        if (addRates) {
          laborBd.total_cost = laborBd.total_cost
            ?? laborBd.rate
            ? laborBd.rate * laborBd.total_hours
            : undefined
        }
      }
    }
  }
}

export function calculateLaborTotals(values: any[], addRates: boolean): any {
  const laborTotals: any = {
    quantity: values.reduce(
      (total, { quantity: current }) => total + parseInt(current),
      0
    ),
      hours: values.reduce((total, { total_hours: current }) => total + current, 0),
  }
  if (addRates) {
    laborTotals.total = values.reduce(
      (total, { total_cost: current }) => total + convertToNumber(current),
      0
    )
  }
  return laborTotals
}
