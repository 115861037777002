import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, } from '@mui/material'

import MetricCard from '../../../../../../components/MetricCard'
import { formatMoney, formatPercent } from '../../../../../../libs/format'


interface EstimateTotalActualCostCardsProps {
  estimateTotalAmount: number,
  estimateTotalActualAmount: number,
}

const numberStyle = {color: "#212121"}
const negativeNumberStyle = {color: "#C62828"}

export default function EstimateTotalActualCostCards(
  {estimateTotalAmount, estimateTotalActualAmount}: EstimateTotalActualCostCardsProps
): JSX.Element {
  const { t } = useTranslation("private")
  const actualGreaterThanEstimate = estimateTotalActualAmount > estimateTotalAmount

  return (
    <Grid container spacing={2} marginTop={2}>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.estimatedCost")}
          large_number={formatMoney(estimateTotalAmount)}
          numberStyle={numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.actualCost")}
          large_number={formatMoney(estimateTotalActualAmount)}
          numberStyle={numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.percentUsed")}
          large_number={ estimateTotalAmount !== 0
            ? formatPercent(estimateTotalActualAmount/estimateTotalAmount)
            : '-'
          }
          numberStyle={actualGreaterThanEstimate ? negativeNumberStyle : numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.remaining")}
          large_number={formatMoney(estimateTotalAmount - estimateTotalActualAmount)}
          numberStyle={actualGreaterThanEstimate ? negativeNumberStyle : numberStyle}
        />
      </Grid>
    </Grid>
  )
}
