import React from "react"
import AddIcon from "@mui/icons-material/Add"
import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"

import AddSub from "./AddSub"
// @ts-ignore
import Dialog from "../../../../../components/Dialog"
import Fab from "../../../../../components/Fab"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"

export default function AddSubButton() {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = React.useState(false)
  const project = useTracFloSelector((state) => state.project)
  const handleClose = () => setOpenDialog(false)

  const hideButton = ! project?.project_license_is_active
    || ! project?.gc_can_add_subs

  return hideButton
    ? <></>
    : <>
      <Grid container item justifyContent="flex-end" marginBottom={1}>
        <Fab
          onClick={() => setOpenDialog(true)}
          size="medium"
          variant="extended"
          style={{ backgroundColor: "white", color: theme.palette.secondary.main }}
        >
          <AddIcon />
          Subcontractor
        </Fab>
      </Grid>
      <Dialog onClose={handleClose} open={openDialog} maxWidth="lg" title="Invite Trades / Subcontractors">
        <AddSub onClose={handleClose} projectId={project.id}/>
      </Dialog>
    </>
}
