import React, {useEffect, useState} from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import {Theme, useTheme} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import {getChangeOrderFiles, getChangeOrderSenderReceiver} from "../../../../../../api/changeOrder"
import InvoiceTopSection from "../../../../../../components/Invoice/InvoiceTopSection"
import ChangeOrderInvoiceTopDetails from "./ChangeOrderInvoiceTopDetails"
import ChangeOrderTicketList from "./ChangeOrderTicketList"
import {createChangeOrderInvoiceTopSummary} from "./ChangeOrderInvoiceTopSummary"
import ChangeOrderMarkup from "./ChangeOrderMarkup"
import {formatMoney} from "../../../../../../libs/format"
import {ChangeOrderInvoiceSignature} from "../Signature"
import { CompanySenderReceiverInfo } from "../../../../../../types/company"


const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    borderLeft: "2px solid #CCCCCC",
    opacity: 1,
    paddingLeft: 10,
  },
  descriptionTitles: { color: "#757575", fontSize: "12px" },
  root: {
    margin: "auto",
    maxWidth: 1080,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    "@media print": {
      maxWidth: 9999,
      padding: 0,
    },
    totals: {
      paddingTop: 20,
    },
  },
}))

const setChangeOrderTotalFromTickets = (
  isLumpSum: boolean,
  setTotal: (t: number) => void
): ((total: number) => void) => {
  return (total: number) => {
    // Only set the total if this isn't a lump sum change order
    if (!isLumpSum) {
      setTotal(total)
    }
  }
}

interface ChangeOrderInvoiceProps {
  changeOrder: any,
  project: any,
  elevation?: number
}

export default function ChangeOrderInvoice(props: ChangeOrderInvoiceProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const classes = useStyles()
  const {changeOrder, project, elevation} = props
  const [sender, setSender] = useState<CompanySenderReceiverInfo | null>(null)
  const [receiver, setReceiver] = useState<CompanySenderReceiverInfo | null>(null)
  const [coFiles, setCoFiles] = useState<any[]>([])
  const [total, setTotal] = useState<number>(changeOrder.is_lump_sum ? changeOrder.lump_sum_total : 0)

  useEffect(() => {
    if (!!changeOrder?.id && !!project?.id) {
      // get sender and receiver for this ticket
      getChangeOrderSenderReceiver(project.id, changeOrder.id).then(
        (res) => {
          const {sender, receiver} = res.data
          setSender(sender)
          setReceiver(receiver)
        }, // TODO BETTER ERROR HANDLING
      )

      // get files for this ticket
      getChangeOrderFiles(project.id, changeOrder.id).then(
        (res: any) => {
          if (res?.data?.length > 0) {
            setCoFiles(res.data)
          }
        }, // TODO BETTER ERROR HANDLING
      )
    }
  }, [changeOrder?.id, project?.id])

  return !!changeOrder?.id && !!project?.id ?  (
    <Paper className={classes.root} elevation={elevation ?? 2}>
      <InvoiceTopSection
        isMobile={isMobile}
        info={{number: changeOrder.number, notes: changeOrder.notes}}
        sender={sender}
        senderLogoUrl={sender?.logo_url}
        receiver={receiver}
        files={coFiles}
        invoiceDetails={(
          <ChangeOrderInvoiceTopDetails
            summary={createChangeOrderInvoiceTopSummary(changeOrder, project)}
            scope={changeOrder.scope}
            exclusion={changeOrder.exclusion}
          />
        )}
      />

      <ChangeOrderTicketList
        projectId={project.id}
        changeOrderId={changeOrder.id}
        setTicketTotal={setChangeOrderTotalFromTickets(changeOrder.is_lump_sum, setTotal)}
        isLumpSum={changeOrder.is_lump_sum}
        total={total}
      />

      {/* If change order is lump sum, we display total at the bottom as text */
        changeOrder.is_lump_sum
          ? <Grid item container xs={12} justifyContent="flex-end">
            <Typography variant="body2" style={{ fontWeight: 800, fontSize: 16, paddingTop: 10, paddingBottom: 20 }}>
              Subtotal: {formatMoney(total)}
            </Typography>
          </Grid>
          : <></>
      }

      <ChangeOrderMarkup projectId={project.id} changeOrderId={changeOrder.id} subtotal={total}/>

      <Grid item container xs={12} justifyContent="flex-end">
        <Typography variant="body2" style={{ fontWeight: 800, fontSize: 20, paddingTop: 10 }}>
          Total: {formatMoney(changeOrder.total_amount)}
        </Typography>
      </Grid>

      <ChangeOrderInvoiceSignature coId={changeOrder.id} projectId={project.id}/>
    </Paper>
  ) : <></>
}
