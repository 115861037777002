import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"

import { EstimateLineItem } from "../../../../../../api/estimate"
import EstimateLineItemsTable from "../../../../../../components/EstimateLineItemsTable"


interface EstimateLineItemsProps {
  isLumpSum: boolean,
  lineItems: EstimateLineItem[]
}

export default function EstimateLineItems({
  isLumpSum, lineItems
}: EstimateLineItemsProps) {
  const { t } = useTranslation("private")

  return lineItems.length > 0 ? (
    <>
      <Grid item xs={12} marginTop={5}>
        <Typography variant="h2">{t("view.Bulletins.SingleBulletin.lineItems")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <EstimateLineItemsTable
          editable={false}
          showCosts={!isLumpSum}
          lineItems={lineItems}
        />
      </Grid>
    </>
  ) : (
    <></>
  )
}
