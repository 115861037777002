import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Container from "../../../../components/Container"
import TicketOverview from "./TicketOverview"
import ChangeOrderOverview from "./ChangeOrderOverview"
import OverviewTabs from "./OverviewTabs"
// @ts-ignore
import ticketOverviewIcon from "../../../../assets/icon-ticket-overview.svg"
// @ts-ignore
import changeordersOverviewIcon from "../../../../assets/icon-changeorders-overview.svg"
import GcOverview from "./GcOverview"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import SubOverview from "./SubOverview"


export default function Dashboard() {
  const { t } = useTranslation("private")
  const company = useTracFloSelector((state) => state.company)

  const tabs = [
    {
      content: <TicketOverview />,
      icon: ticketOverviewIcon,
      navigationText: t("view.Dashboard.ticketOverview"),
    },
    {
      content: <ChangeOrderOverview />,
      icon: changeordersOverviewIcon,
      navigationText: t("view.Dashboard.changeOrderOverview"),
    },
  ]

  return (
    <>
      <Container fullWidth removeBottom>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Dashboard.title")}</Typography>
          </Grid>
        </Grid>
      </Container>
      { company.company_type === "cm" ?
          <GcOverview/>
        : <></>
      }
      {
        company.company_type === "trade" ?
          <SubOverview/>
        : <></>
      }
      <OverviewTabs tabs={tabs} />
    </>
  )
}
