import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import SignatureAndTextFieldForm, {SignatureAndTextFieldFormValues} from "../../../../../../../forms/SignatureAndTextFieldForm"
import {hasFilesForUpload} from "../../../../../../../util/file"
import {
  notifyForCoApprove,
  submitChangeOrderForApprove,
  uploadChangeOrderReviewProcessFiles,
  uploadChangeOrderReviewProcessSignature
} from "../../../../../../../api/changeOrderReviewProcess"


interface ApproveFormProps {
  toggleFormOpen: () => void,
  changeOrderId: string,
  projectId: string,
  changeOrderActionChange: (new_action_id: number) => void
}

export default function ApproveForm(props: ApproveFormProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const submitForm = async (values: SignatureAndTextFieldFormValues): Promise<void> => {
    try {
      // update the change order status
      const {data: corp} = await submitChangeOrderForApprove(
        props.changeOrderId,
        props.projectId,
        values.message.length === 0 ? null : values.message
      )
      // upload files
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
        const fileUploadMessage = await uploadChangeOrderReviewProcessFiles(values.files, props.projectId, corp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      // upload signature
      const signatureUploadMessage = await uploadChangeOrderReviewProcessSignature(values.signature, props.projectId, corp.id)
      if (signatureUploadMessage.error) {
        enqueueSnackbar(signatureUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      }
      // notify of the approval and create history object
      await notifyForCoApprove(corp.id, props.projectId)
      props.toggleFormOpen()
      if (corp?.new_change_order_action_id) {
        props.changeOrderActionChange(corp.new_change_order_action_id)
      }
      enqueueSnackbar(t('view.ChangeOrder.approve_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.ChangeOrder.approve_fail'), {variant: "error"})
    }
  }  
  return (
    <FormSmallContainer>
      <SignatureAndTextFieldForm
        submitValues={submitForm}
        formCancel={props.toggleFormOpen}
        titleText={t('view.ChangeOrder.approve')}
      />
    </FormSmallContainer>
  )
}
