import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material"

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import ScrollToLink from "../../../../../../components/ScrollToLink"
import { getCompany } from "../../../../../../store/features/companySlice"
import { ProjectUserRoleCode } from "../../../../../../types/userRoles"
import TicketReviewButtons from "./TicketReviewButtons"
import TicketEditButton from "./TicketEditButton"
import TicketCostEditButton from "./TicketCostEditButton"
import BackToDraftOrCostEdit from "./BackToDraftOrCostEdit"
import TicketDownloadButton from "./TicketDownloadButton"
import {getChangeOrderById} from "../../../../../../api/changeOrder"
import TicketActionText from "./TicketActionText"
import { ActionInfo, ChangeOrderActionCode, ActionCode } from "../../../../../../types/action"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import { CompanyStore } from "../../../../../../types/company"
import TicketStatusText from "./TicketStatusText"
import {useDispatch} from 'react-redux'
import {
  updateTicket,
  updateTicketAction,
  updateTicketCode,
  updateTicketEstimate,
} from '../../../../../../store/features/ticketsSlice'
import { AttachEstimateToTicketOutput } from "../../../../../../api/ticket"
import { TicketAttachEstimate } from "./TicketAttachEstimate"
import {Ticket} from '../../../../../../types/ticket'
import {TicketAttachCode} from './TicketAttachCode'
import {getCustomCodes} from '../../../../../../store/features/customCodeSlice'


interface TicketReviewSectionProps {
  ticket: Ticket,
  historyRef: any,
  projectId: string,
  projectUserRole: ProjectUserRoleCode,
  setTicket: (t: Ticket) => void,
}

function TicketReviewSection(
  {ticket, historyRef, projectId, projectUserRole, setTicket}: TicketReviewSectionProps
): JSX.Element {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const company: CompanyStore = useTracFloSelector(getCompany)
  const project = useTracFloSelector((state) => state.project)
  const codes = useTracFloSelector(getCustomCodes)
  const { t } = useTranslation("private")
  const actionIdObjMapping = useTracFloSelector((state) => state?.action?.idObjectMapping)
  const [ticketActionInfo, setTicketActionInfo] = React.useState(!!actionIdObjMapping && !!ticket ? actionIdObjMapping[ticket.action_id] : null)
  const [attachedCo, setAttachedCo] = React.useState<any|null>(null)
  const coActionIdObjMapping = useTracFloSelector((state) => state?.changeOrderAction?.idObjectMapping)
  const [changeOrderActionInfo, setChangeOrderActionInfo] = React.useState<ActionInfo | null>(
    !!attachedCo && !!coActionIdObjMapping
      ? coActionIdObjMapping[attachedCo.change_order_action_id]
      : null
  )

  React.useEffect(() => {
    setTicketActionInfo(!!actionIdObjMapping && !!ticket ? actionIdObjMapping[ticket.action_id] : null)
  }, [actionIdObjMapping, ticket])

  React.useEffect(() => {
    if(ticket?.change_order_id){
      getChangeOrderById(projectId, ticket?.change_order_id)
      .then((changeOrderData) => {
        setAttachedCo(changeOrderData)
      })
      .catch(/* intentionally blank */)
    }
  }, [ticket.change_order_id, projectId])

  React.useEffect(() => {
    setChangeOrderActionInfo(
      !!attachedCo && !!coActionIdObjMapping
        ? coActionIdObjMapping[attachedCo.change_order_action_id]
        : null
    )
  }, [attachedCo, coActionIdObjMapping])

  function ticketInvoiceChange(date_invoice: string | null, invoice_number: string | null): void {
    const newTicket: Ticket = {...ticket, date_invoice, invoice_number}
    // update locally and globally
    setTicket(newTicket)
    dispatch(updateTicket({data: newTicket, ccs: codes}))
  }

  function ticketActionChange(new_action_id: number) {
    if(actionIdObjMapping[new_action_id]){
      setTicketActionInfo(actionIdObjMapping[new_action_id])
      dispatch(updateTicketAction({new_action_id, ticket_id: ticket.id}))
    }
  }

  const attachEstimate = (output: AttachEstimateToTicketOutput) => {
    setTicket({...ticket, ...output})
    dispatch(updateTicketEstimate(
      {new_estimate_id: output.estimate_id, new_pco_number: output.pco_number, ticket_id: ticket.id}
    ))
  }

  // Note: PCO number does not change when removing estimate
  const removeEstimate = () => {
    setTicket({...ticket, estimate_id: null})
    dispatch(updateTicketEstimate(
      {new_estimate_id: null, new_pco_number: ticket.pco_number, ticket_id: ticket.id}
    ))
  }

  const attachCode = (ccId: string) => {
    const custom_code_ids = ticket.custom_code_ids == null
      ? [ccId]
      : [...ticket.custom_code_ids, ccId]
    setTicket({...ticket, custom_code_ids})
    dispatch(updateTicketCode(
      {cc_ids: custom_code_ids, ticket_id: ticket.id, ccs: codes}
    ))
  }

  const removeCode = (ccId: string) => {
    const custom_code_ids = ticket.custom_code_ids == null
      ? []
      : [...ticket.custom_code_ids.filter((cc) => cc !== ccId)]
    setTicket({...ticket, custom_code_ids})
    dispatch(updateTicketCode(
      {cc_ids: custom_code_ids, ticket_id: ticket.id, ccs: codes}
    ))
  }

  return ticketActionInfo != null ? (
    <>
      <Container>
        <Grid container spacing={2} justifyContent={isMobile ? "center" : "space-between"}>
          <Grid item xs={6} md={7}>
            <Typography variant="h1">{t("view.Tickets.ticket")}</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginRight: -12, marginTop: -12, textAlign: "right" }}
          >
            <TicketDownloadButton projectId={projectId} ticketId={ticket.id}/>

            <TicketEditButton
              projectId={projectId}
              ticketId={ticket.id}
              companyType={company.company_type}
              ticketActionCode={ticketActionInfo.code as ActionCode}
              hasAttachedCo={!!attachedCo}
              projectLicenseIsActive={project?.project_license_is_active ?? false}
            />
            <BackToDraftOrCostEdit
              companyType={company.company_type}
              projectId={projectId}
              ticket={ticket}
              ticketActionCode={ticketActionInfo.code as ActionCode}
              ticketActionChange={ticketActionChange}
              hasAttachedCo={!!attachedCo}
            />
            <TicketCostEditButton
              projectId={projectId}
              ticketId={ticket.id}
              companyType={company.company_type}
              ticketActionCode={ticketActionInfo.code as ActionCode}
              projectUserRole={projectUserRole}
              changeOrderActionCode={changeOrderActionInfo?.code as ChangeOrderActionCode}
            />
          </Grid>
          <Grid item xs={12}>


            <TicketStatusText
              ticket={ticket}
            />

            <TicketActionText
              attachedCo={attachedCo}
              projectId={projectId}
              ticketActionInfoCode={ticketActionInfo.code as ActionCode}
              companyType={company.company_type}
            />

            <Grid item xs={12}>
              <TicketAttachEstimate
                estimate_id={ticket.estimate_id}
                ticketId={ticket.id}
                attachEstimate={attachEstimate}
                removeEstimate={removeEstimate}
              />
            </Grid>

            <Grid item xs={12}>
              <TicketAttachCode
                ticketId={ticket.id}
                ccIds={ticket.custom_code_ids ?? []}
                attachCode={attachCode}
                removeCode={removeCode}
              />
            </Grid>

            <Grid item xs={12} paddingTop={2}>
              <ScrollToLink targetRef={historyRef}>
                {t("view.ChangeOrder.Summary.history")}
              </ScrollToLink>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <TicketReviewButtons
        ticket={ticket}
        ticketActionInfo={ticketActionInfo}
        ticketActionChange={ticketActionChange}
        projectId={projectId}
        hasAttachedCo={!!attachedCo}
        projectUserRole={projectUserRole}
        isLicenseOwner={company.id === project.license_owner}
        ticketInvoiceChange={ticketInvoiceChange}
      />
    </>
  ) : <></>
}

export default TicketReviewSection
