import React from "react"
import {useTranslation} from "react-i18next"
import { useSnackbar } from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import OneTextFieldAndUploadForm, { OneTextFieldAndUploadFormFields } from '../../../../../../../forms/OneTextFieldAndUploadForm'
import { 
  createHistoryForTradeManualGcCostApprove, 
  submitTradeManualGcCostApprove, 
  uploadTicketReviewProcessFiles 
} from "../../../../../../../api/ticketReviewProcess"
import { hasFilesForUpload } from "../../../../../../../util/file"


interface TradeManualGcCostApproveFormProps {
  toggleFormOpen: () => void,
  ticketId: string,
  projectId: string,
  ticketActionChange: (new_action_id: number) => void
}

export default function TradeManualGcCostApproveForm(
  {toggleFormOpen, ticketId, projectId, ticketActionChange}: TradeManualGcCostApproveFormProps
): JSX.Element {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const submitForm = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    try {
      const {status, data: trp} = await submitTradeManualGcCostApprove(
        ticketId,
        projectId,
        values.message.length === 0 ? null : values.message
      )
      if (status === 200 && trp.id != null) {
        // upload files
        if (hasFilesForUpload(values.files)) {
          const uploadingKey = enqueueSnackbar(t('view.Tickets.TradeManualGcCostApprove.uploadingFiles'), {variant: 'info'})
          const fileUploadMessage = await uploadTicketReviewProcessFiles(values.files, projectId, trp.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        if (trp?.new_action_id) {
          ticketActionChange(trp.new_action_id)
        }
        // create trade manual gc cost approve history object with files
        const createHistoryRes = await createHistoryForTradeManualGcCostApprove(trp.id, projectId)
        toggleFormOpen()
        if (createHistoryRes.status >= 400) {
          enqueueSnackbar(t('view.Tickets.TradeManualGcCostApprove.creatingHistoryError'), {variant: "error"})
          return
        }
        // inform
        enqueueSnackbar(t('view.Tickets.TradeManualGcCostApprove.finishedSuccessfully'), {variant: "success"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.TradeManualGcCostApprove.manualApproveFailed'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <OneTextFieldAndUploadForm
        onCancel={toggleFormOpen}
        submitForm={submitForm}
        titleText={t('view.Tickets.tradeManualGcCostApproveTitle')}
        textFieldRequired={true}
      />
    </FormSmallContainer>
  )
}
