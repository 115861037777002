import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {Bulletin} from '../../../../../types/bulletin'
import {File} from '../../../../../types/file'
import {
  BulletinSubcontractor, editBulletin,
  getBulletinById,
  getBulletinFiles,
  getBulletinSubcontractors, uploadBulletinFiles,
} from '../../../../../api/bulletin'
// @ts-ignore
import Container from '../../../../../components/Container'
import CreateBulletin from '../../../../../forms/CreateBulletin'
import {CreateBulletinInitialValues} from '../../../../../forms/CreateBulletin.types'
import {createInitialFormValues} from './EditBulletin.data'
import {convertFormValuesToApiData} from '../../../../../forms/CreateBulletin.submit'
import {updateBulletin} from '../../../../../store/features/bulletinSlice'
import {goToSingleBulletin} from '../../../../../util/routes'


export default function EditBulletin(): JSX.Element {
  const { t } = useTranslation("private")
  const project = useTracFloSelector((state) => state.project)
  const { bulletinId, projectId } = useParams<{bulletinId: string, projectId: string}>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const dispatch = useDispatch()
  const [bulletin, setBulletin] = useState<Bulletin | null>(null)
  const [files, setFiles] = useState<File[] | null>(null)
  const [bulletinSubs, setBulletinSubs] = useState<BulletinSubcontractor[] | null>(null)
  // NOTE: this needs to be updated if more bulletin-related states are added
  const readyToInitialize: boolean = bulletin != null && bulletinSubs != null && files != null && project != null

  const initialValues = useMemo(() => {
    if (bulletin != null && bulletinSubs != null) {
      return createInitialFormValues(bulletin, bulletinSubs)
    }
    return null
  }, [bulletin, bulletinSubs])

  const submitEditedBulletin = async (values: CreateBulletinInitialValues, nextBulletinPcoNumber: string | null): Promise<void> => {
    try {
      const res = await editBulletin(projectId, bulletinId, convertFormValuesToApiData(values, nextBulletinPcoNumber))
      if (res.status === 200 && res.data.id) {
        const bulletin = res.data
        enqueueSnackbar(t("view.Bulletins.EditBulletin.submitSuccess"), {
          variant: "success",
        })
        dispatch(updateBulletin(bulletin))
        // need to check that files actually exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar(
            t("view.Bulletins.EditBulletin.uploadingFiles"),
            {variant: 'info'}
          )
          const fileUploadMessage = await uploadBulletinFiles(values.files, project.id, bulletin.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleBulletin(project.id, bulletin.id))
      } else {
        enqueueSnackbar(t("view.Bulletins.EditBulletin.submitFail"), {
          variant: "error",
        })
      }
    } catch(_) {
      enqueueSnackbar(t("view.Bulletins.EditBulletin.submitFail"), {
        variant: "error",
      })
    }
  }

  useEffect(() => {
    if (project.id === projectId) {
      getBulletinById(projectId, bulletinId).then((res) => {
        if (res.status === 200 && res.data) {
          setBulletin(res.data)
        }
      })

      getBulletinFiles(projectId, bulletinId).then((res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setFiles(res.data)
        }
      })

      getBulletinSubcontractors(projectId, bulletinId).then((res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setBulletinSubs(res.data)
        }
      })
    }
  }, [project?.id, projectId, bulletinId])

  return project?.id === projectId
    ? (
      <>
        <Container>
          <Typography variant="h1">{t("view.Bulletins.EditBulletin.header")}</Typography>
        </Container>

        {
          readyToInitialize
            ? <CreateBulletin
              projectId={projectId}
              submitValues={submitEditedBulletin}
              existingFiles={files}
              submitButtonText={t("view.Bulletins.EditBulletin.submitButton")}
              initialValues={initialValues}
              editingBulletin={true}
            />
            : <></>
        }
      </>
    )
    : (
      <></>
    )
}
