import { GridColDef } from "@mui/x-data-grid"

//@ts-ignore
import Link from "../../../../../components/Link"
import { formatDate } from "../../../../../libs/format"

export const CompaniesCreatedInLastSixtyDaysColumns = (
  t: (s: string) => string
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("view.Admin.companyName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 450,
      renderCell: (params: any) => (
        <Link to={`/0/admin/companies/${params.id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "date_created",
      headerName: t("view.Admin.companyDateCreated"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 350,
      renderCell: (params: any) => formatDate(params.value)
    }
  ]
}