import React from "react"
import { useTranslation } from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, InputLabel, Theme, Typography } from "@mui/material"

import { useSnackbar } from "notistack"

//@ts-ignore
import Button from "../Button"
import { roundToDecimal } from "../../util/number"
import { bytesInMB } from "../../util/file"

const useStyles = makeStyles<Theme>((theme) => ({
  upload: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    objectFit: "contain"
  }
}))

interface UploadImageProps {
  file: any,
  setImageFile: (file: any) => void, 
  fileSizeLimitInMBs: number
}

export default function UploadImage(props: UploadImageProps) {
  const classes = useStyles()
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [image, setImage] = React.useState("")

  const fileInputRef = React.createRef<any>()
  return (
    <>
      <Grid container spacing={1} sx={{ paddingBottom: 2 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              border: 1,
              borderColor: "#0000001F",
              borderRadius: "5px",
              borderStyle: "dashed",
              padding: 10,
            }}
          >
            <Grid item xs={12} container justifyContent={"center"}>
              <Box
                sx={{
                  backgroundColor: "#E3EEF9",
                  padding: 1,
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                }}
              >
                <UploadFileIcon
                  onClick={() => 
                    fileInputRef.current.click()
                  }
                  fontSize="large"
                  className={classes.upload}
                />
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <Typography
                onClick={() => 
                  fileInputRef.current.click()
                }
                className={classes.upload}
              >
                {t("view.Settings.click_to_upload")}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <InputLabel>
                {t("view.Settings.file_constraints", {fileSizeLimitInMBs: props.fileSizeLimitInMBs })}
              </InputLabel>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6} container alignItems="center">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(event: any) => {
              if (event.currentTarget.files.length > 0) {
                // file limit is checked here if applicable
                if (event.currentTarget.files[0].size > props.fileSizeLimitInMBs * bytesInMB) {
                  enqueueSnackbar(
                    `Image file is too large. Images must be smaller than ${props.fileSizeLimitInMBs} MBs`,
                    { variant: "warning" }
                  )
                  return
                }
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
                props.setImageFile(event.currentTarget.files[0])
              }
            }}
            ref={fileInputRef}
          />
        </Grid>
      </Grid>
      {props.file.name ? (
        <>
          <Grid item xs={12} container sx={{ paddingTop: 5 }}>
              <Grid item xs={2} container justifyContent="center" alignContent="center">
                <Box
                  sx={{
                    backgroundColor: "#E3EEF9",
                    padding: 1,
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <UploadFileIcon fontSize="large" sx={{ color: "#143366" }} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Typography>{props.file.name ?? "No file chosen"}</Typography>
                <InputLabel>{`${roundToDecimal(props.file.size / bytesInMB, 2)} MB`}</InputLabel>
              </Grid>
              <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
                <Button
                  color="secondary"
                  onClick={() => {
                    props.setImageFile({name: ""})
                  }}
                  size="small"
                  variant="text"
                >
                  <DeleteIcon fontSize="medium" />
                </Button>
              </Grid>
          </Grid>
          <Grid item xs={12} container sx={{ paddingTop: 5 }}>
            <InputLabel>{t("view.Settings.preview")}</InputLabel>
            <Grid item xs={12} container sx={{ paddingTop: 3 }} justifyContent={"center"}>
              <img className={classes.image} src={image} alt={props.file.name}/>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>             
  )
}
