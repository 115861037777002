import React from "react"
import { useTranslation } from "react-i18next"

// @ts-ignore
import Container from "../components/Container"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import BreakdownField from "../components/Fields/Breakdown"
import {CreateDailyInitialValues} from './CreateDaily.types'
import {useTracFloSelector} from '../store/useTracFloSelector'
import CreateDailyLaborBreakdownField from './CreateDailyLaborBreakdownField'


type CreateDailyBreakdownProps = {
  setFieldValue: (f: string, value: any) => void,
  values: CreateDailyInitialValues,
  forCostEdit?: boolean,
}

export default function CreateDailyBreakdown(
  {setFieldValue, values, forCostEdit,}: CreateDailyBreakdownProps
) {
  const { t } = useTranslation("private")
  const [equipment, setEquipment] = React.useState<any[]>([])
  const [labor, setLabor] = React.useState<any[]>([])
  const [material, setMaterial] = React.useState<any[]>([])
  const project = useTracFloSelector(state => state.project)

  // update the table rows whenever breakdown objects are updated
  React.useEffect(() => {
    if (Array.isArray(values.materialBreakdown)) {
      setMaterial(
        values.materialBreakdown.map((row: any) => {
          return {...row}
        })
      )
    }
    // }
  }, [values.materialBreakdown])

  React.useEffect(() => {
    if (Array.isArray(values.equipmentBreakdown)) {
      setEquipment(
        values.equipmentBreakdown.map((row: any) => {
          return {...row}
        })
      )
    }
  }, [values.equipmentBreakdown])

  React.useEffect(() => {
    if (Array.isArray(values.laborBreakdown)) {
      setLabor(
        values.laborBreakdown.map((row: any) => {
          return {...row}
        })
      )
    }
  }, [values.laborBreakdown])


  return (
    <>
      <Container removeTop>
        <FormWideContainer>
          <CreateDailyLaborBreakdownField
            setFieldValue={setFieldValue}
            formType={values.type}
            value={labor}
            forCostEdit={forCostEdit ?? false}
            noTypesToAddMessage={
              project.project_user_role === 'crew'
                ? t("view.ChangeOrder.Labor.noTypeItemsCrew")
                : t("view.ChangeOrder.Labor.noTypeItemsSuper")
            }
            markupValue={values.laborMarkup}
            date={values.date_start}
          />
        </FormWideContainer>
      </Container>
      <Container removeTop>
        <FormWideContainer>
          <BreakdownField
            formType={values.type}
            label={t("view.ChangeOrder.Material.title")}
            markupValue={values.materialMarkup}
            name="material"
            setFieldValue={setFieldValue}
            value={material}
            breakdownTypeUrl={'/material'}
            forCostEdit={forCostEdit ?? false}
            buttonText={t("form.label.add_new")}
          />
        </FormWideContainer>
      </Container>
      <Container removeTop>
        <FormWideContainer>
          <BreakdownField
            formType={values.type}
            label={t("view.ChangeOrder.Equipment.title")}
            markupValue={values.equipmentMarkup}
            name="equipment"
            setFieldValue={setFieldValue}
            value={equipment}
            breakdownTypeUrl={'/equipment'}
            forCostEdit={forCostEdit ?? false}
            buttonText={t("form.label.add_new")}
          />
        </FormWideContainer>
      </Container>
    </>
  )
}
