import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {Labor} from '../types/labor'

export async function createLaborType(projectId: string, values: any): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: `/project/${projectId}/labor`,
    data: values,
  })
}

export async function createMultipleLaborTypes(projectId: string, laborTypes: any[]): Promise<AxiosResponse> {
  return api({
    method: "post",
    url: `/project/${projectId}/labors_for_subs`,
    data: laborTypes,
  })
}

export async function editLaborType(projectId: string, labor_id: string, values: any): Promise<AxiosResponse> {
  return api({
    method: "patch",
    url: `/project/${projectId}/labor/${labor_id}`,
    data: values,
  })
}

export async function importLaborTypeFromProject(
  projectId: string,
  data: {from_project_id: string, import_rates?: boolean}
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${projectId}/import_labor_types`,
    data,
  })
}

export async function getLaborTypesForCreateTicketForm(projectId: string): Promise<AxiosResponse<Labor[]>> {
  return api({
    method: "get",
    url: `/project/${projectId}/labor_for_ticket`,
  })
}
