import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'
import {useTheme} from '@mui/material'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {File} from '../../../../../types/file'
// @ts-ignore
import Container from '../../../../../components/Container'
import {
  editEstimate, EstimateLineItem, EstimateMarkup,
  getEstimateById,
  getEstimateFiles,
  getEstimateLineItems, getEstimateMarkup,
  uploadEstimateFiles,
} from '../../../../../api/estimate'
import {Estimate} from '../../../../../types/estimate'
import {CreateEstimateInitialValues} from '../../../../../forms/CreateEstimate.types'
import CreateEstimate from '../../../../../forms/CreateEstimate'
import {createInitialFormValues} from './EditEstimate.data'
import {updateEstimate} from '../../../../../store/features/estimateSlice'
import {goToSingleEstimate} from '../../../../../util/routes'
import {convertFormValuesToApiData} from '../../../../../forms/CreateEstimate.submit'
import BulletinDetailsForEstimates from '../../../../../components/BulletinDetailsForEstimates'


export default function EditEstimate(): JSX.Element {
  const { t } = useTranslation("private")
  const project = useTracFloSelector((state) => state.project)
  const { estimateId, projectId } = useParams<{estimateId: string, projectId: string}>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [estimate, setEstimate] = useState<Estimate | null>(null)
  const [files, setFiles] = useState<File[] | null>(null)
  const [lineItems, setLineItems] = useState<EstimateLineItem[] | null>(null)
  const [markup, setMarkup] = useState<EstimateMarkup[] | null>(null)
  // NOTE: this needs to be updated if more estimate-related states are added
  const readyToInitialize: boolean = estimate != null && files != null && project != null
    && lineItems != null && markup != null

  const initialValues = useMemo(() => {
    if (estimate != null && lineItems != null && markup != null) {
      return createInitialFormValues(estimate, lineItems, markup)
    }
    return null
  }, [estimate, lineItems, markup])

  const submitEditedBulletin = async (values: CreateEstimateInitialValues, nextEstimateNumber: string|null): Promise<void> => {
    try {
      const res = await editEstimate(projectId, estimateId, convertFormValuesToApiData(values, nextEstimateNumber))
      if (res.status === 200 && res.data.id) {
        const updatedEstimate = res.data
        enqueueSnackbar(t("view.Estimates.EditEstimate.submitSuccess"), {
          variant: "success",
        })
        dispatch(updateEstimate(updatedEstimate))
        // need to check that files actually exist
        if (values.files.length > 0 && !!values.files[0].name) {
          const uploadingKey = enqueueSnackbar(
            t("view.Estimates.EditEstimate.uploadingFiles"),
            {variant: 'info'}
          )
          const fileUploadMessage = await uploadEstimateFiles(values.files, project.id, estimateId)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        history.push(goToSingleEstimate(project.id, updatedEstimate.id))
      } else {
        enqueueSnackbar(t("view.Estimates.EditEstimate.submitFail"), {
          variant: "error",
        })
      }
    } catch (_) {
      enqueueSnackbar(t("view.Estimates.EditEstimate.submitFail"), {
        variant: "error",
      })
    }
  }

  useEffect(() => {
    if (project.id === projectId) {
      getEstimateById(projectId, estimateId).then((res) => {
        if (res.status === 200 && res.data) {
          setEstimate(res.data)
        }
      })

      getEstimateFiles(projectId, estimateId).then((res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setFiles(res.data)
        }
      })

      getEstimateLineItems(projectId, estimateId).then((res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setLineItems(res.data)
        }
      })

      getEstimateMarkup(projectId, estimateId).then((res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setMarkup(res.data)
        }
      })
    }
  }, [project?.id, projectId, estimateId])

  return project?.id === projectId
    ? (
      <>
        <Container removeBottom>
          <Typography variant="h1">{t("view.Estimates.EditEstimate.header")}</Typography>
        </Container>

        {
          estimate != null && estimate.bulletin_id != null
            ? <Container fullWidth style={{ background: theme.palette.grey[200] }} removeBottom>
              <BulletinDetailsForEstimates bulletinId={estimate.bulletin_id} projectId={projectId}/>
            </Container>
            : <></>
        }

        {
          readyToInitialize
            ? <Container>
              <CreateEstimate
                projectId={projectId}
                submitValues={submitEditedBulletin}
                initialValues={initialValues}
                existingFiles={files}
                submitButtonText={t("view.Estimates.EditEstimate.submitButton")}
              />
            </Container>
            : <></>
        }
      </>
    )
    : (
      <></>
    )
}
