// @ts-ignore
import { formatMoney, formatDate } from "../../../../../../libs/format"

export function createChangeOrderInvoiceTopSummary(
  changeOrder: any,
  project: any
): any[] {
  return [
    {
      title: "Client/PCO#",
      content: changeOrder.pco_number ? `#${changeOrder.pco_number}` : null,
    },
    {
      title: "Subject",
      content: changeOrder.subject ?? null,
    },
    {
      title: "Total",
      content: changeOrder.total_amount ? formatMoney(changeOrder.total_amount) : null,
    },
    {
      title: "Project #",
      content: project.number ? `#${project.number}` : null,
    },
    {
      title: "Project Name",
      content: project.name ?? null,
    },
    {
      title: "Work Date",
      content: changeOrder.date_start || changeOrder.date_end
        ? formatDate(changeOrder.date_start, changeOrder.date_end)
        : null,
    },
    {
      title: "Created",
      content: changeOrder.date_created ? formatDate(changeOrder.date_created) : null,
    },
    {
      title: "Submitted By",
      content: changeOrder.author ?? null,
    },
    {
      title: "Invoice #",
      content: changeOrder.invoice_number ?? null,
    },
  ]
}
