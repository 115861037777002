import React from "react"
import Grid from "@mui/material/Grid"

import DailyLaborBreakdown from "./DailyLaborBreakdown"
import DailyMaterialBreakdown from "./DailyMaterialBreakdown"
import DailyEquipmentBreakdown from "./DailyEquipmentBreakdown"
import { TicketMarkup } from "../../../../../../types/ticket"


interface DailyBreakdownsProps {
  dailyId: string,
  addRates: boolean,
  projectId: string,
  markup: TicketMarkup[],
  updateSubtotal: (type: 'labor' | 'material' | 'equipment', value: number) => void,
}

export default function DailyBreakdowns(
  {dailyId, addRates, projectId, markup, updateSubtotal}: DailyBreakdownsProps
) {
  return (
    <Grid container style={{ marginTop: 50 }}>
      <Grid item container xs={12} justifyContent="flex-start">
        <DailyLaborBreakdown
          addRates={addRates}
          dailyId={dailyId}
          projectId={projectId}
          markupRows={markup.filter((l) => l.markup_type === 'labor')}
          updateLaborSubtotal={(val) => updateSubtotal('labor', val)}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start">
        <DailyMaterialBreakdown
          addRates={addRates}
          dailyId={dailyId}
          projectId={projectId}
          markupRows={markup.filter((m) => m.markup_type === 'material')}
          updateMaterialSubtotal={(val) => updateSubtotal('material', val)}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start">
        <DailyEquipmentBreakdown
          addRates={addRates}
          dailyId={dailyId}
          projectId={projectId}
          markupRows={markup.filter((e) => e.markup_type === 'equipment')}
          updateEquipmentSubtotal={(val) => updateSubtotal('equipment', val)}
        />
      </Grid>
    </Grid>
  )
}
