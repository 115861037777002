import { Grid } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

//@ts-ignore
import SelectField from "../../../components/Fields/Select"
import { setActiveIntegration } from "../../../store/features/userSlice"
import { useTracFloSelector } from "../../../store/useTracFloSelector"


export default function IntegrationsDropdown() {
  const dispatch = useDispatch()
  const { t } = useTranslation("private")
  const userIntegrations = useTracFloSelector((state) => state.user.integrations)
  const integrationOptions = useTracFloSelector((state) => state.integration.items)
  const activeIntegrationId = useTracFloSelector((state) => state.user.activeIntegration?.integration_id)

  // Format user integrations to include the name of the integration on the label
  const userIntegrationsFormatted = useMemo(() => {
    return userIntegrations.map((integration) => {
      const integrationWithName = integrationOptions.find((integrationOption) => {
        return integrationOption.id === integration.integration_id
      })
      return {
        label: integrationWithName?.name,
        value: integrationWithName?.id
      }
    })
  }, [userIntegrations, integrationOptions])

  return userIntegrationsFormatted.length > 0 && activeIntegrationId ? (
    <Grid item xs={3}>
      <SelectField
        sx={{width: 180}}
        size="small"
        disabled={userIntegrationsFormatted.length <= 1}
        name="set_active_integration"
        label={t("view.Integrations.integrationDropdownTitle")}
        options={userIntegrationsFormatted}
        onChange={(e: any) => {
          // Make sure that selected id exists in the integrations user is connected to
          const selectedActiveIntegration = userIntegrations.find(
            (integration) => integration.integration_id === e.target.value
          )
          if(!!selectedActiveIntegration){
            dispatch(setActiveIntegration(selectedActiveIntegration))
          }
        }}
        value={activeIntegrationId}
      />
    </Grid>
  ) : <></>
}