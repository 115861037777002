import React from "react"
import { makeStyles } from "@mui/styles"
import MuiCard from "@mui/material/Card"
import MuiCardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Theme } from "@mui/material"

import theme from "../theme"


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: 10,
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #3751FF",
      color: "#3751FF",
      cursor: "pointer",
    },
  },
  nonClickable: {
    borderRadius: 10,
    border: "1px solid transparent",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 14,
  },
}))

const defaultNumberStyle = {
  color: theme.palette.secondary.main,
  fontSize: 28,
  fontWeight: 500,
}

interface MetricCardProps {
  title: string,
  large_number: string,
  small_number?: string,
  onClick?: () => void,
  numberStyle?: any,
}

const nonClickableCardNumbers = [
  "0",
  "-"
]

export default function MetricCard(
  {title, large_number, small_number, onClick, numberStyle}: MetricCardProps
): JSX.Element {
  const classes = useStyles()
  const cannotClickCard = !onClick || nonClickableCardNumbers.some((nonClickableNumber) => nonClickableNumber === large_number)
  const clickableClass = cannotClickCard ? classes.nonClickable : classes.root

  const numberStyles = typeof numberStyle === 'object'
    ? {...defaultNumberStyle, ...numberStyle}
    : defaultNumberStyle

  return (
    <MuiCard className={clickableClass} elevation={3} onClick={cannotClickCard ? undefined : onClick}>
      <MuiCardContent>
        <Typography textAlign="center" className={classes.title}>
          {title}
        </Typography>
        <Typography style={numberStyles} variant="h1" textAlign="center">
          {large_number}
        </Typography>
        {
          small_number ?
          <Typography style={numberStyles} textAlign="center">
            {small_number}
          </Typography>
          : <></>
        }
      </MuiCardContent>
    </MuiCard>
  )
}
