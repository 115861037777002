import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"


export async function financingApr2024Api(
  project_id: string,
  interested_in_financing: boolean
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: 'post',
    url: `/user_testing/financing_apr_2024`,
    data: {project_id, interested_in_financing}
  })
}
