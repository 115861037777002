import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { apiHeaders } from "../../api/util"
import { Labor, LaborStore } from "../../types/labor"
import { TracFloState } from "../../types/state"

const initialState: LaborStore = {
  items: [],
  exp: "",
  status: "idle",
}

export const loadLabor = createAsyncThunk<Labor[], void, {state: TracFloState}>(
  "user/loadLabor",
  async (args, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/labor`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const laborSlice = createSlice({
  name: "labor",
  initialState,
  reducers: {
    resetLabor: () => initialState,
    addLabor: (state, action) => {
      state.items.unshift(action.payload)
    },
    addMultipleLaborTypes: (state, action: {payload: Labor[]}) => {
      const newLabor = [...state.items, ...action.payload]
      newLabor.sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1)
      state.items = newLabor
    },
    updateLabor: (state, action) => {
      const newLabor = action.payload
      if (newLabor && newLabor.id) {
        state.items = state.items.map((item: Labor) => (item.id === newLabor.id ? newLabor : item))
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLabor.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadLabor.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadLabor.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          state.items = action.payload
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 60 // one hour
        }
      })
  },
})

export const { resetLabor, addLabor, updateLabor, addMultipleLaborTypes } = laborSlice.actions

export const getLaborTypes = (state: TracFloState) => state.labor.items

export default laborSlice.reducer
