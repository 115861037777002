import { useDispatch } from "react-redux"
import {GridColDef, GridColumnVisibilityModel} from "@mui/x-data-grid"

import { useTracFloSelector } from "../store/useTracFloSelector"
import {getTableColumns, setTableColumns} from '../store/features/tableColumnsSlice'


function getTableColVisModelFromColDefs(colDefs: GridColDef[]): {[p: string]: boolean} {
  const columnVis: {[p: string]: boolean} = {}
  for (let colDef of colDefs) {
    // since this boolean represents visibility, we take the opposite of hiding the column
    // (false is default hide value)
    columnVis[colDef.field] = !(colDef.hide ?? false)
  }
  return columnVis
}

export function useTableColumns(
  tableName: string,
  colDefs: GridColDef[]
): [GridColumnVisibilityModel | undefined, (colVisModel: GridColumnVisibilityModel) => void] {
  const dispatch = useDispatch()
  const globalTableCols = useTracFloSelector(getTableColumns(tableName))

  const setGlobalTableCols = (newTableCols: GridColumnVisibilityModel) => {
    // update global state if it's different
    if (globalTableCols?.columnVisModel !== newTableCols) {
      dispatch(setTableColumns({tableName, columnVisModel: newTableCols}))
    }
  }

  if (colDefs.length > 0 && globalTableCols == null) {
    const initialColVisModel = getTableColVisModelFromColDefs(colDefs)
    dispatch(setTableColumns({tableName, columnVisModel: initialColVisModel}))
  }

  return [globalTableCols?.columnVisModel, setGlobalTableCols]
}
