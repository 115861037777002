import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"


export async function addProjectIntegration(
  project_id: string,
  user_integration_id: string,
  project_id_linked: string,
// returns project ID if successful
): Promise<AxiosResponse<{id: string}>> {
  return api.post(`/project/${project_id}/integration`, {user_integration_id, project_id_linked})
}

export async function changeProjectIsActiveStatus(
  is_active_status: boolean,
  projectId: string,
): Promise<AxiosResponse<{project_id: string}>> {
  return api({
    method: "patch",
    url: `/project/${projectId}/is_active_status`,
    data: {is_active: is_active_status},
  })
}

export async function getAllCompanyProjects(): Promise<AxiosResponse<{id: string, name: string}[]>> {
  return api({
    method: "get",
    url: `/all-project`,
  })
}
