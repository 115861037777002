import { Grid, Typography } from "@mui/material"
import React from "react"

import { formatDate } from "../format"

export const subcontractorColumns = [
  {
    align: "left",
    headerAlign: "left",
    field: "name",
    headerName: "Name",
    minWidth: 100,
    flex: 1,
    height: 40,
    sortable: false,
    renderCell: (params) => (
      <Grid style={{ paddingTop: 6, paddingBottom: 6 }}>
        <Typography style={{ color: "#143366", fontSize: 17, fontWeight: "bold" }}>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    align: "right",
    headerAlign: "right",
    field: "count_of_tickets",
    headerName: "TIX",
    minWidth: 100,
    editable: false,
    sortable: false,
    renderCell: (params) => (
      <Typography style={{ color: "#143366", fontSize: 14, fontWeight: "bold" }}>
        {params.value ? params.value : "—"}
      </Typography>
    ),
  },
  {
    align: "right",
    headerAlign: "right",
    field: "date_added",
    headerName: "Date Added",
    width: 120,
    editable: false,
    sortable: false,
    renderCell: (params) => (
      <Typography style={{ color: "#989898", fontSize: 14 }}>
        {params.value ? formatDate(params.value) : "—"}
      </Typography>
    ),
  },
]
