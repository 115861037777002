import React from "react"

// @ts-ignore
import ButtonDelete from "./ButtonDelete"
import { formatMoney } from "../libs/format"
import TinyTextField from "../forms/TinyTextField"
import {BreakdownTableColumn} from './BreakdownTable.columns'
import {HandleFieldChangeObject} from '../types/form'
import {convertToNumber} from '../util/number'


interface EstimateMarkupTableColumnsInput {
  subtotal: number,
  editable: boolean,
  handleDeleteRow?: (i: number) => void,
  handleFieldChange?: (i: number, fc: HandleFieldChangeObject[]) => void,
  t: (s: string) => string,
}

export default function EstimateMarkupTableColumns(
  {editable, handleDeleteRow, t, handleFieldChange, subtotal}: EstimateMarkupTableColumnsInput
): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "description",
      headerName: t("component.EstimateMarkupTable.descriptionColLabel"),
      flex: 2,
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "percent",
      flex: 1,
      headerName: t("component.EstimateMarkupTable.percentColLabel"),
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return editable && handleFieldChange != null
          ? (<TinyTextField
            fieldWidth={'100px'}
            value={params.value}
            handleChange={
              (newValue) => handleFieldChange(
                // params.index is "1 indexed", so we need to subtract 1
                params.index - 1,
                [{fieldPropName: 'percent', newValue}],
              )
            }
            isPercent={true}
            isNumber={true}
          />)
          : `${params.value}%`
      },
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total",
      flex: 1,
      headerName: t("component.EstimateMarkupTable.totalColLabel"),
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(
        (convertToNumber(params.percent) ?? 0) / 100
        * subtotal
      ),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "",
      flex: 1,
      headerName: "",
      hide: !editable,
      width: 50,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <ButtonDelete
            onClick={() => {
              if (handleDeleteRow) {
                handleDeleteRow(params.index)
              }
            }}
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          />
        )
      },
    },
  ]
}
