import Grid from "@mui/material/Grid"
import {Form, Formik} from "formik"
import Typography from "@mui/material/Typography"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import makeStyles from "@mui/styles/makeStyles"
import {Theme, useTheme} from "@mui/material"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: "100%",
  },

  text: {
    "& .MuiListItemText-primary": {
      fontSize: "13px",
      fontWeight: "normal",
    },
  },
  textBlock: {
    width: "100%",
  },

  attach: {
    height: 40,
    borderRadius: 0,
    textTransform: "capitalize",
  },

  cancelButton: {
    color: "#0092D6",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  clear: {
    color: "#143366",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  send: {
    height: 40,
    borderRadius: "5",
  },
}))


interface OneTextFieldFormProps {
  onCancel: () => void,
  submitForm: (values: any) => Promise<void>,
  titleText: string,
  textFieldRequired: boolean
}

export default function OneTextFieldForm(props: OneTextFieldFormProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const classes = useStyles()

  return (
    <Grid
      container
      style={{ paddingTop: 30, paddingBottom: 30 }}
      justifyContent={isMobile ? "center" : "flex-start"}
    >
      <Formik
        initialValues={{message: ""}}
        onSubmit={(values, { resetForm }) => {
          props.submitForm(values).then(
            // After we submit, reset form
            () => {resetForm()}
          )
        }}
      >
        {({
            handleBlur,
            values,
            setFieldValue,
          }) => {
          const handleChange = (event: any) => {
            setFieldValue(event.target.name, event.target.value)
          }

          return (
            <Form>
              <Grid item container xs={12}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Typography
                    variant="h6"
                    align={isMobile ? "center" : "left"}
                    style={{ fontSize: 20 }}
                  >
                    {props.titleText}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextAreaField
                    label={t("form.label.notes")}
                    value={values.message}
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={props.textFieldRequired}
                  />
                </Grid>

                <Grid item container xs={12} style={{ paddingTop: 20 }} alignItems="center"></Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item style={{ paddingRight: 20 }}>
                    <Button
                      type="reset"
                      className={classes.cancelButton}
                      color="secondary"
                      variant={null}
                      onClick={props.onCancel}
                    >
                      {t("view.ChangeOrder.cancel")}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      type="submit"
                      endIcon={<ArrowForwardIosIcon style={{ color: "white" }} />}
                      className={classes.send}
                    >
                      {t("view.ChangeOrder.Summary.send")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
