import React from "react"
import {GridColDef} from "@mui/x-data-grid"

import {ActionCode, ActionInfo,} from "../../../../../../types/action"
import {goToSingleTicket} from "../../../../../../util/routes"
import {formatMoney, formatDate} from '../../../../../../libs/format'
import FormatTableTitleLink from '../../../../../../components/FormatTableTitleLink'
import {
  AttachedTicketAddsToTotalActionCode,
} from '../../../../../../types/estimateAttachedObjects'
import Action from '../../../../../../components/Action'
import {Ticket} from '../../../../../../types/ticket'

export type AttachedTicket = Ticket & {co_is_attached_too: boolean}

export function ticketCountsTowardsTotal(
  ticket: AttachedTicket,
  actionIdToInfo: {[key: number]: ActionInfo<ActionCode>}
): boolean {
  // either not attached to change order, or attached CO is not attached to estimate
  // AND must have the correct action status
  return !ticket.co_is_attached_too
    && AttachedTicketAddsToTotalActionCode.includes(actionIdToInfo[ticket.action_id].code)
}

export default function AttachedTicketsListColumns (
  projectId: string,
  actionIdToInfo: {[key: number]: ActionInfo<ActionCode>},
  t: any,
): GridColDef<AttachedTicket>[] {
  return [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 110,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoSubjectColHeader"),
      width: 250,
      editable: false,
      renderCell: (params) => {
        return (
          <FormatTableTitleLink
            url={goToSingleTicket(projectId, params.row.id)}
            text={params.value}
            openNewWindow={false}
          />
        )
      },
    },
    {
      field: "first_submit_date",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoSubmittedColHeader"),
      renderCell: (params) => params.value ? formatDate(params.value) : '',
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 120,
      type: 'dateTime'
    },
    {
      align: "left",
      headerAlign: "center",
      field: "action_id",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoActionColHeader"),
      width: 180,
      editable: false,
      renderCell: (params) => {
        return <Action code={actionIdToInfo[params.row.action_id].code}/>
      },
      valueGetter: (params) => actionIdToInfo[params.value].name,
      type: 'string'
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: t("view.Estimates.SingleEstimate.AttachedObjects.attachedCoTotalColHeader"),
      width: 150,
      editable: false,
      renderCell: (params) => {
        return ticketCountsTowardsTotal(params.row, actionIdToInfo)
          ? formatMoney(params.value)
          : t('view.Estimates.SingleEstimate.AttachedObjects.attachedCoDoesNotCountTowardsTotal')
      },
    },
  ]
}
