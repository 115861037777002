import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// @ts-ignore
import Container from "../components/Container"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import FormSmallContainer from '../components/FormSmallContainer'
import Fab from '../components/Fab'
import {deleteAtIndex} from '../util/array'
import EstimateLineItemsTable, {EstimateLineItemForTable} from '../components/EstimateLineItemsTable'
import CreateEstimateLineItemsForm, {EstimateLineItemForForm} from './CreateEstimateLineItems.form'


interface CreateEstimateLineItemsProps {
  lineItems: EstimateLineItemForTable[],
  setLineItems: (s: EstimateLineItemForTable[]) => void,
  showCosts: boolean,
}

export default function CreateEstimateLineItems(
  {lineItems, setLineItems, showCosts}: CreateEstimateLineItemsProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [formActive, setFormActive] = useState<boolean>(false)

  const deleteLineItem = (atIndex: number) => {
    const atZeroIndex = atIndex - 1
    if (lineItems.length > atZeroIndex && atZeroIndex >= 0) {
      setLineItems(deleteAtIndex(lineItems, atZeroIndex))
    }
  }

  const addNewLineItem = (formLineItem: EstimateLineItemForForm) => {
    // @ts-ignore from the form is missing ID and estimate id, but doesnt cause an issue with table
    setLineItems([...lineItems, formLineItem])
    setFormActive(false)
  }

  return (
    <Container removeTop>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t('form.CreateEstimateLineItems.title')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <EstimateLineItemsTable
            editable={true}
            showCosts={showCosts}
            lineItems={lineItems}
            handleDeleteRow={deleteLineItem}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          {!formActive ? (
            <Fab
              variant="extended"
              onClick={() => setFormActive(!formActive)}
            >
              <AddIcon />
              {t('form.CreateEstimateLineItems.addButton')}
            </Fab>
          ) : (
            <FormSmallContainer>
              <CreateEstimateLineItemsForm
                addNewLineItem={addNewLineItem}
                closeForm={() => {setFormActive(false)}}
                showCosts={showCosts}
              />
            </FormSmallContainer>
          )}
        </Grid>
      </FormWideContainer>
    </Container>
  )
}
