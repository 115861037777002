import React from "react"
import {Switch} from "react-router-dom"

import AccountsView from "./Accounts"
import AccountSelectedRouter from "./AccountSelected/AccountSelected.Router"
import AdminRouter from "./Admin/Admin.Router"
import AuthenticatedRouteGuard from "./Authenticated.RouteGuard"
import {goToAccounts, goToIntegrations} from "../../util/routes"
import Integrations from "./Integrations"

export default function AuthenticatedRouter() {
  return(
    <Switch>
      <AuthenticatedRouteGuard exact path={goToIntegrations}>
        <Integrations />
      </AuthenticatedRouteGuard>
      <AuthenticatedRouteGuard exact path={goToAccounts}>
        <AccountsView />
      </AuthenticatedRouteGuard>
      <AuthenticatedRouteGuard path="/0/admin">
        <AdminRouter/>
      </AuthenticatedRouteGuard>
      <AuthenticatedRouteGuard path="/0">
        <AccountSelectedRouter />
      </AuthenticatedRouteGuard>
    </Switch>
  )
}
