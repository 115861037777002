import React from "react"

// @ts-ignore
import FormatRowId from "../../../../../../components/FormatRowId"
import FormatTableTitle from "../../../../../../components/FormatTableTitle"
// @ts-ignore
import { formatMoney } from "../../../../../../libs/format"
import {BreakdownTableColumn} from "../../../../../../components/BreakdownTable.columns"

interface EquipmentBreakdownColumnsProps {
  addRates: boolean,
  t: (s: string) => string
}

export default function EquipmentBreakdownColumns({addRates, t}: EquipmentBreakdownColumnsProps): BreakdownTableColumn[] {
  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: t("view.TicketBreakdowns.type"),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => <FormatTableTitle>{params.value}</FormatTableTitle>,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "cost_code",
      headerName: t("view.TicketBreakdowns.cost_code"),
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "unit",
      headerName: t("view.TicketBreakdowns.unit"),
      flex: 1,
      minWidth: 50,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "quantity",
      headerName: t("view.TicketBreakdowns.quantity"),
      width: 70,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "rate",
      headerName: t("view.TicketBreakdowns.rate_unit"),
      hide: !addRates,
      flex: 1,
      minWidth: 70,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.value, true),
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_cost",
      headerName: t("view.TicketBreakdowns.total_cost"),
      hide: !addRates,
      flex: 1,
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params: any) => formatMoney(params.rate * params.quantity),
    },
  ]
}
