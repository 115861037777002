import React from "react"
import CssBaseline from "@mui/material/CssBaseline"

import AppRouter from "./App.Router"
import CookieConsent from "./components/CookieConsent"
import {startFullstory} from "./libs/fullstory"

function setMetaDescription() {
  const metaElements = document.getElementsByTagName('meta')
  for (const el of metaElements) {
    if (el.name === 'description') {
      el.content = 'Work smarter with real-time cost tracking!'
    }
  }
}

// FullStory integration to record customer sessions
startFullstory()

export default function App() {
  // Set the description
  setMetaDescription()

  return (
    <div id="App" className="App">
      <CssBaseline />
      <AppRouter />
      <CookieConsent />
    </div>
  )
}
