import * as React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Grid, Typography } from "@mui/material"
import {GridFilterItem, GridFilterModel, GridLinkOperator} from "@mui/x-data-grid"

// @ts-ignore
import Container from "../../../../../components/Container"
import MetricCard from "../../../../../components/MetricCard"
import { getTicketsWaitingForReview, getTicketsApprovedByUser } from "../../../../../api/ticket"
import { getChangeOrdersWaitingForReview, getChangeOrdersApprovedByUser } from "../../../../../api/changeOrder"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import { goToChangeOrdersList, goToTicketsList } from "../../../../../util/routes"


export default function GcOverview() {
  const { t } = useTranslation("private")
  const history = useHistory<{filter: GridFilterModel}>()
  const { projectId } = useParams<{ projectId: string }>()
  const project = useTracFloSelector((state) => state.project)
  const [ticketsWaitingForReview, setTicketsWaitingForReview] = React.useState<string[]>([])
  const [ticketsApprovedByUser, setTicketsApprovedByUser] = React.useState<string[]>([])
  const [cosWaitingForReview, setCOsWaitingForReview] = React.useState<string[]>([])
  const [cosApprovedByUser, setCOsApprovedByUser] = React.useState<string[]>([])

  React.useEffect(() => {
    if (projectId && project && project.id === projectId) {
      getTicketsWaitingForReview(projectId).then(async (res) => {
        if (res.data) {
          setTicketsWaitingForReview(res.data)
        }
      })
      getTicketsApprovedByUser(projectId).then(async (res) => {
        if (res.data) {
          setTicketsApprovedByUser(res.data)
        }
      })
      getChangeOrdersWaitingForReview(projectId).then(async (res) => {
        if (res.data) {
          setCOsWaitingForReview(res.data)
        }
      })
      getChangeOrdersApprovedByUser(projectId).then(async (res) => {
        if (res.data) {
          setCOsApprovedByUser(res.data)
        }
      })
    }
  }, [projectId, project])

  const tixApprovedByUserFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: ticketsApprovedByUser
    },
  ]

  const cosApprovedByUserFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: cosApprovedByUser
    },
  ]

  const tixWaitingForReviewFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: ticketsWaitingForReview
    },
  ]

  const cosWaitingForReviewFilter: GridFilterItem[] = [
    {
      columnField: 'id',
      operatorValue: 'isAnyOf',
      value: cosWaitingForReview
    },
  ]

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h2" sx={{color: "#000000", opacity: "87%"}}>
              {t("view.Dashboard.waitingYourReview")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.GcCardTitles.tix")}
                  large_number={ticketsWaitingForReview.length.toString()}
                  onClick={() => {
                    history.push(
                      goToTicketsList(projectId),
                      {filter: {items: tixWaitingForReviewFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.GcCardTitles.cos")}
                  large_number={cosWaitingForReview.length.toString()}
                  onClick={() => {
                    history.push(
                      goToChangeOrdersList(projectId),
                      {filter: {items: cosWaitingForReviewFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h2" sx={{color: "#000000", opacity: "87%"}}>
              {t("view.Dashboard.approvedByYou")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.GcCardTitles.tix")}
                  large_number={ticketsApprovedByUser.length.toString()}
                  onClick={() => {
                    history.push(
                      goToTicketsList(projectId),
                      {filter: {items: tixApprovedByUserFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MetricCard
                  title={t("view.Dashboard.GcCardTitles.cos")}
                  large_number={cosApprovedByUser.length.toString()}
                  onClick={() => {
                    history.push(
                      goToChangeOrdersList(projectId),
                      {filter: {items: cosApprovedByUserFilter, linkOperator: GridLinkOperator.And}}
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
