import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import DocumentIcon from "@mui/icons-material/Description"
import PdfIcon from "@mui/icons-material/PictureAsPdf"
import {File} from "../types/file"

// @ts-ignore
import Modal from "./Modal"
import { Theme } from "@mui/material"
import { fileIsImage, fileIsPdf } from "../util/file"

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    background: "transparent",
    border: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    padding: 0,
    textAlign: "center",
    width: "100%",
    "&:hover": {
      color: theme.palette.secondary.main,
      "& img": {
        outline: `3px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  delete: {
    color: "#0E2348",
    cursor: "pointer",
    float: "left",
    "&:hover": {
      color: theme.palette.secondary.main,
      "& img": {
        outline: `3px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  frame: {
    height: "100%",
    width: "100%",
  },
  icon: {
    height: 100,
    width: 100,
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  thumbnail: {
    height: 100,
    width: 100,
  },
}))

interface FilesRowProps {
  files: File[]
}

export default function FilesRow(props: FilesRowProps) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const handleOpen = (file: File) => {
    // Only open the modal if we have an image or pdf
    if (fileIsImage(file.name) || fileIsPdf(file.name)) {
      setOpen(true)
      setSelectedFile(file)
    } else {
      // otherwise we download it
      window.open(file.url)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Grid style={{ paddingTop: 30 }} container spacing={3}>
        {props.files
          ? props.files.map((file, index) => {
            const filename = file.name
            const thumbnail = fileIsImage(filename) ? (
              <img
                alt="thumbnail"
                className={classes.thumbnail}
                src={file.url}
              />
            ) : filename.includes("pdf") ? (
              <PdfIcon className={classes.icon} />
            ) : (
              <DocumentIcon className={classes.icon} />
            )
            return (
              <Grid key={index} item>
                <button
                  className={classes.button}
                  type="button"
                  onClick={() => handleOpen(file)}
                >
                  {thumbnail}
                </button>
              </Grid>
            )
          })
          : <></>}
      </Grid>
      <Modal handleClose={handleClose} open={open}>
        {!!selectedFile
          ? fileIsImage(selectedFile.name)
            ? (<img alt="attachment" className={classes.image} src={selectedFile.url} />)
            : fileIsPdf(selectedFile.name)
              ? (
                <object
                  className={classes.frame}
                  data={selectedFile.url}
                  title="attachment"
                  type="application/pdf"
                />
              ) : <></>
          : <></>
        }
      </Modal>
    </>
  )
}
