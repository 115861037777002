import React from "react"
import Typography from "@mui/material/Typography"

export default function FormatTableTitle({ children }: {children: any}): JSX.Element {
  return (
    <Typography
      className="subject"
      style={{
        color: "#143366",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  )
}
