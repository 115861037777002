

export interface UpdateInvoiceInformationFormFields {
  date_invoice: string | null,
  invoice_number: string | null,
}

interface UpdateInvoiceInformationApiFields {
  date_invoice: string | null,
  invoice_number: string | null,
}

export function convertTicketInvoiceInformationFieldsForApi(
  output: UpdateInvoiceInformationFormFields
): UpdateInvoiceInformationApiFields {
  const payload: UpdateInvoiceInformationApiFields = {date_invoice: null, invoice_number: null}
  if (output.date_invoice != null && output.date_invoice.length > 0) {
    payload.date_invoice = (new Date(output.date_invoice)).toISOString()
  }
  if (output.invoice_number != null && output.invoice_number.length > 0) {
    payload.invoice_number = output.invoice_number
  }
  return payload
}
