
export type CompanyUserRoleCode = 'admin' | 'super' | 'crew'

export type ProjectUserRoleCode = 'pm' | 'super' | 'crew'

export const ProjectUserRoleCodeToDisplayMapping: any = {
  pm: "Project Manager",
  super: "Superintendent",
  crew: "Crew Member"
}

export const ProjectUserRoleOptionsForSub: any[] = Object.keys(ProjectUserRoleCodeToDisplayMapping).map((option: any) =>
  ({label: ProjectUserRoleCodeToDisplayMapping[option], value: option})
)

export const ProjectUserRoleOptionsForGc: any[] = Object.keys(ProjectUserRoleCodeToDisplayMapping)
  .filter((ProjectUserRole) => ProjectUserRole !== "crew")
  .map((option: any) => ({label: ProjectUserRoleCodeToDisplayMapping[option], value: option})
)