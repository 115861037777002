import Grid from "@mui/material/Grid"
import React from "react"
import {useTranslation} from "react-i18next"

import InvoiceTopCompanyInfo from "./InvoiceTopCompanyInfo"
import InvoiceTopNumber from "./InvoiceTopNumber"
import InvoiceTopLogo from "./InvoiceTopLogo"
import InvoiceTopAttachedFiles from "./InvoiceTopAttachedFiles"
import DetailRow from "../DetailRow"
import { CompanySenderReceiverInfo } from "../../types/company"

interface InvoiceInformation {
  number: string,
  notes: string,
}

interface InvoiceTopSectionProps {
  isMobile: boolean,
  info: InvoiceInformation,
  sender: CompanySenderReceiverInfo | null,
  senderLogoUrl?: string,
  receiver: CompanySenderReceiverInfo | null,
  files: any[],
  // Inner component displayed as-is
  invoiceDetails: any,
}

export default function InvoiceTopSection(props: InvoiceTopSectionProps): any {
  const { t } = useTranslation("private")

  return (
    <Grid container spacing={3}>

      <InvoiceTopNumber number={props.info.number}/>

      <InvoiceTopLogo
        isMobile={props.isMobile}
        senderLogoUrl={props.senderLogoUrl}
        senderName={props.sender?.name}
      />

      <Grid container item xs={props.isMobile ? 12 : 8}>
        {props.invoiceDetails}
      </Grid>

      <Grid container item xs={props.isMobile ? 12 : 4}>
        <InvoiceTopCompanyInfo sender={props.sender} receiver={props.receiver}/>
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow
            title={t("view.ChangeOrder.Summary.notes")}
            content={props.info.notes}
          />
        </Grid>
      </Grid>

      <InvoiceTopAttachedFiles files={props.files}/>
    </Grid>
  )
}
