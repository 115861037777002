import React, { useState } from "react"
import { Form, Formik } from "formik"
import { debounce } from "lodash"
import { Theme, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import CheckIcon from "@mui/icons-material/Check"
import makeStyles from "@mui/styles/makeStyles"

//@ts-ignore
import TextField from "../../../../../components/Fields/Text"
import {validateEmail} from "../../../../../forms/validation"
import {isExistingUser} from "../../../../../api/users"
import Fab from "../../../../../components/Fab"
import {SubInvitee} from "../../../../../types/user"


interface ExistingUserLabelProps {
  existingUser: boolean | null,
  theme: Theme,
  t: any
}

const ExistingUserLabel = (props: ExistingUserLabelProps) => {
  const {existingUser, theme, t} = props

  return existingUser
    ? (
      <Stack
        sx={{
          position: "absolute",
          bottom: -20,
          flexDirection: "row",
          alignItems: "center",
          gap: 0.5,
          justifyContent: "center",
          fontSize: 11
        }}
      >
        <Stack sx={{ backgroundColor: theme.palette.success.light, padding: "1px", borderRadius: 6, fontSize: 10 }}>
          <CheckIcon fontSize="inherit" sx={{ color: "white" }} />
        </Stack>
        <Typography variant="inherit" color={theme.palette.success.light}>
          {t("view.InviteSubContractor.existing_user")}
        </Typography>
      </Stack>
    ) : <></>
}


const useStyles = makeStyles(() => ({
  form: {
    maxHeight: 300,
  }
}))

interface AddSubFormProps {
  addInvitee: (i: SubInvitee) => void,
  projectId: string,
}

const AddSubForm = (props: AddSubFormProps) => {
  const {
    addInvitee,
    projectId
  } = props
  const { t } = useTranslation("private")
  const classes = useStyles();
  const theme = useTheme();
  const [existingUser, setExistingUser] = useState<boolean | null>(null)

  const checkIsExistingUser = async (value: string | undefined) => {
    // if email is invalid, then we return null
    if (!value || !validateEmail(value)) {
      setExistingUser(null)
    } else {
      try {
        const { data } = await isExistingUser(value, projectId)
        setExistingUser(data.user_exists)
      } catch (_) {
        // INTENTIONALLY BLANK -- need better error handling
      }
    }
  }
  const checkIsExistingUserDebounce = debounce(checkIsExistingUser, 500)

  return (
    <Formik
      validationSchema={Yup.object().shape({
        invite_email: Yup.string().email(t("form.message.validEmail")).required(t("form.message.validEmail"))
      })}
      initialValues={{ invite_email: "", invite_first_name: "", invite_last_name: "", invite_company_name: "" }}
      onSubmit={(values, {resetForm}) => {
        addInvitee(values)
        resetForm()
        setExistingUser(null)
      }}
    >
      {({
        errors,
        handleBlur,
        touched,
        values,
        handleChange,
      }) => {
        return (
          <Form className={classes.form}>
            <Divider light style={{ marginBottom: 24 }} />
            <Stack gap={1} justifyContent="space-between" flexDirection="row" alignItems="center" mb={3} position="relative">
              <TextField
                error={Boolean(touched.invite_email && errors.invite_email)}
                helperText={touched.invite_email && errors.invite_email}
                label={t("form.label.emailRequired")}
                name="invite_email"
                onBlur={handleBlur}
                onChange={(e: any) => {
                  checkIsExistingUserDebounce(e.target.value)
                  handleChange(e)
                }}
                type="text"
                value={values.invite_email}
              />
              <TextField
                label={t("form.label.firstName")}
                name="invite_first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.invite_first_name}
              />
              <TextField
                label={t("form.label.lastName")}
                name="invite_last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.invite_last_name}
              />
              <TextField
                label={t("form.label.companyName")}
                name="invite_company_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.invite_company_name}
              />
              <Fab
                type="submit"
                size="medium"
                variant="extended"
                disabled={!touched.invite_email || !!errors.invite_email}
              >
                <AddIcon style={{marginRight: 0}}/>
              </Fab>

              <ExistingUserLabel existingUser={existingUser} theme={theme} t={t}/>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddSubForm
