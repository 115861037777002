import React from 'react'
import Typography from '@mui/material/Typography'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'


interface FormatTableTitleLinkProps {
  url: string,
  text: string,
  openNewWindow: boolean,
}

export default function FormatTableTitleLink({url, text, openNewWindow}: FormatTableTitleLinkProps): JSX.Element {
  const {t} = useTranslation('private')
  // this needs to still be clickable even if the text is all whitespace
  // (text that is all whitespace won't be displayed, I'm not sure why that is)
  const useText = text.trim().length === 0 ? t('component.FormatTableTitleLink.blank') : text
  return (
    <Link
      to={url}
      target={openNewWindow ? '_blank' : '_self'}
      style={{ textDecoration: "none", overflow: "hidden", textOverflow: "ellipsis" }}
      onClick={() => {
        if (!openNewWindow) {
          window.scrollTo(0, 0)
        }
      }}
    >
      <Typography color="secondary" className="subject">
        {useText}
      </Typography>
    </Link>
  )
}
