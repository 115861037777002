import {AxiosResponse} from "axios"

// @ts-ignore
import api from "../libs/api"
import {ProductionMetric} from '../types/productionMetric'


export type CreateProductionMetric = {
  description: string,
  unit: string,
  total_amount: number | string,
}
export async function createProductionMetric(
  project_id: string,
  data: CreateProductionMetric,
): Promise<AxiosResponse<ProductionMetric>> {
  return api({
    method: "post",
    url: `/project/${project_id}/production_metric`,
    data,
  })
}

export async function editProductionMetric(
  project_id: string,
  pm_id: string,
  data: CreateProductionMetric,
): Promise<AxiosResponse<ProductionMetric>> {
  return api({
    method: "patch",
    url: `/project/${project_id}/production_metric/${pm_id}`,
    data,
  })
}
