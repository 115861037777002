import React, {useState} from "react"

//@ts-ignore
import Dialog from "./Dialog"
import Fab from "./Fab"


export interface FabDialogProps {
  buttonText: string,
  titleText: string,
  Component: any,
  componentProps?: any,
  LeadingIconComponent?: any
}

export default function FabDialog(props: FabDialogProps) {
  const {buttonText, titleText, Component, componentProps = {}, LeadingIconComponent} = props
  const [showDialogComponent, setShowDialogComponent] = useState<boolean>(false)

  return (
    <>
      <Fab
        onClick={() => {setShowDialogComponent(!showDialogComponent)}}
        variant="extended"
      >
        {LeadingIconComponent ?? null}
        {buttonText}
      </Fab>
      <Dialog
        hideactions="true"
        open={showDialogComponent}
        onClose={() => setShowDialogComponent(false)}
        title={titleText}
      >
        <Component onCancel={() => setShowDialogComponent(false)} {...componentProps}/>
      </Dialog>
    </>
  )
}
