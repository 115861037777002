import React from "react"
import { Grid } from "@mui/material"
import UndoIcon from '@mui/icons-material/Undo';
import {useSnackbar} from "notistack"

// @ts-ignore
import Button from "../../../../../../../components/Button"
import {CompanyType} from "../../../../../../../types/company"
import {ActionCode} from "../../../../../../../types/action"
import { useTranslation } from "react-i18next"
import { submitBackToCostEdit } from "../../../../../../../api/ticketReviewProcess";

interface BackToCostEditProps {
  companyType: CompanyType,
  projectId: string,
  ticketId: string,
  ticketActionCode: ActionCode,
  ticketActionChange: (new_action_id: number) => void,
  hasAttachedCo: boolean,
}

function showThisButton(companyType: CompanyType, ticketActionCode: ActionCode, hasAttachedCo: boolean): boolean {
  return companyType === 'trade' 
    && ticketActionCode === 'cost_submitted'
    && !hasAttachedCo
}

export default function BackToCostEditButton(props: BackToCostEditProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [isDisabled, setIsDisabled] = React.useState(false)

  const toggleBackToCostEdit = async (): Promise<void> => {
    setIsDisabled(true)
    try {
      const {data: data } = await submitBackToCostEdit(props.ticketId, props.projectId)
      if (data?.new_action_id) {
        props.ticketActionChange(data.new_action_id)
        enqueueSnackbar(t('view.Tickets.back_to_cost_edit_success'), {variant: "success"})
      }
      else {
        enqueueSnackbar(t('view.Tickets.back_to_cost_edit_fail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.back_to_cost_edit_fail'), {variant: "error"})
    }
    setIsDisabled(false)
  }

  return showThisButton(props.companyType, props.ticketActionCode, props.hasAttachedCo) ? (
    <>
      <Grid item>
        <Button
          disabled={isDisabled}
          endIcon={<UndoIcon/>}
          style={{ minWidth: 125, backgroundColor: "grey" }}
          onClick={() => {toggleBackToCostEdit()}}
        >
          {t("view.Tickets.back_to_cost_edit")}
        </Button>
      </Grid>
    </>
  ) : (<></>)
}
