import Grid from "@mui/material/Grid"
import {Form, Formik} from "formik"
import Typography from "@mui/material/Typography"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import {useTheme} from "@mui/material"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import { File } from "../types/file"


const fileUploadLimitInMB = 10

export interface OneTextFieldAndUploadFormFields {
  message: string,
  files: File[]
}

interface OneTextFieldAndUploadFormProps {
  onCancel: () => void,
  submitForm: (values: OneTextFieldAndUploadFormFields) => Promise<void>,
  titleText: string,
  textFieldRequired: boolean,
  doNotClearOnSubmit?: boolean,
}

export default function OneTextFieldAndUploadForm(props: OneTextFieldAndUploadFormProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")

  return (
    <Grid
      container
      style={{ paddingTop: 30, paddingBottom: 30 }}
      justifyContent={isMobile ? "center" : "flex-start"}
    >
      <Formik<OneTextFieldAndUploadFormFields>
        initialValues={{
          message: "",
          files: [
            {
              name: "",
              url: "",
              file_size_bytes: "",
            },
          ],
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true)
          await props.submitForm(values)
          if(!props.doNotClearOnSubmit){
            resetForm()
          }
          setSubmitting(false)
        }}
      >
        {({
            handleBlur,
            values,
            setFieldValue,
            errors,
            touched,
            isSubmitting
          }) => {
          const handleChange = (event: any) => {
            setFieldValue(event.target.name, event.target.value)
          }

          return (
            <Form>
              <Grid item container xs={12}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Typography
                    variant="h6"
                    align={isMobile ? "center" : "left"}
                    style={{ fontSize: 20 }}
                  >
                    {props.titleText}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextAreaField
                    label={t("form.label.notes")}
                    value={values.message}
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={props.textFieldRequired}
                  />
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 20 }}>
                  <UploadMultipleField
                    errors={errors}
                    label={t("form.label.uploadAttachments")}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    fileLimit={fileUploadLimitInMB}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  style={{ paddingTop: 20 }}
                >
                  <Grid item style={{ paddingRight: 20 }}>
                    <Button
                      type="reset"
                      style={{
                        color: "#0092D6",
                        backgroundColor: "transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        cursor: "pointer",
                        overFlow: "hidden",
                        outline: "0",
                        height: 40,
                      }}
                      color="secondary"
                      onClick={props.onCancel}
                      variant={null}
                    >
                      {t("view.ChangeOrder.cancel")}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      endIcon={<ArrowForwardIosIcon style={{ color: "white" }} />}
                      style={{
                        height: 40,
                        borderRadius: "5",
                      }}
                    >
                      {t("view.ChangeOrder.Summary.send")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
