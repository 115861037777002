import {TableCellProps} from "@mui/material/TableCell"

export const getColumnStyles = (column: any) => {
  let styles = column.styles ? column.styles : {}
  if (column.width) {
    styles.maxWidth = column.width
    styles.minWidth = column.width
    styles.width = column.width
  }
  if (column.maxWidth) {
    styles.maxWidth = column.maxWidth
  }
  if (column.minWidth) {
    styles.minWidth = column.minWidth
  }
  return styles
}

export interface BreakdownTableColumn {
  hide?: boolean,
  headerAlign?: TableCellProps['align'],
  headerName?: string,
  align?: TableCellProps['align'],
  field?: string,
  renderCell?: Function,
  flex?: number,
  width?: number,
  minWidth?: number,
  maxWidth?: number,
  editable?: boolean,
  sortable?: boolean,
  style?: any,
}

