import React from "react"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import { useDispatch } from "react-redux"

import CreateEditLaborForm from "../../../../../forms/CreateEditLabor"
//@ts-ignore
import Dialog from "../../../../../components/Dialog"
import LaborColumns from "./Labor.columns"
import { loadLabor, getLaborTypes, addLabor, updateLabor } from "../../../../../store/features/laborSlice"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import {processFormValues} from "../../../../../forms/CreateLabor.submit"
import { createLaborType, editLaborType } from "../../../../../api/labor"
import { defaultEditLaborInitialValues } from "../../../../../forms/CreateLabor.types"
import ImportLaborFromProject from './ImportLabor/ImportLaborFromProject'
import DataGridAccordion from '../../../../../components/DataGridAccordion'
import ImportLaborButton from './ImportLabor/button'
import {DataGridAccordionRowItem} from '../../../../../components/DataGridAccordionRow'
import ImportLaborFromCsv from './ImportLabor/ImportLaborFromCsv'


export default function Labor() {
  const { t } = useTranslation("private")
  const labor = useTracFloSelector(getLaborTypes)
  const dispatch = useDispatch()
  const company = useTracFloSelector((state) => state.company)
  const project = useTracFloSelector((state) => state.project)
  const isSuperadmin = useTracFloSelector((state) => state.user?.is_admin)
  const [dialog, setDialog] = React.useState<string | null>(null)
  const [updateInitialValues, setUpdateInitialValues] = React.useState<any>({})
  const { enqueueSnackbar } = useSnackbar()

  const handleEditLabor = (labor: any) => {
    setUpdateInitialValues(labor)
    setDialog("updateLaborForm")
  }

  const showImportLaborFromProjectDialog = () => {
    setDialog('importLaborFromProject')
  }

  const showImportLaborFromCsvDialog = () => {
    setDialog('importLaborFromCsv')
  }

  const closeDialog = () => {
    setDialog(null)
  }

  const updateLaborType = async (values: any) => {
    try {
      const { data } = await editLaborType(project.id, updateInitialValues.id, processFormValues(values))
      if (data.id) {
        data.created_by_company_id = company.id
        dispatch(updateLabor(data))
        enqueueSnackbar(t("view.Labor.labor_updated_success"), {
          variant: "success",
        })
        return true
      } else {
        enqueueSnackbar(t("view.Labor.labor_updated_fail"), {
          variant: "error",
        })
        return false
      }
    } catch(_) {
      enqueueSnackbar(t("view.Labor.labor_updated_fail"), {
        variant: "error",
      })
      return false
    }
  }

  const addLaborType = async (values: any)=> {
    try {
      const { data } = await createLaborType(project.id, processFormValues(values))
      if (data.id) {
        data.created_by_company_id = company.id
        dispatch(addLabor(data))
        enqueueSnackbar(t("view.Labor.labor_created_success"), {
          variant: "success",
        })
        return true
      } else {
        enqueueSnackbar(t("view.Labor.labor_created_fail"), {
          variant: "error",
        })
        return false
      }
    } catch(_) {
      enqueueSnackbar(t("view.Labor.labor_created_fail"), {
        variant: "error",
      })
      return false
    }
  }

  React.useEffect(() => {
    dispatch(loadLabor())
  }, [company.id, project.id, dispatch])

  const reloadLaborTypes = () => {
    closeDialog()
    dispatch(loadLabor())
  }

  const laborAccordion: DataGridAccordionRowItem = {
    title: t("view.Labor.labor_types"),
    columns: LaborColumns({
      companyType: company.company_type,
      companyId: company.id,
      projectUserRole: project.project_user_role,
      handleEdit: handleEditLabor,
      t: t
    }),
    rows: labor,
  }

  // PMs can add a new labor type
  if (
    project.project_user_role === "pm"
    && (company.company_type === "trade" || company.company_type === "cm")
    && (project.project_license_is_active)
  ) {
    laborAccordion.addForm = {
      submitText: t("view.Labor.add_labor"),
      openButtonText: t("view.Labor.labor_type"),
      title: t("view.Labor.add_labor"),
      props: {submitValues: addLaborType},
      form: CreateEditLaborForm,
    }
  }

  // Only Sub PMs or supers, superadmins arent allowed to import labor types from project
  if(company.company_type === "trade" && project.project_user_role !== "crew" && !isSuperadmin){
    laborAccordion.extraButtons = [
      {component: <ImportLaborButton
          showImportLaborFromCsv={showImportLaborFromCsvDialog}
          showImportLaborFromProject={showImportLaborFromProjectDialog}
        />
      },
    ]
  }

  return (
    <>
      <DataGridAccordion items={[laborAccordion]}/>

      <Dialog
        open={dialog === "updateLaborForm"}
        onClose={closeDialog}
        title="Edit Labor Type"
        maxWidth={"md"}
      >
        <CreateEditLaborForm
          initialValues={defaultEditLaborInitialValues(updateInitialValues)}
          submitValues={updateLaborType}
          update={true}
          onCancel={closeDialog}
          submitButtonText={t("form.label.update")}
        />
      </Dialog>

      <Dialog
        open={dialog === "importLaborFromProject"}
        onClose={closeDialog}
        title={t('view.ProjectSettings.LaborTypes.ImportLaborDialog.title')}
        maxWidth={"md"}
      >
        <ImportLaborFromProject
          importLaborTypesSuccessful={reloadLaborTypes}
          projectId={project.id}
        />
      </Dialog>

      <Dialog
        open={dialog === "importLaborFromCsv"}
        onClose={closeDialog}
        title={t('view.ProjectSettings.LaborTypes.ImportLaborCsvDialog.title')}
        maxWidth={"md"}
      >
        <ImportLaborFromCsv onClose={closeDialog} projectId={project.id} importLaborTypesSuccessful={reloadLaborTypes}/>
      </Dialog>
    </>
  )
}
