import {Grid, Theme, Typography} from '@mui/material'
import { useTheme } from "@mui/styles"


export interface DetailRowInfo {
  title: string,
  content?: any,
  titleStyle?: any,
  contentFormatter?: (content: any) => JSX.Element
}

interface DetailRowProps extends DetailRowInfo {
  titleContainerWidth?: number
}

export default function DetailRow(
  {title, content, titleStyle, titleContainerWidth, contentFormatter}: DetailRowProps
): JSX.Element {
  const theme: Theme = useTheme()

  const defaultTitleStyles = {color: theme.palette.grey[600], fontSize: 12}

  const titleStyles = typeof titleStyle === 'object' 
  ? { ...defaultTitleStyles, ...titleStyle }
  : defaultTitleStyles

  return content ? (
    <Grid container item alignContent="flex-start">
      <Grid item style={{width: titleContainerWidth ?? 92 }}>
        <Typography style={titleStyles}>{title}</Typography>
      </Grid>
      <Grid  
        item
        style={{
          borderLeft: `1px solid ${theme.palette.grey[300]}`,
          paddingLeft: 8,
          width: `calc(100% - ${titleContainerWidth ?? 92}px)`,
          whiteSpace: 'pre-wrap'
        }}  
      >
        <div style={{fontSize: 12, overflowWrap: 'break-word'}}>
          {contentFormatter ? contentFormatter(content) : content}
        </div>
      </Grid> 
    </Grid>
  ) : (<></>)
}
