import React from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"

import theme from "../../theme"
import { formatDate } from "../../libs/format"


interface InvoiceDateProps {
  date_invoice: string
}

export default function InvoiceDate(
  {date_invoice}: InvoiceDateProps
): JSX.Element {
  const { t } = useTranslation("private")

  return (
    <Grid container item>
      <Grid item style={{minWidth: 93}}>
        <Typography style={{fontSize: 13, lineHeight: 1, color: theme.palette.grey[600]}}>
          {t("view.Tickets.Summary.invoiceDate")}
        </Typography>
      </Grid>
      <Grid item style={{paddingLeft: 8}}>
        <Typography style={{fontSize: 13, lineHeight: 1}}>
          {formatDate(date_invoice)}
        </Typography>
      </Grid>
    </Grid>
      
  )
}
