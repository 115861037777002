import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import TextField from "../components/Fields/Text"
import {CustomCode} from '../types/customCode'


export interface CreateEditCodeValues {
  description: string,
  code: string,
}

function defaultInitialValues(): CreateEditCodeValues {
  return {
    description: "",
    code: '',
  }
}

export function createInitialValues(cc: CustomCode | null): CreateEditCodeValues {
  if (cc == null) return defaultInitialValues()
  return {
    description: cc.description,
    code: cc.code
  }
}

interface CreateEditCodeProps {
  onCancel: () => void,
  initialValues?: CreateEditCodeValues,
  submit: (values: CreateEditCodeValues) => Promise<void>,
}

export default function CreateEditCode(props: CreateEditCodeProps) {
  const { t } = useTranslation("private")

  return (
    <Formik
      initialValues={props.initialValues ?? defaultInitialValues()}
      validationSchema={Yup.object().shape({
        description: Yup.string().min(1).required(t("form.message.descriptionRequired")),
        code: Yup.string().min(1).required(t("form.message.codeRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await props.submit(values)
        setSubmitting(false)
        resetForm()
        props.onCancel()
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => {
        return (
          <Form>
            <FormSmallContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    autofocus
                    id="code"
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                    label={t("form.label.code")}
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label={t("form.label.description")}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} paddingTop={3}>
                <Grid container justifyContent="flex-start" item xs={6}>
                  <Button
                    onClick={() => {props.onCancel()}}
                    color="secondary"
                    variant="text"
                  >
                    {t("form.label.cancel")}
                  </Button>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <Button
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {t("form.label.submit")}
                  </Button>
                </Grid>
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
