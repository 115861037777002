import React, { useState } from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {notifyForCostReject, submitCostReject, uploadTicketReviewProcessFiles} from "../../../../../../../api/ticketReviewProcess"
import OneTextFieldAndUploadForm, { OneTextFieldAndUploadFormFields } from "../../../../../../../forms/OneTextFieldAndUploadForm"
import { hasFilesForUpload } from "../../../../../../../util/file"
import ConfirmOrCancelPrompt from "../../../../../../../components/ConfirmOrCancelPrompt"
// @ts-ignore
import Dialog from "../../../../../../../components/Dialog"


interface CostRejectFormProps {
  toggleFormOpen: () => void,
  ticketId: string,
  projectId: string,
  ticketActionChange: (new_action_id: number) => void
}

export default function CostRejectForm(props: CostRejectFormProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [submitValues, setSubmitValues] = useState<OneTextFieldAndUploadFormFields | null>(null)
  const [confirmOrCancelPromptOpen, setConfirmOrCancelPromptOpen] = useState<boolean>(false)

  const openConfirmCancelDialog = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    setSubmitValues(values)
    setConfirmOrCancelPromptOpen(true)
  }
  
  const closeConfirmCancelDialog = () => {
    setConfirmOrCancelPromptOpen(false)
  }

  const submitForm = async (): Promise<void> => {
    closeConfirmCancelDialog()
    if(!!submitValues) {
      try {
        const {data: trp} = await submitCostReject(props.ticketId, props.projectId, submitValues)
        // upload files
        if (hasFilesForUpload(submitValues.files)) {
          const uploadingKey = enqueueSnackbar('Uploading files...', {variant: 'info'})
          const fileUploadMessage = await uploadTicketReviewProcessFiles(submitValues.files, props.projectId, trp.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        // notify of the rejection and create history object
        await notifyForCostReject(trp.id, props.projectId)
        if (trp?.new_action_id) {
          props.ticketActionChange(trp.new_action_id)
        }
        props.toggleFormOpen()
        enqueueSnackbar(t('view.Tickets.cost_reject_success'), {variant: "success"})
      } catch (_) {
        enqueueSnackbar(t('view.Tickets.cost_reject_fail'), {variant: "error"})
      }
    } else {
      enqueueSnackbar(t('view.Tickets.tm_reject_fail'), {variant: "error"})
    }
  }

  return (
    <>
      <FormSmallContainer>
        <OneTextFieldAndUploadForm
          onCancel={props.toggleFormOpen}
          submitForm={openConfirmCancelDialog}
          titleText={t('view.Tickets.cost_reject_title')}
          textFieldRequired={true}
          doNotClearOnSubmit={true}
        />
      </FormSmallContainer>
      <Dialog
        open={confirmOrCancelPromptOpen}
        onClose={closeConfirmCancelDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt 
          submitForm={submitForm} 
          closeDialog={closeConfirmCancelDialog} 
          dialogPrompt={t('view.Tickets.rejectPrompt')}
        />
      </Dialog>
    </>
  )
}
