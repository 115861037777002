import {convertCsvStrToJsArray} from '../../../../../util/csv/read'
import {processRateFieldForImport} from '../../../../../util/csv/util'

export type ImportEquipProperties = 'name' | 'rate' | 'unit' | 'cost_code'

export type ImportEquipFields = 'Description' | 'Rate' | 'Unit' | 'Cost Code'
export const importEquipFields: ImportEquipFields[] = ['Description', 'Rate', 'Unit', 'Cost Code']

export const importEquipFieldToPropertyMapping: {[key in ImportEquipFields]: ImportEquipProperties} = {
  'Description': 'name',
  'Rate': 'rate',
  'Unit': 'unit',
  'Cost Code': 'cost_code',
}

export const ImportEquipFieldValidation: {[key in ImportEquipFields]: (s: string) => string} = {
  'Description': (s) => {
    // name is required
    if (s.length === 0) throw new Error('view.Settings.ImportEquipDialog.errorNameValidation')
    return s
  },
  'Rate': (s) => {
    return processRateFieldForImport(s, 'view.Settings.ImportEquipDialog.errorRateValidation')
  },
  'Unit': (s) => {return s}, // no validation necessary
  'Cost Code': (s) => {return s}, // no validation necessary
}

export function convertCsvStringToEquipArray(csvStr: string): {[key in ImportEquipProperties]: string}[] {
  return convertCsvStrToJsArray<ImportEquipFields, ImportEquipProperties>(
    csvStr,
    importEquipFields,
    ImportEquipFieldValidation,
    importEquipFieldToPropertyMapping,
  )
}
