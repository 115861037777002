import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import React from "react"
import {Container} from "@mui/material"
import {useTranslation} from 'react-i18next'

import {supportCalendarLink} from "../util/customerSupport"


interface ContactSupportToAddFeatureProps {
  featureDescription: string,
}

export default function ContactSupportToAddFeature(props: ContactSupportToAddFeatureProps) {
  const {t} = useTranslation('private')

  return (
    <Container style={{padding: '10px'}}>
      <Typography
        color="primary"
        style={{
          fontWeight: 700,
          paddingBottom: '20px',
          whiteSpace: 'pre-wrap'
        }}
      >
        {t('component.ContactSupportToAddFeature.mainText', {featureDescription: props.featureDescription})}
      </Typography>
      <Link href={supportCalendarLink} target={'_blank'}>
        {t('component.ContactSupportToAddFeature.linkText')}
      </Link>
    </Container>
  )
}
