import React, {useState, useEffect} from "react"
import { decode } from "html-entities"
import { Theme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import { useTranslation } from "react-i18next"

// @ts-ignore
import { formatDate, formatMoney } from "../../../../../libs/format"
import TicketInvoiceTopDetails from "./TicketInvoiceTopDetails"
import BreakdownMarkupCostDetail from "./BreakdownMarkupCostDetail"
import {getTicketFiles, getTicketSenderReceiver} from "../../../../../api/ticket"
import {TicketInvoiceSignatures} from "./Signatures"
import { CompanySenderReceiverInfo } from "../../../../../types/company"
import TicketInvoiceTopSection from "./Invoice/TicketInvoiceTopSection"

const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    borderLeft: "2px solid #CCCCCC",
    opacity: 1,
    paddingLeft: 10,
  },
  descriptionTitles: { color: "#757575", fontSize: "12px" },
  root: {
    margin: "auto",
    maxWidth: 1080,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    "@media print": {
      maxWidth: 9999,
      padding: 0,
    },
    totals: {
      paddingTop: 20,
    },
  },
}))

function createTicketTotal(ticket: any): string | null {
  return ticket.includes_costs ? formatMoney(ticket.total_amount) : null
}

function createTicketSummary(ticket: any, project: any, t: (s: string) => string): any[] {
  return [
    {
      title: t("view.Tickets.Summary.clientPco"),
      content: ticket.pco_number ? `#${ticket.pco_number}` : null,
    },
    {
      title: t("view.Tickets.Summary.subject"),
      content: ticket.subject ? decode(ticket.subject) : null,
    },
    {
      title: t("view.Tickets.Summary.total"),
      content: createTicketTotal(ticket),
    },
    {
      title: t("view.Tickets.Summary.projectNumber"),
      content: project.number ? `#${project.number}` : null,
    },
    {
      title: t("view.Tickets.Summary.project"),
      content: project.name ? decode(project.name) : null,
    },
    {
      title: t("view.Tickets.Summary.workDate"),
      content: ticket.date_start || ticket.date_end
        ? formatDate(ticket.date_start, ticket.date_end)
        : null,
    },
    {
      title: t("view.Tickets.Summary.created"),
      content: ticket.date_created ? formatDate(ticket.date_created) : null,
    },
    {
      title: t("view.Tickets.Summary.submittedBy"),
      content: ticket.author ?? null,
    },
  ]
}

interface TicketInvoiceProps {
  ticket: any,
  project: any,
  elevation?: number,
}

export default function TicketInvoice(props: TicketInvoiceProps) {
  const classes = useStyles()
  const [ticketSender, setSender] = useState<CompanySenderReceiverInfo | null>(null)
  const [ticketReceiver, setReceiver] = useState<CompanySenderReceiverInfo | null>(null)
  const [ticketFiles, setTicketFiles] = useState([])
  const { t } = useTranslation("private")

  useEffect(() => {
    if (!!props.ticket?.id && !!props.project?.id) {
      // get sender and receiver for this ticket
      getTicketSenderReceiver(props.project.id, props.ticket.id).then(
        (res: any) => {
          const {sender, receiver} = res.data
          setSender(sender)
          setReceiver(receiver)
        }, // TODO BETTER ERROR HANDLING
      )

      // get files for this ticket
      getTicketFiles(props.project.id, props.ticket.id).then(
        (res: any) => {
          if (res?.data?.length > 0) {
            setTicketFiles(res.data)
          }
        }, // TODO BETTER ERROR HANDLING
      )
    }
  }, [props.ticket?.id, props.project?.id])

  const summary = createTicketSummary(props.ticket, props.project, t)

  return props.ticket?.id && props.project?.id && props.ticket?.ticket_type ?  (
    <Paper className={classes.root} elevation={props.elevation ?? 2}>

      <div id='ticketInvoice'>
        <TicketInvoiceTopSection
          ticket={props.ticket}
          sender={ticketSender}
          senderLogoUrl={ticketSender?.logo_url}
          receiver={ticketReceiver}
          files={ticketFiles}
          invoiceDetails={(<TicketInvoiceTopDetails summary={summary} description={props.ticket.description}/>)}
        />

        <BreakdownMarkupCostDetail
          ticketId={props.ticket.id}
          lumpSumTotal={props.ticket.lump_sum_total}
          projectId={props.project.id}
          ticketType={props.ticket.ticket_type}
          ticketTotal={createTicketTotal(props.ticket) ?? ''}
        />

        <TicketInvoiceSignatures ticketId={props.ticket.id} projectId={props.project.id}/>
      </div>
    </Paper>
  ) : <></>
}
