import React from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import UndoIcon from '@mui/icons-material/Undo'

import {CompanyType} from "../../../../../../types/company"
import {ChangeOrderActionCode} from "../../../../../../types/action"
// @ts-ignore
import Button from "../../../../../../components/Button"
import { submitChangeOrderBackToDraft } from "../../../../../../api/changeOrderReviewProcess"


interface BackToDraftButtonProps {
  companyType: CompanyType,
  projectId: string,
  changeOrderId: string,
  changeOrderActionCode: ChangeOrderActionCode,
  changeOrderActionChange: (new_action_id: number) => void
}

export default function BackToDraft(props: BackToDraftButtonProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [isDisabled, setIsDisabled] = React.useState(false)
  const showBackToDraftButton = props.companyType === 'trade' && props.changeOrderActionCode === "submitted"

  const toggleBackToDraft = async (): Promise<void> => {
    setIsDisabled(true)
    try {
      const { data } = await submitChangeOrderBackToDraft(props.projectId, props.changeOrderId)
      if (data?.new_change_order_action_id) {
        props.changeOrderActionChange(data.new_change_order_action_id)
        enqueueSnackbar(t('view.ChangeOrder.back_to_draft_success'), {variant: "success"})
      }
      else {
        enqueueSnackbar(t('view.ChangeOrder.back_to_draft_fail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.ChangeOrder.back_to_draft_fail'), {variant: "error"})
    }
    setIsDisabled(false)
  }



  return showBackToDraftButton ? (
    <Grid item>
      <Button
        disabled={isDisabled}
        endIcon={<UndoIcon/>}
        style={{ minWidth: 125, backgroundColor: "grey" }}
        onClick={() => {toggleBackToDraft()}}
      >
        {t("view.Tickets.back_to_draft")}
      </Button>
    </Grid>
  ) : <></>
}
