import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React from "react"
import {BreakdownTableColumn, getColumnStyles} from "./BreakdownTable.columns"

interface BreakdownTableHeaderProps {
  columns: BreakdownTableColumn[],
}

export function BreakdownTableHeader(props: BreakdownTableHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        {props.columns
          .filter((column) => !column.hide)
          .map(
            (column, index) => (
              <TableCell
                align={column.headerAlign}
                key={index}
                style={getColumnStyles(column)}
              >
                {column.headerName ? column.headerName : ""}
              </TableCell>
            )
          )
        }
      </TableRow>
    </TableHead>
  )
}
