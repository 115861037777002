import { useTranslation } from "react-i18next"
import { GridColDef } from "@mui/x-data-grid"
import DeleteIcon from '@mui/icons-material/Delete'

//@ts-ignore
import DtRowTitle from "../../components/DtRowTitle"
import { IconButton } from "@mui/material"


export const ProjectUsersColumns = (
  canRemoveUsers: boolean,
  removeUser: (user_id: string) => void
): GridColDef[] => {
  const { t } = useTranslation("private")

  const columns: GridColDef[] = [
    {
      align: "left",
      headerAlign: "left",
      field: "name",
      headerName: "Name",
      minWidth: 180,
      sortable: false,
      renderCell: (params: any) => <DtRowTitle>{`${params.row.first_name} ${params.row.last_name}`}</DtRowTitle>,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "phone",
      headerName: "Phone",
      width: 130,
      editable: false,
      sortable: false,
    },
  ]

  if(canRemoveUsers){
    columns.push({
      align: "center",
      headerAlign: "center",
      field: "",
      headerName: t("view.Settings.remove_user"),
      width: 120,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <IconButton 
            color="secondary"
            onClick={() => {removeUser(params.id)}}
          >
            <DeleteIcon />
          </IconButton>
        )
      }
    })
  }

  return columns
}
