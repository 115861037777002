import React from "react"
import Box from "@mui/material/Box"
import { Theme, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useTranslation } from "react-i18next"

import { useTracFloSelector } from "../store/useTracFloSelector"
import {ActionCode, getTicketActionLabel} from "../types/action"
import {getTicketActionFill} from '../style/action'


const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      background: theme.palette.grey[900],
      border: "none",
      borderRadius: 5,
      color: "white",
      fontWeight: 900,
      fontSize: 13,
      padding: theme.spacing(1),
      textAlign: "center",
      width: "100%",
    },
    co_attached: {
      // action code here doesnt matter
      background: getTicketActionFill('draft', true),
    },
    draft: {
      background: getTicketActionFill('draft', false),
    },
    tm_submitted: {
      background: getTicketActionFill('tm_submitted', false),
    },
    tm_approve: {
      background: getTicketActionFill('tm_approve', false),
    },
    submitted: {
      background: getTicketActionFill('cost_submitted', false),
    },
    approve: {
      background: getTicketActionFill('cost_approve', false),
    },
    reject: {
      background: getTicketActionFill('tm_reject', false),
    },
    revise: {
      background: getTicketActionFill('tm_revise', false),
    },
    reviewing: {
      background: getTicketActionFill('cost_submitted', false),
      position: "relative",
      "& span": {
        display: "block",
        position: "absolute",
        right: 7,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
      },
      "&:before": {
        background: theme.palette.common.black,
        content: "",
        display: "block",
        height: "100%",
        left: 0,
        opacity: 0.1,
        position: "absolute",
        top: 0,
        width: 0,
      },
    },
    reviewed: {
      "&:before": {
        width: "50%",
      },
    },
    owner: {
      "&:before": {
        width: "75%",
      },
    },
  }
})

export default function Action(props: { code: ActionCode, coAttached?: boolean }) {
  const { t } = useTranslation("private")
  const company = useTracFloSelector((state) => state.company)
  const ticketAction = getTicketActionLabel(t, props.code, company.company_type, props.coAttached)
  const classes = useStyles()
  // use same formatting for similar actions
  const className = props.coAttached
    ? "co_attached"
    : props.code !== "tm_submitted" && props.code !== "tm_approve"
    ? props.code.replace("tm_", "").replace("cost_", "")
    : props.code

  return (
    <Box
      className={`${classes.root} ${
        ["reviewed", "owner"].includes(className) ? classes.reviewing : ""
      } ${classes[className]}`}
    >
      <Typography>{ticketAction}</Typography>
    </Box>
  )
}
