import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import FormControlLabel from "@mui/material/FormControlLabel"
import {ToggleButton, ToggleButtonGroup} from "@mui/material"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import TextField from "../components/Fields/Text"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
import {CompanyType} from "../types/company"
import UploadImage from "../components/Fields/UploadImage"


interface CreateCompanyFormFields {
  address: string,
  name: string,
  phone: string,
  company_type: CompanyType
  file: any
}

interface CreateCompanyProps {
  onCancel: () => void,
  submit: (company: any) => Promise<void>,
  useBigSubmitButton?: boolean
}

export default function CreateCompany(props: CreateCompanyProps) {
  const {onCancel, submit, useBigSubmitButton} = props
  const { t } = useTranslation("private")
  const fileUploadLimitInMB = 3

  //TODO DEV-213 GET - company type from api
  const companyOptions = [
    { label: t("form.label.sub"), value: "trade" },
    { label: t("form.label.cm"), value: "cm" },
  ]

  return (
    <Formik<CreateCompanyFormFields>
      validateOnChange={false}
      initialValues={{
        address: "",
        name: "",
        phone: "",
        company_type: "trade",
        file: {name: "",}
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t("form.message.nameRequired")),
        company_type: Yup.string().required(t("form.message.companyTypeRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await submit(values)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
        setFieldValue
        }) => {
        const setImageFile = (file: any) => {
          setFieldValue("file", file)
        }
        return (
          <Form>
            <FormSmallContainer>
              <Grid container spacing={3}>
                <Grid container item xs={12} justifyContent="flex-end">
                  <FormControlLabel
                    label={t("form.label.type")}
                    labelPlacement={"start"}
                    control={
                      <ToggleButtonGroup
                        exclusive
                        // @ts-ignore
                        name="company_type"
                        onChange={(event, value) => {
                          if (value) {setFieldValue("company_type", value)}
                        }}
                        value={values.company_type}
                        sx={{
                          ml: "10px",
                          mr: "10px",
                          width: { xs: "100%", md: "auto" },
                        }}
                      >
                        {companyOptions.map((co) => (
                          <ToggleButton
                            color={"primary"}
                            size={'small'}
                            value={co.value}
                          >
                            {co.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={t("form.label.companyName")}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t("form.label.companyNumber")}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextAreaField
                    label={t("form.label.companyAddress")}
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                  />
                </Grid>

                <Grid item xs={12}>
                  <UploadImage 
                    file={values.file}
                    setImageFile={setImageFile}
                    fileSizeLimitInMBs={fileUploadLimitInMB}
                  />
                </Grid>
                {useBigSubmitButton
                  // for a new user to create, in the accounts page
                  ? <>
                    <Grid item xs={12} container justifyContent={"flex-end"}>
                      <Button
                        disabled={!Object.keys(touched).length || !isValid}
                        isLoading={isSubmitting}
                        size="large"
                        type="submit"
                        endIcon={<AddIcon />}
                      >
                        {t("form.label.companyCreate")}
                      </Button>
                    </Grid>
                  </>
                  // this is for admin UI in a dialog box
                  : <>
                    <Grid container justifyContent="flex-start" item xs={6}>
                      <Button onClick={onCancel} color="secondary" variant="text">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid container justifyContent="flex-end" item xs={6}>
                      <Button
                        disabled={!Object.keys(touched).length || !isValid}
                        isLoading={isSubmitting}
                        type="submit"
                        endIcon={<AddIcon />}
                      >
                        {t("form.label.companyCreate")}
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            </FormSmallContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
