import {Grid, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {StatusChartData} from './type'
import {formatMoney} from '../../libs/format'
import {roundToDecimal} from '../../util/number'


const statusNameAndFillWidth = 7
const statusCountWidth = 2
const statusCostWidth = 3

type LogStatusChartLegendRowProps = {
  statusRow: StatusChartData
}

function LogStatusChartLegendRow({statusRow}: LogStatusChartLegendRowProps): JSX.Element {
  return (
    <Grid container>
      <Grid container xs={statusNameAndFillWidth}>
        <Grid item xs={2}>
          <div style={{
            backgroundColor: statusRow.fill,
            width: '10px',
            height: '10px',
            marginLeft: '13px',
            marginTop: '3px'
          }}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography style={{fontSize: '12px'}}>
            {statusRow.label}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={statusCountWidth}>
        <Typography style={{fontSize: '12px'}}>
          {statusRow.count}
        </Typography>
      </Grid>
      <Grid item xs={statusCostWidth}>
        <Typography style={{fontSize: '12px'}}>
          {formatMoney(roundToDecimal(statusRow.cost, 2))}
        </Typography>
      </Grid>
    </Grid>
  )
}


export type LogStatusChartLegendProps = {
  statusData: StatusChartData[]
}

export default function LogStatusChartLegend({statusData}: LogStatusChartLegendProps): JSX.Element {
  const { t } = useTranslation("private")
  return (
    <Grid style={{border: 'solid #CCCCCC 2px', borderRadius: '5px'}}>
      <Grid container>
        <Grid item xs={statusNameAndFillWidth}>
          <Typography style={{marginLeft: '10px', fontWeight: '700', fontSize: '12px'}}>
            {t('component.LogStatusChart.Legend.statusHeaderLabel')}
          </Typography>
        </Grid>
        <Grid item xs={statusCountWidth}>
          <Typography style={{fontWeight: '700', fontSize: '12px'}}>
            #
          </Typography>
        </Grid>
        <Grid item xs={statusCostWidth}>
          <Typography style={{fontWeight: '700', fontSize: '12px'}}>
            $
          </Typography>
        </Grid>
      </Grid>

      {statusData.map((row) => <LogStatusChartLegendRow statusRow={row} key={row.id}/>)}
    </Grid>
  )
}
