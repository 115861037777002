import {Grid, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {BudgetStatusChartData} from './type'
import {formatMoney} from '../../libs/format'
import {roundToDecimal} from '../../util/number'


const statusNameAndFillWidth = 7
const statusCountWidth = 2
const statusBudgetWidth = 3
const statusActualWidth = 3
const totalColumns = statusNameAndFillWidth + statusCountWidth + statusBudgetWidth + statusActualWidth

type BudgetLogStatusChartLegendRowProps = {
  statusRow: BudgetStatusChartData
}

function BudgetLogStatusChartLegendRow({statusRow}: BudgetLogStatusChartLegendRowProps): JSX.Element {
  return (
    <Grid container columns={totalColumns}>
      <Grid container xs={statusNameAndFillWidth} columns={15}>
        <Grid item xs={2}>
          <div style={{
            backgroundColor: statusRow.fill,
            width: '10px',
            height: '10px',
            marginLeft: '13px',
            marginTop: '3px'
          }}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography style={{fontSize: '12px'}}>
            {statusRow.label}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={statusCountWidth}>
        <Typography style={{fontSize: '12px'}}>
          {statusRow.count}
        </Typography>
      </Grid>
      <Grid item xs={statusBudgetWidth}>
        <Typography style={{fontSize: '12px'}}>
          {formatMoney(roundToDecimal(statusRow.budget_total, 2))}
        </Typography>
      </Grid>
      <Grid item xs={statusActualWidth}>
        <Typography style={{fontSize: '12px'}}>
          {formatMoney(roundToDecimal(statusRow.actual_total, 2))}
        </Typography>
      </Grid>
    </Grid>
  )
}


export type BudgetLogStatusChartLegendProps = {
  statusData: BudgetStatusChartData[]
}

export default function BudgetLogStatusChartLegend({statusData}: BudgetLogStatusChartLegendProps): JSX.Element {
  const { t } = useTranslation("private")
  return (
    <Grid style={{border: 'solid #CCCCCC 2px', borderRadius: '5px'}}>
      <Grid container columns={totalColumns}>
        <Grid item xs={statusNameAndFillWidth}>
          <Typography style={{marginLeft: '10px', fontWeight: '700', fontSize: '12px'}}>
            {t('component.BudgetLogStatusChart.Legend.statusHeaderLabel')}
          </Typography>
        </Grid>
        <Grid item xs={statusCountWidth}>
          <Typography style={{fontWeight: '700', fontSize: '12px'}}>
            #
          </Typography>
        </Grid>
        <Grid item xs={statusBudgetWidth}>
          <Typography style={{fontWeight: '700', fontSize: '12px'}}>
            $ {t('component.BudgetLogStatusChart.Legend.budgetHeaderLabel')}
          </Typography>
        </Grid>
        <Grid item xs={statusActualWidth}>
          <Typography style={{fontWeight: '700', fontSize: '12px'}}>
            $ {t('component.BudgetLogStatusChart.Legend.actualHeaderLabel')}
          </Typography>
        </Grid>
      </Grid>

      {statusData.map((row) => <BudgetLogStatusChartLegendRow statusRow={row} key={row.id}/>)}
    </Grid>
  )
}
