import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import {BreakdownTableColumn} from "../../../../../../../components/BreakdownTable.columns"
import {BreakdownTableHeader} from "../../../../../../../components/BreakdownTable.header"
import {BreakdownTableRows} from "../../../../../../../components/BreakdownTable.rows"
import { MaterialBreakdownTableTotals } from "../BreakdownTotals/MaterialBreakdownTableTotals"
import {BreakdownTableStyles} from "../../../../../../../components/Invoice/BreakdownTableStyles"

interface MaterialBreakdownTableProps {
  addRates: boolean,
  columns: BreakdownTableColumn[],
  editable?: boolean,
  rows?: any[],
  totals: any,
  subtotalText?: string,
}

export default function MaterialBreakdownTable(props: MaterialBreakdownTableProps) {
  const classes = BreakdownTableStyles()
  // Need to add index to rows
  const idRows = props.rows?.map((row, index) => ({ ...row, index: index + 1 }))

  return idRows?.length ? (
    <TableContainer className={classes.container}>
      <Table size="small" className={classes.table}>

        <BreakdownTableHeader columns={props.columns}/>

        <TableBody>
          <BreakdownTableRows rows={idRows} columns={props.columns}/>

          { !!props.addRates ? 
            <MaterialBreakdownTableTotals
              totals={props.totals}
              columns={props.columns}
              editable={props.editable}
              subtotalText={props.subtotalText}
            /> : <></>
          }
        </TableBody>

      </Table>
    </TableContainer>
  ) : (
    <></>
  )
}
