import { convertToNumber } from "../util/number"

export function processFormValues(values: any): any {
  const submitValues: any = {}
  submitValues.date_start = (new Date(values.date_start)).toISOString()
  submitValues.date_end = values.date_end ? (new Date(values.date_end)).toISOString() : null
  submitValues.name = values.name
  submitValues.subcontractor_id = values.subcontractor_id

  // Rates converted to zero before submit if blank
  const addRates: string [] = [
    'rate_rt', 'rate_ot', 'rate_pot', 'rate_dt', 'rate_pdt'
  ]

  for (let rate of addRates) {
    submitValues[rate] = convertToNumber((values[rate])) ?? 0
  }

  const addTheseFieldsIfLengthGtZero: string[] = [
    'cost_code', 'notes'
  ]
  for (let field of addTheseFieldsIfLengthGtZero) {
    if (values[field].length > 0) {
      submitValues[field] = values[field]
    }
  }
  return submitValues
}
