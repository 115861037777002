import React from "react"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import {useTranslation} from "react-i18next"
import {Tooltip} from "@mui/material"

import {CompanyType} from "../../../../../../../types/company"
import {ActionCode} from "../../../../../../../types/action"
// @ts-ignore
import Button from "../../../../../../../components/Button"


function showThisButton(
  companyType: CompanyType,
  ticketActionCode: ActionCode,
  includesCosts: boolean,
  hasAttachedCo: boolean
): boolean {
  // must be trade
  if (companyType !== 'trade') return false
  // Returns false if ticket is attached to change order
  if(hasAttachedCo) return false
  // these statuses can always submit for T&M
  if (["draft", "tm_submitted", "tm_revise"].includes(ticketActionCode)) return true
  // these statuses can only submit when costs are included
  return  ['tm_approve', 'cost_submitted', 'cost_revise'].includes(ticketActionCode) && includesCosts
}

interface SubmitTmOrCostButtonProps {
  companyType: CompanyType,
  ticketIncludesCosts: boolean,
  ticketActionCode: ActionCode,
  togglePocketForm: () => void,
  hasAttachedCo: boolean,
  disabled: boolean,
  disabledMessage: string,
}

export function SubmitTmOrCostButton(props: SubmitTmOrCostButtonProps) {
  const { t } = useTranslation("private")

  return showThisButton(props.companyType, props.ticketActionCode, props.ticketIncludesCosts, props.hasAttachedCo) ? (
    <Grid item>
      <Tooltip title={props.disabled? props.disabledMessage : null} placement={'top'}>
        <div>
          <Button
            endIcon={<SendIcon />}
            style={{ minWidth: 125 }}
            onClick={props.togglePocketForm}
            disabled={props.disabled}
          >
            {t("view.ChangeOrder.Summary.submit")}
          </Button>
        </div>
      </Tooltip>
    </Grid>
  ) : (
    <></>
  )
}
