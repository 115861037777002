import {ChangeEvent} from 'react'

// NOTE: the return value is null if successful, string is the translation code for an error
export function readCsvFileToString(
  e: ChangeEvent<HTMLInputElement>,
  onError: (errTransStr: string) => void,
  onSuccess: (csvStr: string) => void,
): string | null {
  if (e.target?.files && e.target.files.length > 0) {
    const csvFile = e.target.files[0]
    // must be CSV
    if (csvFile.name.includes('.csv')) {
      const fileReader = new FileReader()
      fileReader.onerror = () => {onError('Util.Csv.errorLoadingFile')}
      fileReader.onload = (e) => {
        if (!!e.target?.result) {
          if (typeof e.target.result === 'string') {
            onSuccess(e.target.result)
            return
          }
          const decode = new TextDecoder()
          const decodedStr = decode.decode(e.target.result)
          onSuccess(decodedStr)
          return
        }
        onError('Util.Csv.errorLoadingFile')
      }
      fileReader.readAsText(csvFile)
      return null
    }
    return 'Util.Csv.errorIncorrectFileType'
  }
  // no error here if they did not select any files
  return null
}

// CSV elements can contain double quotes with commas inside (ex. "Jackhammer, Large")
// These commas should not be treated as separating elements, which is what this regex does
const csvRowSplitRegex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/

const startOrEndDoubleQuote = /^"|"$/g

function convertCsvRowStrToStrArray(csvRowString: string): string[] {
  const csvRowArray = csvRowString.split(csvRowSplitRegex)
  return csvRowArray.map((s) => s.replace(startOrEndDoubleQuote, ``))
}

function convertCsvStrToRowArray(csvStr: string): string[][] {
  // use the Windows delimiter if we find it
  const rowDelim = csvStr.includes('\r\n') ? '\r\n' : '\n'
  return csvStr.split(rowDelim).map(convertCsvRowStrToStrArray)
}

// Returns a map of index to field name
function validateFieldHeaderAndCreateMap<T extends string>(fields: T[], headerRow: string[]): {[key: number]: T} {
  const headerMap: {[key: number]: T} = {}
  for (let field of fields) {
    const fieldIndex = headerRow.findIndex((s) => s === field)
    if (fieldIndex === -1) {
      throw new Error('Util.Csv.errorHeaderIsIncorrect')
    }
    headerMap[fieldIndex] = field
  }
  return headerMap
}

function convertCsvRowToJsObj<T extends string, U extends string>(
  csvRow: string[],
  headerMap: {[key: number]: T},
  validateFields: {[key in T]: (s: string) => string},
  fieldToPropertyMapping: {[key in T]: U}
): {[key in U]: string} {
  if (csvRow.length < Object.keys(headerMap).length) throw new Error('Util.Csv.errorRowIsIncomplete')
  // @ts-ignore this is mad that we initialize to empty object
  const retObj: {[key in U]: string} = {}
  for (let i = 0; i < csvRow.length; i++) {
    if (headerMap[i] != null) {
      retObj[fieldToPropertyMapping[headerMap[i]]] = validateFields[headerMap[i]](csvRow[i])
    }
  }
  return retObj
}

export function convertCsvStrToJsArray<T extends string, U extends string>(
  csvStr: string,
  fields: T[],
  validateFields: {[key in T]: (s: string) => string},
  fieldToPropertyMapping: {[key in T]: U}
): {[key in U]: string}[] {
  const csvRowArr = convertCsvStrToRowArray(csvStr)
  if (csvRowArr.length < 2) throw new Error('Util.Csv.errorNoRowsToImport')
  const headerMap = validateFieldHeaderAndCreateMap(fields, csvRowArr[0])
  // process all non-header rows
  return csvRowArr.slice(1).map(
    (csvRow) => convertCsvRowToJsObj<T, U>(csvRow, headerMap, validateFields, fieldToPropertyMapping)
  )
}
