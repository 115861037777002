import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import jwt_decode from "jwt-decode"
import { reduxStateExpired } from "../../util/expiry"

import config from "../../libs/config"
//@ts-ignore
import http from "../../libs/http"
import { TracFloState } from "../../types/state"
import {User, UserIntegration, UserStore} from "../../types/user"
import { ExpirationField } from "../../types/expiration"
import { Company } from "../../types/company"
import { Project } from "../../types/project"
import {Integration} from "../../types/integration"


const initialState: UserStore = {
  companies: [],
  drawerOpen: true,
  email: "",
  exp: {
    companies: "",
    projects: "",
    user: "",
    integrations: "",
  },
  firstName: "",
  iat: "",
  id: null,
  is_admin: false,
  isLoggedIn: false,
  lastName: "",
  name: "",
  phone: "",
  projects: [],
  status: {
    companies: "idle",
    login: "idle",
    logout: "idle",
    newPassword: "idle",
    projects: "idle",
    integrations: "idle",
  },
  token: "",
  integrations: [],
  language: 'en',
}

const decodeJwt = (token: string) => {
  const user: User & {exp: number} = jwt_decode(token)
  return { user, token }
}

const updateUserStateFromJwt = (state: UserStore, action: {payload: {token: string}}) => {
  const { user, token } = decodeJwt(action.payload.token)
  state.isLoggedIn = true
  state.token = token
  state.exp.user = user.exp
  state.email = user.email
  state.firstName = user.firstName
  state.iat = user.iat
  state.id = user.id
  state.is_admin = user.is_admin
  state.lastName = user.lastName
  state.name = user.name
  state.phone = user.phone
  state.language = user.language ?? 'en' // added coalescing, as this will cause old tokens to error. Can be removed after June 2023
}

export const newPassword = createAsyncThunk<any, {password: string, token: string}>(
  "user/new-password",
  async ({ password, token }, thunkAPI) => {
    const response = await http({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "patch",
      url: config.auth.newPassword,
      data: { password },
    })
    const { data } = response
    if (response.status === 200 && data.success) {
      return {token: data.data.jwt}
    } else {
      return thunkAPI.rejectWithValue(data)
    }
  }
)
export const resetPassword = createAsyncThunk<any, UserStore>(
  "user/reset-password",
  async ({ email }, thunkAPI) => {
    const response = await http.post(config.auth.resetPassword, {
      email,
    })
    const { data } = response
    if (response.status === 200) {
    } else {
      return thunkAPI.rejectWithValue(data)
    }
  }
)

export const login = createAsyncThunk<any, {email: string, password: string, errorHandler: any}>("user/login", async ({ email, password, errorHandler }, thunkAPI) => {
  try {
    const response = await http.post(config.auth.login, {
      email,
      password,
    })
    const { data } = response
    return {token: data.data.jwt}
  } catch (e) {
    errorHandler()
    return thunkAPI.rejectWithValue(e)
  }
})

export const validate = createAsyncThunk<boolean, UserStore>("user/validate", async ({ token }, thunkAPI) => {
  const response = await http.get(config.auth.validate, {
    JWT: token,
  })
  const { data } = response
  if (response.status === 200 && data.success) {
    return true
  } else {
    return thunkAPI.rejectWithValue(false)
  }
})

export const refresh = createAsyncThunk<any, UserStore>("user/refresh", async ({ token }, thunkAPI) => {
  const response = await http.post(config.auth.refresh, {
    JWT: token,
  })
  const { data } = response
  if (response.status === 200 && data.success) {
    const { jwt } = data.data
    const user = jwt_decode(jwt)
    return { user, token: jwt }
  } else {
    return thunkAPI.rejectWithValue(data)
  }
})

export const loadUserCompanies = createAsyncThunk<Company[], void, {state: TracFloState}>(
  "user/loadUserCompanies",
  async (args, thunkAPI) => {
    const { user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/user/companies`,
    })
    if (response.status === 200) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  },
  {
    // Make sure the user's companies are not already loaded or loading
    // And don't reload until expired
    condition: (args, { getState }) => {
      const { user } = getState()
      const status = user.status.companies
      const expiration = user.exp.companies
      const now = Math.floor(Date.now() / 1000)
      if (expiration && expiration > now && (status === "fulfilled" || status === "loading")) {
        return false
      }
    },
  }
)

export const loadUserProjects = createAsyncThunk<Project[], void, {state: TracFloState}>(
  "user/loadUserProjects",
  async (args, thunkAPI) => {
    const { company, user } = await thunkAPI.getState()
    const { token } = user
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          company_id: company.id,
        },
        method: "get",
        timeout: 20000,
        url: `${config.api.url}/project`,
      })
      if (response.status === 200) {
        const { data } = response
        return data
      } else {
        return thunkAPI.fulfillWithValue(null)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
  {
    // Make sure the user's projects are not already loaded or loading
    // Future: Ideally don't reload until expired, unless company changes
    condition: (args, { getState }) => {
      const { user } = getState()
      const status = user.status.projects
      const expiration = user.exp.projects
      const now = Math.floor(Date.now() / 1000)
      if (expiration && expiration > now && (status === "fulfilled" || status === "loading")) {
        return false
      }
    },
  }
)

export const loadActiveIntegrations = createAsyncThunk<UserIntegration[], void, {state: TracFloState}>(
  "user/loadActiveIntegrations",
  async (args, thunkAPI) => {
    const { company, user } = await thunkAPI.getState()
    const { token } = user
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          company_id: company.id,
        },
        method: "get",
        timeout: 20000,
        url: `${config.api.url}/active-integration`,
      })
      if (response.status === 200) {
        const { data } = response
        return data
      } else {
        return thunkAPI.fulfillWithValue(null)
      }
    } catch (error: any) {
      console.log("error", error)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  },
  {
    // Make sure the user's projects are not already loaded or loading
    // Future: Ideally don't reload until expired, unless company changes
    condition: (args, { getState }) => {
      const { user } = getState()
      const status = user.status.integrations
      const expiration = user.exp.integrations
      const now = Math.floor(Date.now() / 1000)
      if (expiration && expiration > now && (status === "fulfilled" || status === "loading")) {
        return false
      }
    },
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
    resetUserProjects: (state) => {
      state.status.projects = initialState.status.projects
      state.projects = initialState.projects
    },
    setActiveIntegration: (state, action: {payload: UserIntegration}) => {
      state.activeIntegration = action.payload
    },
    addIntegration: (state, action: {payload: UserIntegration}) => {
      const integrations = state.integrations
      integrations.unshift(action.payload)
      // we put the newest integrations first
      state.integrations = integrations.sort((a, b) => (a.date_created > b.date_created ? -1 : 1))
    },
    addProjectToIntegration: (state, action: {payload: {projectId: string, userIntegrationId: string}}) => {
      // add to integrations object
      const addedToIntegration = state.integrations.find((i) => i.id === action.payload.userIntegrationId)
      if (addedToIntegration != null) {
        if (addedToIntegration.projects == null) {
          addedToIntegration.projects = [action.payload.projectId]
        } else {
          addedToIntegration.projects.push(action.payload.projectId)
        }
      }
      // add to active integration
      if (state.activeIntegration?.id === action.payload.userIntegrationId) {
        if (state.activeIntegration.projects == null) {
          state.activeIntegration.projects = [action.payload.projectId]
        } else {
          state.activeIntegration.projects.push(action.payload.projectId)
        }
      }
    },
    addCompany: (state, action) => {
      const newCompanies = state.companies
      newCompanies.unshift(action.payload)
      state.companies = newCompanies.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    addProject: (state, action) => {
      const newProjects = state.projects
      newProjects.unshift(action.payload)
      state.projects = newProjects.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    deleteProject: (state, action) =>{
      state.projects.splice(
        state.projects.findIndex((item) => item.id === action.payload),
        1
      )
    },
    updateProject: (state, action) => {
      const updatedProject = action.payload
      if (updatedProject && updatedProject.id) {
        state.projects = state.projects.map((item) =>
          item.id === updatedProject.id ? updatedProject : item
        )
      }
    },
    showDrawer: (state, action) => {
      state.drawerOpen = action.payload
    },
    updateCompany: (state, action) => {
      const updatedCompany = action.payload
      if (updatedCompany && updatedCompany.id) {
        state.companies = state.companies.map((item) => {
          if (item.id === updatedCompany.id) {
            return {
              ...item,
              ...updatedCompany,
            }
          } else {
            return item
          }
        })
      }
    },
    setUserFromToken: updateUserStateFromJwt,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status.login = "loading"
      })
      .addCase(login.rejected, (state) => {
        state.status.login = "idle"
      })
      .addCase(login.fulfilled, (state, action) => {
        updateUserStateFromJwt(state, action)
        state.status.login = "fulfilled"
      })
      .addCase(loadUserCompanies.pending, (state) => {
        state.status.companies = "loading"
      })
      .addCase(loadUserCompanies.rejected, (state) => {
        state.status.companies = "idle"
      })
      .addCase(loadUserCompanies.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          // Move dev sites to the top
          const companiesList = action.payload
          state.companies = [...companiesList]
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp.companies = now + 60 * 60 * 24 // one day
        }
        state.status.companies = "fulfilled"
      })
      .addCase(loadUserProjects.pending, (state) => {
        state.status.projects = "loading"
      })
      .addCase(loadUserProjects.rejected, (state) => {
        state.status.projects = "idle"
      })
      .addCase(loadUserProjects.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          state.projects = [...action.payload]
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp.projects = now + 5 // five seconds
          state.status.projects = "fulfilled"
        } else {
          state.status.projects = "idle"
        }
      })
      .addCase(loadActiveIntegrations.pending, (state) => {
        state.status.integrations = "loading"
      })
      .addCase(loadActiveIntegrations.rejected, (state) => {
        state.status.integrations = "idle"
      })
      .addCase(loadActiveIntegrations.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          state.integrations = [...action.payload]
          state.integrations.sort((a, b) => (a.date_created > b.date_created ? -1 : 1))
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp.integrations = now + 5 // five seconds
          state.status.integrations = "fulfilled"
        } else {
          state.status.integrations = "idle"
        }
      })
      .addCase(newPassword.pending, (state) => {
        state.status.newPassword = "loading"
      })
      .addCase(newPassword.rejected, (state) => {
        state.status.newPassword = "idle"
      })
      .addCase(newPassword.fulfilled, (state, action) => {
        updateUserStateFromJwt(state, action)
        state.status.newPassword = "fulfilled"
      })
  },
})

export const {
  resetUser,
  resetUserProjects,
  showDrawer,
  updateCompany,
  setActiveIntegration,
  addIntegration,
  addCompany,
  addProject,
  deleteProject,
  updateProject,
  setUserFromToken,
  addProjectToIntegration,
} = userSlice.actions

export const getUser = (state: TracFloState) => state.user
export const listUserCompanies = (state: TracFloState) => state.user.companies
export const listUserProjects = (state: TracFloState) => state.user.projects
export const getIntegrationObjForActiveIntegration: (s: TracFloState) => Integration | null = (s: TracFloState) => {
  if (s.integration.items.length > 0 && s.user.activeIntegration != null) {
    const activeIntegrationObj = s.integration.items.find(
      (i) => i.id === s.user.activeIntegration?.integration_id
    )
    if (activeIntegrationObj != null) {
      return activeIntegrationObj
    }
    return null
  }
  return null
}

export const reloadProjectsIfInvalid = (projectsExpiry: ExpirationField, dispatch: any) => {
  if (reduxStateExpired(projectsExpiry)) {
    dispatch(loadUserProjects())
  }
}

export default userSlice.reducer
