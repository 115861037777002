import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

//@ts-ignore
import Container from "../../../../../../components/Container"
import BulletinReviewButtons from "./BulletinReviewButtons"
import { Bulletin } from "../../../../../../types/bulletin"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import { ActionInfo, BulletinActionCode } from "../../../../../../types/action"
import { updateBulletinAction } from "../../../../../../store/features/bulletinSlice"
// @ts-ignore
import ScrollToLink from "../../../../../../components/ScrollToLink"
import { formatDate } from "../../../../../../libs/format"
import BulletinEditButton from './BulletinEditButton'
import BulletinBackupsButton from './BulletinBackupsButton'


interface BulletinReviewSectionProps {
  bulletin: Bulletin
  projectId: string,
  historyRef: any
}

export default function BulletinReviewSection(
  {bulletin, projectId, historyRef}: BulletinReviewSectionProps
): JSX.Element {
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const bulletinActionIdObjMapping = useTracFloSelector((state) => state?.bulletinAction?.idObjectMapping)
  const [bulletinActionInfo, setBulletinActionInfo] = useState<ActionInfo<BulletinActionCode> | null>(
    !!bulletin && !!bulletinActionIdObjMapping
      ? bulletinActionIdObjMapping[bulletin.bulletin_action_id]
      : null
  )

  useEffect(() => {
    setBulletinActionInfo(
      !!bulletin && !!bulletinActionIdObjMapping
        ? bulletinActionIdObjMapping[bulletin.bulletin_action_id]
        : null
    )
  }, [bulletin, bulletinActionIdObjMapping])


  function bulletinActionChange(new_action_id: number) {
    if(bulletinActionIdObjMapping[new_action_id]){
      setBulletinActionInfo(bulletinActionIdObjMapping[new_action_id])
      dispatch(updateBulletinAction({new_action_id, bulletin_id: bulletin.id}))
    }
  }

  return bulletinActionInfo != null ? (
    <>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6} md={7}>
            <Typography variant="h1">{t("view.Bulletins.SingleBulletin.title")}</Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ marginRight: -12, marginTop: -12, textAlign: "right" }}
          >
            <BulletinBackupsButton projectId={projectId} bulletinId={bulletin.id}/>
            <BulletinEditButton
              bulletinId={bulletin.id}
              projectId={projectId}
              bulletinActionCode={bulletinActionInfo.code}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong>{t("view.Bulletins.SingleBulletin.rfpDueDate")}</strong>
              { bulletin.date_estimates_due
                ? formatDate(bulletin.date_estimates_due)
                : t("view.Bulletins.SingleBulletin.noRfpDueDate")
              }
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong>{t("view.Bulletins.SingleBulletin.status")}</strong>
              {t(`view.Bulletins.SingleBulletin.IsClosed.${bulletin.is_closed.toString()}`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong>{t("view.Bulletins.SingleBulletin.action")}</strong>
              {t(`component.BulletinAction.${bulletinActionInfo.code}`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ScrollToLink targetRef={historyRef}>
              {t("view.Bulletins.SingleBulletin.history")}
            </ScrollToLink>
          </Grid>
        </Grid>
      </Container>

      <BulletinReviewButtons
        bulletinId={bulletin.id}
        projectId={projectId}
        bulletinActionInfo={bulletinActionInfo}
        bulletinActionChange={bulletinActionChange}
      />
    </>
  ) : <></>
}
