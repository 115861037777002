import React, {useEffect, useRef, useState} from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useTheme } from "@mui/material"
import {useSnackbar} from "notistack"

// @ts-ignore
import Container from "../../../../../components/Container"
import {getChangeOrderById} from "../../../../../api/changeOrder"
import ChangeOrderInvoice from "./Invoice"
import ChangeOrderReviewSection from "./Review"
import {convertToNumber} from "../../../../../util/number"
import ChangeOrderHistory from "./History"


export default function ChangeOrder() {
  const { changeOrderId, projectId } = useParams<{changeOrderId: string, projectId: string}>()
  const theme = useTheme()
  const project = useSelector((state: any) => state.project)
  const [changeOrder, setChangeOrder] = useState<any>(null)
  const {enqueueSnackbar} = useSnackbar()
  const historyRef = useRef<any>()

  useEffect(() => {
    if (projectId && changeOrderId) {
      getChangeOrderById(projectId, changeOrderId)
        .then((changeOrderData) => {
          changeOrderData.total_amount = convertToNumber(changeOrderData.total_amount)
          if (changeOrderData.is_lump_sum) {
            changeOrderData.lump_sum_total = convertToNumber(changeOrderData.lump_sum_total)
          }
          setChangeOrder(changeOrderData)
        })
        .catch(() => {
          enqueueSnackbar("There was a problem getting the change order information.", {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, changeOrderId])

  return !!changeOrder && project.id === projectId ? (
    <>
      <ChangeOrderReviewSection
        changeOrder={changeOrder}
        historyRef={historyRef}
        projectId={project.id}
        setChangeOrder={setChangeOrder}
      />

      <Container fullWidth style={{ background: theme.palette.grey[200] }}>
        <ChangeOrderInvoice changeOrder={changeOrder} project={project}/>
      </Container>

      <div ref={historyRef}>
        <Container>
          <ChangeOrderHistory change_order_id={changeOrder.id} project_id={projectId} />
        </Container>
      </div>
    </>
  ) : <></>
}
