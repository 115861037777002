import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import {SupportedLanguages} from "../types/language"
// @ts-ignore
import common_en from "./en/common.json"
// @ts-ignore
import common_es from "./es/common.json"
// @ts-ignore
import public_en from "./en/public.json"
// @ts-ignore
import public_es from "./es/public.json"
// @ts-ignore
import private_en from "./en/private.json"
// @ts-ignore
import private_es from "./es/private.json"


export function initializeI18next(): void {
  i18next.use(initReactI18next).init({
    debug: true,
    interpolation: { escapeValue: false },
    react: {
      // https://react.i18next.com/latest/trans-component#trans-props
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "ul", "ol", "li"],
    },
    resources: {
      en: {
        common: common_en,
        public: public_en,
        private: private_en,
      },
      es: {
        common: common_es,
        public: public_es,
        private: private_es,
      },
    },
    lng: SupportedLanguages.English.value,
    fallbackLng: SupportedLanguages.English.value,
  })
}
