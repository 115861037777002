import React, {useState, useEffect, useMemo} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import Breakdowns from "../Breakdowns"
import {TicketMarkup, TicketType} from "../../../../../../types/ticket"
import TicketMarkupSubtotals from "../Markup"
import {getTicketMarkup} from "../../../../../../api/ticket"


interface BreakdownMarkupCostDetailProps {
  ticketId: string,
  lumpSumTotal: number,
  projectId: string,
  ticketType: TicketType,
  ticketTotal: string,
}

export default function BreakdownMarkupCostDetail(props: BreakdownMarkupCostDetailProps) {
  const addRates = props.ticketType === 'sum_rates'
  const showCosts = props.ticketType !== 'tm'
  const [ticketMarkup, setTicketMarkup] = useState<TicketMarkup[]>([])

  // Tracking subtotals
  const [overallSubtotal, setOverallSubtotal] = useState(props.ticketType === "sum_total" ? props.lumpSumTotal : 0)
  const subtotals = useMemo(
    () => ({labor: 0, material: 0, equipment: 0}),
    [props.ticketId, props.projectId]
  )
  function updateSubtotal(type: 'labor' | 'material' | 'equipment', value: number): void {
    subtotals[type] = value
    setOverallSubtotal(subtotals.labor + subtotals.material + subtotals.equipment)
  }

  useEffect(() => {
    // get markup for this ticket
    getTicketMarkup(props.projectId, props.ticketId).then(
      (res: any) => {
        if (res?.data?.length > 0) {
          setTicketMarkup(res.data)
        }
      }, // TODO BETTER ERROR HANDLING
    )
  }, [props.ticketId, props.projectId])

  return (
    <>
      <Breakdowns
        ticket_id={props.ticketId}
        addRates={addRates}
        project_id={props.projectId}
        updateSubtotal={updateSubtotal}
        markup={ticketMarkup}
      />

      {showCosts ? (
        <>
          <TicketMarkupSubtotals
            subtotal={overallSubtotal}
            markup={ticketMarkup.filter((m) => m.markup_type === 'ticket')}
            ticketType={props.ticketType}
          />

          <Grid item container xs={12} justifyContent="flex-end">
            <Typography variant="body2" style={{ fontWeight: 800, fontSize: 20, paddingTop: 10 }}>
              Total: {props.ticketTotal}
            </Typography>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
