import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, } from '@mui/material'

import MetricCard from '../../../../../../components/MetricCard'
import { formatMoney, formatPercent } from '../../../../../../libs/format'


interface EstimateTotalSubmittedCostCardsProps {
  estimateTotalAmount: number,
  estimateTotalSubmittedAmount: number,
}

const numberStyle = {color: "#212121"}
const negativeNumberStyle = {color: "#C62828"}

export default function EstimateTotalSubmittedCostCards(
  {estimateTotalAmount, estimateTotalSubmittedAmount}: EstimateTotalSubmittedCostCardsProps
): JSX.Element {
  const { t } = useTranslation("private")
  const submittedGreaterThanEstimate = estimateTotalSubmittedAmount > estimateTotalAmount

  return (
    <Grid container spacing={2} marginTop={2}>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.estimatedCost")}
          large_number={formatMoney(estimateTotalAmount)}
          numberStyle={numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.submittedCost")}
          large_number={formatMoney(estimateTotalSubmittedAmount)}
          numberStyle={numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.percentSubmitted")}
          large_number={ estimateTotalAmount !== 0
            ? formatPercent(estimateTotalSubmittedAmount/estimateTotalAmount)
            : '-'
          }
          numberStyle={submittedGreaterThanEstimate ? negativeNumberStyle : numberStyle}
        />
      </Grid>
      <Grid item xs={3}>
        <MetricCard
          title={t("view.Estimates.SingleEstimate.Overview.remainingToSubmit")}
          large_number={formatMoney(estimateTotalAmount - estimateTotalSubmittedAmount)}
          numberStyle={submittedGreaterThanEstimate ? negativeNumberStyle : numberStyle}
        />
      </Grid>
    </Grid>
  )
}
