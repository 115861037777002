import parse from "html-react-parser"
import {decode} from "html-entities"
import React from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"

import DetailsTable from "../../../../../components/DetailsTable"
import DetailRow from "../../../../../components/DetailRow"


interface TicketInvoiceTopDetailsProps {
  summary: any,
  description: string,
}

export default function TicketInvoiceTopDetails(props: TicketInvoiceTopDetailsProps): any {
  const { t } = useTranslation("private")
  return (
    <>
      <DetailsTable details={props.summary} />
      <Grid container style={{ marginBottom: 16 }}>
        <DetailRow
          title={t("view.ChangeOrder.Summary.workDescription")}
          content={parse(decode(props.description))}
        />
      </Grid>
    </>
  )
}
