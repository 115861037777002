const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`
const authenticationUrl = `${apiUrl}/login`

const config = {
  env: process.env.REACT_APP_ENV,
  api: {
    node: process.env.REACT_APP_NODE_API,
    url: `${apiUrl}`,
  },
  auth: {
    newPassword: `${apiUrl}/password`,
    resetPassword: `${apiUrl}/reset-password`,
    login: `${authenticationUrl}/auth`, //login using v2.
    refresh: `${authenticationUrl}/auth/refresh`,
    revoke: `${authenticationUrl}/auth/revoke`,
    validate: `${authenticationUrl}/auth/validate`,
    url: authenticationUrl,
  },
  app: {
    domain: process.env.REACT_APP_BASE_URL,
    url: process.env.REACT_APP_URL,
  },
  agave: {
    root: `${process.env.REACT_APP_NODE_API}/agave`,
  },
  fullstory: {
    orgId: process.env.REACT_APP_FS_ORG_ID
  }
}

export default config
