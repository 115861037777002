import React from "react"

// @ts-ignore
import ButtonDelete from "./ButtonDelete"
// @ts-ignore
import FormatRowId from "./FormatRowId"
import FormatTableTitle from "./FormatTableTitle"
import {BreakdownTableColumn} from './BreakdownTable.columns'
import {formatMoney, formatPercent} from '../libs/format'
import {convertToNumber} from '../util/number'
import FormatTableTitleLink from './FormatTableTitleLink'
import {goToSingleEstimate} from '../util/routes'
import {estimateDraftActionStatusId} from '../types/estimate'
import { ActionInfo, EstimateActionCode } from "../types/action"
import EstimateAction from "./EstimateAction"


function estimateNotReadyForReviewYet(params: any): boolean {
  return params.estimate_id == null
    || params.estimate_action_id == null
    || params.estimate_action_id <= estimateDraftActionStatusId
}

interface BulletinSubsTableColumnInputs {
  editable: boolean,
  handleDeleteRow?: (i: number) => void,
  t: (s: string) => string,
  projectId: string,
  estimateActionIdToInfo: {[key: number]: ActionInfo<EstimateActionCode>},
  showEstimateActualCosts: boolean,
}

export default function BulletinSubsTableColumn(
  {editable, handleDeleteRow, t, projectId, estimateActionIdToInfo, showEstimateActualCosts}: BulletinSubsTableColumnInputs
): BreakdownTableColumn[] {
  const columns: BreakdownTableColumn[] = [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      style: { paddingLeft: 0 },
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "subcontractor_name",
      headerName: t("component.BulletinSubsTable.subcontractorColLabel"),
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return <FormatTableTitle>{params.value}</FormatTableTitle>
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "subcontractor_user_name",
      headerName: t("component.BulletinSubsTable.subcontractorContactColLabel"),
      minWidth: 120,
      editable: false,
      sortable: false,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "estimate_action_id",
      headerName: t("component.BulletinSubsTable.estimateActionColLabel"),
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return estimateNotReadyForReviewYet(params)
          ? t('component.BulletinSubsTable.estimateNotCreatedYetCellValue')
          : <EstimateAction code={estimateActionIdToInfo[params.estimate_action_id].code}/>
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "estimate",
      headerName: t("component.BulletinSubsTable.estimateColLabel"),
      minWidth: 100,
      editable: false,
      sortable: false,
      hide: editable,
      renderCell: (params: any) => {
        if (estimateNotReadyForReviewYet(params)) {
          return "-"
        } else {
          return <FormatTableTitleLink
            url={goToSingleEstimate(projectId, params.estimate_id)}
            text={t("component.BulletinSubsTable.view")}
            openNewWindow={true}
          />
        }
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "estimate_total_amount",
      headerName: t("component.BulletinSubsTable.estimateTotalColLabel"),
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => params.value == null || estimateNotReadyForReviewYet(params)
        ? ''
        : formatMoney(convertToNumber(params.value) ?? 0, false),
    },
  ]
  if (showEstimateActualCosts) {
    columns.push(
      {
        align: "right",
        headerAlign: "right",
        field: "estimate_actual_amount",
        headerName: t("component.BulletinSubsTable.estimateActualColLabel"),
        minWidth: 100,
        editable: false,
        renderCell: (params: any) => {
          return formatMoney(params.value ?? 0)
        },
      },
    )
    columns.push(
      {
        align: "right",
        headerAlign: "right",
        field: "actual_perc_approved",
        headerName: t("component.BulletinSubsTable.actualPercColLabel"),
        width: 90,
        editable: false,
        renderCell: (params: any) => {
          return params.value == null
            ? '-'
            : formatPercent(params.value)
        },
      },
    )
  }
  if (editable) {
    columns.push(
      {
        align: "right",
        headerAlign: "right",
        field: "",
        headerName: "",
        width: 50,
        editable: false,
        sortable: false,
        style: { paddingRight: 0 },
        renderCell: (params: any) => (
          <ButtonDelete
            onClick={() => {
              if (handleDeleteRow) {
                handleDeleteRow(params.index)
              }
            }}
            style={{
              marginLeft: "-5px",
              marginRight: "-5px",
            }}
          />
        ),
      },
    )
  }
  return columns
}
