import React, {useEffect} from "react"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import MarkupField from "./Markup"
import BreakdownTable from "../BreakdownTable"
import Fab from "../Fab"
import FormSmallContainer from "../FormSmallContainer"
import AddLaborForm from "../../forms/AddLaborToBreakdown"
import AddMaterialEquipmentForm from "../../forms/AddMaterialEquipmentToBreakdown"
import api from "../../libs/api"
import LaborColumns from "../../libs/tableColumns/labor"
import MaterialEquipmentColumns from "../../libs/tableColumns/materialEquipment"
import {
  calculateEquipmentTotals,
  calculateLaborTotals, calculateMaterialTotals, updateEquipmentBreakdownWithEquipmentType,
  updateLaborBreakdownWithLaborType,
  updateMaterialBreakdownWithMaterialType
} from "./Breakdown.processData"

function handleDeleteRow(setFieldValue, name, value) {
  return (rowIndex) => {
    setFieldValue(
      `${name}Breakdown`,
      value.filter((row, index) => index !== (rowIndex - 1))
    )
  }
}

function handleFieldChanges(setFieldValue, value, valuePropName) {
  return (index, propNamesAndValues) => {
    for (const {fieldPropName, newValue} of propNamesAndValues) {
      value[index][fieldPropName] = newValue
    }
    setFieldValue(
      valuePropName,
      [...value]
    )
  }
}

export default function Breakdown({
  formType,
  label,
  markupValue,
  name,
  buttonText,
  setFieldValue,
  value,
  breakdownTypeUrl,
  noTypesToAddMessage, // if this is omitted, user is allowed to add breakdown even if no types exist
  ticketDates,
  forCostEdit,
}) {
  const { t } = useTranslation("private")
  const project = useSelector((state) => state.project)
  const [columns, setColumns] = React.useState([])
  const [dropdownItems, setDropdownItems] = React.useState([])
  const [formActive, setFormActive] = React.useState(false)
  const [totals, setTotals] = React.useState({})
  const addRates = formType === "sum_rates"
  const canOnlyAddWithExistingTypes = !!noTypesToAddMessage


  useEffect(() => {
    api.get(breakdownTypeUrl)
      .then(({data}) => {
        setDropdownItems(data)
      })
  }, [name, project, breakdownTypeUrl])

  useEffect(() => {
    if (dropdownItems.length > 0) {
      // Need to update breakdowns with type information when they come in
      if (name === 'labor') {
        updateLaborBreakdownWithLaborType(value, dropdownItems, addRates)
        setTotals(calculateLaborTotals(value, addRates))
      }
      if (name === 'material') {
        updateMaterialBreakdownWithMaterialType(value, dropdownItems, addRates)
        setTotals(calculateMaterialTotals(value, addRates))
      }
      if (name === 'equipment') {
        updateEquipmentBreakdownWithEquipmentType(value, dropdownItems, addRates)
        setTotals(calculateEquipmentTotals(value, addRates))
      }
      setFieldValue(`${name}Breakdown`, [...value])
    }
  }, [dropdownItems, addRates, name, setFieldValue])

  useEffect(() => {
    const valueArray = Object.values(value)
    switch (name) {
      case "labor":
        setColumns(LaborColumns({
          addRates,
          handleDeleteRow: handleDeleteRow(setFieldValue, name, value),
          editable: true,
          handleFieldChanges: handleFieldChanges(setFieldValue, value, 'laborBreakdown'),
          forCostEdit,
          t
        }))
        setTotals(calculateLaborTotals(valueArray, addRates))
        break
      case "equipment":
        setColumns(MaterialEquipmentColumns({
          addRates,
          handleDeleteRow: handleDeleteRow(setFieldValue, name, value),
          editable: true,
          handleFieldChanges: handleFieldChanges(setFieldValue, value, 'equipmentBreakdown'),
          forCostEdit,
          type: "equipment",
          t
        }))
        setTotals(calculateEquipmentTotals(valueArray, addRates))
        break
      case "material":
        setColumns(MaterialEquipmentColumns({
          addRates,
          handleDeleteRow: handleDeleteRow(setFieldValue, name, value),
          editable: true,
          handleFieldChanges: handleFieldChanges(setFieldValue, value, 'materialBreakdown'),
          forCostEdit,
          type: "material",
          t
        }))
        setTotals(calculateMaterialTotals(valueArray, addRates))
        break
      default:
        break
    }
  }, [addRates, name, setFieldValue, value])

  return value.length > 0 || !forCostEdit ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        {dropdownItems.length === 0 && canOnlyAddWithExistingTypes
          ? <Typography variant="body1">{noTypesToAddMessage}</Typography>
          // editable adjusts last column for delete icon, we don't show this icon if cost edit
          : <BreakdownTable columns={columns} editable={!forCostEdit} rows={value} totals={totals} />
        }
      </Grid>

      {/* Cannot add if cost edit */}
      {!forCostEdit
        ? (
          <Grid item xs={12} container justifyContent="center">
            {!formActive ? (
              <Fab
                variant="extended"
                disabled={dropdownItems.length === 0 && canOnlyAddWithExistingTypes}
                onClick={() => setFormActive(!formActive)}
              >
                <AddIcon />
                {buttonText || "Add New"}
              </Fab>
            ) : (
              <FormSmallContainer>
                {(() => {
                  switch (name) {
                    case "labor":
                      return (
                        <AddLaborForm
                          addRates={addRates}
                          data={value}
                          dropdownItems={dropdownItems}
                          setData={setFieldValue}
                          setFormActive={setFormActive}
                          ticketDates={ticketDates}
                        />
                      )
                    case "equipment":
                    case "material":
                      return (
                        <AddMaterialEquipmentForm
                          addRates={addRates}
                          data={value}
                          dropdownItems={dropdownItems}
                          setData={setFieldValue}
                          setFormActive={setFormActive}
                          type={name}
                        />
                      )
                    default:
                      return <></>
                  }
                })()}
              </FormSmallContainer>
            )}
          </Grid>
        ) : (<></>)
      }

      {value.length && addRates ? (
        <MarkupField
          name={name}
          setData={setFieldValue}
          total={totals.total || 0}
          value={markupValue}
        />
      ) : (
        <></>
      )}
    </Grid>
  ) : (<></>)
}
