import {ChangeOrder, ChangeOrderMarkup} from "../../../../../types/changeOrder"
import {CreateChangeOrderInitialValues} from "../../../../../forms/CreateChangeOrder.types"

function initializeMarkup(markup: ChangeOrderMarkup): any {
  return {
    id: markup.id,
    amount: markup.percent,
    title: markup.description,
  }
}

export function initializeFormWithChangeOrderData(
  changeOrder: ChangeOrder,
  markup: ChangeOrderMarkup[],
): CreateChangeOrderInitialValues {
  return {
    date_end: changeOrder.date_end,
    date_start: changeOrder.date_start,
    files: [{name: ""}],
    markup: markup.map((m) => initializeMarkup(m)),
    invoice_number: changeOrder.invoice_number ?? '',
    notes: changeOrder.notes ?? '',
    number: changeOrder.number,
    pco_number: changeOrder.pco_number ?? '',
    subject: changeOrder.subject,
    exclusion: changeOrder.exclusion ?? '',
    is_lump_sum: changeOrder.is_lump_sum,
    lump_sum_total: changeOrder.lump_sum_total ?? '',
    scope: changeOrder.scope,
    // NOTE: this remains empty until change order is submitted, even if change order already has attached tickets
    ticketIds: [],
  }
}
