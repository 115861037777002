import { useEffect, useState } from 'react'
import {Grid, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

//@ts-ignore
import Container from "./Container"
import DetailRow from './DetailRow'
import DetailsTable from './DetailsTable'
import { Bulletin } from '../types/bulletin'
import { formatDate } from '../libs/format'
import { getBulletinById, getBulletinFiles } from '../api/bulletin'
import { File } from '../types/file'
import InvoiceTopAttachedFiles from './Invoice/InvoiceTopAttachedFiles'


interface BulletinDetailsForEstimatesProps {
  bulletinId: string,
  projectId: string,
}

export default function BulletinDetailsForEstimates(
  {bulletinId, projectId}: BulletinDetailsForEstimatesProps
): JSX.Element {
  const [bulletin, setBulletin] = useState<Bulletin | null>(null)
  const [bulletinFiles, setBulletinFiles] = useState<File[]>([])
  const { t } = useTranslation("private")
  const {enqueueSnackbar} = useSnackbar()

  // Get Bulletin Info 
  useEffect(() => {
    if (projectId && bulletinId) {
      getBulletinById(projectId, bulletinId)
        .then((res) => {
          if(!!res.data){
            setBulletin(res.data)
          }
        })
        .catch(() => {
          enqueueSnackbar(t("view.Bulletins.SingleBulletin.loadBulletinFail"), {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, bulletinId])

  useEffect(() => {
    // get files for this bulletin
    getBulletinFiles(projectId, bulletinId).then(
      (res) => {
        if (res?.data?.length > 0) {
          setBulletinFiles(res.data)
        }
      }
    )
  }, [projectId, bulletinId])

  const bulletinDetails = [
    {
      title: t('component.BulletinDetailsForEstimates.pcoNumber'),
      content: bulletin?.pco_number,
      titleStyle: { fontSize: 16 },
      contentFormatter: (content: string) => (
        <Typography style={{fontSize: 16}}>{content}</Typography>
      )
    },
    {
      title: t('component.BulletinDetailsForEstimates.dueDate'),
      content: bulletin?.date_estimates_due 
        ? formatDate(bulletin.date_estimates_due) 
        : t('component.BulletinDetailsForEstimates.noDueDate'),
      titleStyle: { fontWeight: 700, color: "#000000", fontSize: 16 },
      contentFormatter: (content: string) => (
        <Typography style={{ fontWeight: 700, color: "#000000", fontSize: 16 }}>
          {content}
        </Typography>
      )
    },
    {
      title: t('component.BulletinDetailsForEstimates.title'),
      content: bulletin?.title,
      titleStyle: { fontSize: 16 },
      contentFormatter: (content: string) => (
        <Typography style={{fontSize: 16}}>{content}</Typography>
      )
    },
    {
      title: t('component.BulletinDetailsForEstimates.requestedBy'),
      content: `${bulletin?.inbox_first_name} ${bulletin?.inbox_last_name}`,
      titleStyle: { fontSize: 16 },
      contentFormatter: (content: string) => (
        <Typography style={{fontSize: 16}}>{content}</Typography>
      )
    }
  ]

  return !!bulletin ? (
    <>
      <Container removeTop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h2'>
              {t('component.BulletinDetailsForEstimates.rfp')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DetailsTable
              details={bulletinDetails}
              titleContainerWidth={120}
            />
            <Grid container item style={{ marginBottom: 16 }}>
              <DetailRow
                title={t('component.BulletinDetailsForEstimates.description')}
                content={bulletin.description}
                titleStyle={{fontSize: 16}}
                titleContainerWidth={120}
                contentFormatter={(content: string) => (
                  <Typography style={{fontSize: 16}}>{content}</Typography>
                )}
              />
            </Grid>
            <Grid container item>
              <DetailRow
                title={t('component.BulletinDetailsForEstimates.notes')}
                content={bulletin.notes}
                titleStyle={{fontSize: 16}}
                titleContainerWidth={120}
                contentFormatter={(content: string) => (
                  <Typography style={{fontSize: 16}}>{content}</Typography>
                )}
              />
            </Grid>
          </Grid>

          <InvoiceTopAttachedFiles files={bulletinFiles}/>
        </Grid>
      </Container>
    </>
  ) : (<></>)
}
