import { formatMoney } from "../libs/format"
import { Daily } from "../types/daily"
import { TicketType } from "../types/ticket"
import { convertToNumber } from "./number"

export function convertDailyToTicketType(daily: Daily): TicketType {
  return daily.includes_costs
    ? daily.is_lump_sum
      ? 'sum_total'
      : 'sum_rates'
    : 'tm'
}

export function createDailyTotal(daily: Daily): string | null {
  return daily.includes_costs ? formatMoney(convertToNumber(daily.total_amount) ?? 0) : null
}