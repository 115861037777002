import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"
import { useTranslation } from "react-i18next"

import {TicketMarkup, TicketType} from "../../../../../../types/ticket"
// @ts-ignore
import { formatMoney } from "../../../../../../libs/format"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"

interface TicketMarkupProps {
  markup: TicketMarkup[],
  subtotal: number
  ticketType: TicketType
}

export default function TicketMarkupSubtotals(props: TicketMarkupProps) {
  const { t } = useTranslation("private")
  return (props.markup.length > 0
    ? (
      <>
        <Grid item container xs={12} justifyContent="flex-end">
          <Typography
            variant="body2"
            style={{ fontWeight: 800, fontSize: 15, paddingBottom: 30, paddingTop: 20 }}
          >
            {props.ticketType === "sum_total" ? t("view.Tickets.sum_total") : t("view.Tickets.subtotal")}: {formatMoney(props.subtotal)}
          </Typography>
        </Grid>

        <InvoiceMarkup rows={props.markup} total={props.subtotal} subTotalText={t("view.Tickets.Markup.markup_total")}/>
      </>
    ) : <></>
  )
}
