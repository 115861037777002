import React from "react"
import {Switch} from "react-router-dom"

import CostEditTicket from "./CostEditTicket"
import EditTicket from "./EditTicket"
//@ts-ignore
import TicketView from "./SingleTicket"
import SubRouteGuard from "../Sub.RouteGuard"
import TicketsListView from "./TicketList/TicketsList"
import ProjectSelectedRouteGuard from "../ProjectSelected.RouteGuard"
import CreateTicketView from "./CreateTicket"
import { goToSingleTicket, goToTicketsList } from "../../../../util/routes"

export default function TicketRouter() {
  return (
    <Switch>
      <ProjectSelectedRouteGuard exact path={goToTicketsList(":projectId")}>
        <TicketsListView />
      </ProjectSelectedRouteGuard>
      <SubRouteGuard
        path={"/0/project/:projectId/tickets/add"}
        Component={CreateTicketView}
        RouteGuard={ProjectSelectedRouteGuard}
      />
      <ProjectSelectedRouteGuard exact path={goToSingleTicket(":projectId", ":ticketId")}>
        <TicketView />
      </ProjectSelectedRouteGuard>
      <SubRouteGuard
        path={"/0/project/:projectId/ticket/:ticketId/edit"}
        Component={EditTicket}
        RouteGuard={ProjectSelectedRouteGuard}
      />
      <SubRouteGuard
        path={"/0/project/:projectId/ticket/:ticketId/edit_costs"}
        Component={CostEditTicket}
        RouteGuard={ProjectSelectedRouteGuard}
      />
    </Switch>
  )
}
