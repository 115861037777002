import React, {useMemo} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import {CompaniesCreatedInLastSixtyDaysColumns} from "./CompaniesCreatedInLastSixtyDays.columns"
//@ts-ignore
import FormWideContainer from "../../../../../components/FormWideContainer"
// @ts-ignore
import DataTable from "../../../../../components/DataTable"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"


function findCompaniesCreatedInLastSixtyDays(singleCompanyDateCreated: any): boolean {
  const todayDate = new Date()
  const singleCompanyDate = new Date(singleCompanyDateCreated)
  const msBetweenDates = todayDate.getTime() - singleCompanyDate.getTime()
  // convert ms to days                     hour   min  sec   ms
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
  return daysBetweenDates < 60
}

export default function CompaniesCreatedInLastSixtyDaysView() {
  const { t } = useTranslation("private")
  const companies = useTracFloSelector((state) => state.user.companies)

  const companiesCreatedInLastSixtyDays = useMemo(() => {
    return companies.filter((company: any) => findCompaniesCreatedInLastSixtyDays(company.date_created))
      .map((company: any) => ({
        id: company.id,
        name: company.name,
        date_created: company.date_created
      }))
      .sort((c1, c2) => {
        return new Date(c2.date_created).getTime() - new Date(c1.date_created).getTime()
      })
  }, [companies])

  const columns = CompaniesCreatedInLastSixtyDaysColumns(t)

  return (
    <>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.Admin.companiesCreatedLastSixtyDaysTitle")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            rows={companiesCreatedInLastSixtyDays}
            getRowId={(row: any) => row.id}
            showCheckboxes={false}
          />
        </Grid>
      </FormWideContainer>
    </>
  )
}