import {TicketType} from "../types/ticket"
import {AddDailyLaborBreakdownInitialValues} from './CreateDailyBreakdown.type'


export type CreateEditDailyProductionMetric = {
  production_metric_id: string,
  amount: number | string,
}

export interface CreateDailyInitialValues {
  date_start: string,
  description: string,
  equipmentBreakdown: any[],
  equipmentMarkup: any[],
  files: any[],
  formMarkup: any[],
  isAddCosts: boolean,
  isLumpSum: boolean,
  laborBreakdown: AddDailyLaborBreakdownInitialValues[],
  laborMarkup: any[],
  lumpSumTotal: string | number,
  manual_total: string | number,
  materialBreakdown: any[],
  materialMarkup: any[],
  production: CreateEditDailyProductionMetric[],
  notes: string,
  number: string,
  subject: string,
  type: TicketType,
}

export function defaultCreateDailyInitialValues(): CreateDailyInitialValues {
  return {
    date_start: (new Date()).toDateString(),
    description: "",
    equipmentBreakdown: [],
    equipmentMarkup: [],
    files: [{name: "",},],
    formMarkup: [],
    isAddCosts: false,
    isLumpSum: false,
    laborBreakdown: [],
    laborMarkup: [],
    lumpSumTotal: "",
    manual_total: "",
    materialBreakdown: [],
    materialMarkup: [],
    production: [],
    notes: "",
    number: "",
    subject: "",
    type: "tm",
  }
}

export interface CreateDailyOutputForApi {
  date_start: string,
  description: string,
  manual_total: string | number,
  notes: string,
  number: string | null,
  subject: string,
  type: TicketType,
  markup: any[],
  includes_costs: boolean,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  equipment: any[],
  labor: any[],
  material: any[],
  production: CreateEditDailyProductionMetric[]
}
