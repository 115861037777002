import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import {apiHeaders} from "../../api/util"
import { TracFloState } from "../../types/state"
import {ActionInfo, ActionsStore, BulletinActionCode} from "../../types/action"

const initialState: ActionsStore<BulletinActionCode> = {
  items: [],
  idObjectMapping: {},
  exp: "",
  status: "idle",
}

export const loadActions = createAsyncThunk<ActionInfo<BulletinActionCode>[], void, {state: TracFloState}>(
  "bulletinAction/loadActions",
  async (args, thunkAPI
  ) => {
    const { user } = await thunkAPI.getState()
    const response = await axios({
      headers: apiHeaders(undefined, user.token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/bulletin-action`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  })

export const bulletinActionSlice = createSlice({
  name: "bulletinAction",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadActions.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadActions.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadActions.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          const actions = action.payload
          state.items = actions
          // Create id to code mapping
          state.idObjectMapping = actions.reduce(
            (idObj: {[key: number]: ActionInfo<BulletinActionCode>}, a) => {
              idObj[a.id] = a
              return idObj
            }, {}
          )
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 60 // one hour
        }
      })
  },
})

export const {
  reset,
} = bulletinActionSlice.actions

export default bulletinActionSlice.reducer
