import React, {useState, useEffect} from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt, useHistory } from "react-router-dom"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import { InputAdornment, InputLabel, Switch, Theme } from "@mui/material"

// @ts-ignore
import Container from "../components/Container"
import FormWideButtons from "../components/FormWideButtons"
// @ts-ignore
import FormWideContainer from "../components/FormWideContainer"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import TextField from "../components/Fields/Text"
import {
  CreateChangeOrderInitialValues,
  defaultCreateChangeOrderInitialValues
} from "./CreateChangeOrder.types"
import SelectTickets from "./SelectTickets"
import { getNextChangeOrderNumber } from "../api/changeOrder"
import ChangeOrderMarkup from "./ChangeOrderMarkup"
import {convertToNumber} from "../util/number"
import {calculateTotalWithMarkupsInForm} from "../util/markup"
import { formatMoney } from "../libs/format"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import {Ticket} from "../types/ticket"
import FilesRow from "../components/FilesRow"
import {calculateTotalFileSizeInBytes} from "../types/file"
import {goToChangeOrdersList} from '../util/routes'
import {DateField} from './components/DateField'


const fileUploadLimitInMB = 50

const useStyles = makeStyles<Theme>((theme) => ({
  total: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 20,
    paddingTop: 10
  }
}))

interface CreateChangeOrderProps {
  initialValues?: CreateChangeOrderInitialValues,
  projectId: string,
  submitValues: (
    output: CreateChangeOrderInitialValues,
    nextChangeOrderNumber: string | null,
    addedTickets: any[]
  ) => Promise<void>,
  initialTickets?: Ticket[],
  existingFiles?: any[],
  submitButtonText?: string,
}

export default function CreateChangeOrder(props: CreateChangeOrderProps) {
  const {projectId, initialValues, initialTickets, submitValues, existingFiles, submitButtonText} = props
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation("private")
  const [nextChangeOrderNumber, setNextChangeOrderNumber] = useState<string|null>(null)
  const [addedTickets, setAddedTickets] = useState<any[]>(initialTickets ?? [])

  useEffect(() => {
    if (projectId) {
      getNextChangeOrderNumber(projectId)
        .then((res) => {
          if(res.status === 200 && !!res.data.next_number){
            setNextChangeOrderNumber(res.data.next_number)
          }
        })
    }
  }, [projectId])

  useEffect(() => {
    window.onbeforeunload = function (_) {
      if (history.location.pathname.includes("changeorders/add")) {
        return ""
      } else {
        return null
      }
    }
  }, [history.location.pathname])

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues ?? defaultCreateChangeOrderInitialValues()}
      validationSchema={Yup.object().shape({
        subject: Yup.string().min(0).required(t("form.message.subjectRequired")),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        await submitValues(values, nextChangeOrderNumber, addedTickets)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        isValid,
        touched,
        values,
        setFieldValue,
      }) => {
        const subtotal = values.is_lump_sum ? convertToNumber(values.lump_sum_total) : addedTickets.reduce(
          (total, { total_amount: current }) => total + convertToNumber(current), 0
        )
        return (
          <Form>
            <Container removeTop removeBottom>
              <FormWideContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={nextChangeOrderNumber ?? ""}
                      value={values.number}
                      label={t("form.label.coNumber")}
                      name="number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t("form.label.client_co")}
                      name="pco_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pco_number}
                    />
                  </Grid>

                  <DateField
                    width={'half'}
                    label={t("form.label.startDate")}
                    value={values.date_start}
                    maxDate={values.date_end ? new Date(values.date_end) : undefined}
                    onChange={(date) => {setFieldValue("date_start", date)}}
                  />

                  <DateField
                    width={'half'}
                    label={t("form.label.endDate")}
                    value={values.date_end}
                    minDate={values.date_start ? new Date(values.date_start) : undefined}
                    onChange={(date) => {setFieldValue("date_end", date)}}
                  />

                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.subject && errors.subject)}
                      helperText={touched.subject && errors.subject}
                      label={t("form.label.subject")}
                      name="subject"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.scopeOfWork")}
                      name="scope"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.scope}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.exclusion")}
                      name="exclusion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.exclusion}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.notes")}
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("form.label.invoiceNumber")}
                      name="invoice_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.invoice_number}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {/* For editing, we show existing files here */
                      existingFiles && existingFiles.length > 0
                        ? <Grid item xs={12} sx={{paddingBottom: 4}}>
                          <Typography color="primary">Current Files:</Typography>
                          <FilesRow files={existingFiles}/>
                        </Grid>
                        : <></>
                    }

                    <UploadMultipleField
                      errors={errors}
                      label={t("form.label.uploadAttachments")}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      fileLimit={fileUploadLimitInMB}
                      existingFilesSizeInBytes={
                        existingFiles != null
                          ? calculateTotalFileSizeInBytes(existingFiles)
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputLabel>{t("form.label.addLumpSumTotal")}</InputLabel>
                    <Switch
                      name="is_lump_sum"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.is_lump_sum}
                    />
                  </Grid>
                  {values.is_lump_sum ? (
                    <Grid item xs={12} md={9}>
                      <TextField
                        label={t("form.label.total")}
                        name="lump_sum_total"
                        onBlur={handleBlur}
                        onChange={(event: any) => {
                          setFieldValue("lump_sum_total", event.target.value)
                        }}
                        type="number"
                        value={values.lump_sum_total}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </FormWideContainer>
            </Container>

            <Container>
              <SelectTickets
                addedTickets={addedTickets}
                setAddedTickets={setAddedTickets}
                projectId={props.projectId}
              />
              { subtotal ?
                <Container removeTop removeBottom>
                  <Grid item xs={12} sx={{paddingTop: 5}} container justifyContent="flex-end">
                    <Typography variant="h3">Subtotal: {formatMoney(subtotal)}</Typography>
                  </Grid>
                </Container>
              : <></>
              }
            </Container>

            <Prompt
              when={!isSubmitting}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <ChangeOrderMarkup
              setFieldValue={setFieldValue}
              markup={values.markup}
              subtotal={subtotal}
            />
            <Container>
              <FormWideContainer>
                <Grid item xs={12} justifyContent="flex-end" style={{display: 'flex'}}>
                  <Typography variant="body2" className={classes.total}>
                    Total: {formatMoney(calculateTotalWithMarkupsInForm(subtotal, values.markup))}
                  </Typography>
                </Grid>
              </FormWideContainer>
            </Container>
            <FormWideButtons
              cancel={{
                action: () => {
                  history.push(goToChangeOrdersList(projectId))
                },
                text: t("view.ChangeOrder.cancel"),
              }}
              submit={{
                disabled: !isValid,
                isSubmitting: isSubmitting,
                text: submitButtonText ?? t("view.ChangeOrder.add"),
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
