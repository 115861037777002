import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import { useSelector } from "react-redux"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../../../components/Pocket"
// @ts-ignore
import { getCompany } from "../../../../../../store/features/companySlice"
import { ActionInfo, ChangeOrderActionCode } from "../../../../../../types/action"
import { SubmitReviewButton } from "./ReviewButtons/SubmitReviewButton"
import SubmitReviewForm from "./ReviewForms/SubmitReviewForm"
import ApproveReviseButtons from "./ReviewButtons/ApproveReviseButtons"
import ApproveForm from "./ReviewForms/ChangeOrderApproveForm"
import ReviseForm from "./ReviewForms/ChangeOrderReviseForm"
import { ShareChangeOrderButton } from "./ReviewButtons/ShareButton"
import ShareChangeOrderForm from "./ReviewForms/ChangeOrderShareForm"
import {getProjectClients, ProjectClient} from "../../../../../../api/ticketReviewProcess"


type FormName = "share" | "submit" | "approve" | "revise" | null

interface ChangeOrderReviewButtonsProps {
  changeOrder: any,
  changeOrderActionInfo: ActionInfo<ChangeOrderActionCode>,
  changeOrderActionChange: (new_action_id: number) => void,
  projectId: string,
}

export default function ChangeOrderReviewButtons(props: ChangeOrderReviewButtonsProps): any {
  const {t} = useTranslation('private')
  const [showThisForm, setShowThisForm] = useState<FormName>(null)
  const company: any = useSelector(getCompany)
  const [clients, setClients] = useState<ProjectClient[]>([])

  const toggleChangeOrderReviewForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  useEffect(() => {
    if (!!props.projectId && !!props.changeOrder?.id) {
      getProjectClients(props.projectId).then(
        (clients) => {
          if (!!clients) {
            setClients(clients)
          }
        }
      )
    }
  }, [props.projectId, props.changeOrder?.id])

  return (
    <>
      {/* Review Process Buttons */}
      <Container removeTop>
        <Grid container xs={12} spacing={2}>
          <SubmitReviewButton
            companyType={company.company_type}
            changeOrderActionCode={props.changeOrderActionInfo.code}
            togglePocketForm={() => toggleChangeOrderReviewForm('submit')}
            disabled={clients.length === 0}
            disabledMessage={t('view.ChangeOrder.noClientsTooltip')}
          />

          <ShareChangeOrderButton
            togglePocketForm={() => toggleChangeOrderReviewForm('share')}
          />

          <ApproveReviseButtons
            companyType={company.company_type}
            changeOrderActionCode={props.changeOrderActionInfo.code}
            toggleApproveForm={() => toggleChangeOrderReviewForm('approve')}
            toggleReviseForm={() => toggleChangeOrderReviewForm('revise')}
          />
        </Grid>
      </Container>

      {/* Review Process Forms */}
      <Pocket show={showThisForm === 'share'}>
        <ShareChangeOrderForm
          projectId={props.projectId}
          toggleFormOpen={() => toggleChangeOrderReviewForm("share")}
          changeOrder={props.changeOrder}
        />
      </Pocket>
      <Pocket show={showThisForm === 'submit'}>
        <SubmitReviewForm
          toggleFormOpen={() => toggleChangeOrderReviewForm("submit")}
          changeOrder={props.changeOrder}
          changeOrderActionInfo={props.changeOrderActionInfo}
          changeOrderActionChange={props.changeOrderActionChange}
          projectId={props.projectId}
          clients={clients}
        />
      </Pocket>
      <Pocket show={showThisForm === 'approve'}>
        <ApproveForm
          toggleFormOpen={() => toggleChangeOrderReviewForm("approve")}
          changeOrderId={props.changeOrder?.id}
          projectId={props.projectId}
          changeOrderActionChange={props.changeOrderActionChange}
        />
      </Pocket>

      <Pocket show={showThisForm === 'revise'}>
        <ReviseForm
          toggleFormOpen={() => toggleChangeOrderReviewForm("revise")}
          changeOrderId={props.changeOrder?.id}
          projectId={props.projectId}
          changeOrderActionChange={props.changeOrderActionChange}
        />
      </Pocket>

    </>
  )
}

