import { CreateChangeOrderInitialValues, CreateChangeOrderOutputForApi } from "./CreateChangeOrder.types"

function generateMarkupData(markup: any[]): any[] {
  return markup.map((row: any) => {
    const rowValue: any = {
      percent: row.amount,
      description: row.title,
    }
    if (row.id) {
      rowValue.id = row.id
    }
    return rowValue
  })
}

export function convertFormValuesToApiData(
  values: CreateChangeOrderInitialValues,
  nextChangeOrderNumber: string|null,
  addedTickets: any[]
): CreateChangeOrderOutputForApi {
  // Set up most of our values
  const submitValues: any = Object.keys(values)
    .filter(
      (key) =>
        !(
          key.includes("Markup") ||
          key.includes("files")
          // @ts-ignore
        ) && values[key]
    )
    .reduce((current, key) => {
      // @ts-ignore
      return Object.assign(current, { [key]: values[key] })
    }, {})
  // Next number
  submitValues.number = values.number ? values.number.toString() : nextChangeOrderNumber
  // initialize markup
  submitValues.markup = generateMarkupData(values.markup)
  submitValues.ticketIds = addedTickets.map((ticket) => ticket.id)
  // Start and end dates
  const startDate = new Date(values.date_start)
  submitValues.date_start = startDate.toISOString()
  if (values.date_end) {
    const endDate = new Date(values.date_end)
    submitValues.date_end = endDate.toISOString()
  } else {
    delete submitValues.date_end
  }
  submitValues.is_lump_sum = submitValues.is_lump_sum ?? false
  return submitValues
}
