import React from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"

import InvoiceTopCompanyInfo from "../../../../../../components/Invoice/InvoiceTopCompanyInfo"
import InvoiceTopLogo from "../../../../../../components/Invoice/InvoiceTopLogo"
import InvoiceTopAttachedFiles from "../../../../../../components/Invoice/InvoiceTopAttachedFiles"
import DetailRow from "../../../../../../components/DetailRow"
import { CompanySenderReceiverInfo } from "../../../../../../types/company"
import InvoiceNumberDate from "../../../../../../components/Invoice/InvoiceNumberDate"
import { Ticket } from "../../../../../../types/ticket"


interface TicketInvoiceTopSectionProps {
  ticket: Ticket,
  sender: CompanySenderReceiverInfo | null,
  senderLogoUrl?: string,
  receiver: CompanySenderReceiverInfo | null,
  files: any[],
  // Inner component displayed as-is
  invoiceDetails: any,
}

export default function TicketInvoiceTopSection(
  {ticket, sender, senderLogoUrl, receiver, files, invoiceDetails}: TicketInvoiceTopSectionProps
): JSX.Element {
  const { t } = useTranslation("private")

  return (
    <Grid container spacing={3}>
      <InvoiceNumberDate ticket={ticket}/>

      <InvoiceTopLogo senderLogoUrl={senderLogoUrl} senderName={sender?.name}/>

      <Grid container item xs={8}>
        {invoiceDetails}
      </Grid>

      <Grid container item xs={4}>
        <InvoiceTopCompanyInfo sender={sender} receiver={receiver}/>
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow
            title={t("view.ChangeOrder.Summary.notes")}
            content={ticket.notes}
          />
        </Grid>
      </Grid>

      <InvoiceTopAttachedFiles files={files}/>
    </Grid>
  )
}
