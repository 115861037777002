import React from "react"
import {Switch, Route, Redirect} from "react-router-dom"

import AuthenticatedRouter from "./views/Authenticated/Authenticated.Router"
//@ts-ignore
import ErrorView from "./views/Error"
import UnauthenticatedRouter from "./views/Unauthenticated/Unauthenticated.Router"

export default function AppRouter() {
  return (
    <Switch>
        <Route path="/0">
          <AuthenticatedRouter />
        </Route>

        <Route exact path="/">
          <Redirect to={'/login'}/>
        </Route>

        <Route path="/">
          <UnauthenticatedRouter/>
        </Route>

        <Route path="*">
          <ErrorView />
        </Route>
    </Switch>
  )
}