
export type SupportedLanguage = 'en' | 'es'

export type SupportedDateLanguages = "es-US" | "en-US"

export const SupportedLanguages: {[key: string]: {display: string, value: SupportedLanguage, date: SupportedDateLanguages}} = {
  // NOTE: display is in private.json
  English: {value: 'en', display: 'form.EditUserProfile.Language.en', date: "en-US"},
  Spanish: {value: 'es', display: 'form.EditUserProfile.Language.es', date: "es-US"},
}
