import {Bulletin} from '../../../../../types/bulletin'
import {BulletinSubcontractor} from '../../../../../api/bulletin'
import {CreateBulletinInitialValues} from '../../../../../forms/CreateBulletin.types'


export function createInitialFormValues(
  bulletin: Bulletin,
  bulletinSubs: BulletinSubcontractor[]
): CreateBulletinInitialValues {
  return {
    pco_number: bulletin.pco_number ?? '',
    title: bulletin.title,
    description: bulletin.description,
    notes: bulletin.notes ?? '',
    date_estimates_due: bulletin.date_estimates_due ?? (new Date()).toDateString(),
    files: [{name: '', url: '', file_size_bytes: 0},],
    attached_subs: bulletinSubs,
  }
}
