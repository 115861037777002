import React from "react"
import { useTranslation } from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"
import { Table, TableHead, TableCell, TableRow } from "@mui/material"
import Paper from "@mui/material/Paper"

import { formatDateToMonthYear, formatMoney } from "../../../../../libs/format"


const useStyles = makeStyles(() => ({
  monthlyCOsReportTable: {
    overflow: 'hidden',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)' 
  },
  monthlyCOsReportLabels: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  monthlyCOsReportData: {
    fontSize: '15px'
  }
}))


export default function MonthlyCOsReportTable(props: any) {
  const { monthlyCOsReportData } = props
  const classes = useStyles()
  const { t } = useTranslation("private")

  return (
    <Paper className={classes.monthlyCOsReportTable}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} height="75px" className={classes.monthlyCOsReportLabels}>
              {t("view.Admin.monthlyCOsReportMonth")}
            </TableCell>
            {monthlyCOsReportData.map((change_order: any) => (
              <TableCell align="center" className={classes.monthlyCOsReportLabels}>
                {formatDateToMonthYear(change_order.month)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={2} height="56px" className={classes.monthlyCOsReportLabels}>
            {t("view.Admin.monthlyCOsReportCOsCreated")}
            </TableCell>
            {monthlyCOsReportData.map((change_order: any) => (
              <TableCell align="center" className={classes.monthlyCOsReportData}>
                {change_order.change_orders_created}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={2} height="56px" className={classes.monthlyCOsReportLabels}>
              {t("view.Admin.monthlyCOsReportCOsAmount")}
            </TableCell>
            {monthlyCOsReportData.map((change_order: any) => (
              <TableCell align="center" className={classes.monthlyCOsReportData}>
                {formatMoney(change_order.change_orders_created_amount)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={10} height="56px"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Paper>
  )
}