import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { apiHeaders } from "../../api/util"
import { Material, MaterialStore } from "../../types/material"
import { TracFloState } from "../../types/state"

const initialState: MaterialStore = {
  items: [],
  exp: "",
  status: "idle",
}

export const loadMaterial = createAsyncThunk<Material[], void, {state: TracFloState}>(
  "user/loadMaterial",
  async (args, thunkAPI) => {
    const { company, user } = await thunkAPI.getState()
    const response = await axios({
      headers: apiHeaders(company.id, user.token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/material`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return data
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const materialSlice = createSlice({
  name: "material",
  initialState,
  reducers: {
    addMaterial: (state, action) => {
      state.items.unshift(action.payload)
    },
    addMultipleMaterials: (state, action: {payload: Material[]}) => {
      const newMaterials = [...state.items, ...action.payload]
      newMaterials.sort((a, b) => a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1)
      state.items = newMaterials
    },
    deleteMaterial: (state, action) => {
      state.items.splice(
        state.items.findIndex((item) => item.id === action.payload),
        1
      )
    },
    updateMaterial: (state, action) => {
      const newMaterial = action.payload
      if (newMaterial && newMaterial.id) {
        state.items = state.items.map((item) => (item.id === newMaterial.id ? newMaterial : item))
      }
    },
    resetMaterial: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMaterial.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadMaterial.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadMaterial.fulfilled, (state, action) => {
        if (action.payload && action.payload.length) {
          const materials = action.payload
          state.items = materials
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 60 // one hour
        }
      })
  },
})

export const { addMaterial, deleteMaterial, updateMaterial, resetMaterial, addMultipleMaterials } =
  materialSlice.actions

export const getMaterialTypes = (state: TracFloState) => state.material.items

export default materialSlice.reducer
