import React from "react"
import DownloadIcon from '@mui/icons-material/Download'
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

import Fab from "../../../../../../components/Fab"
import {downloadBulletinBackups} from '../../../../../../api/bulletin'

interface BulletinBackupsButtonProps {
  projectId: string,
  bulletinId: string,
}

export default function BulletinBackupsButton({projectId, bulletinId}: BulletinBackupsButtonProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()

  const downloadBackups = async () => {
    try {
      const res = await downloadBulletinBackups(projectId, bulletinId)
      if (res.status === 200 && res.data.success) {
        enqueueSnackbar(
          t("view.Bulletins.SingleBulletin.BulletinBackupsButton.success"),
          {variant: 'success'}
        )
        return
      }
      enqueueSnackbar(
        t("view.Bulletins.SingleBulletin.BulletinBackupsButton.error"),
        {variant: 'error'}
      )
    } catch (e: any) {
      const res = e.response
      if (res.status === 400 && typeof(res.data) === 'string') {
        if (res.data.includes('does not have any tickets')) {
          enqueueSnackbar(
            t("view.Bulletins.SingleBulletin.BulletinBackupsButton.noTix"),
            {variant: 'info'}
          )
          return
        }
      }
      enqueueSnackbar(
        t("view.Bulletins.SingleBulletin.BulletinBackupsButton.error"),
        {variant: 'error'}
      )
    }
  }

  return (
    <Fab
      // @ts-ignore
      edge="end"
      size="medium"
      style={{color: "white", backgroundColor: "#143366"}}
      variant="extended"
      onClick={downloadBackups}
    >
      <DownloadIcon />
      {t("view.Bulletins.SingleBulletin.BulletinBackupsButton.buttonText")}
    </Fab>
  )
}
