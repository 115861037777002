import { AxiosResponse } from "axios"

// @ts-ignore
import api from "../libs/api"
import { uploadFiles } from "./file"
import { BulletinReviewProcess } from "../types/bulletin"

export async function uploadBulletinReviewProcessFiles(
  files: any[],
  project_id: string,
  brp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, brp_id, 'bulletin_review_process')
}

export function shareBulletin(
  projectId: string, 
  bulletinId: string, 
  message: string
): Promise<AxiosResponse<BulletinReviewProcess>> {
  return api({
    method: "post",
    url: `/project/${projectId}/bulletin/${bulletinId}/share`,
    data: {message}
  })
}

export function notifyForShareBulletin(
  projectId: string, 
  brpId: string, 
  idsToEmail: string[]
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${projectId}/bulletin_review_process/${brpId}/notify_for_share`,
    data: {idsToEmail}
  })
}