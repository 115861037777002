import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Typography, Menu, MenuItem} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {Link} from 'react-router-dom'

import {AttachableEstimate, getAttachableEstimates, getEstimateById} from '../api/estimate'
import {goToSingleEstimate} from '../util/routes'
import theme from '../theme'
import {useTracFloSelector} from '../store/useTracFloSelector'
import {getCompany} from '../store/features/companySlice'


type AttachEstimateProps = {
  estimate_id: string | null,
  attachNewEstimate: (estimate_id: string) => void,
  removeEstimate: () => void,
}

export function AttachEstimate(
  {estimate_id, attachNewEstimate, removeEstimate}: AttachEstimateProps
): JSX.Element {
  const company = useTracFloSelector(getCompany)
  const project = useTracFloSelector((state) => state.project)
  const { t } = useTranslation("private")
  const [attachableEstimates, setAttachableEstimates] = useState<AttachableEstimate[] | null>(null)
  const [currentEstimate, setCurrentEstimate] = useState<AttachableEstimate | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  useEffect(() => {
    if (company.company_type === 'trade') {
      getAttachableEstimates(project.id).then((res) => {
        if (res.status === 200 && !!res.data) {
          setAttachableEstimates(res.data)
        }
      })
    } else {
      setAttachableEstimates([])
    }
  }, [project.id, company.company_type])

  useEffect(() => {
    if (attachableEstimates != null) {
      if (estimate_id == null) {
        setCurrentEstimate(null)
      } else {
        // trades will have it in their attachable estimates
        if (company.company_type === 'trade') {
          const currentFromAttachable = attachableEstimates.find((e) => e.id === estimate_id)
          setCurrentEstimate(currentFromAttachable ?? null)
        } else {
          // gcs can get info from get by id
          getEstimateById(project.id, estimate_id).then((res) => {
            if (res.status === 200 && res.data.id) {
              setCurrentEstimate({
                id: estimate_id,
                subject: res.data.subject,
                description: res.data.description,
                number: res.data.number,
                pco_number: res.data.pco_number,
              })
            }
          })
        }
      }
    }
  }, [attachableEstimates, estimate_id, project.id, company.company_type])

  const handleOpen = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: any) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const selectNewEstimate = (event: any, estimate: AttachableEstimate) => {
    handleClose(event)
    attachNewEstimate(estimate.id)
  }

  const removeEstimateAndClose = (event: any) => {
    handleClose(event)
    removeEstimate()
  }

  const estimateDisplayText = currentEstimate == null
    ? <></>
    : <Link
      to={goToSingleEstimate(project.id, currentEstimate.id)}
      style={{ textDecorationColor: "#40C4FF" }}
      onClick={() => {window.scrollTo(0, 0)}}
      target={"_blank"}
    >
      <Typography color="secondary" display="inline">
        {`#${currentEstimate.pco_number ?? currentEstimate.number}`}
      </Typography>
    </Link>

  const allowedToEditAttachableEstimate = ['pm', 'super'].includes(project.project_user_role)
    && company.company_type === 'trade'

  const pickNewEstimate = allowedToEditAttachableEstimate
    ? currentEstimate == null
      // show text instructions when no current estimate
      ? <Typography
        color="secondary"
        display="inline"
        style={{
          textDecorationColor: "#40C4FF",
          textDecoration: 'underline',
          cursor: 'pointer',
          fontWeight: 700
        }}
        onClick={handleOpen}
      >
        {t('component.AttachEstimate.addEstimate')}
      </Typography>
      // small edit icon if we have a current estimate
      : <EditIcon
        sx={{color: theme.palette.secondary.main, marginLeft: '5px', cursor: 'pointer', fontSize: 16}}
        onClick={handleOpen}
      />
    // if not allowed to edit, don't display
    : <></>

  const allowedToViewAttachableEstimates = ['pm', 'super'].includes(project.project_user_role)
    && (
      // trades can always see it, so that they can attach an estimate if none exists
      company.company_type === 'trade'
      // GCs can only see it if there's one actively attached
      || estimate_id != null
    )

  return allowedToViewAttachableEstimates && attachableEstimates != null
    ? <Typography display="inline">
      <strong>{t("component.AttachEstimate.rowTitle")}</strong>

      {estimateDisplayText}

      {pickNewEstimate}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          estimate_id != null 
            ? <MenuItem onClick={(event) => removeEstimateAndClose(event)}>
              {t("component.AttachEstimate.removeEstimate")}
            </MenuItem>
            : <></>
        }
        {attachableEstimates.map((e) => (
          <MenuItem onClick={(event) => selectNewEstimate(event, e)}>
            {`${e.subject} (${e.pco_number ? `PCO #${e.pco_number}` : e.number})`}
          </MenuItem>
        ))}
      </Menu>
    </Typography>
    : <></>
}
