import React from "react"
import Box from "@mui/material/Box"

import DataGridAccordionRow, { DataGridAccordionRowItem } from "./DataGridAccordionRow"

interface DataGridAccordionProps {
  items: DataGridAccordionRowItem[]
}

export default function DataGridAccordion({ items }: DataGridAccordionProps) {
  const [expandedIndex, setExpandedIndex] = React.useState<number | null>(null)

  const handlePanelExpandChange = (panelIndex: number) => {
    setExpandedIndex(expandedIndex !== panelIndex ? panelIndex : null)
  }

  return (
    <Box>
      {items.map((item: DataGridAccordionRowItem, index: number) => (
        <DataGridAccordionRow
          expanded={expandedIndex === index}
          handlePanelExpandChange={() => {handlePanelExpandChange(index)}}
          index={index}
          item={item}
          key={item.title}
        />
      ))}
    </Box>
  )
}
