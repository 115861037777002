import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import config from "../../libs/config"
import { reduxStateExpired } from "../../util/expiry"
import { TracFloState } from "../../types/state"
import { ExpirationField } from "../../types/expiration"
import {Bulletin, BulletinsStore} from '../../types/bulletin'
import {apiHeaders} from '../../api/util'

const initialState: BulletinsStore = {
  items: [],
  projectId: null,
  exp: "",
  status: "idle",
}

export const loadBulletins = createAsyncThunk<any, void, {state: TracFloState}>(
  "user/loadBulletins",
  async (_, thunkAPI) => {
    const { company, project, user } = await thunkAPI.getState()
    const { token } = user
    const response = await axios({
      headers: apiHeaders(company.id, token),
      method: "get",
      timeout: 20000,
      url: `${config.api.url}/project/${project.id}/bulletin`,
    })
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      const { data } = response
      return { data, projectId: project.id }
    } else {
      return thunkAPI.rejectWithValue(response)
    }
  }
)

export const bulletinsSlice = createSlice({
  name: "bulletins",
  initialState,
  reducers: {
    addBulletin: (state, action: {payload: Bulletin}) => {
      state.items.unshift(action.payload)
    },
    resetBulletins: () => initialState,
    updateBulletin: (state, action: {payload: Bulletin}) => {
      if (action.payload) {
        const updatedBulletin = action.payload
        updatedBulletin.date_modified = (new Date()).toISOString()
        state.items = [updatedBulletin, ...state.items.filter((b) => b.id !== updatedBulletin.id)]
      }
    },
    updateBulletinAction: (state, action: {payload: {new_action_id: number, bulletin_id: string}}) => {
      const updatedBulletin = state.items.find((b) => b.id === action.payload.bulletin_id)
      if (updatedBulletin != null) {
        updatedBulletin.bulletin_action_id = action.payload.new_action_id
        updatedBulletin.date_modified = (new Date()).toISOString()
        // remove the bulletin
        state.items.splice(
          state.items.findIndex((item) => item.id === action.payload.bulletin_id),
          1
        )
        // add it back in at the front
        state.items = [updatedBulletin, ...state.items]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBulletins.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loadBulletins.rejected, (state) => {
        state.status = "idle"
      })
      .addCase(loadBulletins.fulfilled, (state, action: {payload: {data: Bulletin[], projectId: string}}) => {
        if (action.payload && action.payload.data) {
          state.items =  action.payload.data.filter((row) => row.id).map((b) => {
            return {
              ...b,
              // need to create author from data sent
              author: `${b.inbox_first_name} ${b.inbox_last_name}`
            }
          })
          // Set expiration
          const now = Math.floor(Date.now() / 1000)
          state.exp = now + 60 * 5 // five minutes
          state.projectId = action.payload.projectId
        }
        state.status = "fulfilled"
      })
  },
})

export const { addBulletin, resetBulletins, updateBulletinAction, updateBulletin } = bulletinsSlice.actions

export const listBulletins = (state: TracFloState) => state.bulletins.items

export const reloadBulletinsIfInvalid = (
  projectId: string,
  bulletinsProjectId: string | null,
  bulletinsExpiry: ExpirationField,
  dispatch: any,
) => {
  if (projectId !== bulletinsProjectId || reduxStateExpired(bulletinsExpiry)) {
    dispatch(loadBulletins())
  }
}

export default bulletinsSlice.reducer
