import {AxiosResponse} from 'axios'

// @ts-ignore
import api from "../libs/api"
import {uploadFiles} from './file'
import {Bulletin} from '../types/bulletin'
import { File } from '../types/file'
import { HistoryItem } from '../types/history'


export async function createBulletin(project_id: string, bulletinData: any): Promise<AxiosResponse<Bulletin>> {
  return api({
    method: "post",
    url: `/project/${project_id}/bulletin`,
    data: bulletinData,
  })
}

export async function uploadBulletinFiles(
  files: any[],
  project_id: string,
  bulletin_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, bulletin_id, 'bulletin')
}

export interface BulletinSubcontractor {
  id: number | null, // never null if from API, but this type is used for form as well
  subcontractor_id: string,
  subcontractor_name: string,
  subcontractor_user_id: string,
  subcontractor_user_name: string,
  // null if no estimate is attached yet
  estimate_id: string | null,
  estimate_action_id: number | null,
  estimate_total_amount: string | null,
}

export async function getBulletinSubcontractors(
  project_id: string,
  bulletin_id: string
): Promise<AxiosResponse<BulletinSubcontractor[]>> {
  return api({method: "get", url: `/project/${project_id}/bulletin/${bulletin_id}/subcontractor`,})
}

export async function getBulletinFiles(
  project_id: string,
  bulletin_id: string
): Promise<AxiosResponse<File[]>> {
  return api({method: "get", url: `/project/${project_id}/bulletin/${bulletin_id}/file_presigned`,})
}

export async function getBulletinHistory(project_id: string, bulletin_id: string): Promise<AxiosResponse<HistoryItem[]>> {
  return api.get(`/project/${project_id}/bulletin/${bulletin_id}/history`)
}

export async function getBulletinById(project_id: string, bulletin_id: string): Promise<AxiosResponse<Bulletin>> {
  return api({method: "get", url: `/project/${project_id}/bulletin/${bulletin_id}`})
}

export async function sendRfps(project_id: string, bulletin_id: string): Promise<AxiosResponse<{success: boolean, new_action_id: number}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/bulletin/${bulletin_id}/send_rfps`,
  })
}

export async function editBulletin(project_id: string, bulletin_id: string, bulletinData: any): Promise<AxiosResponse<Bulletin>> {
  return api({
    method: "patch",
    url: `/project/${project_id}/bulletin/${bulletin_id}`,
    data: bulletinData,
  })
}

export async function noLongerNeeded(project_id: string, bulletin_id: string): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/bulletin/${bulletin_id}/not_needed`,
  })
}

export async function notifyToProceed(project_id: string, bulletin_id: string): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/bulletin/${bulletin_id}/notify_to_proceed`,
  })
}

export async function getNextBulletinPcoNumber(project_id: string): Promise<AxiosResponse<{next_pco_number: string}>> {
  return api({
    method: "get",
    url: `/project/${project_id}/next_bulletin_pco_number`,
  })
}

export async function downloadBulletinBackups(
  project_id: string,
  bulletin_id: string
): Promise<AxiosResponse<{success: boolean}>> {
  return api({
    method: "post",
    url: `/project/${project_id}/bulletin/${bulletin_id}/zip_backups`,
  })
}
