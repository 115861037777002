import React from "react"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"


interface StatusFieldProps {
  isClosed: boolean,
}

export default function StatusField({isClosed}: StatusFieldProps): any {
  const { t } = useTranslation("private")
  return (
    <Typography>
      <strong>{t("view.ChangeOrder.Summary.status")}</strong>:{" "}
      {isClosed ? t("view.ChangeOrder.Summary.closed") : t("view.ChangeOrder.Summary.open")}
    </Typography>
  )
}
