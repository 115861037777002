import React from "react"

import ContactSupportToAddFeature from "./ContactSupportToAddFeature"
import FabDialog from "./FabDialog"

interface ContactCustomerSupportDialogProps {
  buttonText: string,
  titleText: string,
  featureDescription: string,
  LeadingIconComponent?: any,
}

export default function ContactCustomerSupportDialog(props: ContactCustomerSupportDialogProps) {
  const {buttonText, titleText, featureDescription, LeadingIconComponent} = props

  return (
    <FabDialog
      buttonText={buttonText}
      titleText={titleText}
      Component={ContactSupportToAddFeature}
      componentProps={{featureDescription}}
      LeadingIconComponent={LeadingIconComponent}
    />
  )
}
