import React from "react"

import {useTracFloSelector} from "../../../../../../store/useTracFloSelector"
import {getIntegrationObjForActiveIntegration} from "../../../../../../store/features/userSlice"
import PushToProcoreContainer from "./PushToProcore"


interface PushToIntegrationFormProps {
  projectId: string,
  changeOrder: any,
  closeDialog: () => void
}

export default function PushToIntegrationForm(props: PushToIntegrationFormProps) {
  const activeIntegrationInfo = useTracFloSelector(getIntegrationObjForActiveIntegration)

  return activeIntegrationInfo?.code === 'procore'
    ? (<PushToProcoreContainer {...props}/>)
    : (<></>)
}
