import React from "react"

//@ts-ignore
import Link from "../../../../../components/Link"
import { formatDate } from "../../../../../libs/format"
import { Company } from "../../../../../types/company"


export const ProjectsCreatedInLastSixtyDaysColumns = (
  t: (s: string) => string
) => {
  return [
    {
      field: "name",
      headerName: t("view.Admin.projectName"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 300,
    },
    {
      field: "date_created",
      headerName: t("view.Admin.projectDateCreated"),
      editable: false,
      align: "left",
      headerAlign: "left",
      width: 300,
      renderCell: (params: any) => formatDate(params.value)
    },
    {
      field: "companies",
      headerName: t("view.Admin.projectCreatedLastSixtyDaysAttachedCompanies"),
      editable: true,
      align: "left",
      headerAlign: "left",
      width: 300,
      renderCell: (params: any) => (
        <ul
          style = {{
            listStyle: "none",
            display: "inline-block",
            padding: "0px"
          }}
        >
          {
            params.value.map((attachedCompany: Company) =>
              <li
                style ={{padding: "5px"}}
              >
                <Link to={`/0/admin/companies/${attachedCompany.id}`}>
                  {attachedCompany.name}
                </Link>
              </li>
            )
          }
        </ul>
      )
    }
  ]
}