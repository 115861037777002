import React from 'react'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {AttachEstimate} from '../../../../../../components/AttachEstimate'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import { attachEstimateToDaily, removeEstimateFromDaily } from '../../../../../../api/daily'


export type DailyAttachedEstimateProps = {
  dailyId: string,
  estimate_id: string | null,
  attachEstimate: (estimate_id: string) => void,
  removeEstimate: () => void,
}

const tName = 'view.Daily.SingleDaily.DailyAttachEstimate'

export function DailyAttachEstimate(
  {estimate_id, dailyId, attachEstimate, removeEstimate}: DailyAttachedEstimateProps
): JSX.Element {
  const {t} = useTranslation('private')
  const {enqueueSnackbar} = useSnackbar()
  const project = useTracFloSelector((state) => state.project)

  const attachEstimateFromComponent = async (estimateId: string) => {
    try {
      const res = await attachEstimateToDaily(project.id, dailyId, estimateId)
      if (res.status !== 200 || !res.data.estimate_id) {
        enqueueSnackbar(t(`${tName}.attachFail`), {variant: 'error'})
        return
      }
      enqueueSnackbar(t(`${tName}.attachSuccess`), {variant: 'success'})
      attachEstimate(res.data.estimate_id)
    } catch (_) {
      enqueueSnackbar(t(`${tName}.attachFail`), {variant: 'error'})
    }
  }

  const removeEstimateFromComponent = async () => {
    try {
      const res = await removeEstimateFromDaily(project.id, dailyId)
      if (res.status !== 200) {
        enqueueSnackbar(t(`${tName}.removeFail`), {variant: 'error'})
        return
      }
      enqueueSnackbar(t(`${tName}.removeSuccess`), {variant: 'success'})
      removeEstimate()
    } catch (_) {
      enqueueSnackbar(t(`${tName}.removeFail`), {variant: 'error'})
    }
  }

  return (
    <AttachEstimate
      estimate_id={estimate_id}
      attachNewEstimate={attachEstimateFromComponent}
      removeEstimate={removeEstimateFromComponent}
    />
  )
}

