// @ts-ignore
import ticketsIcon from "../assets/icon-tickets.svg"
// @ts-ignore
import manageIcon from "../assets/icon-manage.svg"
// @ts-ignore
import changeOrdersIcon from "../assets/icon-changeorders.svg"
// @ts-ignore
import bulletinsIcon from "../assets/icon-bulletins.svg"
// @ts-ignore
import estimatesIcon from "../assets/icon-estimates.svg"
// @ts-ignore
import dailyIcon from "../assets/icon-daily.svg"
// @ts-ignore
import overviewIcon from "../assets/overview.svg"
import {CompanyType} from "../types/company"
import {ProjectUserRoleCode} from "../types/userRoles"
import {
  goToBulletinList,
  goToChangeOrdersList,
  goToDailyList,
  goToEstimateList,
  goToProjectOverview,
  goToTicketsList
} from "../util/routes"

const crewProjectNavItems = (projectId: string, t: any, companyType: CompanyType): any[] => {
  const columns = [
    {
      icon: ticketsIcon,
      route: goToTicketsList(projectId),
      text: t("Tickets"),
    }
  ]

  if(companyType === "trade"){
    columns.splice(1,0, {
      icon: dailyIcon,
      route: goToDailyList(projectId),
      text: t("Daily Reports"),
    })
  }

  return columns
}

const allProjectNavItems = (
  projectUserRole: ProjectUserRoleCode,
  projectId: string,
  t: any,
  companyType: CompanyType
): any[] => {
  const allProjectNavItems = [...crewProjectNavItems(projectId, t, companyType)]
  if(projectUserRole !== 'crew'){
    allProjectNavItems.splice(0,0,
      {
        icon: overviewIcon,
        route: goToProjectOverview(projectId),
        text: t("Overview"),
      }
    )
    allProjectNavItems.splice(1, 0, {
      icon: changeOrdersIcon,
      route: goToChangeOrdersList(projectId),
      text: t("Change_Orders"),
    })
  }
  if (projectUserRole !== 'crew') {
    allProjectNavItems.splice(1,0,
      {
        icon: estimatesIcon,
        route: goToEstimateList(projectId),
        text: t("Estimates"),
      }
    )
  }
  if (companyType === 'cm') {
    allProjectNavItems.splice(1,0,
      {
        icon: bulletinsIcon,
        route: goToBulletinList(projectId),
        text: t("Bulletins"),
      }
    )
  }
  allProjectNavItems.push(
    {
      icon: manageIcon,
      route: `/0/project/${projectId}/settings`,
      text: t("Manage_Project"),
    }
  )
  return allProjectNavItems
}

export const projectNavItems = (
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  projectId: string,
  t: any
): any[] => {
  if (projectUserRole === 'crew') return crewProjectNavItems(projectId, t, companyType)
  if (companyType === 'cm' || companyType === 'trade') return allProjectNavItems(projectUserRole, projectId, t, companyType)
  return []
}
