import React from "react"

import StatusField from '../../../../../../components/Invoice/StatusField'


interface TicketStatusTextProps {
  ticket: any,
}

export default function TicketStatusText(props: TicketStatusTextProps): any {
  const{ ticket } = props

  return (<StatusField isClosed={!!ticket.date_closed && !!ticket.reason_ticket_closed}/>)
}
