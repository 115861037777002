import React from "react"
import {GridColDef, GridValueGetterParams} from "@mui/x-data-grid"
import { Typography } from "@mui/material"

import { formatDate, formatMoney } from '../../../../../libs/format'
import { ProjectUserRoleCode } from "../../../../../types/userRoles"
import {goToSingleDaily} from '../../../../../util/routes'
import FormatTableTitleLink from '../../../../../components/FormatTableTitleLink'
import {ProductionMetric} from '../../../../../types/productionMetric'


function createProductionMetricColumns(pms: ProductionMetric[]): GridColDef[] {
  return pms.map((pm) => ({
    field: pm.description,
    headerName: `${pm.description} (${pm.unit})`,
    type: 'number',
    editable: false,
    align: "center",
    headerAlign: "center",
    width: 120,
  }))
}

function convertCodesToString(codes: string[] | null | undefined): string {
  if (codes == null) return ''
  if (codes.length === 0) return ''
  if (Array.isArray(codes)) {
    return codes.join(', ')
  }
  return ''
}

export const DailyListColumns = (
  projectUserRole: ProjectUserRoleCode,
  t: any,
  projectId: string,
  pms: ProductionMetric[]
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      align: "left",
      headerAlign: "left",
      hide: true,
      width: 110,
    },
    {
      field: "number",
      headerName: "#",
      type: 'string',
      align: "left",
      headerAlign: "left",
      width: 100,
      editable: false,
    },
    {
      align: "left",
      field: "subject",
      headerAlign: "left",
      headerName: t("view.Daily.DailyList.DailyListColumns.subject"),
      width: 250,
      editable: false,
      renderCell: (params: any) => (
        <FormatTableTitleLink
          url={goToSingleDaily(projectId, params.row.id)}
          text={params.value}
          openNewWindow={false}
        />
      )
    },
    {
      field: "description",
      headerName: t("view.Daily.DailyList.DailyListColumns.description"),
      editable: false,
      hide: true,
      align: "left",
      headerAlign: "left",
      width: 115,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "date_start",
      headerName: t("view.Daily.DailyList.DailyListColumns.date"),
      width: 170,
      editable: false,
      renderCell: (params: any) => {
        return formatDate(params.value)
      },
      type: 'dateTime'
    },
    {
      field: "codes",
      type: 'string',
      headerName: t("view.Daily.DailyList.DailyListColumns.codes"),
      valueGetter: (params: GridValueGetterParams<string[], any>) => convertCodesToString(params.value),
      editable: false,
      align: "right",
      headerAlign: "right",
      width: 170,
    },
  ]

  if(projectUserRole !== 'crew'){
    columns.push(
      {
        field: "total_amount",
        headerName: t("view.Daily.DailyList.DailyListColumns.total"),
        type: 'number',
        renderCell: (params) => (
          <Typography textAlign="right">
            {formatMoney(params.row.total_amount ?? 0)}
          </Typography>
        ),
        editable: false,
        align: "right",
        headerAlign: "right",
        width: 192,
      },
      {
        field: "author",
        headerName: t("view.Daily.DailyList.DailyListColumns.inbox"),
        type: "string",
        renderCell: (params: any) => params.value,
        editable: false,
        align: "right",
        headerAlign: "right",
        width: 122,
      },
    )

    // add production metric columns
    columns.push(...createProductionMetricColumns(pms))
  }

  return columns
}
