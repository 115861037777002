import React, {useState} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import AddIcon from "@mui/icons-material/Add"
import { useSnackbar } from "notistack"

// @ts-ignore
import DataTable from "../../../../components/DataTable"
// @ts-ignore
import OverviewListLayout from "../../../../components/OverviewListLayout"
// @ts-ignore
import CreateProjectForm from "../../../../forms/CreateProject"
// @ts-ignore
import Dialog from "../../../../components/Dialog"
import { HomeColumns } from "./Home.columns"
import { listUserProjects, reloadProjectsIfInvalid, deleteProject } from "../../../../store/features/userSlice"
import { useTracFloSelector } from "../../../../store/useTracFloSelector"
import { Project } from "../../../../types/project"
import FeatureAccessButton from "../../../../components/FeatureAccessButton"
import ConnectToIntegration from "./ConnectToIntegration"
import { changeProjectIsActiveStatus } from "../../../../api/project"
import { resetProject } from "../../../../store/features/projectSlice"
import ConfirmOrCancelPrompt from "../../../../components/ConfirmOrCancelPrompt"


export default function Home() {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const loadingStatus = useTracFloSelector((state) => state.user.status.projects)
  const user = useTracFloSelector((state) => state.user)
  const company = useTracFloSelector((state) => state.company)
  const activeProject = useTracFloSelector((state) => state.project)
  const projects: Project[] = useTracFloSelector(listUserProjects)
  const projectsExpiry = useTracFloSelector((state) => state.user.exp.projects)
  const activeIntegration = useTracFloSelector((state) => state.user.activeIntegration)
  const activeIntegrationInfo = useTracFloSelector(
    (s) => s.integration.items.find((i) => i.id === activeIntegration?.integration_id)
  )
  const [openConnectToDialog, setOpenConnectToDialog] = useState<boolean>(false)
  const [connectToProject, setConnectToProject] = useState<{id: string, name: string} | null>(null)
  const [archiveProjectId, setArchiveProjectId] = useState<string | null>(null)
  const [openArchiveProjectDialog, setOpenArchiveProjectDialog] = useState<boolean>(false)

  React.useEffect(() => {
    if (!!company.id) {
      reloadProjectsIfInvalid(projectsExpiry, dispatch)
    }
  }, [company.id, dispatch, projectsExpiry])

  const userCanViewCreateProjectButton = company && (company.company_type === "cm" || company.company_type === "trade")
    && company.company_user_role === "admin"

  // superadmins and sub company admins
  const userCanViewCreateProjectDialog = user.is_admin || (
    company.company_type === 'trade' && company.company_user_role === 'admin'
  )

  const openConnectToIntegrationDialog = (project: any): void => {
    setConnectToProject(project)
    setOpenConnectToDialog(true)
  }

  const closeConnectToIntegrationDialog = (): void => {
    setConnectToProject(null)
    setOpenConnectToDialog(false)
  }

  const openConfirmCancelArchiveProjectDialog = async (projectId: string): Promise<void> => {
    setArchiveProjectId(projectId)
    setOpenArchiveProjectDialog(true)
  }
  
  const closeConfirmCancelArchiveProjectDialog = () => {
    setOpenArchiveProjectDialog(false)
  }

  const archiveProject = async () => {
    closeConfirmCancelArchiveProjectDialog()
    if(!!archiveProjectId){
      try {
        const { data } = await changeProjectIsActiveStatus(
          false,
          archiveProjectId,
        )
        if (!!data.project_id) {
          dispatch(deleteProject(data.project_id)) // Remove the project once we have a successful call.
          // If active project equals archived project, reset project state
          if(data.project_id === activeProject.id) {
            dispatch(resetProject())
          }
          enqueueSnackbar(t("view.Projects.archive_success"), {
            variant: "success",
          })
        } else {
          enqueueSnackbar(t("view.Projects.archive_fail"), {
            variant: "error",
          })
        }
      } catch(_) {
        enqueueSnackbar(t("view.Projects.archive_fail"), {
          variant: "error",
        })
      }
    }
  }

  return (
    <>
      <OverviewListLayout
        actions={
          userCanViewCreateProjectButton
            ?
            <FeatureAccessButton
              canAccessFeature={userCanViewCreateProjectDialog}
              supportDialogFeatureDesc={t("view.Projects.supportDialogFeatureProjectsDesc")}
              supportDialogTitle={t("view.Projects.add")}
              dialogProps={{
                buttonText: t("view.Projects.button"),
                Component: CreateProjectForm,
                titleText: t("view.Projects.add"),
                LeadingIconComponent: <AddIcon/>
              }}
            />
            :
            <></>
        }
        title={t("view.Projects.your_projects")}
      >
        <DataTable
          checkboxSelection={false}
          columns={
            HomeColumns(
              projects.find(
                (project) => project.project_user_role !== "crew"
              ) === undefined,
              openConnectToIntegrationDialog,
              company,
              openConfirmCancelArchiveProjectDialog,
              projects.find(
                (project) => project.project_user_role === "pm"
              ) !== undefined
            )
          }
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          loading={loadingStatus === "loading"}
          rows={projects}
          noRowsContent="You don't have any projects, contact your manager to get added to a project"
        />
      </OverviewListLayout>

      {/* connect to integration dialog */}
      <Dialog
        open={openConnectToDialog}
        onClose={closeConnectToIntegrationDialog}
        maxWidth={'xl'}
        title={
          connectToProject != null && activeIntegrationInfo != null
            ? `Connect ${connectToProject.name} to ${activeIntegrationInfo.name}`
            : ''
        }
      >
        <ConnectToIntegration project={connectToProject} closeDialog={closeConnectToIntegrationDialog}/>
      </Dialog>
      <Dialog
        open={openArchiveProjectDialog}
        onClose={closeConfirmCancelArchiveProjectDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt 
          submitForm={archiveProject} 
          closeDialog={closeConfirmCancelArchiveProjectDialog} 
          dialogPrompt={t("view.Projects.archiveProjectPrompt")}
        />
      </Dialog>
    </>
  )
}
