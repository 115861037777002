import React, {useMemo} from "react"
import {TFunction, useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {
  ActionInfo, BulletinActionCode,
  getBulletinActionLabel,
} from '../../../../../types/action'
import {convertToNumber} from '../../../../../util/number'
import {getBulletinStatusFill,} from '../../../../../style/action'
import BudgetLogStatusChart from '../../../../../components/BudgetLogStatusChart'
import {BudgetStatusChartData} from '../../../../../components/BudgetLogStatusChart/type'
import {Bulletin} from '../../../../../types/bulletin'


function createBulletinStatusChartMapping(
  t: TFunction<'private'>,
  actionMap: {[p: number | string]: ActionInfo<BulletinActionCode>},
): {[status: string]: BudgetStatusChartData} {
  // for each action, prep a mapping that helps us create chart data quickly
  return Object.keys(actionMap).reduce<{[status: string]: BudgetStatusChartData}>(
    // NOTE: actionId is a number
    (statusMap, action_id) => {
      const statusLabel = getBulletinActionLabel(t, actionMap[action_id].code)
      const statusFill = getBulletinStatusFill(actionMap[action_id].code)
      statusMap[statusLabel] = {
        status: statusLabel,
        count: 0,
        budget_total: 0,
        actual_total: 0,
        label: statusLabel,
        fill: statusFill,
        id: action_id
      }
      return statusMap
    },
    {}
  )
}

function addBulletinToStatusChartMapping(
  // updated in place
  statusMap: {[status: string]: BudgetStatusChartData},
  bulletin: Bulletin,
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<BulletinActionCode>},
): void {
  const statusLabel = getBulletinActionLabel(t, actionMap[bulletin.bulletin_action_id].code,)
  statusMap[statusLabel].count += 1
  statusMap[statusLabel].budget_total += convertToNumber(bulletin.total_amount_approved) ?? 0
  statusMap[statusLabel].actual_total += convertToNumber(bulletin.total_actual_amount) ?? 0
}

function convertBulletinsToBulletinStatusChart(
  bulletins: Bulletin[],
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<BulletinActionCode>},
): BudgetStatusChartData[] {
  const statusChartMap = createBulletinStatusChartMapping(t, actionMap,)
  for (let bulletin of bulletins) {
    addBulletinToStatusChartMapping(statusChartMap, bulletin, t, actionMap)
  }
  // just trying to get similar statuses near one another in the chart
  return Object.values(statusChartMap).sort((cd1, cd2) => cd1.id > cd2.id ? 1 : 0)
}

export default function BulletinListStatusChart(): JSX.Element {
  const bulletins = useTracFloSelector((state) => state.bulletins.items)
  const actionMap = useTracFloSelector((state) => state.bulletinAction.idObjectMapping)
  const { t } = useTranslation("private")

  const statusData: BudgetStatusChartData[] = useMemo(() => {
    return convertBulletinsToBulletinStatusChart(bulletins, t, actionMap)
  }, [bulletins, t, actionMap])

  return (
    <BudgetLogStatusChart statusData={statusData}/>
  )
}
