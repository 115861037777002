import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import HistoryListItem from "../../components/History/HistoryListItem"
import { HistoryItem } from "../../types/history"


interface HistoryListProps {
  historyItems: HistoryItem[]
}

export default function HistoryList(props: HistoryListProps) {
  const { t } = useTranslation("private")

  return props.historyItems && props.historyItems.length ? (
    <Box>
      <Typography variant="h2" sx={{ mb: 3, fontSize: 27, fontWeight: 700 }}>
        {t("view.ChangeOrder.history")}
      </Typography>
      <Box>
        {props.historyItems.map((item) => (
          <HistoryListItem key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  ) : (<></>)
}
