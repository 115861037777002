import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"

//@ts-ignore
import Button from "../components/Button"
//@ts-ignore
import TextField from "../components/Fields/Text"
import {InputAdornment} from '@mui/material'


export interface EstimateLineItemForForm {
  description: string,
  cost: string | number,
}

function defaultCreateEstimateLineItemFormValues(): EstimateLineItemForForm {
  return {description: '', cost: 0}
}

interface CreateEstimateLineItemsFormProps {
  addNewLineItem: (s: EstimateLineItemForForm) => void,
  closeForm: () => void,
  showCosts: boolean
}

export default function CreateEstimateLineItemsForm(
  {addNewLineItem, closeForm, showCosts}: CreateEstimateLineItemsFormProps
): JSX.Element {
  const { t } = useTranslation("private")

  return (
    <Formik<EstimateLineItemForForm>
      initialValues={defaultCreateEstimateLineItemFormValues()}
      validationSchema={
        Yup.object().shape({
          description: Yup.string().min(2).required(t("form.CreateEstimateLineItemForm.descriptionRequired")),
        })
      }
      onSubmit={(values, { resetForm }) => {
        addNewLineItem(values)
        resetForm()
      }}
    >
      {({
          errors,
          handleBlur,
          touched,
          values,
          setFieldValue,
          resetForm,
          submitForm,
          handleChange,
          isValid
        }) => {
        return (
          <Form>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={12}>
                <TextField
                  error={touched.description && errors.description}
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  label={t("form.CreateEstimateLineItemForm.descriptionLabel")}
                  name="description"
                />
              </Grid>
              { showCosts
                ? <Grid item xs={12} md={12}>
                  <TextField
                    label={t("form.CreateEstimateLineItemForm.costLabel")}
                    name="cost"
                    onBlur={handleBlur}
                    onChange={(event: any) => {
                      setFieldValue("cost", event.target.value)
                    }}
                    type="number"
                    value={values.cost}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                : <></>
              }

              <Grid container item xs={6} alignContent="center" justifyContent="flex-start">
                <Button
                  color="secondary"
                  onClick={() => {
                    resetForm()
                    closeForm()
                  }}
                  size="small"
                  variant="text"
                >
                  {t("view.ChangeOrder.cancel")}
                </Button>
              </Grid>

              <Grid container item xs={6} alignContent="center" justifyContent="flex-end">
                <Button
                  disabled={!isValid}
                  startIcon={<AddIcon />}
                  onClick={() => {submitForm()}}
                >
                  {t("form.CreateEstimateLineItemForm.submitButton")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
