import {File} from '../types/file'
import {BulletinSubcontractorForTable} from '../components/BulletinSubsTable'

export interface BulletinSubcontractorForSubmit {
  subcontractor_id: string,
  sub_user_id: string,
}

export interface CreateBulletinInitialValues {
  pco_number: string,
  notes: string,
  description: string,
  title: string,
  date_estimates_due: string,
  files: File[],
  attached_subs: BulletinSubcontractorForTable[],
}

export function defaultCreateBulletinInitialValues(): CreateBulletinInitialValues {
  return {
    date_estimates_due: (new Date()).toDateString(),
    title: '',
    description: '',
    notes: "",
    pco_number: "",
    files: [{name: '', url: '', file_size_bytes: 0},],
    attached_subs: [],
  }
}

export interface CreateBulletinOutputForApi {
  pco_number: string | null,
  notes?: string,
  description: string,
  title: string,
  date_estimates_due: string,
  attached_subs: BulletinSubcontractorForSubmit[],
}
