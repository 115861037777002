import React from "react"

// @ts-ignore
import FormatRowId from "../../../../../../components/FormatRowId"
// @ts-ignore
import { formatDate, formatMoney } from "../../../../../../libs/format"
import TableColumnLink from "../../../../../../forms/components/TableColumnLink"
import { goToSingleTicket } from "../../../../../../util/routes"

interface ChangeOrderTicketListColumnsProps {
  subjectFormatting: any,
  projectId: string,
  isLumpSum: boolean,
}

export default function ChangeOrderTicketListColumns(props: ChangeOrderTicketListColumnsProps): any[] {
  const {subjectFormatting, projectId, isLumpSum} = props

  return [
    {
      align: "left",
      headerAlign: "left",
      field: "index",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: (params: any) => <FormatRowId>{params.value}</FormatRowId>,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "number",
      headerName: "#",
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "left",
      headerAlign: "left",
      field: "subject",
      headerName: "Subject",
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <TableColumnLink
            text={params.value}
            url={goToSingleTicket(projectId, params.id)}
            openNewWindow={true}
            className={subjectFormatting}
          />
        )
      },
    },
    {
      align: "center",
      headerAlign: "center",
      field: "date_start",
      headerName: "Work Date",
      renderCell: (params: any) => formatDate(params.date_start, params.date_end),
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
    },
    {
      align: "center",
      headerAlign: "center",
      field: "date_modified",
      headerName: "Submitted",
      renderCell: (params: any) => formatDate(params.value),
      flex: 1,
      minWidth: 50,
      editable: false,
      sortable: false,
    },
    {
      align: "right",
      headerAlign: "right",
      field: "total_amount",
      headerName: "Total",
      hide: isLumpSum,
      renderCell: (params: any) => formatMoney(params.value),
      width: 150,
      editable: false,
      sortable: false,
    },
  ]
}
