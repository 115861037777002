import { useState } from "react"
import Grid from "@mui/material/Grid"
import SendIcon from "@mui/icons-material/Send"
import {useTranslation} from "react-i18next"
import { useSnackbar } from "notistack"

import {CompanyType} from "../../../../../../types/company"
// @ts-ignore
import Button from "../../../../../../components/Button"
// @ts-ignore
import Dialog from "../../../../../../components/Dialog"
import { BulletinActionCode  } from "../../../../../../types/action"
import ConfirmOrCancelPrompt from "../../../../../../components/ConfirmOrCancelPrompt"
import { sendRfps } from "../../../../../../api/bulletin"
import { ProjectUserRoleCode } from "../../../../../../types/userRoles"


function showThisButton(
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  bulletinActionCode: BulletinActionCode
): boolean {
  // must be gc
  if (companyType !== 'cm') return false
  // must be a pm or super
  if (!["pm", "super"].includes(projectUserRole)) return false
  // these statuses can always send
  return ["draft"].includes(bulletinActionCode)
}

interface SendRfpToSubsProps {
  companyType: CompanyType,
  projectUserRole: ProjectUserRoleCode,
  bulletinActionCode: BulletinActionCode,
  bulletinId: string,
  projectId: string,
  bulletinActionChange: (new_action_id: number) => void,
  togglePocketForm: () => void
}

export function SendRfpToSubs(
  {companyType, projectUserRole, bulletinActionCode, bulletinId, projectId, bulletinActionChange, togglePocketForm}: SendRfpToSubsProps
): JSX.Element {
  const { t } = useTranslation("private")
  const [confirmOrCancelPromptOpen, setConfirmOrCancelPromptOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()


  const openConfirmCancelDialog = async (): Promise<void> => {
    togglePocketForm()
    setConfirmOrCancelPromptOpen(true)
  }

  const closeConfirmCancelDialog = () => {
    setConfirmOrCancelPromptOpen(false)
  }

  const submitForm = async (): Promise<void> => {
    closeConfirmCancelDialog()
    try {
      // Sends an email to all sub contacts
      // Creates an estimate
      const {data} = await sendRfps(projectId, bulletinId)
      if(data.success){
        // Changes bulletin status
        bulletinActionChange(data.new_action_id)
        enqueueSnackbar(t('view.Bulletins.SingleBulletin.SendToRfps.sendRfpSuccess'), {variant: "success"})
      } else {
        enqueueSnackbar(t('view.Bulletins.SingleBulletin.SendToRfps.sendRfpFail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Bulletins.SingleBulletin.SendToRfps.sendRfpFail'), {variant: "error"})
    }
  }

  return showThisButton(companyType, projectUserRole, bulletinActionCode) ? (
    <>
      <Grid item>
        <Button
          endIcon={<SendIcon />}
          style={{ minWidth: 125 }}
          onClick={openConfirmCancelDialog}
        >
          {t("view.Bulletins.SingleBulletin.SendToRfps.button")}
        </Button>
      </Grid>

      <Dialog
        open={confirmOrCancelPromptOpen}
        onClose={closeConfirmCancelDialog}
        maxWidth={'sm'}
      >
        <ConfirmOrCancelPrompt
          submitForm={submitForm}
          closeDialog={closeConfirmCancelDialog}
          dialogPrompt={t("view.Bulletins.SingleBulletin.SendToRfps.dialogPrompt")}
        />
      </Dialog>
    </>
  ) : (
    <></>
  )
}
