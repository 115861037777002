import React from "react"
import { useTranslation } from "react-i18next"
import makeStyles from "@mui/styles/makeStyles"
import { Table, TableHead, TableCell, TableRow } from "@mui/material"
import Paper from "@mui/material/Paper"

import { formatDateToMonthYear, formatMoney } from "../../../../../libs/format"


const useStyles = makeStyles(() => ({
  monthlyTicketsReportTable: {
    overflow: 'hidden',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)' 
  },
  monthlyTicketsReportLabels: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  monthlyTicketsReportData: {
    fontSize: '15px'
  }
}))


export default function MonthlyTicketsReportTable(props: any) {
  const { monthlyTicketsReportData } = props
  const classes = useStyles()
  const { t } = useTranslation("private")

  return (
    <Paper className={classes.monthlyTicketsReportTable}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} height="75px" className={classes.monthlyTicketsReportLabels}>
              {t("view.Admin.monthlyTicketsReportMonth")}
            </TableCell>
            {monthlyTicketsReportData.map((ticket: any) => (
              <TableCell align="center" className={classes.monthlyTicketsReportLabels}>
                {formatDateToMonthYear(ticket.month)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={2} height="56px" className={classes.monthlyTicketsReportLabels}>
            {t("view.Admin.monthlyTicketsReportTicketsCreated")}
            </TableCell>
            {monthlyTicketsReportData.map((ticket: any) => (
              <TableCell align="center" className={classes.monthlyTicketsReportData}>
                {ticket.tickets_created}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={2} height="56px" className={classes.monthlyTicketsReportLabels}>
              {t("view.Admin.monthlyTicketsReportTicketsAmount")}
            </TableCell>
            {monthlyTicketsReportData.map((ticket: any) => (
              <TableCell align="center" className={classes.monthlyTicketsReportData}>
                {formatMoney(ticket.tickets_created_amount)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" colSpan={10} height="56px"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Paper>
  )
}
