import React from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { Prompt, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { Theme } from "@mui/material"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"

// @ts-ignore
import FormBreakdowns from "../../../../../forms/FormBreakdowns"
// @ts-ignore
import FormMarkup from "../../../../../forms/FormMarkup"
// @ts-ignore
import Container from "../../../../../components/Container"
// @ts-ignore
import FormWideButtons from "../../../../../components/FormWideButtons"
// @ts-ignore
import FormWideContainer from "../../../../../components/FormWideContainer"
// @ts-ignore
import Switch from "../../../../../components/Fields/Switch"
// @ts-ignore
import TextAreaField from "../../../../../components/Fields/TextArea"
// @ts-ignore
import TextField from "../../../../../components/Fields/Text"
// @ts-ignore
import UploadMultipleField from "../../../../../components/Fields/UploadMultiple"
import {
  CreateTicketInitialValues,
  defaultCreateTicketInitialValues
} from "../../../../../forms/CreateTicket.types"
import FilesRow from "../../../../../components/FilesRow"
import {calculateTotalFileSizeInBytes} from "../../../../../types/file"
import {useSnackbar} from "notistack"
import {convertToNumber} from "../../../../../util/number"
import {calculateTotalWithMarkupsInForm} from "../../../../../util/markup"
import {formatMoney} from "../../../../../libs/format"
import { goToTicketsList } from "../../../../../util/routes"
import {DateField} from '../../../../../forms/components/DateField'

const useStyles = makeStyles<Theme>((theme) => ({
  total: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 20,
    paddingTop: 10
  }
}))

const fileUploadLimitInMB = 50

function materialEquipmentMissingRates(materials: any[], equipment: any[]): boolean {
  for (let m of materials) {
    if (convertToNumber(m.rate) === null) return true
  }
  for (let e of equipment) {
    if (convertToNumber(e.rate) === null) return true
  }
  return false
}

interface CostEditTicketFormProps {
  initialValues?: CreateTicketInitialValues,
  submitValues: (output: CreateTicketInitialValues) => Promise<void>,
  existingFiles?: any[],
  project: any
}

export default function CostEditTicketForm(
  {initialValues, submitValues, existingFiles, project}: CostEditTicketFormProps
) {
  const classes = useStyles()
  const history = useHistory()
  const [submitted, setSubmitted] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    window.onbeforeunload = function (_) {
      if (history.location.pathname.includes("tickets/add")) {
        return ""
      } else {
        return null
      }
    }
  }, [history.location.pathname])


  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues ?? defaultCreateTicketInitialValues()}
      validationSchema={Yup.object().shape({
        description: Yup.string().required(t("form.message.descriptionRequired")),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        // if we need rates but are missing any, we tell them and prevent submit
        if (!values.isLumpSum && materialEquipmentMissingRates(values.materialBreakdown, values.equipmentBreakdown)) {
          enqueueSnackbar(
            t('form.message.ratesRequired'),
            {variant: 'warning'}
          )
          setSubmitting(false)
          return
        }
        setSubmitted(true)
        submitValues(values).then(() => {
          resetForm()
        }).finally(() => {
          setSubmitting(false)
        })
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          touched,
          values,
          setFieldValue,
        }) => {
        return (
          <Form>
            <Container removeTop>
              <FormWideContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.number}
                      label={t("form.label.ticketNumber")}
                      name="number"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.pco_number && errors.pco_number)}
                      helperText={touched.pco_number && errors.pco_number}
                      label={t("form.label.pco")}
                      name="pco_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pco_number}
                    />
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        // @ts-ignore
                        autoOk={true}
                        error={Boolean(touched.date_start && errors.date_start)}
                        helperText={touched.date_start && errors.date_start}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        label={t("form.label.startDate")}
                        mask="__/__/____"
                        maxDate={values.date_end ? new Date(values.date_end) : null}
                        name="date_start"
                        onChange={(date) => {
                          setFieldValue("date_start", date ? date.toString() : "")
                        }}
                        renderInput={(props) => <TextField {...props} />}
                        showTodayButton={true}
                        style={{ backgroundColor: "white", width: "100%" }}
                        value={values.date_start}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        // @ts-ignore
                        autoOk={true}
                        error={Boolean(touched.date_end && errors.date_end)}
                        helperText={touched.date_end && errors.date_end}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        label={t("form.label.endDate")}
                        mask="__/__/____"
                        minDate={values.date_start ? new Date(values.date_start) : null}
                        name="date_end"
                        onChange={(date) => {
                          setFieldValue("date_end", date ? date.toString() : "")
                        }}
                        renderInput={(props) => <TextField {...props} />}
                        showTodayButton={true}
                        style={{ backgroundColor: "white", width: "100%" }}
                        value={values.date_end}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12}>
                    <TextAreaField
                      autoFocus
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label={t("form.label.workDescription")}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextAreaField
                      label={t("form.label.notes")}
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.subject && errors.subject)}
                      helperText={touched.subject && errors.subject}
                      label={t("form.label.subject")}
                      name="subject"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.invoice_number && errors.invoice_number)}
                      helperText={touched.invoice_number && errors.invoice_number}
                      label={t("form.label.invoiceNumber")}
                      name="invoice_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.invoice_number}
                    />
                  </Grid>

                  <DateField
                    width={'half'}
                    label={t("form.label.dateInvoice")}
                    value={values.date_invoice}
                    onChange={(dateStr) => setFieldValue('date_invoice', dateStr)}
                  />

                  <Grid item xs={12}>
                    {/* For editing, we show existing files here */
                      existingFiles && existingFiles.length > 0
                        ? <Grid item xs={12} sx={{paddingBottom: 4}}>
                          <Typography color="primary">Current Files:</Typography>
                          <FilesRow files={existingFiles}/>
                        </Grid>
                        : <></>
                    }

                    <UploadMultipleField
                      errors={errors}
                      label={t("form.label.uploadAttachments")}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      fileLimit={fileUploadLimitInMB}
                      existingFilesSizeInBytes={
                        existingFiles != null
                          ? calculateTotalFileSizeInBytes(existingFiles)
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputLabel>{t("form.label.addCosts")}</InputLabel>
                    <Switch
                      checked={values.isAddCosts}
                      name="isAddCosts"
                      onBlur={handleBlur}
                      disabled={true}
                      leftText={t("form.label.yes")}
                      rightText={t("form.label.no")}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputLabel>{t("form.label.switchLumpSum")}</InputLabel>
                    <Switch
                      checked={values.isLumpSum}
                      name="isLumpSum"
                      onBlur={handleBlur}
                      onChange={() => {
                        const newValue = !values.isLumpSum
                        const newType = newValue
                          ? "sum_total"
                          : values.isAddCosts
                            ? "sum_rates"
                            : "tm"
                        setFieldValue("isLumpSum", newValue)
                        setFieldValue("type", newType)
                      }}
                      leftText={t("form.label.yes")}
                      rightText={t("form.label.no")}
                    />
                  </Grid>

                  {values.isLumpSum ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.manual_total && errors.manual_total)}
                        helperText={touched.manual_total && errors.manual_total}
                        label={t("form.label.total")}
                        name="manual_total"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.manual_total}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </FormWideContainer>
            </Container>

            <FormBreakdowns values={values} setFieldValue={setFieldValue} forCostEdit={true} />

            <FormMarkup values={values} setFieldValue={setFieldValue} total={total} setTotal={setTotal} />

            <Prompt
              when={!submitted}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <Container removeTop>
              <FormWideContainer>
                <Grid item xs={12} justifyContent="flex-end" style={{display: 'flex'}}>
                  <Typography variant="body2" className={classes.total}>
                    Total: {formatMoney(calculateTotalWithMarkupsInForm(total, values.formMarkup))}
                  </Typography>
                </Grid>
              </FormWideContainer>
            </Container>
            <FormWideButtons
              cancel={{
                action: () => {
                  history.push(goToTicketsList(project.id))
                },
                text: t("view.ChangeOrder.cancel"),
              }}
              submit={{
                disabled: !isValid,
                isSubmitting: isSubmitting,
                text: t("view.Tickets.update"),
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
