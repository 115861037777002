import React from "react"
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"
import { useDispatch } from "react-redux"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import SignatureAndTextFieldForm, {SignatureAndTextFieldFormValues} from "../../../../../../../forms/SignatureAndTextFieldForm"
import {hasFilesForUpload} from "../../../../../../../util/file"
import {
  notifyForEstimateApprove,
  submitEstimateForApprove,
  uploadEstimateReviewProcessFiles, 
  uploadEstimateReviewProcessSignature,
} from '../../../../../../../api/estimateReviewProcess'
import { updateBulletin } from "../../../../../../../store/features/bulletinSlice"
import { getBulletinById } from "../../../../../../../api/bulletin"


interface ApproveFormProps {
  toggleFormOpen: () => void,
  estimateId: string,
  projectId: string,
  estimateActionChange: (new_action_id: number) => void,
  bulletinId: string,
}

export default function ApproveForm(
  {toggleFormOpen, estimateActionChange, estimateId, projectId, bulletinId}: ApproveFormProps
) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const submitForm = async (values: SignatureAndTextFieldFormValues): Promise<void> => {
    try {
      // update the change order status
      const erpRes = await submitEstimateForApprove(
        projectId,
        estimateId,
        values.message.length === 0 ? {} : {message: values.message},
      )
      if (erpRes.status === 200 && erpRes.data?.id != null) {
        const erp = erpRes.data
        if(erp.bulletin_is_estimate_approved){
          const bulletinRes = await getBulletinById(projectId, bulletinId)
          if(bulletinRes.status === 200 && bulletinRes.data.id != null){
            dispatch(updateBulletin(bulletinRes.data))
          }
        }
        // upload files
        if (hasFilesForUpload(values.files)) {
          const uploadingKey = enqueueSnackbar(t('form.label.uploadingFiles'), {variant: 'info'})
          const fileUploadMessage = await uploadEstimateReviewProcessFiles(values.files, projectId, erp.id)
          closeSnackbar(uploadingKey)
          enqueueSnackbar(
            fileUploadMessage.message,
            {
              variant: fileUploadMessage.error ? 'error' : 'success',
              style: {whiteSpace: 'pre-wrap'}
            }
          )
        }
        // upload signature
        const signatureUploadMessage = await uploadEstimateReviewProcessSignature(values.signature, projectId, erp.id)
        if (signatureUploadMessage.error) {
          enqueueSnackbar(signatureUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
        }
        // notify of the approval and create history object
        await notifyForEstimateApprove(projectId, erp.id)
        toggleFormOpen()
        if (erp.new_estimate_action_id) {
          estimateActionChange(erp.new_estimate_action_id)
        }
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Approve.apiSuccess'), {variant: "success"})
      } else {
        enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Approve.apiFail'), {variant: "error"})
      }
    } catch (_) {
      enqueueSnackbar(t('view.Estimates.SingleEstimate.Review.Approve.apiFail'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <SignatureAndTextFieldForm
        submitValues={submitForm}
        formCancel={toggleFormOpen}
        titleText={t('view.Estimates.SingleEstimate.Review.Approve.formTitle')}
      />
    </FormSmallContainer>
  )
}
