import {Ticket} from "../../../../../types/ticket"
import {ChangeOrder} from "../../../../../types/changeOrder"
import {convertToNumber} from "../../../../../util/number"
import {ActionInfo, ChangeOrderActionCode} from "../../../../../types/action"
import { sortObjsContainingDateField } from "../../../../../util/time"
import { addCurrentMonthIfMissing, fillInMissingMonths } from "../chartDataUtils"
import { SupportedDateLanguages } from "../../../../../types/language"


export interface ChangeOrderMetricsCards {
  costReviewCount: number,
  open: {
    count: number,
    cost: number,
  },
  submitted: {
    count: number,
    cost: number,
  },
  costApproved: {
    count: number,
    cost: number,
  }
}

export interface MonthlyChartData {
  date: Date
  xAxisLabel: string,
}

export interface MonthlyChangeOrderChartData extends MonthlyChartData {
  coSubmittedCount: number,
  coSubmittedCost: number,
  coApprovedCount: number,
  coApprovedCost: number,
}

interface ChangeOrderMetricsReducer extends ChangeOrderMetricsCards {
  monthlyCoSubmittedApproved: {[key: string]: MonthlyChangeOrderChartData},
}

export interface ChangeOrderMetrics extends ChangeOrderMetricsCards {
  monthlyCoSubmittedApproved: MonthlyChangeOrderChartData[],
}

const createMonthlyCoChartData = (
  currentDate: Date, 
  costData: {submitCost?: number, approveCost?: number} = {},
  languageDate: SupportedDateLanguages
): MonthlyChangeOrderChartData => {
  return {
    date: new Date(currentDate.getFullYear(), currentDate.getMonth()),
    xAxisLabel: new Date(currentDate.getFullYear(), currentDate.getMonth())
      .toLocaleString(languageDate, { month: 'short', year: "numeric"}),
    coSubmittedCount: costData.submitCost ? 1 : 0,
    coSubmittedCost: costData.submitCost ?? 0,
    coApprovedCount: costData.approveCost ? 1 : 0,
    coApprovedCost: costData.approveCost ?? 0,
  }
}

function initializeChangeOrderMetrics(): ChangeOrderMetricsReducer {
  return {
    costReviewCount: 0,
    open: {
      count: 0,
      cost: 0,
    },
    submitted: {
      count: 0,
      cost: 0
    },
    costApproved: {
      count: 0,
      cost: 0,
    },
    monthlyCoSubmittedApproved: {},
  }
}

export function calculateCOTicketsAttachedMetric(tickets: Ticket[]): number {
  const changeOrderTicketsAttachedCount: number = 0
  return tickets.reduce<number> (
    (metric, t) => {
      if (t.change_order_number) {
        return metric + 1
      }
      return metric
    }, changeOrderTicketsAttachedCount
  )
}

export function calculateChangeOrderMetrics(
  changeOrders: ChangeOrder[],
  actionMapper: {[key: number]: ActionInfo<ChangeOrderActionCode>},
  languageDate: SupportedDateLanguages
): ChangeOrderMetrics {
  const changeOrderMetrics = changeOrders.reduce<ChangeOrderMetricsReducer>(
    (metrics, co) => {
      const coCost = convertToNumber(co.total_amount ?? 0) ?? 0
      if (co.reason_co_closed === 'approved') {
        metrics.costApproved.count += 1
        metrics.costApproved.cost += coCost

        if(co.date_closed){
          const dateApproved = new Date(co.date_closed)
          const monthApproved = `${dateApproved.getFullYear()}-${dateApproved.getMonth() + 1}`
          // if we have it, then we increment metrics
          if (!!metrics.monthlyCoSubmittedApproved[monthApproved]) {
            metrics.monthlyCoSubmittedApproved[monthApproved].coApprovedCount += 1
            metrics.monthlyCoSubmittedApproved[monthApproved].coApprovedCost += coCost ?? 0
          } else {
            // initialize if we dont have it already
            metrics.monthlyCoSubmittedApproved[monthApproved] = createMonthlyCoChartData(
              dateApproved, {approveCost: coCost}, languageDate
            )
          }
        }
      }
      if (!!co.first_submit_date) {
        metrics.submitted.count += 1
        metrics.submitted.cost += coCost

        const dateSubmitted = new Date(co.first_submit_date)
        const monthSubmitted = `${dateSubmitted.getFullYear()}-${dateSubmitted.getMonth() + 1}`

        // if we have it, then we increment metrics
        if (!!metrics.monthlyCoSubmittedApproved[monthSubmitted]) {
          metrics.monthlyCoSubmittedApproved[monthSubmitted].coSubmittedCount += 1
          metrics.monthlyCoSubmittedApproved[monthSubmitted].coSubmittedCost += coCost
        } else {
          // initialize if we dont have it already
          metrics.monthlyCoSubmittedApproved[monthSubmitted] = createMonthlyCoChartData(
            dateSubmitted, {submitCost: coCost}, languageDate
          )
        }
      }
      if (!co.date_closed) {
        metrics.open.count += 1
        metrics.open.cost += coCost
      }
      if (actionMapper[co.change_order_action_id].code === 'submitted' && !co.date_closed) {
        metrics.costReviewCount += 1
      }
      return metrics
    }, initializeChangeOrderMetrics()
  )

  // Sort monthly metrics by date ascending
  const monthlyCoSubmittedApproved = Object.values(changeOrderMetrics.monthlyCoSubmittedApproved)
  monthlyCoSubmittedApproved.sort(sortObjsContainingDateField)
  // Check if current month is represented at the end of the list, if not, we should add it.
  addCurrentMonthIfMissing<MonthlyChangeOrderChartData>(monthlyCoSubmittedApproved, createMonthlyCoChartData, languageDate)

  return {
    costReviewCount: changeOrderMetrics.costReviewCount,
    open: changeOrderMetrics.open,
    submitted: changeOrderMetrics.submitted,
    costApproved: changeOrderMetrics.costApproved,
    monthlyCoSubmittedApproved: fillInMissingMonths<MonthlyChangeOrderChartData>(
      monthlyCoSubmittedApproved, createMonthlyCoChartData, languageDate
    ),
  }
}

