import React from "react"
import { GridCellCheckboxRenderer, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { Tooltip } from "@mui/material"

// @ts-ignore
import Action from "../components/Action"
import { ActionCode, ActionInfo } from "../types/action"
import TableColumnLink from "./components/TableColumnLink"
import { goToSingleTicket } from "../util/routes"

export function SelectTicketsColumns (
  projectId: string,
  actionIdToInfo: {[key: number]: ActionInfo},
  t: any,
  formatMoney: any,
  classes: any,
) {
  return [
    // Including this column to override the checkbox:
    // (tooltip indicating "costs need to be added" for the item to be selectable)
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (params: any) => {
          if(params.row.includes_costs){
            return <GridCellCheckboxRenderer {...params} />
          } else {
            return (
              <Tooltip placement={'bottom'} title={'Costs not added yet'}>
                <div>
                  <GridCellCheckboxRenderer {...params} />
                </div>
              </Tooltip>
            )
          }
        }
      },
      {
        editable: false,
        field: "number",
        headerName: "#",
        minWidth: 70,
      },
      {
        editable: false,
        field: "pco_number",
        headerName: "CLIENT/PCO #",
        width: 140,
      },
      {
        field: "subject",
        headerName: t("view.Tickets.subject"),
        width: 350,
        editable: false,
        renderCell: (params: any) => {
          return (
            <TableColumnLink
              text={params.row.subject}
              url={goToSingleTicket(projectId, params.row.id)}
              openNewWindow={true}
              className={classes.subject}
            />
          )
        },
      },
      {
        field: "action_id",
        headerName: t("view.Tickets.status"),
        renderCell: (params: any) => {
          return <Action code={actionIdToInfo[params.row.action_id].code as ActionCode} />
        },
        valueGetter: (params: any) => actionIdToInfo[params.value].name,
        editable: false,
        align: "left",
        headerAlign: "center",
        width: 200,
      },
      {
        align: "right" ,
        editable: false,
        field: "total_amount",
        headerAlign: "right",
        headerName: t("view.Tickets.total"),
        width: 130,
        renderCell: (params: any) => params.row.includes_costs ? formatMoney(params.row.total_amount) : "N/A"
      },
    ]
}
