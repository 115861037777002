import React from "react"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"

import InvoiceTopAttachedFiles from "./InvoiceTopAttachedFiles"
import { File } from "../../types/file"
import { Bulletin } from "../../types/bulletin"
import DetailRow from "../DetailRow"
import DetailsTable from "../DetailsTable"
import { useTracFloSelector } from "../../store/useTracFloSelector"
import { formatDate } from "../../libs/format"
import InvoiceTopNumber from "./InvoiceTopNumber"
import InvoiceTopLogo from "./InvoiceTopLogo"
import InvoiceTopCompanyInfo from "./InvoiceTopCompanyInfo"


interface BulletinDetailsTopSectionProps {
  files: File[],
  bulletin: Bulletin
}

export default function BulletinDetailsTopSection(
  {files, bulletin}: BulletinDetailsTopSectionProps
): JSX.Element {
  const project = useTracFloSelector((state) => state.project)
  const company = useTracFloSelector((state) => state.company)
  const { t } = useTranslation("private")

  const bulletinProjectDetails = [
    {
      title: t('component.BulletinTopSectionDetails.pcoNumber'),
      content: bulletin.pco_number
    },
    {
      title: t('component.BulletinTopSectionDetails.title'),
      content: bulletin.title
    },
    {
      title: t('component.BulletinTopSectionDetails.projectNumber'),
      content: project.number
    },
    {
      title: t('component.BulletinTopSectionDetails.projectName'),
      content: project.name
    },
    {
      title: t('component.BulletinTopSectionDetails.rfpDueDate'),
      content: bulletin.date_estimates_due ? formatDate(bulletin.date_estimates_due) : null
    },
    {
      title: t('component.BulletinTopSectionDetails.dateCreated'),
      content: bulletin.date_created ? formatDate(bulletin.date_created) : null
    },
    {
      title: t('component.BulletinTopSectionDetails.submitted'),
      content: `${bulletin.inbox_first_name} ${bulletin.inbox_last_name}`
    }
  ]

  return (
    <Grid container spacing={3}>

      <InvoiceTopNumber number={bulletin.pco_number}/>
      
      <InvoiceTopLogo senderLogoUrl={company.logo_url} senderName={company.name}/>

      {/* Bulletin and Project Details */}
      <Grid container item xs={8}>
        <DetailsTable details={bulletinProjectDetails} />
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow
            title={t('component.BulletinTopSectionDetails.description')}
            content={bulletin.description}
          />
        </Grid>
      </Grid>

      <Grid container item xs={4}>
        {/* GC Company (Sender) Details */}
        <InvoiceTopCompanyInfo
          sender={{
            id: company.id,
            address: company.address,
            name: company.name,
            phone: company.phone
          }}
          receiver={null}
        />

        {/* Bulletin Notes */}
        <Grid container item style={{ marginBottom: 16 }}>
          <DetailRow
            title={t('component.BulletinTopSectionDetails.notes')}
            content={bulletin.notes}
          />
        </Grid>
      </Grid>

      <InvoiceTopAttachedFiles files={files}/>
    </Grid>
  )
}
