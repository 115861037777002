import React, {useState, useEffect} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { getDailyLaborBreakdown } from "../../../../../../api/daily"
import LaborBreakdownTable from "../../../Tickets/SingleTicket/Breakdowns/BreakdownTables/LaborBreakdownTable"
import { TicketMarkup } from "../../../../../../types/ticket"
import { LaborTotals, calculateLaborTotals } from "../../../Tickets/SingleTicket/Breakdowns/LaborBreakdown"
import InvoiceMarkup from "../../../../../../components/Invoice/Markup"
import { calculateTotalWithMarkups } from "../../../../../../util/markup"
import {DailyLaborBreakdown} from '../../../../../../types/daily'
import DailyLaborBreakdownColumns from './DailyLaborBreakdowns.columns'


interface DailyLaborBreakdownTableProps {
  dailyId: string,
  projectId: string,
  addRates: boolean,
  markupRows: TicketMarkup[],
  updateLaborSubtotal: (ls: number) => void
}

export default function DailyLaborBreakdownTable(
  {dailyId, projectId, addRates, markupRows, updateLaborSubtotal}: DailyLaborBreakdownTableProps
) {
  const { t } = useTranslation("private")
  const [totals, setTotals] = useState<LaborTotals>({crew_size: 0, hours: 0})
  const [rows, setRows] = useState<DailyLaborBreakdown[]>([])

  // Get labor data
  useEffect(() => {
    getDailyLaborBreakdown(projectId, dailyId).then(
      (res) => {
        if (res.status === 200 && Array.isArray(res.data)) {
          setRows(res.data)
        }
      }
    )
  }, [dailyId, projectId])

  // Calculate table totals
  useEffect(() => {
    const laborTotals = calculateLaborTotals(rows, addRates)
    if (addRates && laborTotals.total != null) {
      updateLaborSubtotal(calculateTotalWithMarkups(laborTotals.total, markupRows))
    }
    setTotals(laborTotals)
  }, [addRates, rows, markupRows])

  return rows.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">{t("view.Daily.SingleDaily.Breakdowns.Labor.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <LaborBreakdownTable
          columns={DailyLaborBreakdownColumns({addRates, t})}
          rows={rows}
          totals={totals}
          subtotalText={t("view.Daily.SingleDaily.Breakdowns.Labor.laborSubtotal")}
        />
      </Grid>

      {addRates
        ? <InvoiceMarkup
          rows={markupRows}
          total={totals.total ?? 0}
          subTotalText={t("view.Daily.SingleDaily.Breakdowns.Labor.markup")}
        />
        : <></>
      }
    </>
  ) : (
    <></>
  )
}
