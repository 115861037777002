import {File} from '../types/file'
import {EstimateLineItemForTable} from '../components/EstimateLineItemsTable'
import {EstimateMarkup} from '../api/estimate'


export type CreateEstimateLineItem = EstimateLineItemForTable

export type CreateEstimateMarkup = EstimateMarkup

export interface CreateEstimateInitialValues {
  number: string,
  subject: string,
  description: string,
  notes: string,
  date_start: string | null,
  date_end: string | null,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  files: File[],
  line_items: CreateEstimateLineItem[],
  markup: CreateEstimateMarkup[],
}

export function defaultCreateEstimateInitialValues(): CreateEstimateInitialValues {
  return {
    number: '',
    subject: '',
    description: '',
    notes: '',
    date_start: null,
    date_end: null,
    is_lump_sum: false,
    files: [{name: '', url: '', file_size_bytes: 0},],
    line_items: [],
    markup: [],
  }
}

export interface CreateEstimateOutputForApi {
  number: string | null,
  subject: string,
  description: string,
  notes?: string,
  date_start?: string,
  date_end?: string,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  line_items: CreateEstimateLineItem[],
  markup: CreateEstimateMarkup[],
}
