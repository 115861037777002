import React, {useMemo} from "react"
import {TFunction, useTranslation} from 'react-i18next'

import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import {
  ActionInfo,
  EstimateActionCode, getEstimateActionLabel,
} from '../../../../../types/action'
import {CompanyType} from '../../../../../types/company'
import {convertToNumber} from '../../../../../util/number'
import {getEstimateStatusFill} from '../../../../../style/action'
import BudgetLogStatusChart from '../../../../../components/BudgetLogStatusChart'
import {BudgetStatusChartData} from '../../../../../components/BudgetLogStatusChart/type'
import {Estimate} from '../../../../../types/estimate'


function createEstimateStatusChartMapping(
  t: TFunction<'private'>,
  actionMap: {[p: number | string]: ActionInfo<EstimateActionCode>},
): {[status: string]: BudgetStatusChartData} {
  // for each action, prep a mapping that helps us create chart data quickly
  return Object.keys(actionMap).reduce<{[status: string]: BudgetStatusChartData}>(
    // NOTE: actionId is a number
    (statusMap, action_id) => {
      const statusLabel = getEstimateActionLabel(t, actionMap[action_id].code)
      const statusFill = getEstimateStatusFill(actionMap[action_id].code)
      statusMap[statusLabel] = {
        status: statusLabel,
        count: 0,
        budget_total: 0,
        actual_total: 0,
        label: statusLabel,
        fill: statusFill,
        id: action_id
      }
      return statusMap
    },
    {}
  )
}

function addEstimateToStatusChartMapping(
  // updated in place
  statusMap: {[status: string]: BudgetStatusChartData},
  estimate: Estimate,
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<EstimateActionCode>},
  companyType: CompanyType,
): void {
  const statusLabel = getEstimateActionLabel(t, actionMap[estimate.estimate_action_id].code,)
  statusMap[statusLabel].count += 1
  statusMap[statusLabel].budget_total += convertToNumber(estimate.total_amount) ?? 0
  // actual depends on trade vs. GC
  statusMap[statusLabel].actual_total += convertToNumber(
    companyType === 'trade'
      ? estimate.total_sub_actual_amount
      : estimate.total_actual_amount
  ) ?? 0
}

function convertEstimatesToEstimateStatusChart(
  estimates: Estimate[],
  t: TFunction<'private'>,
  actionMap: {[p: number]: ActionInfo<EstimateActionCode>},
  companyType: CompanyType
): BudgetStatusChartData[] {
  const statusChartMap = createEstimateStatusChartMapping(t, actionMap,)
  for (let estimate of estimates) {
    addEstimateToStatusChartMapping(statusChartMap, estimate, t, actionMap, companyType)
  }
  // just trying to get similar statuses near one another in the chart
  return Object.values(statusChartMap).sort((cd1, cd2) => cd1.id > cd2.id ? 1 : 0)
}

export default function EstimateListStatusChart(): JSX.Element {
  const company = useTracFloSelector((state) => state.company)
  const estimates = useTracFloSelector((state) => state.estimates.items)
  const actionMap = useTracFloSelector((state) => state.estimateAction.idObjectMapping)
  const { t } = useTranslation("private")

  const statusData: BudgetStatusChartData[] = useMemo(() => {
    return convertEstimatesToEstimateStatusChart(estimates, t, actionMap, company.company_type)
  }, [estimates, t, actionMap, company.company_type])

  return (
    <BudgetLogStatusChart statusData={statusData}/>
  )
}
