import {TicketType} from "../types/ticket"

export interface CreateTicketInitialValues {
  date_end: string | null,
  date_start: string,
  description: string,
  equipmentBreakdown: any[],
  equipmentMarkup: any[],
  files: any[],
  formMarkup: any[],
  invoice_number: string,
  isAddCosts: boolean,
  isLumpSum: boolean,
  is_signed: string,
  laborBreakdown: any[],
  laborMarkup: any[],
  lumpSumTotal: string | number,
  manual_total: string | number,
  materialBreakdown: any[],
  materialMarkup: any[],
  notes: string,
  number: string,
  pco_number: string,
  subject: string,
  type: TicketType,
  date_invoice: string | null,
}

export function defaultCreateTicketInitialValues(): CreateTicketInitialValues {
  return {
    date_end: null,
    date_start: (new Date()).toDateString(),
    description: "",
    equipmentBreakdown: [],
    equipmentMarkup: [],
    files: [{name: "",},],
    formMarkup: [],
    invoice_number: "",
    isAddCosts: false,
    isLumpSum: false,
    is_signed: "",
    laborBreakdown: [],
    laborMarkup: [],
    lumpSumTotal: "",
    manual_total: "",
    materialBreakdown: [],
    materialMarkup: [],
    notes: "",
    number: "",
    pco_number: "",
    subject: "",
    type: "tm",
    date_invoice: null,
  }
}

export interface CreateTicketOutputForApi {
  date_end: string | null,
  date_start: string,
  description: string,
  invoice_number: string,
  is_signed: string,
  manual_total: string | number,
  notes: string,
  number: string | null,
  pco_number: string,
  subject: string,
  type: TicketType,
  markup: any[],
  includes_costs: boolean,
  is_lump_sum: boolean,
  lump_sum_total?: number,
  equipment: any[],
  labor: any[],
  material: any[],
  date_invoice: string | null,
}
