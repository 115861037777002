import {convertToNumber} from "../util/number"

export interface File {
  name: string,
  url: string,
  file_size_bytes: number | string,
}

export function calculateTotalFileSizeInBytes(files: File[]): number {
  return files.reduce(
    (total, file) => {
      return total + (convertToNumber(file.file_size_bytes) ?? 0)
    }, 0
  )
}
