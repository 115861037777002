import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {useSnackbar} from "notistack"

// @ts-ignore
import Container from "../../../../components/Container"
// @ts-ignore
import DataTable from "../../../../components/DataTable"
import {AdminCompaniesColumns} from "./AdminCompanies.columns"
import {getCompaniesForAdmin, changeCompanyDemoStatus} from "../../../../api/admin"


export default function AdminCompanies() {
  const [adminCompanies, setAdminCompanies] = useState<any[]>([])
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()

  const toggleCompanyDemoStatus = async (companyId: string, status: boolean): Promise<void> => {
    if (!!companyId) {
      const res = await changeCompanyDemoStatus(companyId, status)
      if (res?.data?.id) {
        const index = adminCompanies.findIndex((company) => company.id === companyId)
        if (index > -1) {
          Object.assign(adminCompanies[index], res?.data)
          setAdminCompanies([...adminCompanies])
        }
      } else {
        enqueueSnackbar(t("view.Admin.issueTogglingDemoStatus"), {
          variant: "error",
        })
      }
    } else {
      enqueueSnackbar(t("view.Admin.issueTogglingDemoStatus"), {
        variant: "error",
      })
    }
  }

  useEffect(() => {
    getCompaniesForAdmin().then(
      (res) => {
        if (res?.data?.length) {
          setAdminCompanies(res.data)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = AdminCompaniesColumns(t, toggleCompanyDemoStatus)

  return (
    <>
      <Container fullWidth>
        <Grid container spacing={2}>
          <Grid container item alignItems="center" justifyContent="flex-start" md={6}>
            <Typography variant="h1">{t("view.Admin.companiesTitle")}</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container fullWidth removeTop>
        <DataTable
          columns={columns}
          rows={adminCompanies}
          showCheckboxes={false}
        />
      </Container>
    </>
  )
}
