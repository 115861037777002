import React from "react"
import DownloadIcon from '@mui/icons-material/Download'
import {useTranslation} from "react-i18next"
import {useSnackbar} from "notistack"

import Fab from "../../../../../../components/Fab"
import {openDailyPdfInNewWindow} from "../../../../../../util/file"

interface DailyDownloadButtonProps {
  projectId: string,
  dailyId: string,
}

export default function DailyDownloadButton({projectId, dailyId}: DailyDownloadButtonProps) {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return (
    <Fab
      // @ts-ignore
      edge="end"
      size="medium"
      style={{color: "white", backgroundColor: "#143366"}}
      variant="extended"
      onClick={async ()=> {await openDailyPdfInNewWindow(projectId, dailyId, enqueueSnackbar, closeSnackbar, t)}}
    >
      <DownloadIcon />
      {t("Frequent.download")}
    </Fab>
  )
}
