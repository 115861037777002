import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Typography from '@mui/material/Typography'
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'

// @ts-ignore
import Container from "../../../../../components/Container"
import {useTracFloSelector} from '../../../../../store/useTracFloSelector'
import CreateEstimate from '../../../../../forms/CreateEstimate'
import {CreateEstimateInitialValues} from '../../../../../forms/CreateEstimate.types'
import {createEstimate, uploadEstimateFiles} from '../../../../../api/estimate'
import {convertFormValuesToApiData} from '../../../../../forms/CreateEstimate.submit'
import {addEstimate} from '../../../../../store/features/estimateSlice'
import {goToSingleEstimate} from '../../../../../util/routes'


export default function CreateEstimateView(): JSX.Element {
  const { t } = useTranslation("private")
  const { projectId } = useParams<{projectId: string}>()
  const project = useTracFloSelector((state) => state.project)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const dispatch = useDispatch()

  const submitNewBulletin = async (values: CreateEstimateInitialValues, nextEstimateNumber: string|null) => {
    try {
      const res = await createEstimate(projectId, convertFormValuesToApiData(values, nextEstimateNumber))
      // return if failure
      if (res.status !== 200 || !res.data.id) {
        enqueueSnackbar(t("view.Estimates.CreateEstimate.submitFail"), {
          variant: "error",
        })
        return
      }
      const estimate = res.data
      enqueueSnackbar(t("view.Estimates.CreateEstimate.submitSuccess"), {
        variant: "success",
      })
      dispatch(addEstimate(estimate))
      // need to check that files actually exist
      if (values.files.length > 0 && !!values.files[0].name) {
        const uploadingKey = enqueueSnackbar(
          t("Frequent.uploadingFiles"),
          {variant: 'info'}
        )
        const fileUploadMessage = await uploadEstimateFiles(values.files, project.id, estimate.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      history.push(goToSingleEstimate(project.id, estimate.id))
    } catch (_) {
      enqueueSnackbar(t("view.Estimates.CreateEstimate.submitFail"), {
        variant: "error",
      })
    }
  }

  return project && project.id === projectId ? (
    <>
      <Container>
        <Typography variant="h1">{t("view.Estimates.CreateEstimate.header")}</Typography>
      </Container>

      <CreateEstimate
        projectId={projectId}
        submitValues={submitNewBulletin}
      />
    </>
  ) : (
    <></>
  )
}
