import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Typography} from '@mui/material'
import {useDispatch} from 'react-redux'

// @ts-ignore
import Container from "../../../../../../components/Container"
import DataGridAccordion from '../../../../../../components/DataGridAccordion'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import AttachedCosListColumns from './AttachedCos.Columns'
import {ChangeOrder} from '../../../../../../types/changeOrder'
import {DataGridAccordionRowItem} from '../../../../../../components/DataGridAccordionRow'
import {
  AttachedCoAddsToTotalActionCode,
} from '../../../../../../types/estimateAttachedObjects'
import {ActionCode, ActionInfo, ChangeOrderActionCode} from '../../../../../../types/action'
import {convertToNumber} from '../../../../../../util/number'
import {formatMoney} from '../../../../../../libs/format'
import {reloadChangeOrdersIfInvalid} from '../../../../../../store/features/changeOrdersSlice'
import {reloadTicketsIfInvalid} from '../../../../../../store/features/ticketsSlice'
import {Ticket} from '../../../../../../types/ticket'
import AttachedTicketsListColumns, {AttachedTicket, ticketCountsTowardsTotal} from './AttachedTickets.Columns'
import { reloadDailyIfInvalid } from '../../../../../../store/features/dailySlice'
import { Daily } from '../../../../../../types/daily'
import AttachedDailyListColumns from './AttachedDaily.Columns'
import { getCompany } from '../../../../../../store/features/companySlice'


function createCoMiddleSubtitle(
  cos: ChangeOrder[],
  t: (s: string, o: any) => string,
  actionIdToInfo: {[key: number]: ActionInfo<ChangeOrderActionCode>}
): string {
  const count = cos.length
  const total = cos.reduce<number>(
    (total, co) => {
      if (
        co.total_amount != null
        && AttachedCoAddsToTotalActionCode.includes(actionIdToInfo[co.change_order_action_id].code)
      ) {
        return total + (convertToNumber(co.total_amount) ?? 0)
      }
      return total
    }, 0
  )
  return t(
    'view.Estimates.SingleEstimate.AttachedObjects.changeOrderAccordionMiddleSubtitle',
    {count, total: formatMoney(total)}
  )
}

// Need to add a field to reduce computational overhead
function createAttachedTicket(t: Ticket, attachedCos: ChangeOrder[]): AttachedTicket {
  // flag indicates that ticket is attached to a CO that is also attached to this estimate
  const co_is_attached_too = t.change_order_id == null
    ? false
    : attachedCos.find((co) => co.id === t.change_order_id) != null
  return {
    ...t,
    co_is_attached_too
  }
}

function createTicketMiddleSubtitle(
  tickets: AttachedTicket[],
  t: (s: string, o: any) => string,
  actionIdToInfo: {[key: number]: ActionInfo<ActionCode>}
): string {
  const count = tickets.length
  const total = tickets.reduce<number>(
    (total, t) => {
      if (
        t.total_amount != null
        && ticketCountsTowardsTotal(t, actionIdToInfo)
      ) {
        return total + (convertToNumber(t.total_amount) ?? 0)
      }
      return total
    }, 0
  )
  return t(
    'view.Estimates.SingleEstimate.AttachedObjects.changeOrderAccordionMiddleSubtitle',
    {count, total: formatMoney(total)}
  )
}

function createDailyMiddleSubtitle(
  drs: Daily[],
  t: (s: string, o: any) => string,
): string {
  const count = drs.length
  const total = drs.reduce<number>(
    (total, dr) => {
      if (dr.total_amount != null) {
        return total + (convertToNumber(dr.total_amount) ?? 0)
      }
      return total
    }, 0
  )
  return t(
    'view.Estimates.SingleEstimate.AttachedObjects.dailyAccordionMiddleSubtitle',
    {count, total: formatMoney(total)}
  )
}

export type EstimateAttachedObjectsAccordionProps = {
  projectId: string,
  estimateId: string,
}

export default function EstimateAttachedObjectsAccordion(
  {projectId, estimateId}: EstimateAttachedObjectsAccordionProps
): JSX.Element {
  const {t} = useTranslation('private')
  const dispatch = useDispatch()
  const company = useTracFloSelector(getCompany)
  // CO state
  const changeOrders = useTracFloSelector((state) => state.changeOrders.items)
  const changeOrdersProjectId = useTracFloSelector((state) => state.changeOrders.projectId)
  const coExpiry = useTracFloSelector((state) => state.changeOrders.exp)
  const attachedChangeOrders = useMemo(() => {
    return changeOrders.filter((co) => co.estimate_id === estimateId)
  }, [estimateId, changeOrders])
  const coActionIdObjMapping = useTracFloSelector(
    (state) => state?.changeOrderAction?.idObjectMapping
  )
  // Ticket state
  const tickets = useTracFloSelector((state) => state.tickets.items)
  const ticketsProjectId = useTracFloSelector((state) => state.tickets.projectId)
  const ticketExpiry = useTracFloSelector((state) => state.tickets.exp)
  const attachedTickets = useMemo(() => {
    return tickets.filter((t) => t.estimate_id === estimateId)
      .map((t) => createAttachedTicket(t, attachedChangeOrders))
  }, [estimateId, tickets, attachedChangeOrders])
  const ticketActionIdObjMapping = useTracFloSelector((state) => state.action.idObjectMapping)
  // Daily state
  const daily = useTracFloSelector((state) => state.daily.items)
  const dailyProjectId = useTracFloSelector((state) => state.daily.projectId)
  const dailyExpiry = useTracFloSelector((state) => state.daily.exp)
  const attachedDaily = useMemo(() => {
    return daily.filter((d) => d.estimate_id === estimateId)
  }, [estimateId, daily])

  useEffect(() => {
    reloadChangeOrdersIfInvalid(projectId, changeOrdersProjectId, coExpiry, dispatch)
  }, [projectId, changeOrdersProjectId, coExpiry, dispatch])

  useEffect(() => {
    reloadTicketsIfInvalid(projectId, ticketsProjectId, ticketExpiry, dispatch)
  }, [projectId, ticketsProjectId, ticketExpiry, dispatch])

  useEffect(() => {
    if(company.company_type === "trade"){
      reloadDailyIfInvalid(projectId, dailyProjectId, dailyExpiry, dispatch)
    }
  }, [projectId, dailyProjectId, dailyExpiry, dispatch, company.company_type])

  const dataGridAccordionItems: DataGridAccordionRowItem[] = []

  if (attachedChangeOrders.length > 0) {
    dataGridAccordionItems.push(
      {
        title: t('view.Estimates.SingleEstimate.AttachedObjects.changeOrderAccordionTitle'),
        middleSubtitle: createCoMiddleSubtitle(attachedChangeOrders, t, coActionIdObjMapping),
        rows: attachedChangeOrders,
        columns: AttachedCosListColumns(projectId, coActionIdObjMapping, t)
      }
    )
  }

  if (attachedTickets.length > 0) {
    dataGridAccordionItems.push(
      {
        title: t('view.Estimates.SingleEstimate.AttachedObjects.ticketAccordionTitle'),
        middleSubtitle: createTicketMiddleSubtitle(attachedTickets, t, ticketActionIdObjMapping),
        rows: attachedTickets,
        columns: AttachedTicketsListColumns(projectId, ticketActionIdObjMapping, t)
      }
    )
  }

  if (attachedDaily.length > 0) {
    dataGridAccordionItems.push(
      {
        title: t('view.Estimates.SingleEstimate.AttachedObjects.dailyAccordionTitle'),
        middleSubtitle: createDailyMiddleSubtitle(attachedDaily, t),
        rows: attachedDaily,
        columns: AttachedDailyListColumns(projectId, t)
      }
    )
  }

  const showAttachedObjectsAccordion = dataGridAccordionItems.length > 0

  return showAttachedObjectsAccordion
    ? (
      <Container>
        <Grid item xs={12}>
          <Typography variant='h2'>
            {t("view.Estimates.SingleEstimate.AttachedObjects.title")}
          </Typography>
        </Grid>
        <DataGridAccordion items={dataGridAccordionItems}/>
      </Container>
    )
    : (<></>)
}
