import React from "react"
import {Box} from "@mui/system"
import {Grid, Typography} from "@mui/material"

import { formatDateTimeShort } from "../../libs/format"
import {stringIsValidNonWhitespace} from '../../util/string'


interface InvoiceSignatureProps {
  url: string,
  signed_by: string,
  date_signed: Date,
  approved_text: string,
  disclaimerText?: string,
}

export function InvoiceSignature(
  {url, signed_by, date_signed, approved_text, disclaimerText}: InvoiceSignatureProps
): JSX.Element {
  return (
    <Grid item xs={12} md={4}>
      <Box
        component="img"
        alt="signature"
        src={url}
        sx={{
          borderBottom: "1px solid #666",
          maxWidth: "100%",
        }}
      />
      <Typography>{approved_text}</Typography>
      <Typography paragraph={true} variant="caption" sx={{ lineHeight: 1.2 }}>
        {`${signed_by} ${formatDateTimeShort(date_signed)}`}
      </Typography>
      {
        stringIsValidNonWhitespace(disclaimerText)
          ? <Typography style={{fontSize: 9, textAlign: "left", textTransform: 'uppercase'}}>
            *{disclaimerText}
          </Typography>
          : <></>
      }
    </Grid>
  )
}
