import { Grid } from "@mui/material"
import MuiCard from "@mui/material/Card"
import MuiCardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import { formatMoney } from "../libs/format"


export type yAxisType = 'money' | 'number'

interface MetricCardGraphProps {
  title: string,
  data: any[],
  lines: {
    name: string,
    dataKey: string,
    color: string,
  }[]
  yAxisType: yAxisType,
}

export default function MetricCardGraph(props: MetricCardGraphProps) {
  const { title, data, lines, yAxisType } = props
  return (
    <MuiCard elevation={3}>
      <MuiCardContent>
        <Typography variant="h2" textAlign="left" sx={{textTransform: "uppercase", color: "#000000", opacity: "87%"}}>
          {title}
        </Typography>
        <Grid item container justifyContent={"center"} paddingTop={2}>
          <ResponsiveContainer width='100%' height={300}>
            <LineChart data={data}>                     
              <CartesianGrid stroke="#F0F0F0" />
              <Tooltip 
                formatter={(value) => {
                  // Note: If y-axis is money, display values as money.
                  if(yAxisType === 'money' && typeof value === 'number'){
                    return formatMoney(value)
                  }
                  return value
                }}
              />
              <XAxis
                axisLine={{stroke:"#F0F0F0"}}
                dy={5}
                dataKey={'xAxisLabel'}
                tick={{fill: '#A3A3A3', fontSize: 14}}
                tickLine={false}
              />
              <YAxis
                axisLine={{stroke:"#F0F0F0"}}
                dx={-5}
                tick={{fill: '#A3A3A3', fontSize: 15,}}
                tickFormatter={(tick) => {
                  // Note: Money tick needs to be rounded for display purposes.
                  if(yAxisType === 'money'){
                    if(tick >= 1000 && tick < 1000000){
                      return (`$${tick/1000}k`)
                    }
                    else if (tick >= 1000000) {
                      return (`$${tick/1000000}M`)
                    }
                    
                    return `$${tick}`
                  }
                  return tick
                }}
                tickLine={false}
              />
              {
                lines.map((lineInfo, index) => {
                  return (
                    <Line 
                      key={index}
                      name={lineInfo.name}
                      type="linear"
                      dataKey={lineInfo.dataKey}
                      stroke={lineInfo.color}
                      strokeWidth={3}
                      dot={{stroke: lineInfo.color, strokeWidth: 2, r: 4, fill: lineInfo.color}}
                    />
                  )
                })
              }
              <Legend iconType="plainline" />  
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </MuiCardContent>
    </MuiCard>    
  )
}