import React, {useEffect} from "react"
import {Container} from "@mui/material"
import {useHistory, useParams} from "react-router-dom"
import Typography from "@mui/material/Typography"
import {useTranslation} from "react-i18next"
import {useDispatch} from "react-redux"
import {useSnackbar} from "notistack"

import {completeSubInvite} from "../../../../api/invite"
// @ts-ignore
import { addProject } from "../../../../store/features/userSlice"
import { goHome } from "../../../../util/routes"


export default function SubInvite() {
  const { subInviteId } = useParams<{subInviteId: string}>()
  const { t } = useTranslation("private")
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const handleSubInvite = async (inviteId: string): Promise<void> => {
    try {
      const res = await completeSubInvite(inviteId)
      if (res?.data?.id) {
        // success should return new project, so we need to add it then go home
        dispatch(addProject(res.data))
        history.push(goHome)
        return
      } else {
        enqueueSnackbar("There was a problem adding this company to the project", {variant: "error",})
      }
    } catch(_) {
      enqueueSnackbar("There was a problem adding this company to the project", {variant: "error",})
    }
  }

  useEffect(() => {
    if (subInviteId) {
      handleSubInvite(subInviteId).then(/*intentionally blank*/)
    }
  }, [subInviteId])

  return (
    <Container maxWidth={'md'} style={{marginTop: '48px'}}>
      <Typography variant="h3">{t("view.CompleteSubInvite.waitForInviteToFinish")}</Typography>
    </Container>
  )
}
