import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"
import { useTranslation } from "react-i18next"

import FilesRow from "../FilesRow"

interface InvoiceTopAttachedFilesProps {
  files: any[]
}

export default function InvoiceTopAttachedFiles(props: InvoiceTopAttachedFilesProps): any {
  const { t } = useTranslation("private")
  return props.files?.length > 0
    ? (
      <Grid container item xs={12}>
        <Typography variant="h2">{t("view.Tickets.attachments")}</Typography>
        <FilesRow files={props.files} />
      </Grid>
    ) : (<></>)
}
