import React, {useEffect, useState} from "react"
import { Formik, Form } from "formik"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import NextIcon from "@mui/icons-material/ArrowForwardIos"

// @ts-ignore
import FormSmallContainer from "../components/FormSmallContainer"
// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import SelectField from "../components/Fields/Select"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
import { getUsersInProject } from "../api/users"
import { makeUserName } from "../util/user"
import { ListOption, ProjectShareAndUploadFormSubmitValues } from "../types/form"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"

const fileUploadLimitInMB = 10

interface ShareAndUploadFormProps {
  projectId: string
  toggleFormOpen: () => void
  submitValues: (values: ProjectShareAndUploadFormSubmitValues) => Promise<void>,
}

export default function ShareAndUploadForm(
  {projectId, toggleFormOpen, submitValues}: ShareAndUploadFormProps
): JSX.Element {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()
  const [internalUsers, setInternalUsers] = useState<ListOption<string>[]>([])

  useEffect(() => {
    if (!!projectId) {
      getUsersInProject(projectId)
        .then((res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            setInternalUsers(
              res.data
                .filter((user) => user.project_user_role_code !== 'crew')
                .map((user) => ({
                  label: makeUserName(user),
                  value: user.id,
                }))
            )
          }
        })
        .catch(() => {
          enqueueSnackbar(t("form.label.getUsersInProjectFail"), {
            variant: "error",
          })
        })
    }
  }, [projectId, enqueueSnackbar, t])


  return (
    <FormSmallContainer>
      <Formik
      initialValues={{
        shareWith: [],
        notes: "",
        files: [
          {
            name: "",
            url: "",
            file_size_bytes: "",
          },
        ]
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        // Send emails
        await submitValues(values)
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          handleBlur,
          handleChange,
          values,
          errors,
          setFieldValue,
          touched,
        }) => {
        return internalUsers.length > 0 ? (
          <Form>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" align={"left"}>
                  {t("form.label.recipients")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  label={t("view.Settings.internal")}
                  multiple={true}
                  name={'shareWith'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={internalUsers}
                  value={values.shareWith}
                />
              </Grid>

              <Grid item xs={12}>
                <TextAreaField
                  label={t("form.label.notes")}
                  value={values.notes}
                  name="notes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <UploadMultipleField
                  errors={errors}
                  label={t("form.label.uploadAttachments")}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  fileLimit={fileUploadLimitInMB}
                />
              </Grid>

              <Grid container item xs={6} alignItems="center">
                <Button color="secondary" variant="text" onClick={toggleFormOpen}>
                  {t("form.label.cancel")}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent={"flex-end"}
              >
                <Button
                  type="submit"
                  endIcon={<NextIcon />}
                  disabled={(values.shareWith.length) === 0}
                >
                  {t("form.label.send")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        ) : <></>
      }}
    </Formik>
    </FormSmallContainer>
  )
}
