import React from "react"
import {useTranslation} from "react-i18next"
import { useSnackbar } from "notistack"

// @ts-ignore
import FormSmallContainer from "../../../../../../../components/FormSmallContainer"
import {submitGcManualCostApprove,} from "../../../../../../../api/ticketReviewProcess"
import OneTextFieldForm from '../../../../../../../forms/OneTextFieldForm'


interface GcManualCostApproveFormProps {
  toggleFormOpen: () => void,
  ticketId: string,
  projectId: string,
  ticketActionChange: (new_action_id: number) => void
}

export default function GcManualCostApproveForm(
  {toggleFormOpen, ticketId, projectId, ticketActionChange}: GcManualCostApproveFormProps
): JSX.Element {
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()

  const submitForm = async (values: {message: string}): Promise<void> => {
    try {
      const {status, data: trp} = await submitGcManualCostApprove(
        ticketId,
        projectId,
        values.message
      )
      toggleFormOpen()
      if (status === 200 && trp.id != null) {
        if (trp?.new_action_id) {
          ticketActionChange(trp.new_action_id)
        }
        enqueueSnackbar(t('view.Tickets.GcManualCostApproveForm.finishedSuccessfully'), {variant: "success"})
      } else {
        enqueueSnackbar(t('view.Tickets.GcManualCostApproveForm.manualApproveFailed'), {variant: "error"})
      }
    } catch (_) {
      toggleFormOpen()
      enqueueSnackbar(t('view.Tickets.GcManualCostApproveForm.manualApproveFailed'), {variant: "error"})
    }
  }

  return (
    <FormSmallContainer>
      <OneTextFieldForm
        onCancel={toggleFormOpen}
        submitForm={submitForm}
        titleText={t('view.Tickets.GcManualCostApproveForm.title')}
        textFieldRequired={false}
      />
    </FormSmallContainer>
  )
}
