import React, {useMemo} from "react"
import {Pie, PieChart} from 'recharts'
import {Grid} from '@mui/material'

import {RechartsData} from '../../types/charts'
import {BudgetStatusChartData} from './type'
import BudgetLogStatusChartLegend from './Legend'


export type BudgetLogStatusChartProps = {
  statusData: BudgetStatusChartData[]
}

export default function BudgetLogStatusChart({statusData}: BudgetLogStatusChartProps): JSX.Element {
  const chartData: RechartsData[] = useMemo(() => {
    return statusData
      .filter((sd) => sd.count > 0)
      .map((sd) => ({name: sd.label, value: sd.count, fill: sd.fill}))
  }, [statusData])

  return (
    <Grid container spacing={2} justifyContent='center' style={{marginTop: '15px'}} alignItems={'center'}>
      <Grid item xs={3}>
        <PieChart width={250} height={250}>
          <Pie dataKey={'value'} data={chartData} cx={'50%'} cy={'50%'} outerRadius={100}/>
        </PieChart>
      </Grid>
      <Grid item xs={5}>
        <BudgetLogStatusChartLegend statusData={statusData}/>
      </Grid>
    </Grid>
  )
}
