import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'

import {BulletinActionCode} from '../../../../../../types/action'
import Fab from '../../../../../../components/Fab'
import {goToEditBulletin} from '../../../../../../util/routes'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {getUser} from '../../../../../../store/features/userSlice'


interface BulletinEditButtonProps {
  projectId?: string,
  bulletinId?: string,
  bulletinActionCode?: BulletinActionCode,
}

export default function BulletinEditButton(
  {projectId, bulletinId, bulletinActionCode}: BulletinEditButtonProps
): JSX.Element {
  const user = useTracFloSelector(getUser)
  const showEditButtonForActionCodes: BulletinActionCode[] = ['draft', 'review']
  // if you can see this, you can edit it, unless you're a superadmin
  const showEditButton = projectId && bulletinId
    && !user.is_admin
    && bulletinActionCode
    && showEditButtonForActionCodes.includes(bulletinActionCode)
  const { t } = useTranslation("private")

  return showEditButton
    ? (
      <Fab
        color="secondary"
        component={Link}
        edge="end"
        size="medium"
        to={goToEditBulletin(projectId, bulletinId)}
        variant="extended"
      >
        <EditIcon />
        {t("view.Bulletins.SingleBulletin.editButton")}
      </Fab>
    ) : <></>
}
