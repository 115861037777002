import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { GridColDef } from '@mui/x-data-grid'

//@ts-ignore
import Button from "./Button"
//@ts-ignore
import FormSmallContainer from "./FormSmallContainer"
// @ts-ignore
import DataTable from "./DataTable"


type DataGridAccordionExtraButton = {text: string, onClick: (event: any) => void} | {component: JSX.Element}

export interface DataGridAccordionRowItem {
  title: string,
  middleSubtitle?: string,
  columns: GridColDef[],
  rows: any[],
  addForm?: {
    submitText: string,
    openButtonText: string,
    title: string,
    form: any,
    props?: Object,
  },
  extraButtons?: DataGridAccordionExtraButton[]
}

interface DataGridAccordionRowProps {
  expanded: boolean,
  handlePanelExpandChange: () => void,
  index: number,
  item: DataGridAccordionRowItem
}

export default function DataGridAccordionRow({ expanded, handlePanelExpandChange, index, item }: DataGridAccordionRowProps) {
  const [showAddForm, setShowAddForm] = React.useState(false)

  return (
    <Accordion
      expanded={expanded}
      onChange={handlePanelExpandChange}
      sx={{ mb: 2, mt: 2 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="h2" sx={{ m: 0 }}>
              {item.title}
            </Typography>
          </Grid>

          {
            item.middleSubtitle == null
              ? <></>
              : <Grid item xs={12} md={8}>
                <Typography style={{fontSize: 16, color: '#888888'}}>
                  {item.middleSubtitle}
                </Typography>
              </Grid>
          }

          { expanded
            ? <Grid item container justifyContent={"flex-end"} xs={12} md={8}>
              <Box>
                { !!item.extraButtons && item.extraButtons.length > 0
                  ? item.extraButtons.map((
                    extraButton
                  ) => {
                    if ('component' in extraButton) {
                      return extraButton.component
                    } else {
                      return <Button
                        color="secondary"
                        variant="text"
                        onClick={extraButton.onClick}
                        key={extraButton.text}
                      >
                        {extraButton.text}
                      </Button>
                    }
                  })
                  : <></>
                }
                { !!item.addForm
                  ? <Button
                    color="secondary"
                    startIcon={<AddIcon />}
                    variant="text"
                    onClick={(event: any) => {
                      event.stopPropagation()
                      setShowAddForm(!showAddForm)
                    }}
                  >
                    {item.addForm.openButtonText ?? "Add"}
                  </Button>
                  : <></>
                }
              </Box>
            </Grid>
            : <></>
          }
        </Grid>
      </AccordionSummary>
      <AccordionDetails id={`panel${index}-content`}>
        <DataTable
          elevation={0}
          columns={item.columns}
          disableSelectionOnClick
          pageSize={25}
          rows={item.rows}
          checkboxSelection={false}
          showExportToolbar={true}
          style={{ border: "none" }}
        />
        { item.addForm
          ? <Collapse in={showAddForm} timeout="auto" unmountOnExit>
            <FormSmallContainer pt={1} pb={3}>
              <Typography variant="h2">{item.addForm.title}</Typography>
              <item.addForm.form
                onCancel={() => setShowAddForm(false)}
                {...(item.addForm.props ?? {})}
              />
            </FormSmallContainer>
          </Collapse>
          : <></>
        }
      </AccordionDetails>
    </Accordion>
  )
}
