import { EstimateLineItem, EstimateMarkup } from "../api/estimate"
import { CreateEstimateLineItem, CreateEstimateMarkup } from "../forms/CreateEstimate.types"
import { convertToNumber } from "./number"

export function calculateEstimateLineItemSubtotal(lineItems: EstimateLineItem[]): number {
  return lineItems.reduce<number>((sum, lineItem) => {
    return sum + (convertToNumber(lineItem.cost) ?? 0)
  }, 0)
}
  
export function calculateEstimateMarkupTotalPercent(markup: EstimateMarkup[]): number {
  return markup.reduce<number>((sum, markupItem) => {
    return sum + (convertToNumber(markupItem.percent) ?? 0)
  }, 0)
}

export function calculateEstimateSubtotal(
  is_lump_sum: boolean, 
  lump_sum_total: string | number, 
  line_items: EstimateLineItem[]
): number {
  return is_lump_sum
    ? (convertToNumber(lump_sum_total) ?? 0)
    : line_items 
    ? calculateEstimateLineItemSubtotal(line_items)
    : 0
}

export function calculateEstimateTotal(
  is_lump_sum: boolean, 
  lump_sum_total: string | number,
  line_items: CreateEstimateLineItem[], 
  markup: CreateEstimateMarkup[],
): number {
  const subtotal = calculateEstimateSubtotal(
    is_lump_sum,
    lump_sum_total,
    line_items
  )
  const markupTotal = calculateEstimateMarkupTotalPercent(markup)
  return markupTotal / 100 * subtotal + subtotal
}