// @ts-ignore
import api from "../libs/api"
import {uploadFiles, uploadSignature} from "./file"
import {AxiosResponse} from "axios"
import {ProjectUserRoleCode} from "../types/userRoles"
import { TicketReviewProcess } from "../types/ticketReviewProcess"

export interface ProjectClient {
  email: string,
  first_name: string,
  id: string,
  last_name: string,
  phone: string,
  project_user_role_code: ProjectUserRoleCode,
}

export async function getProjectClients(projectId: string): Promise<ProjectClient[]> {
  const {data: projectClients} = await api.get(`/project/${projectId}/clients`)
  return projectClients
}

export async function uploadTicketReviewProcessFiles(
  files: any[],
  project_id: string,
  trp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadFiles(files, project_id, trp_id, 'ticket_review_process')
}

export async function submitTmRevise(ticketId: string, projectId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_tm_revise`,
    data
  })
}

export async function notifyForTmRevise(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_tm_revise`,
  })
}

export async function submitTmReject(ticketId: string, projectId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_tm_reject`,
    data
  })
}

export async function notifyForTmReject(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_tm_reject`,
  })
}

export async function uploadTicketReviewProcessSignature(
  signature: Blob,
  project_id: string,
  trp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadSignature(signature, project_id, trp_id, 'ticket_review_process')
}

export async function submitTmApprove(
  ticketId: string,
  projectId: string,
  message: string | null
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_tm_approve`,
    data: message === null ? {} : {message}
  })
}

export async function notifyForTmApprove(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_tm_approve`,
  })
}

export function submitTicketForTmReview(projectId: string, ticketId: string, message: string): Promise<any> {
  return api.post(`/project/${projectId}/ticket/${ticketId}/submit_for_tm_review`, {message})
}

export function submitTicketForCostReview(projectId: string, ticketId: string, message: string): Promise<any> {
  return api.post(`/project/${projectId}/ticket/${ticketId}/submit_for_cost_review`, {message})
}

export function submitTicketForShare(projectId: string, ticketId: string, message: string): Promise<any> {
  return api.post(`/project/${projectId}/ticket/${ticketId}/submit_for_share`, {message})
}

export function notifyForShare(project_id: string, trp_id: string, idsToEmail: string[]): Promise<any> {
  return api.post(`/project/${project_id}/ticket_review_process/${trp_id}/share`, {idsToEmail})
}

export function notifyForTmReview(project_id: string, trp_id: string, idsToEmail: string[]): Promise<any> {
  return api.post(`/project/${project_id}/ticket_review_process/${trp_id}/notify_for_tm_review`, {idsToEmail})
}

export function notifyForCostReview(project_id: string, trp_id: string, idsToEmail: string[]): Promise<any> {
  return api.post(`/project/${project_id}/ticket_review_process/${trp_id}/notify_for_cost_review`, {idsToEmail})
}

export async function submitCostReject(ticketId: string, projectId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_cost_reject`,
    data
  })
}

export async function notifyForCostReject(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_cost_reject`,
  })
}

export async function submitCostRevise(ticketId: string, projectId: string, data: any): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_cost_revise`,
    data
  })
}

export async function notifyForCostRevise(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_cost_revise`,
  })
}

export async function submitBackToDraft(ticketId: string, projectId: string, message?:string): Promise<any> {
  return api.post(`/project/${projectId}/ticket/${ticketId}/back_to_draft`, {message})
}

export async function ticketHasBeenTmApproved(ticketId: string, projectId: string): Promise<any> {
  const {data: is_tm_approved} = await api.get(`/project/${projectId}/ticket/${ticketId}/is_tm_approved`)
  return is_tm_approved
}

export async function submitBackToCostEdit(ticketId: string, projectId: string, message?:string): Promise<any> {
  return api.post(`/project/${projectId}/ticket/${ticketId}/back_to_cost_edit`, {message})
}

export async function submitCostApprove(
  ticketId: string,
  projectId: string,
  message: string | null
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/submit_cost_approve`,
    data: message === null ? {} : {message}
  })
}

export async function notifyForCostApprove(
  trpId: string,
  projectId: string
): Promise<any> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/notify_for_cost_approve`,
  })
}

export async function attachTicketInvoice(trpId: string, projectId: string): Promise<AxiosResponse<string>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/attach_ticket_invoice`,
  })
}

export async function submitTradeManualGcCostApprove(
  ticketId: string,
  projectId: string,
  message: string | null
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/trade_manual_gc_cost_approve`,
    data: message === null ? {} : {message}
  })
}

export async function createHistoryForTradeManualGcCostApprove(
  trpId: string,
  projectId: string
): Promise<AxiosResponse<string>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket_review_process/${trpId}/history_for_trade_manual_gc_cost_approve`,
  })
}

export async function submitGcManualCostApprove(
  ticketId: string,
  projectId: string,
  message: string
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/project/${projectId}/ticket/${ticketId}/gc_manual_cost_approve`,
    data: {message}
  })
}
