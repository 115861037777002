import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import {BreakdownTableColumn} from "./BreakdownTable.columns"
import {BreakdownTableHeader} from "./BreakdownTable.header"
import {BreakdownTableRows} from "./BreakdownTable.rows"
import {BreakdownTableTotals} from "./BreakdownTable.totals"
import { Theme } from "@mui/material"

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "block",
    marginBottom: theme.spacing(1),
    maxWidth: "100%",
    overflowX: "auto",
    width: "100%",
  },
  table: {
    border: "none",
    "& .MuiTableCell-head": {
      color: theme.palette.grey[600],
      fontSize: 12,
      paddingLeft: 5,
      paddingRight: 5,
      "&:first-child": {
        lineHeight: 1,
        paddingBottom: 0,
        paddingTop: 0,
      },
      "&:last-child": {
        lineHeight: 1,
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    "& .MuiTableCell-body": {
      paddingLeft: 5,
      paddingRight: 5,
      "&:first-child": {},
      "&:last-child": {},
    },
  },
}))

interface BreakdownTableProps {
  columns: BreakdownTableColumn[],
  editable?: boolean,
  rows?: any[],
  totals: any,
  subtotalText?: string,
}

export default function BreakdownTable(props: BreakdownTableProps) {
  const classes = useStyles()
  // Need to add index to rows
  const idRows = props.rows?.map((row, index) => ({ ...row, index: index + 1 }))

  return idRows?.length ? (
    <TableContainer className={classes.container}>
      <Table size="small" className={classes.table}>

        <BreakdownTableHeader columns={props.columns}/>

        <TableBody>
          <BreakdownTableRows rows={idRows} columns={props.columns}/>
          <BreakdownTableTotals
            totals={props.totals}
            columns={props.columns}
            editable={props.editable}
            subtotalText={props.subtotalText}
          />
        </TableBody>

      </Table>
    </TableContainer>
  ) : (
    <></>
  )
}
