import {getTicketInvoicePdfUrl} from "../api/ticket"
import {getDailyInvoicePdfUrl} from '../api/daily'

export const bytesInMB: number = 1000 * 1000

export function hasFilesForUpload(files: any[]): boolean {
  return files.find((f) => !!f?.name) !== undefined
}

export async function getBlobFromSignaturePad(signaturePadRef: any): Promise<Blob> {
  return new Promise(
    resolve => signaturePadRef.current.canvasRef.current.toBlob(resolve, 'image/png')
  )
}

export async function openTicketPdfInNewWindow(
  projectId: string,
  ticketId: string,
  enqueueSnackbar: (message: string, options: any) => any,
  closeSnackbar: any,
  t: (s: string) => string
): Promise<void> {
  const snackbarKey = enqueueSnackbar(t('view.Tickets.downloading_ticket'), {
    variant: 'info',
    persist: true,
  })
  try {
    const pdfRes = await getTicketInvoicePdfUrl(projectId, ticketId)
    closeSnackbar(snackbarKey)
    if (!!pdfRes.data) {
      window.open(pdfRes.data.url, '_blank')
      return
    }
    enqueueSnackbar(t('view.Tickets.downloading_ticket_fail'), {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
  } catch (_) {
    closeSnackbar(snackbarKey)
    enqueueSnackbar(t('view.Tickets.downloading_ticket_fail'), {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
  }
}

export async function openDailyPdfInNewWindow(
  projectId: string,
  dailyId: string,
  enqueueSnackbar: (message: string, options: any) => any,
  closeSnackbar: any,
  t: (s: string) => string
): Promise<void> {
  const snackbarKey = enqueueSnackbar(t('view.Daily.SingleDaily.Download.downloading'), {
    variant: 'info',
    persist: true,
  })
  try {
    const pdfRes = await getDailyInvoicePdfUrl(projectId, dailyId)
    closeSnackbar(snackbarKey)
    if (!!pdfRes.data) {
      window.open(pdfRes.data.url, '_blank')
      return
    }
    enqueueSnackbar(
      t('view.Daily.SingleDaily.Download.downloading_fail'),
      {variant: 'error', style: {whiteSpace: 'pre-wrap'}}
    )
  } catch (_) {
    closeSnackbar(snackbarKey)
    enqueueSnackbar(
      t('view.Daily.SingleDaily.Download.downloading_fail'),
      {variant: 'error', style: {whiteSpace: 'pre-wrap'}}
    )
  }
}

export function fileIsImage(filename: string): boolean {
  const imageFileTypes: string[] = ['.png', '.jpg', '.jpeg', '.svg']
  return imageFileTypes.some((ift) => filename.includes(ift))
}

export function fileIsPdf(filename: string): boolean {
  return filename.toLowerCase().includes('.pdf')
}
