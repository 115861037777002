import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'

import {EstimateActionCode} from '../../../../../../types/action'
import Fab from '../../../../../../components/Fab'
import {goToEditEstimate} from '../../../../../../util/routes'
import {useTracFloSelector} from '../../../../../../store/useTracFloSelector'
import {getUser} from '../../../../../../store/features/userSlice'
import {getCompany} from '../../../../../../store/features/companySlice'


interface EstimateEditButtonProps {
  projectId?: string,
  estimateId?: string,
  estimateActionCode?: EstimateActionCode,
  estimateIsClosed: boolean,
}

export default function EstimateEditButton(
  {projectId, estimateId, estimateActionCode, estimateIsClosed}: EstimateEditButtonProps
): JSX.Element {
  const user = useTracFloSelector(getUser)
  const company = useTracFloSelector(getCompany)
  const showEditButtonForActionCodes: EstimateActionCode[] = ['pending', 'draft', 'revise']
  // if you can see this, you can edit it, unless you're a superadmin
  const showEditButton = projectId && estimateId
    && company.company_type === 'trade'
    && !user.is_admin
    && !estimateIsClosed
    && estimateActionCode
    && showEditButtonForActionCodes.includes(estimateActionCode)
  const { t } = useTranslation("private")

  return showEditButton
    ? (
      <Fab
        color="secondary"
        component={Link}
        edge="end"
        size="medium"
        to={goToEditEstimate(projectId, estimateId)}
        variant="extended"
      >
        <EditIcon />
        {t("view.Estimates.SingleEstimate.editButton")}
      </Fab>
    ) : <></>
}
