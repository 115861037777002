import React, {useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import MonthlyCOsReportTable from "./MonthlyCOsReport.table"
import { getMonthlyCOsReportForAdmin } from "../../../../../api/admin"
//@ts-ignore
import FormWideContainer from "../../../../../components/FormWideContainer"


export default function MonthlyCOsReportView() {
  const [ monthlyCOsReport,
          setMonthlyCOsReport] = useState<{month: string, change_orders_created: string, change_orders_created_amount: string}[]>([])
  const { t } = useTranslation("private")

  useEffect(() => {
    getMonthlyCOsReportForAdmin().then(
      (res) => {
        if (res?.data?.length > 0) {
          const monthlyCOsReportData = res.data.slice(0, 6).reverse()
          setMonthlyCOsReport(monthlyCOsReportData)
        }
      }
    )
  }, [])

  return (
    <>
      <FormWideContainer>
        <Grid item xs={12}>
          <Typography variant="h2">{t("view.Admin.monthlyCOsReportTitle")}</Typography>
        </Grid>
        <MonthlyCOsReportTable monthlyCOsReportData={monthlyCOsReport}/>
      </FormWideContainer>
    </>
  )
}