import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useTheme } from "@mui/material"

//@ts-ignore
import Container from "../../../../../components/Container"
import { Estimate } from "../../../../../types/estimate"
import { getEstimateById } from "../../../../../api/estimate"
import BulletinDetailsForEstimates from "../../../../../components/BulletinDetailsForEstimates"
import { useTracFloSelector } from "../../../../../store/useTracFloSelector"
import EstimateHistory from "./History"
import EstimateReviewSection from "./Review"
import EstimateInvoice from "./Invoice"
import EstimateCostOverview from "./CostOverview"
import EstimateAttachedObjectsAccordion from './AttachedObjects'


export default function SingleEstimateView(): JSX.Element {
  const { t } = useTranslation("private")
  const { estimateId, projectId } = useParams<{estimateId: string, projectId: string}>()
  const [estimate, setEstimate] = useState<Estimate | null>(null)
  const project = useTracFloSelector((state) => state.project)
  const {enqueueSnackbar} = useSnackbar()
  const theme = useTheme()
  const historyRef = useRef<any>()

  // Get Estimate Info
  useEffect(() => {
    if (projectId === project.id && estimateId) {
      getEstimateById(projectId, estimateId)
        .then((res) => {
          if(!!res.data){
            setEstimate(res.data)
          }
        })
        .catch(() => {
          enqueueSnackbar(t("view.Estimates.SingleEstimate.loadEstimateFail"), {
            variant: "error",
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project.id, estimateId])

  return project.id === projectId && !!estimate ? (
    <>
      <EstimateReviewSection projectId={project.id} estimate={estimate} historyRef={historyRef}/>

      <Container fullWidth style={{ background: theme.palette.grey[200] }}>
        <BulletinDetailsForEstimates bulletinId={estimate.bulletin_id} projectId={projectId}/>
        <EstimateInvoice estimate={estimate} project={project}/>
        <EstimateCostOverview estimate={estimate}/>
        <EstimateAttachedObjectsAccordion
          projectId={project.id}
          estimateId={estimate.id}
        />
      </Container>

      <div ref={historyRef}>
        <Container>
          <EstimateHistory estimate_id={estimate.id} project_id={project.id} />
        </Container>
      </div>
    </>
  ) : (<></>)
}
