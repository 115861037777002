import {StoreBase} from './storeBase'
import {LoadingStatus} from './status'
import {ExpirationField} from './expiration'


export type CustomCode = {
  id: string,
  company_id: string,
  project_id?: string,
  code: string,
  description: string,
  is_active: boolean,
  date_created: Date | string,
  date_modified: Date | string,
  created_by: string, // user ID
}

export type CustomCodeStore = {items: CustomCode[], companyId: string | null} & StoreBase<LoadingStatus, ExpirationField>

export type HasCustomCodesObject = {
  custom_code_ids: string[] | null,
  codes: string[] | null
}

// modifies object in place
export function addCodesFieldToObject<T extends HasCustomCodesObject>(
  obj: T,
  ccs: CustomCode[]
): T {
  if (obj.custom_code_ids == null || obj.custom_code_ids.length === 0 || ccs.length === 0) return obj
  const codes = []
  for (let cc_id of obj.custom_code_ids) {
    const cc = ccs.find((cc) => cc.id === cc_id)
    if (cc != null) {
      codes.push(cc.code)
    }
  }
  obj.codes = codes
  return obj
}
